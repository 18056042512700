import { Image } from "react-bootstrap";
import {
  CarbonSec,
  CityMapCardSearch,
  CitySolarMapCard,
  MapCardContent,
  MapCardIntro,
  MapCardIntroText,
  MapCardTitle,
  SearchContent,
  SolarAutocomplete,
  SolarCard,
  SolarCardContent,
  SolarCardContentItem,
  SolarCardTitle,
  SolarCityIntro,
  SolarImgCard,
  SolarMapCard,
  SolarMapCardSec,
  SolarGridContainer,
  SolarGridItem,
  SolarValueText,
  SolarDescriptionText,
  SolarTitle
} from "./Solar.styled";
import {
  Autocomplete,
  Button,
  Grid,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
} from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import { AddressProps } from "../../types";
import { css } from "@emotion/css";
import throttle from "lodash/throttle";
import { fetchAddress } from "../../api/city";
import { useParams, useNavigate } from "react-router-dom";
import { capitalizeString } from "../CommonFun";
import NumberFormat from "react-number-format";
import { squareMetersToSquareFeet } from "../../utils/convert";

export const SolarBillCard = (props: any) => {
  const { value, handleSelected, options } = props;

  return (
    <SolarCard
      style={{
        background: "url(/images/card_bottom_bg1.png) 10px bottom no-repeat",
        backgroundSize: "cover",
      }}
    >
      <SolarCardContentItem>
        <p>YOUR AVERAGE MONTHLY ELECTRIC BILL</p>
        <label>
          We use your bill to estimate how much electricity you use based on
          <br />
          typical utility rates in your area.
        </label>
        <Select
          labelId="term-select-label"
          id="term-select"
          value={value || "20"}
          onChange={(evt: SelectChangeEvent) => {
            handleSelected(evt.target.value as string);
          }}
        >
          {options.map((val: any, idx: any) => (
            <MenuItem value={val} key={idx}>{`$ ${val}`}</MenuItem>
          ))}
        </Select>
      </SolarCardContentItem>
    </SolarCard>
  );
};

export const SolarSizeCard = ({
  solarInsight,
}: {
  solarInsight: {
    watt: number;
    cover: number;
    sqft: number;
  };
}) => {
  const roundedCoverage = Math.round(solarInsight.cover);

  return (
    <SolarCard>
      <SolarCardContentItem>
        <p>YOUR RECOMMENDED SOLAR INSTALLATION SIZE</p>
        <label>
          This size will cover about of{" "}
          <NumberFormat
            value={roundedCoverage < 100 ? roundedCoverage : 100}
            displayType="text"
            suffix="%"
            thousandSeparator
          />{" "}
          your electricity usage. Solar installations are sized in kilowatts
          (kW).
        </label>
        <h6>
          <NumberFormat
            value={solarInsight.watt}
            displayType="text"
            thousandSeparator
            suffix=" kW"
            style={{ fontSize: "inherit" }}
          />{" "}
          <span>
            (
            <NumberFormat
              value={Math.round(solarInsight.sqft)}
              displayType="text"
              thousandSeparator
            />{" "}
            ft<sup>2</sup>)
          </span>
        </h6>
      </SolarCardContentItem>
    </SolarCard>
  );
};

export const SolarSizeCardNew = ({
  solarInsight,
}: {
  solarInsight: {
    watt: number;
    cover: number;
    sqft: number;
    hourYear: number;
    maxPanel: number;
    maxft: number;
    availableSolarPanel: number;
  };
}) => {
  const roundedCoverage = Math.round(solarInsight.cover);

  return (
    <SolarCard>
      <div style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
        <SolarTitle>
          Solar calculation results in your roof
        </SolarTitle>
        <div style={{ flex: 1 }}>
          <SolarGridContainer>
            <SolarGridItem>
              <SolarValueText>{solarInsight.watt} kW</SolarValueText>
              <SolarDescriptionText>Recommended Solar Installation size</SolarDescriptionText>
            </SolarGridItem>
            <SolarGridItem>
              <SolarValueText>{<NumberFormat value={Math.round(solarInsight.sqft)} displayType={'text'} thousandSeparator={true} />}{' '}ft<sup>2</sup></SolarValueText>
              <SolarDescriptionText>Covered roof area</SolarDescriptionText>
            </SolarGridItem>
            <SolarGridItem>
              <SolarValueText>{roundedCoverage < 100 ? roundedCoverage : 100}%</SolarValueText>
              <SolarDescriptionText>Percentage of coverage usage</SolarDescriptionText>
            </SolarGridItem>
            <SolarGridItem>
              <SolarValueText>{solarInsight.availableSolarPanel}/{solarInsight.maxPanel}</SolarValueText>
              <SolarDescriptionText>Installable panel / Max panel</SolarDescriptionText>
            </SolarGridItem>
            <SolarGridItem>
              <SolarValueText>{<NumberFormat value={(Math.round(solarInsight.maxft))} displayType={'text'} thousandSeparator={true} />}{' '}ft<sup>2</sup></SolarValueText>
              <SolarDescriptionText>Roof area for solar panels</SolarDescriptionText>
            </SolarGridItem>
            <SolarGridItem>
              <SolarValueText>{<NumberFormat value={Math.round(solarInsight.hourYear)} displayType={'text'} thousandSeparator={true} />} h</SolarValueText>
              <SolarDescriptionText>Roof light time</SolarDescriptionText>
            </SolarGridItem>
          </SolarGridContainer>
        </div>
        <p style={{ marginBottom: '3px', color: '#6b7280', fontSize: '12px' }}>Part of the data is from google roof solar</p>
      </div>
    </SolarCard>
  );
};

export const SolarModalCard = () => (
  <SolarCard
    bg="#82be460c"
    style={{
      display: "flex",
      justifyContent: "space-evenly",
      flexDirection: "column",
    }}
  >
    <SolarCardTitle>Solar Energy Saving</SolarCardTitle>
    <SolarCardContent wrap>
      <SolarImgCard>
        <Image src="/images/icon-solar-pc.png" />
        <p className="energy-saving">PC Prodoction</p>
      </SolarImgCard>
      <Image src="/images/img-solar-eqal.png" className="img-eqal" />
      <SolarImgCard>
        <Image src="/images/icon-solar-ac.png" />
        <p className="energy-saving">AC/DC Inverter</p>
      </SolarImgCard>
      <Image src="/images/img-solar-eqal.png" className="img-eqal" />
      <SolarImgCard>
        <Image src="/images/icon-solar-battery.png" />
        <p className="energy-saving">Battery Level</p>
      </SolarImgCard>
      <Image src="/images/img-solar-eqal.png" className="img-eqal" />
      <SolarImgCard>
        <Image src="/images/icon-solar-discharge.png" />
        <p>Discharge</p>
      </SolarImgCard>
    </SolarCardContent>
  </SolarCard>
);

export const SolarPotentialCard = ({
  solarData,
  watt,
}: {
  solarData: any;
  watt: number;
}) => {
  const maxSunshineHoursPerYear =
    solarData?.solarPotential?.maxSunshineHoursPerYear || 0;
  const carbonOffsetFactorKgPerMwh =
    solarData?.solarPotential?.carbonOffsetFactorKgPerMwh || 0;
  const yearlyProductionKwh = (maxSunshineHoursPerYear * watt) / 1000; // MWh
  const reducedCO2 = (yearlyProductionKwh * carbonOffsetFactorKgPerMwh) / 1000; // tons

  // https://sunroof.withgoogle.com/assets/data-explorer-methodology.pdf
  const yearlyCO2EmissionsPerCar = 4.73; // metric tons CO2E/vehicle/year
  const capacityPerTree = 0.039; // metric ton CO2 per urban tree planted

  return (
    <SolarCard bg="#fff0c819">
      <SolarCardTitle>
        YOUR POTENTIAL ENVIRONMENTAL IMPACT
        <p>
          Estimated annual environmental impact of the recommended solar
          installation size.
        </p>
      </SolarCardTitle>
      <SolarCardContent>
        <CarbonSec>
          <Image src="/images/ic_co2.png" />
          <div>
            <p className="title">Carbon dioxide</p>
            <h4>
              <NumberFormat
                value={reducedCO2}
                displayType="text"
                thousandSeparator
                decimalScale={1}
                fixedDecimalScale
              />
            </h4>
            <p className="intro">metric tons</p>
          </div>
        </CarbonSec>
        <CarbonSec>
          <Image src="/images/ic_car.png" />
          <div>
            <p className="title">Passenger cars</p>
            <h4>
              <NumberFormat
                value={reducedCO2 / yearlyCO2EmissionsPerCar}
                displayType="text"
                thousandSeparator
                decimalScale={1}
                fixedDecimalScale
              />
            </h4>
            <p className="intro">taken off the road for 1 yr</p>
          </div>
        </CarbonSec>
        <CarbonSec>
          <Image src="/images/ic_tree.png" />
          <div>
            <p className="title">Tree seedlings</p>
            <h4>
              <NumberFormat
                value={reducedCO2 / capacityPerTree}
                displayType="text"
                thousandSeparator
                decimalScale={1}
                fixedDecimalScale
              />
            </h4>
            <p className="intro">grown for 10 yrs</p>
          </div>
        </CarbonSec>
      </SolarCardContent>
    </SolarCard>
  );
};

export const MapCard = (props: any) => {
  const [address, setAddress] = useState("");
  const [selectedAddress, setSelectedAddress] = useState<
    AddressProps | undefined
  >(undefined);
  const [addressOptions, setAddressOptions] = useState([]);
  const [addressLoading, setAddressLoading] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    fetchAddressDelayed(address, (result: any) => {
      setAddressLoading(false);
      setAddressOptions(result || []);
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [address]);

  const fetchAddressDelayed = useCallback(
    throttle((text, callback) => {
      if (text && text.trim()) {
        setAddressLoading(true);
        fetchAddress(text.trim()).then(callback);
      }
    }, 1000),
    []
  );

  const onSubmit = async () => {
    if (!selectedAddress) return;
    // const state = "tx";
    const city = selectedAddress.city.toLowerCase();
    const street = selectedAddress.street.toLowerCase();
    navigate(
      `/solar/texas/${city}/${selectedAddress.zipcode}/${encodeURIComponent(
        street
      )}`
    );
  };

  const customFilterOptions = (options: any, { inputValue }: any) => {
    const trimmedInput = inputValue.trim().toLowerCase();
    const filterArr = trimmedInput.split(" ");

    return options.filter((option: any) =>
      filterArr.every((char: any) => option.label.toLowerCase().includes(char))
    );
  };

  const { data, defaultAddress } = props;

  if (!defaultAddress) return null;
  return (
    <SolarMapCardSec>
      <SolarAutocomplete>
        <div
          style={{
            flex: 1,
          }}
        >
          <Autocomplete
            freeSolo
            onChange={(evt, val: any) => {
              setSelectedAddress(val);
            }}
            onInputChange={(evt, val: any) => {
              setAddress(val);
            }}
            options={addressOptions.map((option: any) => option)}
            renderOption={(props, option) => (
              <li {...props} key={option.id}>
                {option.label}
              </li>
            )}
            defaultValue={defaultAddress}
            loading={addressLoading}
            filterOptions={customFilterOptions}
            renderInput={(params) => (
              <TextField
                {...params}
                disabled={addressLoading}
                className={css`
                  @media (max-width: 600px) {
                    label {
                      padding-top: 5px;
                      font-size: 12px;
                    }
                  }
                `}
              />
            )}
            className={css`
              label {
                padding-left: 10px;
              }
            `}
          />
        </div>
        <Button
          variant="contained"
          onClick={onSubmit}
          disabled={addressLoading}
          style={{ paddingLeft: 30, paddingRight: 30 }}
          className={css`
            background: #38c3a8 !important;
            color: #fff !important;
            border-radius: 50px;
          `}

          sx={{
            borderRadius: "50px",
          }}
        >
          Go
        </Button>
      </SolarAutocomplete>
      {data && false && (
        <SolarMapCard>
          <MapCardTitle>
            <Image src="/images/icon-solar-success.png" alt="" />
            Analysis complete. Your roof has:
          </MapCardTitle>
          <MapCardContent>
            <Image src="/images/icon-sun.png" width={40} />
            <div>
              <p>
                <NumberFormat
                  value={Math.round(
                    data.solarPotential.maxSunshineHoursPerYear
                  )}
                  displayType="text"
                  thousandSeparator
                />{" "}
                hours of usable sunlight per year
              </p>
              <label>Based on day-to-day analysis of weather patterns</label>
            </div>
          </MapCardContent>
          <MapCardContent>
            <Image src="/images/icon-solar-house.png" width={40} />
            <div>
              <p>
                <NumberFormat
                  value={Math.round(
                    squareMetersToSquareFeet(
                      Number(data.solarPotential.maxArrayAreaMeters2)
                    )
                  )}
                  displayType="text"
                  thousandSeparator
                />{" "}
                sq feet available for solar panels
              </p>
              <label>Based on 3D modeling of your roof and nearby trees</label>
            </div>
          </MapCardContent>
        </SolarMapCard>
      )}
    </SolarMapCardSec>
  );
};

export const CityMapSection = (props: any) => {
  const [address, setAddress] = useState("");
  const [selectedAddress, setSelectedAddress] = useState<
    AddressProps | undefined
  >(undefined);
  const [addressOptions, setAddressOptions] = useState([]);
  const [addressLoading, setAddressLoading] = useState(false);
  const navigate = useNavigate();

  const { city, state, zipcode, isTexas } = props;

  const CityName = capitalizeString(city);
  const handleChangeAddress = (evt: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = evt.target;
    setAddress(value);
  };

  useEffect(() => {
    fetchAddressDelayed(address, (result: any) => {
      setAddressLoading(false);
      setAddressOptions(result || []);
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [address]);

  const fetchAddressDelayed = useCallback(
    throttle((text, callback) => {
      if (text && text.trim()) {
        setAddressLoading(true);
        fetchAddress(text.trim()).then(callback);
      }
    }, 1000),
    []
  );

  const onSubmit = async () => {
    if (!selectedAddress) return;
    // const state = "tx";
    const city = selectedAddress.city.toLowerCase();
    const street = selectedAddress.street.toLowerCase();
    navigate(
      `/solar/texas/${city}/${selectedAddress.zipcode}/${encodeURIComponent(
        street
      )}`
    );
  };

  const customFilterOptions = (options: any, { inputValue }: any) => {
    const trimmedInput = inputValue.trim().toLowerCase();
    const filterArr = trimmedInput.split(" ");

    return options.filter((option: any) =>
      filterArr.every((char: any) => option.label.toLowerCase().includes(char))
    );
  };

  return (
    <CitySolarMapCard>
      <MapCardIntro>
        <Image src="/images/icon-sun.png" />
        <MapCardIntroText>
          <h5>{`Solar Energy Options in ${CityName} ${state} ${zipcode}`}</h5>
          <p>{`New Solar Energy Program. Make a positive difference by switching to cleaner solar energy in 
        ${city}, ${state}. Our renewable energy programs can meet your specific energy needs while 
        contributing to a greener future.`}</p>
        </MapCardIntroText>
      </MapCardIntro>
      {isTexas && (
        <CityMapCardSearch>
          <Image src="/images/icon-solar-house.png" />
          <SearchContent>
            <h3>Check the usage of solar energy in your address</h3>
            <SolarAutocomplete>
              <div
                style={{
                  flex: 1,
                  marginRight: -30,
                  marginLeft: -10,
                }}
              >
                <Autocomplete
                  freeSolo
                  onChange={(evt, val: any) => {
                    setAddress(val ? val.label : "");
                    setSelectedAddress(val);
                  }}
                  options={addressOptions.map((option: any) => option)}
                  renderOption={(props, option) => (
                    <li {...props} key={option.id}>
                      {option.label}
                    </li>
                  )}
                  loading={addressLoading}
                  filterOptions={customFilterOptions}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Please Enter Your Address"
                      value={address}
                      onChange={handleChangeAddress}
                      disabled={addressLoading}
                      sx={{
                        "& .MuiOutlinedInput-root": {
                          "& fieldset": {
                            borderColor: "#38c3a8", 
                          },
                          "&:hover fieldset": {
                            borderColor: "#38c3a8",
                          },
                          "&.Mui-focused fieldset": {
                            borderColor: "#38c3a8", 
                          },
                        },
                      }}
                      className={css`
                    @media (max-width: 600px) {
                      label {
                        padding-top: 5px;
                        font-size: 12px;
                      }
                    }
                  `}
                    />
                  )}
                  style={{ transform: "translatex(10px)" }}
                  className={css`
                label {
                  padding-left: 10px;
                }
              `}
                />

              </div>
              <Button
                variant="contained"
                onClick={onSubmit}
                disabled={addressLoading || !selectedAddress}
                style={{ paddingLeft: 30, paddingRight: 30 }}
                className={css`
                  background: #38c3a8 !important;
                  color: #fff !important;
                  border-radius: 50px;
                `}

                sx={{
                  borderRadius: "50px",
                  transform: "translateX(-10px)",
                }}
              >
                Go
              </Button>
            </SolarAutocomplete>
          </SearchContent>
        </CityMapCardSearch>
      )}
    </CitySolarMapCard>
  );
};

export const CitySolarIntro = () => (
  <Grid container spacing={3} sx={{ height: "100%", padding: "30px 0" }}>
    <Grid item sm={4} xs={12}>
      <SolarCityIntro>
        <h6>Receive a home solar analysis</h6>
        <p>
          Enter your home address into personalized to estimate potential energy
          production and bill savings you could generate with rooftop solar.
        </p>
      </SolarCityIntro>
    </Grid>

    <Grid item sm={4} xs={12}>
      <SolarCityIntro>
        <h6>Utility net metering agreement</h6>
        <p>
          Residential solar systems often generate more electricity than your
          home uses during daylight hours, so you’ll need a net-metering account
          with your utility. Most installers will help you select the right plan
          to ensure optimal savings over time.
        </p>
      </SolarCityIntro>
    </Grid>

    <Grid item sm={4} xs={12}>
      <SolarCityIntro>
        <h6>Install and inspect</h6>
        <p>
          Typical systems can be installed in a day or two. After installation
          local city and utility representatives will come out to your home to
          inspect the system.
        </p>
      </SolarCityIntro>
    </Grid>

    <Grid item sm={4} xs={12} height="100%">
      <SolarCityIntro style={{ marginBottom: "10px" }}>
        <h6>Contract with a provider</h6>
        <p>Finalize agreement with the provider you prefer.</p>
      </SolarCityIntro>

      <SolarCityIntro>
        <h6>Design system and obtain permit</h6>
        <p>
          Review and approve your system design so your provider can get permits
          from your local building department.
        </p>
      </SolarCityIntro>
    </Grid>

    <Grid item sm={8} xs={12}>
      <SolarModalCard />
    </Grid>
  </Grid>
);
