import { styled } from "@mui/system";

export const SectionWrapper = styled("div")`
  p,
  h3 {
    margin-bottom: 0;
  }
`;

export const MobileSpan = styled("div")`
  @media (max-width: 991px) {
    p {
      display: none;
    }
  }
`;

export const IntroSection = styled("div")`
  padding: 70px 0;
  dl {
    padding: 40px;
  }
  dt {
    font-size: 2rem;
  }
  dd {
    font-size: 1.1rem;
    color: #b0b0b3;
  }
  img {
    width: 100%;
  }
  @media (max-width: 768px) {
    img {
      display: none;
    }
    dl {
      padding: 0;
    }
    padding: 20px 0;
  }
`;

interface BluebgProps {
  notbottom?: boolean;
  notmove?: boolean;
}
export const BluebgSection = styled("div")<BluebgProps>`
  background: #ecf1ff;
  padding: ${({ notbottom }: { notbottom?: boolean }) =>
    notbottom ? "0" : "0px 0 50px"};
  transform: ${({ notmove }: { notmove?: boolean }) =>
    notmove ? "none" : "translateY(-20px)"};
`;

export const WavebgSection = styled("div")`
  background: url("/images/wave_bg.png") no-repeat top;
  height: 90px;
  width: 100%;
  background-size: 100% 90px;
  padding-bottom: 20px;
  transform: ${({ reverse }: { reverse?: boolean }) =>
    reverse ? "rotate(180deg)" : "none"};
`;
export const CitySelectList = styled("div")`
  background-color: #e8f7f1;
  border-radius: 2rem;
  padding: 40px 32px;
  margin: 20px 0;

  ol,
  ul,
  li {
    padding-left: 0;
    list-style: none;
  }
  ul {
    display: flex;
    margin-bottom: 0;
  }
  .city-button {
    width: 100%;
    border: 1px solid #dbd6d6;
    /* box-shadow: 0px 1px 4px rgba(85, 197, 129, 0.12); */
    background-color: #fff;
    cursor: pointer;
    padding: 10px 0;
    border-radius: 22.5px;
    display:block;
    text-decoration:none;
    text-align: center;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 0.85rem;
    line-height: 1.1;
    padding: 8px 4px;
    border: 0 none;
    color: inherit;
    /* &:hover {
      background: rgba(85, 197, 129, 0.12);
      box-shadow: 0px 1px 4px rgba(85, 197, 129, 0.12) !important;
      transition:
        box-shadow 0.3s,
        background 0.8s;
    } */
  }
  li {
    margin-right: 5px;
    &:last-child {
      margin-right: 0;
    }
  }
  li {
    /* color: #ffffff; */
  }
  .checked-li {
    /* color: #ffffff; */
    font-weight: 500;
  }
  .not-checked-li {
    opacity: .5;
    /* color: #6288e9; */
  }
  .selectCity-TablePagination {
    .MuiTablePagination-selectLabel {
      display: none !important;
    }
    .css-16c50h-MuiInputBase-root-MuiTablePagination-select {
      display: none !important;
    }
  }
  .MuiInputBase-root.MuiInputBase-colorPrimary {
    display: none !important;
  }
  .MuiTablePagination-displayedRows {
    margin-bottom: 0;
  }

  @media (max-width: 768px) {
    ul {
      width: 100%;
      justify-content: space-around;
    }
  }
`;

export const FaqSection = styled("div")`
  background-color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 40px 0;
  .faqs {
    padding: 80px 0 40px;
  }
  h2 {
    font-size: 36px;
  }
  h5 {
    font-size: 24px;
    padding-left: 40px;
  }

  @media (max-width: 768px) {
    .faqs {
      padding: 10px 0 20px 0;
    }
    h2 {
      font-size: 24px;
    }
    h5 {
      font-size: 16px;
    }
  }
`;

export const HomeFaqCard = styled("div")`
  width: 49%;
  float: left;

  &:nth-of-type(2n + 2) {
    margin-left: 2%;
    margin-top: 40px;
  }

  &:nth-of-type(2n + 1) {
    margin-top: -30px;
  }

  &:last-child {
    margin-top: -125px;
  }

  @media (max-width: 768px) {
    width: 100%;
    margin-top: 20px !important;
    margin-left: 0 !important;
  }
`;

export const HomeIntroTitle = styled("div")`
  padding: 80px 0;
  font-size: 36px;
  font-weight: 600;
  text-align: center;

  @media (max-width: 768px) {
    padding: 30px 0;
    font-size: 24px;
  }
`;

export const CityFaqSection = styled("div")`
  padding: 40px 0;
  .faq-title,
  .faq-intro {
    text-align: center;
  }
  .faq-title {
    font-size: 36px;
  }
  .faq-intro {
    font-size: 28px;
  }
  h5 {
    text-align: left;
    font-size: 24px;
    padding-left: 40px;
  }

  label {
    font-size: 1.2rem;
    margin-bottom: 3rem;
  }

  @media (max-width: 768px) {
    padding: 20px 0;
    .faq-title {
      font-size: 24px;
    }

    .faq-intro {
      font-size: 19px;
    }

    h5 {
      font-size: 16px;
    }

    label {
      font-size: 1rem;
      margin-bottom: 1.5rem;
    }
  }
`;

export const SelectBar = styled("div")`
  display: flex;
  align-items: center;
  justify-content: ${({ city }: { city: boolean }) =>
    city && city === true ? "space-between" : "flex-start"};
  /* background-color: ${({ city }: { city: boolean }) =>
    city && city === true ? "#A5E2D0 " : "#F0F0F0"}; */
  /* // margin-top: ${({ city }: { city: boolean }) =>
    city && city === true ? "20px" : "0"}; */
  /* box-shadow: 0px 1px 4px rgba(0, 21, 41, 0.12); */
  border-bottom: 1px solid rgb(221, 221, 221);
  padding-bottom: 8px;
  margin: 24px 0;
  font-size: 0.825rem;

  a {
    color: inherit;
    text-decoration: none;
  }

  b {
    font-weight: 400;
  }

  .state-select {
    padding: 10px 0;
    margin-left: 20px;
    min-width: 200px;
  }

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

export const Selcetpage = styled("div")`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  padding: 10px 0;

  @media (max-width: 768px) {
    width: 100%;
    flex-direction: column;
  }
`;

export const BlueTitle = styled("p")`
  font-size: 28px;
  text-align: center;
  font-weight: 700;
  padding: 10px 0;

  @media (max-width: 768px) {
    font-size: 18px;
  }
`;
export const HomeChartCard = styled("div")`
  padding: 50px 0;
  h2,
  h6 {
    text-align: center;
    color: rgba(0, 0, 0, 0.85);
  }

  h2 {
    color: rgba(0, 0, 0, 0.85);
    font-size: 36px;
    font-weight: 700;
  }

  h6 {
    font-size: 24px;
    line-height: 50px;
    font-weight: 400;
  }
`;

export const CardBottombg = styled("img")`
  position: absolute;
  bottom: 0;
  right: 0;
  width: 100%;
  z-index: -1;
`;

export const HomeSloganCard = styled("div")`
  height: 100%;
  background: url("/images/card_bottom_bg1.png") bottom right no-repeat;
  background-color: #fff;
  border-radius: 50px;
  padding: 50px 0;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;

  h6 {
    color: rgba(0, 0, 0, 0.85);
    font-size: 24px;
    font-weight: 700;
    line-height: 30px;
  }

  p {
    color: rgba(0, 0, 0, 0.85);
  }

  button {
    margin-left: auto;
    margin-right: auto;
    margin-top: 20px;
  }
`;

export const IntroCardTitle = styled("div")`
  font-size: 28px;
  font-weight: 600;
  color: #38c3a8;
  margin-bottom: 3px;
`;

export const IntroCardContent = styled("div")`
  font-size: 18px;
  font-weight: 400;
  margin-bottom: 90px;
`;

export const ChartCards = styled("div")`
  display: flex;
  justify-content: space-between;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

export const ChartCard = styled("div")`
  width: 49%;
  background-color: #fff;
  border-radius: 30px;
  padding: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  .MuiTableCell-head {
    color: rgba(0, 0, 0, 0.85);
    font-size: 18px;
    font-weight: 600;
    line-height: 18px;
  }

  .MuiTableCell-body {
    color: rgba(0, 0, 0, 0.85);
    font-size: 16px;
    font-weight: 500;
    line-height: 18px;
  }

  .MuiTableContainer-root {
    border: none;
    box-shadow: none;
  }

  @media (max-width: 768px) {
    width: 100%;
    margin-bottom: 10px;
  }
`;

export const ChartCardIntro = styled("p")`
  margin: 0;
  padding-left: 15px;
  color: rgba(133, 124, 124, 0.85);

  a {
    color: rgba(133, 124, 124, 0.85);
    text-decoration: none;

    :hover {
      color: #38c3a8;
      transition: all 0.3s;
    }
  }
`;

export const AICardSection = styled("div")`
  padding: 80px 0;
`;
export const AICardsTitle = styled("div")`
  text-align: center;
  margin-bottom: 100px;

  h4 {
    color: rgba(0, 0, 0, 0.85);
    font-size: 36px;
    font-weight: 700;
  }
  p {
    color: rgba(0, 0, 0, 0.85);
    font-size: 24px;
  }

  @media (max-width: 768px) {
    margin-bottom: 40px;
    h4 {
      font-size: 32px;
    }

    p {
      font-size: 22px;
    }
  }
`;

export const AICards = styled("div")``;

interface AICardProps {
  color: string;
}
export const AICard = styled("div")<AICardProps>`
  background: ${({ color }) => color};
  padding: 40px 30px;
  border-radius: 30px;

  min-height: 360px;
  img {
    max-width: 100%;
  }

  h5 {
    color: rgba(0, 0, 0, 0.85);
    font-size: 24px;
    margin-bottom: 15px;
  }

  p {
    color: rgba(0, 0, 0, 0.85);
    font-size: 16px;
    margin-bottom: 15px;
  }

  @media (max-width: 768px) {
    padding: 20px 30px;
    min-height: auto;
  }
`;

export const FeatureCard = styled("div")<{
  $dark?: boolean;
}>`
  display: flex;
  gap: 12px;
  align-items: center;
  background-color: ${({ $dark }) => ($dark ? "#48539B" : "#fff")};
  color: ${({ $dark }) => ($dark ? "#fff" : "inherit")};
  height: ${({ $dark }) => ($dark ? "120%" : "100%")};
  bottom: ${({ $dark }) => ($dark ? "20%" : 0)};
  position: relative;
  border-radius: 16px;
  padding: 24px 22px;
  font-size: ${({ $dark }) => ($dark ? "18px" : "14px")};
  box-shadow: ${({ $dark }) =>
    $dark ? "0 6px 12px rgba(0, 0, 0, .2)" : "0 6px 12px rgba(0, 0, 0, .05)"};

  i {
    display: flex;
    flex-shrink: 0;
    width: 44px;
    height: 44px;
    align-items: center;
    justify-content: center;
    background-color: ${({ $dark }) => ($dark ? "#E6C044" : "#48539B")};
    color: #fff;
    border-radius: 50%;
    /* margin-bottom: 12px; */
  }

  svg {
    width: 20px;
    height: 20px;
  }

  @media (max-width: 768px) {
    height: 100%;
    bottom: 0;
  }
`;

export const SocialMediaFixed = styled("div")`
  position: fixed;
  right: 15px;
  border-radius: 10px;
  background: #6289e9;
  opacity: 0.65;
  z-index: 9999;
  bottom: 5vh;
  padding: 30px 10px;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (max-width: 768px) {
    right: 5px;
    bottom: 10px;
  }
`
export const Line = styled("div")`
  width: 1px;
  height: 100px;
  background-color: white;

  @media (max-width: 768px) {
    height: 20px;
  }
`

export const SocialMediaLinks = styled("div")`

`

export const SocialMediaLink = styled("div")`
  margin: 15px 0;
  width: 40px; 
  height: 40px; 
  border: 2px solid white;
  border-radius: 50%;
  cursor: pointer;
  transition: border-color 0.5s ease;

  &:hover {
    border-color: #333;
  }

  svg {
    font-size: 20px;
    color: white;
    transition: color 0.5s ease
  }

  &:hover svg{
    color: #333;
  }

  i {
    display: flex;
    height: 100%;
    align-items: center;
    justify-content: center;
  }

  @media (max-width: 768px) {
    width: 25px; 
    height: 25px; 

    svg {
      font-size: 12px;
    }
  }
`

export const SocialMediaBlock = styled("div")`
  background: #6289e9;
  opacity: 0.65;
  display: flex;
  align-items: center;

  @media (max-width: 768px) {
    right: 5px;
    bottom: 10px;
  }
`