import React, { useEffect, useState } from "react";
import { Typography } from "@mui/material";
import { capitalizeString } from "../CommonFun";
import { CityFaqSection } from "../styles/saving-section.styled";
import { fetchUSNPlans } from "../../api/city";
import { PROVIDER_ID } from "../../config";
import { Utilities } from "../../types";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from "../styles/MyAccordion";
import { SolarEnergyFAQSText } from "../../common/data";

interface CityGuideSectionProps {
  city: string;
  zipcode: string;
  utility: Utilities;
  gasUtility: Utilities;
  type: string;
}

export const CityGuideSection: React.FC<CityGuideSectionProps> = ({
  city,
  zipcode,
  utility,
  gasUtility,
  type,
}) => {
  const [rates, setRates] = useState({
    "500": 0,
    "1000": 0,
    "2000": 0,
  });
  const [, setLoading] = useState(false);

  useEffect(() => {
    if (utility && zipcode) {
      getAllAverageRate();
    }
  }, [utility, zipcode]);

  const getAllAverageRate = async () => {
    setLoading(true);
    const state_id = utility.state_id ? utility.state_id : "TX";

    try {
      const [rate500, rate1000, rate2000] = await Promise.all([
        fetchPlans(state_id, 500),
        fetchPlans(state_id, 1000),
        fetchPlans(state_id, 2000),
      ]);

      const _rates = { ...rates };
      if (rate500) _rates["500"] = rate500;
      if (rate1000) _rates["1000"] = rate1000;
      if (rate2000) _rates["2000"] = rate2000;
      setRates(_rates);
    } catch (error) {
      console.error("Error fetching plans:", error);
      // Handle the error appropriately
    } finally {
      setLoading(false);
    }
  };

  const fetchPlans = async (state: string, usage: number) => {
    const res = await fetchUSNPlans(
      zipcode,
      type === "gas" ? gasUtility?.utility_code : utility?.utility_code,
      "12",
      new Array(12).fill(usage),
      state,
      type === "renewable"
    );

    if (res && Number(res.status) === 1) {
      const { plans } = res.response;
      return getTotalPrice(plans);
    }
    return null;
  };

  const getTotalPrice = (plans:any) => {
    const lowestPriceProvider = plans[0] ? plans[0].provider_id : PROVIDER_ID;
    const filteredPlans = plans.filter(
      (plan:any) => plan.provider_id === lowestPriceProvider
    );
    const total_price = filteredPlans.reduce(
      (acc:any, val:any) => acc + Number(val.rate),
      0
    );
    return Math.ceil((total_price / filteredPlans.length) * 100) / 100;
  };

  const cityName = capitalizeString(city);

  const AllEnergyFAQSText = [
    {
      q: `How can I review all electricity plans in ${cityName}?`,
      a: `Our platform offers a comprehensive and easy-to-use feature that lets you compare different electricity providers in ${cityName}. Simply input your address/zipcode and you’ll see the available plans, including their contract terms and costs.`,
    },
    {
      q: `What is the standard contract term for electricity plans in ${cityName}`,
      a: `Most electricity providers in ${cityName} offer plans with a standard contract term of 12 months. Many of them also offer fixed rate plans that can last anywhere between 3 months to 3 or more years. These plans provide stable, year-long service to their customers.`,
    },
    {
      q: `What is the cost per kWh for 500kWh usage in ${cityName}?`,
      a: `For 500kWh usage, the cost per kWh in ${cityName} currently averages ${rates["500"]}¢/kWh. This rate is standard across many electricity plans.`,
    },
    {
      q: `How much would it cost per kWh for 1000kWh usage in ${cityName}?`,
      a: `An average of electricity plans in ${cityName} typically charge ${rates["1000"]}¢/kWh for 1000kWh usage. The competitive market rate helps ensure fair and affordable pricing for customers.`,
    },
    {
      q: `What is the cost per kWh for 2000kWh usage in ${cityName}?`,
      a: `If your usage amounts to 2000kwh per month in ${cityName}, the average electricity plan rate is ${rates["2000"]}¢/kWh.`,
    },
    {
      q: `Are there any green or renewable energy plans in ${cityName}?`,
      a: `Many electricity providers in ${cityName} offer green energy options. These plans provide a portion or all of your electricity from renewable resources, like wind or solar power.`,
    },
    {
      q: `How do I switch my electricity plan in ${cityName}?`,
      a: `Can be done online through our website. Be aware switching your electricity plan in ${cityName} can often be done online or over the phone, through the provider's customer service. Be aware that early termination fees may apply if you switch before your current contract is up.`,
    },
    {
      q: `What should I consider when choosing electricity plans in ${cityName}?`,
      a: `When comparing electricity plans in ${cityName}, consider the contract term, price per kWh based on your usage, any extra fees, and the reputation of the provider. By weighing these factors, you can find a plan that fits your needs and budget.`,
    },
  ];

  const GreenEnergyFAQSText = [
    {
      q: `How do Green & Renewable Energy plans work?`,
      a: `When you sign up for a green energy plan, your electricity usage is matched with the equivalent amount of renewable energy, which is fed into the grid. This may come at a slightly higher cost per kWh than conventional energy plans due to the higher production costs of renewable energy.`,
    },
    {
      q: `What are the benefits of choosing Green & Renewable Energy plans?`,
      a: `Choosing Green & Renewable Energy plans helps to reduce your carbon footprint, support renewable energy industries, and decrease greenhouse gas emissions. As technology advances, these plans are becoming increasingly cost-competitive.`,
    },
    {
      q: `What is the cost per kWh for Green & Renewable Energy plans for 500kWh usage in ${cityName}?`,
      a: `For 500kWh usage, the cost per kWh in ${cityName}  for Green & Renewable Energy plans is ${rates["500"]}¢/kWh, Keep in mind, this rate reflects the use of renewable sources and contributes towards a more sustainable environment.`,
    },
    {
      q: `How much would it cost per kWh for Green & Renewable Energy plans for 1000kWh usage in ${cityName}?`,
      a: `For 1000kWh usage, the cost per kWh in ${cityName} for Green & Renewable Energy plans is ${rates["1000"]}¢/kWh. Keep in mind, this rate reflects the use of renewable sources and contributes towards a more sustainable environment.`,
    },
    {
      q: `What is the cost per kWh for Green & Renewable Energy plans for 2000kWh usage in ${cityName}?`,
      a: `For 2000kWh usage, the cost per kWh in ${cityName} for Green & Renewable Energy plans is ${rates["2000"]}¢/kWh. Keep in mind, this rate reflects the use of renewable sources and contributes towards a more sustainable environment.`,
    },
    {
      q: `Are Green & Renewable Energy plans more expensive in ${cityName}?`,
      a: `The cost of Green & Renewable Energy plans may be slightly higher than standard plans due to the higher costs of producing renewable energy. However, many find the environmental benefits worth the additional cost.`,
    },
    {
      q: `Which providers offer Green & Renewable Energy plans in ${cityName}?`,
      a: `Several electricity providers in ${cityName} offer green energy plans. Check the details of each plan carefully to learn more about the percentage of electricity sourced from renewable resources.`,
    },
    {
      q: `What should I consider when choosing electricity plans in ${cityName}?`,
      a: `When comparing electricity plans in ${cityName}, consider the contract term, price per kWh based on your usage, any extra fees, and the reputation of the provider. By weighing these factors, you can find a plan that fits your needs and budget.`,
    },
  ];

  const SolarText = SolarEnergyFAQSText;

  const FAQText =
    type === "renewable"
      ? GreenEnergyFAQSText
      : type === "solar-energy"
        ? SolarText
        : AllEnergyFAQSText;

  return (
    <CityFaqSection>
      <h2 className="faq-title">Your Power Choice Guide</h2>
      <p className="faq-intro">
        Comparing and Choosing Electricity Plans in {capitalizeString(city)},{" "}
        {zipcode}
      </p>
      {/* <Grid
        container
        position="relative"
        alignItems="center"
        justifyContent="space-between"
        sx={{ pt: 3, pb: 3 }}
      >
        {FAQText.map((item, index) => (
          <Grid
            item
            xs={12}
            md={6}
            display="flex"
            flexDirection="column"
            style={{ alignItems: "center" }}
            key={index}
            mt={{ xs: 2, md: 5 }}
            padding={{ xs: 0, md: "10px 30px" }}
          >
            <h5>{item.q}</h5>
            <PopCard
              sm
              same
              green={type === 2}
              className={css`
                transform: rotateX(180deg);
                margin-top: 20px !important;
              `}
            >
              <div
                style={{
                  transform: "rotateX(180deg)",
                  display: "flex",
                  alignItems: "center",
                  fontSize: "0.95rem",
                }}
              >
                {item.a}
              </div>
            </PopCard>
          </Grid>
        ))}
      </Grid> */}

      <>
        {FAQText.map((item, index) => (
          <Accordion key={index} style={{ background: "transparent" }}>
            <AccordionSummary>
              <Typography>{item.q}</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>{item.a}</Typography>
            </AccordionDetails>
          </Accordion>
        ))}
      </>
    </CityFaqSection>
  );
};
