import React from "react";
// import { useTranslation } from "react-i18next";

import NumberFormat from "react-number-format";
import { MonthlyBreakdownProps } from "../../../types";

export const MonthlyBreakdownTable: React.FC<MonthlyBreakdownProps> = ({
  usage,
  monthlyPrice,
  hidePrice,
}) => {
  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  if (!usage) return null;

  return (
    <div className="breakdown-table-wrapper">
      <div className="breakdown-table-container">
        <table cellSpacing="0">
          <thead>
            <tr>
              {months.map((val, idx) => (
                <th
                  key={idx}
                  className={monthlyPrice[idx + 1] ? "" : "disabled"}
                >
                  {val}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            <tr>
              {usage &&
                months.map((key, idx) => {
                  if (monthlyPrice[idx + 1]) {
                    return (
                      <td key={idx}>
                        <NumberFormat
                          value={usage[idx + 1]}
                          displayType="text"
                          thousandSeparator
                          suffix="kWh"
                        />
                      </td>
                    );
                  }
                  return (
                    <td key={idx} className="disabled">
                      -
                    </td>
                  );
                })}
            </tr>
            <tr>
              {months.map((key, idx) => {
                if (monthlyPrice[idx + 1]) {
                  if (hidePrice) {
                    return <td key={idx}>N/A</td>;
                  }
                  return (
                    <td key={idx}>
                      <NumberFormat
                        value={monthlyPrice[idx + 1]}
                        displayType="text"
                        thousandSeparator
                        prefix="$"
                      />
                    </td>
                  );
                }
                return (
                  <td key={idx} className="disabled">
                    -
                  </td>
                );
              })}
            </tr>
            <tr>
              {usage &&
                months.map((key, idx) => {
                  if (monthlyPrice[idx + 1]) {
                    if (hidePrice) {
                      return <td key={idx}>N/A</td>;
                    }
                    return (
                      <td key={idx}>
                        <NumberFormat
                          value={
                            (parseFloat(monthlyPrice[idx + 1]) /
                              parseFloat(usage[idx + 1])) *
                            100
                          }
                          displayType="text"
                          thousandSeparator
                          suffix="¢"
                          decimalScale={2}
                        />
                      </td>
                    );
                  }
                  return (
                    <td key={idx} className="disabled">
                      -
                    </td>
                  );
                })}
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};
