import React from "react";
import { useTranslation } from "react-i18next";
import { RenewableFilterProps } from "../../../types";
import { LoadingRow } from "../LoadingRow";
import { CheckboxItem } from "./checkbox-item";

export const RenewableFilter: React.FC<RenewableFilterProps> = ({
  initializeFilter,
  value,
  loading,
  handleClick,
}) => {
  const { t } = useTranslation("plan");
  if (initializeFilter) return <LoadingRow size="sm" />;

  return (
    <div className="plans-filter-options">
      <CheckboxItem
        label={t("Show 100% Green Plans")}
        value="-1"
        key={-1}
        checked={value}
        handleClick={handleClick}
        disabled={loading}
        full
      />
    </div>
  );
};
