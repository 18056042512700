import React from "react";
import { useParams } from "react-router-dom";
import { Helmet } from "react-helmet";
import moment from "moment";
import { SolarAddress } from "../components/solar-section/solar-address";
import Header from '../components/header'

const Solar = () => {
  const { state, city, zipcode, street } = useParams<{
    state: string;
    city: string;
    zipcode: string;
    street: string;
  }>();

  return (
    <>
      <Helmet>
        <title>
          {`Solar Electricity Cost in ${street}, ${city}, ${state}, ${zipcode}: ${moment().format(
            "YYYY"
          )} Electric Rates`}
        </title>
        <meta name="keywords" content="solar panels cost, storage cost" />
        <meta
          name="description"
          content={`Discover how much solar panels cost in ${street}, ${city}, ${state}, ${zipcode} based on actual solar quote data, and determine if investing in solar energy is worth it.`}
        />
      </Helmet>
      <Header />
      <SolarAddress state={state} city={city} zipcode={zipcode} street={street} />
    </>
  );
};

export default Solar;
