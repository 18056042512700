import styled from "styled-components";

export const CurtainWrapper = styled("div")`
  background: linear-gradient(to bottom, rgba(56, 195, 168, 0.2), rgba(56, 195, 168, 0));
  background-size: 100% 20%;
  background-repeat: no-repeat;
  /* padding-top: 30px; */
  h2 {
    font-size: 18px;
    font-weight: 400;
  }
`;

export const AddressTitle = styled("div")`
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0) 0%,
    #ffffff 69.27%
  );
  padding: 50px 0px 50px;

  h3 {
    color: #38c3a8;
  }
  img {
    width: 30px;
    margin-right: 10px;
  }
  @media (max-width: 768px) {
    padding: 55px 25px;
  }
`;

interface ChartProps {
  green?: boolean;
}
export const ChartSection = styled("div")<ChartProps>`
  padding: 90px 0;

  .Mui-focused .MuiOutlinedInput-notchedOutline {
    border-color: ${({ green }) => (green ? "#3baf41" : "#38c3a8")};
  }

  .css-167qk0q-MuiButtonBase-root-MuiButton-root {
    background: ${({ green }) => (green ? "#54C581" : "#38c3a8")};
    border: ${({ green }) =>
      green ? "1px solid #54C581" : "1px solid rgba(56, 195, 168, 0.5)"};
  }
  .css-14x7n0z-MuiButtonBase-root-MuiButton-root {
    color: ${({ green }) => (green ? "#54C581" : "#38c3a8")};
    border: ${({ green }) =>
      green ? "1px solid #54C581" : "1px solid rgba(56, 195, 168, 0.5)"};
    :hover {
      background: ${({ green }) => (green ? "#F0FCF3" : "#DFE7FF")};
      color: #fff;
    }
  }
  @media (max-width: 768px) {
    padding: 40px 0;
    .MuiButtonGroup-root,
    .HomePage_homepage_term_select__KckQc {
      height: 44px;
      margin-top: 0px;
    }
  }
`;

export const CardSection = styled("div")`
  .css-bhp9pd-MuiPaper-root-MuiCard-root {
    border-radius: 10px;
  }
  h5 {
    text-align: left;
    padding: 0 16px;
  }
  .googlemap-wrapper {
    height: 200px;
    width: 100%;
    display: flex;
    align-items: center;
    background-color: #f3f3f3;
    margin-bottom: 10px;
    border-radius: 10px;
    overflow: hidden;
  }

  .map-marker {
    display: inline-block;
    width: 22px;
    height: 30px;
    overflow: hidden;
    background: url("/images/pin.png") 0 0 no-repeat;
    background-size: cover;
    font-size: 0;
    position: absolute;
    top: 0;
    left: 50%;
    transform: translate(-50%, -100%);
  }

  .MuiCard-root {
    padding: 20px;
  }
  ul {
    display: flex;
  }
  li {
    display: flex;
    flex-direction: column;
    margin-right: 10px;
    &:last-child {
      margin-right: 0;
    }
  }

  label {
    font-weight: 600;
    text-align: left;
  }
  span {
    text-align: left;
  }

  .house {
    span {
      font-size: 1.3rem;
    }
  }

  img {
    width: 35px;
  }
  @media (max-width: 768px) {
    .css-bhp9pd-MuiPaper-root-MuiCard-root {
      margin-bottom: 10px;
    }
  }
`;
