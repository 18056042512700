import { useEffect, useState,createContext } from "react";
import { useParams } from "react-router-dom";
import { capitalizeString } from "../components/CommonFun";
import CitySection from "../components/city/CitySection";
import ProviderSection from "../components/city/ProviderSection";
import useGetState from "../components/hooks/useGetState";
import { fetchAllCityList,fetchState } from "../api/api";
import moment from "moment";
import { Helmet } from "react-helmet";
import Header from '../components/header'
export const CityPageContext = createContext<any>(null);

const CityPage = () => {
  const { city, state, zipcode, type } = useParams();
  const [energyType, setEnergyType] = useState<string>("all");
  const [stateArray, setStateArray] = useState<any>([]);
  const [cityArray, setCityArray] = useState<any>([]);
  
  const selectState = useGetState(state);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  useEffect(() => {
    const fetchData = async () => {
      const res1 = await fetchState();
      const res2 = await fetchAllCityList();
      if (res1 && res1.code === 200) setStateArray(res1.data);
      if (res2 && res2.code === 200) setCityArray(res2.data);
    };

    fetchData();

    if (!type || type === "undefined") {
      setEnergyType("all");
    } else if (type === "renewable") {
      setEnergyType("renewable");
    } else if (type === "gas") {
      setEnergyType("gas");
    } else if (type === "solar-energy") {
      setEnergyType("solar-energy");
    } else {
      setEnergyType("provider");
    }
  }, [type]);

  const getHelemt = () => {
    const stateId = selectState?.state_id || "";
    const cityName = capitalizeString(city);
    const StateName = selectState ? selectState.state_name : capitalizeString(state);

    let helemt = {
      title: `Compare and find the right plan ${capitalizeString(city)} ${StateName} ${zipcode}`,
      keywords: `${capitalizeString(city)} electricity rates, electric rates, best ${capitalizeString(city)} electricity rate, compare electricity rates, find electricity rates, ${capitalizeString(city)} ${StateName} ${zipcode}`,
      description: `Compare electricity rates for ${capitalizeString(city)} (${zipcode}). Research over 7000 provider reviews and find the best electricity rate in ${capitalizeString(city)} ${StateName} ${zipcode}.`,
    };

    if (type) {
      if (type === "renewable") {
        helemt = {
          title: `Affordable Green & Renewable Energy in ${capitalizeString(city)}, ${StateName} ${zipcode}`,
          keywords: `${capitalizeString(city)} ${StateName} ${zipcode}, green energy in ${capitalizeString(city)}, renewable energy in ${capitalizeString(city)}, ${selectState ? selectState?.state_name : StateName} green energy, ${selectState ? selectState?.state_name : StateName} renewable energy, energy provider ${capitalizeString(city)} ${StateName}`,
          description: `Discover affordable green and renewable energy options in ${capitalizeString(city)}, ${StateName} ${zipcode} . EIQShopping provides the best energy deals suited to your needs.`,
        };
      } else if (type === "solar-energy") {
        helemt = {
          title: `Solar Electricity Cost in ${capitalizeString(city)}, ${selectState?.state_id} ${zipcode}: ${moment().year()} Electric Rates`,
          keywords: `solar panels cost, storage cost, ${capitalizeString(city)}, ${selectState?.state_id} ${zipcode}`,
          description: `Discover how much solar panels cost in ${capitalizeString(city)}, ${selectState?.state_id} ${zipcode} based on actual solar quote data, and determine if investing in solar energy is worth it.`,
        };
      } else {
        const provider = type;
        if (provider.includes("-vs-")) {
          const [provider1, provider2] = provider.split("-vs-") || [];
          const providerName1 = capitalizeString(provider1);
          const providerName2 = capitalizeString(provider2);

          helemt = {
            title: `Top & Best Electricity Rates: Compare ${providerName1} vs ${providerName2} in ${stateId} ${cityName} ${zipcode}`,
            keywords: `top best electricity rates compare, compare ${providerName1} vs ${providerName2}`,
            description: `Explore and compare the top and best electricity rates from ${providerName1} vs ${providerName2} in ${stateId} ${cityName} ${zipcode} on Personalized Energy. Discover affordable energy plans tailored to save your bill.`,
          };
        } else {
          const providerName = capitalizeString(provider);
          helemt = {
            title: `5 Top Electricity Rates: Compare ${providerName}’s Cheapest Energy Plans in ${stateId} ${cityName} ${zipcode}`,
            keywords: `${providerName}, best electric rates, top electricity plans, compare energy costs`,
            description: `Find the top-rated and cheapest ${providerName} electricity rates in ${stateId} ${cityName} ${zipcode}. Compare best energy plans and save on your bill only on EIQShopping.`,
          };
        }
      }
    }

    return helemt;
  };

  const HelemtText = getHelemt();

  return (
    <>
      <Helmet>
        <title>{HelemtText.title}</title>
        <meta name="keywords" content={HelemtText.keywords} />
        <meta name="description" content={HelemtText.description} />
      </Helmet>
      <CityPageContext.Provider value={{ stateArray, cityArray }}>
        <Header />
        {/* <ProviderSection /> */}
        {energyType === "provider" ? <ProviderSection /> : <CitySection />}
      </CityPageContext.Provider>
    </>
  );
};

export default CityPage;

export const getServerSideProps = async () => {
  let stateArray = [];
  let cityArray = [];
  const res1: any = await fetchState();
  const res2: any = await fetchAllCityList();

  if (res1 && res1.code === 200) stateArray = res1.data;

  if (res2 && res2.code === 200) cityArray = res2.data;

  return {
    props: {
      stateArray,
      cityArray,
    },
  };
};
