import { Typography } from "@mui/material"
import { SolarEnergyFAQSText } from "../../common/data";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from "../styles/MyAccordion";
import { CityFaqSection } from "../styles/saving-section.styled";
import { capitalizeString } from "../CommonFun";

export const SolarFaq = (props: any) => {
  const { city, zipcode } = props;

  const cityName = capitalizeString(city);

  return (
    <CityFaqSection>
      <h2 className="faq-title">Your Power Choice Guide</h2>
      <p className="faq-intro">
        {` Comparing and Choosing Electricity Plans in ${cityName}, ${zipcode}`}
      </p>
      <>
        {SolarEnergyFAQSText.map((item, index) => (
          <Accordion key={index}>
            <AccordionSummary>
              <Typography>{item.q}</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>{item.a}</Typography>
            </AccordionDetails>
          </Accordion>
        ))}
      </>
    </CityFaqSection>
  );
};