import { styled } from "@mui/system";
import { Link } from "react-router-dom";

export const EnergyMenuHeader = styled("div")`
  width: 100%;
  min-height: 3rem;
  background: #38c3a8;
  position: relative;
  z-index: 1;
`;
export const EnergyMenu = styled("div")`
  display: flex;
  align-items: center;
  justify-content: center;
`;

interface MenuItemProps {
  active?: boolean;
}
export const EnergyMenuItem = styled("div")<MenuItemProps>`
  font-size: 18px;
  font-weight: ${({ active }) => (active ? "500" : "400")};
  color: #fff;
  padding: 14px 30px;
  cursor: pointer;
  background-color: ${({ active }) => (active ? "#0000004d" : "transparent")};

  @media (max-width: 768px) {
    font-size: 16px;
    padding: 10px;
    text-align: center;
  }
`;
interface CityH1Props {
  green?: boolean;
  textAlign?: string;
}
export const CityTitleH1 = styled("h1")<CityH1Props>`
  color: ${({ green }) => (green ? "#54C581" : "#38c3a8")};
  font-size: 1.5rem;
  text-align: ${({ textAlign }) => textAlign || "center"};
`;

interface CardProps {
  green?: boolean;
}
export const SeePriceCard = styled("div")<CardProps>`
  background-color: ${({ green }) => (green ? "#F0FCF3" : "#e8f7f1")};
  min-height: 300px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 30px;

  .Mui-focused .MuiOutlinedInput-notchedOutline {
    border-color: ${({ green }) => (green ? "#3baf41" : "#38c3a8")};
  }
  img {
    max-width: 40px;
    margin-bottom: 20px;
  }

  h3 {
    color: #4a4a4a;
    font-size: 24px;
    font-weight: 700;
    margin-bottom: 20px;
  }
`;

export const FaqProviders = styled("div")`
  display: flex;
  padding-left: 0;
  gap: 20px;
  flex-wrap: wrap;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

interface BtnProps {
  green?: boolean;
}
export const FaqProviderBtn = styled(Link)<BtnProps>`
  background: ${({ green }) => (green ? "#F0FCF3" : "#e8f7f1")};
  border-radius: 20px;
  white-space: nowrap;
  display: inline-block;

  @media (max-width: 768px) {
    text-align: center;
    margin-bottom: 10px;
  }
`;