import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import type { CheckboxItemProps } from "../../../types";
import {
  faCheckCircle,
  faCheckSquare,
  faCircle,
  faSquare,
} from "@fortawesome/free-solid-svg-icons";

export const CheckboxItem: React.FC<CheckboxItemProps> = ({
  label,
  value,
  checked,
  handleClick,
  onlyread,
  disabled,
  isRadio,
  full,
  error,
  id,
}) => {
  if (value === "0") return null;
  return (
    <div
      className={`checkbox-item ${onlyread ? "onlyread" : ""} ${
        full ? "full" : ""
      }`}
      onClick={() => {
        if (!disabled && !onlyread) handleClick(value);
      }}
      data-test-id={id || ""}
    >
      {checked ? (
        <FontAwesomeIcon
          icon={isRadio ? faCheckCircle : faCheckSquare}
          style={{ color: "#38c3a8", fontSize: 20 }}
          width={20}
        />
      ) : (
        <FontAwesomeIcon
          icon={isRadio ? faCircle : faSquare}
          style={{ color: "#ddd", fontSize: 20 }}
          width={20}
        />
      )}
      <span className={error ? "error" : ""}>{label}</span>
    </div>
  );
};
