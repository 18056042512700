import { useEffect, useState } from "react";

const useIsTexas = (props: any) => {
  const [isTexas, setIsTexas] = useState<any>();

  useEffect(() => {
    BooleanIsTexas(props);
  }, [props]);

  useEffect(() => {
    sessionStorage.setItem("isTx", isTexas);
  }, [isTexas]);

  function BooleanIsTexas(str: any) {
    setIsTexas(str === "tx" || str === "texas");
  }

  return isTexas;
};
export default useIsTexas;
