type PUCT_number_type = {
    [key: string]: string;
}

export const PUCT_number: PUCT_number_type = {
    '210': '10259', // Pulse
    '194': '10105', // APGE
    '183': '10098', // Champion
    '239': '10279', // Rhythm
    '258': '10293', // Value Power
    '196': '10014', // Constellation
};

export const CityProviders = [
    { name: "Reliant Energy", value: "reliant-energy" },
    { name: "Rhythm Energy", value: "rhythm-ops" },
    { name: "Cirro Energy", value: "cirro-energy" },
    { name: "Green Mountain Energy", value: "green-mountain-energy" },
    { name: "Pulse Power", value: "pulse-power-llc" },
    // { name: "AP Gas & Electric (APG&E)", value: "apg-and-e" },
];

export const SolarEnergyFAQSText = [
    {
        q: "How does solar photovoltaic energy work?",
        a: "Photovoltaic panels absorb sunlight and convert solar radiant energy into direct current. The PV inverter converts the DC power into AC power.The generated power can either be stored directly in the battery or incorporated into the grid via an inverter.",
    },
    {
        q: "How long do solar systems last?",
        a: "The lifespan of a solar installation depends on the local environment and the durability of the system you purchase. A high quality solar installation can last more than 30 years.",
    },
    {
        q: "What are the risks to getting solar power?",
        a: "Before deciding to go solar, make sure to select a provider with a good track record and an understanding of how to install and maintain solar systems. When properly installed and maintained, solar panels should not pose any danger to your home. Issues related to the installation and operation of a solar system are relatively uncommon, but can include PV systems catching fire, roof leaks, and hail and/or wind damage. For more information, talk with your provider.",
    },
    {
        q: "Will my projected solar savings match actual savings throughout the life of system?",
        a: "Actual savings can vary from projected savings for a variety of reasons. Fast-growing trees can shade solar installations, reducing production over time. Utilities can change how much they charge their customers for electricity, changing the savings from solar. Policies that are beneficial to solar installations may change (e.g., Net Metering). For states without net metering, savings may also vary by the amount of solar electricity consumed in the household compared to the amount exported to the grid.",
    },
    {
        q: "What makes a roof good for solar?",
        a: "The best solar roofs have large areas with South or Southwest exposure, little shade, and a roof in good condition.",
    },
];

export const EnergyOptions = [
    {
      label: "All Energy",
      value: "all",
      key: 1,
    },
    {
      label: "Green & Renewable Energy",
      value: "renewable",
      key: 2,
    },
    // {
    //     label:'Gas',
    //     key:3
    // }
    {
      label: "Solar Energy",
      value: "solar-energy",
      key: 5,
    },
  ];