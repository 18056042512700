import { useEffect, useState } from "react";
import { fetchUSNUtility } from "../../api/city";

const defaultData = {
  electricity: [],
  gas: [],
};

const useUtilities = (zipcode: string) => {
  const [data, setData] = useState(defaultData);

  useEffect(() => {
    if (!zipcode) return;

    getData();
  }, [zipcode]);

  const getData = () => {
    fetchUSNUtility(zipcode).then((res) => {
      if (res?.status === "1" && res?.response?.length > 0) {
        const { response } = res;
        const isTx = response[0].state_id === "TX";
        if (isTx) {
          setData({ ...data, electricity: response, gas: [] });
        } else {
          setData({
            ...data,
            electricity: response.filter(
              (item: any) => item.commodity_name === "Electric"
            ),
            gas: response.filter((item: any) => item.commodity_name === "Gas"),
          });
        }
      } else {
        setData(defaultData);
      }
    });
  };

  return { data };
};

export default useUtilities;
