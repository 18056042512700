import {
  Box,
  Button,
  CircularProgress,
  Container,
  Grid,
  TablePagination,
} from "@mui/material";
import throttle from "lodash/throttle";
import React, { useEffect, useState } from "react";
import { css } from "@emotion/css";
import { Image } from "react-bootstrap";
import Select from "react-select";
import {
  BlueTitle,
  CitySelectList,
  Selcetpage,
  SelectBar,
} from "./styles/saving-section.styled";
import { fetchState, fetchIPAddress } from "../api/api";
import { Link } from "react-router-dom";

const options = [
  {
    label: "A-F",
    checked: false,
    index: 1,
    arr: [] as any[],
  },
  {
    label: "G-L",
    checked: false,
    index: 2,
    arr: [] as any[],
  },
  {
    label: "M-R",
    checked: false,
    index: 3,
    arr: [] as any[],
  },
  {
    label: "S-Z",
    checked: false,
    index: 4,
    arr: [] as any[],
  },
  {
    label: "ALL",
    checked: true,
    index: 5,
    arr: [] as any[],
  },
];

const SelectCity = (props: any) => {
  const [page, setPage] = useState(0);
  const [cityList, setCityList] = useState<any>([]);
  const [selectCities, setSelectCities] = useState<any>([]);
  const [showCities, setShowCities] = useState<any>([]);
  const [state, setState] = useState<any>();
  const [stateList, setStateList] = useState<any>([]);
  const [loading, setLoading] = useState(false);
  const [searchValue, setSearchValue] = useState("");

  const { stateArray, cityArray, selectedCity } = props;

  useEffect(() => {
    if (!selectedCity) return;
    const i = cityArray.findIndex(
      (item: any) => item?.city?.toLowerCase() === selectedCity?.toLowerCase()
    );
    setCityList(cityArray.slice(i, i + 20));
  }, [selectedCity]);

  useEffect(() => {
    if (selectedCity) return;
    if (!state && stateArray)
      return setCityList(cityArray.find((item: any) => item.state_id === "TX"));
    getList();
  }, [state]);

  useEffect(() => {
    if (stateArray) {
      getIpAddress(stateArray);
    } else {
      getState();
    }
  }, [stateArray]);

  useEffect(() => {
    if (selectedCity) {
      setShowCities(cityList);
      setLoading(false);
      return;
    }
    if (cityList.length > 0) {
      getcities();
    }
  }, [cityList]);

  const getList = throttle(() => {
    setLoading(true);
    setCityList(cityArray.filter((item: any) => item.state_id === state.state_id));
  }, 2000);

  const getState = () => {
    fetchState().then((res) => {
      if (res && res.code === 200) {
        setStateList(res.data);
        getIpAddress(res.data);
        setState(res.data[0]);
        localStorage.setItem("state_list", JSON.stringify(res.data));
      }
    });
  };

  const selectStyles = {
    control: (style: object) => ({
      ...style,
      borderColor: "#a0e4d1",
      borderRadius: ".3rem",
    }),
    singleValue: (style: object) => ({
      ...style,
      color: "#495057",
      fontSize: "1.25rem",
      lineHeight: 1.5,
    }),
    valueContainer: (style: object) => ({
      ...style,
      padding: "4px 1rem",
    }),
  };

  const getIpAddress = async (list: any) => {
    if (selectedCity) return;
    try {
      const ip = await fetchIPAddress();
      if (!ip) return setState(list[0]);
      const address = await fetch(`https://api.ipapi.is?q=${ip}`).then((res) =>
        res.json()
      );
      if (!address || address.location.country_cody !== "US")
        return setState(list[0]);
      const _state = list.find(
        (item: any) => item.state_name === address.location.state
      );
      if (_state) {
        return setState(_state);
      }
      return setState(list[0]);
    } catch (error) {
      console.log(error);
    }
  };

  const getcities = () => {
    if (cityList.length === 0) return;
    const afarr: any[] = [];
    const glarr: any[] = [];
    const mrarr: any[] = [];
    const szarr: any[] = [];
    const allarr: any[] = cityList;
    cityList.map((item: any) => {
      const name = item.city.slice(0, 1).charCodeAt();
      if (name >= 65 && name <= 70) return afarr.push(item);
      if (name >= 71 && name <= 76) return glarr.push(item);
      if (name >= 77 && name <= 82) return mrarr.push(item);
      if (name >= 83 && name <= 90) return szarr.push(item);
    });

    afarr.sort((a, b) => a.city.localeCompare(b.city));
    glarr.sort((a, b) => a.city.localeCompare(b.city));
    mrarr.sort((a, b) => a.city.localeCompare(b.city));
    szarr.sort((a, b) => a.city.localeCompare(b.city));
    allarr.sort((a, b) => a.city.localeCompare(b.city));

    options.map((item) => {
      if (item.index === 1) return (item.arr = afarr);
      if (item.index === 2) return (item.arr = glarr);
      if (item.index === 3) return (item.arr = mrarr);
      if (item.index === 4) return (item.arr = szarr);
      if (item.index === 5) return (item.arr = allarr);
    });

    setSelectCities(cityList);
    setShowCities(cityList.slice(0, 25));
    setLoading(false);
  };

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage);
    const newArr = selectCities.slice(newPage * 25, (newPage + 1) * 25);
    setShowCities(newArr);
  };
  const handleChangeIndex = (val: any) => {
    const checked = options.find((item) => item.checked === true);
    if (checked) checked.checked = false;
    const o = options.find((item) => item.label === val.label);
    if (o) o.checked = true;
    setSelectCities(o!.arr);
    setShowCities(o!.arr.slice(0, 25));
    setPage(0);
  };

  const onSelectCity = (val: any) => {
    localStorage.setItem("selectCity", JSON.stringify(val));
    // router.push(`/city?state=tx?city=${val.city}?zipcode=${val.zip_code}`)
    // const _state =
    //   state.state_id === "TX"
    //     ? "texas"
    //     : state.state_name.toLowerCase().replace(/ /g, "_");
    // router.push(`/city/${_state}/${val.city.toLowerCase()}/${val.zip_code}`);
  };

  const handleSearch = () => {
    if (!searchValue) {
      return;
    }
    const searchResults = cityList.filter((city: any) => {
      const cityName = city.city.toLowerCase();
      const searchTerm = searchValue.toLowerCase();
      return cityName.includes(searchTerm);
    });
    setShowCities(searchResults.slice(0, 25));
  };

  return (
    <CitySelectList>
      {!selectedCity && <Container>
        <SelectBar city={false}>
          <span>
            State: <b>{state?.state_id}</b>
          </span>
          <Select
            options={stateArray || stateList}
            value={state}
            onChange={(obj: any) => {
              if (obj && obj.state_id !== state?.state_id) {
                setCityList([]);
                setLoading(true);
                setState(obj);
              }
            }}
            className="state-select"
            instanceId="state-select"
            isSearchable
            styles={selectStyles}
            getOptionLabel={(option) => option.state_name}
            getOptionValue={(option) => option.state_id}
            maxMenuHeight={200}
          />
        </SelectBar>
      </Container>}

      {cityList.length > 0 && !loading && (
        <>
          <h5 style={{ textAlign: 'center' }}>Choose your city</h5>
          <SelectBar city>
            <span>{selectedCity ? 'Nearby cities' : 'City'}</span>
            {selectedCity ?
              <Link to="/service_areas">{'More City >'}</Link>
              : <Selcetpage>
                <ul>
                  {options.map((item: any) => (
                    <li
                      className={item.checked ? "checked-li" : "not-checked-li"}
                      key={item.label}
                      style={{ cursor: "pointer", marginRight: 15 }}
                      onClick={() => {
                        handleChangeIndex(item);
                      }}
                    >
                      {item.label}
                    </li>
                  ))}
                </ul>
                <Box
                  sx={{
                    display: "flex",
                  }}
                >
                  <input
                    value={searchValue}
                    onChange={(e) => {
                      setSearchValue(e.target.value);
                    }}
                    placeholder="Search And Press Enter..."
                    className={css`
                    border-radius: 22.5px;
                    border: none;
                    padding: 10px 20px;
                    font-size: 12px;
                    &:focus {
                      outline: none;
                    }
                  `}
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        handleSearch();
                      }
                    }}
                  />
                  {/* <Button
                    endIcon={
                      <Image
                        src="/images/icon_search.png"
                        alt=""
                        onClick={handleSearch}
                      />
                    }
                  /> */}
                </Box>
              </Selcetpage>}
          </SelectBar>
          <Box mt={3}>
            <Grid container display="flex" spacing={{ xs: 2, sm: 3 }}>
              {showCities.map((item: any) => (
                <Grid item key={item.city_id} xs={6} sm={4} md={3}>
                  <Link
                    className="city-button"
                    to={`/city/${item.state_name.toLowerCase().replace(/ /g, "_")}/${item.city.toLowerCase()}/${item.zip_code}`}
                    onClick={() => {
                      onSelectCity(item);
                    }}
                  >
                    <span>{item.city}</span>
                  </Link>
                </Grid>
              ))}
            </Grid>
          </Box>
          {!selectedCity && <TablePagination
            component="div"
            count={selectCities.length || 0}
            page={page}
            onPageChange={handleChangePage}
            rowsPerPage={25}
            className="selectCity-TablePagination"
          />}
        </>
      )}

      {cityList.length === 0 && loading && (
        <Box mt={5}>
          <CircularProgress sx={{ color: "#38c3a8" }}/>
        </Box>
      )}
    </CitySelectList>
  );
};

export default SelectCity;
