import { Grid } from "@mui/material";
import { GetUsageComponent } from "./get_usage_solar";
import { ModalCloseBtn } from "./styles/common.styled";

export const GetUsageSidebar = ({
  show,
  handleClose,
  handleSetConsentId,
  defaultView,
  zipcode,
}: {
  show: boolean;
  handleClose: Function;
  handleSetConsentId: Function;
  defaultView?: string;
  zipcode: string;
}) => {
  if (!show) return null;

  return (
    <div className={`${show ? "active" : ""}`}>
      <div className="side-modal-body">
        <Grid container justifyContent="flex-end">
          <ModalCloseBtn onClick={() => handleClose()} />
        </Grid>
        <GetUsageComponent
          handleSetConsentId={handleSetConsentId}
          defaultView={defaultView}
          inModal
          zipcode={zipcode}
          forcastUsage={() => {
            handleClose();
          }}
        />
      </div>
      <div className="mask" onClick={() => handleClose()} />
    </div>
  );
};
