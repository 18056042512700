import React, { useEffect, useState } from "react";
import { capitalizeString } from "../CommonFun";
import { FaqProviderBtn, FaqProviders } from "../styles/CityPage.styled";
import { CityFaqSection } from "../styles/saving-section.styled";
import { convertToKebabCase } from "../../utils/helper";

type ProviderListType = {
  business_name: string;
  show_name: string;
  description: string;
  provider_id: string;
  provider_name: string;
};

interface ProviderIntroSectionProps {
  city: string;
  zipcode: string;
  type: string;
  state: string;
  providerList: ProviderListType[] | undefined;
}

export const ProviderIntroSection: React.FC<ProviderIntroSectionProps> = ({
  city,
  zipcode,
  type,
  state,
  providerList,
}) => {
  const cityName = capitalizeString(city);
  const [pickedProviders, setPickedProviders] = useState<any>([]);

  useEffect(() => {
    if (providerList) {
      const shuffed = providerList.sort(() => 0.5 - Math.random());
      setPickedProviders(shuffed.slice(0, 6));
    }
  }, [providerList]);

  return (
    <CityFaqSection>
      <h2
        style={{ marginBottom: "2rem" }}
        className="faq-title"
      >{`${cityName} Energy Providers`}</h2>
      <label>
        {`Energy choice, also known as Energy Deregulation, allows open competition in energy markets, giving consumers the ability the 
          power to choose their energy supplier. An energy supplier, which may also be called an energy provider, a Competitive Retail 
          Electric Service provider (CRES), or retail energy provider (REP) is a company who purchases electricity from the wholesale 
          market and sells it directly to consumers such as yourself at retail prices that they control. This will be the firm to which your 
          electricity charges will be remitted in ${cityName}, ${state}.`}
      </label>
      <label>
        Some suppliers may covertly include hidden charges and have excessively
        high early termination fees as part of their electricity plans. These
        costs may only become evident when they reflect on your electricity
        invoice. To avoid being surprised, make sure you read the contract terms
        of service.
      </label>
      <label>
        {`Electricity prices among different energy providers in ${cityName} can fluctuate daily or even throughout the day, which can make the 
          selection of an electricity plan very difficult; that is why EIQShopping does the work for you. We collect information on 
          energy offers available in ${cityName}, analyze your home and personal usage profile, and recommend the best energy plan for you. We 
          provide you with an apples-to-apples comparison, showing you how much you will spend based on your usage information, so that 
          you can choose your electricity plan and provider to best fit your needs. Some of the highly reputed energy firms in ${cityName} that are 
          included in our offerings are:`}
      </label>

      <FaqProviders>
        {pickedProviders.map(
          (item: {
            show_name: string;
            provider_id: string;
            provider_name: string;
            name?: string;
          }) => {
            const provider = item.show_name
              ? convertToKebabCase(item.show_name)
              : convertToKebabCase(item.provider_name);
              console.log(item,'item')
            return (
              <FaqProviderBtn
                to={provider ? `/city/${state}/${city}/${zipcode}/${provider}` : "#"}
                key={item.provider_id}
                green={false}
                sx={{
                  textDecoration: "none",
                  background: "#f3faf7",
                  color: "inherit",
                  fontSize: "0.85rem",
                  borderRadius: "5rem",
                  padding: "4px 10px",
                }}
              >
                {item.provider_name ? item.provider_name : item.name}
              </FaqProviderBtn>
            );
          }
        )}
      </FaqProviders>
    </CityFaqSection>
  );
};
