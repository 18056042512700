import GoogleMapReact from "google-map-react";
import {
  MapHeroMask,
  SolarMapBlock,
  SolarMapContainer,
  SolarMapBlockMagrin
} from "../styles/MyComponents.styled";
import GoogleMapWithSolarRoof from "../solarRoof/GoogleMap";
import { GOOGLE_API_KEY } from '../../config'

const apiKey = GOOGLE_API_KEY;

export const GoogleSolarMap = ({
  location,
}: {
  location: {
    lat: number;
    lng: number;
  };
}) => {
  if (location.lat && location.lng) {
    return (
      <div className="googlemap-wrapper">
        <GoogleMapReact
          bootstrapURLKeys={{ key: apiKey }}
          defaultZoom={10}
          center={location}
        >
          <Marker
            // lat={location.lat}
            // lng={location.lng}
            text="Marker"
          />
        </GoogleMapReact>
      </div>
    );
  }
  return null;
};

const Marker = ({ text }: { text: string }) => (
  <div className="map-marker">{text}</div>
);

export const GoogleMap = ({ address }: { address: any }) => (
  <div>
    {address && (
      <SolarMapBlockMagrin >
        <SolarMapContainer>
          <GoogleMapWithSolarRoof address={address} />
          <MapHeroMask />
        </SolarMapContainer>
      </SolarMapBlockMagrin>
    )}
  </div>
);
