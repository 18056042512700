// import { useEffect, useState } from "react";
import { GoogleMap } from "./solar_map_city";
import { capitalizeString } from "../CommonFun";
import { SolarFaq } from "./solar_fqs";
import {
  Box,
  CircularProgress,
  Container,
  Grid,
  Typography,
  Tabs,
  Tab,
  Slider
} from "@mui/material";
import {
  MapCard,
  SolarBillCard,
  // SolarModalCard,
  SolarPotentialCard,
  SolarSizeCardNew,
} from "./solar_cards";
import {
  GoNowCard,
  SolarAddrSection,
  SolarContent,
  SolarTitle,
} from "./Solar.styled";
import { MyButton } from "../styles/MyComponents.styled";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { Image } from "react-bootstrap";
// import { SolarIntro } from "./solar_intro";
import { Link } from "react-router-dom";
// import { fetchRateCompare, fetchUtilityData } from "../../api/api";
import {
  calculateYearlyKwhEnergyConsumption,
  countSolarPanel,
  dcToAcDerate,
  panelCapacityWatts,
  // findBestPanelsCount,
} from "../../utils/countSolarPanel";
import { calculateSolarPanelSqft } from "../../utils/calculateSolarPanelSqft";
import { EstimatedUsageCardSolar } from "../city/plans/cardSection/ElectricityCard";
import {
  fetchEstUsage,
  fetchAddress,
  fetchUtility,
} from "../../api/city";
import {
  SolarCard,
} from "./Solar.styled";
import { MyAddressContext } from "./context-manager";
import { GetUsageSidebar } from "../GetUsageSidebar";
import { Utilities } from "../../types";

export const SolarAddress = (props: any) => {
  const { city, street, zipcode, state } = props;
  const [loading, setLoading] = useState(false);
  const [code, setCode] = useState({ lat: 0, lng: 0 });
  const [solarData, setSolarData] = useState<any>();
  const navigate = useNavigate();
  const [selectedBill, setSelectBill] = useState(20);
  const [avgCostPerKwh, setAvgRate] = useState(0);
  const [solarInsight, setSolarInsight] = useState({
    watt: 0,
    sqft: 0,
    cover: 0,
    hourYear: 0,
    maxPanel: 0,
    maxft: 0,
    availableSolarPanel: 0,
  });
  const [detailUsages, setDetailUsages] = useState<number[]>([]);
  const [showGetUsageSidebar, setShowGetUsageSidebar] = useState(false);
  const [tabValue, setTabValue] = useState(0);
  const [SliderValue, setSliderValue] = useState(50);
  const [selectedAddress, setSelectedAddress] = useState<any>();
  const [utility, setUtility] = useState<Utilities | undefined>(undefined);
  const [sourceFrom, setSourceFrom] = useState("");
  const [step, setStep] = useState(10);
  const [min, setMin] = useState(10);
  const [max, setMax] = useState(200);
  const [usageMonth, setUsageMonth] = useState(0);

  const handleSliderValue = (event: any, newValue: any) => {
    setSliderValue(newValue);
    setSelectBill(newValue)
  };

  const handleTabChange = (event: any, newValue: any) => {
    setTabValue(newValue);
    if (newValue === 0 && detailUsages.length > 0) {
      const currentMonthIndex = new Date().getMonth();
      let bill = detailUsages[currentMonthIndex]
      setSelectBill(bill)
    } else {
      setSelectBill(SliderValue)
    }
  };


  let address = "";
  if (city && street && zipcode && state) {
    address = `${capitalizeString(street)}, ${capitalizeString(
      city
    )}, ${"TX"} ${zipcode}`;
  }

  useEffect(() => {
    const getEstUsage = () => {
      fetchEstUsage(street, city, "tx", zipcode).then((res) => {
        if (res && parseInt(res.status) === 1) {
          const usageArr = Object.keys(res.usages).map(
            (val: any) => res.usages[val]
          );
          setDetailUsages(usageArr);
          const currentMonthIndex = new Date().getMonth();
          let bill = usageArr[currentMonthIndex]
          setSelectBill(bill)
        }
      });
    };


    if (city && zipcode && street) {
      getEstUsage();
    }
  }, [city, zipcode, street]);
  useEffect(() => {
    if (!zipcode) return;

    setAvgRate(0.1425);
    // fetchUtilityData(zipcode).then((res) => {
    //   const { utility_code } = res?.response?.[0] || {};
    //   if (utility_code) {
    //     fetchRateCompare(utility_code, "12").then((res) => {
    //       const { total_average_price } = res?.response || {};
    //       setAvgRate(
    //         total_average_price ? Number(total_average_price) / 100 : 0
    //       );
    //     });
    //   }
    // });
  }, [zipcode]);

  useEffect(() => {
    if (selectedBill && avgCostPerKwh > 0 && solarData) {
      // console.log("avgCostPerKwh", avgCostPerKwh);
      const {
        maxSunshineHoursPerYear,
        maxArrayPanelsCount,
        // solarPanelConfigs,
        // maxArrayAreaMeters2,
      } = solarData.solarPotential;

      // const yearlyKwhEnergyConsumption = calculateYearlyKwhEnergyConsumption(
      //   selectedBill,
      //   avgCostPerKwh
      // );

      // console.log("yearlyKwhEnergyConsumption", yearlyKwhEnergyConsumption);

      // const bestSolarInfo = findBestPanelsCount(
      //   solarPanelConfigs,
      //   yearlyKwhEnergyConsumption
      // );

      // console.log("bestSolarInfo", bestSolarInfo);

      // const installationSizeKw =
      //   (bestSolarInfo.panelsCount * panelCapacityWatts) / 1000;

      // console.log("installationSizeKw", installationSizeKw);

      // setSolarInsight({
      //   watt: installationSizeKw,
      //   sqft: calculateSolarPanelSqft(bestSolarInfo.panelsCount),
      //   cover: bestSolarInfo.coveredEnergy,
      // });

      const panelsCount = countSolarPanel(
        selectedBill,
        avgCostPerKwh,
        maxSunshineHoursPerYear
      );

      const availableSolarPanel =
        panelsCount < maxArrayPanelsCount ? panelsCount : maxArrayPanelsCount;

      const installationSizeKw =
        (availableSolarPanel * panelCapacityWatts) / 1000;
      const solarPanelSqft = calculateSolarPanelSqft(availableSolarPanel);
      const totalPowerOutput =
        availableSolarPanel *
        panelCapacityWatts *
        (maxSunshineHoursPerYear / 1000);
      const coverage =
        ((totalPowerOutput * dcToAcDerate) /
          calculateYearlyKwhEnergyConsumption(selectedBill, avgCostPerKwh)) *
        100;
      setSolarInsight({
        watt: installationSizeKw,
        sqft: solarPanelSqft,
        cover: coverage,
        hourYear: maxSunshineHoursPerYear,
        maxPanel: maxArrayPanelsCount,
        maxft: calculateSolarPanelSqft(maxArrayPanelsCount),
        availableSolarPanel: availableSolarPanel,
      });
    }
  }, [selectedBill, solarData, avgCostPerKwh]);


  useEffect(() => {
    if (avgCostPerKwh > 0 && solarData) {
      const { maxSunshineHoursPerYear, maxArrayPanelsCount } = solarData.solarPotential;
      const calculated = ((maxArrayPanelsCount *
        panelCapacityWatts *
        (maxSunshineHoursPerYear / 1000) * dcToAcDerate) / 12) * avgCostPerKwh;

      let newStep, newMax;

      if (calculated <= 200) {
        newStep = 10;
        newMax = Math.ceil(calculated / newStep) * newStep;
      } else if (calculated <= 500) {
        newStep = 25;
        newMax = Math.ceil(calculated / newStep) * newStep;
      } else {
        newStep = 50;
        newMax = Math.ceil(calculated / newStep) * newStep;
      }

      // Ensure max does not exceed 3000
      newMax = Math.min(newMax, 3000);

      // Update step and max
      setStep(newStep);
      setMin(newStep);
      setMax(newMax);

      const middleValue = Math.round((newMax + min) / 2 / newStep) * newStep;
      setSliderValue(middleValue); // Set middle value for Slider
    }
  }, [solarData, avgCostPerKwh]);


  useEffect(() => {
    if (address) {
      getCode(address);
    }
  }, [address]);

  useEffect(() => {
    if (code.lat && code.lng) {
      getData();
    }
  }, [code]);

  const getCode = (address: any) => {
    if (address !== undefined) {
      fetch(`https://www.personalized.energy/api/getGeoLocation?address=${encodeURIComponent(address)}`)
        .then((res) => res.json())
        .then((data) => {
          if (data?.status === 1) {
            const { lat, lng } = data;
            setCode({ lat, lng });
          }
        });
    }
  };

  const getData = async () => {
    try {
      setLoading(true);
      const closestBuilding = await fetch(
        `https://www.personalized.energy/api/getClosestBuilding?lat=${code.lat}&lng=${code.lng}`
      ).then((res) => res.json());

      setLoading(false);
      if (closestBuilding?.status === 1 && closestBuilding?.response) {
        setSolarData(closestBuilding.response);
      } else {
        setSolarData(null);
      }
    } catch (err) {
      setLoading(false);
      setSolarData(null);
      console.log(err);
    }
  };
  const incrementValue = () => {
    setSliderValue((prev) => Math.min(prev + step, max));
    setSelectBill((prev) => Math.min(prev + step, max));
  };

  useEffect(() => {


    const getUSNUtility = () => {
      setLoading(true);
      fetchUtility(zipcode).then((res: any) => {
        if (res && res[0] && res[0].utility_code) {
          setUtility(res[0]);
        } else {
          setLoading(false);
        }
      });
    };

    if (zipcode) {
      getUSNUtility();
    }
  }, [zipcode]);


  useEffect(() => {
    fetchAddress(address).then((result: any) => {
      setSelectedAddress(result[0] || {});
      localStorage.setItem("defaultAddress", JSON.stringify(result[0]));
    });

  }, [address]);
  const decrementValue = () => {
    setSliderValue((prev) => Math.max(prev - step, min));
    setSelectBill((prev) => Math.max(prev - step, min));
  };
  return (
    <SolarAddrSection>
      <GoogleMap address={address} />
      <Container className="map-card">
        <MapCard data={solarData} defaultAddress={address} />
      </Container>

      <Container>
        <SolarContent>
          {loading ? (
            <Box textAlign="center" pt={5} pb={5}>
              <CircularProgress sx={{ color: "#38c3a8" }} />
            </Box>
          ) : solarData ? (
            <>
              <SolarTitle>
                <Image src="/images/icon-dashboard.png" alt="" width={40} />{" "}
                Green energy saving program
              </SolarTitle>
              <Grid container spacing={3} sx={{ height: "100%" }}>
                <Grid item xs={12} sm={5} order={{ xs: 1, sm: 1 }}>
                  <SolarCard style={{ minHeight: 368 }}>
                    <Tabs value={tabValue} onChange={handleTabChange} aria-label="bill tabs"
                      sx={{
                        "& .MuiTabs-indicator": {
                          backgroundColor: "#38c3a8",
                        },
                        "& .MuiTab-root": {
                          color: "#38c3a8",
                        },
                        "& .Mui-selected": {
                          color: "#38c3a8 !important",
                        }
                      }}>
                      <Tab label="Estimated Usage Bill" />
                      <Tab label="Monthly Electric Bill" />
                    </Tabs>
                    <Box sx={{ mt: 2 }}>
                      {tabValue === 0 && (
                        <EstimatedUsageCardSolar
                          data={detailUsages}
                          openUsages={() => setShowGetUsageSidebar(true)}
                          returnUsages={(month: any) => {
                            setUsageMonth(month)
                            let bill = detailUsages[month]
                            console.log(bill)
                            setSelectBill(bill)
                          }}
                        />
                      )}
                      {tabValue === 1 && (
                        <div>
                          {/* <SolarBillCard
                            value={selectedBill}
                            options={termOptions}
                            handleSelected={(val) => setSelectBill(val)}
                          /> */}
                          <p style={{ marginTop: '10px' }}>Your average monthly electric bill</p>
                          <div style={{ display: 'flex', alignItems: 'center', margin: '20px 0' }}>
                            <div onClick={decrementValue} style={{ marginRight: '16px', cursor: 'pointer' }}>
                              -
                            </div>
                            <Slider
                              value={SliderValue}
                              onChange={handleSliderValue}
                              aria-labelledby="draggable-slider"
                              valueLabelDisplay="auto"
                              step={step}
                              marks
                              min={min}
                              max={max}
                              sx={{
                                "& .MuiSlider-rail": {
                                  backgroundColor: "#d3d3d3",
                                },
                                "& .MuiSlider-track": {
                                  backgroundColor: "#38c3a8",
                                },
                                "& .MuiSlider-thumb": {
                                  backgroundColor: "#38c3a8",
                                  border: "2px solid #fff",
                                },
                                "& .MuiSlider-mark": {
                                  backgroundColor: "#38c3a8",
                                },
                              }}
                            />
                            <div onClick={incrementValue} style={{ marginLeft: '16px', cursor: 'pointer' }}>
                              +
                            </div>
                          </div>
                          <p style={{ textAlign: 'center', fontSize: '24px' }}>${SliderValue}</p>
                          <p style={{ textAlign: 'center' }}>The bill amount you selected is.</p>
                          <p style={{ fontSize: '12px', color: '#6b7280' }}>We use your bill to estimate how much electricity you use based on
                            typical utility rates in your area.</p>
                        </div>
                      )}
                    </Box>
                  </SolarCard>
                </Grid>
                <Grid item xs={12} sm={7} order={{ xs: 2, sm: 2 }}>
                  <SolarSizeCardNew solarInsight={solarInsight} />

                </Grid>
                <Grid item xs={12} sm={12} order={{ xs: 3, sm: 3 }}>
                  <SolarPotentialCard
                    solarData={solarData}
                    watt={solarInsight.watt}
                  />
                </Grid>
                {/* <Grid item xs={12} sm={8} order={{ xs: 4, sm: 4 }}>
                  <SolarModalCard />
                </Grid> */}
              </Grid>
            </>
          ) : (
            <Box
              textAlign="center"
              pt={5}
              pb={8}
              mb={8}
              color="#666"
              sx={{ borderBottom: "1px solid #eee" }}
            >
              <Typography variant="h3" fontSize="24px" mb={3}>
                Oops. We do not have rooftop solar data available for this
                address.
              </Typography>
              <Typography>
                We&apos;re currently expanding our database to bring rooftop
                solar data to more areas. Please check back soon for updates.
              </Typography>
              <Typography>
                Explore solar information in other cities by visiting our{" "}
                <Link to="/service_areas"> Service Areas</Link> section.
              </Typography>
            </Box>
          )}
          <GoNowCard>
            <h6>
              You can select a plan that matches the calculated results by clicking on the options below.
            </h6>
            <MyButton
              onClick={() => {
                navigate(`/plans?address=${encodeURIComponent(street)}&esiid=${selectedAddress.esiid}&zipcode=${selectedAddress.zipcode}&city=${city}&state=${selectedAddress.state}&utcode=${utility?.utility_code}`);
              }}
            >
              View All Plans
            </MyButton>
          </GoNowCard>
          <SolarFaq city={city} zipcode={zipcode} />
          {/* <SolarIntro city={city} /> */}
        </SolarContent>
      </Container>

      <MyAddressContext.Provider
        value={{
          myAddress: selectedAddress,
          utilityCode: utility?.utility_code,
          sourceFrom,
          handleSetUsage: (val: { usage: number[]; source: string }) => {
            setDetailUsages(val.usage);
            setSourceFrom(val.source);
            if (val.source) {
              // setIsEstMode(false);
            }
            setShowGetUsageSidebar(false);
          },
        }}
      >
        <GetUsageSidebar
          show={showGetUsageSidebar}
          handleClose={() => {
            setShowGetUsageSidebar(false);
          }}
          handleSetConsentId={(val: string) => {
            // setConsentId(val)
          }}
          zipcode={zipcode}
        />
      </MyAddressContext.Provider>
    </SolarAddrSection>
  );
};
