import { useEffect, useState } from "react";
import {
  GoogleMap,
  Marker,
  useJsApiLoader,
  GroundOverlay,
} from "@react-google-maps/api";
import {
  createPalette,
  ironPalette,
  normalize,
} from "../../utils/visualize";
import { renderRGB } from "../../utils/renderRGB";
import { fetchGetSolarRoofImg, fetchPostSolarRoofImg } from "../../api/solar";
import { ENABLE_SOLAR_MAP,GOOGLE_API_KEY } from "../../config";

const containerStyle = {
  width: "100%",
  height: "500px",
};

const GoogleMapWithSolarRoof = ({ address }: { address: string }) => {
  const apiKey = GOOGLE_API_KEY;
  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: apiKey,
    // libraries: ["geometry"],
  });
  const [overlayUrl, setOverlayUrl] = useState<any>(null);
  const [overlayBounds, setOverlayBounds] = useState(null);
  const [lat, setLat] = useState(undefined);
  const [lng, setLng] = useState(undefined);
  const [center, setCenter] = useState<any>(undefined);
  const [loading, setLoading] = useState(false);
  let addressurl = "https://www.personalized.energy";

  useEffect(() => {
    if (!ENABLE_SOLAR_MAP) return;
    if (address) {
      setLoading(true);
      setCenter(undefined);
      fetch(`${addressurl}/api/getGeoLocation?address=${encodeURIComponent(address)}`)
        .then((res) => res.json())
        .then((data) => {
          if (data?.status === 1) {
            const { lat, lng } = data;
            setLat(lat);
            setLng(lng);
          } else {
            setLoading(false);
          }
        });
    }
  }, [address]);

  useEffect(() => {
    if (lat && lng) {
      getData();
    }
  }, [lat, lng]);

  const getData = async () => {
    const savedOverlayData = await fetchGetSolarRoofImg(address, lat, lng);
    try {
      if (savedOverlayData?.status === 1 && savedOverlayData?.data) {
        const { bounds, solar_pic_url } = savedOverlayData.data;
        if (bounds && solar_pic_url) {
          const closestBuilding = await findClosestBuilding();

          if (closestBuilding) {
            const { lat, lng } = closestBuilding;
            setCenter({ lat, lng });
          } else {
            setCenter({ lat, lng });
          }
          setLoading(false);
          setOverlayBounds(JSON.parse(bounds));
          setOverlayUrl(solar_pic_url);
        } else {
          handleGetDataLayerUrls();
        }
      } else {
        handleGetDataLayerUrls();
      }
    } catch (err) {
      console.error(err);
      handleGetDataLayerUrls();
    }
  };

  const handleGetDataLayerUrls = async () => {
    const layer = await getDataLayerUrls();
    if (layer) {
      const { bounds, render } = layer;
      const layerCanvas = render();

      setOverlayBounds(bounds);
      const layerBase64 = layerCanvas.toDataURL();
      setOverlayUrl(layerBase64);
      saveOverlayData(layerBase64, JSON.stringify(bounds));
    }
  };

  const saveOverlayData = (img: string, bounds: string) => {
    fetchPostSolarRoofImg(address, lat, lng, img, bounds).then((res) => {
      if (res?.status === 1) {
        console.log(res?.message || "saved successfully");
      } else {
        console.error(res?.message || "failed to save");
      }
    });
  };

  const findClosestBuilding = async () => {
    try {
      const closestBuilding = await fetch(
        `${addressurl}/api/getClosestBuilding?lat=${lat}&lng=${lng}`
      ).then((res) => res.json());

      if (closestBuilding?.status === 1 && closestBuilding?.response) {
        const {
          center: { latitude, longitude },
          // boundingBox,
        } = closestBuilding.response;

        // const { ne, sw } = boundingBox;

        // const diameter =
        //   window.google.maps.geometry.spherical.computeDistanceBetween(
        //     new window.google.maps.LatLng(ne.latitude, ne.longitude),
        //     new window.google.maps.LatLng(sw.latitude, sw.longitude)
        //   );

        return {
          lat: latitude,
          lng: longitude,
          // r: Math.ceil(diameter / 2),
        };
      }
      return null;
    } catch (err) {
      console.error(err);
      return null;
    }
  };

  const getDataLayerUrls = async () => {
    try {
      const closestBuilding = await findClosestBuilding();

      const args = {
        lat,
        lng,
        r: 150,
      };

      if (closestBuilding) {
        const { lat, lng } = closestBuilding;
        args.lat = lat;
        args.lng = lng;
        setCenter({ lat, lng });
      } else {
        setCenter({ lat, lng });
      }

      const dataLayerUrls = await fetch(
        `${addressurl}/api/getDataLayerUrls?lat=${args.lat}&lng=${args.lng}&radius=${args.r}`
      ).then((res) => res.json());

      if (dataLayerUrls?.status === 1) {
        const { maskUrl, annualFluxUrl } = dataLayerUrls?.response || {};
        if (maskUrl && annualFluxUrl) {
          const [mask, annualFlex] = await Promise.all([
            fetch(
              `https://z7addc12sl.execute-api.us-east-2.amazonaws.com/s1/api/getGeoTiff`,
              {
                method: "POST",
                headers: {
                  "Content-Type": "application/json",
                },
                body: JSON.stringify({ url: maskUrl }),
              }
            ),
            fetch(
              `https://z7addc12sl.execute-api.us-east-2.amazonaws.com/s1/api/getGeoTiff`,
              {
                method: "POST",
                headers: {
                  "Content-Type": "application/json",
                },
                body: JSON.stringify({ url: annualFluxUrl }),
              }
            ),
          ]);

          const maskData = await mask.json();
          const annualFlexData = await annualFlex.json();

          return {
            id: "annualFlex",
            bounds: maskData.data.bounds,
            palette: {
              colors: ironPalette,
              min: "Shady",
              max: "Sunny",
            },
            render: () =>
              renderPalette({
                data: annualFlexData.data,
                mask: maskData.data,
                colors: ironPalette,
                min: 0,
                max: 1800,
              }),
          };
        }
      }
    } catch (error) {
      console.error(error);
      return null;
    }
  };

  const renderPalette = ({
    data,
    mask,
    colors,
    min,
    max,
    index,
  }: {
    data: any;
    mask: any;
    colors: string[];
    min: number;
    max: number;
    index?: number;
  }) => {
    const palette = createPalette(colors ?? ["000000", "ffffff"]);
    const indices = data.rasters[index ?? 0]
      .map((x:any) => normalize(x, max ?? 1, min ?? 0))
      .map((x:any) => Math.round(x * (palette.length - 1)));

    return renderRGB(
      {
        ...data,
        // Map each index into the corresponding RGB values.
        rasters: [
          indices.map((i:any) => palette[i].r),
          indices.map((i:any) => palette[i].g),
          indices.map((i:any) => palette[i].b),
        ],
      },
      mask
    );
  };

  if (!ENABLE_SOLAR_MAP && address) {
    return (
      <div
        style={{
          height: 500,
          width: "160%",
          position: "relative",
          top: "-10%",
          left: "-30%",
        }}
      >
        <iframe
          width="100%"
          height="100%"
          loading="lazy"
          allowFullScreen
          title="googleMap"
          referrerPolicy="no-referrer-when-downgrade"
          src={`https://www.google.com/maps/embed/v1/place?key=${apiKey}&q=${address}&zoom=16`}
        />
      </div>
    );
  }

  if (isLoaded) {
    if (center) {
      return (
        <div style={{ height: "500px", background: "#E8E6E3" }}>
          <GoogleMap
            mapContainerStyle={containerStyle}
            options={{
              mapTypeControl: false,
              fullscreenControl: false,
              rotateControl: false,
              streetViewControl: false,
              zoomControl: false,
              mapTypeId: "satellite",
              tilt: 0,
            }}
            center={center}
            zoom={19}
          >
            {overlayUrl && overlayBounds && (
              <GroundOverlay url={overlayUrl} bounds={overlayBounds} />
            )}
            <Marker position={center} />
          </GoogleMap>
        </div>
      );
    }
    if (!loading) {
      return (
        <div
          style={{
            height: "500px",
            background:
              'url("/images/map-default-bg.png") center center no-repeat',
            backgroundSize: "cover",
          }}
        />
      );
    }
  }
  return <div style={{ height: "500px", background: "#E8E6E3" }} />;

  // <iframe
  //   width="100%"
  //   height="100%"
  //   loading="lazy"
  //   allowFullScreen
  //   title="googleMap"
  //   referrerPolicy="no-referrer-when-downgrade"
  //   src={`https://www.google.com/maps/embed/v1/place?key=${apiKey}&q=${address}&zoom=16`}
  // />
};

export default GoogleMapWithSolarRoof;
