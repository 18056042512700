import React, { useEffect, useState } from "react";
import { Dropdown } from "react-bootstrap";
import { useParams } from "react-router-dom";
import type { TermFilterProps } from "../../../types";
import { LoadingRow } from "../LoadingRow";
import { useTranslation } from "react-i18next";

interface TermOption {
  label: string;
  value: string;
}

export const TermFilter: React.FC<TermFilterProps> = ({
  initializeFilter,
  loading,
  handleClick,
  availableTerms,
  terms,
  allTerm,
  showAllTerms,
}) => {
  const { t } = useTranslation("plan");
  const { term } = useParams<{ term?: string }>();
  const defaultTerm: TermOption = { value: term || "0", label: "Show All" };

  const [termsOptions, setTermsOptions] = useState<TermOption[]>([]);
  const [selectedTerm, setSelectedTerm] = useState<TermOption>(defaultTerm);

  useEffect(() => {
    const newTermsOptions: TermOption[] = [];
    const tier1: string[] = [];
    const tier2: string[] = [];
    const tier3: string[] = [];

    const arr:any = showAllTerms ? allTerm : terms;
    arr.forEach((val:any) => {
      const month = parseInt(val, 10);
      if (month === 0) return;
      if (month > 0 && month < 12) tier1.push(`${month}`);
      if (month >= 12 && month < 24) tier2.push(`${month}`);
      if (month >= 24) tier3.push(`${month}`);
      newTermsOptions.push({ label: `${month} Month`, value: `${month}` });
    });

    if (tier3.length > 0) {
      newTermsOptions.unshift({
        label: "More Than 2 Years",
        value: `${tier3.join(",")},`,
      });
    }
    if (tier2.length > 0) {
      newTermsOptions.unshift({
        label: "Between 1-2 Years",
        value: `${tier2.join(",")},`,
      });
    }
    if (tier1.length > 0) {
      newTermsOptions.unshift({
        label: "Less Than 1 Year",
        value: `${tier1.join(",")},`,
      });
    }
    newTermsOptions.unshift({ label: "Show All", value: "0" });

    setTermsOptions(newTermsOptions);

    if (!selectedTerm) {
      setSelectedTerm(newTermsOptions[0]);
    } else {
      for (const option of newTermsOptions) {
        if (option.value === selectedTerm.value) {
          setSelectedTerm(option);
          break;
        }
      }
    }
  }, [terms, availableTerms, allTerm]);

  useEffect(() => {
    if (initializeFilter && termsOptions.length > 0) {
      setSelectedTerm(termsOptions[0]);
    }
  }, [initializeFilter, termsOptions]);

  if (initializeFilter || termsOptions.length === 0) return <LoadingRow size="sm" />;

  return (
    <div className="plans-filter-options">
      <Dropdown className="plans-filter-dd">
        <Dropdown.Toggle variant="light" disabled={loading}>
          {t("Plan Length")}: {selectedTerm.label}
        </Dropdown.Toggle>
        <Dropdown.Menu>
          {termsOptions.map((val, idx) => (
            <Dropdown.Item
              eventKey={idx}
              key={idx}
              onClick={() => {
                handleClick(val.value);
                setSelectedTerm(val);
              }}
              active={selectedTerm?.value === val.value}
              disabled={
                availableTerms.indexOf(val.value) < 0 &&
                val.value !== "0" &&
                !val.value.includes(",")
              }
            >
              {val.label}
            </Dropdown.Item>
          ))}
        </Dropdown.Menu>
      </Dropdown>
    </div>
  );
};
