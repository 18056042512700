import React from "react";

import type { UtilitiesFilterProps } from "../../../types";
import { LoadingRow } from "../LoadingRow";
import { CheckboxItem } from "./checkbox-item";

export const UtilitiesFilter: React.FC<UtilitiesFilterProps> = ({
  initializeFilter,
  value,
  loading,
  handleClick,
  utilities,
}) => {
  if (initializeFilter) return <LoadingRow size="sm" />;

  return (
    <div className="plans-filter-options">
      {utilities.map((val, idx) => (
        <CheckboxItem
          label={val.utility_name}
          value={val.utility_id}
          key={idx}
          checked={
            idx === 0 && value === "" ? true : value === val.utility_code
          }
          handleClick={() => {
            handleClick(val.utility_code);
          }}
          disabled={loading}
        />
      ))}
    </div>
  );
};
