import React from "react";
import type { ProviderFilterProps } from "../../../types";
import { LoadingRow } from "../LoadingRow";
import { Dropdown } from "react-bootstrap";

export const ProviderFilter: React.FC<ProviderFilterProps> = ({
  initializeFilter,
  value,
  loading,
  handleClick,
  // availableReps,
  reps,
  // defalutReps,
  // showAllReps
}) => {
  if (initializeFilter) return <LoadingRow size="sm" />;

  const options = [...reps];
  options.unshift({ name: "Show All", provider_id: "-1" });

  return (
    <div className="plans-filter-options">
      <Dropdown className="plans-filter-dd">
        <Dropdown.Toggle variant="light" disabled={loading}>
          {((val) => {
            const res = reps.filter((item) => item.provider_id === val[0]);
            return res[0] ? res[0].name : "Show All";
          })(value)}
        </Dropdown.Toggle>
        <Dropdown.Menu style={{ minWidth: "200px" }}>
          {options.map((val, idx) => (
            <Dropdown.Item
              eventKey={val}
              key={idx}
              onClick={() => {
                handleClick(val.provider_id);
              }}
              active={
                value[0] === val.provider_id ||
                (val.provider_id === "-1" && !value[0])
              }
            >
              {val.name}
            </Dropdown.Item>
          ))}
        </Dropdown.Menu>
      </Dropdown>
    </div>
  );
};
