import { styled } from "@mui/system";

export const SolarGridContainer = styled('div')({
  display: 'grid',
  gridTemplateColumns: 'repeat(3, 1fr)',
  gap: '16px',
  padding: '10px 0',
  height:'100%'
});

export const SolarGridItem = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  // alignItems: 'flex-start',
  // justifyContent:'center'
});

export const SolarValueText = styled('span')({
  fontWeight: 'bold',
  fontSize: '22px',
  marginTop:'25px'
});

export const SolarDescriptionText = styled('span')({
  color: '#6b7280',
  fontSize: '12px'
});

interface CardProps {
  bg?: string;
}

export const SolarCard = styled("div")<CardProps>`
  position: relative;
  border-radius: 20px;
  border: 1px solid #dbd6d6;
  box-shadow: 0 3px 8px rgba(0, 0, 0, 0.03);
  padding: 20px 30px;
  height: 100%;
  background-color: ${({ bg }) => (bg ? `${bg}` : "#fff")};

  @media (max-width: 768px) {
    padding: 20px;
  }
`;

export const SolarCardTitle = styled("h6")`
  margin-bottom: 10px;

  p {
    color: #000;
    font-size: 14px;
    font-weight: 200;
    margin-top: 10px;
  }
`;

interface CardContentProps {
  wrap?: boolean;
}

export const SolarCardContent = styled("div")<CardContentProps>`
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;

  .eqal {
    font-size: 1.25rem;
    font-weight: 600;
  }

  @media (max-width: 768px) {
    flex-direction: ${({ wrap }) => (wrap ? "wrap" : "column")};
    flex-wrap: wrap;

    .img-eqal {
      display: none;
    }
  }
`;

export const SolarCardContentItem = styled("div")`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  p {
    color: #000;
    font-size: 16px;
    font-weight: 500;
  }

  label {
    color: #000;
    font-size: 14px;
    font-weight: 200;
  }

  h6 {
    color: #4a4a4a;
    font-family: Roboto;
    font-size: 24px;
    font-weight: 400;
  }

  h6 span {
    color: #4a4a4a;
    font-family: Roboto;
    font-size: 14px;
    font-weight: 400;
  }
  .css-2g2urp-MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root {
    max-width: 150px;
  }
  .MuiSelect-select {
    padding: 5px 10px;
  }
`;

export const SolarImgCard = styled("div")`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  img {
    margin-bottom: 10px;
  }

  p {
    margin-bottom: 0;
  }

  @media (max-width: 768px) {
    width: 50%;

    img {
      height: 63px;
    }

    p {
      margin-bottom: 10px;
    }
  }
`;

export const CarbonSec = styled("div")`
  display: flex;
  align-items: center;
  border-radius: 6px;
  border: 1px solid #dbd6d6;
  background: #fff;
  position: relative;
  padding: 10px 30px;
  .title {
    color: #000;
    font-size: 16px;
    font-weight: 500;
  }

  h4 {
    color: #54c581;
    font-size: 20px;
    font-weight: 600;
  }

  .intro {
    color: #4a4a4a;
    font-size: 12px;
    font-weight: 400;
  }
  img {
    position: absolute;
    left: -18px;
    top: 0;
    width: 40px;
    height: 40px;
  }

  @media (max-width: 768px) {
    margin-bottom: 20px;
    max-width: 180px;
  }
`;

export const SolarAddrSection = styled("div")`
  position: relative;
  max-width: 100%;
  overflow: hidden;
  margin-top: 20px;

  .map-card-section {
  }

  @media (max-width: 600px) {
    margin-top: 0;
  }
`;

export const SolarTitle = styled("h6")`
  color: #38c3a8;
  font-size: 24px;
  font-weight: 800;
  font-family: Inter;

  img {
    margin-right: 15px;
  }
`;

export const SolarContent = styled("div")`
  padding: 40px 0;
`;

export const SolarMapCard = styled("div")`
  background: #fff;
  border-radius: 2px;
  box-shadow: 3px 3px 5px rgba(0, 0, 0, 0.3);
  margin-bottom: 4px;
  padding: 15px 30px 15px 30px;
`;

export const SolarMapCardSec = styled("div")`
  position: absolute;
  top: 80px;
  min-width: 400px;

  @media (max-width: 768px) {
    position: relative;
    max-width: 100%;
    min-width: 300px;
    top: 10px;
  }
`;

export const SolarAutocomplete = styled("div")`
  display: flex;
  margin-bottom: 20px;
  width: 500px;
  max-width: 100%;
  gap: 12px;
  .MuiAutocomplete-inputRoot {
    background: #fff;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.1);
    border-radius: 60px;
  }
`;

export const MapCardTitle = styled("p")`
  line-height: 1.2em;
  font-size: 14px;
  font-weight: 300;
  border-bottom: 1px solid #eee;
  padding: 14px 5px;

  img {
    margin-right: 29px;
  }
`;

export const MapCardContent = styled("div")`
  display: flex;
  align-items: center;
  margin-bottom: 15px;
  img {
    margin-right: 24px;
  }

  p {
    margin-bottom: 0;
    color: #212121;
    font-weight: 400;
    line-height: 1.5em;
    font-size: 16px;
  }

  label {
    color: #757575;
    padding-top: 3px;
    font-size: 14px;
  }
`;

export const GoNowCard = styled("div")`
  margin-top: 30px;
  background-color: rgb(243, 250, 247);
  border-radius: 30px;
  text-align: center;
  padding: 50px 0;

  h6 {
    font-size: 1.6rem;
    margin-bottom: 2rem;
  }

  button {
    display: block;
    margin: auto;
  }
`;

export const CitySolarMapCard = styled("div")`
  position: absolute;
  top: 60px;
  max-width: 600px;

  @media (max-width: 768px) {
    position: relative;
    top: 0;
    padding: 20px 0;
  }
`;

export const CitySolar = styled("div")`
  position: relative;
  overflow: hidden;
  padding-top: 20px;
`;

export const SolarCityIntro = styled("div")`
  border-radius: 10px;
  border: 1px solid #dbd6d6;
  background: rgba(255, 240, 200, 0.1);
  padding: 18px 20px;
  height: 100%;
  h6 {
    color: #000;
    font-size: 18px;
    font-weight: 500;
    line-height: 25px;
  }

  p {
    color: #000;
    font-size: 14px;
    font-weight: 200;
  }
`;

export const MapCardIntro = styled("div")`
  border-radius: 20px;
  background: rgba(255, 255, 255, 0.9);
  box-shadow: 0px 4px 4px 2px rgba(0, 0, 0, 0.1);
  padding: 20px 50px 20px 35px;
  display: flex;
  margin-bottom: 10px;

  img {
    flex-shink: 0;
    width: 40px;
    height: 40px;
    margin-right: 15px;
  }
`;

export const CityMapCardSearch = styled("div")`
  border-radius: 20px;
  background: rgba(255, 255, 255, 0.9);
  box-shadow: 0px 4px 4px 2px rgba(0, 0, 0, 0.1);
  padding: 20px 50px 20px 35px;
  display: flex;

  img {
    flex-shink: 0;
    width: 40px;
    height: 40px;
    margin-right: 15px;
  }
`;

export const SearchContent = styled("div")`
  width: 100%;
  h3 {
    color: rgba(0, 0, 0, 0.85);
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 10px;
  }
`;
export const MapCardIntroText = styled("div")`
  h5 {
    color: #38c3a8;
    font-size: 16px;
    font-weight: 600;
  }

  p {
    color: #000;
    font-size: 14px;
    font-weight: 400;
  }
`;
