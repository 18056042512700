import { Alert, Box, Button, Grid, Snackbar } from "@mui/material";
// import copy from "copy-to-clipboard";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import {
  Bar,
  BarChart,
  CartesianGrid,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import { Card, MyButton } from "../../../styles/MyComponents.styled";

const EsiidCard = (props: { content: any }) => {
  // const [, setCopyStatus] = useState(false);
  const [open, setOpen] = useState(false);
  const { content } = props;
  // const { esiid, status }: any = content;
  // copy
  // const copyId = () => {
  //   copy(esiid);
  //   setCopyStatus(true);
  //   setOpen(true);
  // message.success('Copy success');
  // };
  return (
    <Card style={{ flex: 1 }}>
      <div className="d-flex align-items-center">
        <img src="/images/icon_esid.png" alt="" />
        <h5>ESID Number(s)</h5>
      </div>
      <div style={{ textAlign: "left", padding: "10px 20px" }}>
        <label>{content.esiid}</label>
        {/* <Button onClick={() => copyId()}>COPY</Button> */}
      </div>

      <Snackbar
        open={open}
        autoHideDuration={6000}
        onClose={() => setOpen(false)}
      >
        <Alert
          onClose={() => setOpen(false)}
          severity="success"
          sx={{ width: "100%" }}
        >
          Copy success!
        </Alert>
      </Snackbar>
    </Card>
  );
};

const HomeAreaCard = (props: { houseArea: any }) => {
  const { houseArea } = props;
  const { bathrooms, bedrooms, lot_size }: any = houseArea || {};
  return (
    <Card className="house" style={{ flex: 2 }}>
      <div className="d-flex align-items-center">
        <img src="/images/icon_house_area.png" alt="" />
        <h5>House Area</h5>
      </div>
      <div
        style={{
          height: "70%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          paddingLeft: 50,
          gap: 30,
        }}
      >
        <h4 style={{ textAlign: "start" }}>
          {lot_size ? toThousands(lot_size) : 0}&nbsp; SqFt{" "}
        </h4>
        <div style={{ display: "flex", gap: 30 }}>
          <span>
            <img src="/images/icon-bedroom.png" alt=" " />
            {bedrooms || 0}
          </span>
          <span>
            <img src="/images/icon-bathroom.png" alt=" " />
            {bathrooms || 0}
          </span>
        </div>
      </div>
    </Card>
  );
};

const EstimatedUsageCard = (props: { data: any; openUsages: Function }) => {
  const { data, openUsages } = props;
  return (
    <Box>
      <Grid
        item
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: "2rem",
        }}
      >
        <div className="d-flex align-items-center">
          {/* <img src="/images/icon_est_usage.png" alt="" /> */}
          <h5>Estimated Usage</h5>
        </div>
        <Button
          variant="outlined"
          size="small"
          onClick={() => {
            openUsages();
          }}
        >
          Get My Usage
        </Button>
      </Grid>
      <div className="d-flex flex-column gap-3">
        <ResponsiveContainer height={200}>
          <BarChart
            data={chartData(data)}
            margin={{ top: 5, right: 30, left: 0, bottom: 5 }}
          >
            <CartesianGrid strokeDasharray="3 3" vertical={false} />
            <XAxis dataKey="month" interval={1} />
            <YAxis />
            <Tooltip separator=" " itemStyle={{ color: "black" }} />
            <Bar dataKey="value" fill="#e8ecec" name=" " unit=" kwh" />
          </BarChart>
        </ResponsiveContainer>
        <p style={{ fontSize: 14, color: "#666" }}>
          Rate is calculated by estimated usage by zip code and house size.
          Adjust with your own estimates.
        </p>
      </div>
    </Box>
  );
};

const EstimatedUsageCardSolar = (props: { data: any; openUsages: Function,returnUsages: Function }) => {
  const { data, openUsages, returnUsages } = props;
  const [month, setMonth] = useState(0);
  
  const _usages:number[] = Object.values(data);
  const handleClick=(data:any,index:any)=>{
    setMonth(index)
    returnUsages(index)
  }
  useEffect(()=>{
    const currentMonthIndex = new Date().getMonth(); 
    setMonth(currentMonthIndex)
  },[])

  const chartDataSolar = (props: any,month:Number) => {
    const data = [
      { month: "Jan", value: 0 },
      { month: "Feb", value: 0 },
      { month: "Mar", value: 0 },
      { month: "Apr", value: 0 },
      { month: "May", value: 0 },
      { month: "Jun", value: 0 },
      { month: "Jul", value: 0 },
      { month: "Aug", value: 0 },
      { month: "Sept", value: 0 },
      { month: "Oct", value: 0 },
      { month: "Nov", value: 0 },
      { month: "Dec", value: 0 },
    ];
  
    const _usages = Object.values(props);
    const _data: any[] = [];
    data.forEach((o: any, index: number) => {
      o.value = _usages[index];
      o.fill = index === month ? '#38c3a8' : '#e8ecec';
      _data.push(o);
    });
    return _data;
  };
  return (
    <Box>
      <Grid
        item
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: "0.25rem",
        }}
      >
        <div className="d-flex align-items-center">
          {/* <img src="/images/icon_est_usage.png" alt="" /> */}
          <h5>Estimated Usage</h5>
        </div>
        <Button
          variant="outlined"
          size="small"
          onClick={() => {
            openUsages();
          }}

          sx={{
            color:'#38c3a8',
            borderColor:'#38c3a8',
          }}
        >
          Get My Usage
        </Button>
      </Grid>
      <div className="d-flex flex-column gap-1">
        <ResponsiveContainer height={160}>
          <BarChart
            data={chartDataSolar(data,month)}
            margin={{ top: 5, right: 30, left: 0, bottom: 5 }}
          >
            <CartesianGrid strokeDasharray="3 3" vertical={false} />
            <XAxis dataKey="month" interval={1} />
            <YAxis />
            <Tooltip separator=" " itemStyle={{ color: "black" }} />
            <Bar onClick={handleClick} dataKey="value" fill="#e8ecec" name=" " unit=" kwh" />
          </BarChart>
        </ResponsiveContainer>
        <div style={{textAlign:'center',color:'#38c3a8'}}>{_usages[month]}Kwh</div>
        <p style={{ fontSize: 14, color: "#666", marginBottom: "0rem",}}>
          Rate is calculated by estimated usage by zip code and house size.
          Adjust with your own estimates.
        </p>
      </div>
    </Box>
  );
};

const GoogleMapCard = (props: {
  address: any;
  status: any;
  premise_type: any;
  utility_name: any;
}) => {
  const navigate = useNavigate();
  const { status, premise_type, utility_name, address } = props;
  return (
    <Card>
      <Grid
        item
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <div className="d-flex align-items-center">
          <img src="/images/icon_location_blue.png" alt="" />
          <h5>Address Info</h5>
        </div>
        <MyButton
          style={{
            maxHeight: "40px",
            borderRadius: "10px",
            padding: "5px 15px",
          }}
          onClick={() => {
            navigate("/");
          }}
        >
          Edit
        </MyButton>
      </Grid>
      <div style={{ height: 30 }} />
      <GoogleMap address={address} />
      <ul>
        <li className="label">
          <label>Status</label>
          <label>Premise Type</label>
          <label>Utility</label>
        </li>
        <li className="span">
          <span>{status}</span>
          <span>{premise_type}</span>
          <span>{utility_name}</span>
        </li>
      </ul>
    </Card>
  );
};

const chartData = (props: any) => {
  // let { usages } = props;
  const data = [
    { month: "Jan", value: 0 },
    { month: "Feb", value: 0 },
    { month: "Mar", value: 0 },
    { month: "Apr", value: 0 },
    { month: "May", value: 0 },
    { month: "Jun", value: 0 },
    { month: "Jul", value: 0 },
    { month: "Aug", value: 0 },
    { month: "Sept", value: 0 },
    { month: "Oct", value: 0 },
    { month: "Nov", value: 0 },
    { month: "Dec", value: 0 },
  ];

  const _usages = Object.values(props);
  const _data: any[] = [];
  data.forEach((o: any, index: number) => {
    o.value = _usages[index];
    _data.push(o);
  });
  return _data;
};

const toThousands = (num: any) => {
  const result = [];
  let counter = 0;
  num = (num || 0).toString().split("");
  for (let i = num.length - 1; i >= 0; i -= 1) {
    counter += 1;
    result.unshift(num[i]);
    if (!(counter % 3) && i !== 0) {
      result.unshift(",");
    }
  }
  return result.join("");
};

const GoogleMap = ({ address }: { address: any }) => {
  const apiKey = process.env.GOOGLE_MAPS_API_KEY;

  const arr = address.split(" ");

  for (let i = 0; i < arr.length; i += 1) {
    arr[i] = `+${arr[i]}`;
  }
  const _address = arr.join("");

  if (address) {
    return (
      <div className="googlemap-wrapper">
        <iframe
          width="600"
          height="450"
          loading="lazy"
          allowFullScreen
          title="googleMap"
          referrerPolicy="no-referrer-when-downgrade"
          src={`https://www.google.com/maps/embed/v1/place?key=${apiKey}&q=${_address}&zoom=17`}
        />
      </div>
    );
  }
  return null;
};

// const Marker = ({ text }: { text: string; lat: number; lng: number }) => {
//   return <div className="map-marker">{text}</div>;
// };

export { EsiidCard, EstimatedUsageCard, GoogleMapCard, HomeAreaCard ,EstimatedUsageCardSolar };
