import { useEffect, useState } from "react";
import { fetchState } from "../../api/api";


const useGetState = (props: any) => {
  const [selectState, setSelectState] = useState<any>();

  const state = props;

  useEffect(() => {
    if (props) {
      booleanRouter();
    }
  }, [props]);

  const booleanRouter = async () => {
    const res = await fetchState();
    if (res && res.code === 200) {
      getState(res.data);
      // localStorage.setItem('state_list', JSON.stringify(res.data))
    }
  };

  const getState = (list: any) => {
    if (list) {
      const val = list.find(
        (item:any) =>
          item.state_id.toUpperCase() === state.toUpperCase() ||
          item.state_name.toUpperCase() ===
          state.toUpperCase().replace(/_/g, " ")
      );
      if (val) {
        setSelectState(val);
      } else {
        // router.push("/");
      }
    }
  };

  return selectState;
};
export default useGetState;
