export const cityRes = {
  code: 200,
  message: "success",
  data: [
    {
      city_id: "31862",
      city: "Acampo",
      zip_code: "95220",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "31417",
      city: "Acton",
      zip_code: "93510",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "31072",
      city: "Adelanto",
      zip_code: "92301",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "30804",
      city: "Agoura Hills",
      zip_code: "91301",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "31155",
      city: "Aguanga",
      zip_code: "92536",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "31457",
      city: "Ahwahnee",
      zip_code: "93601",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "31633",
      city: "Alameda",
      zip_code: "94501",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "31638",
      city: "Alamo",
      zip_code: "94507",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "31735",
      city: "Albany",
      zip_code: "94706",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "31967",
      city: "Albion",
      zip_code: "95410",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "32031",
      city: "Alderpoint",
      zip_code: "95511",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "30908",
      city: "Alhambra",
      zip_code: "91801",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "31207",
      city: "Aliso Viejo",
      zip_code: "92656",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "32216",
      city: "Alleghany",
      zip_code: "95910",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "31301",
      city: "Alpaugh",
      zip_code: "93201",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "30910",
      city: "Alpine",
      zip_code: "91901",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "32163",
      city: "Alta",
      zip_code: "95701",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "30773",
      city: "Altadena",
      zip_code: "91001",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "31863",
      city: "Altaville",
      zip_code: "95221",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "31782",
      city: "Alviso",
      zip_code: "95002",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "32076",
      city: "Amador City",
      zip_code: "95601",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "31073",
      city: "Amboy",
      zip_code: "92304",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "31635",
      city: "American Canyon",
      zip_code: "94503",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "31235",
      city: "Anaheim",
      zip_code: "92801",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "32288",
      city: "Anderson",
      zip_code: "96007",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "31864",
      city: "Angels Camp",
      zip_code: "95222",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "31074",
      city: "Angelus Oaks",
      zip_code: "92305",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "31639",
      city: "Angwin",
      zip_code: "94508",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "31968",
      city: "Annapolis",
      zip_code: "95412",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "32212",
      city: "Antelope",
      zip_code: "95843",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "31640",
      city: "Antioch",
      zip_code: "94509",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "31075",
      city: "Apple Valley",
      zip_code: "92307",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "32164",
      city: "Applegate",
      zip_code: "95703",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "31783",
      city: "Aptos",
      zip_code: "95003",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "32217",
      city: "Arbuckle",
      zip_code: "95912",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "30774",
      city: "Arcadia",
      zip_code: "91006",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "32034",
      city: "Arcata",
      zip_code: "95521",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "31302",
      city: "Armona",
      zip_code: "93202",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "31865",
      city: "Arnold",
      zip_code: "95223",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "31784",
      city: "Aromas",
      zip_code: "95004",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "31384",
      city: "Arroyo Grande",
      zip_code: "93420",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "30737",
      city: "Artesia",
      zip_code: "90701",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "32218",
      city: "Artois",
      zip_code: "95913",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "31303",
      city: "Arvin",
      zip_code: "93203",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "31385",
      city: "Atascadero",
      zip_code: "93422",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "31573",
      city: "Atherton",
      zip_code: "94027",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "31891",
      city: "Atwater",
      zip_code: "95301",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "31458",
      city: "Auberry",
      zip_code: "93602",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "32077",
      city: "Auburn",
      zip_code: "95602",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "31304",
      city: "Avenal",
      zip_code: "93204",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "31866",
      city: "Avery",
      zip_code: "95224",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "31386",
      city: "Avila Beach",
      zip_code: "93424",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "30863",
      city: "Azusa",
      zip_code: "91702",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "31459",
      city: "Badger",
      zip_code: "93603",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "31077",
      city: "Baker",
      zip_code: "92309",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "31366",
      city: "Bakersfield",
      zip_code: "93301",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "30864",
      city: "Baldwin Park",
      zip_code: "91706",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "31892",
      city: "Ballico",
      zip_code: "95303",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "32219",
      city: "Bangor",
      zip_code: "95914",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "31028",
      city: "Banning",
      zip_code: "92220",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "31079",
      city: "Barstow",
      zip_code: "92311",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "31460",
      city: "Bass Lake",
      zip_code: "93604",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "32035",
      city: "Bayside",
      zip_code: "95524",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "32215",
      city: "Beale Afb",
      zip_code: "95903",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "31030",
      city: "Beaumont",
      zip_code: "92223",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "32220",
      city: "Belden",
      zip_code: "95915",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "30668",
      city: "Bell Gardens",
      zip_code: "90201",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "32289",
      city: "Bella Vista",
      zip_code: "96008",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "30740",
      city: "Bellflower",
      zip_code: "90706",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "31561",
      city: "Belmont",
      zip_code: "94002",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "31749",
      city: "Belvedere Tiburon",
      zip_code: "94920",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "31785",
      city: "Ben Lomond",
      zip_code: "95005",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "31641",
      city: "Benicia",
      zip_code: "94510",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "31731",
      city: "Berkeley",
      zip_code: "94702",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "32221",
      city: "Berry Creek",
      zip_code: "95916",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "31642",
      city: "Bethel Island",
      zip_code: "94511",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "30669",
      city: "Beverly Hills",
      zip_code: "90210",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "32290",
      city: "Bieber",
      zip_code: "96009",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "32291",
      city: "Big Bar",
      zip_code: "96010",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "31081",
      city: "Big Bear City",
      zip_code: "92314",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "31082",
      city: "Big Bear Lake",
      zip_code: "92315",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "32292",
      city: "Big Bend",
      zip_code: "96011",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "31461",
      city: "Big Creek",
      zip_code: "93605",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "31894",
      city: "Big Oak Flat",
      zip_code: "95305",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "31541",
      city: "Big Sur",
      zip_code: "93920",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "32222",
      city: "Biggs",
      zip_code: "95917",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "31462",
      city: "Biola",
      zip_code: "93606",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "31643",
      city: "Birds Landing",
      zip_code: "94512",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "32032",
      city: "Blocksburg",
      zip_code: "95514",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "31083",
      city: "Bloomington",
      zip_code: "92316",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "31084",
      city: "Blue Jay",
      zip_code: "92317",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "32036",
      city: "Blue Lake",
      zip_code: "95525",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "31031",
      city: "Blythe",
      zip_code: "92225",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "31750",
      city: "Bodega",
      zip_code: "94922",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "31751",
      city: "Bodega Bay",
      zip_code: "94923",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "31305",
      city: "Bodfish",
      zip_code: "93205",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "31752",
      city: "Bolinas",
      zip_code: "94924",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "30911",
      city: "Bonita",
      zip_code: "91902",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "30935",
      city: "Bonsall",
      zip_code: "92003",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "31969",
      city: "Boonville",
      zip_code: "95415",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "31421",
      city: "Boron",
      zip_code: "93516",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "30936",
      city: "Borrego Springs",
      zip_code: "92004",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "31786",
      city: "Boulder Creek",
      zip_code: "95006",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "30912",
      city: "Boulevard",
      zip_code: "91905",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "31387",
      city: "Bradley",
      zip_code: "93426",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "31288",
      city: "Brandeis",
      zip_code: "93064",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "31970",
      city: "Branscomb",
      zip_code: "95417",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "31032",
      city: "Brawley",
      zip_code: "92227",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "31242",
      city: "Brea",
      zip_code: "92821",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "31644",
      city: "Brentwood",
      zip_code: "94513",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "32037",
      city: "Bridgeville",
      zip_code: "95526",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "31562",
      city: "Brisbane",
      zip_code: "94005",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "31787",
      city: "Brookdale",
      zip_code: "95007",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "32081",
      city: "Brooks",
      zip_code: "95606",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "32223",
      city: "Browns Valley",
      zip_code: "95918",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "32224",
      city: "Brownsville",
      zip_code: "95919",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "31388",
      city: "Buellton",
      zip_code: "93427",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "30725",
      city: "Buena Park",
      zip_code: "90620",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "30850",
      city: "Burbank",
      zip_code: "91501",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "31563",
      city: "Burlingame",
      zip_code: "94010",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "32293",
      city: "Burney",
      zip_code: "96013",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "32038",
      city: "Burnt Ranch",
      zip_code: "95527",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "31867",
      city: "Burson",
      zip_code: "95225",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "32225",
      city: "Butte City",
      zip_code: "95920",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "31306",
      city: "Buttonwillow",
      zip_code: "93206",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "31645",
      city: "Byron",
      zip_code: "94514",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "31033",
      city: "Cabazon",
      zip_code: "92230",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "30805",
      city: "Calabasas",
      zip_code: "91302",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "31034",
      city: "Calexico",
      zip_code: "92231",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "31423",
      city: "Caliente",
      zip_code: "93518",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "31416",
      city: "California City",
      zip_code: "93505",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "31307",
      city: "California Hot Springs",
      zip_code: "93207",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "31085",
      city: "Calimesa",
      zip_code: "92320",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "31035",
      city: "Calipatria",
      zip_code: "92233",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "31646",
      city: "Calistoga",
      zip_code: "94515",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "32294",
      city: "Callahan",
      zip_code: "96014",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "31271",
      city: "Camarillo",
      zip_code: "93010",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "31389",
      city: "Cambria",
      zip_code: "93428",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "32165",
      city: "Camino",
      zip_code: "95709",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "31971",
      city: "Camp Meeker",
      zip_code: "95419",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "30956",
      city: "Camp Pendleton",
      zip_code: "92055",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "31788",
      city: "Campbell",
      zip_code: "95008",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "30913",
      city: "Campo",
      zip_code: "91906",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "31868",
      city: "Campo Seco",
      zip_code: "95226",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "32226",
      city: "Camptonville",
      zip_code: "95922",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "30806",
      city: "Canoga Park",
      zip_code: "91303",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "31424",
      city: "Cantil",
      zip_code: "93519",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "31463",
      city: "Cantua Creek",
      zip_code: "93608",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "31647",
      city: "Canyon",
      zip_code: "94516",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "30826",
      city: "Canyon Country",
      zip_code: "91351",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "32227",
      city: "Canyon Dam",
      zip_code: "95923",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "32082",
      city: "Capay",
      zip_code: "95607",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "31193",
      city: "Capistrano Beach",
      zip_code: "92624",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "31789",
      city: "Capitola",
      zip_code: "95010",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "30937",
      city: "Cardiff By The Sea",
      zip_code: "92007",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "32039",
      city: "Carlotta",
      zip_code: "95528",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "30938",
      city: "Carlsbad",
      zip_code: "92008",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "31543",
      city: "Carmel",
      zip_code: "93923",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "31542",
      city: "Carmel By The Sea",
      zip_code: "93921",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "31544",
      city: "Carmel Valley",
      zip_code: "93924",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "32083",
      city: "Carmichael",
      zip_code: "95608",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "32392",
      city: "Carnelian Bay",
      zip_code: "96140",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "31273",
      city: "Carpinteria",
      zip_code: "93013",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "30755",
      city: "Carson",
      zip_code: "90745",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "31464",
      city: "Caruthers",
      zip_code: "93609",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "31390",
      city: "Casmalia",
      zip_code: "93429",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "31972",
      city: "Caspar",
      zip_code: "95420",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "32296",
      city: "Cassel",
      zip_code: "96016",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "30839",
      city: "Castaic",
      zip_code: "91384",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "32297",
      city: "Castella",
      zip_code: "96017",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "31669",
      city: "Castro Valley",
      zip_code: "94546",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "31790",
      city: "Castroville",
      zip_code: "95012",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "31036",
      city: "Cathedral City",
      zip_code: "92234",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "31895",
      city: "Catheys Valley",
      zip_code: "95306",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "31391",
      city: "Cayucos",
      zip_code: "93430",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "31973",
      city: "Cazadero",
      zip_code: "95421",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "31086",
      city: "Cedar Glen",
      zip_code: "92321",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "31087",
      city: "Cedarpines Park",
      zip_code: "92322",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "31896",
      city: "Ceres",
      zip_code: "95307",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "30738",
      city: "Cerritos",
      zip_code: "90703",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "32228",
      city: "Challenge",
      zip_code: "95925",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "30810",
      city: "Chatsworth",
      zip_code: "91311",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "32299",
      city: "Chester",
      zip_code: "96020",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "32229",
      city: "Chico",
      zip_code: "95926",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "31897",
      city: "Chinese Camp",
      zip_code: "95309",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "30865",
      city: "Chino",
      zip_code: "91708",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "30866",
      city: "Chino Hills",
      zip_code: "91709",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "31465",
      city: "Chowchilla",
      zip_code: "93610",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "31545",
      city: "Chualar",
      zip_code: "93925",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "30914",
      city: "Chula Vista",
      zip_code: "91910",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "32084",
      city: "Citrus Heights",
      zip_code: "95610",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "30868",
      city: "Claremont",
      zip_code: "91711",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "32085",
      city: "Clarksburg",
      zip_code: "95612",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "31648",
      city: "Clayton",
      zip_code: "94517",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "31974",
      city: "Clearlake",
      zip_code: "95422",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "31975",
      city: "Clearlake Oaks",
      zip_code: "95423",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "31869",
      city: "Clements",
      zip_code: "95227",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "32232",
      city: "Clipper Mills",
      zip_code: "95930",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "31976",
      city: "Cloverdale",
      zip_code: "95425",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "31466",
      city: "Clovis",
      zip_code: "93611",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "31037",
      city: "Coachella",
      zip_code: "92236",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "31309",
      city: "Coalinga",
      zip_code: "93210",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "31468",
      city: "Coarsegold",
      zip_code: "93614",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "31977",
      city: "Cobb",
      zip_code: "95426",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "32166",
      city: "Colfax",
      zip_code: "95713",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "32086",
      city: "Coloma",
      zip_code: "95613",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "31088",
      city: "Colton",
      zip_code: "92324",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "31898",
      city: "Columbia",
      zip_code: "95310",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "32233",
      city: "Colusa",
      zip_code: "95932",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "31978",
      city: "Comptche",
      zip_code: "95427",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "30672",
      city: "Compton",
      zip_code: "90220",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "31649",
      city: "Concord",
      zip_code: "94518",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "32087",
      city: "Cool",
      zip_code: "95614",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "31870",
      city: "Copperopolis",
      zip_code: "95228",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "31310",
      city: "Corcoran",
      zip_code: "93212",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "32300",
      city: "Corning",
      zip_code: "96021",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "31261",
      city: "Corona",
      zip_code: "92879",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "31194",
      city: "Corona Del Mar",
      zip_code: "92625",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "30997",
      city: "Coronado",
      zip_code: "92118",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "31753",
      city: "Corte Madera",
      zip_code: "94925",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "31195",
      city: "Costa Mesa",
      zip_code: "92626",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "31757",
      city: "Cotati",
      zip_code: "94931",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "32301",
      city: "Cottonwood",
      zip_code: "96022",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "31899",
      city: "Coulterville",
      zip_code: "95311",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "32088",
      city: "Courtland",
      zip_code: "95615",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "31979",
      city: "Covelo",
      zip_code: "95428",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "30869",
      city: "Covina",
      zip_code: "91722",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "31791",
      city: "Coyote",
      zip_code: "95013",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "32040",
      city: "Crescent City",
      zip_code: "95531",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "32234",
      city: "Crescent Mills",
      zip_code: "95934",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "31900",
      city: "Cressey",
      zip_code: "95312",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "31089",
      city: "Crestline",
      zip_code: "92325",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "31392",
      city: "Creston",
      zip_code: "93432",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "31654",
      city: "Crockett",
      zip_code: "94525",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "31901",
      city: "Crows Landing",
      zip_code: "95313",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "30675",
      city: "Culver City",
      zip_code: "90230",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "31792",
      city: "Cupertino",
      zip_code: "95014",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "31469",
      city: "Cutler",
      zip_code: "93615",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "30728",
      city: "Cypress",
      zip_code: "90630",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "31090",
      city: "Daggett",
      zip_code: "92327",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "31564",
      city: "Daly City",
      zip_code: "94014",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "31197",
      city: "Dana Point",
      zip_code: "92629",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "31637",
      city: "Danville",
      zip_code: "94506",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "31793",
      city: "Davenport",
      zip_code: "95017",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "32089",
      city: "Davis",
      zip_code: "95616",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "31696",
      city: "Deer Park",
      zip_code: "94576",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "30942",
      city: "Del Mar",
      zip_code: "92014",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "31470",
      city: "Del Rey",
      zip_code: "93616",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "31311",
      city: "Delano",
      zip_code: "93215",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "31902",
      city: "Delhi",
      zip_code: "95315",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "31903",
      city: "Denair",
      zip_code: "95316",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "30919",
      city: "Descanso",
      zip_code: "91916",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "31038",
      city: "Desert Center",
      zip_code: "92239",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "31039",
      city: "Desert Hot Springs",
      zip_code: "92240",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "31656",
      city: "Diablo",
      zip_code: "94528",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "30893",
      city: "Diamond Bar",
      zip_code: "91765",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "32091",
      city: "Diamond Springs",
      zip_code: "95619",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "31755",
      city: "Dillon Beach",
      zip_code: "94929",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "31471",
      city: "Dinuba",
      zip_code: "93618",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "31636",
      city: "Discovery Bay",
      zip_code: "94505",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "32092",
      city: "Dixon",
      zip_code: "95620",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "32235",
      city: "Dobbins",
      zip_code: "95935",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "32302",
      city: "Dorris",
      zip_code: "96023",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "31473",
      city: "Dos Palos",
      zip_code: "93620",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "31980",
      city: "Dos Rios",
      zip_code: "95429",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "32303",
      city: "Douglas City",
      zip_code: "96024",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "30677",
      city: "Downey",
      zip_code: "90240",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "32236",
      city: "Downieville",
      zip_code: "95936",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "32162",
      city: "Drytown",
      zip_code: "95699",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "30776",
      city: "Duarte",
      zip_code: "91008",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "31689",
      city: "Dublin",
      zip_code: "94568",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "31312",
      city: "Ducor",
      zip_code: "93218",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "30920",
      city: "Dulzura",
      zip_code: "91917",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "31981",
      city: "Duncans Mills",
      zip_code: "95430",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "31474",
      city: "Dunlap",
      zip_code: "93621",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "32237",
      city: "Dunnigan",
      zip_code: "95937",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "32304",
      city: "Dunsmuir",
      zip_code: "96025",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "32238",
      city: "Durham",
      zip_code: "95938",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "32167",
      city: "Dutch Flat",
      zip_code: "95714",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "31313",
      city: "Earlimart",
      zip_code: "93219",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "32171",
      city: "Echo Lake",
      zip_code: "95721",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "31314",
      city: "Edison",
      zip_code: "93220",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "31426",
      city: "Edwards",
      zip_code: "93523",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "30943",
      city: "El Cajon",
      zip_code: "92019",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "31042",
      city: "El Centro",
      zip_code: "92243",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "31657",
      city: "El Cerrito",
      zip_code: "94530",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "32094",
      city: "El Dorado",
      zip_code: "95623",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "32182",
      city: "El Dorado Hills",
      zip_code: "95762",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "31566",
      city: "El Granada",
      zip_code: "94018",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "30873",
      city: "El Monte",
      zip_code: "91731",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "31904",
      city: "El Nido",
      zip_code: "95317",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "31905",
      city: "El Portal",
      zip_code: "95318",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "30680",
      city: "El Segundo",
      zip_code: "90245",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "31742",
      city: "El Sobrante",
      zip_code: "94803",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "31982",
      city: "Eldridge",
      zip_code: "95431",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "31983",
      city: "Elk",
      zip_code: "95432",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "32239",
      city: "Elk Creek",
      zip_code: "95939",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "32095",
      city: "Elk Grove",
      zip_code: "95624",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "32096",
      city: "Elmira",
      zip_code: "95625",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "32097",
      city: "Elverta",
      zip_code: "95626",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "31722",
      city: "Emeryville",
      zip_code: "94608",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "32168",
      city: "Emigrant Gap",
      zip_code: "95715",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "31906",
      city: "Empire",
      zip_code: "95319",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "30946",
      city: "Encinitas",
      zip_code: "92024",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "30811",
      city: "Encino",
      zip_code: "91316",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "31907",
      city: "Escalon",
      zip_code: "95320",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "30947",
      city: "Escondido",
      zip_code: "92025",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "32098",
      city: "Esparto",
      zip_code: "95627",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "31092",
      city: "Essex",
      zip_code: "92332",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "32029",
      city: "Eureka",
      zip_code: "95501",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "31315",
      city: "Exeter",
      zip_code: "93221",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "32099",
      city: "Fair Oaks",
      zip_code: "95628",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "31756",
      city: "Fairfax",
      zip_code: "94930",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "31659",
      city: "Fairfield",
      zip_code: "94533",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "32306",
      city: "Fall River Mills",
      zip_code: "96028",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "30950",
      city: "Fallbrook",
      zip_code: "92028",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "31317",
      city: "Farmersville",
      zip_code: "93223",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "31871",
      city: "Farmington",
      zip_code: "95230",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "31093",
      city: "Fawnskin",
      zip_code: "92333",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "31318",
      city: "Fellows",
      zip_code: "93224",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "31794",
      city: "Felton",
      zip_code: "95018",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "32041",
      city: "Ferndale",
      zip_code: "95536",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "32100",
      city: "Fiddletown",
      zip_code: "95629",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "32042",
      city: "Fields Landing",
      zip_code: "95537",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "31274",
      city: "Fillmore",
      zip_code: "93015",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "31984",
      city: "Finley",
      zip_code: "95435",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "31475",
      city: "Firebaugh",
      zip_code: "93622",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "31476",
      city: "Fish Camp",
      zip_code: "93623",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "31477",
      city: "Five Points",
      zip_code: "93624",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "32367",
      city: "Floriston",
      zip_code: "96111",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "32307",
      city: "Flournoy",
      zip_code: "96029",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "32101",
      city: "Folsom",
      zip_code: "95630",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "31094",
      city: "Fontana",
      zip_code: "92335",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "31187",
      city: "Foothill Ranch",
      zip_code: "92610",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "32240",
      city: "Forbestown",
      zip_code: "95941",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "31098",
      city: "Forest Falls",
      zip_code: "92339",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "31758",
      city: "Forest Knolls",
      zip_code: "94933",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "32241",
      city: "Forest Ranch",
      zip_code: "95942",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "32102",
      city: "Foresthill",
      zip_code: "95631",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "31985",
      city: "Forestville",
      zip_code: "95436",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "32308",
      city: "Forks Of Salmon",
      zip_code: "96031",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "31986",
      city: "Fort Bragg",
      zip_code: "95437",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "31078",
      city: "Fort Irwin",
      zip_code: "92310",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "32044",
      city: "Fortuna",
      zip_code: "95540",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "31232",
      city: "Fountain Valley",
      zip_code: "92708",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "31478",
      city: "Fowler",
      zip_code: "93625",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "31319",
      city: "Frazier Park",
      zip_code: "93225",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "31795",
      city: "Freedom",
      zip_code: "95019",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "31662",
      city: "Fremont",
      zip_code: "94536",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "31872",
      city: "French Camp",
      zip_code: "95231",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "32310",
      city: "French Gulch",
      zip_code: "96033",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "31498",
      city: "Fresno",
      zip_code: "93650",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "31479",
      city: "Friant",
      zip_code: "93626",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "31244",
      city: "Fullerton",
      zip_code: "92831",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "31987",
      city: "Fulton",
      zip_code: "95439",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "32103",
      city: "Galt",
      zip_code: "95632",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "32045",
      city: "Garberville",
      zip_code: "95542",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "31248",
      city: "Garden Grove",
      zip_code: "92840",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "32104",
      city: "Garden Valley",
      zip_code: "95633",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "30681",
      city: "Gardena",
      zip_code: "90247",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "32105",
      city: "Georgetown",
      zip_code: "95634",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "32312",
      city: "Gerber",
      zip_code: "96035",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "31988",
      city: "Geyserville",
      zip_code: "95441",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "31796",
      city: "Gilroy",
      zip_code: "95020",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "31989",
      city: "Glen Ellen",
      zip_code: "95442",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "31873",
      city: "Glencoe",
      zip_code: "95232",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "30794",
      city: "Glendale",
      zip_code: "91201",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "30878",
      city: "Glendora",
      zip_code: "91740",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "31990",
      city: "Glenhaven",
      zip_code: "95443",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "32242",
      city: "Glenn",
      zip_code: "95943",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "31320",
      city: "Glennville",
      zip_code: "93226",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "32169",
      city: "Gold Run",
      zip_code: "95717",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "31300",
      city: "Goleta",
      zip_code: "93117",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "31546",
      city: "Gonzales",
      zip_code: "93926",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "32243",
      city: "Goodyears Bar",
      zip_code: "95944",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "31321",
      city: "Goshen",
      zip_code: "93227",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "30823",
      city: "Granada Hills",
      zip_code: "91344",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "31080",
      city: "Grand Terrace",
      zip_code: "92313",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "32178",
      city: "Granite Bay",
      zip_code: "95746",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "32244",
      city: "Grass Valley",
      zip_code: "95945",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "31991",
      city: "Graton",
      zip_code: "95444",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "31099",
      city: "Green Valley Lake",
      zip_code: "92341",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "31748",
      city: "Greenbrae",
      zip_code: "94904",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "31547",
      city: "Greenfield",
      zip_code: "93927",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "32246",
      city: "Greenville",
      zip_code: "95947",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "32106",
      city: "Greenwood",
      zip_code: "95635",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "32247",
      city: "Gridley",
      zip_code: "95948",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "32249",
      city: "Grimes",
      zip_code: "95950",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "32107",
      city: "Grizzly Flats",
      zip_code: "95636",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "31908",
      city: "Groveland",
      zip_code: "95321",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "31393",
      city: "Grover Beach",
      zip_code: "93433",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "31394",
      city: "Guadalupe",
      zip_code: "93434",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "31992",
      city: "Gualala",
      zip_code: "95445",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "30921",
      city: "Guatay",
      zip_code: "91931",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "31993",
      city: "Guerneville",
      zip_code: "95446",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "32108",
      city: "Guinda",
      zip_code: "95637",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "31909",
      city: "Gustine",
      zip_code: "95322",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "30881",
      city: "Hacienda Heights",
      zip_code: "91745",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "31567",
      city: "Half Moon Bay",
      zip_code: "94019",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "32250",
      city: "Hamilton City",
      zip_code: "95951",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "31322",
      city: "Hanford",
      zip_code: "93230",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "32315",
      city: "Happy Camp",
      zip_code: "96039",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "30741",
      city: "Harbor City",
      zip_code: "90710",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "32316",
      city: "Hat Creek",
      zip_code: "96040",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "31874",
      city: "Hathaway Pines",
      zip_code: "95233",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "30745",
      city: "Hawaiian Gardens",
      zip_code: "90716",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "30684",
      city: "Hawthorne",
      zip_code: "90250",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "32317",
      city: "Hayfork",
      zip_code: "96041",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "31665",
      city: "Hayward",
      zip_code: "94541",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "31994",
      city: "Healdsburg",
      zip_code: "95448",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "31043",
      city: "Heber",
      zip_code: "92249",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "31100",
      city: "Helendale",
      zip_code: "92342",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "31480",
      city: "Helm",
      zip_code: "93627",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "31157",
      city: "Hemet",
      zip_code: "92543",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "32109",
      city: "Herald",
      zip_code: "95638",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "31670",
      city: "Hercules",
      zip_code: "94547",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "30685",
      city: "Hermosa Beach",
      zip_code: "90254",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "31101",
      city: "Hesperia",
      zip_code: "92344",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "31910",
      city: "Hickman",
      zip_code: "95323",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "32012",
      city: "Hidden Valley Lake",
      zip_code: "95467",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "31103",
      city: "Highland",
      zip_code: "92346",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "31911",
      city: "Hilmar",
      zip_code: "95324",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "31104",
      city: "Hinkley",
      zip_code: "92347",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "31797",
      city: "Hollister",
      zip_code: "95023",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "31044",
      city: "Holtville",
      zip_code: "92250",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "31160",
      city: "Homeland",
      zip_code: "92548",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "32393",
      city: "Homewood",
      zip_code: "96141",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "32047",
      city: "Honeydew",
      zip_code: "95545",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "32110",
      city: "Hood",
      zip_code: "95639",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "32048",
      city: "Hoopa",
      zip_code: "95546",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "31995",
      city: "Hopland",
      zip_code: "95449",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "31912",
      city: "Hornitos",
      zip_code: "95325",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "31913",
      city: "Hughson",
      zip_code: "95326",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "31481",
      city: "Hume",
      zip_code: "93628",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "31200",
      city: "Huntington Beach",
      zip_code: "92646",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "30686",
      city: "Huntington Park",
      zip_code: "90255",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "31323",
      city: "Huron",
      zip_code: "93234",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "32319",
      city: "Hyampom",
      zip_code: "96046",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "32049",
      city: "Hydesville",
      zip_code: "95547",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "32320",
      city: "Igo",
      zip_code: "96047",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "31045",
      city: "Imperial",
      zip_code: "92251",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "30922",
      city: "Imperial Beach",
      zip_code: "91932",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "31428",
      city: "Independence",
      zip_code: "93526",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "31026",
      city: "Indian Wells",
      zip_code: "92210",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "31024",
      city: "Indio",
      zip_code: "92201",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "30703",
      city: "Inglewood",
      zip_code: "90301",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "31759",
      city: "Inverness",
      zip_code: "94937",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "31429",
      city: "Inyokern",
      zip_code: "93527",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "32111",
      city: "Ione",
      zip_code: "95640",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "31183",
      city: "Irvine",
      zip_code: "92602",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "32112",
      city: "Isleton",
      zip_code: "95641",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "31324",
      city: "Ivanhoe",
      zip_code: "93235",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "32113",
      city: "Jackson",
      zip_code: "95642",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "30923",
      city: "Jacumba",
      zip_code: "91934",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "31914",
      city: "Jamestown",
      zip_code: "95327",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "30924",
      city: "Jamul",
      zip_code: "91935",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "31996",
      city: "Jenner",
      zip_code: "95450",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "31430",
      city: "Johannesburg",
      zip_code: "93528",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "31548",
      city: "Jolon",
      zip_code: "93928",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "31046",
      city: "Joshua Tree",
      zip_code: "92252",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "30952",
      city: "Julian",
      zip_code: "92036",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "32321",
      city: "Junction City",
      zip_code: "96048",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "31150",
      city: "Jurupa Valley",
      zip_code: "92509",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "31433",
      city: "Keene",
      zip_code: "93531",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "31997",
      city: "Kelseyville",
      zip_code: "95451",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "31998",
      city: "Kenwood",
      zip_code: "95452",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "31482",
      city: "Kerman",
      zip_code: "93630",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "31326",
      city: "Kernville",
      zip_code: "93238",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "31327",
      city: "Kettleman City",
      zip_code: "93239",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "31915",
      city: "Keyes",
      zip_code: "95328",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "31549",
      city: "King City",
      zip_code: "93930",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "32395",
      city: "Kings Beach",
      zip_code: "96143",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "31484",
      city: "Kings Canyon National Pk",
      zip_code: "93633",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "31483",
      city: "Kingsburg",
      zip_code: "93631",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "32051",
      city: "Kneeland",
      zip_code: "95549",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "32114",
      city: "Knights Landing",
      zip_code: "95645",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "31671",
      city: "Knightsen",
      zip_code: "94548",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "32052",
      city: "Korbel",
      zip_code: "95550",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "32170",
      city: "Kyburz",
      zip_code: "95720",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "30778",
      city: "La Canada Flintridge",
      zip_code: "91011",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "30803",
      city: "La Crescenta",
      zip_code: "91214",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "31916",
      city: "La Grange",
      zip_code: "95329",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "30729",
      city: "La Habra",
      zip_code: "90631",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "31568",
      city: "La Honda",
      zip_code: "94020",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "30953",
      city: "La Jolla",
      zip_code: "92037",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "30925",
      city: "La Mesa",
      zip_code: "91941",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "30730",
      city: "La Mirada",
      zip_code: "90638",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "30727",
      city: "La Palma",
      zip_code: "90623",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "30880",
      city: "La Puente",
      zip_code: "91744",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "31047",
      city: "La Quinta",
      zip_code: "92253",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "30884",
      city: "La Verne",
      zip_code: "91750",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "31224",
      city: "Ladera Ranch",
      zip_code: "92694",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "31672",
      city: "Lafayette",
      zip_code: "94549",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "31204",
      city: "Laguna Beach",
      zip_code: "92651",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "31205",
      city: "Laguna Hills",
      zip_code: "92653",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "31217",
      city: "Laguna Niguel",
      zip_code: "92677",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "31199",
      city: "Laguna Woods",
      zip_code: "92637",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "31760",
      city: "Lagunitas",
      zip_code: "94938",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "31106",
      city: "Lake Arrowhead",
      zip_code: "92352",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "31153",
      city: "Lake Elsinore",
      zip_code: "92530",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "31198",
      city: "Lake Forest",
      zip_code: "92630",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "31434",
      city: "Lake Hughes",
      zip_code: "93532",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "31328",
      city: "Lake Isabella",
      zip_code: "93240",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "32323",
      city: "Lakehead",
      zip_code: "96051",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "31999",
      city: "Lakeport",
      zip_code: "95453",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "31485",
      city: "Lakeshore",
      zip_code: "93634",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "30954",
      city: "Lakeside",
      zip_code: "92040",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "30742",
      city: "Lakewood",
      zip_code: "90712",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "31329",
      city: "Lamont",
      zip_code: "93241",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "31435",
      city: "Lancaster",
      zip_code: "93534",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "31071",
      city: "Landers",
      zip_code: "92285",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "31761",
      city: "Larkspur",
      zip_code: "94939",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "31917",
      city: "Lathrop",
      zip_code: "95330",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "31330",
      city: "Laton",
      zip_code: "93242",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "30687",
      city: "Lawndale",
      zip_code: "90260",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "32000",
      city: "Laytonville",
      zip_code: "95454",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "31918",
      city: "Le Grand",
      zip_code: "95333",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "31331",
      city: "Lebec",
      zip_code: "93243",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "32072",
      city: "Leggett",
      zip_code: "95585",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "30927",
      city: "Lemon Grove",
      zip_code: "91945",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "31333",
      city: "Lemoore",
      zip_code: "93245",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "32324",
      city: "Lewiston",
      zip_code: "96052",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "32116",
      city: "Lincoln",
      zip_code: "95648",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "31875",
      city: "Linden",
      zip_code: "95236",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "31335",
      city: "Lindsay",
      zip_code: "93247",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "32001",
      city: "Little River",
      zip_code: "95456",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "31439",
      city: "Littlerock",
      zip_code: "93543",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "32251",
      city: "Live Oak",
      zip_code: "95953",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "31673",
      city: "Livermore",
      zip_code: "94550",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "31919",
      city: "Livingston",
      zip_code: "95334",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "31440",
      city: "Llano",
      zip_code: "93544",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "31876",
      city: "Lockeford",
      zip_code: "95237",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "31550",
      city: "Lockwood",
      zip_code: "93932",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "31877",
      city: "Lodi",
      zip_code: "95240",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "32053",
      city: "Loleta",
      zip_code: "95551",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "31105",
      city: "Loma Linda",
      zip_code: "92350",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "31569",
      city: "Loma Mar",
      zip_code: "94021",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "30746",
      city: "Lomita",
      zip_code: "90717",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "31395",
      city: "Lompoc",
      zip_code: "93436",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "31920",
      city: "Long Barn",
      zip_code: "95335",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "30759",
      city: "Long Beach",
      zip_code: "90802",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "32117",
      city: "Loomis",
      zip_code: "95650",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "30747",
      city: "Los Alamitos",
      zip_code: "90720",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "31397",
      city: "Los Alamos",
      zip_code: "93440",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "31570",
      city: "Los Altos",
      zip_code: "94022",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "30600",
      city: "Los Angeles",
      zip_code: "90001",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "31486",
      city: "Los Banos",
      zip_code: "93635",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "31798",
      city: "Los Gatos",
      zip_code: "95030",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "32326",
      city: "Los Molinos",
      zip_code: "96055",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "31398",
      city: "Los Olivos",
      zip_code: "93441",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "31378",
      city: "Los Osos",
      zip_code: "93402",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "31336",
      city: "Lost Hills",
      zip_code: "93249",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "32118",
      city: "Lotus",
      zip_code: "95651",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "32002",
      city: "Lower Lake",
      zip_code: "95457",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "32003",
      city: "Lucerne",
      zip_code: "95458",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "31108",
      city: "Lucerne Valley",
      zip_code: "92356",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "31097",
      city: "Ludlow",
      zip_code: "92338",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "30688",
      city: "Lynwood",
      zip_code: "90262",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "31109",
      city: "Lytle Creek",
      zip_code: "92358",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "32054",
      city: "Mad River",
      zip_code: "95552",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "31487",
      city: "Madera",
      zip_code: "93636",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "32120",
      city: "Madison",
      zip_code: "95653",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "32252",
      city: "Magalia",
      zip_code: "95954",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "30689",
      city: "Malibu",
      zip_code: "90263",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "32004",
      city: "Manchester",
      zip_code: "95459",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "30691",
      city: "Manhattan Beach",
      zip_code: "90266",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "31921",
      city: "Manteca",
      zip_code: "95336",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "32330",
      city: "Manton",
      zip_code: "96059",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "31151",
      city: "March Air Reserve Base",
      zip_code: "92518",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "31339",
      city: "Maricopa",
      zip_code: "93252",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "31551",
      city: "Marina",
      zip_code: "93933",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "30701",
      city: "Marina Del Rey",
      zip_code: "90292",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "31923",
      city: "Mariposa",
      zip_code: "95338",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "32376",
      city: "Markleeville",
      zip_code: "96120",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "31762",
      city: "Marshall",
      zip_code: "94940",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "31676",
      city: "Martinez",
      zip_code: "94553",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "32214",
      city: "Marysville",
      zip_code: "95901",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "32121",
      city: "Mather",
      zip_code: "95655",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "32253",
      city: "Maxwell",
      zip_code: "95955",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "30692",
      city: "Maywood",
      zip_code: "90270",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "32327",
      city: "Mcarthur",
      zip_code: "96056",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "32119",
      city: "Mcclellan",
      zip_code: "95652",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "31337",
      city: "McFarland",
      zip_code: "93250",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "32033",
      city: "Mckinleyville",
      zip_code: "95519",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "31338",
      city: "McKittrick",
      zip_code: "93251",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "32254",
      city: "Meadow Valley",
      zip_code: "95956",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "32172",
      city: "Meadow Vista",
      zip_code: "95722",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "31048",
      city: "Mecca",
      zip_code: "92254",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "32005",
      city: "Mendocino",
      zip_code: "95460",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "31490",
      city: "Mendota",
      zip_code: "93640",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "31174",
      city: "Menifee",
      zip_code: "92584",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "31572",
      city: "Menlo Park",
      zip_code: "94025",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "31110",
      city: "Mentone",
      zip_code: "92359",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "31924",
      city: "Merced",
      zip_code: "95340",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "32255",
      city: "Meridian",
      zip_code: "95957",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "31927",
      city: "Mi Wuk Village",
      zip_code: "95346",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "32006",
      city: "Middletown",
      zip_code: "95461",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "31926",
      city: "Midpines",
      zip_code: "95345",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "31206",
      city: "Midway City",
      zip_code: "92655",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "32331",
      city: "Mill Creek",
      zip_code: "96061",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "31763",
      city: "Mill Valley",
      zip_code: "94941",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "31575",
      city: "Millbrae",
      zip_code: "94030",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "32332",
      city: "Millville",
      zip_code: "96062",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "31801",
      city: "Milpitas",
      zip_code: "95035",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "32333",
      city: "Mineral",
      zip_code: "96063",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "30885",
      city: "Mira Loma",
      zip_code: "91752",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "31491",
      city: "Miramonte",
      zip_code: "93641",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "32055",
      city: "Miranda",
      zip_code: "95553",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "30824",
      city: "Mission Hills",
      zip_code: "91345",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "31222",
      city: "Mission Viejo",
      zip_code: "92691",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "31929",
      city: "Modesto",
      zip_code: "95350",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "31415",
      city: "Mojave",
      zip_code: "93501",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "31879",
      city: "Mokelumne Hill",
      zip_code: "95245",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "30779",
      city: "Monrovia",
      zip_code: "91016",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "32334",
      city: "Montague",
      zip_code: "96064",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "31576",
      city: "Montara",
      zip_code: "94037",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "30891",
      city: "Montclair",
      zip_code: "91763",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "32007",
      city: "Monte Rio",
      zip_code: "95462",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "30732",
      city: "Montebello",
      zip_code: "90640",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "31552",
      city: "Monterey",
      zip_code: "93940",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "30886",
      city: "Monterey Park",
      zip_code: "91754",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "32335",
      city: "Montgomery Creek",
      zip_code: "96065",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "30780",
      city: "Montrose",
      zip_code: "91020",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "31275",
      city: "Moorpark",
      zip_code: "93021",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "31678",
      city: "Moraga",
      zip_code: "94556",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "31162",
      city: "Moreno Valley",
      zip_code: "92551",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "31802",
      city: "Morgan Hill",
      zip_code: "95037",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "31049",
      city: "Morongo Valley",
      zip_code: "92256",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "31399",
      city: "Morro Bay",
      zip_code: "93442",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "31577",
      city: "Moss Beach",
      zip_code: "94038",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "31803",
      city: "Moss Landing",
      zip_code: "95039",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "31848",
      city: "Mount Hamilton",
      zip_code: "95140",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "31804",
      city: "Mount Hermon",
      zip_code: "95041",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "30928",
      city: "Mount Laguna",
      zip_code: "91948",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "31880",
      city: "Mountain Ranch",
      zip_code: "95246",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "31578",
      city: "Mountain View",
      zip_code: "94040",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "31881",
      city: "Murphys",
      zip_code: "95247",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "31167",
      city: "Murrieta",
      zip_code: "92562",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "32056",
      city: "Myers Flat",
      zip_code: "95554",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "31679",
      city: "Napa",
      zip_code: "94558",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "30929",
      city: "National City",
      zip_code: "91950",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "32008",
      city: "Navarro",
      zip_code: "95463",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "31111",
      city: "Needles",
      zip_code: "92363",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "32256",
      city: "Nelson",
      zip_code: "95958",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "32257",
      city: "Nevada City",
      zip_code: "95959",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "31340",
      city: "New Cuyama",
      zip_code: "93254",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "31681",
      city: "Newark",
      zip_code: "94560",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "31113",
      city: "Newberry Springs",
      zip_code: "92365",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "30812",
      city: "Newbury Park",
      zip_code: "91320",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "32122",
      city: "Newcastle",
      zip_code: "95658",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "30813",
      city: "Newhall",
      zip_code: "91321",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "31936",
      city: "Newman",
      zip_code: "95360",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "31209",
      city: "Newport Beach",
      zip_code: "92660",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "31208",
      city: "Newport Coast",
      zip_code: "92657",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "31765",
      city: "Nicasio",
      zip_code: "94946",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "32009",
      city: "Nice",
      zip_code: "95464",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "32123",
      city: "Nicolaus",
      zip_code: "95659",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "31050",
      city: "Niland",
      zip_code: "92257",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "31400",
      city: "Nipomo",
      zip_code: "93444",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "31112",
      city: "Nipton",
      zip_code: "92364",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "31253",
      city: "Norco",
      zip_code: "92860",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "31492",
      city: "North Fork",
      zip_code: "93643",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "32124",
      city: "North Highlands",
      zip_code: "95660",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "30822",
      city: "North Hills",
      zip_code: "91343",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "30855",
      city: "North Hollywood",
      zip_code: "91601",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "31051",
      city: "North Palm Springs",
      zip_code: "92258",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "32258",
      city: "North San Juan",
      zip_code: "95960",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "30814",
      city: "Northridge",
      zip_code: "91324",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "30733",
      city: "Norwalk",
      zip_code: "90650",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "31764",
      city: "Novato",
      zip_code: "94945",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "32337",
      city: "Nubieber",
      zip_code: "96068",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "31169",
      city: "Nuevo",
      zip_code: "92567",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "31494",
      city: "O'Neals",
      zip_code: "93645",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "30837",
      city: "Oak Park",
      zip_code: "91377",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "32338",
      city: "Oak Run",
      zip_code: "96069",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "31276",
      city: "Oak View",
      zip_code: "93022",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "31937",
      city: "Oakdale",
      zip_code: "95361",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "31493",
      city: "Oakhurst",
      zip_code: "93644",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "31716",
      city: "Oakland",
      zip_code: "94601",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "31682",
      city: "Oakley",
      zip_code: "94561",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "31683",
      city: "Oakville",
      zip_code: "94562",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "32010",
      city: "Occidental",
      zip_code: "95465",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "31401",
      city: "Oceano",
      zip_code: "93445",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "30955",
      city: "Oceanside",
      zip_code: "92054",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "31052",
      city: "Ocotillo",
      zip_code: "92259",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "31277",
      city: "Ojai",
      zip_code: "93023",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "32339",
      city: "Old Station",
      zip_code: "96071",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "31768",
      city: "Olema",
      zip_code: "94950",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "32259",
      city: "Olivehurst",
      zip_code: "95961",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "32397",
      city: "Olympic Valley",
      zip_code: "96146",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "30889",
      city: "Ontario",
      zip_code: "91761",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "31341",
      city: "Onyx",
      zip_code: "93255",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "31255",
      city: "Orange",
      zip_code: "92865",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "31495",
      city: "Orange Cove",
      zip_code: "93646",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "32126",
      city: "Orangevale",
      zip_code: "95662",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "32260",
      city: "Oregon House",
      zip_code: "95962",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "32057",
      city: "Orick",
      zip_code: "95555",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "31684",
      city: "Orinda",
      zip_code: "94563",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "32261",
      city: "Orland",
      zip_code: "95963",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "32058",
      city: "Orleans",
      zip_code: "95556",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "31114",
      city: "Oro Grande",
      zip_code: "92368",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "31496",
      city: "Orosi",
      zip_code: "93647",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "32262",
      city: "Oroville",
      zip_code: "95965",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "31278",
      city: "Oxnard",
      zip_code: "93030",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "31555",
      city: "Pacific Grove",
      zip_code: "93950",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "30693",
      city: "Pacific Palisades",
      zip_code: "90272",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "31581",
      city: "Pacifica",
      zip_code: "94044",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "30818",
      city: "Pacoima",
      zip_code: "91331",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "31805",
      city: "Paicines",
      zip_code: "95043",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "30960",
      city: "Pala",
      zip_code: "92059",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "32264",
      city: "Palermo",
      zip_code: "95968",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "31027",
      city: "Palm Desert",
      zip_code: "92211",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "31054",
      city: "Palm Springs",
      zip_code: "92262",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "31444",
      city: "Palmdale",
      zip_code: "93550",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "31624",
      city: "Palo Alto",
      zip_code: "94301",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "32340",
      city: "Palo Cedro",
      zip_code: "96073",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "30961",
      city: "Palomar Mountain",
      zip_code: "92060",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "30694",
      city: "Palos Verdes Peninsula",
      zip_code: "90274",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "30843",
      city: "Panorama City",
      zip_code: "91402",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "32265",
      city: "Paradise",
      zip_code: "95969",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "30748",
      city: "Paramount",
      zip_code: "90723",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "31057",
      city: "Parker Dam",
      zip_code: "92267",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "31497",
      city: "Parlier",
      zip_code: "93648",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "30786",
      city: "Pasadena",
      zip_code: "91101",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "32341",
      city: "Paskenta",
      zip_code: "96074",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "31402",
      city: "Paso Robles",
      zip_code: "93446",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "31938",
      city: "Patterson",
      zip_code: "95363",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "31115",
      city: "Patton",
      zip_code: "92369",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "30962",
      city: "Pauma Valley",
      zip_code: "92061",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "32342",
      city: "Paynes Creek",
      zip_code: "96075",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "31447",
      city: "Pearblossom",
      zip_code: "93553",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "31556",
      city: "Pebble Beach",
      zip_code: "93953",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "32245",
      city: "Penn Valley",
      zip_code: "95946",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "31769",
      city: "Penngrove",
      zip_code: "94951",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "32127",
      city: "Penryn",
      zip_code: "95663",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "31170",
      city: "Perris",
      zip_code: "92570",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "31582",
      city: "Pescadero",
      zip_code: "94060",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "31770",
      city: "Petaluma",
      zip_code: "94952",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "32059",
      city: "Petrolia",
      zip_code: "95558",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "31116",
      city: "Phelan",
      zip_code: "92371",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "32060",
      city: "Phillipsville",
      zip_code: "95559",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "32011",
      city: "Philo",
      zip_code: "95466",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "30734",
      city: "Pico Rivera",
      zip_code: "90660",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "32073",
      city: "Piercy",
      zip_code: "95587",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "32128",
      city: "Pilot Hill",
      zip_code: "95664",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "32129",
      city: "Pine Grove",
      zip_code: "95665",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "31316",
      city: "Pine Mountain Club",
      zip_code: "93222",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "30930",
      city: "Pine Valley",
      zip_code: "91962",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "31939",
      city: "Pinecrest",
      zip_code: "95364",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "31685",
      city: "Pinole",
      zip_code: "94564",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "31117",
      city: "Pinon Hills",
      zip_code: "92372",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "32130",
      city: "Pioneer",
      zip_code: "95666",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "31058",
      city: "Pioneertown",
      zip_code: "92268",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "31282",
      city: "Piru",
      zip_code: "93040",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "31403",
      city: "Pismo Beach",
      zip_code: "93449",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "31686",
      city: "Pittsburg",
      zip_code: "94565",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "31342",
      city: "Pixley",
      zip_code: "93256",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "31260",
      city: "Placentia",
      zip_code: "92870",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "32131",
      city: "Placerville",
      zip_code: "95667",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "31940",
      city: "Planada",
      zip_code: "95365",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "32343",
      city: "Platina",
      zip_code: "96076",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "30702",
      city: "Playa Del Rey",
      zip_code: "90293",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "30666",
      city: "Playa Vista",
      zip_code: "90094",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "32132",
      city: "Pleasant Grove",
      zip_code: "95668",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "31653",
      city: "Pleasant Hill",
      zip_code: "94523",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "31687",
      city: "Pleasanton",
      zip_code: "94566",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "32133",
      city: "Plymouth",
      zip_code: "95669",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "32013",
      city: "Point Arena",
      zip_code: "95468",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "31284",
      city: "Point Mugu Nawc",
      zip_code: "93042",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "31772",
      city: "Point Reyes Station",
      zip_code: "94956",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "32173",
      city: "Pollock Pines",
      zip_code: "95726",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "30894",
      city: "Pomona",
      zip_code: "91766",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "31688",
      city: "Pope Valley",
      zip_code: "94567",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "31690",
      city: "Port Costa",
      zip_code: "94569",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "31283",
      city: "Port Hueneme",
      zip_code: "93041",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "31285",
      city: "Port Hueneme Cbc Base",
      zip_code: "93043",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "30816",
      city: "Porter Ranch",
      zip_code: "91326",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "31343",
      city: "Porterville",
      zip_code: "93257",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "31574",
      city: "Portola Valley",
      zip_code: "94028",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "31345",
      city: "Posey",
      zip_code: "93260",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "30931",
      city: "Potrero",
      zip_code: "91963",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "32014",
      city: "Potter Valley",
      zip_code: "95469",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "30963",
      city: "Poway",
      zip_code: "92064",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "31499",
      city: "Prather",
      zip_code: "93651",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "32266",
      city: "Princeton",
      zip_code: "95970",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "32344",
      city: "Proberta",
      zip_code: "96078",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "32267",
      city: "Quincy",
      zip_code: "95971",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "32268",
      city: "Rackerby",
      zip_code: "95972",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "31882",
      city: "Rail Road Flat",
      zip_code: "95248",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "31500",
      city: "Raisin City",
      zip_code: "93652",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "30964",
      city: "Ramona",
      zip_code: "92065",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "30965",
      city: "Ranchita",
      zip_code: "92066",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "32134",
      city: "Rancho Cordova",
      zip_code: "95670",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "30862",
      city: "Rancho Cucamonga",
      zip_code: "91701",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "31059",
      city: "Rancho Mirage",
      zip_code: "92270",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "30695",
      city: "Rancho Palos Verdes",
      zip_code: "90275",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "30966",
      city: "Rancho Santa Fe",
      zip_code: "92067",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "31221",
      city: "Rancho Santa Margarita",
      zip_code: "92688",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "31448",
      city: "Randsburg",
      zip_code: "93554",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "31501",
      city: "Raymond",
      zip_code: "93653",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "32345",
      city: "Red Bluff",
      zip_code: "96080",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "31450",
      city: "Red Mountain",
      zip_code: "93558",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "32068",
      city: "Redcrest",
      zip_code: "95569",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "32284",
      city: "Redding",
      zip_code: "96001",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "31118",
      city: "Redlands",
      zip_code: "92373",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "30696",
      city: "Redondo Beach",
      zip_code: "90277",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "32061",
      city: "Redway",
      zip_code: "95560",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "31583",
      city: "Redwood City",
      zip_code: "94061",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "32015",
      city: "Redwood Valley",
      zip_code: "95470",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "31502",
      city: "Reedley",
      zip_code: "93654",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "32135",
      city: "Represa",
      zip_code: "95671",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "32136",
      city: "Rescue",
      zip_code: "95672",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "30819",
      city: "Reseda",
      zip_code: "91335",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "31120",
      city: "Rialto",
      zip_code: "92376",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "31346",
      city: "Richgrove",
      zip_code: "93261",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "31741",
      city: "Richmond",
      zip_code: "94801",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "32270",
      city: "Richvale",
      zip_code: "95974",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "31449",
      city: "Ridgecrest",
      zip_code: "93555",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "31122",
      city: "Rimforest",
      zip_code: "92378",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "32062",
      city: "Rio Dell",
      zip_code: "95562",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "32137",
      city: "Rio Linda",
      zip_code: "95673",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "32016",
      city: "Rio Nido",
      zip_code: "95471",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "32138",
      city: "Rio Oso",
      zip_code: "95674",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "31691",
      city: "Rio Vista",
      zip_code: "94571",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "31941",
      city: "Ripon",
      zip_code: "95366",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "32139",
      city: "River Pines",
      zip_code: "95675",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "31942",
      city: "Riverbank",
      zip_code: "95367",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "31503",
      city: "Riverdale",
      zip_code: "93656",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "31143",
      city: "Riverside",
      zip_code: "92501",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "32140",
      city: "Robbins",
      zip_code: "95676",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "32141",
      city: "Rocklin",
      zip_code: "95677",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "31692",
      city: "Rodeo",
      zip_code: "94572",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "31754",
      city: "Rohnert Park",
      zip_code: "94928",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "31451",
      city: "Rosamond",
      zip_code: "93560",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "30897",
      city: "Rosemead",
      zip_code: "91770",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "32125",
      city: "Roseville",
      zip_code: "95661",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "31773",
      city: "Ross",
      zip_code: "94957",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "32271",
      city: "Rough And Ready",
      zip_code: "95975",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "32346",
      city: "Round Mountain",
      zip_code: "96084",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "30883",
      city: "Rowland Heights",
      zip_code: "91748",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "32143",
      city: "Rumsey",
      zip_code: "95679",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "31123",
      city: "Running Springs",
      zip_code: "92382",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "31693",
      city: "Rutherford",
      zip_code: "94573",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "32144",
      city: "Ryde",
      zip_code: "95680",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "32185",
      city: "Sacramento",
      zip_code: "95811",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "31694",
      city: "Saint Helena",
      zip_code: "94574",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "31943",
      city: "Salida",
      zip_code: "95368",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "31536",
      city: "Salinas",
      zip_code: "93901",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "32063",
      city: "Salyer",
      zip_code: "95563",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "32064",
      city: "Samoa",
      zip_code: "95564",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "31883",
      city: "San Andreas",
      zip_code: "95249",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "31774",
      city: "San Anselmo",
      zip_code: "94960",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "31404",
      city: "San Ardo",
      zip_code: "93450",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "31135",
      city: "San Bernardino",
      zip_code: "92401",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "31587",
      city: "San Bruno",
      zip_code: "94066",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "31588",
      city: "San Carlos",
      zip_code: "94070",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "31213",
      city: "San Clemente",
      zip_code: "92672",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "30981",
      city: "San Diego",
      zip_code: "92101",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "30898",
      city: "San Dimas",
      zip_code: "91773",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "30820",
      city: "San Fernando",
      zip_code: "91340",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "31595",
      city: "San Francisco",
      zip_code: "94102",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "30899",
      city: "San Gabriel",
      zip_code: "91775",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "31775",
      city: "San Geronimo",
      zip_code: "94963",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "31589",
      city: "San Gregorio",
      zip_code: "94074",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "31172",
      city: "San Jacinto",
      zip_code: "92582",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "31505",
      city: "San Joaquin",
      zip_code: "93660",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "31821",
      city: "San Jose",
      zip_code: "95110",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "31806",
      city: "San Juan Bautista",
      zip_code: "95045",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "31215",
      city: "San Juan Capistrano",
      zip_code: "92675",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "31697",
      city: "San Leandro",
      zip_code: "94577",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "31700",
      city: "San Lorenzo",
      zip_code: "94580",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "31557",
      city: "San Lucas",
      zip_code: "93954",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "31377",
      city: "San Luis Obispo",
      zip_code: "93401",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "30967",
      city: "San Marcos",
      zip_code: "92069",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "30792",
      city: "San Marino",
      zip_code: "91108",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "31807",
      city: "San Martin",
      zip_code: "95046",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "31629",
      city: "San Mateo",
      zip_code: "94401",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "31405",
      city: "San Miguel",
      zip_code: "93451",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "31745",
      city: "San Pablo",
      zip_code: "94806",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "30749",
      city: "San Pedro",
      zip_code: "90731",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "31776",
      city: "San Quentin",
      zip_code: "94964",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "31746",
      city: "San Rafael",
      zip_code: "94901",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "31701",
      city: "San Ramon",
      zip_code: "94582",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "31406",
      city: "San Simeon",
      zip_code: "93452",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "31021",
      city: "San Ysidro",
      zip_code: "92173",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "31504",
      city: "Sanger",
      zip_code: "93657",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "31226",
      city: "Santa Ana",
      zip_code: "92701",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "31292",
      city: "Santa Barbara",
      zip_code: "93101",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "31808",
      city: "Santa Clara",
      zip_code: "95050",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "30825",
      city: "Santa Clarita",
      zip_code: "91350",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "31812",
      city: "Santa Cruz",
      zip_code: "95060",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "30735",
      city: "Santa Fe Springs",
      zip_code: "90670",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "31407",
      city: "Santa Margarita",
      zip_code: "93453",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "31408",
      city: "Santa Maria",
      zip_code: "93454",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "30708",
      city: "Santa Monica",
      zip_code: "90401",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "31286",
      city: "Santa Paula",
      zip_code: "93060",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "31961",
      city: "Santa Rosa",
      zip_code: "95401",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "31411",
      city: "Santa Ynez",
      zip_code: "93460",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "30968",
      city: "Santa Ysabel",
      zip_code: "92070",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "30969",
      city: "Santee",
      zip_code: "92071",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "31817",
      city: "Saratoga",
      zip_code: "95070",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "31777",
      city: "Sausalito",
      zip_code: "94965",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "32065",
      city: "Scotia",
      zip_code: "95565",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "31816",
      city: "Scotts Valley",
      zip_code: "95066",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "30751",
      city: "Seal Beach",
      zip_code: "90740",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "31558",
      city: "Seaside",
      zip_code: "93955",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "32017",
      city: "Sebastopol",
      zip_code: "95472",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "31060",
      city: "Seeley",
      zip_code: "92273",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "31506",
      city: "Selma",
      zip_code: "93662",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "31347",
      city: "Sequoia National Park",
      zip_code: "93262",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "31348",
      city: "Shafter",
      zip_code: "93263",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "31412",
      city: "Shandon",
      zip_code: "93461",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "32349",
      city: "Shasta",
      zip_code: "96087",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "32298",
      city: "Shasta Lake",
      zip_code: "96019",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "31507",
      city: "Shaver Lake",
      zip_code: "93664",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "32145",
      city: "Sheridan",
      zip_code: "95681",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "30844",
      city: "Sherman Oaks",
      zip_code: "91403",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "32146",
      city: "Shingle Springs",
      zip_code: "95682",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "32350",
      city: "Shingletown",
      zip_code: "96088",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "32381",
      city: "Sierra City",
      zip_code: "96125",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "30781",
      city: "Sierra Madre",
      zip_code: "91024",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "30758",
      city: "Signal Hill",
      zip_code: "90755",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "31216",
      city: "Silverado",
      zip_code: "92676",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "31287",
      city: "Simi Valley",
      zip_code: "93063",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "31125",
      city: "Skyforest",
      zip_code: "92385",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "32147",
      city: "Sloughhouse",
      zip_code: "95683",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "32272",
      city: "Smartsville",
      zip_code: "95977",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "31944",
      city: "Snelling",
      zip_code: "95369",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "32174",
      city: "Soda Springs",
      zip_code: "95728",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "30970",
      city: "Solana Beach",
      zip_code: "92075",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "31559",
      city: "Soledad",
      zip_code: "93960",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "31413",
      city: "Solvang",
      zip_code: "93463",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "32148",
      city: "Somerset",
      zip_code: "95684",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "32067",
      city: "Somes Bar",
      zip_code: "95568",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "31290",
      city: "Somis",
      zip_code: "93066",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "32018",
      city: "Sonoma",
      zip_code: "95476",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "31945",
      city: "Sonora",
      zip_code: "95370",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "31818",
      city: "Soquel",
      zip_code: "95073",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "31946",
      city: "Soulsbyville",
      zip_code: "95372",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "31508",
      city: "South Dos Palos",
      zip_code: "93665",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "30875",
      city: "South El Monte",
      zip_code: "91733",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "30698",
      city: "South Gate",
      zip_code: "90280",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "32399",
      city: "South Lake Tahoe",
      zip_code: "96150",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "30782",
      city: "South Pasadena",
      zip_code: "91030",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "31590",
      city: "South San Francisco",
      zip_code: "94080",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "31560",
      city: "Spreckels",
      zip_code: "93962",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "30932",
      city: "Spring Valley",
      zip_code: "91977",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "31349",
      city: "Springville",
      zip_code: "93265",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "31515",
      city: "Squaw Valley",
      zip_code: "93675",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "31627",
      city: "Stanford",
      zip_code: "94305",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "30736",
      city: "Stanton",
      zip_code: "90680",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "30838",
      city: "Stevenson Ranch",
      zip_code: "91381",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "31947",
      city: "Stevinson",
      zip_code: "95374",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "32019",
      city: "Stewarts Point",
      zip_code: "95480",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "31778",
      city: "Stinson Beach",
      zip_code: "94970",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "32273",
      city: "Stirling City",
      zip_code: "95978",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "31850",
      city: "Stockton",
      zip_code: "95202",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "32274",
      city: "Stonyford",
      zip_code: "95979",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "31350",
      city: "Stratford",
      zip_code: "93266",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "31351",
      city: "Strathmore",
      zip_code: "93267",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "31948",
      city: "Strawberry",
      zip_code: "95375",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "32275",
      city: "Strawberry Valley",
      zip_code: "95981",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "30857",
      city: "Studio City",
      zip_code: "91604",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "31126",
      city: "Sugarloaf",
      zip_code: "92386",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "31703",
      city: "Suisun City",
      zip_code: "94585",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "31509",
      city: "Sultana",
      zip_code: "93666",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "31291",
      city: "Summerland",
      zip_code: "93067",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "30827",
      city: "Sun Valley",
      zip_code: "91352",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "30783",
      city: "Sunland",
      zip_code: "91040",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "31591",
      city: "Sunnyvale",
      zip_code: "94085",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "31704",
      city: "Sunol",
      zip_code: "94586",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "30752",
      city: "Sunset Beach",
      zip_code: "90742",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "30753",
      city: "Surfside",
      zip_code: "90743",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "32385",
      city: "Susanville",
      zip_code: "96130",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "32276",
      city: "Sutter",
      zip_code: "95982",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "32149",
      city: "Sutter Creek",
      zip_code: "95685",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "30821",
      city: "Sylmar",
      zip_code: "91342",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "31352",
      city: "Taft",
      zip_code: "93268",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "32396",
      city: "Tahoe City",
      zip_code: "96145",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "32398",
      city: "Tahoe Vista",
      zip_code: "96148",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "32394",
      city: "Tahoma",
      zip_code: "96142",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "30830",
      city: "Tarzana",
      zip_code: "91356",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "32277",
      city: "Taylorsville",
      zip_code: "95983",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "30934",
      city: "Tecate",
      zip_code: "91980",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "31452",
      city: "Tehachapi",
      zip_code: "93561",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "32351",
      city: "Tehama",
      zip_code: "96090",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "31178",
      city: "Temecula",
      zip_code: "92590",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "30901",
      city: "Temple City",
      zip_code: "91780",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "31414",
      city: "Templeton",
      zip_code: "93465",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "31353",
      city: "Terra Bella",
      zip_code: "93270",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "32028",
      city: "The Sea Ranch",
      zip_code: "95497",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "31061",
      city: "Thermal",
      zip_code: "92274",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "32150",
      city: "Thornton",
      zip_code: "95686",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "30831",
      city: "Thousand Oaks",
      zip_code: "91360",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "31063",
      city: "Thousand Palms",
      zip_code: "92276",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "31354",
      city: "Three Rivers",
      zip_code: "93271",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "31355",
      city: "Tipton",
      zip_code: "93272",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "31510",
      city: "Tollhouse",
      zip_code: "93667",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "31779",
      city: "Tomales",
      zip_code: "94971",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "30699",
      city: "Topanga",
      zip_code: "90290",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "30713",
      city: "Torrance",
      zip_code: "90501",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "31218",
      city: "Trabuco Canyon",
      zip_code: "92678",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "31893",
      city: "Tracy",
      zip_code: "95304",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "31511",
      city: "Tranquillity",
      zip_code: "93668",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "31514",
      city: "Traver",
      zip_code: "93673",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "31661",
      city: "Travis Afb",
      zip_code: "94535",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "31819",
      city: "Tres Pinos",
      zip_code: "95075",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "32069",
      city: "Trinidad",
      zip_code: "95570",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "32352",
      city: "Trinity Center",
      zip_code: "96091",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "31453",
      city: "Trona",
      zip_code: "93562",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "32401",
      city: "Truckee",
      zip_code: "96161",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "30784",
      city: "Tujunga",
      zip_code: "91042",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "31356",
      city: "Tulare",
      zip_code: "93274",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "31951",
      city: "Tuolumne",
      zip_code: "95379",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "31357",
      city: "Tupman",
      zip_code: "93276",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "31952",
      city: "Turlock",
      zip_code: "95380",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "31233",
      city: "Tustin",
      zip_code: "92780",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "32278",
      city: "Twain",
      zip_code: "95984",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "31954",
      city: "Twain Harte",
      zip_code: "95383",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "31064",
      city: "Twentynine Palms",
      zip_code: "92277",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "32175",
      city: "Twin Bridges",
      zip_code: "95735",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "31128",
      city: "Twin Peaks",
      zip_code: "92391",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "32020",
      city: "Ukiah",
      zip_code: "95482",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "31705",
      city: "Union City",
      zip_code: "94587",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "30861",
      city: "Universal City",
      zip_code: "91608",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "30902",
      city: "Upland",
      zip_code: "91784",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "32021",
      city: "Upper Lake",
      zip_code: "95485",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "32151",
      city: "Vacaville",
      zip_code: "95687",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "30828",
      city: "Valencia",
      zip_code: "91354",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "31884",
      city: "Vallecito",
      zip_code: "95251",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "31707",
      city: "Vallejo",
      zip_code: "94589",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "30973",
      city: "Valley Center",
      zip_code: "92082",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "31780",
      city: "Valley Ford",
      zip_code: "94972",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "31885",
      city: "Valley Springs",
      zip_code: "95252",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "30860",
      city: "Valley Village",
      zip_code: "91607",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "30842",
      city: "Van Nuys",
      zip_code: "91401",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "30700",
      city: "Venice",
      zip_code: "90291",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "31268",
      city: "Ventura",
      zip_code: "93001",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "30785",
      city: "Verdugo City",
      zip_code: "91046",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "31955",
      city: "Vernalis",
      zip_code: "95385",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "31886",
      city: "Victor",
      zip_code: "95253",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "31129",
      city: "Victorville",
      zip_code: "92392",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "31066",
      city: "Vidal",
      zip_code: "92280",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "32022",
      city: "Villa Grande",
      zip_code: "95486",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "31254",
      city: "Villa Park",
      zip_code: "92861",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "32353",
      city: "Vina",
      zip_code: "96092",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "31358",
      city: "Visalia",
      zip_code: "93277",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "30972",
      city: "Vista",
      zip_code: "92081",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "32153",
      city: "Volcano",
      zip_code: "95689",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "31887",
      city: "Wallace",
      zip_code: "95254",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "30904",
      city: "Walnut",
      zip_code: "91789",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "31711",
      city: "Walnut Creek",
      zip_code: "94595",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "32154",
      city: "Walnut Grove",
      zip_code: "95690",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "30976",
      city: "Warner Springs",
      zip_code: "92086",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "31359",
      city: "Wasco",
      zip_code: "93280",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "32279",
      city: "Washington",
      zip_code: "95986",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "31956",
      city: "Waterford",
      zip_code: "95386",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "31820",
      city: "Watsonville",
      zip_code: "95076",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "32354",
      city: "Weaverville",
      zip_code: "96093",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "32176",
      city: "Weimar",
      zip_code: "95736",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "31360",
      city: "Weldon",
      zip_code: "93283",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "32070",
      city: "Weott",
      zip_code: "95571",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "30905",
      city: "West Covina",
      zip_code: "91790",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "30809",
      city: "West Hills",
      zip_code: "91307",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "30660",
      city: "West Hollywood",
      zip_code: "90069",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "31888",
      city: "West Point",
      zip_code: "95255",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "32080",
      city: "West Sacramento",
      zip_code: "95605",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "30832",
      city: "Westlake Village",
      zip_code: "91361",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "31957",
      city: "Westley",
      zip_code: "95387",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "31220",
      city: "Westminster",
      zip_code: "92683",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "31067",
      city: "Westmorland",
      zip_code: "92281",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "32023",
      city: "Westport",
      zip_code: "95488",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "32391",
      city: "Westwood",
      zip_code: "96137",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "32156",
      city: "Wheatland",
      zip_code: "95692",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "32074",
      city: "Whitethorn",
      zip_code: "95589",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "31068",
      city: "Whitewater",
      zip_code: "92282",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "32356",
      city: "Whitmore",
      zip_code: "96096",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "30719",
      city: "Whittier",
      zip_code: "90601",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "31181",
      city: "Wildomar",
      zip_code: "92595",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "32280",
      city: "Williams",
      zip_code: "95987",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "32024",
      city: "Willits",
      zip_code: "95490",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "32071",
      city: "Willow Creek",
      zip_code: "95573",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "32281",
      city: "Willows",
      zip_code: "95988",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "30754",
      city: "Wilmington",
      zip_code: "90744",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "31889",
      city: "Wilseyville",
      zip_code: "95257",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "32157",
      city: "Wilton",
      zip_code: "95693",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "31182",
      city: "Winchester",
      zip_code: "92596",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "32025",
      city: "Windsor",
      zip_code: "95492",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "30808",
      city: "Winnetka",
      zip_code: "91306",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "32158",
      city: "Winters",
      zip_code: "95694",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "31958",
      city: "Winton",
      zip_code: "95388",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "31512",
      city: "Wishon",
      zip_code: "93669",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "32026",
      city: "Witter Springs",
      zip_code: "95493",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "31361",
      city: "Wofford Heights",
      zip_code: "93285",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "31781",
      city: "Woodacre",
      zip_code: "94973",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "31890",
      city: "Woodbridge",
      zip_code: "95258",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "31362",
      city: "Woodlake",
      zip_code: "93286",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "32159",
      city: "Woodland",
      zip_code: "95695",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "30834",
      city: "Woodland Hills",
      zip_code: "91364",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "31363",
      city: "Woody",
      zip_code: "93287",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "31132",
      city: "Wrightwood",
      zip_code: "92397",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "31133",
      city: "Yermo",
      zip_code: "92398",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "31513",
      city: "Yettem",
      zip_code: "93670",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "32160",
      city: "Yolo",
      zip_code: "95697",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "31266",
      city: "Yorba Linda",
      zip_code: "92886",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "32027",
      city: "Yorkville",
      zip_code: "95494",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "31959",
      city: "Yosemite National Park",
      zip_code: "95389",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "31715",
      city: "Yountville",
      zip_code: "94599",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "32282",
      city: "Yuba City",
      zip_code: "95991",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "31134",
      city: "Yucaipa",
      zip_code: "92399",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "31070",
      city: "Yucca Valley",
      zip_code: "92284",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "32161",
      city: "Zamora",
      zip_code: "95698",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "32075",
      city: "Zenia",
      zip_code: "95595",
      state_id: "CA",
      state_name: "California",
    },
    {
      city_id: "1773",
      city: "Amston",
      zip_code: "06231",
      state_id: "CT",
      state_name: "Connecticut",
    },
    {
      city_id: "1774",
      city: "Andover",
      zip_code: "06232",
      state_id: "CT",
      state_name: "Connecticut",
    },
    {
      city_id: "1841",
      city: "Ansonia",
      zip_code: "06401",
      state_id: "CT",
      state_name: "Connecticut",
    },
    {
      city_id: "1800",
      city: "Ashford",
      zip_code: "06278",
      state_id: "CT",
      state_name: "Connecticut",
    },
    {
      city_id: "1697",
      city: "Avon",
      zip_code: "06001",
      state_id: "CT",
      state_name: "Connecticut",
    },
    {
      city_id: "1806",
      city: "Baltic",
      zip_code: "06330",
      state_id: "CT",
      state_name: "Connecticut",
    },
    {
      city_id: "1925",
      city: "Bantam",
      zip_code: "06750",
      state_id: "CT",
      state_name: "Connecticut",
    },
    {
      city_id: "1730",
      city: "Barkhamsted",
      zip_code: "06063",
      state_id: "CT",
      state_name: "Connecticut",
    },
    {
      city_id: "1842",
      city: "Beacon Falls",
      zip_code: "06403",
      state_id: "CT",
      state_name: "Connecticut",
    },
    {
      city_id: "1716",
      city: "Berlin",
      zip_code: "06037",
      state_id: "CT",
      state_name: "Connecticut",
    },
    {
      city_id: "1905",
      city: "Bethany",
      zip_code: "06524",
      state_id: "CT",
      state_name: "Connecticut",
    },
    {
      city_id: "1955",
      city: "Bethel",
      zip_code: "06801",
      state_id: "CT",
      state_name: "Connecticut",
    },
    {
      city_id: "1926",
      city: "Bethlehem",
      zip_code: "06751",
      state_id: "CT",
      state_name: "Connecticut",
    },
    {
      city_id: "1698",
      city: "Bloomfield",
      zip_code: "06002",
      state_id: "CT",
      state_name: "Connecticut",
    },
    {
      city_id: "1720",
      city: "Bolton",
      zip_code: "06043",
      state_id: "CT",
      state_name: "Connecticut",
    },
    {
      city_id: "1810",
      city: "Bozrah",
      zip_code: "06334",
      state_id: "CT",
      state_name: "Connecticut",
    },
    {
      city_id: "1843",
      city: "Branford",
      zip_code: "06405",
      state_id: "CT",
      state_name: "Connecticut",
    },
    {
      city_id: "1907",
      city: "Bridgeport",
      zip_code: "06604",
      state_id: "CT",
      state_name: "Connecticut",
    },
    {
      city_id: "1927",
      city: "Bridgewater",
      zip_code: "06752",
      state_id: "CT",
      state_name: "Connecticut",
    },
    {
      city_id: "1699",
      city: "Bristol",
      zip_code: "06010",
      state_id: "CT",
      state_name: "Connecticut",
    },
    {
      city_id: "1701",
      city: "Broad Brook",
      zip_code: "06016",
      state_id: "CT",
      state_name: "Connecticut",
    },
    {
      city_id: "1956",
      city: "Brookfield",
      zip_code: "06804",
      state_id: "CT",
      state_name: "Connecticut",
    },
    {
      city_id: "1775",
      city: "Brooklyn",
      zip_code: "06234",
      state_id: "CT",
      state_name: "Connecticut",
    },
    {
      city_id: "1700",
      city: "Burlington",
      zip_code: "06013",
      state_id: "CT",
      state_name: "Connecticut",
    },
    {
      city_id: "1702",
      city: "Canaan",
      zip_code: "06018",
      state_id: "CT",
      state_name: "Connecticut",
    },
    {
      city_id: "1807",
      city: "Canterbury",
      zip_code: "06331",
      state_id: "CT",
      state_name: "Connecticut",
    },
    {
      city_id: "1703",
      city: "Canton",
      zip_code: "06019",
      state_id: "CT",
      state_name: "Connecticut",
    },
    {
      city_id: "1704",
      city: "Canton Center",
      zip_code: "06020",
      state_id: "CT",
      state_name: "Connecticut",
    },
    {
      city_id: "1844",
      city: "Centerbrook",
      zip_code: "06409",
      state_id: "CT",
      state_name: "Connecticut",
    },
    {
      city_id: "1808",
      city: "Central Village",
      zip_code: "06332",
      state_id: "CT",
      state_name: "Connecticut",
    },
    {
      city_id: "1776",
      city: "Chaplin",
      zip_code: "06235",
      state_id: "CT",
      state_name: "Connecticut",
    },
    {
      city_id: "1845",
      city: "Cheshire",
      zip_code: "06410",
      state_id: "CT",
      state_name: "Connecticut",
    },
    {
      city_id: "1846",
      city: "Chester",
      zip_code: "06412",
      state_id: "CT",
      state_name: "Connecticut",
    },
    {
      city_id: "1847",
      city: "Clinton",
      zip_code: "06413",
      state_id: "CT",
      state_name: "Connecticut",
    },
    {
      city_id: "1848",
      city: "Cobalt",
      zip_code: "06414",
      state_id: "CT",
      state_name: "Connecticut",
    },
    {
      city_id: "1849",
      city: "Colchester",
      zip_code: "06415",
      state_id: "CT",
      state_name: "Connecticut",
    },
    {
      city_id: "1705",
      city: "Colebrook",
      zip_code: "06021",
      state_id: "CT",
      state_name: "Connecticut",
    },
    {
      city_id: "1706",
      city: "Collinsville",
      zip_code: "06022",
      state_id: "CT",
      state_name: "Connecticut",
    },
    {
      city_id: "1777",
      city: "Columbia",
      zip_code: "06237",
      state_id: "CT",
      state_name: "Connecticut",
    },
    {
      city_id: "1928",
      city: "Cornwall",
      zip_code: "06753",
      state_id: "CT",
      state_name: "Connecticut",
    },
    {
      city_id: "1929",
      city: "Cornwall Bridge",
      zip_code: "06754",
      state_id: "CT",
      state_name: "Connecticut",
    },
    {
      city_id: "1957",
      city: "Cos Cob",
      zip_code: "06807",
      state_id: "CT",
      state_name: "Connecticut",
    },
    {
      city_id: "1778",
      city: "Coventry",
      zip_code: "06238",
      state_id: "CT",
      state_name: "Connecticut",
    },
    {
      city_id: "1850",
      city: "Cromwell",
      zip_code: "06416",
      state_id: "CT",
      state_name: "Connecticut",
    },
    {
      city_id: "1958",
      city: "Danbury",
      zip_code: "06810",
      state_id: "CT",
      state_name: "Connecticut",
    },
    {
      city_id: "1779",
      city: "Danielson",
      zip_code: "06239",
      state_id: "CT",
      state_name: "Connecticut",
    },
    {
      city_id: "1961",
      city: "Darien",
      zip_code: "06820",
      state_id: "CT",
      state_name: "Connecticut",
    },
    {
      city_id: "1780",
      city: "Dayville",
      zip_code: "06241",
      state_id: "CT",
      state_name: "Connecticut",
    },
    {
      city_id: "1851",
      city: "Deep River",
      zip_code: "06417",
      state_id: "CT",
      state_name: "Connecticut",
    },
    {
      city_id: "1852",
      city: "Derby",
      zip_code: "06418",
      state_id: "CT",
      state_name: "Connecticut",
    },
    {
      city_id: "1855",
      city: "Durham",
      zip_code: "06422",
      state_id: "CT",
      state_name: "Connecticut",
    },
    {
      city_id: "1707",
      city: "East Berlin",
      zip_code: "06023",
      state_id: "CT",
      state_name: "Connecticut",
    },
    {
      city_id: "1708",
      city: "East Canaan",
      zip_code: "06024",
      state_id: "CT",
      state_name: "Connecticut",
    },
    {
      city_id: "1709",
      city: "East Granby",
      zip_code: "06026",
      state_id: "CT",
      state_name: "Connecticut",
    },
    {
      city_id: "1856",
      city: "East Haddam",
      zip_code: "06423",
      state_id: "CT",
      state_name: "Connecticut",
    },
    {
      city_id: "1857",
      city: "East Hampton",
      zip_code: "06424",
      state_id: "CT",
      state_name: "Connecticut",
    },
    {
      city_id: "1762",
      city: "East Hartford",
      zip_code: "06108",
      state_id: "CT",
      state_name: "Connecticut",
    },
    {
      city_id: "1710",
      city: "East Hartland",
      zip_code: "06027",
      state_id: "CT",
      state_name: "Connecticut",
    },
    {
      city_id: "1897",
      city: "East Haven",
      zip_code: "06512",
      state_id: "CT",
      state_name: "Connecticut",
    },
    {
      city_id: "1782",
      city: "East Killingly",
      zip_code: "06243",
      state_id: "CT",
      state_name: "Connecticut",
    },
    {
      city_id: "1809",
      city: "East Lyme",
      zip_code: "06333",
      state_id: "CT",
      state_name: "Connecticut",
    },
    {
      city_id: "1749",
      city: "East Windsor",
      zip_code: "06088",
      state_id: "CT",
      state_name: "Connecticut",
    },
    {
      city_id: "1781",
      city: "Eastford",
      zip_code: "06242",
      state_id: "CT",
      state_name: "Connecticut",
    },
    {
      city_id: "1914",
      city: "Easton",
      zip_code: "06612",
      state_id: "CT",
      state_name: "Connecticut",
    },
    {
      city_id: "1711",
      city: "Ellington",
      zip_code: "06029",
      state_id: "CT",
      state_name: "Connecticut",
    },
    {
      city_id: "1746",
      city: "Enfield",
      zip_code: "06082",
      state_id: "CT",
      state_name: "Connecticut",
    },
    {
      city_id: "1858",
      city: "Essex",
      zip_code: "06426",
      state_id: "CT",
      state_name: "Connecticut",
    },
    {
      city_id: "1962",
      city: "Fairfield",
      zip_code: "06824",
      state_id: "CT",
      state_name: "Connecticut",
    },
    {
      city_id: "1712",
      city: "Falls Village",
      zip_code: "06031",
      state_id: "CT",
      state_name: "Connecticut",
    },
    {
      city_id: "1713",
      city: "Farmington",
      zip_code: "06032",
      state_id: "CT",
      state_name: "Connecticut",
    },
    {
      city_id: "1811",
      city: "Gales Ferry",
      zip_code: "06335",
      state_id: "CT",
      state_name: "Connecticut",
    },
    {
      city_id: "1930",
      city: "Gaylordsville",
      zip_code: "06755",
      state_id: "CT",
      state_name: "Connecticut",
    },
    {
      city_id: "1812",
      city: "Gilman",
      zip_code: "06336",
      state_id: "CT",
      state_name: "Connecticut",
    },
    {
      city_id: "1714",
      city: "Glastonbury",
      zip_code: "06033",
      state_id: "CT",
      state_name: "Connecticut",
    },
    {
      city_id: "1931",
      city: "Goshen",
      zip_code: "06756",
      state_id: "CT",
      state_name: "Connecticut",
    },
    {
      city_id: "1715",
      city: "Granby",
      zip_code: "06035",
      state_id: "CT",
      state_name: "Connecticut",
    },
    {
      city_id: "1964",
      city: "Greenwich",
      zip_code: "06830",
      state_id: "CT",
      state_name: "Connecticut",
    },
    {
      city_id: "1815",
      city: "Groton",
      zip_code: "06340",
      state_id: "CT",
      state_name: "Connecticut",
    },
    {
      city_id: "1859",
      city: "Guilford",
      zip_code: "06437",
      state_id: "CT",
      state_name: "Connecticut",
    },
    {
      city_id: "1860",
      city: "Haddam",
      zip_code: "06438",
      state_id: "CT",
      state_name: "Connecticut",
    },
    {
      city_id: "1861",
      city: "Hadlyme",
      zip_code: "06439",
      state_id: "CT",
      state_name: "Connecticut",
    },
    {
      city_id: "1899",
      city: "Hamden",
      zip_code: "06514",
      state_id: "CT",
      state_name: "Connecticut",
    },
    {
      city_id: "1783",
      city: "Hampton",
      zip_code: "06247",
      state_id: "CT",
      state_name: "Connecticut",
    },
    {
      city_id: "1816",
      city: "Hanover",
      zip_code: "06350",
      state_id: "CT",
      state_name: "Connecticut",
    },
    {
      city_id: "1758",
      city: "Hartford",
      zip_code: "06103",
      state_id: "CT",
      state_name: "Connecticut",
    },
    {
      city_id: "1949",
      city: "Harwinton",
      zip_code: "06791",
      state_id: "CT",
      state_name: "Connecticut",
    },
    {
      city_id: "1784",
      city: "Hebron",
      zip_code: "06248",
      state_id: "CT",
      state_name: "Connecticut",
    },
    {
      city_id: "1862",
      city: "Higganum",
      zip_code: "06441",
      state_id: "CT",
      state_name: "Connecticut",
    },
    {
      city_id: "1863",
      city: "Ivoryton",
      zip_code: "06442",
      state_id: "CT",
      state_name: "Connecticut",
    },
    {
      city_id: "1817",
      city: "Jewett City",
      zip_code: "06351",
      state_id: "CT",
      state_name: "Connecticut",
    },
    {
      city_id: "1932",
      city: "Kent",
      zip_code: "06757",
      state_id: "CT",
      state_name: "Connecticut",
    },
    {
      city_id: "1853",
      city: "Killingworth",
      zip_code: "06419",
      state_id: "CT",
      state_name: "Connecticut",
    },
    {
      city_id: "1933",
      city: "Lakeside",
      zip_code: "06758",
      state_id: "CT",
      state_name: "Connecticut",
    },
    {
      city_id: "1717",
      city: "Lakeville",
      zip_code: "06039",
      state_id: "CT",
      state_name: "Connecticut",
    },
    {
      city_id: "1785",
      city: "Lebanon",
      zip_code: "06249",
      state_id: "CT",
      state_name: "Connecticut",
    },
    {
      city_id: "1814",
      city: "Ledyard",
      zip_code: "06339",
      state_id: "CT",
      state_name: "Connecticut",
    },
    {
      city_id: "1934",
      city: "Litchfield",
      zip_code: "06759",
      state_id: "CT",
      state_name: "Connecticut",
    },
    {
      city_id: "1864",
      city: "Madison",
      zip_code: "06443",
      state_id: "CT",
      state_name: "Connecticut",
    },
    {
      city_id: "1718",
      city: "Manchester",
      zip_code: "06040",
      state_id: "CT",
      state_name: "Connecticut",
    },
    {
      city_id: "1786",
      city: "Mansfield Center",
      zip_code: "06250",
      state_id: "CT",
      state_name: "Connecticut",
    },
    {
      city_id: "1865",
      city: "Marion",
      zip_code: "06444",
      state_id: "CT",
      state_name: "Connecticut",
    },
    {
      city_id: "1866",
      city: "Marlborough",
      zip_code: "06447",
      state_id: "CT",
      state_name: "Connecticut",
    },
    {
      city_id: "1813",
      city: "Mashantucket",
      zip_code: "06338",
      state_id: "CT",
      state_name: "Connecticut",
    },
    {
      city_id: "1867",
      city: "Meriden",
      zip_code: "06450",
      state_id: "CT",
      state_name: "Connecticut",
    },
    {
      city_id: "1870",
      city: "Middle Haddam",
      zip_code: "06456",
      state_id: "CT",
      state_name: "Connecticut",
    },
    {
      city_id: "1935",
      city: "Middlebury",
      zip_code: "06762",
      state_id: "CT",
      state_name: "Connecticut",
    },
    {
      city_id: "1869",
      city: "Middlefield",
      zip_code: "06455",
      state_id: "CT",
      state_name: "Connecticut",
    },
    {
      city_id: "1871",
      city: "Middletown",
      zip_code: "06457",
      state_id: "CT",
      state_name: "Connecticut",
    },
    {
      city_id: "1873",
      city: "Milford",
      zip_code: "06460",
      state_id: "CT",
      state_name: "Connecticut",
    },
    {
      city_id: "1875",
      city: "Milldale",
      zip_code: "06467",
      state_id: "CT",
      state_name: "Connecticut",
    },
    {
      city_id: "1876",
      city: "Monroe",
      zip_code: "06468",
      state_id: "CT",
      state_name: "Connecticut",
    },
    {
      city_id: "1818",
      city: "Montville",
      zip_code: "06353",
      state_id: "CT",
      state_name: "Connecticut",
    },
    {
      city_id: "1877",
      city: "Moodus",
      zip_code: "06469",
      state_id: "CT",
      state_name: "Connecticut",
    },
    {
      city_id: "1819",
      city: "Moosup",
      zip_code: "06354",
      state_id: "CT",
      state_name: "Connecticut",
    },
    {
      city_id: "1936",
      city: "Morris",
      zip_code: "06763",
      state_id: "CT",
      state_name: "Connecticut",
    },
    {
      city_id: "1820",
      city: "Mystic",
      zip_code: "06355",
      state_id: "CT",
      state_name: "Connecticut",
    },
    {
      city_id: "1937",
      city: "Naugatuck",
      zip_code: "06770",
      state_id: "CT",
      state_name: "Connecticut",
    },
    {
      city_id: "1721",
      city: "New Britain",
      zip_code: "06051",
      state_id: "CT",
      state_name: "Connecticut",
    },
    {
      city_id: "1966",
      city: "New Canaan",
      zip_code: "06840",
      state_id: "CT",
      state_name: "Connecticut",
    },
    {
      city_id: "1960",
      city: "New Fairfield",
      zip_code: "06812",
      state_id: "CT",
      state_name: "Connecticut",
    },
    {
      city_id: "1724",
      city: "New Hartford",
      zip_code: "06057",
      state_id: "CT",
      state_name: "Connecticut",
    },
    {
      city_id: "1895",
      city: "New Haven",
      zip_code: "06510",
      state_id: "CT",
      state_name: "Connecticut",
    },
    {
      city_id: "1805",
      city: "New London",
      zip_code: "06320",
      state_id: "CT",
      state_name: "Connecticut",
    },
    {
      city_id: "1938",
      city: "New Milford",
      zip_code: "06776",
      state_id: "CT",
      state_name: "Connecticut",
    },
    {
      city_id: "1939",
      city: "New Preston Marble Dale",
      zip_code: "06777",
      state_id: "CT",
      state_name: "Connecticut",
    },
    {
      city_id: "1765",
      city: "Newington",
      zip_code: "06111",
      state_id: "CT",
      state_name: "Connecticut",
    },
    {
      city_id: "1878",
      city: "Newtown",
      zip_code: "06470",
      state_id: "CT",
      state_name: "Connecticut",
    },
    {
      city_id: "1821",
      city: "Niantic",
      zip_code: "06357",
      state_id: "CT",
      state_name: "Connecticut",
    },
    {
      city_id: "1725",
      city: "Norfolk",
      zip_code: "06058",
      state_id: "CT",
      state_name: "Connecticut",
    },
    {
      city_id: "1879",
      city: "North Branford",
      zip_code: "06471",
      state_id: "CT",
      state_name: "Connecticut",
    },
    {
      city_id: "1726",
      city: "North Canton",
      zip_code: "06059",
      state_id: "CT",
      state_name: "Connecticut",
    },
    {
      city_id: "1787",
      city: "North Franklin",
      zip_code: "06254",
      state_id: "CT",
      state_name: "Connecticut",
    },
    {
      city_id: "1727",
      city: "North Granby",
      zip_code: "06060",
      state_id: "CT",
      state_name: "Connecticut",
    },
    {
      city_id: "1788",
      city: "North Grosvenordale",
      zip_code: "06255",
      state_id: "CT",
      state_name: "Connecticut",
    },
    {
      city_id: "1881",
      city: "North Haven",
      zip_code: "06473",
      state_id: "CT",
      state_name: "Connecticut",
    },
    {
      city_id: "1822",
      city: "North Stonington",
      zip_code: "06359",
      state_id: "CT",
      state_name: "Connecticut",
    },
    {
      city_id: "1789",
      city: "North Windham",
      zip_code: "06256",
      state_id: "CT",
      state_name: "Connecticut",
    },
    {
      city_id: "1940",
      city: "Northfield",
      zip_code: "06778",
      state_id: "CT",
      state_name: "Connecticut",
    },
    {
      city_id: "1880",
      city: "Northford",
      zip_code: "06472",
      state_id: "CT",
      state_name: "Connecticut",
    },
    {
      city_id: "1967",
      city: "Norwalk",
      zip_code: "06850",
      state_id: "CT",
      state_name: "Connecticut",
    },
    {
      city_id: "1823",
      city: "Norwich",
      zip_code: "06360",
      state_id: "CT",
      state_name: "Connecticut",
    },
    {
      city_id: "1825",
      city: "Oakdale",
      zip_code: "06370",
      state_id: "CT",
      state_name: "Connecticut",
    },
    {
      city_id: "1941",
      city: "Oakville",
      zip_code: "06779",
      state_id: "CT",
      state_name: "Connecticut",
    },
    {
      city_id: "1972",
      city: "Old Greenwich",
      zip_code: "06870",
      state_id: "CT",
      state_name: "Connecticut",
    },
    {
      city_id: "1826",
      city: "Old Lyme",
      zip_code: "06371",
      state_id: "CT",
      state_name: "Connecticut",
    },
    {
      city_id: "1882",
      city: "Old Saybrook",
      zip_code: "06475",
      state_id: "CT",
      state_name: "Connecticut",
    },
    {
      city_id: "1827",
      city: "Oneco",
      zip_code: "06373",
      state_id: "CT",
      state_name: "Connecticut",
    },
    {
      city_id: "1883",
      city: "Orange",
      zip_code: "06477",
      state_id: "CT",
      state_name: "Connecticut",
    },
    {
      city_id: "1884",
      city: "Oxford",
      zip_code: "06478",
      state_id: "CT",
      state_name: "Connecticut",
    },
    {
      city_id: "1833",
      city: "Pawcatuck",
      zip_code: "06379",
      state_id: "CT",
      state_name: "Connecticut",
    },
    {
      city_id: "1728",
      city: "Pine Meadow",
      zip_code: "06061",
      state_id: "CT",
      state_name: "Connecticut",
    },
    {
      city_id: "1828",
      city: "Plainfield",
      zip_code: "06374",
      state_id: "CT",
      state_name: "Connecticut",
    },
    {
      city_id: "1729",
      city: "Plainville",
      zip_code: "06062",
      state_id: "CT",
      state_name: "Connecticut",
    },
    {
      city_id: "1885",
      city: "Plantsville",
      zip_code: "06479",
      state_id: "CT",
      state_name: "Connecticut",
    },
    {
      city_id: "1942",
      city: "Plymouth",
      zip_code: "06782",
      state_id: "CT",
      state_name: "Connecticut",
    },
    {
      city_id: "1790",
      city: "Pomfret",
      zip_code: "06258",
      state_id: "CT",
      state_name: "Connecticut",
    },
    {
      city_id: "1791",
      city: "Pomfret Center",
      zip_code: "06259",
      state_id: "CT",
      state_name: "Connecticut",
    },
    {
      city_id: "1886",
      city: "Portland",
      zip_code: "06480",
      state_id: "CT",
      state_name: "Connecticut",
    },
    {
      city_id: "1824",
      city: "Preston",
      zip_code: "06365",
      state_id: "CT",
      state_name: "Connecticut",
    },
    {
      city_id: "1923",
      city: "Prospect",
      zip_code: "06712",
      state_id: "CT",
      state_name: "Connecticut",
    },
    {
      city_id: "1792",
      city: "Putnam",
      zip_code: "06260",
      state_id: "CT",
      state_name: "Connecticut",
    },
    {
      city_id: "1829",
      city: "Quaker Hill",
      zip_code: "06375",
      state_id: "CT",
      state_name: "Connecticut",
    },
    {
      city_id: "1793",
      city: "Quinebaug",
      zip_code: "06262",
      state_id: "CT",
      state_name: "Connecticut",
    },
    {
      city_id: "1978",
      city: "Redding",
      zip_code: "06896",
      state_id: "CT",
      state_name: "Connecticut",
    },
    {
      city_id: "1973",
      city: "Ridgefield",
      zip_code: "06877",
      state_id: "CT",
      state_name: "Connecticut",
    },
    {
      city_id: "1974",
      city: "Riverside",
      zip_code: "06878",
      state_id: "CT",
      state_name: "Connecticut",
    },
    {
      city_id: "1731",
      city: "Riverton",
      zip_code: "06065",
      state_id: "CT",
      state_name: "Connecticut",
    },
    {
      city_id: "1887",
      city: "Rockfall",
      zip_code: "06481",
      state_id: "CT",
      state_name: "Connecticut",
    },
    {
      city_id: "1733",
      city: "Rocky Hill",
      zip_code: "06067",
      state_id: "CT",
      state_name: "Connecticut",
    },
    {
      city_id: "1794",
      city: "Rogers",
      zip_code: "06263",
      state_id: "CT",
      state_name: "Connecticut",
    },
    {
      city_id: "1943",
      city: "Roxbury",
      zip_code: "06783",
      state_id: "CT",
      state_name: "Connecticut",
    },
    {
      city_id: "1854",
      city: "Salem",
      zip_code: "06420",
      state_id: "CT",
      state_name: "Connecticut",
    },
    {
      city_id: "1734",
      city: "Salisbury",
      zip_code: "06068",
      state_id: "CT",
      state_name: "Connecticut",
    },
    {
      city_id: "1888",
      city: "Sandy Hook",
      zip_code: "06482",
      state_id: "CT",
      state_name: "Connecticut",
    },
    {
      city_id: "1795",
      city: "Scotland",
      zip_code: "06264",
      state_id: "CT",
      state_name: "Connecticut",
    },
    {
      city_id: "1889",
      city: "Seymour",
      zip_code: "06483",
      state_id: "CT",
      state_name: "Connecticut",
    },
    {
      city_id: "1735",
      city: "Sharon",
      zip_code: "06069",
      state_id: "CT",
      state_name: "Connecticut",
    },
    {
      city_id: "1890",
      city: "Shelton",
      zip_code: "06484",
      state_id: "CT",
      state_name: "Connecticut",
    },
    {
      city_id: "1944",
      city: "Sherman",
      zip_code: "06784",
      state_id: "CT",
      state_name: "Connecticut",
    },
    {
      city_id: "1736",
      city: "Simsbury",
      zip_code: "06070",
      state_id: "CT",
      state_name: "Connecticut",
    },
    {
      city_id: "1737",
      city: "Somers",
      zip_code: "06071",
      state_id: "CT",
      state_name: "Connecticut",
    },
    {
      city_id: "1738",
      city: "Somersville",
      zip_code: "06072",
      state_id: "CT",
      state_name: "Connecticut",
    },
    {
      city_id: "1739",
      city: "South Glastonbury",
      zip_code: "06073",
      state_id: "CT",
      state_name: "Connecticut",
    },
    {
      city_id: "1945",
      city: "South Kent",
      zip_code: "06785",
      state_id: "CT",
      state_name: "Connecticut",
    },
    {
      city_id: "1830",
      city: "South Lyme",
      zip_code: "06376",
      state_id: "CT",
      state_name: "Connecticut",
    },
    {
      city_id: "1796",
      city: "South Windham",
      zip_code: "06266",
      state_id: "CT",
      state_name: "Connecticut",
    },
    {
      city_id: "1740",
      city: "South Windsor",
      zip_code: "06074",
      state_id: "CT",
      state_name: "Connecticut",
    },
    {
      city_id: "1891",
      city: "Southbury",
      zip_code: "06488",
      state_id: "CT",
      state_name: "Connecticut",
    },
    {
      city_id: "1892",
      city: "Southington",
      zip_code: "06489",
      state_id: "CT",
      state_name: "Connecticut",
    },
    {
      city_id: "1977",
      city: "Southport",
      zip_code: "06890",
      state_id: "CT",
      state_name: "Connecticut",
    },
    {
      city_id: "1741",
      city: "Stafford Springs",
      zip_code: "06076",
      state_id: "CT",
      state_name: "Connecticut",
    },
    {
      city_id: "1980",
      city: "Stamford",
      zip_code: "06901",
      state_id: "CT",
      state_name: "Connecticut",
    },
    {
      city_id: "1831",
      city: "Sterling",
      zip_code: "06377",
      state_id: "CT",
      state_name: "Connecticut",
    },
    {
      city_id: "1832",
      city: "Stonington",
      zip_code: "06378",
      state_id: "CT",
      state_name: "Connecticut",
    },
    {
      city_id: "1797",
      city: "Storrs Mansfield",
      zip_code: "06268",
      state_id: "CT",
      state_name: "Connecticut",
    },
    {
      city_id: "1915",
      city: "Stratford",
      zip_code: "06614",
      state_id: "CT",
      state_name: "Connecticut",
    },
    {
      city_id: "1742",
      city: "Suffield",
      zip_code: "06078",
      state_id: "CT",
      state_name: "Connecticut",
    },
    {
      city_id: "1743",
      city: "Taconic",
      zip_code: "06079",
      state_id: "CT",
      state_name: "Connecticut",
    },
    {
      city_id: "1834",
      city: "Taftville",
      zip_code: "06380",
      state_id: "CT",
      state_name: "Connecticut",
    },
    {
      city_id: "1745",
      city: "Tariffville",
      zip_code: "06081",
      state_id: "CT",
      state_name: "Connecticut",
    },
    {
      city_id: "1946",
      city: "Terryville",
      zip_code: "06786",
      state_id: "CT",
      state_name: "Connecticut",
    },
    {
      city_id: "1947",
      city: "Thomaston",
      zip_code: "06787",
      state_id: "CT",
      state_name: "Connecticut",
    },
    {
      city_id: "1799",
      city: "Thompson",
      zip_code: "06277",
      state_id: "CT",
      state_name: "Connecticut",
    },
    {
      city_id: "1747",
      city: "Tolland",
      zip_code: "06084",
      state_id: "CT",
      state_name: "Connecticut",
    },
    {
      city_id: "1948",
      city: "Torrington",
      zip_code: "06790",
      state_id: "CT",
      state_name: "Connecticut",
    },
    {
      city_id: "1913",
      city: "Trumbull",
      zip_code: "06611",
      state_id: "CT",
      state_name: "Connecticut",
    },
    {
      city_id: "1835",
      city: "Uncasville",
      zip_code: "06382",
      state_id: "CT",
      state_name: "Connecticut",
    },
    {
      city_id: "1748",
      city: "Unionville",
      zip_code: "06085",
      state_id: "CT",
      state_name: "Connecticut",
    },
    {
      city_id: "1732",
      city: "Vernon Rockville",
      zip_code: "06066",
      state_id: "CT",
      state_name: "Connecticut",
    },
    {
      city_id: "1836",
      city: "Voluntown",
      zip_code: "06384",
      state_id: "CT",
      state_name: "Connecticut",
    },
    {
      city_id: "1893",
      city: "Wallingford",
      zip_code: "06492",
      state_id: "CT",
      state_name: "Connecticut",
    },
    {
      city_id: "1950",
      city: "Washington",
      zip_code: "06793",
      state_id: "CT",
      state_name: "Connecticut",
    },
    {
      city_id: "1951",
      city: "Washington Depot",
      zip_code: "06794",
      state_id: "CT",
      state_name: "Connecticut",
    },
    {
      city_id: "1917",
      city: "Waterbury",
      zip_code: "06702",
      state_id: "CT",
      state_name: "Connecticut",
    },
    {
      city_id: "1837",
      city: "Waterford",
      zip_code: "06385",
      state_id: "CT",
      state_name: "Connecticut",
    },
    {
      city_id: "1952",
      city: "Watertown",
      zip_code: "06795",
      state_id: "CT",
      state_name: "Connecticut",
    },
    {
      city_id: "1838",
      city: "Wauregan",
      zip_code: "06387",
      state_id: "CT",
      state_name: "Connecticut",
    },
    {
      city_id: "1750",
      city: "Weatogue",
      zip_code: "06089",
      state_id: "CT",
      state_name: "Connecticut",
    },
    {
      city_id: "1953",
      city: "West Cornwall",
      zip_code: "06796",
      state_id: "CT",
      state_name: "Connecticut",
    },
    {
      city_id: "1751",
      city: "West Granby",
      zip_code: "06090",
      state_id: "CT",
      state_name: "Connecticut",
    },
    {
      city_id: "1761",
      city: "West Hartford",
      zip_code: "06107",
      state_id: "CT",
      state_name: "Connecticut",
    },
    {
      city_id: "1752",
      city: "West Hartland",
      zip_code: "06091",
      state_id: "CT",
      state_name: "Connecticut",
    },
    {
      city_id: "1901",
      city: "West Haven",
      zip_code: "06516",
      state_id: "CT",
      state_name: "Connecticut",
    },
    {
      city_id: "1753",
      city: "West Simsbury",
      zip_code: "06092",
      state_id: "CT",
      state_name: "Connecticut",
    },
    {
      city_id: "1754",
      city: "West Suffield",
      zip_code: "06093",
      state_id: "CT",
      state_name: "Connecticut",
    },
    {
      city_id: "1894",
      city: "Westbrook",
      zip_code: "06498",
      state_id: "CT",
      state_name: "Connecticut",
    },
    {
      city_id: "1976",
      city: "Weston",
      zip_code: "06883",
      state_id: "CT",
      state_name: "Connecticut",
    },
    {
      city_id: "1975",
      city: "Westport",
      zip_code: "06880",
      state_id: "CT",
      state_name: "Connecticut",
    },
    {
      city_id: "1763",
      city: "Wethersfield",
      zip_code: "06109",
      state_id: "CT",
      state_name: "Connecticut",
    },
    {
      city_id: "1772",
      city: "Willimantic",
      zip_code: "06226",
      state_id: "CT",
      state_name: "Connecticut",
    },
    {
      city_id: "1801",
      city: "Willington",
      zip_code: "06279",
      state_id: "CT",
      state_name: "Connecticut",
    },
    {
      city_id: "1979",
      city: "Wilton",
      zip_code: "06897",
      state_id: "CT",
      state_name: "Connecticut",
    },
    {
      city_id: "1802",
      city: "Windham",
      zip_code: "06280",
      state_id: "CT",
      state_name: "Connecticut",
    },
    {
      city_id: "1755",
      city: "Windsor",
      zip_code: "06095",
      state_id: "CT",
      state_name: "Connecticut",
    },
    {
      city_id: "1756",
      city: "Windsor Locks",
      zip_code: "06096",
      state_id: "CT",
      state_name: "Connecticut",
    },
    {
      city_id: "1757",
      city: "Winsted",
      zip_code: "06098",
      state_id: "CT",
      state_name: "Connecticut",
    },
    {
      city_id: "1924",
      city: "Wolcott",
      zip_code: "06716",
      state_id: "CT",
      state_name: "Connecticut",
    },
    {
      city_id: "1906",
      city: "Woodbridge",
      zip_code: "06525",
      state_id: "CT",
      state_name: "Connecticut",
    },
    {
      city_id: "1954",
      city: "Woodbury",
      zip_code: "06798",
      state_id: "CT",
      state_name: "Connecticut",
    },
    {
      city_id: "1803",
      city: "Woodstock",
      zip_code: "06281",
      state_id: "CT",
      state_name: "Connecticut",
    },
    {
      city_id: "1804",
      city: "Woodstock Valley",
      zip_code: "06282",
      state_id: "CT",
      state_name: "Connecticut",
    },
    {
      city_id: "1839",
      city: "Yantic",
      zip_code: "06389",
      state_id: "CT",
      state_name: "Connecticut",
    },
    {
      city_id: "6389",
      city: "Naval Anacost Annex",
      zip_code: "20373",
      state_id: "DC",
      state_name: "District of Columbia",
    },
    {
      city_id: "6308",
      city: "Washington",
      zip_code: "20001",
      state_id: "DC",
      state_name: "District of Columbia",
    },
    {
      city_id: "6390",
      city: "Washington Navy Yard",
      zip_code: "20388",
      state_id: "DC",
      state_name: "District of Columbia",
    },
    {
      city_id: "6240",
      city: "Bear",
      zip_code: "19701",
      state_id: "DE",
      state_name: "Delaware",
    },
    {
      city_id: "6272",
      city: "Bethany Beach",
      zip_code: "19930",
      state_id: "DE",
      state_name: "Delaware",
    },
    {
      city_id: "6273",
      city: "Bethel",
      zip_code: "19931",
      state_id: "DE",
      state_name: "Delaware",
    },
    {
      city_id: "6274",
      city: "Bridgeville",
      zip_code: "19933",
      state_id: "DE",
      state_name: "Delaware",
    },
    {
      city_id: "6275",
      city: "Camden Wyoming",
      zip_code: "19934",
      state_id: "DE",
      state_name: "Delaware",
    },
    {
      city_id: "6276",
      city: "Cheswold",
      zip_code: "19936",
      state_id: "DE",
      state_name: "Delaware",
    },
    {
      city_id: "6242",
      city: "Claymont",
      zip_code: "19703",
      state_id: "DE",
      state_name: "Delaware",
    },
    {
      city_id: "6277",
      city: "Clayton",
      zip_code: "19938",
      state_id: "DE",
      state_name: "Delaware",
    },
    {
      city_id: "6278",
      city: "Dagsboro",
      zip_code: "19939",
      state_id: "DE",
      state_name: "Delaware",
    },
    {
      city_id: "6243",
      city: "Delaware City",
      zip_code: "19706",
      state_id: "DE",
      state_name: "Delaware",
    },
    {
      city_id: "6279",
      city: "Delmar",
      zip_code: "19940",
      state_id: "DE",
      state_name: "Delaware",
    },
    {
      city_id: "6269",
      city: "Dover",
      zip_code: "19901",
      state_id: "DE",
      state_name: "Delaware",
    },
    {
      city_id: "6270",
      city: "Dover Afb",
      zip_code: "19902",
      state_id: "DE",
      state_name: "Delaware",
    },
    {
      city_id: "6280",
      city: "Ellendale",
      zip_code: "19941",
      state_id: "DE",
      state_name: "Delaware",
    },
    {
      city_id: "6281",
      city: "Felton",
      zip_code: "19943",
      state_id: "DE",
      state_name: "Delaware",
    },
    {
      city_id: "6282",
      city: "Fenwick Island",
      zip_code: "19944",
      state_id: "DE",
      state_name: "Delaware",
    },
    {
      city_id: "6283",
      city: "Frankford",
      zip_code: "19945",
      state_id: "DE",
      state_name: "Delaware",
    },
    {
      city_id: "6284",
      city: "Frederica",
      zip_code: "19946",
      state_id: "DE",
      state_name: "Delaware",
    },
    {
      city_id: "6285",
      city: "Georgetown",
      zip_code: "19947",
      state_id: "DE",
      state_name: "Delaware",
    },
    {
      city_id: "6286",
      city: "Greenwood",
      zip_code: "19950",
      state_id: "DE",
      state_name: "Delaware",
    },
    {
      city_id: "6287",
      city: "Harbeson",
      zip_code: "19951",
      state_id: "DE",
      state_name: "Delaware",
    },
    {
      city_id: "6288",
      city: "Harrington",
      zip_code: "19952",
      state_id: "DE",
      state_name: "Delaware",
    },
    {
      city_id: "6289",
      city: "Hartly",
      zip_code: "19953",
      state_id: "DE",
      state_name: "Delaware",
    },
    {
      city_id: "6244",
      city: "Hockessin",
      zip_code: "19707",
      state_id: "DE",
      state_name: "Delaware",
    },
    {
      city_id: "6290",
      city: "Houston",
      zip_code: "19954",
      state_id: "DE",
      state_name: "Delaware",
    },
    {
      city_id: "6291",
      city: "Kenton",
      zip_code: "19955",
      state_id: "DE",
      state_name: "Delaware",
    },
    {
      city_id: "6292",
      city: "Laurel",
      zip_code: "19956",
      state_id: "DE",
      state_name: "Delaware",
    },
    {
      city_id: "6293",
      city: "Lewes",
      zip_code: "19958",
      state_id: "DE",
      state_name: "Delaware",
    },
    {
      city_id: "6294",
      city: "Lincoln",
      zip_code: "19960",
      state_id: "DE",
      state_name: "Delaware",
    },
    {
      city_id: "6295",
      city: "Magnolia",
      zip_code: "19962",
      state_id: "DE",
      state_name: "Delaware",
    },
    {
      city_id: "6297",
      city: "Marydel",
      zip_code: "19964",
      state_id: "DE",
      state_name: "Delaware",
    },
    {
      city_id: "6245",
      city: "Middletown",
      zip_code: "19709",
      state_id: "DE",
      state_name: "Delaware",
    },
    {
      city_id: "6296",
      city: "Milford",
      zip_code: "19963",
      state_id: "DE",
      state_name: "Delaware",
    },
    {
      city_id: "6298",
      city: "Millsboro",
      zip_code: "19966",
      state_id: "DE",
      state_name: "Delaware",
    },
    {
      city_id: "6299",
      city: "Millville",
      zip_code: "19967",
      state_id: "DE",
      state_name: "Delaware",
    },
    {
      city_id: "6300",
      city: "Milton",
      zip_code: "19968",
      state_id: "DE",
      state_name: "Delaware",
    },
    {
      city_id: "6246",
      city: "Montchanin",
      zip_code: "19710",
      state_id: "DE",
      state_name: "Delaware",
    },
    {
      city_id: "6251",
      city: "New Castle",
      zip_code: "19720",
      state_id: "DE",
      state_name: "Delaware",
    },
    {
      city_id: "6241",
      city: "Newark",
      zip_code: "19702",
      state_id: "DE",
      state_name: "Delaware",
    },
    {
      city_id: "6301",
      city: "Ocean View",
      zip_code: "19970",
      state_id: "DE",
      state_name: "Delaware",
    },
    {
      city_id: "6252",
      city: "Odessa",
      zip_code: "19730",
      state_id: "DE",
      state_name: "Delaware",
    },
    {
      city_id: "6253",
      city: "Port Penn",
      zip_code: "19731",
      state_id: "DE",
      state_name: "Delaware",
    },
    {
      city_id: "6302",
      city: "Rehoboth Beach",
      zip_code: "19971",
      state_id: "DE",
      state_name: "Delaware",
    },
    {
      city_id: "6254",
      city: "Rockland",
      zip_code: "19732",
      state_id: "DE",
      state_name: "Delaware",
    },
    {
      city_id: "6255",
      city: "Saint Georges",
      zip_code: "19733",
      state_id: "DE",
      state_name: "Delaware",
    },
    {
      city_id: "6303",
      city: "Seaford",
      zip_code: "19973",
      state_id: "DE",
      state_name: "Delaware",
    },
    {
      city_id: "6304",
      city: "Selbyville",
      zip_code: "19975",
      state_id: "DE",
      state_name: "Delaware",
    },
    {
      city_id: "6305",
      city: "Smyrna",
      zip_code: "19977",
      state_id: "DE",
      state_name: "Delaware",
    },
    {
      city_id: "6256",
      city: "Townsend",
      zip_code: "19734",
      state_id: "DE",
      state_name: "Delaware",
    },
    {
      city_id: "6306",
      city: "Viola",
      zip_code: "19979",
      state_id: "DE",
      state_name: "Delaware",
    },
    {
      city_id: "6259",
      city: "Wilmington",
      zip_code: "19801",
      state_id: "DE",
      state_name: "Delaware",
    },
    {
      city_id: "6257",
      city: "Winterthur",
      zip_code: "19735",
      state_id: "DE",
      state_name: "Delaware",
    },
    {
      city_id: "6307",
      city: "Woodside",
      zip_code: "19980",
      state_id: "DE",
      state_name: "Delaware",
    },
    {
      city_id: "6258",
      city: "Yorklyn",
      zip_code: "19736",
      state_id: "DE",
      state_name: "Delaware",
    },
    {
      city_id: "9822",
      city: "Acworth",
      zip_code: "30101",
      state_id: "GA",
      state_name: "Georgia",
    },
    {
      city_id: "9824",
      city: "Adairsville",
      zip_code: "30103",
      state_id: "GA",
      state_name: "Georgia",
    },
    {
      city_id: "10367",
      city: "Adel",
      zip_code: "31620",
      state_id: "GA",
      state_name: "Georgia",
    },
    {
      city_id: "10187",
      city: "Adrian",
      zip_code: "31002",
      state_id: "GA",
      state_name: "Georgia",
    },
    {
      city_id: "9976",
      city: "Ailey",
      zip_code: "30410",
      state_id: "GA",
      state_name: "Georgia",
    },
    {
      city_id: "9977",
      city: "Alamo",
      zip_code: "30411",
      state_id: "GA",
      state_name: "Georgia",
    },
    {
      city_id: "10280",
      city: "Allenhurst",
      zip_code: "31301",
      state_id: "GA",
      state_name: "Georgia",
    },
    {
      city_id: "10188",
      city: "Allentown",
      zip_code: "31003",
      state_id: "GA",
      state_name: "Georgia",
    },
    {
      city_id: "10318",
      city: "Alma",
      zip_code: "31510",
      state_id: "GA",
      state_name: "Georgia",
    },
    {
      city_id: "9761",
      city: "Alpharetta",
      zip_code: "30004",
      state_id: "GA",
      state_name: "Georgia",
    },
    {
      city_id: "9978",
      city: "Alston",
      zip_code: "30412",
      state_id: "GA",
      state_name: "Georgia",
    },
    {
      city_id: "10022",
      city: "Alto",
      zip_code: "30510",
      state_id: "GA",
      state_name: "Georgia",
    },
    {
      city_id: "10398",
      city: "Americus",
      zip_code: "31709",
      state_id: "GA",
      state_name: "Georgia",
    },
    {
      city_id: "10399",
      city: "Andersonville",
      zip_code: "31711",
      state_id: "GA",
      state_name: "Georgia",
    },
    {
      city_id: "10157",
      city: "Appling",
      zip_code: "30802",
      state_id: "GA",
      state_name: "Georgia",
    },
    {
      city_id: "9825",
      city: "Aragon",
      zip_code: "30104",
      state_id: "GA",
      state_name: "Georgia",
    },
    {
      city_id: "10369",
      city: "Argyle",
      zip_code: "31623",
      state_id: "GA",
      state_name: "Georgia",
    },
    {
      city_id: "13210",
      city: "Arlington",
      zip_code: "39813",
      state_id: "GA",
      state_name: "Georgia",
    },
    {
      city_id: "9826",
      city: "Armuchee",
      zip_code: "30105",
      state_id: "GA",
      state_name: "Georgia",
    },
    {
      city_id: "10087",
      city: "Arnoldsville",
      zip_code: "30619",
      state_id: "GA",
      state_name: "Georgia",
    },
    {
      city_id: "10081",
      city: "Athens",
      zip_code: "30601",
      state_id: "GA",
      state_name: "Georgia",
    },
    {
      city_id: "9935",
      city: "Atlanta",
      zip_code: "30303",
      state_id: "GA",
      state_name: "Georgia",
    },
    {
      city_id: "9765",
      city: "Auburn",
      zip_code: "30011",
      state_id: "GA",
      state_name: "Georgia",
    },
    {
      city_id: "10179",
      city: "Augusta",
      zip_code: "30901",
      state_id: "GA",
      state_name: "Georgia",
    },
    {
      city_id: "9827",
      city: "Austell",
      zip_code: "30106",
      state_id: "GA",
      state_name: "Georgia",
    },
    {
      city_id: "10158",
      city: "Avera",
      zip_code: "30803",
      state_id: "GA",
      state_name: "Georgia",
    },
    {
      city_id: "9760",
      city: "Avondale Estates",
      zip_code: "30002",
      state_id: "GA",
      state_name: "Georgia",
    },
    {
      city_id: "10370",
      city: "Axson",
      zip_code: "31624",
      state_id: "GA",
      state_name: "Georgia",
    },
    {
      city_id: "10023",
      city: "Baldwin",
      zip_code: "30511",
      state_id: "GA",
      state_name: "Georgia",
    },
    {
      city_id: "9828",
      city: "Ball Ground",
      zip_code: "30107",
      state_id: "GA",
      state_name: "Georgia",
    },
    {
      city_id: "9879",
      city: "Barnesville",
      zip_code: "30204",
      state_id: "GA",
      state_name: "Georgia",
    },
    {
      city_id: "10371",
      city: "Barney",
      zip_code: "31625",
      state_id: "GA",
      state_name: "Georgia",
    },
    {
      city_id: "9979",
      city: "Bartow",
      zip_code: "30413",
      state_id: "GA",
      state_name: "Georgia",
    },
    {
      city_id: "10320",
      city: "Baxley",
      zip_code: "31513",
      state_id: "GA",
      state_name: "Georgia",
    },
    {
      city_id: "10088",
      city: "Bethlehem",
      zip_code: "30620",
      state_id: "GA",
      state_name: "Georgia",
    },
    {
      city_id: "10089",
      city: "Bishop",
      zip_code: "30621",
      state_id: "GA",
      state_name: "Georgia",
    },
    {
      city_id: "10321",
      city: "Blackshear",
      zip_code: "31516",
      state_id: "GA",
      state_name: "Georgia",
    },
    {
      city_id: "10024",
      city: "Blairsville",
      zip_code: "30512",
      state_id: "GA",
      state_name: "Georgia",
    },
    {
      city_id: "10281",
      city: "Bloomingdale",
      zip_code: "31302",
      state_id: "GA",
      state_name: "Georgia",
    },
    {
      city_id: "10025",
      city: "Blue Ridge",
      zip_code: "30513",
      state_id: "GA",
      state_name: "Georgia",
    },
    {
      city_id: "10159",
      city: "Blythe",
      zip_code: "30805",
      state_id: "GA",
      state_name: "Georgia",
    },
    {
      city_id: "10090",
      city: "Bogart",
      zip_code: "30622",
      state_id: "GA",
      state_name: "Georgia",
    },
    {
      city_id: "10189",
      city: "Bolingbroke",
      zip_code: "31004",
      state_id: "GA",
      state_name: "Georgia",
    },
    {
      city_id: "10190",
      city: "Bonaire",
      zip_code: "31005",
      state_id: "GA",
      state_name: "Georgia",
    },
    {
      city_id: "10091",
      city: "Bostwick",
      zip_code: "30623",
      state_id: "GA",
      state_name: "Georgia",
    },
    {
      city_id: "9829",
      city: "Bowdon",
      zip_code: "30108",
      state_id: "GA",
      state_name: "Georgia",
    },
    {
      city_id: "10026",
      city: "Bowersville",
      zip_code: "30516",
      state_id: "GA",
      state_name: "Georgia",
    },
    {
      city_id: "10092",
      city: "Bowman",
      zip_code: "30624",
      state_id: "GA",
      state_name: "Georgia",
    },
    {
      city_id: "10446",
      city: "Box Springs",
      zip_code: "31801",
      state_id: "GA",
      state_name: "Georgia",
    },
    {
      city_id: "10027",
      city: "Braselton",
      zip_code: "30517",
      state_id: "GA",
      state_name: "Georgia",
    },
    {
      city_id: "9830",
      city: "Bremen",
      zip_code: "30110",
      state_id: "GA",
      state_name: "Georgia",
    },
    {
      city_id: "10322",
      city: "Bristol",
      zip_code: "31518",
      state_id: "GA",
      state_name: "Georgia",
    },
    {
      city_id: "9981",
      city: "Brooklet",
      zip_code: "30415",
      state_id: "GA",
      state_name: "Georgia",
    },
    {
      city_id: "9880",
      city: "Brooks",
      zip_code: "30205",
      state_id: "GA",
      state_name: "Georgia",
    },
    {
      city_id: "10324",
      city: "Brunswick",
      zip_code: "31520",
      state_id: "GA",
      state_name: "Georgia",
    },
    {
      city_id: "9832",
      city: "Buchanan",
      zip_code: "30113",
      state_id: "GA",
      state_name: "Georgia",
    },
    {
      city_id: "10447",
      city: "Buena Vista",
      zip_code: "31803",
      state_id: "GA",
      state_name: "Georgia",
    },
    {
      city_id: "10028",
      city: "Buford",
      zip_code: "30518",
      state_id: "GA",
      state_name: "Georgia",
    },
    {
      city_id: "10191",
      city: "Butler",
      zip_code: "31006",
      state_id: "GA",
      state_name: "Georgia",
    },
    {
      city_id: "10192",
      city: "Byromville",
      zip_code: "31007",
      state_id: "GA",
      state_name: "Georgia",
    },
    {
      city_id: "10193",
      city: "Byron",
      zip_code: "31008",
      state_id: "GA",
      state_name: "Georgia",
    },
    {
      city_id: "10194",
      city: "Cadwell",
      zip_code: "31009",
      state_id: "GA",
      state_name: "Georgia",
    },
    {
      city_id: "10125",
      city: "Calhoun",
      zip_code: "30701",
      state_id: "GA",
      state_name: "Georgia",
    },
    {
      city_id: "10160",
      city: "Camak",
      zip_code: "30807",
      state_id: "GA",
      state_name: "Georgia",
    },
    {
      city_id: "10030",
      city: "Canon",
      zip_code: "30520",
      state_id: "GA",
      state_name: "Georgia",
    },
    {
      city_id: "9833",
      city: "Canton",
      zip_code: "30114",
      state_id: "GA",
      state_name: "Georgia",
    },
    {
      city_id: "10094",
      city: "Carlton",
      zip_code: "30627",
      state_id: "GA",
      state_name: "Georgia",
    },
    {
      city_id: "10031",
      city: "Carnesville",
      zip_code: "30521",
      state_id: "GA",
      state_name: "Georgia",
    },
    {
      city_id: "9835",
      city: "Carrollton",
      zip_code: "30116",
      state_id: "GA",
      state_name: "Georgia",
    },
    {
      city_id: "9838",
      city: "Cartersville",
      zip_code: "30120",
      state_id: "GA",
      state_name: "Georgia",
    },
    {
      city_id: "9841",
      city: "Cave Spring",
      zip_code: "30124",
      state_id: "GA",
      state_name: "Georgia",
    },
    {
      city_id: "9842",
      city: "Cedartown",
      zip_code: "30125",
      state_id: "GA",
      state_name: "Georgia",
    },
    {
      city_id: "10210",
      city: "Centerville",
      zip_code: "31028",
      state_id: "GA",
      state_name: "Georgia",
    },
    {
      city_id: "10126",
      city: "Chatsworth",
      zip_code: "30705",
      state_id: "GA",
      state_name: "Georgia",
    },
    {
      city_id: "10195",
      city: "Chauncey",
      zip_code: "31011",
      state_id: "GA",
      state_name: "Georgia",
    },
    {
      city_id: "10032",
      city: "Cherry Log",
      zip_code: "30522",
      state_id: "GA",
      state_name: "Georgia",
    },
    {
      city_id: "10196",
      city: "Chester",
      zip_code: "31012",
      state_id: "GA",
      state_name: "Georgia",
    },
    {
      city_id: "10127",
      city: "Chickamauga",
      zip_code: "30707",
      state_id: "GA",
      state_name: "Georgia",
    },
    {
      city_id: "10128",
      city: "Cisco",
      zip_code: "30708",
      state_id: "GA",
      state_name: "Georgia",
    },
    {
      city_id: "9831",
      city: "Clarkdale",
      zip_code: "30111",
      state_id: "GA",
      state_name: "Georgia",
    },
    {
      city_id: "10033",
      city: "Clarkesville",
      zip_code: "30523",
      state_id: "GA",
      state_name: "Georgia",
    },
    {
      city_id: "9773",
      city: "Clarkston",
      zip_code: "30021",
      state_id: "GA",
      state_name: "Georgia",
    },
    {
      city_id: "9982",
      city: "Claxton",
      zip_code: "30417",
      state_id: "GA",
      state_name: "Georgia",
    },
    {
      city_id: "10035",
      city: "Clermont",
      zip_code: "30527",
      state_id: "GA",
      state_name: "Georgia",
    },
    {
      city_id: "10036",
      city: "Cleveland",
      zip_code: "30528",
      state_id: "GA",
      state_name: "Georgia",
    },
    {
      city_id: "10282",
      city: "Clyo",
      zip_code: "31303",
      state_id: "GA",
      state_name: "Georgia",
    },
    {
      city_id: "9983",
      city: "Cobbtown",
      zip_code: "30420",
      state_id: "GA",
      state_name: "Georgia",
    },
    {
      city_id: "10197",
      city: "Cochran",
      zip_code: "31014",
      state_id: "GA",
      state_name: "Georgia",
    },
    {
      city_id: "10129",
      city: "Cohutta",
      zip_code: "30710",
      state_id: "GA",
      state_name: "Georgia",
    },
    {
      city_id: "10095",
      city: "Colbert",
      zip_code: "30628",
      state_id: "GA",
      state_name: "Georgia",
    },
    {
      city_id: "9984",
      city: "Collins",
      zip_code: "30421",
      state_id: "GA",
      state_name: "Georgia",
    },
    {
      city_id: "10096",
      city: "Comer",
      zip_code: "30629",
      state_id: "GA",
      state_name: "Georgia",
    },
    {
      city_id: "10037",
      city: "Commerce",
      zip_code: "30529",
      state_id: "GA",
      state_name: "Georgia",
    },
    {
      city_id: "9881",
      city: "Concord",
      zip_code: "30206",
      state_id: "GA",
      state_name: "Georgia",
    },
    {
      city_id: "9925",
      city: "Conley",
      zip_code: "30288",
      state_id: "GA",
      state_name: "Georgia",
    },
    {
      city_id: "9766",
      city: "Conyers",
      zip_code: "30012",
      state_id: "GA",
      state_name: "Georgia",
    },
    {
      city_id: "10198",
      city: "Cordele",
      zip_code: "31015",
      state_id: "GA",
      state_name: "Georgia",
    },
    {
      city_id: "10039",
      city: "Cornelia",
      zip_code: "30531",
      state_id: "GA",
      state_name: "Georgia",
    },
    {
      city_id: "9768",
      city: "Covington",
      zip_code: "30014",
      state_id: "GA",
      state_name: "Georgia",
    },
    {
      city_id: "10130",
      city: "Crandall",
      zip_code: "30711",
      state_id: "GA",
      state_name: "Georgia",
    },
    {
      city_id: "10097",
      city: "Crawford",
      zip_code: "30630",
      state_id: "GA",
      state_name: "Georgia",
    },
    {
      city_id: "10098",
      city: "Crawfordville",
      zip_code: "30631",
      state_id: "GA",
      state_name: "Georgia",
    },
    {
      city_id: "10199",
      city: "Culloden",
      zip_code: "31016",
      state_id: "GA",
      state_name: "Georgia",
    },
    {
      city_id: "9777",
      city: "Cumming",
      zip_code: "30028",
      state_id: "GA",
      state_name: "Georgia",
    },
    {
      city_id: "9772",
      city: "Dacula",
      zip_code: "30019",
      state_id: "GA",
      state_name: "Georgia",
    },
    {
      city_id: "10040",
      city: "Dahlonega",
      zip_code: "30533",
      state_id: "GA",
      state_name: "Georgia",
    },
    {
      city_id: "9845",
      city: "Dallas",
      zip_code: "30132",
      state_id: "GA",
      state_name: "Georgia",
    },
    {
      city_id: "10131",
      city: "Dalton",
      zip_code: "30720",
      state_id: "GA",
      state_name: "Georgia",
    },
    {
      city_id: "10099",
      city: "Danielsville",
      zip_code: "30633",
      state_id: "GA",
      state_name: "Georgia",
    },
    {
      city_id: "10200",
      city: "Danville",
      zip_code: "31017",
      state_id: "GA",
      state_name: "Georgia",
    },
    {
      city_id: "10283",
      city: "Darien",
      zip_code: "31305",
      state_id: "GA",
      state_name: "Georgia",
    },
    {
      city_id: "10201",
      city: "Davisboro",
      zip_code: "31018",
      state_id: "GA",
      state_name: "Georgia",
    },
    {
      city_id: "10041",
      city: "Dawsonville",
      zip_code: "30534",
      state_id: "GA",
      state_name: "Georgia",
    },
    {
      city_id: "10161",
      city: "Dearing",
      zip_code: "30808",
      state_id: "GA",
      state_name: "Georgia",
    },
    {
      city_id: "9778",
      city: "Decatur",
      zip_code: "30030",
      state_id: "GA",
      state_name: "Georgia",
    },
    {
      city_id: "10042",
      city: "Demorest",
      zip_code: "30535",
      state_id: "GA",
      state_name: "Georgia",
    },
    {
      city_id: "10100",
      city: "Dewy Rose",
      zip_code: "30634",
      state_id: "GA",
      state_name: "Georgia",
    },
    {
      city_id: "10202",
      city: "Dexter",
      zip_code: "31019",
      state_id: "GA",
      state_name: "Georgia",
    },
    {
      city_id: "10044",
      city: "Dillard",
      zip_code: "30537",
      state_id: "GA",
      state_name: "Georgia",
    },
    {
      city_id: "10331",
      city: "Douglas",
      zip_code: "31533",
      state_id: "GA",
      state_name: "Georgia",
    },
    {
      city_id: "9846",
      city: "Douglasville",
      zip_code: "30134",
      state_id: "GA",
      state_name: "Georgia",
    },
    {
      city_id: "10203",
      city: "Dry Branch",
      zip_code: "31020",
      state_id: "GA",
      state_name: "Georgia",
    },
    {
      city_id: "10375",
      city: "Du Pont",
      zip_code: "31630",
      state_id: "GA",
      state_name: "Georgia",
    },
    {
      city_id: "10204",
      city: "Dublin",
      zip_code: "31021",
      state_id: "GA",
      state_name: "Georgia",
    },
    {
      city_id: "10205",
      city: "Dudley",
      zip_code: "31022",
      state_id: "GA",
      state_name: "Georgia",
    },
    {
      city_id: "9820",
      city: "Duluth",
      zip_code: "30096",
      state_id: "GA",
      state_name: "Georgia",
    },
    {
      city_id: "10209",
      city: "East Dublin",
      zip_code: "31027",
      state_id: "GA",
      state_name: "Georgia",
    },
    {
      city_id: "10046",
      city: "East Ellijay",
      zip_code: "30539",
      state_id: "GA",
      state_name: "Georgia",
    },
    {
      city_id: "10045",
      city: "Eastanollee",
      zip_code: "30538",
      state_id: "GA",
      state_name: "Georgia",
    },
    {
      city_id: "10206",
      city: "Eastman",
      zip_code: "31023",
      state_id: "GA",
      state_name: "Georgia",
    },
    {
      city_id: "10207",
      city: "Eatonton",
      zip_code: "31024",
      state_id: "GA",
      state_name: "Georgia",
    },
    {
      city_id: "10284",
      city: "Eden",
      zip_code: "31307",
      state_id: "GA",
      state_name: "Georgia",
    },
    {
      city_id: "10101",
      city: "Elberton",
      zip_code: "30635",
      state_id: "GA",
      state_name: "Georgia",
    },
    {
      city_id: "10208",
      city: "Elko",
      zip_code: "31025",
      state_id: "GA",
      state_name: "Georgia",
    },
    {
      city_id: "10285",
      city: "Ellabell",
      zip_code: "31308",
      state_id: "GA",
      state_name: "Georgia",
    },
    {
      city_id: "10450",
      city: "Ellaville",
      zip_code: "31806",
      state_id: "GA",
      state_name: "Georgia",
    },
    {
      city_id: "9931",
      city: "Ellenwood",
      zip_code: "30294",
      state_id: "GA",
      state_name: "Georgia",
    },
    {
      city_id: "10451",
      city: "Ellerslie",
      zip_code: "31807",
      state_id: "GA",
      state_name: "Georgia",
    },
    {
      city_id: "10043",
      city: "Ellijay",
      zip_code: "30536",
      state_id: "GA",
      state_name: "Georgia",
    },
    {
      city_id: "9848",
      city: "Emerson",
      zip_code: "30137",
      state_id: "GA",
      state_name: "Georgia",
    },
    {
      city_id: "10048",
      city: "Epworth",
      zip_code: "30541",
      state_id: "GA",
      state_name: "Georgia",
    },
    {
      city_id: "10133",
      city: "Eton",
      zip_code: "30724",
      state_id: "GA",
      state_name: "Georgia",
    },
    {
      city_id: "10162",
      city: "Evans",
      zip_code: "30809",
      state_id: "GA",
      state_name: "Georgia",
    },
    {
      city_id: "9882",
      city: "Fairburn",
      zip_code: "30213",
      state_id: "GA",
      state_name: "Georgia",
    },
    {
      city_id: "9849",
      city: "Fairmount",
      zip_code: "30139",
      state_id: "GA",
      state_name: "Georgia",
    },
    {
      city_id: "10376",
      city: "Fargo",
      zip_code: "31631",
      state_id: "GA",
      state_name: "Georgia",
    },
    {
      city_id: "9883",
      city: "Fayetteville",
      zip_code: "30214",
      state_id: "GA",
      state_name: "Georgia",
    },
    {
      city_id: "10416",
      city: "Fitzgerald",
      zip_code: "31750",
      state_id: "GA",
      state_name: "Georgia",
    },
    {
      city_id: "10286",
      city: "Fleming",
      zip_code: "31309",
      state_id: "GA",
      state_name: "Georgia",
    },
    {
      city_id: "10134",
      city: "Flintstone",
      zip_code: "30725",
      state_id: "GA",
      state_name: "Georgia",
    },
    {
      city_id: "9885",
      city: "Flovilla",
      zip_code: "30216",
      state_id: "GA",
      state_name: "Georgia",
    },
    {
      city_id: "10049",
      city: "Flowery Branch",
      zip_code: "30542",
      state_id: "GA",
      state_name: "Georgia",
    },
    {
      city_id: "10333",
      city: "Folkston",
      zip_code: "31537",
      state_id: "GA",
      state_name: "Georgia",
    },
    {
      city_id: "9934",
      city: "Forest Park",
      zip_code: "30297",
      state_id: "GA",
      state_name: "Georgia",
    },
    {
      city_id: "10211",
      city: "Forsyth",
      zip_code: "31029",
      state_id: "GA",
      state_name: "Georgia",
    },
    {
      city_id: "10476",
      city: "Fort Benning",
      zip_code: "31905",
      state_id: "GA",
      state_name: "Georgia",
    },
    {
      city_id: "10147",
      city: "Fort Oglethorpe",
      zip_code: "30742",
      state_id: "GA",
      state_name: "Georgia",
    },
    {
      city_id: "10289",
      city: "Fort Stewart",
      zip_code: "31314",
      state_id: "GA",
      state_name: "Georgia",
    },
    {
      city_id: "10212",
      city: "Fort Valley",
      zip_code: "31030",
      state_id: "GA",
      state_name: "Georgia",
    },
    {
      city_id: "9886",
      city: "Franklin",
      zip_code: "30217",
      state_id: "GA",
      state_name: "Georgia",
    },
    {
      city_id: "10018",
      city: "Gainesville",
      zip_code: "30501",
      state_id: "GA",
      state_name: "Georgia",
    },
    {
      city_id: "9986",
      city: "Garfield",
      zip_code: "30425",
      state_id: "GA",
      state_name: "Georgia",
    },
    {
      city_id: "9887",
      city: "Gay",
      zip_code: "30218",
      state_id: "GA",
      state_name: "Georgia",
    },
    {
      city_id: "10163",
      city: "Gibson",
      zip_code: "30810",
      state_id: "GA",
      state_name: "Georgia",
    },
    {
      city_id: "10050",
      city: "Gillsville",
      zip_code: "30543",
      state_id: "GA",
      state_name: "Georgia",
    },
    {
      city_id: "9988",
      city: "Glennville",
      zip_code: "30427",
      state_id: "GA",
      state_name: "Georgia",
    },
    {
      city_id: "9989",
      city: "Glenwood",
      zip_code: "30428",
      state_id: "GA",
      state_name: "Georgia",
    },
    {
      city_id: "10103",
      city: "Good Hope",
      zip_code: "30641",
      state_id: "GA",
      state_name: "Georgia",
    },
    {
      city_id: "10213",
      city: "Gordon",
      zip_code: "31031",
      state_id: "GA",
      state_name: "Georgia",
    },
    {
      city_id: "10164",
      city: "Gracewood",
      zip_code: "30812",
      state_id: "GA",
      state_name: "Georgia",
    },
    {
      city_id: "9888",
      city: "Grantville",
      zip_code: "30220",
      state_id: "GA",
      state_name: "Georgia",
    },
    {
      city_id: "10214",
      city: "Gray",
      zip_code: "31032",
      state_id: "GA",
      state_name: "Georgia",
    },
    {
      city_id: "9770",
      city: "Grayson",
      zip_code: "30017",
      state_id: "GA",
      state_name: "Georgia",
    },
    {
      city_id: "10135",
      city: "Graysville",
      zip_code: "30726",
      state_id: "GA",
      state_name: "Georgia",
    },
    {
      city_id: "10104",
      city: "Greensboro",
      zip_code: "30642",
      state_id: "GA",
      state_name: "Georgia",
    },
    {
      city_id: "9889",
      city: "Greenville",
      zip_code: "30222",
      state_id: "GA",
      state_name: "Georgia",
    },
    {
      city_id: "9890",
      city: "Griffin",
      zip_code: "30223",
      state_id: "GA",
      state_name: "Georgia",
    },
    {
      city_id: "10165",
      city: "Grovetown",
      zip_code: "30813",
      state_id: "GA",
      state_name: "Georgia",
    },
    {
      city_id: "10287",
      city: "Guyton",
      zip_code: "31312",
      state_id: "GA",
      state_name: "Georgia",
    },
    {
      city_id: "10215",
      city: "Haddock",
      zip_code: "31033",
      state_id: "GA",
      state_name: "Georgia",
    },
    {
      city_id: "10377",
      city: "Hahira",
      zip_code: "31632",
      state_id: "GA",
      state_name: "Georgia",
    },
    {
      city_id: "10454",
      city: "Hamilton",
      zip_code: "31811",
      state_id: "GA",
      state_name: "Georgia",
    },
    {
      city_id: "9892",
      city: "Hampton",
      zip_code: "30228",
      state_id: "GA",
      state_name: "Georgia",
    },
    {
      city_id: "9893",
      city: "Haralson",
      zip_code: "30229",
      state_id: "GA",
      state_name: "Georgia",
    },
    {
      city_id: "10216",
      city: "Hardwick",
      zip_code: "31034",
      state_id: "GA",
      state_name: "Georgia",
    },
    {
      city_id: "10166",
      city: "Harlem",
      zip_code: "30814",
      state_id: "GA",
      state_name: "Georgia",
    },
    {
      city_id: "10217",
      city: "Harrison",
      zip_code: "31035",
      state_id: "GA",
      state_name: "Georgia",
    },
    {
      city_id: "10105",
      city: "Hartwell",
      zip_code: "30643",
      state_id: "GA",
      state_name: "Georgia",
    },
    {
      city_id: "10218",
      city: "Hawkinsville",
      zip_code: "31036",
      state_id: "GA",
      state_name: "Georgia",
    },
    {
      city_id: "10334",
      city: "Hazlehurst",
      zip_code: "31539",
      state_id: "GA",
      state_name: "Georgia",
    },
    {
      city_id: "10051",
      city: "Helen",
      zip_code: "30545",
      state_id: "GA",
      state_name: "Georgia",
    },
    {
      city_id: "10167",
      city: "Hephzibah",
      zip_code: "30815",
      state_id: "GA",
      state_name: "Georgia",
    },
    {
      city_id: "10052",
      city: "Hiawassee",
      zip_code: "30546",
      state_id: "GA",
      state_name: "Georgia",
    },
    {
      city_id: "10220",
      city: "Hillsboro",
      zip_code: "31038",
      state_id: "GA",
      state_name: "Georgia",
    },
    {
      city_id: "10288",
      city: "Hinesville",
      zip_code: "31313",
      state_id: "GA",
      state_name: "Georgia",
    },
    {
      city_id: "9850",
      city: "Hiram",
      zip_code: "30141",
      state_id: "GA",
      state_name: "Georgia",
    },
    {
      city_id: "10335",
      city: "Hoboken",
      zip_code: "31542",
      state_id: "GA",
      state_name: "Georgia",
    },
    {
      city_id: "9894",
      city: "Hogansville",
      zip_code: "30230",
      state_id: "GA",
      state_name: "Georgia",
    },
    {
      city_id: "10053",
      city: "Homer",
      zip_code: "30547",
      state_id: "GA",
      state_name: "Georgia",
    },
    {
      city_id: "10378",
      city: "Homerville",
      zip_code: "31634",
      state_id: "GA",
      state_name: "Georgia",
    },
    {
      city_id: "10336",
      city: "Hortense",
      zip_code: "31543",
      state_id: "GA",
      state_name: "Georgia",
    },
    {
      city_id: "10054",
      city: "Hoschton",
      zip_code: "30548",
      state_id: "GA",
      state_name: "Georgia",
    },
    {
      city_id: "10106",
      city: "Hull",
      zip_code: "30646",
      state_id: "GA",
      state_name: "Georgia",
    },
    {
      city_id: "10222",
      city: "Ideal",
      zip_code: "31041",
      state_id: "GA",
      state_name: "Georgia",
    },
    {
      city_id: "10223",
      city: "Irwinton",
      zip_code: "31042",
      state_id: "GA",
      state_name: "Georgia",
    },
    {
      city_id: "9895",
      city: "Jackson",
      zip_code: "30233",
      state_id: "GA",
      state_name: "Georgia",
    },
    {
      city_id: "10337",
      city: "Jacksonville",
      zip_code: "31544",
      state_id: "GA",
      state_name: "Georgia",
    },
    {
      city_id: "9851",
      city: "Jasper",
      zip_code: "30143",
      state_id: "GA",
      state_name: "Georgia",
    },
    {
      city_id: "10055",
      city: "Jefferson",
      zip_code: "30549",
      state_id: "GA",
      state_name: "Georgia",
    },
    {
      city_id: "10224",
      city: "Jeffersonville",
      zip_code: "31044",
      state_id: "GA",
      state_name: "Georgia",
    },
    {
      city_id: "10329",
      city: "Jekyll Island",
      zip_code: "31527",
      state_id: "GA",
      state_name: "Georgia",
    },
    {
      city_id: "9896",
      city: "Jenkinsburg",
      zip_code: "30234",
      state_id: "GA",
      state_name: "Georgia",
    },
    {
      city_id: "10338",
      city: "Jesup",
      zip_code: "31545",
      state_id: "GA",
      state_name: "Georgia",
    },
    {
      city_id: "10225",
      city: "Jewell",
      zip_code: "31045",
      state_id: "GA",
      state_name: "Georgia",
    },
    {
      city_id: "9897",
      city: "Jonesboro",
      zip_code: "30236",
      state_id: "GA",
      state_name: "Georgia",
    },
    {
      city_id: "10226",
      city: "Juliette",
      zip_code: "31046",
      state_id: "GA",
      state_name: "Georgia",
    },
    {
      city_id: "10455",
      city: "Junction City",
      zip_code: "31812",
      state_id: "GA",
      state_name: "Georgia",
    },
    {
      city_id: "9852",
      city: "Kennesaw",
      zip_code: "30144",
      state_id: "GA",
      state_name: "Georgia",
    },
    {
      city_id: "10340",
      city: "Kings Bay",
      zip_code: "31547",
      state_id: "GA",
      state_name: "Georgia",
    },
    {
      city_id: "10341",
      city: "Kingsland",
      zip_code: "31548",
      state_id: "GA",
      state_name: "Georgia",
    },
    {
      city_id: "9853",
      city: "Kingston",
      zip_code: "30145",
      state_id: "GA",
      state_name: "Georgia",
    },
    {
      city_id: "10228",
      city: "Kite",
      zip_code: "31049",
      state_id: "GA",
      state_name: "Georgia",
    },
    {
      city_id: "10229",
      city: "Knoxville",
      zip_code: "31050",
      state_id: "GA",
      state_name: "Georgia",
    },
    {
      city_id: "10136",
      city: "La Fayette",
      zip_code: "30728",
      state_id: "GA",
      state_name: "Georgia",
    },
    {
      city_id: "9899",
      city: "Lagrange",
      zip_code: "30240",
      state_id: "GA",
      state_name: "Georgia",
    },
    {
      city_id: "10380",
      city: "Lake Park",
      zip_code: "31636",
      state_id: "GA",
      state_name: "Georgia",
    },
    {
      city_id: "10379",
      city: "Lakeland",
      zip_code: "31635",
      state_id: "GA",
      state_name: "Georgia",
    },
    {
      city_id: "10056",
      city: "Lakemont",
      zip_code: "30552",
      state_id: "GA",
      state_name: "Georgia",
    },
    {
      city_id: "10057",
      city: "Lavonia",
      zip_code: "30553",
      state_id: "GA",
      state_name: "Georgia",
    },
    {
      city_id: "9787",
      city: "Lawrenceville",
      zip_code: "30043",
      state_id: "GA",
      state_name: "Georgia",
    },
    {
      city_id: "10107",
      city: "Lexington",
      zip_code: "30648",
      state_id: "GA",
      state_name: "Georgia",
    },
    {
      city_id: "9791",
      city: "Lilburn",
      zip_code: "30047",
      state_id: "GA",
      state_name: "Georgia",
    },
    {
      city_id: "10169",
      city: "Lincolnton",
      zip_code: "30817",
      state_id: "GA",
      state_name: "Georgia",
    },
    {
      city_id: "9854",
      city: "Lindale",
      zip_code: "30147",
      state_id: "GA",
      state_name: "Georgia",
    },
    {
      city_id: "9840",
      city: "Lithia Springs",
      zip_code: "30122",
      state_id: "GA",
      state_name: "Georgia",
    },
    {
      city_id: "9783",
      city: "Lithonia",
      zip_code: "30038",
      state_id: "GA",
      state_name: "Georgia",
    },
    {
      city_id: "10231",
      city: "Lizella",
      zip_code: "31052",
      state_id: "GA",
      state_name: "Georgia",
    },
    {
      city_id: "9901",
      city: "Locust Grove",
      zip_code: "30248",
      state_id: "GA",
      state_name: "Georgia",
    },
    {
      city_id: "9792",
      city: "Loganville",
      zip_code: "30052",
      state_id: "GA",
      state_name: "Georgia",
    },
    {
      city_id: "10150",
      city: "Lookout Mountain",
      zip_code: "30750",
      state_id: "GA",
      state_name: "Georgia",
    },
    {
      city_id: "9991",
      city: "Louisville",
      zip_code: "30434",
      state_id: "GA",
      state_name: "Georgia",
    },
    {
      city_id: "9902",
      city: "Lovejoy",
      zip_code: "30250",
      state_id: "GA",
      state_name: "Georgia",
    },
    {
      city_id: "10291",
      city: "Ludowici",
      zip_code: "31316",
      state_id: "GA",
      state_name: "Georgia",
    },
    {
      city_id: "10058",
      city: "Lula",
      zip_code: "30554",
      state_id: "GA",
      state_name: "Georgia",
    },
    {
      city_id: "10342",
      city: "Lumber City",
      zip_code: "31549",
      state_id: "GA",
      state_name: "Georgia",
    },
    {
      city_id: "9903",
      city: "Luthersville",
      zip_code: "30251",
      state_id: "GA",
      state_name: "Georgia",
    },
    {
      city_id: "10137",
      city: "Lyerly",
      zip_code: "30730",
      state_id: "GA",
      state_name: "Georgia",
    },
    {
      city_id: "9992",
      city: "Lyons",
      zip_code: "30436",
      state_id: "GA",
      state_name: "Georgia",
    },
    {
      city_id: "9843",
      city: "Mableton",
      zip_code: "30126",
      state_id: "GA",
      state_name: "Georgia",
    },
    {
      city_id: "10270",
      city: "Macon",
      zip_code: "31201",
      state_id: "GA",
      state_name: "Georgia",
    },
    {
      city_id: "10108",
      city: "Madison",
      zip_code: "30650",
      state_id: "GA",
      state_name: "Georgia",
    },
    {
      city_id: "10458",
      city: "Manchester",
      zip_code: "31816",
      state_id: "GA",
      state_name: "Georgia",
    },
    {
      city_id: "10343",
      city: "Manor",
      zip_code: "31550",
      state_id: "GA",
      state_name: "Georgia",
    },
    {
      city_id: "9794",
      city: "Mansfield",
      zip_code: "30055",
      state_id: "GA",
      state_name: "Georgia",
    },
    {
      city_id: "9855",
      city: "Marble Hill",
      zip_code: "30148",
      state_id: "GA",
      state_name: "Georgia",
    },
    {
      city_id: "9763",
      city: "Marietta",
      zip_code: "30008",
      state_id: "GA",
      state_name: "Georgia",
    },
    {
      city_id: "10234",
      city: "Marshallville",
      zip_code: "31057",
      state_id: "GA",
      state_name: "Georgia",
    },
    {
      city_id: "10060",
      city: "Martin",
      zip_code: "30557",
      state_id: "GA",
      state_name: "Georgia",
    },
    {
      city_id: "10170",
      city: "Matthews",
      zip_code: "30818",
      state_id: "GA",
      state_name: "Georgia",
    },
    {
      city_id: "10235",
      city: "Mauk",
      zip_code: "31058",
      state_id: "GA",
      state_name: "Georgia",
    },
    {
      city_id: "10061",
      city: "Maysville",
      zip_code: "30558",
      state_id: "GA",
      state_name: "Georgia",
    },
    {
      city_id: "10059",
      city: "McCaysville",
      zip_code: "30555",
      state_id: "GA",
      state_name: "Georgia",
    },
    {
      city_id: "9904",
      city: "Mcdonough",
      zip_code: "30252",
      state_id: "GA",
      state_name: "Georgia",
    },
    {
      city_id: "10232",
      city: "McIntyre",
      zip_code: "31054",
      state_id: "GA",
      state_name: "Georgia",
    },
    {
      city_id: "10219",
      city: "McRae Helena",
      zip_code: "31037",
      state_id: "GA",
      state_name: "Georgia",
    },
    {
      city_id: "9906",
      city: "Meansville",
      zip_code: "30256",
      state_id: "GA",
      state_name: "Georgia",
    },
    {
      city_id: "10292",
      city: "Meldrim",
      zip_code: "31318",
      state_id: "GA",
      state_name: "Georgia",
    },
    {
      city_id: "10138",
      city: "Menlo",
      zip_code: "30731",
      state_id: "GA",
      state_name: "Georgia",
    },
    {
      city_id: "10344",
      city: "Mershon",
      zip_code: "31551",
      state_id: "GA",
      state_name: "Georgia",
    },
    {
      city_id: "9993",
      city: "Metter",
      zip_code: "30439",
      state_id: "GA",
      state_name: "Georgia",
    },
    {
      city_id: "9994",
      city: "Midville",
      zip_code: "30441",
      state_id: "GA",
      state_name: "Georgia",
    },
    {
      city_id: "10293",
      city: "Midway",
      zip_code: "31320",
      state_id: "GA",
      state_name: "Georgia",
    },
    {
      city_id: "10236",
      city: "Milan",
      zip_code: "31060",
      state_id: "GA",
      state_name: "Georgia",
    },
    {
      city_id: "10237",
      city: "Milledgeville",
      zip_code: "31061",
      state_id: "GA",
      state_name: "Georgia",
    },
    {
      city_id: "9995",
      city: "Millen",
      zip_code: "30442",
      state_id: "GA",
      state_name: "Georgia",
    },
    {
      city_id: "10345",
      city: "Millwood",
      zip_code: "31552",
      state_id: "GA",
      state_name: "Georgia",
    },
    {
      city_id: "9907",
      city: "Milner",
      zip_code: "30257",
      state_id: "GA",
      state_name: "Georgia",
    },
    {
      city_id: "10062",
      city: "Mineral Bluff",
      zip_code: "30559",
      state_id: "GA",
      state_name: "Georgia",
    },
    {
      city_id: "10171",
      city: "Mitchell",
      zip_code: "30820",
      state_id: "GA",
      state_name: "Georgia",
    },
    {
      city_id: "9908",
      city: "Molena",
      zip_code: "30258",
      state_id: "GA",
      state_name: "Georgia",
    },
    {
      city_id: "10109",
      city: "Monroe",
      zip_code: "30655",
      state_id: "GA",
      state_name: "Georgia",
    },
    {
      city_id: "10239",
      city: "Montezuma",
      zip_code: "31063",
      state_id: "GA",
      state_name: "Georgia",
    },
    {
      city_id: "10240",
      city: "Monticello",
      zip_code: "31064",
      state_id: "GA",
      state_name: "Georgia",
    },
    {
      city_id: "10241",
      city: "Montrose",
      zip_code: "31065",
      state_id: "GA",
      state_name: "Georgia",
    },
    {
      city_id: "10393",
      city: "Moody Afb",
      zip_code: "31699",
      state_id: "GA",
      state_name: "Georgia",
    },
    {
      city_id: "9909",
      city: "Moreland",
      zip_code: "30259",
      state_id: "GA",
      state_name: "Georgia",
    },
    {
      city_id: "10063",
      city: "Morganton",
      zip_code: "30560",
      state_id: "GA",
      state_name: "Georgia",
    },
    {
      city_id: "9910",
      city: "Morrow",
      zip_code: "30260",
      state_id: "GA",
      state_name: "Georgia",
    },
    {
      city_id: "10382",
      city: "Morven",
      zip_code: "31638",
      state_id: "GA",
      state_name: "Georgia",
    },
    {
      city_id: "10423",
      city: "Moultrie",
      zip_code: "31768",
      state_id: "GA",
      state_name: "Georgia",
    },
    {
      city_id: "10065",
      city: "Mount Airy",
      zip_code: "30563",
      state_id: "GA",
      state_name: "Georgia",
    },
    {
      city_id: "9856",
      city: "Mount Berry",
      zip_code: "30149",
      state_id: "GA",
      state_name: "Georgia",
    },
    {
      city_id: "9996",
      city: "Mount Vernon",
      zip_code: "30445",
      state_id: "GA",
      state_name: "Georgia",
    },
    {
      city_id: "10066",
      city: "Murrayville",
      zip_code: "30564",
      state_id: "GA",
      state_name: "Georgia",
    },
    {
      city_id: "10242",
      city: "Musella",
      zip_code: "31066",
      state_id: "GA",
      state_name: "Georgia",
    },
    {
      city_id: "10346",
      city: "Nahunta",
      zip_code: "31553",
      state_id: "GA",
      state_name: "Georgia",
    },
    {
      city_id: "10383",
      city: "Nashville",
      zip_code: "31639",
      state_id: "GA",
      state_name: "Georgia",
    },
    {
      city_id: "10384",
      city: "Naylor",
      zip_code: "31641",
      state_id: "GA",
      state_name: "Georgia",
    },
    {
      city_id: "9795",
      city: "Newborn",
      zip_code: "30056",
      state_id: "GA",
      state_name: "Georgia",
    },
    {
      city_id: "9997",
      city: "Newington",
      zip_code: "30446",
      state_id: "GA",
      state_name: "Georgia",
    },
    {
      city_id: "9911",
      city: "Newnan",
      zip_code: "30263",
      state_id: "GA",
      state_name: "Georgia",
    },
    {
      city_id: "10347",
      city: "Nicholls",
      zip_code: "31554",
      state_id: "GA",
      state_name: "Georgia",
    },
    {
      city_id: "10067",
      city: "Nicholson",
      zip_code: "30565",
      state_id: "GA",
      state_name: "Georgia",
    },
    {
      city_id: "9804",
      city: "Norcross",
      zip_code: "30071",
      state_id: "GA",
      state_name: "Georgia",
    },
    {
      city_id: "10172",
      city: "Norwood",
      zip_code: "30821",
      state_id: "GA",
      state_name: "Georgia",
    },
    {
      city_id: "9998",
      city: "Nunez",
      zip_code: "30448",
      state_id: "GA",
      state_name: "Georgia",
    },
    {
      city_id: "10068",
      city: "Oakwood",
      zip_code: "30566",
      state_id: "GA",
      state_name: "Georgia",
    },
    {
      city_id: "10427",
      city: "Ocilla",
      zip_code: "31774",
      state_id: "GA",
      state_name: "Georgia",
    },
    {
      city_id: "10243",
      city: "Oconee",
      zip_code: "31067",
      state_id: "GA",
      state_name: "Georgia",
    },
    {
      city_id: "10348",
      city: "Odum",
      zip_code: "31555",
      state_id: "GA",
      state_name: "Georgia",
    },
    {
      city_id: "10349",
      city: "Offerman",
      zip_code: "31556",
      state_id: "GA",
      state_name: "Georgia",
    },
    {
      city_id: "10244",
      city: "Oglethorpe",
      zip_code: "31068",
      state_id: "GA",
      state_name: "Georgia",
    },
    {
      city_id: "9793",
      city: "Oxford",
      zip_code: "30054",
      state_id: "GA",
      state_name: "Georgia",
    },
    {
      city_id: "9913",
      city: "Palmetto",
      zip_code: "30268",
      state_id: "GA",
      state_name: "Georgia",
    },
    {
      city_id: "10350",
      city: "Patterson",
      zip_code: "31557",
      state_id: "GA",
      state_name: "Georgia",
    },
    {
      city_id: "9914",
      city: "Peachtree City",
      zip_code: "30269",
      state_id: "GA",
      state_name: "Georgia",
    },
    {
      city_id: "9817",
      city: "Peachtree Corners",
      zip_code: "30092",
      state_id: "GA",
      state_name: "Georgia",
    },
    {
      city_id: "10385",
      city: "Pearson",
      zip_code: "31642",
      state_id: "GA",
      state_name: "Georgia",
    },
    {
      city_id: "10294",
      city: "Pembroke",
      zip_code: "31321",
      state_id: "GA",
      state_name: "Georgia",
    },
    {
      city_id: "10069",
      city: "Pendergrass",
      zip_code: "30567",
      state_id: "GA",
      state_name: "Georgia",
    },
    {
      city_id: "10245",
      city: "Perry",
      zip_code: "31069",
      state_id: "GA",
      state_name: "Georgia",
    },
    {
      city_id: "9805",
      city: "Pine Lake",
      zip_code: "30072",
      state_id: "GA",
      state_name: "Georgia",
    },
    {
      city_id: "10461",
      city: "Pine Mountain",
      zip_code: "31822",
      state_id: "GA",
      state_name: "Georgia",
    },
    {
      city_id: "10462",
      city: "Pine Mountain Valley",
      zip_code: "31823",
      state_id: "GA",
      state_name: "Georgia",
    },
    {
      city_id: "10139",
      city: "Plainville",
      zip_code: "30733",
      state_id: "GA",
      state_name: "Georgia",
    },
    {
      city_id: "10295",
      city: "Pooler",
      zip_code: "31322",
      state_id: "GA",
      state_name: "Georgia",
    },
    {
      city_id: "10308",
      city: "Port Wentworth",
      zip_code: "31407",
      state_id: "GA",
      state_name: "Georgia",
    },
    {
      city_id: "10000",
      city: "Portal",
      zip_code: "30450",
      state_id: "GA",
      state_name: "Georgia",
    },
    {
      city_id: "9803",
      city: "Porterdale",
      zip_code: "30070",
      state_id: "GA",
      state_name: "Georgia",
    },
    {
      city_id: "9844",
      city: "Powder Springs",
      zip_code: "30127",
      state_id: "GA",
      state_name: "Georgia",
    },
    {
      city_id: "10386",
      city: "Quitman",
      zip_code: "31643",
      state_id: "GA",
      state_name: "Georgia",
    },
    {
      city_id: "10140",
      city: "Ranger",
      zip_code: "30734",
      state_id: "GA",
      state_name: "Georgia",
    },
    {
      city_id: "10387",
      city: "Ray City",
      zip_code: "31645",
      state_id: "GA",
      state_name: "Georgia",
    },
    {
      city_id: "10111",
      city: "Rayle",
      zip_code: "30660",
      state_id: "GA",
      state_name: "Georgia",
    },
    {
      city_id: "9915",
      city: "Red Oak",
      zip_code: "30272",
      state_id: "GA",
      state_name: "Georgia",
    },
    {
      city_id: "10003",
      city: "Reidsville",
      zip_code: "30453",
      state_id: "GA",
      state_name: "Georgia",
    },
    {
      city_id: "10249",
      city: "Rentz",
      zip_code: "31075",
      state_id: "GA",
      state_name: "Georgia",
    },
    {
      city_id: "10141",
      city: "Resaca",
      zip_code: "30735",
      state_id: "GA",
      state_name: "Georgia",
    },
    {
      city_id: "9916",
      city: "Rex",
      zip_code: "30273",
      state_id: "GA",
      state_name: "Georgia",
    },
    {
      city_id: "10250",
      city: "Reynolds",
      zip_code: "31076",
      state_id: "GA",
      state_name: "Georgia",
    },
    {
      city_id: "10251",
      city: "Rhine",
      zip_code: "31077",
      state_id: "GA",
      state_name: "Georgia",
    },
    {
      city_id: "10296",
      city: "Riceboro",
      zip_code: "31323",
      state_id: "GA",
      state_name: "Georgia",
    },
    {
      city_id: "10297",
      city: "Richmond Hill",
      zip_code: "31324",
      state_id: "GA",
      state_name: "Georgia",
    },
    {
      city_id: "10298",
      city: "Rincon",
      zip_code: "31326",
      state_id: "GA",
      state_name: "Georgia",
    },
    {
      city_id: "10142",
      city: "Ringgold",
      zip_code: "30736",
      state_id: "GA",
      state_name: "Georgia",
    },
    {
      city_id: "10143",
      city: "Rising Fawn",
      zip_code: "30738",
      state_id: "GA",
      state_name: "Georgia",
    },
    {
      city_id: "9917",
      city: "Riverdale",
      zip_code: "30274",
      state_id: "GA",
      state_name: "Georgia",
    },
    {
      city_id: "10252",
      city: "Roberta",
      zip_code: "31078",
      state_id: "GA",
      state_name: "Georgia",
    },
    {
      city_id: "10144",
      city: "Rock Spring",
      zip_code: "30739",
      state_id: "GA",
      state_name: "Georgia",
    },
    {
      city_id: "10004",
      city: "Rockledge",
      zip_code: "30454",
      state_id: "GA",
      state_name: "Georgia",
    },
    {
      city_id: "9858",
      city: "Rockmart",
      zip_code: "30153",
      state_id: "GA",
      state_name: "Georgia",
    },
    {
      city_id: "10145",
      city: "Rocky Face",
      zip_code: "30740",
      state_id: "GA",
      state_name: "Georgia",
    },
    {
      city_id: "9860",
      city: "Rome",
      zip_code: "30161",
      state_id: "GA",
      state_name: "Georgia",
    },
    {
      city_id: "9863",
      city: "Roopville",
      zip_code: "30170",
      state_id: "GA",
      state_name: "Georgia",
    },
    {
      city_id: "10146",
      city: "Rossville",
      zip_code: "30741",
      state_id: "GA",
      state_name: "Georgia",
    },
    {
      city_id: "9806",
      city: "Roswell",
      zip_code: "30075",
      state_id: "GA",
      state_name: "Georgia",
    },
    {
      city_id: "10112",
      city: "Royston",
      zip_code: "30662",
      state_id: "GA",
      state_name: "Georgia",
    },
    {
      city_id: "10254",
      city: "Rupert",
      zip_code: "31081",
      state_id: "GA",
      state_name: "Georgia",
    },
    {
      city_id: "9864",
      city: "Rydal",
      zip_code: "30171",
      state_id: "GA",
      state_name: "Georgia",
    },
    {
      city_id: "10354",
      city: "Saint George",
      zip_code: "31562",
      state_id: "GA",
      state_name: "Georgia",
    },
    {
      city_id: "10351",
      city: "Saint Marys",
      zip_code: "31558",
      state_id: "GA",
      state_name: "Georgia",
    },
    {
      city_id: "10325",
      city: "Saint Simons Island",
      zip_code: "31522",
      state_id: "GA",
      state_name: "Georgia",
    },
    {
      city_id: "10255",
      city: "Sandersville",
      zip_code: "31082",
      state_id: "GA",
      state_name: "Georgia",
    },
    {
      city_id: "10299",
      city: "Sapelo Island",
      zip_code: "31327",
      state_id: "GA",
      state_name: "Georgia",
    },
    {
      city_id: "9918",
      city: "Sargent",
      zip_code: "30275",
      state_id: "GA",
      state_name: "Georgia",
    },
    {
      city_id: "10071",
      city: "Sautee Nacoochee",
      zip_code: "30571",
      state_id: "GA",
      state_name: "Georgia",
    },
    {
      city_id: "10304",
      city: "Savannah",
      zip_code: "31401",
      state_id: "GA",
      state_name: "Georgia",
    },
    {
      city_id: "10256",
      city: "Scotland",
      zip_code: "31083",
      state_id: "GA",
      state_name: "Georgia",
    },
    {
      city_id: "9809",
      city: "Scottdale",
      zip_code: "30079",
      state_id: "GA",
      state_name: "Georgia",
    },
    {
      city_id: "10352",
      city: "Screven",
      zip_code: "31560",
      state_id: "GA",
      state_name: "Georgia",
    },
    {
      city_id: "10353",
      city: "Sea Island",
      zip_code: "31561",
      state_id: "GA",
      state_name: "Georgia",
    },
    {
      city_id: "9919",
      city: "Senoia",
      zip_code: "30276",
      state_id: "GA",
      state_name: "Georgia",
    },
    {
      city_id: "10114",
      city: "Sharon",
      zip_code: "30664",
      state_id: "GA",
      state_name: "Georgia",
    },
    {
      city_id: "9920",
      city: "Sharpsburg",
      zip_code: "30277",
      state_id: "GA",
      state_name: "Georgia",
    },
    {
      city_id: "10465",
      city: "Shiloh",
      zip_code: "31826",
      state_id: "GA",
      state_name: "Georgia",
    },
    {
      city_id: "9865",
      city: "Silver Creek",
      zip_code: "30173",
      state_id: "GA",
      state_name: "Georgia",
    },
    {
      city_id: "9810",
      city: "Smyrna",
      zip_code: "30080",
      state_id: "GA",
      state_name: "Georgia",
    },
    {
      city_id: "9784",
      city: "Snellville",
      zip_code: "30039",
      state_id: "GA",
      state_name: "Georgia",
    },
    {
      city_id: "9776",
      city: "Social Circle",
      zip_code: "30025",
      state_id: "GA",
      state_name: "Georgia",
    },
    {
      city_id: "10007",
      city: "Soperton",
      zip_code: "30457",
      state_id: "GA",
      state_name: "Georgia",
    },
    {
      city_id: "10259",
      city: "Sparta",
      zip_code: "31087",
      state_id: "GA",
      state_name: "Georgia",
    },
    {
      city_id: "10301",
      city: "Springfield",
      zip_code: "31329",
      state_id: "GA",
      state_name: "Georgia",
    },
    {
      city_id: "10174",
      city: "Stapleton",
      zip_code: "30823",
      state_id: "GA",
      state_name: "Georgia",
    },
    {
      city_id: "10389",
      city: "Statenville",
      zip_code: "31648",
      state_id: "GA",
      state_name: "Georgia",
    },
    {
      city_id: "10008",
      city: "Statesboro",
      zip_code: "30458",
      state_id: "GA",
      state_name: "Georgia",
    },
    {
      city_id: "10116",
      city: "Statham",
      zip_code: "30666",
      state_id: "GA",
      state_name: "Georgia",
    },
    {
      city_id: "10117",
      city: "Stephens",
      zip_code: "30667",
      state_id: "GA",
      state_name: "Georgia",
    },
    {
      city_id: "10011",
      city: "Stillmore",
      zip_code: "30464",
      state_id: "GA",
      state_name: "Georgia",
    },
    {
      city_id: "9921",
      city: "Stockbridge",
      zip_code: "30281",
      state_id: "GA",
      state_name: "Georgia",
    },
    {
      city_id: "10390",
      city: "Stockton",
      zip_code: "31649",
      state_id: "GA",
      state_name: "Georgia",
    },
    {
      city_id: "9812",
      city: "Stone Mountain",
      zip_code: "30083",
      state_id: "GA",
      state_name: "Georgia",
    },
    {
      city_id: "10072",
      city: "Suches",
      zip_code: "30572",
      state_id: "GA",
      state_name: "Georgia",
    },
    {
      city_id: "10148",
      city: "Sugar Valley",
      zip_code: "30746",
      state_id: "GA",
      state_name: "Georgia",
    },
    {
      city_id: "10149",
      city: "Summerville",
      zip_code: "30747",
      state_id: "GA",
      state_name: "Georgia",
    },
    {
      city_id: "9922",
      city: "Sunny Side",
      zip_code: "30284",
      state_id: "GA",
      state_name: "Georgia",
    },
    {
      city_id: "10355",
      city: "Surrency",
      zip_code: "31563",
      state_id: "GA",
      state_name: "Georgia",
    },
    {
      city_id: "9775",
      city: "Suwanee",
      zip_code: "30024",
      state_id: "GA",
      state_name: "Georgia",
    },
    {
      city_id: "9975",
      city: "Swainsboro",
      zip_code: "30401",
      state_id: "GA",
      state_name: "Georgia",
    },
    {
      city_id: "10012",
      city: "Sylvania",
      zip_code: "30467",
      state_id: "GA",
      state_name: "Georgia",
    },
    {
      city_id: "10466",
      city: "Talbotton",
      zip_code: "31827",
      state_id: "GA",
      state_name: "Georgia",
    },
    {
      city_id: "9866",
      city: "Talking Rock",
      zip_code: "30175",
      state_id: "GA",
      state_name: "Georgia",
    },
    {
      city_id: "9867",
      city: "Tallapoosa",
      zip_code: "30176",
      state_id: "GA",
      state_name: "Georgia",
    },
    {
      city_id: "10074",
      city: "Talmo",
      zip_code: "30575",
      state_id: "GA",
      state_name: "Georgia",
    },
    {
      city_id: "10013",
      city: "Tarrytown",
      zip_code: "30470",
      state_id: "GA",
      state_name: "Georgia",
    },
    {
      city_id: "9868",
      city: "Tate",
      zip_code: "30177",
      state_id: "GA",
      state_name: "Georgia",
    },
    {
      city_id: "9869",
      city: "Taylorsville",
      zip_code: "30178",
      state_id: "GA",
      state_name: "Georgia",
    },
    {
      city_id: "9870",
      city: "Temple",
      zip_code: "30179",
      state_id: "GA",
      state_name: "Georgia",
    },
    {
      city_id: "10151",
      city: "Tennga",
      zip_code: "30751",
      state_id: "GA",
      state_name: "Georgia",
    },
    {
      city_id: "10261",
      city: "Tennille",
      zip_code: "31089",
      state_id: "GA",
      state_name: "Georgia",
    },
    {
      city_id: "9923",
      city: "The Rock",
      zip_code: "30285",
      state_id: "GA",
      state_name: "Georgia",
    },
    {
      city_id: "9924",
      city: "Thomaston",
      zip_code: "30286",
      state_id: "GA",
      state_name: "Georgia",
    },
    {
      city_id: "10175",
      city: "Thomson",
      zip_code: "30824",
      state_id: "GA",
      state_name: "Georgia",
    },
    {
      city_id: "10118",
      city: "Tignall",
      zip_code: "30668",
      state_id: "GA",
      state_name: "Georgia",
    },
    {
      city_id: "10076",
      city: "Toccoa",
      zip_code: "30577",
      state_id: "GA",
      state_name: "Georgia",
    },
    {
      city_id: "10262",
      city: "Toomsboro",
      zip_code: "31090",
      state_id: "GA",
      state_name: "Georgia",
    },
    {
      city_id: "10302",
      city: "Townsend",
      zip_code: "31331",
      state_id: "GA",
      state_name: "Georgia",
    },
    {
      city_id: "10152",
      city: "Trenton",
      zip_code: "30752",
      state_id: "GA",
      state_name: "Georgia",
    },
    {
      city_id: "10153",
      city: "Trion",
      zip_code: "30753",
      state_id: "GA",
      state_name: "Georgia",
    },
    {
      city_id: "9813",
      city: "Tucker",
      zip_code: "30084",
      state_id: "GA",
      state_name: "Georgia",
    },
    {
      city_id: "10154",
      city: "Tunnel Hill",
      zip_code: "30755",
      state_id: "GA",
      state_name: "Georgia",
    },
    {
      city_id: "9926",
      city: "Turin",
      zip_code: "30289",
      state_id: "GA",
      state_name: "Georgia",
    },
    {
      city_id: "10014",
      city: "Twin City",
      zip_code: "30471",
      state_id: "GA",
      state_name: "Georgia",
    },
    {
      city_id: "10300",
      city: "Tybee Island",
      zip_code: "31328",
      state_id: "GA",
      state_name: "Georgia",
    },
    {
      city_id: "9927",
      city: "Tyrone",
      zip_code: "30290",
      state_id: "GA",
      state_name: "Georgia",
    },
    {
      city_id: "10263",
      city: "Unadilla",
      zip_code: "31091",
      state_id: "GA",
      state_name: "Georgia",
    },
    {
      city_id: "9928",
      city: "Union City",
      zip_code: "30291",
      state_id: "GA",
      state_name: "Georgia",
    },
    {
      city_id: "10119",
      city: "Union Point",
      zip_code: "30669",
      state_id: "GA",
      state_name: "Georgia",
    },
    {
      city_id: "10467",
      city: "Upatoi",
      zip_code: "31829",
      state_id: "GA",
      state_name: "Georgia",
    },
    {
      city_id: "10015",
      city: "Uvalda",
      zip_code: "30473",
      state_id: "GA",
      state_name: "Georgia",
    },
    {
      city_id: "10363",
      city: "Valdosta",
      zip_code: "31601",
      state_id: "GA",
      state_name: "Georgia",
    },
    {
      city_id: "10016",
      city: "Vidalia",
      zip_code: "30474",
      state_id: "GA",
      state_name: "Georgia",
    },
    {
      city_id: "10264",
      city: "Vienna",
      zip_code: "31092",
      state_id: "GA",
      state_name: "Georgia",
    },
    {
      city_id: "9871",
      city: "Villa Rica",
      zip_code: "30180",
      state_id: "GA",
      state_name: "Georgia",
    },
    {
      city_id: "9872",
      city: "Waco",
      zip_code: "30182",
      state_id: "GA",
      state_name: "Georgia",
    },
    {
      city_id: "10017",
      city: "Wadley",
      zip_code: "30477",
      state_id: "GA",
      state_name: "Georgia",
    },
    {
      city_id: "9873",
      city: "Waleska",
      zip_code: "30183",
      state_id: "GA",
      state_name: "Georgia",
    },
    {
      city_id: "10303",
      city: "Walthourville",
      zip_code: "31333",
      state_id: "GA",
      state_name: "Georgia",
    },
    {
      city_id: "10356",
      city: "Waresboro",
      zip_code: "31564",
      state_id: "GA",
      state_name: "Georgia",
    },
    {
      city_id: "10468",
      city: "Warm Springs",
      zip_code: "31830",
      state_id: "GA",
      state_name: "Georgia",
    },
    {
      city_id: "10260",
      city: "Warner Robins",
      zip_code: "31088",
      state_id: "GA",
      state_name: "Georgia",
    },
    {
      city_id: "10176",
      city: "Warrenton",
      zip_code: "30828",
      state_id: "GA",
      state_name: "Georgia",
    },
    {
      city_id: "10266",
      city: "Warthen",
      zip_code: "31094",
      state_id: "GA",
      state_name: "Georgia",
    },
    {
      city_id: "10120",
      city: "Washington",
      zip_code: "30673",
      state_id: "GA",
      state_name: "Georgia",
    },
    {
      city_id: "10121",
      city: "Watkinsville",
      zip_code: "30677",
      state_id: "GA",
      state_name: "Georgia",
    },
    {
      city_id: "10357",
      city: "Waverly",
      zip_code: "31565",
      state_id: "GA",
      state_name: "Georgia",
    },
    {
      city_id: "10469",
      city: "Waverly Hall",
      zip_code: "31831",
      state_id: "GA",
      state_name: "Georgia",
    },
    {
      city_id: "10316",
      city: "Waycross",
      zip_code: "31501",
      state_id: "GA",
      state_name: "Georgia",
    },
    {
      city_id: "10177",
      city: "Waynesboro",
      zip_code: "30830",
      state_id: "GA",
      state_name: "Georgia",
    },
    {
      city_id: "10358",
      city: "Waynesville",
      zip_code: "31566",
      state_id: "GA",
      state_name: "Georgia",
    },
    {
      city_id: "10471",
      city: "West Point",
      zip_code: "31833",
      state_id: "GA",
      state_name: "Georgia",
    },
    {
      city_id: "9874",
      city: "White",
      zip_code: "30184",
      state_id: "GA",
      state_name: "Georgia",
    },
    {
      city_id: "10360",
      city: "White Oak",
      zip_code: "31568",
      state_id: "GA",
      state_name: "Georgia",
    },
    {
      city_id: "9875",
      city: "Whitesburg",
      zip_code: "30185",
      state_id: "GA",
      state_name: "Georgia",
    },
    {
      city_id: "10156",
      city: "Wildwood",
      zip_code: "30757",
      state_id: "GA",
      state_name: "Georgia",
    },
    {
      city_id: "10391",
      city: "Willacoochee",
      zip_code: "31650",
      state_id: "GA",
      state_name: "Georgia",
    },
    {
      city_id: "9929",
      city: "Williamson",
      zip_code: "30292",
      state_id: "GA",
      state_name: "Georgia",
    },
    {
      city_id: "10123",
      city: "Winder",
      zip_code: "30680",
      state_id: "GA",
      state_name: "Georgia",
    },
    {
      city_id: "9876",
      city: "Winston",
      zip_code: "30187",
      state_id: "GA",
      state_name: "Georgia",
    },
    {
      city_id: "10124",
      city: "Winterville",
      zip_code: "30683",
      state_id: "GA",
      state_name: "Georgia",
    },
    {
      city_id: "10361",
      city: "Woodbine",
      zip_code: "31569",
      state_id: "GA",
      state_name: "Georgia",
    },
    {
      city_id: "9930",
      city: "Woodbury",
      zip_code: "30293",
      state_id: "GA",
      state_name: "Georgia",
    },
    {
      city_id: "10472",
      city: "Woodland",
      zip_code: "31836",
      state_id: "GA",
      state_name: "Georgia",
    },
    {
      city_id: "9877",
      city: "Woodstock",
      zip_code: "30188",
      state_id: "GA",
      state_name: "Georgia",
    },
    {
      city_id: "10178",
      city: "Wrens",
      zip_code: "30833",
      state_id: "GA",
      state_name: "Georgia",
    },
    {
      city_id: "10267",
      city: "Wrightsville",
      zip_code: "31096",
      state_id: "GA",
      state_name: "Georgia",
    },
    {
      city_id: "10268",
      city: "Yatesville",
      zip_code: "31097",
      state_id: "GA",
      state_name: "Georgia",
    },
    {
      city_id: "9932",
      city: "Zebulon",
      zip_code: "30295",
      state_id: "GA",
      state_name: "Georgia",
    },
    {
      city_id: "21404",
      city: "Abingdon",
      zip_code: "61410",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21405",
      city: "Adair",
      zip_code: "61411",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21756",
      city: "Addieville",
      zip_code: "62214",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "20884",
      city: "Addison",
      zip_code: "60101",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21297",
      city: "Albany",
      zip_code: "61230",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21757",
      city: "Albers",
      zip_code: "62215",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "22053",
      city: "Albion",
      zip_code: "62806",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21298",
      city: "Aledo",
      zip_code: "61231",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21986",
      city: "Alexander",
      zip_code: "62601",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21406",
      city: "Alexis",
      zip_code: "61412",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "20885",
      city: "Algonquin",
      zip_code: "60102",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21674",
      city: "Alhambra",
      zip_code: "62001",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21876",
      city: "Allendale",
      zip_code: "62410",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21588",
      city: "Allerton",
      zip_code: "61810",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "22054",
      city: "Alma",
      zip_code: "62807",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21407",
      city: "Alpha",
      zip_code: "61413",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21987",
      city: "Alsey",
      zip_code: "62610",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21159",
      city: "Alsip",
      zip_code: "60803",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21877",
      city: "Altamont",
      zip_code: "62411",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "22140",
      city: "Alto Pass",
      zip_code: "62905",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21675",
      city: "Alton",
      zip_code: "62002",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21408",
      city: "Altona",
      zip_code: "61414",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21589",
      city: "Alvin",
      zip_code: "61811",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21341",
      city: "Amboy",
      zip_code: "61310",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21536",
      city: "Anchor",
      zip_code: "61720",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21342",
      city: "Ancona",
      zip_code: "61311",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21299",
      city: "Andalusia",
      zip_code: "61232",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21300",
      city: "Andover",
      zip_code: "61233",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "22141",
      city: "Anna",
      zip_code: "62906",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21878",
      city: "Annapolis",
      zip_code: "62413",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21301",
      city: "Annawan",
      zip_code: "61234",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "20819",
      city: "Antioch",
      zip_code: "60002",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21219",
      city: "Apple River",
      zip_code: "61001",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21644",
      city: "Arcola",
      zip_code: "61910",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21988",
      city: "Arenzville",
      zip_code: "62611",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21935",
      city: "Argenta",
      zip_code: "62501",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21343",
      city: "Arlington",
      zip_code: "61312",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "20820",
      city: "Arlington Heights",
      zip_code: "60004",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21537",
      city: "Armington",
      zip_code: "61721",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21590",
      city: "Armstrong",
      zip_code: "61812",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21164",
      city: "Aroma Park",
      zip_code: "60910",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21538",
      city: "Arrowsmith",
      zip_code: "61722",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21645",
      city: "Arthur",
      zip_code: "61911",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21165",
      city: "Ashkum",
      zip_code: "60911",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21989",
      city: "Ashland",
      zip_code: "62612",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "22055",
      city: "Ashley",
      zip_code: "62808",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21646",
      city: "Ashmore",
      zip_code: "61912",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21220",
      city: "Ashton",
      zip_code: "61006",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21936",
      city: "Assumption",
      zip_code: "62510",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21475",
      city: "Astoria",
      zip_code: "61501",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21990",
      city: "Athens",
      zip_code: "62613",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21302",
      city: "Atkinson",
      zip_code: "61235",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21539",
      city: "Atlanta",
      zip_code: "61723",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21647",
      city: "Atwood",
      zip_code: "61913",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21991",
      city: "Auburn",
      zip_code: "62615",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21825",
      city: "Augusta",
      zip_code: "62311",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21044",
      city: "Aurora",
      zip_code: "60502",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "22142",
      city: "Ava",
      zip_code: "62907",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21758",
      city: "Aviston",
      zip_code: "62216",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21409",
      city: "Avon",
      zip_code: "61415",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21221",
      city: "Baileyville",
      zip_code: "61007",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21759",
      city: "Baldwin",
      zip_code: "62217",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21410",
      city: "Bardolph",
      zip_code: "61416",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "22056",
      city: "Barnhill",
      zip_code: "62809",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "20824",
      city: "Barrington",
      zip_code: "60010",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21826",
      city: "Barry",
      zip_code: "62312",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21303",
      city: "Barstow",
      zip_code: "61236",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21760",
      city: "Bartelso",
      zip_code: "62218",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "20886",
      city: "Bartlett",
      zip_code: "60103",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21827",
      city: "Basco",
      zip_code: "62313",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21049",
      city: "Batavia",
      zip_code: "60510",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21676",
      city: "Batchtown",
      zip_code: "62006",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21992",
      city: "Bath",
      zip_code: "62617",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21828",
      city: "Baylis",
      zip_code: "62314",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21993",
      city: "Beardstown",
      zip_code: "62618",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21937",
      city: "Beason",
      zip_code: "62512",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21166",
      city: "Beaverville",
      zip_code: "60912",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21761",
      city: "Beckemeyer",
      zip_code: "62219",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "20964",
      city: "Beecher",
      zip_code: "60401",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21879",
      city: "Beecher City",
      zip_code: "62414",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "22143",
      city: "Belknap",
      zip_code: "62908",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "22057",
      city: "Belle Rive",
      zip_code: "62810",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21762",
      city: "Belleville",
      zip_code: "62220",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21540",
      city: "Bellflower",
      zip_code: "61724",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "22058",
      city: "Bellmont",
      zip_code: "62811",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "20887",
      city: "Bellwood",
      zip_code: "60104",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21222",
      city: "Belvidere",
      zip_code: "61008",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21591",
      city: "Bement",
      zip_code: "61813",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21677",
      city: "Benld",
      zip_code: "62009",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "20888",
      city: "Bensenville",
      zip_code: "60106",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21476",
      city: "Benson",
      zip_code: "61516",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "22059",
      city: "Benton",
      zip_code: "62812",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "20930",
      city: "Berkeley",
      zip_code: "60163",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21411",
      city: "Berwick",
      zip_code: "61417",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "20965",
      city: "Berwyn",
      zip_code: "60402",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21678",
      city: "Bethalto",
      zip_code: "62010",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21648",
      city: "Bethany",
      zip_code: "61914",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21050",
      city: "Big Rock",
      zip_code: "60511",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21412",
      city: "Biggsville",
      zip_code: "61418",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21679",
      city: "Bingham",
      zip_code: "62011",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21413",
      city: "Bishop Hill",
      zip_code: "61419",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21592",
      city: "Bismarck",
      zip_code: "61814",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21344",
      city: "Blackstone",
      zip_code: "61313",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21414",
      city: "Blandinsville",
      zip_code: "61420",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "20890",
      city: "Bloomingdale",
      zip_code: "60108",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21533",
      city: "Bloomington",
      zip_code: "61701",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "20968",
      city: "Blue Island",
      zip_code: "60406",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21938",
      city: "Blue Mound",
      zip_code: "62513",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21995",
      city: "Bluff Springs",
      zip_code: "62622",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21994",
      city: "Bluffs",
      zip_code: "62621",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "22060",
      city: "Bluford",
      zip_code: "62814",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "20997",
      city: "Bolingbrook",
      zip_code: "60440",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21593",
      city: "Bondville",
      zip_code: "61815",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "22061",
      city: "Bone Gap",
      zip_code: "62815",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21167",
      city: "Bonfield",
      zip_code: "60913",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "22062",
      city: "Bonnie",
      zip_code: "62816",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21939",
      city: "Boody",
      zip_code: "62514",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21168",
      city: "Bourbonnais",
      zip_code: "60914",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21829",
      city: "Bowen",
      zip_code: "62316",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "20969",
      city: "Braceville",
      zip_code: "60407",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21415",
      city: "Bradford",
      zip_code: "61421",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21169",
      city: "Bradley",
      zip_code: "60915",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "20970",
      city: "Braidwood",
      zip_code: "60408",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21767",
      city: "Breese",
      zip_code: "62230",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21880",
      city: "Bridgeport",
      zip_code: "62417",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21011",
      city: "Bridgeview",
      zip_code: "60455",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21680",
      city: "Brighton",
      zip_code: "62012",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21477",
      city: "Brimfield",
      zip_code: "61517",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21051",
      city: "Bristol",
      zip_code: "60512",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21594",
      city: "Broadlands",
      zip_code: "61816",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "20925",
      city: "Broadview",
      zip_code: "60155",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21649",
      city: "Brocton",
      zip_code: "61917",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21052",
      city: "Brookfield",
      zip_code: "60513",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "22144",
      city: "Brookport",
      zip_code: "62910",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "22063",
      city: "Broughton",
      zip_code: "62817",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21996",
      city: "Browning",
      zip_code: "62624",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "22064",
      city: "Browns",
      zip_code: "62818",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21881",
      city: "Brownstown",
      zip_code: "62418",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21681",
      city: "Brussels",
      zip_code: "62013",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21478",
      city: "Bryant",
      zip_code: "61519",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21170",
      city: "Buckingham",
      zip_code: "60917",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21171",
      city: "Buckley",
      zip_code: "60918",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "22065",
      city: "Buckner",
      zip_code: "62819",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21345",
      city: "Buda",
      zip_code: "61314",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21940",
      city: "Buffalo",
      zip_code: "62515",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "20876",
      city: "Buffalo Grove",
      zip_code: "60089",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21304",
      city: "Buffalo Prairie",
      zip_code: "61237",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21941",
      city: "Bulpitt",
      zip_code: "62517",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "22145",
      city: "Buncombe",
      zip_code: "62912",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21682",
      city: "Bunker Hill",
      zip_code: "62014",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21015",
      city: "Burbank",
      zip_code: "60459",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21346",
      city: "Bureau",
      zip_code: "61315",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "20891",
      city: "Burlington",
      zip_code: "60109",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "22066",
      city: "Burnt Prairie",
      zip_code: "62820",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21416",
      city: "Bushnell",
      zip_code: "61422",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21683",
      city: "Butler",
      zip_code: "62015",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21223",
      city: "Byron",
      zip_code: "61010",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21172",
      city: "Cabery",
      zip_code: "60919",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "22146",
      city: "Cairo",
      zip_code: "62914",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21224",
      city: "Caledonia",
      zip_code: "61011",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21882",
      city: "Calhoun",
      zip_code: "62419",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "20971",
      city: "Calumet City",
      zip_code: "60409",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21650",
      city: "Camargo",
      zip_code: "61919",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "22147",
      city: "Cambria",
      zip_code: "62915",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21305",
      city: "Cambridge",
      zip_code: "61238",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21830",
      city: "Camden",
      zip_code: "62319",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21417",
      city: "Cameron",
      zip_code: "61423",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21418",
      city: "Camp Grove",
      zip_code: "61424",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21831",
      city: "Camp Point",
      zip_code: "62320",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "22148",
      city: "Campbell Hill",
      zip_code: "62916",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21173",
      city: "Campus",
      zip_code: "60920",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21479",
      city: "Canton",
      zip_code: "61520",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21997",
      city: "Cantrall",
      zip_code: "62625",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21225",
      city: "Capron",
      zip_code: "61012",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21306",
      city: "Carbon Cliff",
      zip_code: "61239",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "22137",
      city: "Carbondale",
      zip_code: "62901",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21998",
      city: "Carlinville",
      zip_code: "62626",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21541",
      city: "Carlock",
      zip_code: "61725",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21768",
      city: "Carlyle",
      zip_code: "62231",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21419",
      city: "Carman",
      zip_code: "61425",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "22067",
      city: "Carmi",
      zip_code: "62821",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "20948",
      city: "Carol Stream",
      zip_code: "60188",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "20892",
      city: "Carpentersville",
      zip_code: "60110",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "22149",
      city: "Carrier Mills",
      zip_code: "62917",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21684",
      city: "Carrollton",
      zip_code: "62016",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "22150",
      city: "Carterville",
      zip_code: "62918",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21832",
      city: "Carthage",
      zip_code: "62321",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "20826",
      city: "Cary",
      zip_code: "60013",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21883",
      city: "Casey",
      zip_code: "62420",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21769",
      city: "Caseyville",
      zip_code: "62232",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21420",
      city: "Castleton",
      zip_code: "61426",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21595",
      city: "Catlin",
      zip_code: "61817",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "22151",
      city: "Cave In Rock",
      zip_code: "62919",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21347",
      city: "Cedar Point",
      zip_code: "61316",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21226",
      city: "Cedarville",
      zip_code: "61013",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "22051",
      city: "Centralia",
      zip_code: "62801",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21596",
      city: "Cerro Gordo",
      zip_code: "61818",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21227",
      city: "Chadwick",
      zip_code: "61014",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21833",
      city: "Chambersburg",
      zip_code: "62323",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21597",
      city: "Champaign",
      zip_code: "61820",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21228",
      city: "Chana",
      zip_code: "61015",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21999",
      city: "Chandlerville",
      zip_code: "62627",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "20972",
      city: "Channahon",
      zip_code: "60410",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "22000",
      city: "Chapin",
      zip_code: "62628",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21651",
      city: "Charleston",
      zip_code: "61920",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "22001",
      city: "Chatham",
      zip_code: "62629",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21174",
      city: "Chatsworth",
      zip_code: "60921",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21175",
      city: "Chebanse",
      zip_code: "60922",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21542",
      city: "Chenoa",
      zip_code: "61726",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21348",
      city: "Cherry",
      zip_code: "61317",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21229",
      city: "Cherry Valley",
      zip_code: "61016",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21770",
      city: "Chester",
      zip_code: "62233",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "22002",
      city: "Chesterfield",
      zip_code: "62630",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21942",
      city: "Chestnut",
      zip_code: "62518",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21099",
      city: "Chicago",
      zip_code: "60601",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "20973",
      city: "Chicago Heights",
      zip_code: "60411",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "20974",
      city: "Chicago Ridge",
      zip_code: "60415",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21480",
      city: "Chillicothe",
      zip_code: "61523",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21652",
      city: "Chrisman",
      zip_code: "61924",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "22068",
      city: "Christopher",
      zip_code: "62822",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21160",
      city: "Cicero",
      zip_code: "60804",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21600",
      city: "Cisco",
      zip_code: "61830",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "22069",
      city: "Cisne",
      zip_code: "62823",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21176",
      city: "Cissna Park",
      zip_code: "60924",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "20893",
      city: "Clare",
      zip_code: "60111",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21884",
      city: "Claremont",
      zip_code: "62421",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21053",
      city: "Clarendon Hills",
      zip_code: "60514",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "22070",
      city: "Clay City",
      zip_code: "62824",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21834",
      city: "Clayton",
      zip_code: "62324",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21177",
      city: "Claytonville",
      zip_code: "60926",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21178",
      city: "Clifton",
      zip_code: "60927",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21543",
      city: "Clinton",
      zip_code: "61727",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "20975",
      city: "Coal City",
      zip_code: "60416",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21307",
      city: "Coal Valley",
      zip_code: "61240",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21835",
      city: "Coatsburg",
      zip_code: "62325",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "22152",
      city: "Cobden",
      zip_code: "62920",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "22071",
      city: "Coello",
      zip_code: "62825",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21685",
      city: "Coffeen",
      zip_code: "62017",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21836",
      city: "Colchester",
      zip_code: "62326",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21544",
      city: "Colfax",
      zip_code: "61728",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21771",
      city: "Collinsville",
      zip_code: "62234",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21601",
      city: "Collison",
      zip_code: "61831",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21308",
      city: "Colona",
      zip_code: "61241",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "22153",
      city: "Colp",
      zip_code: "62921",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21772",
      city: "Columbia",
      zip_code: "62236",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21349",
      city: "Compton",
      zip_code: "61318",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "22003",
      city: "Concord",
      zip_code: "62631",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21545",
      city: "Congerville",
      zip_code: "61729",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21546",
      city: "Cooksville",
      zip_code: "61730",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21309",
      city: "Cordova",
      zip_code: "61242",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21350",
      city: "Cornell",
      zip_code: "61319",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21943",
      city: "Cornland",
      zip_code: "62519",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "20894",
      city: "Cortland",
      zip_code: "60112",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21686",
      city: "Cottage Hills",
      zip_code: "62018",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21773",
      city: "Coulterville",
      zip_code: "62237",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21034",
      city: "Country Club Hills",
      zip_code: "60478",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21885",
      city: "Cowden",
      zip_code: "62422",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "22154",
      city: "Creal Springs",
      zip_code: "62922",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21179",
      city: "Crescent City",
      zip_code: "60928",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "20966",
      city: "Crest Hill",
      zip_code: "60403",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "20895",
      city: "Creston",
      zip_code: "60113",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "20977",
      city: "Crestwood",
      zip_code: "60418",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "20976",
      city: "Crete",
      zip_code: "60417",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21527",
      city: "Creve Coeur",
      zip_code: "61610",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21547",
      city: "Cropsey",
      zip_code: "61731",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "22072",
      city: "Crossville",
      zip_code: "62827",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "20825",
      city: "Crystal Lake",
      zip_code: "60012",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21421",
      city: "Cuba",
      zip_code: "61427",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21180",
      city: "Cullom",
      zip_code: "60929",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21774",
      city: "Cutler",
      zip_code: "62238",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "22155",
      city: "Cypress",
      zip_code: "62923",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21422",
      city: "Dahinda",
      zip_code: "61428",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "22073",
      city: "Dahlgren",
      zip_code: "62828",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21230",
      city: "Dakota",
      zip_code: "61018",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "22074",
      city: "Dale",
      zip_code: "62829",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21837",
      city: "Dallas City",
      zip_code: "62330",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21653",
      city: "Dalton City",
      zip_code: "61925",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21351",
      city: "Dalzell",
      zip_code: "61320",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21352",
      city: "Dana",
      zip_code: "61321",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21181",
      city: "Danforth",
      zip_code: "60930",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21548",
      city: "Danvers",
      zip_code: "61732",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21602",
      city: "Danville",
      zip_code: "61832",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21093",
      city: "Darien",
      zip_code: "60561",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21231",
      city: "Davis",
      zip_code: "61019",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21232",
      city: "Davis Junction",
      zip_code: "61020",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21944",
      city: "Dawson",
      zip_code: "62520",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21605",
      city: "De Land",
      zip_code: "61839",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "22156",
      city: "De Soto",
      zip_code: "62924",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21945",
      city: "Decatur",
      zip_code: "62521",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21549",
      city: "Deer Creek",
      zip_code: "61733",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21310",
      city: "Deer Grove",
      zip_code: "61243",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "20828",
      city: "Deerfield",
      zip_code: "60015",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "20896",
      city: "Dekalb",
      zip_code: "60115",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21550",
      city: "Delavan",
      zip_code: "61734",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21886",
      city: "Dennison",
      zip_code: "62423",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21353",
      city: "Depue",
      zip_code: "61322",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "20829",
      city: "Des Plaines",
      zip_code: "60016",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21606",
      city: "Dewey",
      zip_code: "61840",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21551",
      city: "Dewitt",
      zip_code: "61735",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21887",
      city: "Dieterich",
      zip_code: "62424",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21949",
      city: "Divernon",
      zip_code: "62530",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "22075",
      city: "Dix",
      zip_code: "62830",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21233",
      city: "Dixon",
      zip_code: "61021",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "20978",
      city: "Dolton",
      zip_code: "60419",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "22157",
      city: "Dongola",
      zip_code: "62926",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21687",
      city: "Donnellson",
      zip_code: "62019",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21182",
      city: "Donovan",
      zip_code: "60931",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21688",
      city: "Dorsey",
      zip_code: "62021",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21354",
      city: "Dover",
      zip_code: "61323",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21689",
      city: "Dow",
      zip_code: "62022",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "22158",
      city: "Dowell",
      zip_code: "62927",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21054",
      city: "Downers Grove",
      zip_code: "60515",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21552",
      city: "Downs",
      zip_code: "61736",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "22076",
      city: "Du Bois",
      zip_code: "62831",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "22077",
      city: "Du Quoin",
      zip_code: "62832",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21888",
      city: "Dundas",
      zip_code: "62425",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "20897",
      city: "Dundee",
      zip_code: "60118",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21481",
      city: "Dunfermline",
      zip_code: "61524",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21482",
      city: "Dunlap",
      zip_code: "61525",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21775",
      city: "Dupo",
      zip_code: "62239",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21234",
      city: "Durand",
      zip_code: "61024",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "20979",
      city: "Dwight",
      zip_code: "60420",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21690",
      city: "Eagarville",
      zip_code: "62023",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21057",
      city: "Earlville",
      zip_code: "60518",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21691",
      city: "East Alton",
      zip_code: "62024",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21776",
      city: "East Carondelet",
      zip_code: "62240",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21235",
      city: "East Dubuque",
      zip_code: "61025",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21423",
      city: "East Galesburg",
      zip_code: "61430",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21183",
      city: "East Lynn",
      zip_code: "60932",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21311",
      city: "East Moline",
      zip_code: "61244",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21528",
      city: "East Peoria",
      zip_code: "61611",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21749",
      city: "East Saint Louis",
      zip_code: "62201",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "22004",
      city: "Easton",
      zip_code: "62633",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "22159",
      city: "Eddyville",
      zip_code: "62928",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21483",
      city: "Edelstein",
      zip_code: "61526",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21889",
      city: "Edgewood",
      zip_code: "62426",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21950",
      city: "Edinburg",
      zip_code: "62531",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21484",
      city: "Edwards",
      zip_code: "61528",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21692",
      city: "Edwardsville",
      zip_code: "62025",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21875",
      city: "Effingham",
      zip_code: "62401",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21554",
      city: "El Paso",
      zip_code: "61738",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "20898",
      city: "Elburn",
      zip_code: "60119",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21355",
      city: "Eldena",
      zip_code: "61324",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "22160",
      city: "Eldorado",
      zip_code: "62930",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21694",
      city: "Eldred",
      zip_code: "62027",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21236",
      city: "Eleroy",
      zip_code: "61027",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "20899",
      city: "Elgin",
      zip_code: "60120",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21237",
      city: "Elizabeth",
      zip_code: "61028",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "22161",
      city: "Elizabethtown",
      zip_code: "62931",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "20822",
      city: "Elk Grove Village",
      zip_code: "60007",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "22005",
      city: "Elkhart",
      zip_code: "62634",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "22162",
      city: "Elkville",
      zip_code: "62932",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "22078",
      city: "Ellery",
      zip_code: "62833",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21184",
      city: "Elliott",
      zip_code: "60933",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21777",
      city: "Ellis Grove",
      zip_code: "62241",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21424",
      city: "Ellisville",
      zip_code: "61431",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21553",
      city: "Ellsworth",
      zip_code: "61737",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "20902",
      city: "Elmhurst",
      zip_code: "60126",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21485",
      city: "Elmwood",
      zip_code: "61529",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21156",
      city: "Elmwood Park",
      zip_code: "60707",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21695",
      city: "Elsah",
      zip_code: "62028",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21838",
      city: "Elvaston",
      zip_code: "62334",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21951",
      city: "Elwin",
      zip_code: "62532",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "20980",
      city: "Elwood",
      zip_code: "60421",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "22006",
      city: "Emden",
      zip_code: "62635",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21185",
      city: "Emington",
      zip_code: "60934",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "22163",
      city: "Energy",
      zip_code: "62933",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "22079",
      city: "Enfield",
      zip_code: "62835",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21058",
      city: "Eola",
      zip_code: "60519",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "22164",
      city: "Equality",
      zip_code: "62934",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21312",
      city: "Erie",
      zip_code: "61250",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "20903",
      city: "Esmond",
      zip_code: "60129",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21186",
      city: "Essex",
      zip_code: "60935",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21486",
      city: "Eureka",
      zip_code: "61530",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "20956",
      city: "Evanston",
      zip_code: "60201",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21778",
      city: "Evansville",
      zip_code: "62242",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21161",
      city: "Evergreen Park",
      zip_code: "60805",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "22080",
      city: "Ewing",
      zip_code: "62836",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21555",
      city: "Fairbury",
      zip_code: "61739",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "22081",
      city: "Fairfield",
      zip_code: "62837",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21607",
      city: "Fairmount",
      zip_code: "61841",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21425",
      city: "Fairview",
      zip_code: "61432",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21755",
      city: "Fairview Heights",
      zip_code: "62208",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "22082",
      city: "Farina",
      zip_code: "62838",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21608",
      city: "Farmer City",
      zip_code: "61842",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21952",
      city: "Farmersville",
      zip_code: "62533",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21487",
      city: "Farmington",
      zip_code: "61531",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21313",
      city: "Fenton",
      zip_code: "61251",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21839",
      city: "Ferris",
      zip_code: "62336",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21426",
      city: "Fiatt",
      zip_code: "61433",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21696",
      city: "Fidelity",
      zip_code: "62030",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21697",
      city: "Fieldon",
      zip_code: "62031",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21698",
      city: "Fillmore",
      zip_code: "62032",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21953",
      city: "Findlay",
      zip_code: "62534",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21609",
      city: "Fisher",
      zip_code: "61843",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21610",
      city: "Fithian",
      zip_code: "61844",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21556",
      city: "Flanagan",
      zip_code: "61740",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21890",
      city: "Flat Rock",
      zip_code: "62427",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "22083",
      city: "Flora",
      zip_code: "62839",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "20981",
      city: "Flossmoor",
      zip_code: "60422",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21611",
      city: "Foosland",
      zip_code: "61845",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21488",
      city: "Forest City",
      zip_code: "61532",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "20904",
      city: "Forest Park",
      zip_code: "60130",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21557",
      city: "Forrest",
      zip_code: "61741",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21238",
      city: "Forreston",
      zip_code: "61030",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21954",
      city: "Forsyth",
      zip_code: "62535",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21840",
      city: "Fowler",
      zip_code: "62338",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "20831",
      city: "Fox Lake",
      zip_code: "60020",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "20832",
      city: "Fox River Grove",
      zip_code: "60021",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "20982",
      city: "Frankfort",
      zip_code: "60423",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "22007",
      city: "Franklin",
      zip_code: "62638",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21239",
      city: "Franklin Grove",
      zip_code: "61031",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "20905",
      city: "Franklin Park",
      zip_code: "60131",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "22008",
      city: "Frederick",
      zip_code: "62639",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21779",
      city: "Freeburg",
      zip_code: "62243",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "22084",
      city: "Freeman Spur",
      zip_code: "62841",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21240",
      city: "Freeport",
      zip_code: "61032",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21314",
      city: "Fulton",
      zip_code: "61252",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21780",
      city: "Fults",
      zip_code: "62244",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "22165",
      city: "Galatia",
      zip_code: "62935",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21241",
      city: "Galena",
      zip_code: "61036",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21402",
      city: "Galesburg",
      zip_code: "61401",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21242",
      city: "Galt",
      zip_code: "61037",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21427",
      city: "Galva",
      zip_code: "61434",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21243",
      city: "Garden Prairie",
      zip_code: "61038",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "20983",
      city: "Gardner",
      zip_code: "60424",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21654",
      city: "Gays",
      zip_code: "61928",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "22085",
      city: "Geff",
      zip_code: "62842",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21315",
      city: "Geneseo",
      zip_code: "61254",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "20907",
      city: "Geneva",
      zip_code: "60134",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "20908",
      city: "Genoa",
      zip_code: "60135",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21612",
      city: "Georgetown",
      zip_code: "61846",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21428",
      city: "Gerlaw",
      zip_code: "61435",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21244",
      city: "German Valley",
      zip_code: "61039",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21781",
      city: "Germantown",
      zip_code: "62245",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21187",
      city: "Gibson City",
      zip_code: "60936",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21613",
      city: "Gifford",
      zip_code: "61847",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "20909",
      city: "Gilberts",
      zip_code: "60136",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21699",
      city: "Gillespie",
      zip_code: "62033",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21188",
      city: "Gilman",
      zip_code: "60938",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21429",
      city: "Gilson",
      zip_code: "61436",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "22009",
      city: "Girard",
      zip_code: "62640",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21430",
      city: "Gladstone",
      zip_code: "61437",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21489",
      city: "Glasford",
      zip_code: "61533",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21700",
      city: "Glen Carbon",
      zip_code: "62034",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "20910",
      city: "Glen Ellyn",
      zip_code: "60137",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21955",
      city: "Glenarm",
      zip_code: "62536",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "20833",
      city: "Glencoe",
      zip_code: "60022",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "20911",
      city: "Glendale Heights",
      zip_code: "60139",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "20834",
      city: "Glenview",
      zip_code: "60025",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "20984",
      city: "Glenwood",
      zip_code: "60425",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21701",
      city: "Godfrey",
      zip_code: "62035",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "22166",
      city: "Golconda",
      zip_code: "62938",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21841",
      city: "Golden",
      zip_code: "62339",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21702",
      city: "Golden Eagle",
      zip_code: "62036",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "22086",
      city: "Golden Gate",
      zip_code: "62843",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "20836",
      city: "Golf",
      zip_code: "60029",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21431",
      city: "Good Hope",
      zip_code: "61438",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21558",
      city: "Goodfield",
      zip_code: "61742",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21189",
      city: "Goodwine",
      zip_code: "60939",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "22167",
      city: "Goreville",
      zip_code: "62939",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "22168",
      city: "Gorham",
      zip_code: "62940",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21703",
      city: "Grafton",
      zip_code: "62037",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "22169",
      city: "Grand Chain",
      zip_code: "62941",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21356",
      city: "Grand Ridge",
      zip_code: "61325",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "22170",
      city: "Grand Tower",
      zip_code: "62942",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21704",
      city: "Granite City",
      zip_code: "62040",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21190",
      city: "Grant Park",
      zip_code: "60940",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "22171",
      city: "Grantsburg",
      zip_code: "62943",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21357",
      city: "Granville",
      zip_code: "61326",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21559",
      city: "Graymont",
      zip_code: "61743",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "20837",
      city: "Grayslake",
      zip_code: "60030",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "22087",
      city: "Grayville",
      zip_code: "62844",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "20875",
      city: "Great Lakes",
      zip_code: "60088",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21490",
      city: "Green Valley",
      zip_code: "61534",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21705",
      city: "Greenfield",
      zip_code: "62044",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21891",
      city: "Greenup",
      zip_code: "62428",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "22010",
      city: "Greenview",
      zip_code: "62642",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21782",
      city: "Greenville",
      zip_code: "62246",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21560",
      city: "Gridley",
      zip_code: "61744",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21842",
      city: "Griggsville",
      zip_code: "62340",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21491",
      city: "Groveland",
      zip_code: "61535",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "20838",
      city: "Gurnee",
      zip_code: "60031",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21706",
      city: "Hamburg",
      zip_code: "62045",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21707",
      city: "Hamel",
      zip_code: "62046",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21843",
      city: "Hamilton",
      zip_code: "62341",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21655",
      city: "Hammond",
      zip_code: "61929",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "20912",
      city: "Hampshire",
      zip_code: "60140",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21316",
      city: "Hampton",
      zip_code: "61256",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21492",
      city: "Hanna City",
      zip_code: "61536",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21245",
      city: "Hanover",
      zip_code: "61041",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "20906",
      city: "Hanover Park",
      zip_code: "60133",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21708",
      city: "Hardin",
      zip_code: "62047",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21246",
      city: "Harmon",
      zip_code: "61042",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "22172",
      city: "Harrisburg",
      zip_code: "62946",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21956",
      city: "Harristown",
      zip_code: "62537",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21709",
      city: "Hartford",
      zip_code: "62048",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "22011",
      city: "Hartsburg",
      zip_code: "62643",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "20839",
      city: "Harvard",
      zip_code: "60033",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21957",
      city: "Harvel",
      zip_code: "62538",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "20985",
      city: "Harvey",
      zip_code: "60426",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21155",
      city: "Harwood Heights",
      zip_code: "60706",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "22012",
      city: "Havana",
      zip_code: "62644",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "20987",
      city: "Hazel Crest",
      zip_code: "60429",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "20840",
      city: "Hebron",
      zip_code: "60034",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21783",
      city: "Hecker",
      zip_code: "62248",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21432",
      city: "Henderson",
      zip_code: "61439",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21358",
      city: "Hennepin",
      zip_code: "61327",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21614",
      city: "Henning",
      zip_code: "61848",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21493",
      city: "Henry",
      zip_code: "61537",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "22173",
      city: "Herod",
      zip_code: "62947",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21892",
      city: "Herrick",
      zip_code: "62431",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "22174",
      city: "Herrin",
      zip_code: "62948",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21191",
      city: "Herscher",
      zip_code: "60941",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "22013",
      city: "Hettick",
      zip_code: "62649",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21561",
      city: "Heyworth",
      zip_code: "61745",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21013",
      city: "Hickory Hills",
      zip_code: "60457",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21893",
      city: "Hidalgo",
      zip_code: "62432",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21784",
      city: "Highland",
      zip_code: "62249",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "20841",
      city: "Highland Park",
      zip_code: "60035",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "20842",
      city: "Highwood",
      zip_code: "60040",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21710",
      city: "Hillsboro",
      zip_code: "62049",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21317",
      city: "Hillsdale",
      zip_code: "61257",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "20929",
      city: "Hillside",
      zip_code: "60162",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21711",
      city: "Hillview",
      zip_code: "62050",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21059",
      city: "Hinckley",
      zip_code: "60520",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21656",
      city: "Hindsboro",
      zip_code: "61930",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "20913",
      city: "Hines",
      zip_code: "60141",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21060",
      city: "Hinsdale",
      zip_code: "60521",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21785",
      city: "Hoffman",
      zip_code: "62250",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "20933",
      city: "Hoffman Estates",
      zip_code: "60169",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21247",
      city: "Holcomb",
      zip_code: "61043",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21615",
      city: "Homer",
      zip_code: "61849",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21042",
      city: "Homer Glen",
      zip_code: "60491",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21012",
      city: "Hometown",
      zip_code: "60456",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "20988",
      city: "Homewood",
      zip_code: "60430",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21192",
      city: "Hoopeston",
      zip_code: "60942",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21318",
      city: "Hooppole",
      zip_code: "61258",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21562",
      city: "Hopedale",
      zip_code: "61747",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "22052",
      city: "Hoyleton",
      zip_code: "62803",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21563",
      city: "Hudson",
      zip_code: "61748",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21844",
      city: "Hull",
      zip_code: "62343",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21657",
      city: "Humboldt",
      zip_code: "61931",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21658",
      city: "Hume",
      zip_code: "61932",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "20914",
      city: "Huntley",
      zip_code: "60142",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21845",
      city: "Huntsville",
      zip_code: "62344",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "22175",
      city: "Hurst",
      zip_code: "62949",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21894",
      city: "Hutsonville",
      zip_code: "62433",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21319",
      city: "Illinois City",
      zip_code: "61259",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21958",
      city: "Illiopolis",
      zip_code: "62539",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "22088",
      city: "Ina",
      zip_code: "62846",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21616",
      city: "Indianola",
      zip_code: "61850",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21433",
      city: "Industry",
      zip_code: "61440",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "20843",
      city: "Ingleside",
      zip_code: "60041",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21895",
      city: "Ingraham",
      zip_code: "62434",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21434",
      city: "Ipava",
      zip_code: "61441",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21193",
      city: "Iroquois",
      zip_code: "60945",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21712",
      city: "Irving",
      zip_code: "62051",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "22089",
      city: "Irvington",
      zip_code: "62848",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "20844",
      city: "Island Lake",
      zip_code: "60042",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "20915",
      city: "Itasca",
      zip_code: "60143",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "22090",
      city: "Iuka",
      zip_code: "62849",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21617",
      city: "Ivesdale",
      zip_code: "61851",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "22014",
      city: "Jacksonville",
      zip_code: "62650",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "22176",
      city: "Jacob",
      zip_code: "62950",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21713",
      city: "Jerseyville",
      zip_code: "62052",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21896",
      city: "Jewett",
      zip_code: "62436",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "22091",
      city: "Johnsonville",
      zip_code: "62850",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "22177",
      city: "Johnston City",
      zip_code: "62951",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "20989",
      city: "Joliet",
      zip_code: "60431",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "22178",
      city: "Jonesboro",
      zip_code: "62952",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "22179",
      city: "Joppa",
      zip_code: "62953",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21320",
      city: "Joy",
      zip_code: "61260",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "22180",
      city: "Junction",
      zip_code: "62954",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21014",
      city: "Justice",
      zip_code: "60458",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21714",
      city: "Kampsville",
      zip_code: "62053",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21715",
      city: "Kane",
      zip_code: "62054",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "20916",
      city: "Kaneville",
      zip_code: "60144",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21163",
      city: "Kankakee",
      zip_code: "60901",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21659",
      city: "Kansas",
      zip_code: "61933",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "22181",
      city: "Karnak",
      zip_code: "62956",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21359",
      city: "Kasbeer",
      zip_code: "61328",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "22092",
      city: "Keenes",
      zip_code: "62851",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "22093",
      city: "Keensburg",
      zip_code: "62852",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21435",
      city: "Keithsburg",
      zip_code: "61442",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "22094",
      city: "Kell",
      zip_code: "62853",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21194",
      city: "Kempton",
      zip_code: "60946",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "20845",
      city: "Kenilworth",
      zip_code: "60043",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21564",
      city: "Kenney",
      zip_code: "61749",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21248",
      city: "Kent",
      zip_code: "61044",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21436",
      city: "Kewanee",
      zip_code: "61443",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21786",
      city: "Keyesport",
      zip_code: "62253",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "22015",
      city: "Kilbourne",
      zip_code: "62655",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21959",
      city: "Kincaid",
      zip_code: "62540",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21846",
      city: "Kinderhook",
      zip_code: "62345",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "20917",
      city: "Kingston",
      zip_code: "60145",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21494",
      city: "Kingston Mines",
      zip_code: "61539",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "22095",
      city: "Kinmundy",
      zip_code: "62854",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "20994",
      city: "Kinsman",
      zip_code: "60437",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "20918",
      city: "Kirkland",
      zip_code: "60146",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21437",
      city: "Kirkwood",
      zip_code: "61447",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21438",
      city: "Knoxville",
      zip_code: "61448",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21439",
      city: "La Fayette",
      zip_code: "61449",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21062",
      city: "La Grange",
      zip_code: "60525",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21063",
      city: "La Grange Park",
      zip_code: "60526",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21440",
      city: "La Harpe",
      zip_code: "61450",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21361",
      city: "La Moille",
      zip_code: "61330",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21660",
      city: "La Place",
      zip_code: "61936",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21847",
      city: "La Prairie",
      zip_code: "62346",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21496",
      city: "La Rose",
      zip_code: "61541",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21340",
      city: "La Salle",
      zip_code: "61301",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21495",
      city: "Lacon",
      zip_code: "61540",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21360",
      city: "Ladd",
      zip_code: "61329",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "20846",
      city: "Lake Bluff",
      zip_code: "60044",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "20847",
      city: "Lake Forest",
      zip_code: "60045",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21960",
      city: "Lake Fork",
      zip_code: "62541",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "20926",
      city: "Lake In The Hills",
      zip_code: "60156",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "20848",
      city: "Lake Villa",
      zip_code: "60046",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "20849",
      city: "Lake Zurich",
      zip_code: "60047",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21897",
      city: "Lakewood",
      zip_code: "62438",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21249",
      city: "Lanark",
      zip_code: "61046",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21565",
      city: "Lane",
      zip_code: "61750",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "20995",
      city: "Lansing",
      zip_code: "60438",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21961",
      city: "Latham",
      zip_code: "62543",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21441",
      city: "Laura",
      zip_code: "61451",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21566",
      city: "Lawndale",
      zip_code: "61751",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21898",
      city: "Lawrenceville",
      zip_code: "62439",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21567",
      city: "Le Roy",
      zip_code: "61752",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21250",
      city: "Leaf River",
      zip_code: "61047",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21787",
      city: "Lebanon",
      zip_code: "62254",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21065",
      city: "Lee",
      zip_code: "60530",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21362",
      city: "Lee Center",
      zip_code: "61331",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21066",
      city: "Leland",
      zip_code: "60531",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "20996",
      city: "Lemont",
      zip_code: "60439",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21251",
      city: "Lena",
      zip_code: "61048",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21788",
      city: "Lenzburg",
      zip_code: "62255",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21363",
      city: "Leonore",
      zip_code: "61332",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21899",
      city: "Lerna",
      zip_code: "62440",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21497",
      city: "Lewistown",
      zip_code: "61542",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21568",
      city: "Lexington",
      zip_code: "61753",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21848",
      city: "Liberty",
      zip_code: "62347",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "20850",
      city: "Libertyville",
      zip_code: "60048",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21849",
      city: "Lima",
      zip_code: "62348",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "22016",
      city: "Lincoln",
      zip_code: "62656",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "20861",
      city: "Lincolnshire",
      zip_code: "60069",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21157",
      city: "Lincolnwood",
      zip_code: "60712",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21252",
      city: "Lindenwood",
      zip_code: "61049",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21067",
      city: "Lisle",
      zip_code: "60532",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21716",
      city: "Litchfield",
      zip_code: "62056",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21443",
      city: "Little York",
      zip_code: "61453",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21442",
      city: "Littleton",
      zip_code: "61452",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21498",
      city: "Liverpool",
      zip_code: "61543",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21717",
      city: "Livingston",
      zip_code: "62058",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "22017",
      city: "Loami",
      zip_code: "62661",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "20998",
      city: "Lockport",
      zip_code: "60441",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21195",
      city: "Loda",
      zip_code: "60948",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "22096",
      city: "Logan",
      zip_code: "62856",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21444",
      city: "Lomax",
      zip_code: "61454",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "20919",
      city: "Lombard",
      zip_code: "60148",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21499",
      city: "London Mills",
      zip_code: "61544",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21364",
      city: "Long Point",
      zip_code: "61333",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21618",
      city: "Longview",
      zip_code: "61852",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21850",
      city: "Loraine",
      zip_code: "62349",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21365",
      city: "Lostant",
      zip_code: "61334",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "22097",
      city: "Louisville",
      zip_code: "62858",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21718",
      city: "Lovejoy",
      zip_code: "62059",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21292",
      city: "Loves Park",
      zip_code: "61111",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21661",
      city: "Lovington",
      zip_code: "61937",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21500",
      city: "Lowpoint",
      zip_code: "61545",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21196",
      city: "Ludlow",
      zip_code: "60949",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21321",
      city: "Lyndon",
      zip_code: "61261",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21322",
      city: "Lynn Center",
      zip_code: "61262",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21068",
      city: "Lyons",
      zip_code: "60534",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "22099",
      city: "Macedonia",
      zip_code: "62860",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21295",
      city: "Machesney Park",
      zip_code: "61115",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21570",
      city: "Mackinaw",
      zip_code: "61755",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21445",
      city: "Macomb",
      zip_code: "61455",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21962",
      city: "Macon",
      zip_code: "62544",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21719",
      city: "Madison",
      zip_code: "62060",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21789",
      city: "Maeystown",
      zip_code: "62256",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21367",
      city: "Magnolia",
      zip_code: "61336",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21619",
      city: "Mahomet",
      zip_code: "61853",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "22183",
      city: "Makanda",
      zip_code: "62958",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21368",
      city: "Malden",
      zip_code: "61337",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "20920",
      city: "Malta",
      zip_code: "60150",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "22018",
      city: "Manchester",
      zip_code: "62663",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "20999",
      city: "Manhattan",
      zip_code: "60442",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21501",
      city: "Manito",
      zip_code: "61546",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21369",
      city: "Manlius",
      zip_code: "61338",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21620",
      city: "Mansfield",
      zip_code: "61854",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21197",
      city: "Manteno",
      zip_code: "60950",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "20921",
      city: "Maple Park",
      zip_code: "60151",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21502",
      city: "Mapleton",
      zip_code: "61547",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21446",
      city: "Maquon",
      zip_code: "61458",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "20922",
      city: "Marengo",
      zip_code: "60152",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21447",
      city: "Marietta",
      zip_code: "61459",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21720",
      city: "Marine",
      zip_code: "62061",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "22184",
      city: "Marion",
      zip_code: "62959",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21790",
      city: "Marissa",
      zip_code: "62257",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21370",
      city: "Mark",
      zip_code: "61340",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "20986",
      city: "Markham",
      zip_code: "60428",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21571",
      city: "Maroa",
      zip_code: "61756",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21371",
      city: "Marseilles",
      zip_code: "61341",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21900",
      city: "Marshall",
      zip_code: "62441",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21901",
      city: "Martinsville",
      zip_code: "62442",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21198",
      city: "Martinton",
      zip_code: "60951",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21721",
      city: "Maryville",
      zip_code: "62062",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21791",
      city: "Mascoutah",
      zip_code: "62258",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21902",
      city: "Mason",
      zip_code: "62443",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "22019",
      city: "Mason City",
      zip_code: "62664",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21323",
      city: "Matherville",
      zip_code: "61263",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21000",
      city: "Matteson",
      zip_code: "60443",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21662",
      city: "Mattoon",
      zip_code: "61938",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "22100",
      city: "Maunie",
      zip_code: "62861",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "20923",
      city: "Maywood",
      zip_code: "60153",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21001",
      city: "Mazon",
      zip_code: "60444",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "22182",
      city: "McClure",
      zip_code: "62957",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21253",
      city: "McConnell",
      zip_code: "61050",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "20851",
      city: "Mchenry",
      zip_code: "60050",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21569",
      city: "McLean",
      zip_code: "61754",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "22098",
      city: "McLeansboro",
      zip_code: "62859",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21366",
      city: "McNabb",
      zip_code: "61335",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21963",
      city: "Mechanicsburg",
      zip_code: "62545",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21448",
      city: "Media",
      zip_code: "61460",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "20927",
      city: "Medinah",
      zip_code: "60157",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21722",
      city: "Medora",
      zip_code: "62063",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "20928",
      city: "Melrose Park",
      zip_code: "60160",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21199",
      city: "Melvin",
      zip_code: "60952",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21851",
      city: "Mendon",
      zip_code: "62351",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21372",
      city: "Mendota",
      zip_code: "61342",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "22020",
      city: "Meredosia",
      zip_code: "62665",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21503",
      city: "Metamora",
      zip_code: "61548",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21663",
      city: "Metcalf",
      zip_code: "61940",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "22185",
      city: "Metropolis",
      zip_code: "62960",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "22021",
      city: "Middletown",
      zip_code: "62666",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21002",
      city: "Midlothian",
      zip_code: "60445",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21324",
      city: "Milan",
      zip_code: "61264",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21200",
      city: "Milford",
      zip_code: "60953",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "22101",
      city: "Mill Shoals",
      zip_code: "62862",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21069",
      city: "Millbrook",
      zip_code: "60536",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "22186",
      city: "Millcreek",
      zip_code: "62961",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21254",
      city: "Milledgeville",
      zip_code: "61051",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "22187",
      city: "Miller City",
      zip_code: "62962",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21070",
      city: "Millington",
      zip_code: "60537",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21792",
      city: "Millstadt",
      zip_code: "62260",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21621",
      city: "Milmine",
      zip_code: "61855",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21852",
      city: "Milton",
      zip_code: "62352",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21373",
      city: "Mineral",
      zip_code: "61344",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21572",
      city: "Minier",
      zip_code: "61759",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21573",
      city: "Minonk",
      zip_code: "61760",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21004",
      city: "Minooka",
      zip_code: "60447",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21903",
      city: "Mode",
      zip_code: "62444",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "22022",
      city: "Modesto",
      zip_code: "62667",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21793",
      city: "Modoc",
      zip_code: "62261",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21005",
      city: "Mokena",
      zip_code: "60448",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21325",
      city: "Moline",
      zip_code: "61265",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21201",
      city: "Momence",
      zip_code: "60954",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21006",
      city: "Monee",
      zip_code: "60449",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21449",
      city: "Monmouth",
      zip_code: "61462",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21255",
      city: "Monroe Center",
      zip_code: "61052",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21071",
      city: "Montgomery",
      zip_code: "60538",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21622",
      city: "Monticello",
      zip_code: "61856",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21904",
      city: "Montrose",
      zip_code: "62445",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21072",
      city: "Mooseheart",
      zip_code: "60539",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21723",
      city: "Moro",
      zip_code: "62067",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21007",
      city: "Morris",
      zip_code: "60450",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21326",
      city: "Morrison",
      zip_code: "61270",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21964",
      city: "Morrisonville",
      zip_code: "62546",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21504",
      city: "Morton",
      zip_code: "61550",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "20853",
      city: "Morton Grove",
      zip_code: "60053",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21505",
      city: "Mossville",
      zip_code: "61552",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "22188",
      city: "Mound City",
      zip_code: "62963",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "22189",
      city: "Mounds",
      zip_code: "62964",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21965",
      city: "Mount Auburn",
      zip_code: "62547",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "22102",
      city: "Mount Carmel",
      zip_code: "62863",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21256",
      city: "Mount Carroll",
      zip_code: "61053",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21905",
      city: "Mount Erie",
      zip_code: "62446",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21257",
      city: "Mount Morris",
      zip_code: "61054",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21724",
      city: "Mount Olive",
      zip_code: "62069",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "20854",
      city: "Mount Prospect",
      zip_code: "60056",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21966",
      city: "Mount Pulaski",
      zip_code: "62548",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21853",
      city: "Mount Sterling",
      zip_code: "62353",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "22103",
      city: "Mount Vernon",
      zip_code: "62864",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21968",
      city: "Moweaqua",
      zip_code: "62550",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21967",
      city: "Mt Zion",
      zip_code: "62549",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "22190",
      city: "Muddy",
      zip_code: "62965",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21794",
      city: "Mulberry Grove",
      zip_code: "62262",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "22104",
      city: "Mulkeytown",
      zip_code: "62865",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21623",
      city: "Muncie",
      zip_code: "61857",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "20855",
      city: "Mundelein",
      zip_code: "60060",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21664",
      city: "Murdock",
      zip_code: "61941",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "22191",
      city: "Murphysboro",
      zip_code: "62966",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "22023",
      city: "Murrayville",
      zip_code: "62668",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21258",
      city: "Nachusa",
      zip_code: "61057",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21073",
      city: "Naperville",
      zip_code: "60540",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21795",
      city: "Nashville",
      zip_code: "62263",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21854",
      city: "Nauvoo",
      zip_code: "62354",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21855",
      city: "Nebo",
      zip_code: "62355",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21906",
      city: "Neoga",
      zip_code: "62447",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21374",
      city: "Neponset",
      zip_code: "61345",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21796",
      city: "New Athens",
      zip_code: "62264",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21797",
      city: "New Baden",
      zip_code: "62265",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21375",
      city: "New Bedford",
      zip_code: "61346",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "22024",
      city: "New Berlin",
      zip_code: "62670",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21327",
      city: "New Boston",
      zip_code: "61272",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "22192",
      city: "New Burnside",
      zip_code: "62967",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21856",
      city: "New Canton",
      zip_code: "62356",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21725",
      city: "New Douglas",
      zip_code: "62074",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "22105",
      city: "New Haven",
      zip_code: "62867",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "22025",
      city: "New Holland",
      zip_code: "62671",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21008",
      city: "New Lenox",
      zip_code: "60451",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21798",
      city: "New Memphis",
      zip_code: "62266",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21857",
      city: "New Salem",
      zip_code: "62357",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21450",
      city: "New Windsor",
      zip_code: "61465",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21074",
      city: "Newark",
      zip_code: "60541",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21665",
      city: "Newman",
      zip_code: "61942",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21907",
      city: "Newton",
      zip_code: "62448",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21969",
      city: "Niantic",
      zip_code: "62551",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21158",
      city: "Niles",
      zip_code: "60714",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "22026",
      city: "Nilwood",
      zip_code: "62672",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21858",
      city: "Niota",
      zip_code: "62358",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "22106",
      city: "Noble",
      zip_code: "62868",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21726",
      city: "Nokomis",
      zip_code: "62075",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21259",
      city: "Nora",
      zip_code: "61059",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21574",
      city: "Normal",
      zip_code: "61761",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21506",
      city: "Norris",
      zip_code: "61553",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "22107",
      city: "Norris City",
      zip_code: "62869",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21075",
      city: "North Aurora",
      zip_code: "60542",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "20858",
      city: "North Chicago",
      zip_code: "60064",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21451",
      city: "North Henderson",
      zip_code: "61466",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "20857",
      city: "Northbrook",
      zip_code: "60062",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21800",
      city: "O'Fallon",
      zip_code: "62269",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21061",
      city: "Oak Brook",
      zip_code: "60523",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21009",
      city: "Oak Forest",
      zip_code: "60452",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21010",
      city: "Oak Lawn",
      zip_code: "60453",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "20960",
      city: "Oak Park",
      zip_code: "60301",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21799",
      city: "Oakdale",
      zip_code: "62268",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "22027",
      city: "Oakford",
      zip_code: "62673",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21666",
      city: "Oakland",
      zip_code: "61943",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21624",
      city: "Oakwood",
      zip_code: "61858",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21908",
      city: "Oblong",
      zip_code: "62449",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21970",
      city: "Oconee",
      zip_code: "62553",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21016",
      city: "Odell",
      zip_code: "60460",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "22108",
      city: "Odin",
      zip_code: "62870",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21625",
      city: "Ogden",
      zip_code: "61859",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21376",
      city: "Oglesby",
      zip_code: "61348",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21377",
      city: "Ohio",
      zip_code: "61349",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21727",
      city: "Ohlman",
      zip_code: "62076",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21801",
      city: "Okawville",
      zip_code: "62271",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "22193",
      city: "Olive Branch",
      zip_code: "62969",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "22194",
      city: "Olmsted",
      zip_code: "62970",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21909",
      city: "Olney",
      zip_code: "62450",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21017",
      city: "Olympia Fields",
      zip_code: "60461",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "22109",
      city: "Omaha",
      zip_code: "62871",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21202",
      city: "Onarga",
      zip_code: "60955",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21452",
      city: "Oneida",
      zip_code: "61467",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "22110",
      city: "Opdyke",
      zip_code: "62872",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21453",
      city: "Ophiem",
      zip_code: "61468",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21454",
      city: "Oquawka",
      zip_code: "61469",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21260",
      city: "Orangeville",
      zip_code: "61060",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21971",
      city: "Oreana",
      zip_code: "62554",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21261",
      city: "Oregon",
      zip_code: "61061",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "22111",
      city: "Orient",
      zip_code: "62874",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21328",
      city: "Orion",
      zip_code: "61273",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21018",
      city: "Orland Park",
      zip_code: "60462",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21329",
      city: "Osco",
      zip_code: "61274",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21076",
      city: "Oswego",
      zip_code: "60543",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21378",
      city: "Ottawa",
      zip_code: "61350",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21972",
      city: "Owaneco",
      zip_code: "62555",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "22195",
      city: "Ozark",
      zip_code: "62972",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "20859",
      city: "Palatine",
      zip_code: "60067",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21910",
      city: "Palestine",
      zip_code: "62451",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21973",
      city: "Palmer",
      zip_code: "62556",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "22028",
      city: "Palmyra",
      zip_code: "62674",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21859",
      city: "Paloma",
      zip_code: "62359",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21019",
      city: "Palos Heights",
      zip_code: "60463",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21021",
      city: "Palos Hills",
      zip_code: "60465",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21020",
      city: "Palos Park",
      zip_code: "60464",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21974",
      city: "Pana",
      zip_code: "62557",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21728",
      city: "Panama",
      zip_code: "62077",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21203",
      city: "Papineau",
      zip_code: "60956",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21667",
      city: "Paris",
      zip_code: "61944",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21022",
      city: "Park Forest",
      zip_code: "60466",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "20860",
      city: "Park Ridge",
      zip_code: "60068",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21911",
      city: "Parkersburg",
      zip_code: "62452",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "22112",
      city: "Patoka",
      zip_code: "62875",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21729",
      city: "Patterson",
      zip_code: "62078",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21379",
      city: "Paw Paw",
      zip_code: "61353",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21975",
      city: "Pawnee",
      zip_code: "62558",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21204",
      city: "Paxton",
      zip_code: "60957",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21860",
      city: "Payson",
      zip_code: "62360",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21861",
      city: "Pearl",
      zip_code: "62361",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21262",
      city: "Pearl City",
      zip_code: "61062",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21263",
      city: "Pecatonica",
      zip_code: "61063",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21507",
      city: "Pekin",
      zip_code: "61554",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21205",
      city: "Pembroke Township",
      zip_code: "60958",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21626",
      city: "Penfield",
      zip_code: "61862",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21521",
      city: "Peoria",
      zip_code: "61602",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21531",
      city: "Peoria Heights",
      zip_code: "61616",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21024",
      city: "Peotone",
      zip_code: "60468",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21802",
      city: "Percy",
      zip_code: "62272",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21862",
      city: "Perry",
      zip_code: "62362",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21380",
      city: "Peru",
      zip_code: "61354",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21627",
      city: "Pesotum",
      zip_code: "61863",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "22029",
      city: "Petersburg",
      zip_code: "62675",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21628",
      city: "Philo",
      zip_code: "61864",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21730",
      city: "Piasa",
      zip_code: "62079",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21803",
      city: "Pierron",
      zip_code: "62273",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21804",
      city: "Pinckneyville",
      zip_code: "62274",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21206",
      city: "Piper City",
      zip_code: "60959",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "22196",
      city: "Pittsburg",
      zip_code: "62974",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21863",
      city: "Pittsfield",
      zip_code: "62363",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21077",
      city: "Plainfield",
      zip_code: "60544",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21864",
      city: "Plainville",
      zip_code: "62365",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21078",
      city: "Plano",
      zip_code: "60545",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21865",
      city: "Pleasant Hill",
      zip_code: "62366",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "22030",
      city: "Pleasant Plains",
      zip_code: "62677",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21866",
      city: "Plymouth",
      zip_code: "62367",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21805",
      city: "Pocahontas",
      zip_code: "62275",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21264",
      city: "Polo",
      zip_code: "61064",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "22197",
      city: "Pomona",
      zip_code: "62975",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21575",
      city: "Pontiac",
      zip_code: "61764",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21265",
      city: "Poplar Grove",
      zip_code: "61065",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21330",
      city: "Port Byron",
      zip_code: "61275",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21025",
      city: "Posen",
      zip_code: "60469",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21629",
      city: "Potomac",
      zip_code: "61865",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21455",
      city: "Prairie City",
      zip_code: "61470",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21806",
      city: "Prairie Du Rocher",
      zip_code: "62277",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21331",
      city: "Preemption",
      zip_code: "61276",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21381",
      city: "Princeton",
      zip_code: "61356",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21508",
      city: "Princeville",
      zip_code: "61559",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21332",
      city: "Prophetstown",
      zip_code: "61277",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "20862",
      city: "Prospect Heights",
      zip_code: "60070",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "22198",
      city: "Pulaski",
      zip_code: "62976",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21509",
      city: "Putnam",
      zip_code: "61560",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21823",
      city: "Quincy",
      zip_code: "62301",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "22113",
      city: "Radom",
      zip_code: "62876",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "22199",
      city: "Raleigh",
      zip_code: "62977",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21731",
      city: "Ramsey",
      zip_code: "62080",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21207",
      city: "Rankin",
      zip_code: "60960",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21026",
      city: "Ransom",
      zip_code: "60470",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21630",
      city: "Rantoul",
      zip_code: "61866",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21333",
      city: "Rapids City",
      zip_code: "61278",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21456",
      city: "Raritan",
      zip_code: "61471",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21976",
      city: "Raymond",
      zip_code: "62560",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21807",
      city: "Red Bud",
      zip_code: "62278",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21208",
      city: "Reddick",
      zip_code: "60961",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21668",
      city: "Redmon",
      zip_code: "61949",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21808",
      city: "Renault",
      zip_code: "62279",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21334",
      city: "Reynolds",
      zip_code: "61279",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "20863",
      city: "Richmond",
      zip_code: "60071",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21027",
      city: "Richton Park",
      zip_code: "60471",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "22114",
      city: "Richview",
      zip_code: "62877",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21631",
      city: "Ridge Farm",
      zip_code: "61870",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "22200",
      city: "Ridgway",
      zip_code: "62979",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21266",
      city: "Ridott",
      zip_code: "61067",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "22115",
      city: "Rinard",
      zip_code: "62878",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "20864",
      city: "Ringwood",
      zip_code: "60072",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21457",
      city: "Rio",
      zip_code: "61472",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "20963",
      city: "River Forest",
      zip_code: "60305",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "20934",
      city: "River Grove",
      zip_code: "60171",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21162",
      city: "Riverdale",
      zip_code: "60827",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21079",
      city: "Riverside",
      zip_code: "60546",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21977",
      city: "Riverton",
      zip_code: "62561",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21510",
      city: "Roanoke",
      zip_code: "61561",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21028",
      city: "Robbins",
      zip_code: "60472",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21209",
      city: "Roberts",
      zip_code: "60962",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21912",
      city: "Robinson",
      zip_code: "62454",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21267",
      city: "Rochelle",
      zip_code: "61068",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21978",
      city: "Rochester",
      zip_code: "62563",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21268",
      city: "Rock City",
      zip_code: "61070",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21269",
      city: "Rock Falls",
      zip_code: "61071",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21296",
      city: "Rock Island",
      zip_code: "61201",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21732",
      city: "Rockbridge",
      zip_code: "62081",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21285",
      city: "Rockford",
      zip_code: "61101",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21867",
      city: "Rockport",
      zip_code: "62370",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21270",
      city: "Rockton",
      zip_code: "61072",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21809",
      city: "Rockwood",
      zip_code: "62280",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "20823",
      city: "Rolling Meadows",
      zip_code: "60008",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21511",
      city: "Rome",
      zip_code: "61562",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21003",
      city: "Romeoville",
      zip_code: "60446",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21733",
      city: "Roodhouse",
      zip_code: "62082",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21734",
      city: "Rosamond",
      zip_code: "62083",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21271",
      city: "Roscoe",
      zip_code: "61073",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "20935",
      city: "Roselle",
      zip_code: "60172",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21458",
      city: "Roseville",
      zip_code: "61473",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "22201",
      city: "Rosiclare",
      zip_code: "62982",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21210",
      city: "Rossville",
      zip_code: "60963",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "20865",
      city: "Round Lake",
      zip_code: "60073",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21735",
      city: "Roxana",
      zip_code: "62084",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21632",
      city: "Royal",
      zip_code: "61871",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "22202",
      city: "Royalton",
      zip_code: "62983",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "22031",
      city: "Rushville",
      zip_code: "62681",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "20867",
      city: "Russell",
      zip_code: "60075",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21382",
      city: "Rutland",
      zip_code: "61358",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21633",
      city: "Sadorus",
      zip_code: "61872",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "22116",
      city: "Sailor Springs",
      zip_code: "62879",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21211",
      city: "Saint Anne",
      zip_code: "60964",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21459",
      city: "Saint Augustine",
      zip_code: "61474",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "20937",
      city: "Saint Charles",
      zip_code: "60174",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21512",
      city: "Saint David",
      zip_code: "61563",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21913",
      city: "Saint Elmo",
      zip_code: "62458",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21915",
      city: "Saint Francisville",
      zip_code: "62460",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21810",
      city: "Saint Jacob",
      zip_code: "62281",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21634",
      city: "Saint Joseph",
      zip_code: "61873",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21811",
      city: "Saint Libory",
      zip_code: "62282",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "22117",
      city: "Saint Peter",
      zip_code: "62880",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21914",
      city: "Sainte Marie",
      zip_code: "62459",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "22118",
      city: "Salem",
      zip_code: "62881",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "22032",
      city: "San Jose",
      zip_code: "62682",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "22119",
      city: "Sandoval",
      zip_code: "62882",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21080",
      city: "Sandwich",
      zip_code: "60548",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21576",
      city: "Saunemin",
      zip_code: "61769",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21272",
      city: "Savanna",
      zip_code: "61074",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21635",
      city: "Savoy",
      zip_code: "61874",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21736",
      city: "Sawyerville",
      zip_code: "62085",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21577",
      city: "Saybrook",
      zip_code: "61770",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21273",
      city: "Scales Mound",
      zip_code: "61075",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "20936",
      city: "Schaumburg",
      zip_code: "60173",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "22120",
      city: "Scheller",
      zip_code: "62883",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "20939",
      city: "Schiller Park",
      zip_code: "60176",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21460",
      city: "Sciota",
      zip_code: "61475",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21765",
      city: "Scott Air Force Base",
      zip_code: "62225",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21461",
      city: "Seaton",
      zip_code: "61476",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21383",
      city: "Seatonville",
      zip_code: "61359",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21578",
      city: "Secor",
      zip_code: "61771",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21384",
      city: "Seneca",
      zip_code: "61360",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21081",
      city: "Serena",
      zip_code: "60549",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "22121",
      city: "Sesser",
      zip_code: "62884",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21274",
      city: "Seward",
      zip_code: "61077",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21636",
      city: "Seymour",
      zip_code: "61875",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21082",
      city: "Shabbona",
      zip_code: "60550",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21275",
      city: "Shannon",
      zip_code: "61078",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "22203",
      city: "Shawneetown",
      zip_code: "62984",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21385",
      city: "Sheffield",
      zip_code: "61361",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21979",
      city: "Shelbyville",
      zip_code: "62565",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21212",
      city: "Sheldon",
      zip_code: "60966",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21083",
      city: "Sheridan",
      zip_code: "60551",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "22033",
      city: "Sherman",
      zip_code: "62684",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21335",
      city: "Sherrard",
      zip_code: "61281",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "22034",
      city: "Shipman",
      zip_code: "62685",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21276",
      city: "Shirland",
      zip_code: "61079",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21579",
      city: "Shirley",
      zip_code: "61772",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "22122",
      city: "Shobonier",
      zip_code: "62885",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "20967",
      city: "Shorewood",
      zip_code: "60404",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21916",
      city: "Shumway",
      zip_code: "62461",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21580",
      city: "Sibley",
      zip_code: "61773",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21637",
      city: "Sidell",
      zip_code: "61876",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21638",
      city: "Sidney",
      zip_code: "61877",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21917",
      city: "Sigel",
      zip_code: "62462",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21336",
      city: "Silvis",
      zip_code: "61282",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "22204",
      city: "Simpson",
      zip_code: "62985",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "22123",
      city: "Sims",
      zip_code: "62886",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "20868",
      city: "Skokie",
      zip_code: "60076",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21812",
      city: "Smithboro",
      zip_code: "62284",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21462",
      city: "Smithfield",
      zip_code: "61477",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21463",
      city: "Smithshire",
      zip_code: "61478",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21813",
      city: "Smithton",
      zip_code: "62285",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21084",
      city: "Somonauk",
      zip_code: "60552",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21737",
      city: "Sorento",
      zip_code: "62086",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21277",
      city: "South Beloit",
      zip_code: "61080",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "20940",
      city: "South Elgin",
      zip_code: "60177",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21029",
      city: "South Holland",
      zip_code: "60473",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21513",
      city: "South Pekin",
      zip_code: "61564",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21738",
      city: "South Roxana",
      zip_code: "62087",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21030",
      city: "South Wilmington",
      zip_code: "60474",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21514",
      city: "Sparland",
      zip_code: "61565",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21814",
      city: "Sparta",
      zip_code: "62286",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21464",
      city: "Speer",
      zip_code: "61479",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "20870",
      city: "Spring Grove",
      zip_code: "60081",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21386",
      city: "Spring Valley",
      zip_code: "61362",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "22124",
      city: "Springerton",
      zip_code: "62887",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "22043",
      city: "Springfield",
      zip_code: "62701",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21387",
      city: "Standard",
      zip_code: "61363",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21581",
      city: "Stanford",
      zip_code: "61774",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21739",
      city: "Staunton",
      zip_code: "62088",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21815",
      city: "Steeleville",
      zip_code: "62288",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21031",
      city: "Steger",
      zip_code: "60475",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21278",
      city: "Sterling",
      zip_code: "61081",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21085",
      city: "Steward",
      zip_code: "60553",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21918",
      city: "Stewardson",
      zip_code: "62463",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21279",
      city: "Stillman Valley",
      zip_code: "61084",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21213",
      city: "Stockland",
      zip_code: "60967",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21280",
      city: "Stockton",
      zip_code: "61085",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "20932",
      city: "Stone Park",
      zip_code: "60165",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "22205",
      city: "Stonefort",
      zip_code: "62987",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21980",
      city: "Stonington",
      zip_code: "62567",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21919",
      city: "Stoy",
      zip_code: "62464",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21920",
      city: "Strasburg",
      zip_code: "62465",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21582",
      city: "Strawn",
      zip_code: "61775",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "20889",
      city: "Streamwood",
      zip_code: "60107",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21388",
      city: "Streator",
      zip_code: "61364",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21465",
      city: "Stronghurst",
      zip_code: "61480",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21389",
      city: "Sublette",
      zip_code: "61367",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21086",
      city: "Sugar Grove",
      zip_code: "60554",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21669",
      city: "Sullivan",
      zip_code: "61951",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21816",
      city: "Summerfield",
      zip_code: "62289",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21043",
      city: "Summit Argo",
      zip_code: "60501",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21921",
      city: "Sumner",
      zip_code: "62466",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21868",
      city: "Sutter",
      zip_code: "62373",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "20941",
      city: "Sycamore",
      zip_code: "60178",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21466",
      city: "Table Grove",
      zip_code: "61482",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "22035",
      city: "Tallula",
      zip_code: "62688",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "22125",
      city: "Tamaroa",
      zip_code: "62888",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "22206",
      city: "Tamms",
      zip_code: "62988",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21337",
      city: "Tampico",
      zip_code: "61283",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21338",
      city: "Taylor Ridge",
      zip_code: "61284",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21740",
      city: "Taylor Springs",
      zip_code: "62089",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21981",
      city: "Taylorville",
      zip_code: "62568",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21869",
      city: "Tennessee",
      zip_code: "62374",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21922",
      city: "Teutopolis",
      zip_code: "62467",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "22126",
      city: "Texico",
      zip_code: "62889",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21214",
      city: "Thawville",
      zip_code: "60968",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "22036",
      city: "Thayer",
      zip_code: "62689",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "22207",
      city: "Thebes",
      zip_code: "62990",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21639",
      city: "Thomasboro",
      zip_code: "61878",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "22127",
      city: "Thompsonville",
      zip_code: "62890",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21339",
      city: "Thomson",
      zip_code: "61285",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21032",
      city: "Thornton",
      zip_code: "60476",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21817",
      city: "Tilden",
      zip_code: "62292",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21603",
      city: "Tilton",
      zip_code: "61833",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21870",
      city: "Timewell",
      zip_code: "62375",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21033",
      city: "Tinley Park",
      zip_code: "60477",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21390",
      city: "Tiskilwa",
      zip_code: "61368",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21923",
      city: "Toledo",
      zip_code: "62468",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21640",
      city: "Tolono",
      zip_code: "61880",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21391",
      city: "Toluca",
      zip_code: "61369",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21392",
      city: "Tonica",
      zip_code: "61370",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21515",
      city: "Topeka",
      zip_code: "61567",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21467",
      city: "Toulon",
      zip_code: "61483",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21982",
      city: "Tovey",
      zip_code: "62570",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21583",
      city: "Towanda",
      zip_code: "61776",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21983",
      city: "Tower Hill",
      zip_code: "62571",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21516",
      city: "Tremont",
      zip_code: "61568",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21818",
      city: "Trenton",
      zip_code: "62293",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21924",
      city: "Trilla",
      zip_code: "62469",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21393",
      city: "Triumph",
      zip_code: "61371",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21517",
      city: "Trivoli",
      zip_code: "61569",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21819",
      city: "Troy",
      zip_code: "62294",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21394",
      city: "Troy Grove",
      zip_code: "61372",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21670",
      city: "Tuscola",
      zip_code: "61953",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "22208",
      city: "Ullin",
      zip_code: "62992",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "20942",
      city: "Union",
      zip_code: "60180",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21215",
      city: "Union Hill",
      zip_code: "60969",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21039",
      city: "University Park",
      zip_code: "60484",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21586",
      city: "Urbana",
      zip_code: "61801",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21871",
      city: "Ursa",
      zip_code: "62376",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21395",
      city: "Utica",
      zip_code: "61373",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "22128",
      city: "Valier",
      zip_code: "62891",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21820",
      city: "Valmeyer",
      zip_code: "62295",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21396",
      city: "Van Orin",
      zip_code: "61374",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21925",
      city: "Vandalia",
      zip_code: "62471",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21397",
      city: "Varna",
      zip_code: "61375",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21741",
      city: "Venice",
      zip_code: "62090",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "22209",
      city: "Vergennes",
      zip_code: "62994",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21671",
      city: "Vermilion",
      zip_code: "61955",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21468",
      city: "Vermont",
      zip_code: "61484",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "22129",
      city: "Vernon",
      zip_code: "62892",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "20856",
      city: "Vernon Hills",
      zip_code: "60061",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21035",
      city: "Verona",
      zip_code: "60479",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21872",
      city: "Versailles",
      zip_code: "62378",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21469",
      city: "Victoria",
      zip_code: "61485",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "22210",
      city: "Vienna",
      zip_code: "62995",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21672",
      city: "Villa Grove",
      zip_code: "61956",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "20943",
      city: "Villa Park",
      zip_code: "60181",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "22211",
      city: "Villa Ridge",
      zip_code: "62996",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21470",
      city: "Viola",
      zip_code: "61486",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "22037",
      city: "Virden",
      zip_code: "62690",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "22038",
      city: "Virginia",
      zip_code: "62691",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "20871",
      city: "Wadsworth",
      zip_code: "60083",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21984",
      city: "Waggoner",
      zip_code: "62572",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21398",
      city: "Walnut",
      zip_code: "61376",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "22130",
      city: "Walnut Hill",
      zip_code: "62893",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21821",
      city: "Walsh",
      zip_code: "62297",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21742",
      city: "Walshville",
      zip_code: "62091",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "22131",
      city: "Waltonville",
      zip_code: "62894",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21584",
      city: "Wapella",
      zip_code: "61777",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21281",
      city: "Warren",
      zip_code: "61087",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21985",
      city: "Warrensburg",
      zip_code: "62573",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21087",
      city: "Warrenville",
      zip_code: "60555",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21873",
      city: "Warsaw",
      zip_code: "62379",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "20944",
      city: "Wasco",
      zip_code: "60183",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21518",
      city: "Washburn",
      zip_code: "61570",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21519",
      city: "Washington",
      zip_code: "61571",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21471",
      city: "Wataga",
      zip_code: "61488",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21822",
      city: "Waterloo",
      zip_code: "62298",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21088",
      city: "Waterman",
      zip_code: "60556",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21216",
      city: "Watseka",
      zip_code: "60970",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21926",
      city: "Watson",
      zip_code: "62473",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "20872",
      city: "Wauconda",
      zip_code: "60084",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "20873",
      city: "Waukegan",
      zip_code: "60085",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "22039",
      city: "Waverly",
      zip_code: "62692",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "20945",
      city: "Wayne",
      zip_code: "60184",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "22132",
      city: "Wayne City",
      zip_code: "62895",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21585",
      city: "Waynesville",
      zip_code: "61778",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21089",
      city: "Wedron",
      zip_code: "60557",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21641",
      city: "Weldon",
      zip_code: "61882",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21217",
      city: "Wellington",
      zip_code: "60973",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21399",
      city: "Wenona",
      zip_code: "61377",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21400",
      city: "West Brooklyn",
      zip_code: "61378",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "20946",
      city: "West Chicago",
      zip_code: "60185",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "22133",
      city: "West Frankfort",
      zip_code: "62896",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21928",
      city: "West Liberty",
      zip_code: "62475",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21874",
      city: "West Point",
      zip_code: "62380",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21929",
      city: "West Salem",
      zip_code: "62476",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21930",
      city: "West Union",
      zip_code: "62477",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21931",
      city: "West York",
      zip_code: "62478",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "20924",
      city: "Westchester",
      zip_code: "60154",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21090",
      city: "Western Springs",
      zip_code: "60558",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21927",
      city: "Westfield",
      zip_code: "62474",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21091",
      city: "Westmont",
      zip_code: "60559",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21642",
      city: "Westville",
      zip_code: "61883",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "20947",
      city: "Wheaton",
      zip_code: "60187",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21932",
      city: "Wheeler",
      zip_code: "62479",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "20877",
      city: "Wheeling",
      zip_code: "60090",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21743",
      city: "White Hall",
      zip_code: "62092",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21643",
      city: "White Heath",
      zip_code: "61884",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "22134",
      city: "Whittington",
      zip_code: "62897",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21472",
      city: "Williamsfield",
      zip_code: "61489",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "22040",
      city: "Williamsville",
      zip_code: "62693",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "22212",
      city: "Willisville",
      zip_code: "62997",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21933",
      city: "Willow Hill",
      zip_code: "62480",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21036",
      city: "Willow Springs",
      zip_code: "60480",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21064",
      city: "Willowbrook",
      zip_code: "60527",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "20878",
      city: "Wilmette",
      zip_code: "60091",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21037",
      city: "Wilmington",
      zip_code: "60481",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21744",
      city: "Wilsonville",
      zip_code: "62093",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "22041",
      city: "Winchester",
      zip_code: "62694",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21673",
      city: "Windsor",
      zip_code: "61957",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "20950",
      city: "Winfield",
      zip_code: "60190",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21282",
      city: "Winnebago",
      zip_code: "61088",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "20879",
      city: "Winnetka",
      zip_code: "60093",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21283",
      city: "Winslow",
      zip_code: "61089",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "20880",
      city: "Winthrop Harbor",
      zip_code: "60096",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21745",
      city: "Witt",
      zip_code: "62094",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "22213",
      city: "Wolf Lake",
      zip_code: "62998",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "20881",
      city: "Wonder Lake",
      zip_code: "60097",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "20951",
      city: "Wood Dale",
      zip_code: "60191",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21746",
      city: "Wood River",
      zip_code: "62095",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21473",
      city: "Woodhull",
      zip_code: "61490",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21218",
      city: "Woodland",
      zip_code: "60974",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "22135",
      city: "Woodlawn",
      zip_code: "62898",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21056",
      city: "Woodridge",
      zip_code: "60517",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "22042",
      city: "Woodson",
      zip_code: "62695",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "20882",
      city: "Woodstock",
      zip_code: "60098",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21284",
      city: "Woosung",
      zip_code: "61091",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21747",
      city: "Worden",
      zip_code: "62097",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21038",
      city: "Worth",
      zip_code: "60482",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21748",
      city: "Wrights",
      zip_code: "62098",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21401",
      city: "Wyanet",
      zip_code: "61379",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21474",
      city: "Wyoming",
      zip_code: "61491",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "22136",
      city: "Xenia",
      zip_code: "62899",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21934",
      city: "Yale",
      zip_code: "62481",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21520",
      city: "Yates City",
      zip_code: "61572",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "21092",
      city: "Yorkville",
      zip_code: "60560",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "22214",
      city: "Zeigler",
      zip_code: "62999",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "20883",
      city: "Zion",
      zip_code: "60099",
      state_id: "IL",
      state_name: "Illinois",
    },
    {
      city_id: "15591",
      city: "Akron",
      zip_code: "46910",
      state_id: "IN",
      state_name: "Indiana",
    },
    {
      city_id: "15511",
      city: "Albion",
      zip_code: "46701",
      state_id: "IN",
      state_name: "Indiana",
    },
    {
      city_id: "16003",
      city: "Ambia",
      zip_code: "47917",
      state_id: "IN",
      state_name: "Indiana",
    },
    {
      city_id: "15592",
      city: "Amboy",
      zip_code: "46911",
      state_id: "IN",
      state_name: "Indiana",
    },
    {
      city_id: "15257",
      city: "Anderson",
      zip_code: "46013",
      state_id: "IN",
      state_name: "Indiana",
    },
    {
      city_id: "15512",
      city: "Andrews",
      zip_code: "46702",
      state_id: "IN",
      state_name: "Indiana",
    },
    {
      city_id: "15513",
      city: "Angola",
      zip_code: "46703",
      state_id: "IN",
      state_name: "Indiana",
    },
    {
      city_id: "15514",
      city: "Arcola",
      zip_code: "46704",
      state_id: "IN",
      state_name: "Indiana",
    },
    {
      city_id: "15452",
      city: "Argos",
      zip_code: "46501",
      state_id: "IN",
      state_name: "Indiana",
    },
    {
      city_id: "15515",
      city: "Ashley",
      zip_code: "46705",
      state_id: "IN",
      state_name: "Indiana",
    },
    {
      city_id: "15261",
      city: "Atlanta",
      zip_code: "46031",
      state_id: "IN",
      state_name: "Indiana",
    },
    {
      city_id: "16004",
      city: "Attica",
      zip_code: "47918",
      state_id: "IN",
      state_name: "Indiana",
    },
    {
      city_id: "15453",
      city: "Atwood",
      zip_code: "46502",
      state_id: "IN",
      state_name: "Indiana",
    },
    {
      city_id: "15516",
      city: "Auburn",
      zip_code: "46706",
      state_id: "IN",
      state_name: "Indiana",
    },
    {
      city_id: "15517",
      city: "Avilla",
      zip_code: "46710",
      state_id: "IN",
      state_name: "Indiana",
    },
    {
      city_id: "16005",
      city: "Battle Ground",
      zip_code: "47920",
      state_id: "IN",
      state_name: "Indiana",
    },
    {
      city_id: "15518",
      city: "Berne",
      zip_code: "46711",
      state_id: "IN",
      state_name: "Indiana",
    },
    {
      city_id: "15398",
      city: "Beverly Shores",
      zip_code: "46301",
      state_id: "IN",
      state_name: "Indiana",
    },
    {
      city_id: "15519",
      city: "Bluffton",
      zip_code: "46714",
      state_id: "IN",
      state_name: "Indiana",
    },
    {
      city_id: "15399",
      city: "Boone Grove",
      zip_code: "46302",
      state_id: "IN",
      state_name: "Indiana",
    },
    {
      city_id: "16006",
      city: "Boswell",
      zip_code: "47921",
      state_id: "IN",
      state_name: "Indiana",
    },
    {
      city_id: "15454",
      city: "Bourbon",
      zip_code: "46504",
      state_id: "IN",
      state_name: "Indiana",
    },
    {
      city_id: "15455",
      city: "Bremen",
      zip_code: "46506",
      state_id: "IN",
      state_name: "Indiana",
    },
    {
      city_id: "15593",
      city: "Bringhurst",
      zip_code: "46913",
      state_id: "IN",
      state_name: "Indiana",
    },
    {
      city_id: "15456",
      city: "Bristol",
      zip_code: "46507",
      state_id: "IN",
      state_name: "Indiana",
    },
    {
      city_id: "16007",
      city: "Brook",
      zip_code: "47922",
      state_id: "IN",
      state_name: "Indiana",
    },
    {
      city_id: "16008",
      city: "Brookston",
      zip_code: "47923",
      state_id: "IN",
      state_name: "Indiana",
    },
    {
      city_id: "16010",
      city: "Buffalo",
      zip_code: "47925",
      state_id: "IN",
      state_name: "Indiana",
    },
    {
      city_id: "15594",
      city: "Bunker Hill",
      zip_code: "46914",
      state_id: "IN",
      state_name: "Indiana",
    },
    {
      city_id: "15457",
      city: "Burket",
      zip_code: "46508",
      state_id: "IN",
      state_name: "Indiana",
    },
    {
      city_id: "15595",
      city: "Burlington",
      zip_code: "46915",
      state_id: "IN",
      state_name: "Indiana",
    },
    {
      city_id: "16011",
      city: "Burnettsville",
      zip_code: "47926",
      state_id: "IN",
      state_name: "Indiana",
    },
    {
      city_id: "15520",
      city: "Butler",
      zip_code: "46721",
      state_id: "IN",
      state_name: "Indiana",
    },
    {
      city_id: "15596",
      city: "Camden",
      zip_code: "46917",
      state_id: "IN",
      state_name: "Indiana",
    },
    {
      city_id: "16012",
      city: "Cayuga",
      zip_code: "47928",
      state_id: "IN",
      state_name: "Indiana",
    },
    {
      city_id: "15400",
      city: "Cedar Lake",
      zip_code: "46303",
      state_id: "IN",
      state_name: "Indiana",
    },
    {
      city_id: "16013",
      city: "Chalmers",
      zip_code: "47929",
      state_id: "IN",
      state_name: "Indiana",
    },
    {
      city_id: "15401",
      city: "Chesterton",
      zip_code: "46304",
      state_id: "IN",
      state_name: "Indiana",
    },
    {
      city_id: "15521",
      city: "Churubusco",
      zip_code: "46723",
      state_id: "IN",
      state_name: "Indiana",
    },
    {
      city_id: "15458",
      city: "Claypool",
      zip_code: "46510",
      state_id: "IN",
      state_name: "Indiana",
    },
    {
      city_id: "15522",
      city: "Columbia City",
      zip_code: "46725",
      state_id: "IN",
      state_name: "Indiana",
    },
    {
      city_id: "15597",
      city: "Converse",
      zip_code: "46919",
      state_id: "IN",
      state_name: "Indiana",
    },
    {
      city_id: "15523",
      city: "Corunna",
      zip_code: "46730",
      state_id: "IN",
      state_name: "Indiana",
    },
    {
      city_id: "15524",
      city: "Craigville",
      zip_code: "46731",
      state_id: "IN",
      state_name: "Indiana",
    },
    {
      city_id: "15525",
      city: "Cromwell",
      zip_code: "46732",
      state_id: "IN",
      state_name: "Indiana",
    },
    {
      city_id: "15402",
      city: "Crown Point",
      zip_code: "46307",
      state_id: "IN",
      state_name: "Indiana",
    },
    {
      city_id: "15459",
      city: "Culver",
      zip_code: "46511",
      state_id: "IN",
      state_name: "Indiana",
    },
    {
      city_id: "15598",
      city: "Cutler",
      zip_code: "46920",
      state_id: "IN",
      state_name: "Indiana",
    },
    {
      city_id: "15526",
      city: "Decatur",
      zip_code: "46733",
      state_id: "IN",
      state_name: "Indiana",
    },
    {
      city_id: "15599",
      city: "Delong",
      zip_code: "46922",
      state_id: "IN",
      state_name: "Indiana",
    },
    {
      city_id: "15600",
      city: "Delphi",
      zip_code: "46923",
      state_id: "IN",
      state_name: "Indiana",
    },
    {
      city_id: "15403",
      city: "Demotte",
      zip_code: "46310",
      state_id: "IN",
      state_name: "Indiana",
    },
    {
      city_id: "15601",
      city: "Denver",
      zip_code: "46926",
      state_id: "IN",
      state_name: "Indiana",
    },
    {
      city_id: "15460",
      city: "Donaldson",
      zip_code: "46513",
      state_id: "IN",
      state_name: "Indiana",
    },
    {
      city_id: "15404",
      city: "Dyer",
      zip_code: "46311",
      state_id: "IN",
      state_name: "Indiana",
    },
    {
      city_id: "16019",
      city: "Earl Park",
      zip_code: "47942",
      state_id: "IN",
      state_name: "Indiana",
    },
    {
      city_id: "15405",
      city: "East Chicago",
      zip_code: "46312",
      state_id: "IN",
      state_name: "Indiana",
    },
    {
      city_id: "15461",
      city: "Elkhart",
      zip_code: "46514",
      state_id: "IN",
      state_name: "Indiana",
    },
    {
      city_id: "15464",
      city: "Etna Green",
      zip_code: "46524",
      state_id: "IN",
      state_name: "Indiana",
    },
    {
      city_id: "15945",
      city: "Evansville",
      zip_code: "47720",
      state_id: "IN",
      state_name: "Indiana",
    },
    {
      city_id: "16020",
      city: "Fair Oaks",
      zip_code: "47943",
      state_id: "IN",
      state_name: "Indiana",
    },
    {
      city_id: "15603",
      city: "Flora",
      zip_code: "46929",
      state_id: "IN",
      state_name: "Indiana",
    },
    {
      city_id: "15269",
      city: "Forest",
      zip_code: "46039",
      state_id: "IN",
      state_name: "Indiana",
    },
    {
      city_id: "15573",
      city: "Fort Wayne",
      zip_code: "46802",
      state_id: "IN",
      state_name: "Indiana",
    },
    {
      city_id: "16021",
      city: "Fowler",
      zip_code: "47944",
      state_id: "IN",
      state_name: "Indiana",
    },
    {
      city_id: "16022",
      city: "Francesville",
      zip_code: "47946",
      state_id: "IN",
      state_name: "Indiana",
    },
    {
      city_id: "15271",
      city: "Frankfort",
      zip_code: "46041",
      state_id: "IN",
      state_name: "Indiana",
    },
    {
      city_id: "15527",
      city: "Fremont",
      zip_code: "46737",
      state_id: "IN",
      state_name: "Indiana",
    },
    {
      city_id: "15605",
      city: "Fulton",
      zip_code: "46931",
      state_id: "IN",
      state_name: "Indiana",
    },
    {
      city_id: "15606",
      city: "Galveston",
      zip_code: "46932",
      state_id: "IN",
      state_name: "Indiana",
    },
    {
      city_id: "15528",
      city: "Garrett",
      zip_code: "46738",
      state_id: "IN",
      state_name: "Indiana",
    },
    {
      city_id: "15443",
      city: "Gary",
      zip_code: "46402",
      state_id: "IN",
      state_name: "Indiana",
    },
    {
      city_id: "15529",
      city: "Geneva",
      zip_code: "46740",
      state_id: "IN",
      state_name: "Indiana",
    },
    {
      city_id: "15879",
      city: "Gentryville",
      zip_code: "47537",
      state_id: "IN",
      state_name: "Indiana",
    },
    {
      city_id: "16023",
      city: "Goodland",
      zip_code: "47948",
      state_id: "IN",
      state_name: "Indiana",
    },
    {
      city_id: "15465",
      city: "Goshen",
      zip_code: "46526",
      state_id: "IN",
      state_name: "Indiana",
    },
    {
      city_id: "15830",
      city: "Gosport",
      zip_code: "47433",
      state_id: "IN",
      state_name: "Indiana",
    },
    {
      city_id: "15530",
      city: "Grabill",
      zip_code: "46741",
      state_id: "IN",
      state_name: "Indiana",
    },
    {
      city_id: "15467",
      city: "Granger",
      zip_code: "46530",
      state_id: "IN",
      state_name: "Indiana",
    },
    {
      city_id: "15608",
      city: "Greentown",
      zip_code: "46936",
      state_id: "IN",
      state_name: "Indiana",
    },
    {
      city_id: "15918",
      city: "Griffin",
      zip_code: "47616",
      state_id: "IN",
      state_name: "Indiana",
    },
    {
      city_id: "15406",
      city: "Griffith",
      zip_code: "46319",
      state_id: "IN",
      state_name: "Indiana",
    },
    {
      city_id: "15632",
      city: "Grissom Arb",
      zip_code: "46971",
      state_id: "IN",
      state_name: "Indiana",
    },
    {
      city_id: "15468",
      city: "Grovertown",
      zip_code: "46531",
      state_id: "IN",
      state_name: "Indiana",
    },
    {
      city_id: "15531",
      city: "Hamilton",
      zip_code: "46742",
      state_id: "IN",
      state_name: "Indiana",
    },
    {
      city_id: "15469",
      city: "Hamlet",
      zip_code: "46532",
      state_id: "IN",
      state_name: "Indiana",
    },
    {
      city_id: "15407",
      city: "Hammond",
      zip_code: "46320",
      state_id: "IN",
      state_name: "Indiana",
    },
    {
      city_id: "15413",
      city: "Hanna",
      zip_code: "46340",
      state_id: "IN",
      state_name: "Indiana",
    },
    {
      city_id: "15532",
      city: "Harlan",
      zip_code: "46743",
      state_id: "IN",
      state_name: "Indiana",
    },
    {
      city_id: "15414",
      city: "Hebron",
      zip_code: "46341",
      state_id: "IN",
      state_name: "Indiana",
    },
    {
      city_id: "15609",
      city: "Hemlock",
      zip_code: "46937",
      state_id: "IN",
      state_name: "Indiana",
    },
    {
      city_id: "15409",
      city: "Highland",
      zip_code: "46322",
      state_id: "IN",
      state_name: "Indiana",
    },
    {
      city_id: "15533",
      city: "Hoagland",
      zip_code: "46745",
      state_id: "IN",
      state_name: "Indiana",
    },
    {
      city_id: "15415",
      city: "Hobart",
      zip_code: "46342",
      state_id: "IN",
      state_name: "Indiana",
    },
    {
      city_id: "15534",
      city: "Howe",
      zip_code: "46746",
      state_id: "IN",
      state_name: "Indiana",
    },
    {
      city_id: "15535",
      city: "Hudson",
      zip_code: "46747",
      state_id: "IN",
      state_name: "Indiana",
    },
    {
      city_id: "15536",
      city: "Huntertown",
      zip_code: "46748",
      state_id: "IN",
      state_name: "Indiana",
    },
    {
      city_id: "15537",
      city: "Huntington",
      zip_code: "46750",
      state_id: "IN",
      state_name: "Indiana",
    },
    {
      city_id: "16025",
      city: "Idaville",
      zip_code: "47950",
      state_id: "IN",
      state_name: "Indiana",
    },
    {
      city_id: "15373",
      city: "Indianapolis",
      zip_code: "46221",
      state_id: "IN",
      state_name: "Indiana",
    },
    {
      city_id: "15700",
      city: "Jeffersonville",
      zip_code: "47130",
      state_id: "IN",
      state_name: "Indiana",
    },
    {
      city_id: "15538",
      city: "Kendallville",
      zip_code: "46755",
      state_id: "IN",
      state_name: "Indiana",
    },
    {
      city_id: "16026",
      city: "Kentland",
      zip_code: "47951",
      state_id: "IN",
      state_name: "Indiana",
    },
    {
      city_id: "15611",
      city: "Kewanna",
      zip_code: "46939",
      state_id: "IN",
      state_name: "Indiana",
    },
    {
      city_id: "15540",
      city: "Kimmell",
      zip_code: "46760",
      state_id: "IN",
      state_name: "Indiana",
    },
    {
      city_id: "15416",
      city: "Kingsbury",
      zip_code: "46345",
      state_id: "IN",
      state_name: "Indiana",
    },
    {
      city_id: "15417",
      city: "Kingsford Heights",
      zip_code: "46346",
      state_id: "IN",
      state_name: "Indiana",
    },
    {
      city_id: "15470",
      city: "Knox",
      zip_code: "46534",
      state_id: "IN",
      state_name: "Indiana",
    },
    {
      city_id: "15589",
      city: "Kokomo",
      zip_code: "46901",
      state_id: "IN",
      state_name: "Indiana",
    },
    {
      city_id: "15418",
      city: "Kouts",
      zip_code: "46347",
      state_id: "IN",
      state_name: "Indiana",
    },
    {
      city_id: "15419",
      city: "La Crosse",
      zip_code: "46348",
      state_id: "IN",
      state_name: "Indiana",
    },
    {
      city_id: "15612",
      city: "La Fontaine",
      zip_code: "46940",
      state_id: "IN",
      state_name: "Indiana",
    },
    {
      city_id: "15421",
      city: "La Porte",
      zip_code: "46350",
      state_id: "IN",
      state_name: "Indiana",
    },
    {
      city_id: "15996",
      city: "Lafayette",
      zip_code: "47901",
      state_id: "IN",
      state_name: "Indiana",
    },
    {
      city_id: "15541",
      city: "Lagrange",
      zip_code: "46761",
      state_id: "IN",
      state_name: "Indiana",
    },
    {
      city_id: "15613",
      city: "Lagro",
      zip_code: "46941",
      state_id: "IN",
      state_name: "Indiana",
    },
    {
      city_id: "15446",
      city: "Lake Station",
      zip_code: "46405",
      state_id: "IN",
      state_name: "Indiana",
    },
    {
      city_id: "15420",
      city: "Lake Village",
      zip_code: "46349",
      state_id: "IN",
      state_name: "Indiana",
    },
    {
      city_id: "15614",
      city: "Laketon",
      zip_code: "46943",
      state_id: "IN",
      state_name: "Indiana",
    },
    {
      city_id: "15471",
      city: "Lakeville",
      zip_code: "46536",
      state_id: "IN",
      state_name: "Indiana",
    },
    {
      city_id: "15542",
      city: "Laotto",
      zip_code: "46763",
      state_id: "IN",
      state_name: "Indiana",
    },
    {
      city_id: "15472",
      city: "Lapaz",
      zip_code: "46537",
      state_id: "IN",
      state_name: "Indiana",
    },
    {
      city_id: "15543",
      city: "Larwill",
      zip_code: "46764",
      state_id: "IN",
      state_name: "Indiana",
    },
    {
      city_id: "15473",
      city: "Leesburg",
      zip_code: "46538",
      state_id: "IN",
      state_name: "Indiana",
    },
    {
      city_id: "15615",
      city: "Leiters Ford",
      zip_code: "46945",
      state_id: "IN",
      state_name: "Indiana",
    },
    {
      city_id: "15544",
      city: "Leo",
      zip_code: "46765",
      state_id: "IN",
      state_name: "Indiana",
    },
    {
      city_id: "15785",
      city: "Liberty",
      zip_code: "47353",
      state_id: "IN",
      state_name: "Indiana",
    },
    {
      city_id: "15616",
      city: "Liberty Mills",
      zip_code: "46946",
      state_id: "IN",
      state_name: "Indiana",
    },
    {
      city_id: "15546",
      city: "Ligonier",
      zip_code: "46767",
      state_id: "IN",
      state_name: "Indiana",
    },
    {
      city_id: "15617",
      city: "Logansport",
      zip_code: "46947",
      state_id: "IN",
      state_name: "Indiana",
    },
    {
      city_id: "15422",
      city: "Lowell",
      zip_code: "46356",
      state_id: "IN",
      state_name: "Indiana",
    },
    {
      city_id: "15618",
      city: "Lucerne",
      zip_code: "46950",
      state_id: "IN",
      state_name: "Indiana",
    },
    {
      city_id: "15619",
      city: "Macy",
      zip_code: "46951",
      state_id: "IN",
      state_name: "Indiana",
    },
    {
      city_id: "15620",
      city: "Marion",
      zip_code: "46952",
      state_id: "IN",
      state_name: "Indiana",
    },
    {
      city_id: "15547",
      city: "Markle",
      zip_code: "46770",
      state_id: "IN",
      state_name: "Indiana",
    },
    {
      city_id: "16030",
      city: "Medaryville",
      zip_code: "47957",
      state_id: "IN",
      state_name: "Indiana",
    },
    {
      city_id: "16031",
      city: "Mellott",
      zip_code: "47958",
      state_id: "IN",
      state_name: "Indiana",
    },
    {
      city_id: "15474",
      city: "Mentone",
      zip_code: "46539",
      state_id: "IN",
      state_name: "Indiana",
    },
    {
      city_id: "15451",
      city: "Merrillville",
      zip_code: "46410",
      state_id: "IN",
      state_name: "Indiana",
    },
    {
      city_id: "15623",
      city: "Mexico",
      zip_code: "46958",
      state_id: "IN",
      state_name: "Indiana",
    },
    {
      city_id: "15624",
      city: "Miami",
      zip_code: "46959",
      state_id: "IN",
      state_name: "Indiana",
    },
    {
      city_id: "15423",
      city: "Michigan City",
      zip_code: "46360",
      state_id: "IN",
      state_name: "Indiana",
    },
    {
      city_id: "15282",
      city: "Michigantown",
      zip_code: "46057",
      state_id: "IN",
      state_name: "Indiana",
    },
    {
      city_id: "15475",
      city: "Middlebury",
      zip_code: "46540",
      state_id: "IN",
      state_name: "Indiana",
    },
    {
      city_id: "15476",
      city: "Milford",
      zip_code: "46542",
      state_id: "IN",
      state_name: "Indiana",
    },
    {
      city_id: "15424",
      city: "Mill Creek",
      zip_code: "46365",
      state_id: "IN",
      state_name: "Indiana",
    },
    {
      city_id: "15477",
      city: "Millersburg",
      zip_code: "46543",
      state_id: "IN",
      state_name: "Indiana",
    },
    {
      city_id: "15478",
      city: "Mishawaka",
      zip_code: "46544",
      state_id: "IN",
      state_name: "Indiana",
    },
    {
      city_id: "15548",
      city: "Mongo",
      zip_code: "46771",
      state_id: "IN",
      state_name: "Indiana",
    },
    {
      city_id: "16032",
      city: "Monon",
      zip_code: "47959",
      state_id: "IN",
      state_name: "Indiana",
    },
    {
      city_id: "15549",
      city: "Monroe",
      zip_code: "46772",
      state_id: "IN",
      state_name: "Indiana",
    },
    {
      city_id: "15550",
      city: "Monroeville",
      zip_code: "46773",
      state_id: "IN",
      state_name: "Indiana",
    },
    {
      city_id: "15625",
      city: "Monterey",
      zip_code: "46960",
      state_id: "IN",
      state_name: "Indiana",
    },
    {
      city_id: "16033",
      city: "Monticello",
      zip_code: "47960",
      state_id: "IN",
      state_name: "Indiana",
    },
    {
      city_id: "15338",
      city: "Mooresville",
      zip_code: "46158",
      state_id: "IN",
      state_name: "Indiana",
    },
    {
      city_id: "16034",
      city: "Morocco",
      zip_code: "47963",
      state_id: "IN",
      state_name: "Indiana",
    },
    {
      city_id: "16035",
      city: "Mount Ayr",
      zip_code: "47964",
      state_id: "IN",
      state_name: "Indiana",
    },
    {
      city_id: "15283",
      city: "Mulberry",
      zip_code: "46058",
      state_id: "IN",
      state_name: "Indiana",
    },
    {
      city_id: "15408",
      city: "Munster",
      zip_code: "46321",
      state_id: "IN",
      state_name: "Indiana",
    },
    {
      city_id: "15480",
      city: "Nappanee",
      zip_code: "46550",
      state_id: "IN",
      state_name: "Indiana",
    },
    {
      city_id: "15481",
      city: "New Carlisle",
      zip_code: "46552",
      state_id: "IN",
      state_name: "Indiana",
    },
    {
      city_id: "15551",
      city: "New Haven",
      zip_code: "46774",
      state_id: "IN",
      state_name: "Indiana",
    },
    {
      city_id: "15482",
      city: "New Paris",
      zip_code: "46553",
      state_id: "IN",
      state_name: "Indiana",
    },
    {
      city_id: "15626",
      city: "New Waverly",
      zip_code: "46961",
      state_id: "IN",
      state_name: "Indiana",
    },
    {
      city_id: "15425",
      city: "North Judson",
      zip_code: "46366",
      state_id: "IN",
      state_name: "Indiana",
    },
    {
      city_id: "15483",
      city: "North Liberty",
      zip_code: "46554",
      state_id: "IN",
      state_name: "Indiana",
    },
    {
      city_id: "15627",
      city: "North Manchester",
      zip_code: "46962",
      state_id: "IN",
      state_name: "Indiana",
    },
    {
      city_id: "15484",
      city: "North Webster",
      zip_code: "46555",
      state_id: "IN",
      state_name: "Indiana",
    },
    {
      city_id: "15485",
      city: "Notre Dame",
      zip_code: "46556",
      state_id: "IN",
      state_name: "Indiana",
    },
    {
      city_id: "15628",
      city: "Oakford",
      zip_code: "46965",
      state_id: "IN",
      state_name: "Indiana",
    },
    {
      city_id: "15629",
      city: "Onward",
      zip_code: "46967",
      state_id: "IN",
      state_name: "Indiana",
    },
    {
      city_id: "15630",
      city: "Ora",
      zip_code: "46968",
      state_id: "IN",
      state_name: "Indiana",
    },
    {
      city_id: "15552",
      city: "Orland",
      zip_code: "46776",
      state_id: "IN",
      state_name: "Indiana",
    },
    {
      city_id: "15486",
      city: "Osceola",
      zip_code: "46561",
      state_id: "IN",
      state_name: "Indiana",
    },
    {
      city_id: "15553",
      city: "Ossian",
      zip_code: "46777",
      state_id: "IN",
      state_name: "Indiana",
    },
    {
      city_id: "16041",
      city: "Otterbein",
      zip_code: "47970",
      state_id: "IN",
      state_name: "Indiana",
    },
    {
      city_id: "16042",
      city: "Oxford",
      zip_code: "47971",
      state_id: "IN",
      state_name: "Indiana",
    },
    {
      city_id: "15798",
      city: "Pennville",
      zip_code: "47369",
      state_id: "IN",
      state_name: "Indiana",
    },
    {
      city_id: "15631",
      city: "Peru",
      zip_code: "46970",
      state_id: "IN",
      state_name: "Indiana",
    },
    {
      city_id: "15487",
      city: "Pierceton",
      zip_code: "46562",
      state_id: "IN",
      state_name: "Indiana",
    },
    {
      city_id: "16044",
      city: "Pine Village",
      zip_code: "47975",
      state_id: "IN",
      state_name: "Indiana",
    },
    {
      city_id: "15347",
      city: "Plainfield",
      zip_code: "46168",
      state_id: "IN",
      state_name: "Indiana",
    },
    {
      city_id: "15555",
      city: "Pleasant Lake",
      zip_code: "46779",
      state_id: "IN",
      state_name: "Indiana",
    },
    {
      city_id: "15488",
      city: "Plymouth",
      zip_code: "46563",
      state_id: "IN",
      state_name: "Indiana",
    },
    {
      city_id: "15556",
      city: "Poneto",
      zip_code: "46781",
      state_id: "IN",
      state_name: "Indiana",
    },
    {
      city_id: "15426",
      city: "Portage",
      zip_code: "46368",
      state_id: "IN",
      state_name: "Indiana",
    },
    {
      city_id: "15800",
      city: "Portland",
      zip_code: "47371",
      state_id: "IN",
      state_name: "Indiana",
    },
    {
      city_id: "16045",
      city: "Remington",
      zip_code: "47977",
      state_id: "IN",
      state_name: "Indiana",
    },
    {
      city_id: "16046",
      city: "Rensselaer",
      zip_code: "47978",
      state_id: "IN",
      state_name: "Indiana",
    },
    {
      city_id: "16047",
      city: "Reynolds",
      zip_code: "47980",
      state_id: "IN",
      state_name: "Indiana",
    },
    {
      city_id: "15633",
      city: "Roann",
      zip_code: "46974",
      state_id: "IN",
      state_name: "Indiana",
    },
    {
      city_id: "15557",
      city: "Roanoke",
      zip_code: "46783",
      state_id: "IN",
      state_name: "Indiana",
    },
    {
      city_id: "15634",
      city: "Rochester",
      zip_code: "46975",
      state_id: "IN",
      state_name: "Indiana",
    },
    {
      city_id: "15427",
      city: "Rolling Prairie",
      zip_code: "46371",
      state_id: "IN",
      state_name: "Indiana",
    },
    {
      city_id: "15558",
      city: "Rome City",
      zip_code: "46784",
      state_id: "IN",
      state_name: "Indiana",
    },
    {
      city_id: "15288",
      city: "Rossville",
      zip_code: "46065",
      state_id: "IN",
      state_name: "Indiana",
    },
    {
      city_id: "15635",
      city: "Royal Center",
      zip_code: "46978",
      state_id: "IN",
      state_name: "Indiana",
    },
    {
      city_id: "15636",
      city: "Russiaville",
      zip_code: "46979",
      state_id: "IN",
      state_name: "Indiana",
    },
    {
      city_id: "15559",
      city: "Saint Joe",
      zip_code: "46785",
      state_id: "IN",
      state_name: "Indiana",
    },
    {
      city_id: "15428",
      city: "Saint John",
      zip_code: "46373",
      state_id: "IN",
      state_name: "Indiana",
    },
    {
      city_id: "15429",
      city: "San Pierre",
      zip_code: "46374",
      state_id: "IN",
      state_name: "Indiana",
    },
    {
      city_id: "15430",
      city: "Schererville",
      zip_code: "46375",
      state_id: "IN",
      state_name: "Indiana",
    },
    {
      city_id: "15431",
      city: "Schneider",
      zip_code: "46376",
      state_id: "IN",
      state_name: "Indiana",
    },
    {
      city_id: "15637",
      city: "Servia",
      zip_code: "46980",
      state_id: "IN",
      state_name: "Indiana",
    },
    {
      city_id: "15289",
      city: "Sharpsville",
      zip_code: "46068",
      state_id: "IN",
      state_name: "Indiana",
    },
    {
      city_id: "15432",
      city: "Shelby",
      zip_code: "46377",
      state_id: "IN",
      state_name: "Indiana",
    },
    {
      city_id: "15489",
      city: "Shipshewana",
      zip_code: "46565",
      state_id: "IN",
      state_name: "Indiana",
    },
    {
      city_id: "15638",
      city: "Silver Lake",
      zip_code: "46982",
      state_id: "IN",
      state_name: "Indiana",
    },
    {
      city_id: "15500",
      city: "South Bend",
      zip_code: "46601",
      state_id: "IN",
      state_name: "Indiana",
    },
    {
      city_id: "15560",
      city: "South Milford",
      zip_code: "46786",
      state_id: "IN",
      state_name: "Indiana",
    },
    {
      city_id: "15561",
      city: "South Whitley",
      zip_code: "46787",
      state_id: "IN",
      state_name: "Indiana",
    },
    {
      city_id: "15562",
      city: "Spencerville",
      zip_code: "46788",
      state_id: "IN",
      state_name: "Indiana",
    },
    {
      city_id: "15852",
      city: "Springville",
      zip_code: "47462",
      state_id: "IN",
      state_name: "Indiana",
    },
    {
      city_id: "15640",
      city: "Star City",
      zip_code: "46985",
      state_id: "IN",
      state_name: "Indiana",
    },
    {
      city_id: "15563",
      city: "Stroh",
      zip_code: "46789",
      state_id: "IN",
      state_name: "Indiana",
    },
    {
      city_id: "15433",
      city: "Sumava Resorts",
      zip_code: "46379",
      state_id: "IN",
      state_name: "Indiana",
    },
    {
      city_id: "15490",
      city: "Syracuse",
      zip_code: "46567",
      state_id: "IN",
      state_name: "Indiana",
    },
    {
      city_id: "16051",
      city: "Templeton",
      zip_code: "47986",
      state_id: "IN",
      state_name: "Indiana",
    },
    {
      city_id: "15434",
      city: "Thayer",
      zip_code: "46381",
      state_id: "IN",
      state_name: "Indiana",
    },
    {
      city_id: "15491",
      city: "Tippecanoe",
      zip_code: "46570",
      state_id: "IN",
      state_name: "Indiana",
    },
    {
      city_id: "15293",
      city: "Tipton",
      zip_code: "46072",
      state_id: "IN",
      state_name: "Indiana",
    },
    {
      city_id: "15492",
      city: "Topeka",
      zip_code: "46571",
      state_id: "IN",
      state_name: "Indiana",
    },
    {
      city_id: "15643",
      city: "Twelve Mile",
      zip_code: "46988",
      state_id: "IN",
      state_name: "Indiana",
    },
    {
      city_id: "15493",
      city: "Tyner",
      zip_code: "46572",
      state_id: "IN",
      state_name: "Indiana",
    },
    {
      city_id: "15435",
      city: "Union Mills",
      zip_code: "46382",
      state_id: "IN",
      state_name: "Indiana",
    },
    {
      city_id: "15564",
      city: "Uniondale",
      zip_code: "46791",
      state_id: "IN",
      state_name: "Indiana",
    },
    {
      city_id: "15645",
      city: "Urbana",
      zip_code: "46990",
      state_id: "IN",
      state_name: "Indiana",
    },
    {
      city_id: "15436",
      city: "Valparaiso",
      zip_code: "46383",
      state_id: "IN",
      state_name: "Indiana",
    },
    {
      city_id: "15647",
      city: "Wabash",
      zip_code: "46992",
      state_id: "IN",
      state_name: "Indiana",
    },
    {
      city_id: "15494",
      city: "Wakarusa",
      zip_code: "46573",
      state_id: "IN",
      state_name: "Indiana",
    },
    {
      city_id: "15495",
      city: "Walkerton",
      zip_code: "46574",
      state_id: "IN",
      state_name: "Indiana",
    },
    {
      city_id: "15648",
      city: "Walton",
      zip_code: "46994",
      state_id: "IN",
      state_name: "Indiana",
    },
    {
      city_id: "15438",
      city: "Wanatah",
      zip_code: "46390",
      state_id: "IN",
      state_name: "Indiana",
    },
    {
      city_id: "15565",
      city: "Warren",
      zip_code: "46792",
      state_id: "IN",
      state_name: "Indiana",
    },
    {
      city_id: "15496",
      city: "Warsaw",
      zip_code: "46580",
      state_id: "IN",
      state_name: "Indiana",
    },
    {
      city_id: "15566",
      city: "Waterloo",
      zip_code: "46793",
      state_id: "IN",
      state_name: "Indiana",
    },
    {
      city_id: "15567",
      city: "Wawaka",
      zip_code: "46794",
      state_id: "IN",
      state_name: "Indiana",
    },
    {
      city_id: "15999",
      city: "West Lafayette",
      zip_code: "47906",
      state_id: "IN",
      state_name: "Indiana",
    },
    {
      city_id: "15439",
      city: "Westville",
      zip_code: "46391",
      state_id: "IN",
      state_name: "Indiana",
    },
    {
      city_id: "15440",
      city: "Wheatfield",
      zip_code: "46392",
      state_id: "IN",
      state_name: "Indiana",
    },
    {
      city_id: "15910",
      city: "Wheatland",
      zip_code: "47597",
      state_id: "IN",
      state_name: "Indiana",
    },
    {
      city_id: "15441",
      city: "Wheeler",
      zip_code: "46393",
      state_id: "IN",
      state_name: "Indiana",
    },
    {
      city_id: "15358",
      city: "Whiteland",
      zip_code: "46184",
      state_id: "IN",
      state_name: "Indiana",
    },
    {
      city_id: "15442",
      city: "Whiting",
      zip_code: "46394",
      state_id: "IN",
      state_name: "Indiana",
    },
    {
      city_id: "15858",
      city: "Williams",
      zip_code: "47470",
      state_id: "IN",
      state_name: "Indiana",
    },
    {
      city_id: "16057",
      city: "Williamsport",
      zip_code: "47993",
      state_id: "IN",
      state_name: "Indiana",
    },
    {
      city_id: "15649",
      city: "Winamac",
      zip_code: "46996",
      state_id: "IN",
      state_name: "Indiana",
    },
    {
      city_id: "15296",
      city: "Windfall",
      zip_code: "46076",
      state_id: "IN",
      state_name: "Indiana",
    },
    {
      city_id: "15498",
      city: "Winona Lake",
      zip_code: "46590",
      state_id: "IN",
      state_name: "Indiana",
    },
    {
      city_id: "16059",
      city: "Wolcott",
      zip_code: "47995",
      state_id: "IN",
      state_name: "Indiana",
    },
    {
      city_id: "15568",
      city: "Wolcottville",
      zip_code: "46795",
      state_id: "IN",
      state_name: "Indiana",
    },
    {
      city_id: "15570",
      city: "Woodburn",
      zip_code: "46797",
      state_id: "IN",
      state_name: "Indiana",
    },
    {
      city_id: "15499",
      city: "Wyatt",
      zip_code: "46595",
      state_id: "IN",
      state_name: "Indiana",
    },
    {
      city_id: "16060",
      city: "Yeoman",
      zip_code: "47997",
      state_id: "IN",
      state_name: "Indiana",
    },
    {
      city_id: "15571",
      city: "Yoder",
      zip_code: "46798",
      state_id: "IN",
      state_name: "Indiana",
    },
    {
      city_id: "15650",
      city: "Young America",
      zip_code: "46998",
      state_id: "IN",
      state_name: "Indiana",
    },
    {
      city_id: "15572",
      city: "Zanesville",
      zip_code: "46799",
      state_id: "IN",
      state_name: "Indiana",
    },
    {
      city_id: "13595",
      city: "Adams",
      zip_code: "41201",
      state_id: "KY",
      state_name: "Kentucky",
    },
    {
      city_id: "13515",
      city: "Alexandria",
      zip_code: "41001",
      state_id: "KY",
      state_name: "Kentucky",
    },
    {
      city_id: "13687",
      city: "Allen",
      zip_code: "41601",
      state_id: "KY",
      state_name: "Kentucky",
    },
    {
      city_id: "13570",
      city: "Argillite",
      zip_code: "41121",
      state_id: "KY",
      state_name: "Kentucky",
    },
    {
      city_id: "13568",
      city: "Ashland",
      zip_code: "41101",
      state_id: "KY",
      state_name: "Kentucky",
    },
    {
      city_id: "13516",
      city: "Augusta",
      zip_code: "41002",
      state_id: "KY",
      state_name: "Kentucky",
    },
    {
      city_id: "13241",
      city: "Bagdad",
      zip_code: "40003",
      state_id: "KY",
      state_name: "Kentucky",
    },
    {
      city_id: "13689",
      city: "Banner",
      zip_code: "41603",
      state_id: "KY",
      state_name: "Kentucky",
    },
    {
      city_id: "13626",
      city: "Beattyville",
      zip_code: "41311",
      state_id: "KY",
      state_name: "Kentucky",
    },
    {
      city_id: "13596",
      city: "Beauty",
      zip_code: "41203",
      state_id: "KY",
      state_name: "Kentucky",
    },
    {
      city_id: "13690",
      city: "Beaver",
      zip_code: "41604",
      state_id: "KY",
      state_name: "Kentucky",
    },
    {
      city_id: "13653",
      city: "Belfry",
      zip_code: "41514",
      state_id: "KY",
      state_name: "Kentucky",
    },
    {
      city_id: "13396",
      city: "Berea",
      zip_code: "40403",
      state_id: "KY",
      state_name: "Kentucky",
    },
    {
      city_id: "13517",
      city: "Berry",
      zip_code: "41003",
      state_id: "KY",
      state_name: "Kentucky",
    },
    {
      city_id: "13244",
      city: "Bethlehem",
      zip_code: "40007",
      state_id: "KY",
      state_name: "Kentucky",
    },
    {
      city_id: "13691",
      city: "Betsy Layne",
      zip_code: "41605",
      state_id: "KY",
      state_name: "Kentucky",
    },
    {
      city_id: "13692",
      city: "Bevinsville",
      zip_code: "41606",
      state_id: "KY",
      state_name: "Kentucky",
    },
    {
      city_id: "13571",
      city: "Blaine",
      zip_code: "41124",
      state_id: "KY",
      state_name: "Kentucky",
    },
    {
      city_id: "13627",
      city: "Booneville",
      zip_code: "41314",
      state_id: "KY",
      state_name: "Kentucky",
    },
    {
      city_id: "13597",
      city: "Boons Camp",
      zip_code: "41204",
      state_id: "KY",
      state_name: "Kentucky",
    },
    {
      city_id: "13835",
      city: "Bowling Green",
      zip_code: "42101",
      state_id: "KY",
      state_name: "Kentucky",
    },
    {
      city_id: "13518",
      city: "Brooksville",
      zip_code: "41004",
      state_id: "KY",
      state_name: "Kentucky",
    },
    {
      city_id: "13520",
      city: "Butler",
      zip_code: "41006",
      state_id: "KY",
      state_name: "Kentucky",
    },
    {
      city_id: "13694",
      city: "Bypro",
      zip_code: "41612",
      state_id: "KY",
      state_name: "Kentucky",
    },
    {
      city_id: "13521",
      city: "California",
      zip_code: "41007",
      state_id: "KY",
      state_name: "Kentucky",
    },
    {
      city_id: "13624",
      city: "Campton",
      zip_code: "41301",
      state_id: "KY",
      state_name: "Kentucky",
    },
    {
      city_id: "13356",
      city: "Carlisle",
      zip_code: "40311",
      state_id: "KY",
      state_name: "Kentucky",
    },
    {
      city_id: "13522",
      city: "Carrollton",
      zip_code: "41008",
      state_id: "KY",
      state_name: "Kentucky",
    },
    {
      city_id: "13572",
      city: "Catlettsburg",
      zip_code: "41129",
      state_id: "KY",
      state_name: "Kentucky",
    },
    {
      city_id: "13357",
      city: "Clay City",
      zip_code: "40312",
      state_id: "KY",
      state_name: "Kentucky",
    },
    {
      city_id: "13998",
      city: "Columbia",
      zip_code: "42728",
      state_id: "KY",
      state_name: "Kentucky",
    },
    {
      city_id: "13731",
      city: "Combs",
      zip_code: "41729",
      state_id: "KY",
      state_name: "Kentucky",
    },
    {
      city_id: "13437",
      city: "Corbin",
      zip_code: "40701",
      state_id: "KY",
      state_name: "Kentucky",
    },
    {
      city_id: "13732",
      city: "Cornettsville",
      zip_code: "41731",
      state_id: "KY",
      state_name: "Kentucky",
    },
    {
      city_id: "13531",
      city: "Cynthiana",
      zip_code: "41031",
      state_id: "KY",
      state_name: "Kentucky",
    },
    {
      city_id: "13695",
      city: "Dana",
      zip_code: "41615",
      state_id: "KY",
      state_name: "Kentucky",
    },
    {
      city_id: "13598",
      city: "Debord",
      zip_code: "41214",
      state_id: "KY",
      state_name: "Kentucky",
    },
    {
      city_id: "13784",
      city: "Dema",
      zip_code: "41859",
      state_id: "KY",
      state_name: "Kentucky",
    },
    {
      city_id: "13359",
      city: "Denniston",
      zip_code: "40316",
      state_id: "KY",
      state_name: "Kentucky",
    },
    {
      city_id: "13533",
      city: "Dover",
      zip_code: "41034",
      state_id: "KY",
      state_name: "Kentucky",
    },
    {
      city_id: "13697",
      city: "Drift",
      zip_code: "41619",
      state_id: "KY",
      state_name: "Kentucky",
    },
    {
      city_id: "13699",
      city: "Eastern",
      zip_code: "41622",
      state_id: "KY",
      state_name: "Kentucky",
    },
    {
      city_id: "13536",
      city: "Falmouth",
      zip_code: "41040",
      state_id: "KY",
      state_name: "Kentucky",
    },
    {
      city_id: "13657",
      city: "Fedscreek",
      zip_code: "41524",
      state_id: "KY",
      state_name: "Kentucky",
    },
    {
      city_id: "13575",
      city: "Flatwoods",
      zip_code: "41139",
      state_id: "KY",
      state_name: "Kentucky",
    },
    {
      city_id: "13537",
      city: "Flemingsburg",
      zip_code: "41041",
      state_id: "KY",
      state_name: "Kentucky",
    },
    {
      city_id: "13539",
      city: "Foster",
      zip_code: "41043",
      state_id: "KY",
      state_name: "Kentucky",
    },
    {
      city_id: "13436",
      city: "Frankfort",
      zip_code: "40601",
      state_id: "KY",
      state_name: "Kentucky",
    },
    {
      city_id: "13360",
      city: "Frenchburg",
      zip_code: "40322",
      state_id: "KY",
      state_name: "Kentucky",
    },
    {
      city_id: "13758",
      city: "Garner",
      zip_code: "41817",
      state_id: "KY",
      state_name: "Kentucky",
    },
    {
      city_id: "13361",
      city: "Georgetown",
      zip_code: "40324",
      state_id: "KY",
      state_name: "Kentucky",
    },
    {
      city_id: "13540",
      city: "Germantown",
      zip_code: "41044",
      state_id: "KY",
      state_name: "Kentucky",
    },
    {
      city_id: "13578",
      city: "Grayson",
      zip_code: "41143",
      state_id: "KY",
      state_name: "Kentucky",
    },
    {
      city_id: "13579",
      city: "Greenup",
      zip_code: "41144",
      state_id: "KY",
      state_name: "Kentucky",
    },
    {
      city_id: "13701",
      city: "Grethel",
      zip_code: "41631",
      state_id: "KY",
      state_name: "Kentucky",
    },
    {
      city_id: "13601",
      city: "Hagerhill",
      zip_code: "41222",
      state_id: "KY",
      state_name: "Kentucky",
    },
    {
      city_id: "13661",
      city: "Hardy",
      zip_code: "41531",
      state_id: "KY",
      state_name: "Kentucky",
    },
    {
      city_id: "13703",
      city: "Harold",
      zip_code: "41635",
      state_id: "KY",
      state_name: "Kentucky",
    },
    {
      city_id: "13363",
      city: "Harrodsburg",
      zip_code: "40330",
      state_id: "KY",
      state_name: "Kentucky",
    },
    {
      city_id: "13721",
      city: "Hazard",
      zip_code: "41701",
      state_id: "KY",
      state_name: "Kentucky",
    },
    {
      city_id: "13704",
      city: "Hi Hat",
      zip_code: "41636",
      state_id: "KY",
      state_name: "Kentucky",
    },
    {
      city_id: "13761",
      city: "Hindman",
      zip_code: "41822",
      state_id: "KY",
      state_name: "Kentucky",
    },
    {
      city_id: "13364",
      city: "Hope",
      zip_code: "40334",
      state_id: "KY",
      state_name: "Kentucky",
    },
    {
      city_id: "13877",
      city: "Hopkinsville",
      zip_code: "42240",
      state_id: "KY",
      state_name: "Kentucky",
    },
    {
      city_id: "13602",
      city: "Inez",
      zip_code: "41224",
      state_id: "KY",
      state_name: "Kentucky",
    },
    {
      city_id: "13365",
      city: "Irvine",
      zip_code: "40336",
      state_id: "KY",
      state_name: "Kentucky",
    },
    {
      city_id: "13706",
      city: "Ivel",
      zip_code: "41642",
      state_id: "KY",
      state_name: "Kentucky",
    },
    {
      city_id: "13630",
      city: "Jackson",
      zip_code: "41339",
      state_id: "KY",
      state_name: "Kentucky",
    },
    {
      city_id: "13366",
      city: "Jeffersonville",
      zip_code: "40337",
      state_id: "KY",
      state_name: "Kentucky",
    },
    {
      city_id: "13666",
      city: "Kimper",
      zip_code: "41539",
      state_id: "KY",
      state_name: "Kentucky",
    },
    {
      city_id: "13708",
      city: "Langley",
      zip_code: "41645",
      state_id: "KY",
      state_name: "Kentucky",
    },
    {
      city_id: "13368",
      city: "Lawrenceburg",
      zip_code: "40342",
      state_id: "KY",
      state_name: "Kentucky",
    },
    {
      city_id: "13766",
      city: "Leburn",
      zip_code: "41831",
      state_id: "KY",
      state_name: "Kentucky",
    },
    {
      city_id: "13420",
      city: "Lexington",
      zip_code: "40502",
      state_id: "KY",
      state_name: "Kentucky",
    },
    {
      city_id: "13667",
      city: "Lick Creek",
      zip_code: "41540",
      state_id: "KY",
      state_name: "Kentucky",
    },
    {
      city_id: "13769",
      city: "Littcarr",
      zip_code: "41834",
      state_id: "KY",
      state_name: "Kentucky",
    },
    {
      city_id: "13260",
      city: "Lockport",
      zip_code: "40036",
      state_id: "KY",
      state_name: "Kentucky",
    },
    {
      city_id: "13442",
      city: "London",
      zip_code: "40741",
      state_id: "KY",
      state_name: "Kentucky",
    },
    {
      city_id: "13604",
      city: "Louisa",
      zip_code: "41230",
      state_id: "KY",
      state_name: "Kentucky",
    },
    {
      city_id: "13324",
      city: "Louisville",
      zip_code: "40205",
      state_id: "KY",
      state_name: "Kentucky",
    },
    {
      city_id: "13605",
      city: "Lovely",
      zip_code: "41231",
      state_id: "KY",
      state_name: "Kentucky",
    },
    {
      city_id: "13606",
      city: "Lowmansville",
      zip_code: "41232",
      state_id: "KY",
      state_name: "Kentucky",
    },
    {
      city_id: "13771",
      city: "Mallie",
      zip_code: "41836",
      state_id: "KY",
      state_name: "Kentucky",
    },
    {
      city_id: "13503",
      city: "Manchester",
      zip_code: "40962",
      state_id: "KY",
      state_name: "Kentucky",
    },
    {
      city_id: "13582",
      city: "Martha",
      zip_code: "41159",
      state_id: "KY",
      state_name: "Kentucky",
    },
    {
      city_id: "13710",
      city: "Martin",
      zip_code: "41649",
      state_id: "KY",
      state_name: "Kentucky",
    },
    {
      city_id: "13547",
      city: "Mayslick",
      zip_code: "41055",
      state_id: "KY",
      state_name: "Kentucky",
    },
    {
      city_id: "13548",
      city: "Maysville",
      zip_code: "41056",
      state_id: "KY",
      state_name: "Kentucky",
    },
    {
      city_id: "13668",
      city: "McAndrews",
      zip_code: "41543",
      state_id: "KY",
      state_name: "Kentucky",
    },
    {
      city_id: "13669",
      city: "McCarr",
      zip_code: "41544",
      state_id: "KY",
      state_name: "Kentucky",
    },
    {
      city_id: "13709",
      city: "McDowell",
      zip_code: "41647",
      state_id: "KY",
      state_name: "Kentucky",
    },
    {
      city_id: "13369",
      city: "Means",
      zip_code: "40346",
      state_id: "KY",
      state_name: "Kentucky",
    },
    {
      city_id: "13370",
      city: "Midway",
      zip_code: "40347",
      state_id: "KY",
      state_name: "Kentucky",
    },
    {
      city_id: "13371",
      city: "Millersburg",
      zip_code: "40348",
      state_id: "KY",
      state_name: "Kentucky",
    },
    {
      city_id: "13712",
      city: "Minnie",
      zip_code: "41651",
      state_id: "KY",
      state_name: "Kentucky",
    },
    {
      city_id: "13373",
      city: "Morehead",
      zip_code: "40351",
      state_id: "KY",
      state_name: "Kentucky",
    },
    {
      city_id: "13265",
      city: "Mount Eden",
      zip_code: "40046",
      state_id: "KY",
      state_name: "Kentucky",
    },
    {
      city_id: "13551",
      city: "Mount Olivet",
      zip_code: "41064",
      state_id: "KY",
      state_name: "Kentucky",
    },
    {
      city_id: "13374",
      city: "Mount Sterling",
      zip_code: "40353",
      state_id: "KY",
      state_name: "Kentucky",
    },
    {
      city_id: "13671",
      city: "Mouthcard",
      zip_code: "41548",
      state_id: "KY",
      state_name: "Kentucky",
    },
    {
      city_id: "13376",
      city: "Nicholasville",
      zip_code: "40356",
      state_id: "KY",
      state_name: "Kentucky",
    },
    {
      city_id: "13377",
      city: "North Middletown",
      zip_code: "40357",
      state_id: "KY",
      state_name: "Kentucky",
    },
    {
      city_id: "13583",
      city: "Olive Hill",
      zip_code: "41164",
      state_id: "KY",
      state_name: "Kentucky",
    },
    {
      city_id: "13378",
      city: "Olympia",
      zip_code: "40358",
      state_id: "KY",
      state_name: "Kentucky",
    },
    {
      city_id: "13380",
      city: "Owingsville",
      zip_code: "40360",
      state_id: "KY",
      state_name: "Kentucky",
    },
    {
      city_id: "13609",
      city: "Paintsville",
      zip_code: "41240",
      state_id: "KY",
      state_name: "Kentucky",
    },
    {
      city_id: "13381",
      city: "Paris",
      zip_code: "40361",
      state_id: "KY",
      state_name: "Kentucky",
    },
    {
      city_id: "13672",
      city: "Phelps",
      zip_code: "41553",
      state_id: "KY",
      state_name: "Kentucky",
    },
    {
      city_id: "13673",
      city: "Phyllis",
      zip_code: "41554",
      state_id: "KY",
      state_name: "Kentucky",
    },
    {
      city_id: "13649",
      city: "Pikeville",
      zip_code: "41501",
      state_id: "KY",
      state_name: "Kentucky",
    },
    {
      city_id: "13610",
      city: "Pilgrim",
      zip_code: "41250",
      state_id: "KY",
      state_name: "Kentucky",
    },
    {
      city_id: "13634",
      city: "Pine Ridge",
      zip_code: "41360",
      state_id: "KY",
      state_name: "Kentucky",
    },
    {
      city_id: "13776",
      city: "Pine Top",
      zip_code: "41843",
      state_id: "KY",
      state_name: "Kentucky",
    },
    {
      city_id: "13674",
      city: "Pinsonfork",
      zip_code: "41555",
      state_id: "KY",
      state_name: "Kentucky",
    },
    {
      city_id: "13777",
      city: "Pippa Passes",
      zip_code: "41844",
      state_id: "KY",
      state_name: "Kentucky",
    },
    {
      city_id: "13273",
      city: "Pleasureville",
      zip_code: "40057",
      state_id: "KY",
      state_name: "Kentucky",
    },
    {
      city_id: "13713",
      city: "Prestonsburg",
      zip_code: "41653",
      state_id: "KY",
      state_name: "Kentucky",
    },
    {
      city_id: "13714",
      city: "Printer",
      zip_code: "41655",
      state_id: "KY",
      state_name: "Kentucky",
    },
    {
      city_id: "13676",
      city: "Ransom",
      zip_code: "41558",
      state_id: "KY",
      state_name: "Kentucky",
    },
    {
      city_id: "13414",
      city: "Ravenna",
      zip_code: "40472",
      state_id: "KY",
      state_name: "Kentucky",
    },
    {
      city_id: "13415",
      city: "Richmond",
      zip_code: "40475",
      state_id: "KY",
      state_name: "Kentucky",
    },
    {
      city_id: "13636",
      city: "Rogers",
      zip_code: "41365",
      state_id: "KY",
      state_name: "Kentucky",
    },
    {
      city_id: "13585",
      city: "Rush",
      zip_code: "41168",
      state_id: "KY",
      state_name: "Kentucky",
    },
    {
      city_id: "13586",
      city: "Russell",
      zip_code: "41169",
      state_id: "KY",
      state_name: "Kentucky",
    },
    {
      city_id: "13383",
      city: "Sadieville",
      zip_code: "40370",
      state_id: "KY",
      state_name: "Kentucky",
    },
    {
      city_id: "13384",
      city: "Salt Lick",
      zip_code: "40371",
      state_id: "KY",
      state_name: "Kentucky",
    },
    {
      city_id: "13385",
      city: "Salvisa",
      zip_code: "40372",
      state_id: "KY",
      state_name: "Kentucky",
    },
    {
      city_id: "13741",
      city: "Sassafras",
      zip_code: "41759",
      state_id: "KY",
      state_name: "Kentucky",
    },
    {
      city_id: "13511",
      city: "Sextons Creek",
      zip_code: "40983",
      state_id: "KY",
      state_name: "Kentucky",
    },
    {
      city_id: "13386",
      city: "Sharpsburg",
      zip_code: "40374",
      state_id: "KY",
      state_name: "Kentucky",
    },
    {
      city_id: "13679",
      city: "Shelbiana",
      zip_code: "41562",
      state_id: "KY",
      state_name: "Kentucky",
    },
    {
      city_id: "13280",
      city: "Shelbyville",
      zip_code: "40065",
      state_id: "KY",
      state_name: "Kentucky",
    },
    {
      city_id: "13314",
      city: "Shepherdsville",
      zip_code: "40165",
      state_id: "KY",
      state_name: "Kentucky",
    },
    {
      city_id: "13681",
      city: "Sidney",
      zip_code: "41564",
      state_id: "KY",
      state_name: "Kentucky",
    },
    {
      city_id: "13387",
      city: "Slade",
      zip_code: "40376",
      state_id: "KY",
      state_name: "Kentucky",
    },
    {
      city_id: "13961",
      city: "Somerset",
      zip_code: "42501",
      state_id: "KY",
      state_name: "Kentucky",
    },
    {
      city_id: "13588",
      city: "South Portsmouth",
      zip_code: "41174",
      state_id: "KY",
      state_name: "Kentucky",
    },
    {
      city_id: "13589",
      city: "South Shore",
      zip_code: "41175",
      state_id: "KY",
      state_name: "Kentucky",
    },
    {
      city_id: "13650",
      city: "South Williamson",
      zip_code: "41503",
      state_id: "KY",
      state_name: "Kentucky",
    },
    {
      city_id: "13388",
      city: "Stamping Ground",
      zip_code: "40379",
      state_id: "KY",
      state_name: "Kentucky",
    },
    {
      city_id: "13389",
      city: "Stanton",
      zip_code: "40380",
      state_id: "KY",
      state_name: "Kentucky",
    },
    {
      city_id: "13715",
      city: "Stanville",
      zip_code: "41659",
      state_id: "KY",
      state_name: "Kentucky",
    },
    {
      city_id: "13682",
      city: "Steele",
      zip_code: "41566",
      state_id: "KY",
      state_name: "Kentucky",
    },
    {
      city_id: "13683",
      city: "Stone",
      zip_code: "41567",
      state_id: "KY",
      state_name: "Kentucky",
    },
    {
      city_id: "13684",
      city: "Stopover",
      zip_code: "41568",
      state_id: "KY",
      state_name: "Kentucky",
    },
    {
      city_id: "13716",
      city: "Teaberry",
      zip_code: "41660",
      state_id: "KY",
      state_name: "Kentucky",
    },
    {
      city_id: "13594",
      city: "Tollesboro",
      zip_code: "41189",
      state_id: "KY",
      state_name: "Kentucky",
    },
    {
      city_id: "13616",
      city: "Tomahawk",
      zip_code: "41262",
      state_id: "KY",
      state_name: "Kentucky",
    },
    {
      city_id: "13786",
      city: "Topmost",
      zip_code: "41862",
      state_id: "KY",
      state_name: "Kentucky",
    },
    {
      city_id: "13618",
      city: "Ulysses",
      zip_code: "41264",
      state_id: "KY",
      state_name: "Kentucky",
    },
    {
      city_id: "13619",
      city: "Van Lear",
      zip_code: "41265",
      state_id: "KY",
      state_name: "Kentucky",
    },
    {
      city_id: "13590",
      city: "Vanceburg",
      zip_code: "41179",
      state_id: "KY",
      state_name: "Kentucky",
    },
    {
      city_id: "13685",
      city: "Varney",
      zip_code: "41571",
      state_id: "KY",
      state_name: "Kentucky",
    },
    {
      city_id: "13390",
      city: "Versailles",
      zip_code: "40383",
      state_id: "KY",
      state_name: "Kentucky",
    },
    {
      city_id: "13640",
      city: "Vincent",
      zip_code: "41386",
      state_id: "KY",
      state_name: "Kentucky",
    },
    {
      city_id: "13686",
      city: "Virgie",
      zip_code: "41572",
      state_id: "KY",
      state_name: "Kentucky",
    },
    {
      city_id: "13391",
      city: "Waco",
      zip_code: "40385",
      state_id: "KY",
      state_name: "Kentucky",
    },
    {
      city_id: "13287",
      city: "Waddy",
      zip_code: "40076",
      state_id: "KY",
      state_name: "Kentucky",
    },
    {
      city_id: "13620",
      city: "Warfield",
      zip_code: "41267",
      state_id: "KY",
      state_name: "Kentucky",
    },
    {
      city_id: "13718",
      city: "Wayland",
      zip_code: "41666",
      state_id: "KY",
      state_name: "Kentucky",
    },
    {
      city_id: "13591",
      city: "Webbville",
      zip_code: "41180",
      state_id: "KY",
      state_name: "Kentucky",
    },
    {
      city_id: "13392",
      city: "Wellington",
      zip_code: "40387",
      state_id: "KY",
      state_name: "Kentucky",
    },
    {
      city_id: "13648",
      city: "West Liberty",
      zip_code: "41472",
      state_id: "KY",
      state_name: "Kentucky",
    },
    {
      city_id: "13720",
      city: "Wheelwright",
      zip_code: "41669",
      state_id: "KY",
      state_name: "Kentucky",
    },
    {
      city_id: "13566",
      city: "Williamstown",
      zip_code: "41097",
      state_id: "KY",
      state_name: "Kentucky",
    },
    {
      city_id: "13393",
      city: "Wilmore",
      zip_code: "40390",
      state_id: "KY",
      state_name: "Kentucky",
    },
    {
      city_id: "13394",
      city: "Winchester",
      zip_code: "40391",
      state_id: "KY",
      state_name: "Kentucky",
    },
    {
      city_id: "13593",
      city: "Worthington",
      zip_code: "41183",
      state_id: "KY",
      state_name: "Kentucky",
    },
    {
      city_id: "13642",
      city: "Zoe",
      zip_code: "41397",
      state_id: "KY",
      state_name: "Kentucky",
    },
    {
      city_id: "542",
      city: "Abington",
      zip_code: "02351",
      state_id: "MA",
      state_name: "Massachusetts",
    },
    {
      city_id: "355",
      city: "Acton",
      zip_code: "01718",
      state_id: "MA",
      state_name: "Massachusetts",
    },
    {
      city_id: "658",
      city: "Acushnet",
      zip_code: "02743",
      state_id: "MA",
      state_name: "Massachusetts",
    },
    {
      city_id: "215",
      city: "Adams",
      zip_code: "01220",
      state_id: "MA",
      state_name: "Massachusetts",
    },
    {
      city_id: "139",
      city: "Agawam",
      zip_code: "01001",
      state_id: "MA",
      state_name: "Massachusetts",
    },
    {
      city_id: "493",
      city: "Allston",
      zip_code: "02134",
      state_id: "MA",
      state_name: "Massachusetts",
    },
    {
      city_id: "419",
      city: "Amesbury",
      zip_code: "01913",
      state_id: "MA",
      state_name: "Massachusetts",
    },
    {
      city_id: "140",
      city: "Amherst",
      zip_code: "01002",
      state_id: "MA",
      state_name: "Massachusetts",
    },
    {
      city_id: "382",
      city: "Andover",
      zip_code: "01810",
      state_id: "MA",
      state_name: "Massachusetts",
    },
    {
      city_id: "574",
      city: "Arlington",
      zip_code: "02474",
      state_id: "MA",
      state_name: "Massachusetts",
    },
    {
      city_id: "277",
      city: "Ashburnham",
      zip_code: "01430",
      state_id: "MA",
      state_name: "Massachusetts",
    },
    {
      city_id: "278",
      city: "Ashby",
      zip_code: "01431",
      state_id: "MA",
      state_name: "Massachusetts",
    },
    {
      city_id: "247",
      city: "Ashfield",
      zip_code: "01330",
      state_id: "MA",
      state_name: "Massachusetts",
    },
    {
      city_id: "358",
      city: "Ashland",
      zip_code: "01721",
      state_id: "MA",
      state_name: "Massachusetts",
    },
    {
      city_id: "216",
      city: "Ashley Falls",
      zip_code: "01222",
      state_id: "MA",
      state_name: "Massachusetts",
    },
    {
      city_id: "642",
      city: "Assonet",
      zip_code: "02702",
      state_id: "MA",
      state_name: "Massachusetts",
    },
    {
      city_id: "248",
      city: "Athol",
      zip_code: "01331",
      state_id: "MA",
      state_name: "Massachusetts",
    },
    {
      city_id: "643",
      city: "Attleboro",
      zip_code: "02703",
      state_id: "MA",
      state_name: "Massachusetts",
    },
    {
      city_id: "666",
      city: "Attleboro Falls",
      zip_code: "02763",
      state_id: "MA",
      state_name: "Massachusetts",
    },
    {
      city_id: "297",
      city: "Auburn",
      zip_code: "01501",
      state_id: "MA",
      state_name: "Massachusetts",
    },
    {
      city_id: "570",
      city: "Auburndale",
      zip_code: "02466",
      state_id: "MA",
      state_name: "Massachusetts",
    },
    {
      city_id: "529",
      city: "Avon",
      zip_code: "02322",
      state_id: "MA",
      state_name: "Massachusetts",
    },
    {
      city_id: "279",
      city: "Ayer",
      zip_code: "01432",
      state_id: "MA",
      state_name: "Massachusetts",
    },
    {
      city_id: "562",
      city: "Babson Park",
      zip_code: "02457",
      state_id: "MA",
      state_name: "Massachusetts",
    },
    {
      city_id: "281",
      city: "Baldwinville",
      zip_code: "01436",
      state_id: "MA",
      state_name: "Massachusetts",
    },
    {
      city_id: "608",
      city: "Barnstable",
      zip_code: "02630",
      state_id: "MA",
      state_name: "Massachusetts",
    },
    {
      city_id: "142",
      city: "Barre",
      zip_code: "01005",
      state_id: "MA",
      state_name: "Massachusetts",
    },
    {
      city_id: "217",
      city: "Becket",
      zip_code: "01223",
      state_id: "MA",
      state_name: "Massachusetts",
    },
    {
      city_id: "359",
      city: "Bedford",
      zip_code: "01730",
      state_id: "MA",
      state_name: "Massachusetts",
    },
    {
      city_id: "143",
      city: "Belchertown",
      zip_code: "01007",
      state_id: "MA",
      state_name: "Massachusetts",
    },
    {
      city_id: "444",
      city: "Bellingham",
      zip_code: "02019",
      state_id: "MA",
      state_name: "Massachusetts",
    },
    {
      city_id: "576",
      city: "Belmont",
      zip_code: "02478",
      state_id: "MA",
      state_name: "Massachusetts",
    },
    {
      city_id: "674",
      city: "Berkley",
      zip_code: "02779",
      state_id: "MA",
      state_name: "Massachusetts",
    },
    {
      city_id: "218",
      city: "Berkshire",
      zip_code: "01224",
      state_id: "MA",
      state_name: "Massachusetts",
    },
    {
      city_id: "298",
      city: "Berlin",
      zip_code: "01503",
      state_id: "MA",
      state_name: "Massachusetts",
    },
    {
      city_id: "249",
      city: "Bernardston",
      zip_code: "01337",
      state_id: "MA",
      state_name: "Massachusetts",
    },
    {
      city_id: "420",
      city: "Beverly",
      zip_code: "01915",
      state_id: "MA",
      state_name: "Massachusetts",
    },
    {
      city_id: "383",
      city: "Billerica",
      zip_code: "01821",
      state_id: "MA",
      state_name: "Massachusetts",
    },
    {
      city_id: "299",
      city: "Blackstone",
      zip_code: "01504",
      state_id: "MA",
      state_name: "Massachusetts",
    },
    {
      city_id: "144",
      city: "Blandford",
      zip_code: "01008",
      state_id: "MA",
      state_name: "Massachusetts",
    },
    {
      city_id: "361",
      city: "Bolton",
      zip_code: "01740",
      state_id: "MA",
      state_name: "Massachusetts",
    },
    {
      city_id: "145",
      city: "Bondsville",
      zip_code: "01009",
      state_id: "MA",
      state_name: "Massachusetts",
    },
    {
      city_id: "470",
      city: "Boston",
      zip_code: "02108",
      state_id: "MA",
      state_name: "Massachusetts",
    },
    {
      city_id: "356",
      city: "Boxborough",
      zip_code: "01719",
      state_id: "MA",
      state_name: "Massachusetts",
    },
    {
      city_id: "421",
      city: "Boxford",
      zip_code: "01921",
      state_id: "MA",
      state_name: "Massachusetts",
    },
    {
      city_id: "300",
      city: "Boylston",
      zip_code: "01505",
      state_id: "MA",
      state_name: "Massachusetts",
    },
    {
      city_id: "516",
      city: "Braintree",
      zip_code: "02184",
      state_id: "MA",
      state_name: "Massachusetts",
    },
    {
      city_id: "609",
      city: "Brewster",
      zip_code: "02631",
      state_id: "MA",
      state_name: "Massachusetts",
    },
    {
      city_id: "530",
      city: "Bridgewater",
      zip_code: "02324",
      state_id: "MA",
      state_name: "Massachusetts",
    },
    {
      city_id: "494",
      city: "Brighton",
      zip_code: "02135",
      state_id: "MA",
      state_name: "Massachusetts",
    },
    {
      city_id: "146",
      city: "Brimfield",
      zip_code: "01010",
      state_id: "MA",
      state_name: "Massachusetts",
    },
    {
      city_id: "527",
      city: "Brockton",
      zip_code: "02301",
      state_id: "MA",
      state_name: "Massachusetts",
    },
    {
      city_id: "301",
      city: "Brookfield",
      zip_code: "01506",
      state_id: "MA",
      state_name: "Massachusetts",
    },
    {
      city_id: "557",
      city: "Brookline",
      zip_code: "02445",
      state_id: "MA",
      state_name: "Massachusetts",
    },
    {
      city_id: "250",
      city: "Buckland",
      zip_code: "01338",
      state_id: "MA",
      state_name: "Massachusetts",
    },
    {
      city_id: "381",
      city: "Burlington",
      zip_code: "01803",
      state_id: "MA",
      state_name: "Massachusetts",
    },
    {
      city_id: "582",
      city: "Buzzards Bay",
      zip_code: "02532",
      state_id: "MA",
      state_name: "Massachusetts",
    },
    {
      city_id: "422",
      city: "Byfield",
      zip_code: "01922",
      state_id: "MA",
      state_name: "Massachusetts",
    },
    {
      city_id: "496",
      city: "Cambridge",
      zip_code: "02138",
      state_id: "MA",
      state_name: "Massachusetts",
    },
    {
      city_id: "445",
      city: "Canton",
      zip_code: "02021",
      state_id: "MA",
      state_name: "Massachusetts",
    },
    {
      city_id: "362",
      city: "Carlisle",
      zip_code: "01741",
      state_id: "MA",
      state_name: "Massachusetts",
    },
    {
      city_id: "532",
      city: "Carver",
      zip_code: "02330",
      state_id: "MA",
      state_name: "Massachusetts",
    },
    {
      city_id: "583",
      city: "Cataumet",
      zip_code: "02534",
      state_id: "MA",
      state_name: "Massachusetts",
    },
    {
      city_id: "610",
      city: "Centerville",
      zip_code: "02632",
      state_id: "MA",
      state_name: "Massachusetts",
    },
    {
      city_id: "251",
      city: "Charlemont",
      zip_code: "01339",
      state_id: "MA",
      state_name: "Massachusetts",
    },
    {
      city_id: "488",
      city: "Charlestown",
      zip_code: "02129",
      state_id: "MA",
      state_name: "Massachusetts",
    },
    {
      city_id: "302",
      city: "Charlton",
      zip_code: "01507",
      state_id: "MA",
      state_name: "Massachusetts",
    },
    {
      city_id: "611",
      city: "Chatham",
      zip_code: "02633",
      state_id: "MA",
      state_name: "Massachusetts",
    },
    {
      city_id: "384",
      city: "Chelmsford",
      zip_code: "01824",
      state_id: "MA",
      state_name: "Massachusetts",
    },
    {
      city_id: "506",
      city: "Chelsea",
      zip_code: "02150",
      state_id: "MA",
      state_name: "Massachusetts",
    },
    {
      city_id: "351",
      city: "Cherry Valley",
      zip_code: "01611",
      state_id: "MA",
      state_name: "Massachusetts",
    },
    {
      city_id: "219",
      city: "Cheshire",
      zip_code: "01225",
      state_id: "MA",
      state_name: "Massachusetts",
    },
    {
      city_id: "147",
      city: "Chester",
      zip_code: "01011",
      state_id: "MA",
      state_name: "Massachusetts",
    },
    {
      city_id: "148",
      city: "Chesterfield",
      zip_code: "01012",
      state_id: "MA",
      state_name: "Massachusetts",
    },
    {
      city_id: "571",
      city: "Chestnut Hill",
      zip_code: "02467",
      state_id: "MA",
      state_name: "Massachusetts",
    },
    {
      city_id: "149",
      city: "Chicopee",
      zip_code: "01013",
      state_id: "MA",
      state_name: "Massachusetts",
    },
    {
      city_id: "584",
      city: "Chilmark",
      zip_code: "02535",
      state_id: "MA",
      state_name: "Massachusetts",
    },
    {
      city_id: "303",
      city: "Clinton",
      zip_code: "01510",
      state_id: "MA",
      state_name: "Massachusetts",
    },
    {
      city_id: "446",
      city: "Cohasset",
      zip_code: "02025",
      state_id: "MA",
      state_name: "Massachusetts",
    },
    {
      city_id: "252",
      city: "Colrain",
      zip_code: "01340",
      state_id: "MA",
      state_name: "Massachusetts",
    },
    {
      city_id: "363",
      city: "Concord",
      zip_code: "01742",
      state_id: "MA",
      state_name: "Massachusetts",
    },
    {
      city_id: "253",
      city: "Conway",
      zip_code: "01341",
      state_id: "MA",
      state_name: "Massachusetts",
    },
    {
      city_id: "612",
      city: "Cotuit",
      zip_code: "02635",
      state_id: "MA",
      state_name: "Massachusetts",
    },
    {
      city_id: "613",
      city: "Cummaquid",
      zip_code: "02637",
      state_id: "MA",
      state_name: "Massachusetts",
    },
    {
      city_id: "152",
      city: "Cummington",
      zip_code: "01026",
      state_id: "MA",
      state_name: "Massachusetts",
    },
    {
      city_id: "644",
      city: "Cuttyhunk",
      zip_code: "02713",
      state_id: "MA",
      state_name: "Massachusetts",
    },
    {
      city_id: "220",
      city: "Dalton",
      zip_code: "01226",
      state_id: "MA",
      state_name: "Massachusetts",
    },
    {
      city_id: "423",
      city: "Danvers",
      zip_code: "01923",
      state_id: "MA",
      state_name: "Massachusetts",
    },
    {
      city_id: "447",
      city: "Dedham",
      zip_code: "02026",
      state_id: "MA",
      state_name: "Massachusetts",
    },
    {
      city_id: "254",
      city: "Deerfield",
      zip_code: "01342",
      state_id: "MA",
      state_name: "Massachusetts",
    },
    {
      city_id: "614",
      city: "Dennis",
      zip_code: "02638",
      state_id: "MA",
      state_name: "Massachusetts",
    },
    {
      city_id: "615",
      city: "Dennis Port",
      zip_code: "02639",
      state_id: "MA",
      state_name: "Massachusetts",
    },
    {
      city_id: "280",
      city: "Devens",
      zip_code: "01434",
      state_id: "MA",
      state_name: "Massachusetts",
    },
    {
      city_id: "645",
      city: "Dighton",
      zip_code: "02715",
      state_id: "MA",
      state_name: "Massachusetts",
    },
    {
      city_id: "481",
      city: "Dorchester",
      zip_code: "02121",
      state_id: "MA",
      state_name: "Massachusetts",
    },
    {
      city_id: "483",
      city: "Dorchester Center",
      zip_code: "02124",
      state_id: "MA",
      state_name: "Massachusetts",
    },
    {
      city_id: "305",
      city: "Douglas",
      zip_code: "01516",
      state_id: "MA",
      state_name: "Massachusetts",
    },
    {
      city_id: "448",
      city: "Dover",
      zip_code: "02030",
      state_id: "MA",
      state_name: "Massachusetts",
    },
    {
      city_id: "385",
      city: "Dracut",
      zip_code: "01826",
      state_id: "MA",
      state_name: "Massachusetts",
    },
    {
      city_id: "255",
      city: "Drury",
      zip_code: "01343",
      state_id: "MA",
      state_name: "Massachusetts",
    },
    {
      city_id: "336",
      city: "Dudley",
      zip_code: "01571",
      state_id: "MA",
      state_name: "Massachusetts",
    },
    {
      city_id: "386",
      city: "Dunstable",
      zip_code: "01827",
      state_id: "MA",
      state_name: "Massachusetts",
    },
    {
      city_id: "533",
      city: "Duxbury",
      zip_code: "02332",
      state_id: "MA",
      state_name: "Massachusetts",
    },
    {
      city_id: "487",
      city: "East Boston",
      zip_code: "02128",
      state_id: "MA",
      state_name: "Massachusetts",
    },
    {
      city_id: "534",
      city: "East Bridgewater",
      zip_code: "02333",
      state_id: "MA",
      state_name: "Massachusetts",
    },
    {
      city_id: "304",
      city: "East Brookfield",
      zip_code: "01515",
      state_id: "MA",
      state_name: "Massachusetts",
    },
    {
      city_id: "616",
      city: "East Dennis",
      zip_code: "02641",
      state_id: "MA",
      state_name: "Massachusetts",
    },
    {
      city_id: "585",
      city: "East Falmouth",
      zip_code: "02536",
      state_id: "MA",
      state_name: "Massachusetts",
    },
    {
      city_id: "646",
      city: "East Freetown",
      zip_code: "02717",
      state_id: "MA",
      state_name: "Massachusetts",
    },
    {
      city_id: "154",
      city: "East Longmeadow",
      zip_code: "01028",
      state_id: "MA",
      state_name: "Massachusetts",
    },
    {
      city_id: "618",
      city: "East Orleans",
      zip_code: "02643",
      state_id: "MA",
      state_name: "Massachusetts",
    },
    {
      city_id: "155",
      city: "East Otis",
      zip_code: "01029",
      state_id: "MA",
      state_name: "Massachusetts",
    },
    {
      city_id: "586",
      city: "East Sandwich",
      zip_code: "02537",
      state_id: "MA",
      state_name: "Massachusetts",
    },
    {
      city_id: "647",
      city: "East Taunton",
      zip_code: "02718",
      state_id: "MA",
      state_name: "Massachusetts",
    },
    {
      city_id: "282",
      city: "East Templeton",
      zip_code: "01438",
      state_id: "MA",
      state_name: "Massachusetts",
    },
    {
      city_id: "449",
      city: "East Walpole",
      zip_code: "02032",
      state_id: "MA",
      state_name: "Massachusetts",
    },
    {
      city_id: "587",
      city: "East Wareham",
      zip_code: "02538",
      state_id: "MA",
      state_name: "Massachusetts",
    },
    {
      city_id: "519",
      city: "East Weymouth",
      zip_code: "02189",
      state_id: "MA",
      state_name: "Massachusetts",
    },
    {
      city_id: "617",
      city: "Eastham",
      zip_code: "02642",
      state_id: "MA",
      state_name: "Massachusetts",
    },
    {
      city_id: "153",
      city: "Easthampton",
      zip_code: "01027",
      state_id: "MA",
      state_name: "Massachusetts",
    },
    {
      city_id: "588",
      city: "Edgartown",
      zip_code: "02539",
      state_id: "MA",
      state_name: "Massachusetts",
    },
    {
      city_id: "256",
      city: "Erving",
      zip_code: "01344",
      state_id: "MA",
      state_name: "Massachusetts",
    },
    {
      city_id: "424",
      city: "Essex",
      zip_code: "01929",
      state_id: "MA",
      state_name: "Massachusetts",
    },
    {
      city_id: "505",
      city: "Everett",
      zip_code: "02149",
      state_id: "MA",
      state_name: "Massachusetts",
    },
    {
      city_id: "648",
      city: "Fairhaven",
      zip_code: "02719",
      state_id: "MA",
      state_name: "Massachusetts",
    },
    {
      city_id: "649",
      city: "Fall River",
      zip_code: "02720",
      state_id: "MA",
      state_name: "Massachusetts",
    },
    {
      city_id: "589",
      city: "Falmouth",
      zip_code: "02540",
      state_id: "MA",
      state_name: "Massachusetts",
    },
    {
      city_id: "364",
      city: "Fayville",
      zip_code: "01745",
      state_id: "MA",
      state_name: "Massachusetts",
    },
    {
      city_id: "156",
      city: "Feeding Hills",
      zip_code: "01030",
      state_id: "MA",
      state_name: "Massachusetts",
    },
    {
      city_id: "306",
      city: "Fiskdale",
      zip_code: "01518",
      state_id: "MA",
      state_name: "Massachusetts",
    },
    {
      city_id: "276",
      city: "Fitchburg",
      zip_code: "01420",
      state_id: "MA",
      state_name: "Massachusetts",
    },
    {
      city_id: "173",
      city: "Florence",
      zip_code: "01062",
      state_id: "MA",
      state_name: "Massachusetts",
    },
    {
      city_id: "619",
      city: "Forestdale",
      zip_code: "02644",
      state_id: "MA",
      state_name: "Massachusetts",
    },
    {
      city_id: "450",
      city: "Foxboro",
      zip_code: "02035",
      state_id: "MA",
      state_name: "Massachusetts",
    },
    {
      city_id: "353",
      city: "Framingham",
      zip_code: "01701",
      state_id: "MA",
      state_name: "Massachusetts",
    },
    {
      city_id: "451",
      city: "Franklin",
      zip_code: "02038",
      state_id: "MA",
      state_name: "Massachusetts",
    },
    {
      city_id: "283",
      city: "Gardner",
      zip_code: "01440",
      state_id: "MA",
      state_name: "Massachusetts",
    },
    {
      city_id: "389",
      city: "Georgetown",
      zip_code: "01833",
      state_id: "MA",
      state_name: "Massachusetts",
    },
    {
      city_id: "157",
      city: "Gilbertville",
      zip_code: "01031",
      state_id: "MA",
      state_name: "Massachusetts",
    },
    {
      city_id: "262",
      city: "Gill",
      zip_code: "01354",
      state_id: "MA",
      state_name: "Massachusetts",
    },
    {
      city_id: "221",
      city: "Glendale",
      zip_code: "01229",
      state_id: "MA",
      state_name: "Massachusetts",
    },
    {
      city_id: "425",
      city: "Gloucester",
      zip_code: "01930",
      state_id: "MA",
      state_name: "Massachusetts",
    },
    {
      city_id: "158",
      city: "Goshen",
      zip_code: "01032",
      state_id: "MA",
      state_name: "Massachusetts",
    },
    {
      city_id: "307",
      city: "Grafton",
      zip_code: "01519",
      state_id: "MA",
      state_name: "Massachusetts",
    },
    {
      city_id: "159",
      city: "Granby",
      zip_code: "01033",
      state_id: "MA",
      state_name: "Massachusetts",
    },
    {
      city_id: "160",
      city: "Granville",
      zip_code: "01034",
      state_id: "MA",
      state_name: "Massachusetts",
    },
    {
      city_id: "222",
      city: "Great Barrington",
      zip_code: "01230",
      state_id: "MA",
      state_name: "Massachusetts",
    },
    {
      city_id: "246",
      city: "Greenfield",
      zip_code: "01301",
      state_id: "MA",
      state_name: "Massachusetts",
    },
    {
      city_id: "284",
      city: "Groton",
      zip_code: "01450",
      state_id: "MA",
      state_name: "Massachusetts",
    },
    {
      city_id: "390",
      city: "Groveland",
      zip_code: "01834",
      state_id: "MA",
      state_name: "Massachusetts",
    },
    {
      city_id: "161",
      city: "Hadley",
      zip_code: "01035",
      state_id: "MA",
      state_name: "Massachusetts",
    },
    {
      city_id: "535",
      city: "Halifax",
      zip_code: "02338",
      state_id: "MA",
      state_name: "Massachusetts",
    },
    {
      city_id: "162",
      city: "Hampden",
      zip_code: "01036",
      state_id: "MA",
      state_name: "Massachusetts",
    },
    {
      city_id: "536",
      city: "Hanover",
      zip_code: "02339",
      state_id: "MA",
      state_name: "Massachusetts",
    },
    {
      city_id: "360",
      city: "Hanscom Afb",
      zip_code: "01731",
      state_id: "MA",
      state_name: "Massachusetts",
    },
    {
      city_id: "537",
      city: "Hanson",
      zip_code: "02341",
      state_id: "MA",
      state_name: "Massachusetts",
    },
    {
      city_id: "163",
      city: "Hardwick",
      zip_code: "01037",
      state_id: "MA",
      state_name: "Massachusetts",
    },
    {
      city_id: "285",
      city: "Harvard",
      zip_code: "01451",
      state_id: "MA",
      state_name: "Massachusetts",
    },
    {
      city_id: "620",
      city: "Harwich",
      zip_code: "02645",
      state_id: "MA",
      state_name: "Massachusetts",
    },
    {
      city_id: "621",
      city: "Harwich Port",
      zip_code: "02646",
      state_id: "MA",
      state_name: "Massachusetts",
    },
    {
      city_id: "164",
      city: "Hatfield",
      zip_code: "01038",
      state_id: "MA",
      state_name: "Massachusetts",
    },
    {
      city_id: "426",
      city: "Hathorne",
      zip_code: "01937",
      state_id: "MA",
      state_name: "Massachusetts",
    },
    {
      city_id: "387",
      city: "Haverhill",
      zip_code: "01830",
      state_id: "MA",
      state_name: "Massachusetts",
    },
    {
      city_id: "165",
      city: "Haydenville",
      zip_code: "01039",
      state_id: "MA",
      state_name: "Massachusetts",
    },
    {
      city_id: "257",
      city: "Heath",
      zip_code: "01346",
      state_id: "MA",
      state_name: "Massachusetts",
    },
    {
      city_id: "452",
      city: "Hingham",
      zip_code: "02043",
      state_id: "MA",
      state_name: "Massachusetts",
    },
    {
      city_id: "223",
      city: "Hinsdale",
      zip_code: "01235",
      state_id: "MA",
      state_name: "Massachusetts",
    },
    {
      city_id: "538",
      city: "Holbrook",
      zip_code: "02343",
      state_id: "MA",
      state_name: "Massachusetts",
    },
    {
      city_id: "308",
      city: "Holden",
      zip_code: "01520",
      state_id: "MA",
      state_name: "Massachusetts",
    },
    {
      city_id: "309",
      city: "Holland",
      zip_code: "01521",
      state_id: "MA",
      state_name: "Massachusetts",
    },
    {
      city_id: "365",
      city: "Holliston",
      zip_code: "01746",
      state_id: "MA",
      state_name: "Massachusetts",
    },
    {
      city_id: "166",
      city: "Holyoke",
      zip_code: "01040",
      state_id: "MA",
      state_name: "Massachusetts",
    },
    {
      city_id: "366",
      city: "Hopedale",
      zip_code: "01747",
      state_id: "MA",
      state_name: "Massachusetts",
    },
    {
      city_id: "367",
      city: "Hopkinton",
      zip_code: "01748",
      state_id: "MA",
      state_name: "Massachusetts",
    },
    {
      city_id: "224",
      city: "Housatonic",
      zip_code: "01236",
      state_id: "MA",
      state_name: "Massachusetts",
    },
    {
      city_id: "286",
      city: "Hubbardston",
      zip_code: "01452",
      state_id: "MA",
      state_name: "Massachusetts",
    },
    {
      city_id: "368",
      city: "Hudson",
      zip_code: "01749",
      state_id: "MA",
      state_name: "Massachusetts",
    },
    {
      city_id: "453",
      city: "Hull",
      zip_code: "02045",
      state_id: "MA",
      state_name: "Massachusetts",
    },
    {
      city_id: "454",
      city: "Humarock",
      zip_code: "02047",
      state_id: "MA",
      state_name: "Massachusetts",
    },
    {
      city_id: "167",
      city: "Huntington",
      zip_code: "01050",
      state_id: "MA",
      state_name: "Massachusetts",
    },
    {
      city_id: "607",
      city: "Hyannis",
      zip_code: "02601",
      state_id: "MA",
      state_name: "Massachusetts",
    },
    {
      city_id: "622",
      city: "Hyannis Port",
      zip_code: "02647",
      state_id: "MA",
      state_name: "Massachusetts",
    },
    {
      city_id: "495",
      city: "Hyde Park",
      zip_code: "02136",
      state_id: "MA",
      state_name: "Massachusetts",
    },
    {
      city_id: "213",
      city: "Indian Orchard",
      zip_code: "01151",
      state_id: "MA",
      state_name: "Massachusetts",
    },
    {
      city_id: "427",
      city: "Ipswich",
      zip_code: "01938",
      state_id: "MA",
      state_name: "Massachusetts",
    },
    {
      city_id: "489",
      city: "Jamaica Plain",
      zip_code: "02130",
      state_id: "MA",
      state_name: "Massachusetts",
    },
    {
      city_id: "310",
      city: "Jefferson",
      zip_code: "01522",
      state_id: "MA",
      state_name: "Massachusetts",
    },
    {
      city_id: "547",
      city: "Kingston",
      zip_code: "02364",
      state_id: "MA",
      state_name: "Massachusetts",
    },
    {
      city_id: "258",
      city: "Lake Pleasant",
      zip_code: "01347",
      state_id: "MA",
      state_name: "Massachusetts",
    },
    {
      city_id: "540",
      city: "Lakeville",
      zip_code: "02347",
      state_id: "MA",
      state_name: "Massachusetts",
    },
    {
      city_id: "311",
      city: "Lancaster",
      zip_code: "01523",
      state_id: "MA",
      state_name: "Massachusetts",
    },
    {
      city_id: "225",
      city: "Lanesborough",
      zip_code: "01237",
      state_id: "MA",
      state_name: "Massachusetts",
    },
    {
      city_id: "392",
      city: "Lawrence",
      zip_code: "01840",
      state_id: "MA",
      state_name: "Massachusetts",
    },
    {
      city_id: "226",
      city: "Lee",
      zip_code: "01238",
      state_id: "MA",
      state_name: "Massachusetts",
    },
    {
      city_id: "168",
      city: "Leeds",
      zip_code: "01053",
      state_id: "MA",
      state_name: "Massachusetts",
    },
    {
      city_id: "312",
      city: "Leicester",
      zip_code: "01524",
      state_id: "MA",
      state_name: "Massachusetts",
    },
    {
      city_id: "227",
      city: "Lenox",
      zip_code: "01240",
      state_id: "MA",
      state_name: "Massachusetts",
    },
    {
      city_id: "228",
      city: "Lenox Dale",
      zip_code: "01242",
      state_id: "MA",
      state_name: "Massachusetts",
    },
    {
      city_id: "287",
      city: "Leominster",
      zip_code: "01453",
      state_id: "MA",
      state_name: "Massachusetts",
    },
    {
      city_id: "169",
      city: "Leverett",
      zip_code: "01054",
      state_id: "MA",
      state_name: "Massachusetts",
    },
    {
      city_id: "555",
      city: "Lexington",
      zip_code: "02420",
      state_id: "MA",
      state_name: "Massachusetts",
    },
    {
      city_id: "376",
      city: "Lincoln",
      zip_code: "01773",
      state_id: "MA",
      state_name: "Massachusetts",
    },
    {
      city_id: "313",
      city: "Linwood",
      zip_code: "01525",
      state_id: "MA",
      state_name: "Massachusetts",
    },
    {
      city_id: "288",
      city: "Littleton",
      zip_code: "01460",
      state_id: "MA",
      state_name: "Massachusetts",
    },
    {
      city_id: "204",
      city: "Longmeadow",
      zip_code: "01106",
      state_id: "MA",
      state_name: "Massachusetts",
    },
    {
      city_id: "397",
      city: "Lowell",
      zip_code: "01850",
      state_id: "MA",
      state_name: "Massachusetts",
    },
    {
      city_id: "170",
      city: "Ludlow",
      zip_code: "01056",
      state_id: "MA",
      state_name: "Massachusetts",
    },
    {
      city_id: "289",
      city: "Lunenburg",
      zip_code: "01462",
      state_id: "MA",
      state_name: "Massachusetts",
    },
    {
      city_id: "412",
      city: "Lynn",
      zip_code: "01901",
      state_id: "MA",
      state_name: "Massachusetts",
    },
    {
      city_id: "428",
      city: "Lynnfield",
      zip_code: "01940",
      state_id: "MA",
      state_name: "Massachusetts",
    },
    {
      city_id: "504",
      city: "Malden",
      zip_code: "02148",
      state_id: "MA",
      state_name: "Massachusetts",
    },
    {
      city_id: "429",
      city: "Manchester",
      zip_code: "01944",
      state_id: "MA",
      state_name: "Massachusetts",
    },
    {
      city_id: "455",
      city: "Mansfield",
      zip_code: "02048",
      state_id: "MA",
      state_name: "Massachusetts",
    },
    {
      city_id: "430",
      city: "Marblehead",
      zip_code: "01945",
      state_id: "MA",
      state_name: "Massachusetts",
    },
    {
      city_id: "655",
      city: "Marion",
      zip_code: "02738",
      state_id: "MA",
      state_name: "Massachusetts",
    },
    {
      city_id: "369",
      city: "Marlborough",
      zip_code: "01752",
      state_id: "MA",
      state_name: "Massachusetts",
    },
    {
      city_id: "456",
      city: "Marshfield",
      zip_code: "02050",
      state_id: "MA",
      state_name: "Massachusetts",
    },
    {
      city_id: "623",
      city: "Marstons Mills",
      zip_code: "02648",
      state_id: "MA",
      state_name: "Massachusetts",
    },
    {
      city_id: "624",
      city: "Mashpee",
      zip_code: "02649",
      state_id: "MA",
      state_name: "Massachusetts",
    },
    {
      city_id: "485",
      city: "Mattapan",
      zip_code: "02126",
      state_id: "MA",
      state_name: "Massachusetts",
    },
    {
      city_id: "656",
      city: "Mattapoisett",
      zip_code: "02739",
      state_id: "MA",
      state_name: "Massachusetts",
    },
    {
      city_id: "370",
      city: "Maynard",
      zip_code: "01754",
      state_id: "MA",
      state_name: "Massachusetts",
    },
    {
      city_id: "457",
      city: "Medfield",
      zip_code: "02052",
      state_id: "MA",
      state_name: "Massachusetts",
    },
    {
      city_id: "509",
      city: "Medford",
      zip_code: "02155",
      state_id: "MA",
      state_name: "Massachusetts",
    },
    {
      city_id: "458",
      city: "Medway",
      zip_code: "02053",
      state_id: "MA",
      state_name: "Massachusetts",
    },
    {
      city_id: "514",
      city: "Melrose",
      zip_code: "02176",
      state_id: "MA",
      state_name: "Massachusetts",
    },
    {
      city_id: "371",
      city: "Mendon",
      zip_code: "01756",
      state_id: "MA",
      state_name: "Massachusetts",
    },
    {
      city_id: "401",
      city: "Merrimac",
      zip_code: "01860",
      state_id: "MA",
      state_name: "Massachusetts",
    },
    {
      city_id: "395",
      city: "Methuen",
      zip_code: "01844",
      state_id: "MA",
      state_name: "Massachusetts",
    },
    {
      city_id: "539",
      city: "Middleboro",
      zip_code: "02346",
      state_id: "MA",
      state_name: "Massachusetts",
    },
    {
      city_id: "229",
      city: "Middlefield",
      zip_code: "01243",
      state_id: "MA",
      state_name: "Massachusetts",
    },
    {
      city_id: "431",
      city: "Middleton",
      zip_code: "01949",
      state_id: "MA",
      state_name: "Massachusetts",
    },
    {
      city_id: "372",
      city: "Milford",
      zip_code: "01757",
      state_id: "MA",
      state_name: "Massachusetts",
    },
    {
      city_id: "230",
      city: "Mill River",
      zip_code: "01244",
      state_id: "MA",
      state_name: "Massachusetts",
    },
    {
      city_id: "314",
      city: "Millbury",
      zip_code: "01527",
      state_id: "MA",
      state_name: "Massachusetts",
    },
    {
      city_id: "259",
      city: "Millers Falls",
      zip_code: "01349",
      state_id: "MA",
      state_name: "Massachusetts",
    },
    {
      city_id: "459",
      city: "Millis",
      zip_code: "02054",
      state_id: "MA",
      state_name: "Massachusetts",
    },
    {
      city_id: "315",
      city: "Millville",
      zip_code: "01529",
      state_id: "MA",
      state_name: "Massachusetts",
    },
    {
      city_id: "517",
      city: "Milton",
      zip_code: "02186",
      state_id: "MA",
      state_name: "Massachusetts",
    },
    {
      city_id: "541",
      city: "Monponsett",
      zip_code: "02350",
      state_id: "MA",
      state_name: "Massachusetts",
    },
    {
      city_id: "260",
      city: "Monroe Bridge",
      zip_code: "01350",
      state_id: "MA",
      state_name: "Massachusetts",
    },
    {
      city_id: "171",
      city: "Monson",
      zip_code: "01057",
      state_id: "MA",
      state_name: "Massachusetts",
    },
    {
      city_id: "261",
      city: "Montague",
      zip_code: "01351",
      state_id: "MA",
      state_name: "Massachusetts",
    },
    {
      city_id: "231",
      city: "Monterey",
      zip_code: "01245",
      state_id: "MA",
      state_name: "Massachusetts",
    },
    {
      city_id: "592",
      city: "Monument Beach",
      zip_code: "02553",
      state_id: "MA",
      state_name: "Massachusetts",
    },
    {
      city_id: "418",
      city: "Nahant",
      zip_code: "01908",
      state_id: "MA",
      state_name: "Massachusetts",
    },
    {
      city_id: "593",
      city: "Nantucket",
      zip_code: "02554",
      state_id: "MA",
      state_name: "Massachusetts",
    },
    {
      city_id: "373",
      city: "Natick",
      zip_code: "01760",
      state_id: "MA",
      state_name: "Massachusetts",
    },
    {
      city_id: "579",
      city: "Needham",
      zip_code: "02492",
      state_id: "MA",
      state_name: "Massachusetts",
    },
    {
      city_id: "581",
      city: "Needham Heights",
      zip_code: "02494",
      state_id: "MA",
      state_name: "Massachusetts",
    },
    {
      city_id: "657",
      city: "New Bedford",
      zip_code: "02740",
      state_id: "MA",
      state_name: "Massachusetts",
    },
    {
      city_id: "316",
      city: "New Braintree",
      zip_code: "01531",
      state_id: "MA",
      state_name: "Massachusetts",
    },
    {
      city_id: "263",
      city: "New Salem",
      zip_code: "01355",
      state_id: "MA",
      state_name: "Massachusetts",
    },
    {
      city_id: "433",
      city: "Newbury",
      zip_code: "01951",
      state_id: "MA",
      state_name: "Massachusetts",
    },
    {
      city_id: "432",
      city: "Newburyport",
      zip_code: "01950",
      state_id: "MA",
      state_name: "Massachusetts",
    },
    {
      city_id: "563",
      city: "Newton",
      zip_code: "02458",
      state_id: "MA",
      state_name: "Massachusetts",
    },
    {
      city_id: "564",
      city: "Newton Center",
      zip_code: "02459",
      state_id: "MA",
      state_name: "Massachusetts",
    },
    {
      city_id: "566",
      city: "Newton Highlands",
      zip_code: "02461",
      state_id: "MA",
      state_name: "Massachusetts",
    },
    {
      city_id: "567",
      city: "Newton Lower Falls",
      zip_code: "02462",
      state_id: "MA",
      state_name: "Massachusetts",
    },
    {
      city_id: "568",
      city: "Newton Upper Falls",
      zip_code: "02464",
      state_id: "MA",
      state_name: "Massachusetts",
    },
    {
      city_id: "565",
      city: "Newtonville",
      zip_code: "02460",
      state_id: "MA",
      state_name: "Massachusetts",
    },
    {
      city_id: "460",
      city: "Norfolk",
      zip_code: "02056",
      state_id: "MA",
      state_name: "Massachusetts",
    },
    {
      city_id: "232",
      city: "North Adams",
      zip_code: "01247",
      state_id: "MA",
      state_name: "Massachusetts",
    },
    {
      city_id: "396",
      city: "North Andover",
      zip_code: "01845",
      state_id: "MA",
      state_name: "Massachusetts",
    },
    {
      city_id: "664",
      city: "North Attleboro",
      zip_code: "02760",
      state_id: "MA",
      state_name: "Massachusetts",
    },
    {
      city_id: "402",
      city: "North Billerica",
      zip_code: "01862",
      state_id: "MA",
      state_name: "Massachusetts",
    },
    {
      city_id: "319",
      city: "North Brookfield",
      zip_code: "01535",
      state_id: "MA",
      state_name: "Massachusetts",
    },
    {
      city_id: "625",
      city: "North Chatham",
      zip_code: "02650",
      state_id: "MA",
      state_name: "Massachusetts",
    },
    {
      city_id: "403",
      city: "North Chelmsford",
      zip_code: "01863",
      state_id: "MA",
      state_name: "Massachusetts",
    },
    {
      city_id: "662",
      city: "North Dartmouth",
      zip_code: "02747",
      state_id: "MA",
      state_name: "Massachusetts",
    },
    {
      city_id: "667",
      city: "North Dighton",
      zip_code: "02764",
      state_id: "MA",
      state_name: "Massachusetts",
    },
    {
      city_id: "543",
      city: "North Easton",
      zip_code: "02356",
      state_id: "MA",
      state_name: "Massachusetts",
    },
    {
      city_id: "594",
      city: "North Falmouth",
      zip_code: "02556",
      state_id: "MA",
      state_name: "Massachusetts",
    },
    {
      city_id: "320",
      city: "North Grafton",
      zip_code: "01536",
      state_id: "MA",
      state_name: "Massachusetts",
    },
    {
      city_id: "175",
      city: "North Hatfield",
      zip_code: "01066",
      state_id: "MA",
      state_name: "Massachusetts",
    },
    {
      city_id: "321",
      city: "North Oxford",
      zip_code: "01537",
      state_id: "MA",
      state_name: "Massachusetts",
    },
    {
      city_id: "404",
      city: "North Reading",
      zip_code: "01864",
      state_id: "MA",
      state_name: "Massachusetts",
    },
    {
      city_id: "626",
      city: "North Truro",
      zip_code: "02652",
      state_id: "MA",
      state_name: "Massachusetts",
    },
    {
      city_id: "521",
      city: "North Weymouth",
      zip_code: "02191",
      state_id: "MA",
      state_name: "Massachusetts",
    },
    {
      city_id: "172",
      city: "Northampton",
      zip_code: "01060",
      state_id: "MA",
      state_name: "Massachusetts",
    },
    {
      city_id: "317",
      city: "Northborough",
      zip_code: "01532",
      state_id: "MA",
      state_name: "Massachusetts",
    },
    {
      city_id: "318",
      city: "Northbridge",
      zip_code: "01534",
      state_id: "MA",
      state_name: "Massachusetts",
    },
    {
      city_id: "264",
      city: "Northfield",
      zip_code: "01360",
      state_id: "MA",
      state_name: "Massachusetts",
    },
    {
      city_id: "668",
      city: "Norton",
      zip_code: "02766",
      state_id: "MA",
      state_name: "Massachusetts",
    },
    {
      city_id: "461",
      city: "Norwell",
      zip_code: "02061",
      state_id: "MA",
      state_name: "Massachusetts",
    },
    {
      city_id: "462",
      city: "Norwood",
      zip_code: "02062",
      state_id: "MA",
      state_name: "Massachusetts",
    },
    {
      city_id: "595",
      city: "Oak Bluffs",
      zip_code: "02557",
      state_id: "MA",
      state_name: "Massachusetts",
    },
    {
      city_id: "176",
      city: "Oakham",
      zip_code: "01068",
      state_id: "MA",
      state_name: "Massachusetts",
    },
    {
      city_id: "596",
      city: "Onset",
      zip_code: "02558",
      state_id: "MA",
      state_name: "Massachusetts",
    },
    {
      city_id: "265",
      city: "Orange",
      zip_code: "01364",
      state_id: "MA",
      state_name: "Massachusetts",
    },
    {
      city_id: "627",
      city: "Orleans",
      zip_code: "02653",
      state_id: "MA",
      state_name: "Massachusetts",
    },
    {
      city_id: "628",
      city: "Osterville",
      zip_code: "02655",
      state_id: "MA",
      state_name: "Massachusetts",
    },
    {
      city_id: "233",
      city: "Otis",
      zip_code: "01253",
      state_id: "MA",
      state_name: "Massachusetts",
    },
    {
      city_id: "322",
      city: "Oxford",
      zip_code: "01540",
      state_id: "MA",
      state_name: "Massachusetts",
    },
    {
      city_id: "177",
      city: "Palmer",
      zip_code: "01069",
      state_id: "MA",
      state_name: "Massachusetts",
    },
    {
      city_id: "352",
      city: "Paxton",
      zip_code: "01612",
      state_id: "MA",
      state_name: "Massachusetts",
    },
    {
      city_id: "435",
      city: "Peabody",
      zip_code: "01960",
      state_id: "MA",
      state_name: "Massachusetts",
    },
    {
      city_id: "545",
      city: "Pembroke",
      zip_code: "02359",
      state_id: "MA",
      state_name: "Massachusetts",
    },
    {
      city_id: "290",
      city: "Pepperell",
      zip_code: "01463",
      state_id: "MA",
      state_name: "Massachusetts",
    },
    {
      city_id: "266",
      city: "Petersham",
      zip_code: "01366",
      state_id: "MA",
      state_name: "Massachusetts",
    },
    {
      city_id: "214",
      city: "Pittsfield",
      zip_code: "01201",
      state_id: "MA",
      state_name: "Massachusetts",
    },
    {
      city_id: "178",
      city: "Plainfield",
      zip_code: "01070",
      state_id: "MA",
      state_name: "Massachusetts",
    },
    {
      city_id: "665",
      city: "Plainville",
      zip_code: "02762",
      state_id: "MA",
      state_name: "Massachusetts",
    },
    {
      city_id: "546",
      city: "Plymouth",
      zip_code: "02360",
      state_id: "MA",
      state_name: "Massachusetts",
    },
    {
      city_id: "549",
      city: "Plympton",
      zip_code: "02367",
      state_id: "MA",
      state_name: "Massachusetts",
    },
    {
      city_id: "597",
      city: "Pocasset",
      zip_code: "02559",
      state_id: "MA",
      state_name: "Massachusetts",
    },
    {
      city_id: "436",
      city: "Prides Crossing",
      zip_code: "01965",
      state_id: "MA",
      state_name: "Massachusetts",
    },
    {
      city_id: "323",
      city: "Princeton",
      zip_code: "01541",
      state_id: "MA",
      state_name: "Massachusetts",
    },
    {
      city_id: "629",
      city: "Provincetown",
      zip_code: "02657",
      state_id: "MA",
      state_name: "Massachusetts",
    },
    {
      city_id: "511",
      city: "Quincy",
      zip_code: "02169",
      state_id: "MA",
      state_name: "Massachusetts",
    },
    {
      city_id: "550",
      city: "Randolph",
      zip_code: "02368",
      state_id: "MA",
      state_name: "Massachusetts",
    },
    {
      city_id: "669",
      city: "Raynham",
      zip_code: "02767",
      state_id: "MA",
      state_name: "Massachusetts",
    },
    {
      city_id: "405",
      city: "Reading",
      zip_code: "01867",
      state_id: "MA",
      state_name: "Massachusetts",
    },
    {
      city_id: "670",
      city: "Rehoboth",
      zip_code: "02769",
      state_id: "MA",
      state_name: "Massachusetts",
    },
    {
      city_id: "507",
      city: "Revere",
      zip_code: "02151",
      state_id: "MA",
      state_name: "Massachusetts",
    },
    {
      city_id: "234",
      city: "Richmond",
      zip_code: "01254",
      state_id: "MA",
      state_name: "Massachusetts",
    },
    {
      city_id: "324",
      city: "Rochdale",
      zip_code: "01542",
      state_id: "MA",
      state_name: "Massachusetts",
    },
    {
      city_id: "671",
      city: "Rochester",
      zip_code: "02770",
      state_id: "MA",
      state_name: "Massachusetts",
    },
    {
      city_id: "551",
      city: "Rockland",
      zip_code: "02370",
      state_id: "MA",
      state_name: "Massachusetts",
    },
    {
      city_id: "437",
      city: "Rockport",
      zip_code: "01966",
      state_id: "MA",
      state_name: "Massachusetts",
    },
    {
      city_id: "490",
      city: "Roslindale",
      zip_code: "02131",
      state_id: "MA",
      state_name: "Massachusetts",
    },
    {
      city_id: "267",
      city: "Rowe",
      zip_code: "01367",
      state_id: "MA",
      state_name: "Massachusetts",
    },
    {
      city_id: "438",
      city: "Rowley",
      zip_code: "01969",
      state_id: "MA",
      state_name: "Massachusetts",
    },
    {
      city_id: "479",
      city: "Roxbury",
      zip_code: "02119",
      state_id: "MA",
      state_name: "Massachusetts",
    },
    {
      city_id: "480",
      city: "Roxbury Crossing",
      zip_code: "02120",
      state_id: "MA",
      state_name: "Massachusetts",
    },
    {
      city_id: "268",
      city: "Royalston",
      zip_code: "01368",
      state_id: "MA",
      state_name: "Massachusetts",
    },
    {
      city_id: "179",
      city: "Russell",
      zip_code: "01071",
      state_id: "MA",
      state_name: "Massachusetts",
    },
    {
      city_id: "325",
      city: "Rutland",
      zip_code: "01543",
      state_id: "MA",
      state_name: "Massachusetts",
    },
    {
      city_id: "598",
      city: "Sagamore",
      zip_code: "02561",
      state_id: "MA",
      state_name: "Massachusetts",
    },
    {
      city_id: "599",
      city: "Sagamore Beach",
      zip_code: "02562",
      state_id: "MA",
      state_name: "Massachusetts",
    },
    {
      city_id: "439",
      city: "Salem",
      zip_code: "01970",
      state_id: "MA",
      state_name: "Massachusetts",
    },
    {
      city_id: "434",
      city: "Salisbury",
      zip_code: "01952",
      state_id: "MA",
      state_name: "Massachusetts",
    },
    {
      city_id: "235",
      city: "Sandisfield",
      zip_code: "01255",
      state_id: "MA",
      state_name: "Massachusetts",
    },
    {
      city_id: "600",
      city: "Sandwich",
      zip_code: "02563",
      state_id: "MA",
      state_name: "Massachusetts",
    },
    {
      city_id: "416",
      city: "Saugus",
      zip_code: "01906",
      state_id: "MA",
      state_name: "Massachusetts",
    },
    {
      city_id: "236",
      city: "Savoy",
      zip_code: "01256",
      state_id: "MA",
      state_name: "Massachusetts",
    },
    {
      city_id: "463",
      city: "Scituate",
      zip_code: "02066",
      state_id: "MA",
      state_name: "Massachusetts",
    },
    {
      city_id: "672",
      city: "Seekonk",
      zip_code: "02771",
      state_id: "MA",
      state_name: "Massachusetts",
    },
    {
      city_id: "464",
      city: "Sharon",
      zip_code: "02067",
      state_id: "MA",
      state_name: "Massachusetts",
    },
    {
      city_id: "237",
      city: "Sheffield",
      zip_code: "01257",
      state_id: "MA",
      state_name: "Massachusetts",
    },
    {
      city_id: "269",
      city: "Shelburne Falls",
      zip_code: "01370",
      state_id: "MA",
      state_name: "Massachusetts",
    },
    {
      city_id: "374",
      city: "Sherborn",
      zip_code: "01770",
      state_id: "MA",
      state_name: "Massachusetts",
    },
    {
      city_id: "291",
      city: "Shirley",
      zip_code: "01464",
      state_id: "MA",
      state_name: "Massachusetts",
    },
    {
      city_id: "326",
      city: "Shrewsbury",
      zip_code: "01545",
      state_id: "MA",
      state_name: "Massachusetts",
    },
    {
      city_id: "180",
      city: "Shutesbury",
      zip_code: "01072",
      state_id: "MA",
      state_name: "Massachusetts",
    },
    {
      city_id: "601",
      city: "Siasconset",
      zip_code: "02564",
      state_id: "MA",
      state_name: "Massachusetts",
    },
    {
      city_id: "653",
      city: "Somerset",
      zip_code: "02725",
      state_id: "MA",
      state_name: "Massachusetts",
    },
    {
      city_id: "501",
      city: "Somerville",
      zip_code: "02143",
      state_id: "MA",
      state_name: "Massachusetts",
    },
    {
      city_id: "182",
      city: "South Barre",
      zip_code: "01074",
      state_id: "MA",
      state_name: "Massachusetts",
    },
    {
      city_id: "486",
      city: "South Boston",
      zip_code: "02127",
      state_id: "MA",
      state_name: "Massachusetts",
    },
    {
      city_id: "548",
      city: "South Carver",
      zip_code: "02366",
      state_id: "MA",
      state_name: "Massachusetts",
    },
    {
      city_id: "630",
      city: "South Chatham",
      zip_code: "02659",
      state_id: "MA",
      state_name: "Massachusetts",
    },
    {
      city_id: "663",
      city: "South Dartmouth",
      zip_code: "02748",
      state_id: "MA",
      state_name: "Massachusetts",
    },
    {
      city_id: "270",
      city: "South Deerfield",
      zip_code: "01373",
      state_id: "MA",
      state_name: "Massachusetts",
    },
    {
      city_id: "631",
      city: "South Dennis",
      zip_code: "02660",
      state_id: "MA",
      state_name: "Massachusetts",
    },
    {
      city_id: "552",
      city: "South Easton",
      zip_code: "02375",
      state_id: "MA",
      state_name: "Massachusetts",
    },
    {
      city_id: "238",
      city: "South Egremont",
      zip_code: "01258",
      state_id: "MA",
      state_name: "Massachusetts",
    },
    {
      city_id: "328",
      city: "South Grafton",
      zip_code: "01560",
      state_id: "MA",
      state_name: "Massachusetts",
    },
    {
      city_id: "183",
      city: "South Hadley",
      zip_code: "01075",
      state_id: "MA",
      state_name: "Massachusetts",
    },
    {
      city_id: "440",
      city: "South Hamilton",
      zip_code: "01982",
      state_id: "MA",
      state_name: "Massachusetts",
    },
    {
      city_id: "329",
      city: "South Lancaster",
      zip_code: "01561",
      state_id: "MA",
      state_name: "Massachusetts",
    },
    {
      city_id: "240",
      city: "South Lee",
      zip_code: "01260",
      state_id: "MA",
      state_name: "Massachusetts",
    },
    {
      city_id: "465",
      city: "South Walpole",
      zip_code: "02071",
      state_id: "MA",
      state_name: "Massachusetts",
    },
    {
      city_id: "632",
      city: "South Wellfleet",
      zip_code: "02663",
      state_id: "MA",
      state_name: "Massachusetts",
    },
    {
      city_id: "520",
      city: "South Weymouth",
      zip_code: "02190",
      state_id: "MA",
      state_name: "Massachusetts",
    },
    {
      city_id: "633",
      city: "South Yarmouth",
      zip_code: "02664",
      state_id: "MA",
      state_name: "Massachusetts",
    },
    {
      city_id: "181",
      city: "Southampton",
      zip_code: "01073",
      state_id: "MA",
      state_name: "Massachusetts",
    },
    {
      city_id: "375",
      city: "Southborough",
      zip_code: "01772",
      state_id: "MA",
      state_name: "Massachusetts",
    },
    {
      city_id: "327",
      city: "Southbridge",
      zip_code: "01550",
      state_id: "MA",
      state_name: "Massachusetts",
    },
    {
      city_id: "239",
      city: "Southfield",
      zip_code: "01259",
      state_id: "MA",
      state_name: "Massachusetts",
    },
    {
      city_id: "184",
      city: "Southwick",
      zip_code: "01077",
      state_id: "MA",
      state_name: "Massachusetts",
    },
    {
      city_id: "330",
      city: "Spencer",
      zip_code: "01562",
      state_id: "MA",
      state_name: "Massachusetts",
    },
    {
      city_id: "201",
      city: "Springfield",
      zip_code: "01103",
      state_id: "MA",
      state_name: "Massachusetts",
    },
    {
      city_id: "331",
      city: "Sterling",
      zip_code: "01564",
      state_id: "MA",
      state_name: "Massachusetts",
    },
    {
      city_id: "241",
      city: "Stockbridge",
      zip_code: "01262",
      state_id: "MA",
      state_name: "Massachusetts",
    },
    {
      city_id: "515",
      city: "Stoneham",
      zip_code: "02180",
      state_id: "MA",
      state_name: "Massachusetts",
    },
    {
      city_id: "466",
      city: "Stoughton",
      zip_code: "02072",
      state_id: "MA",
      state_name: "Massachusetts",
    },
    {
      city_id: "377",
      city: "Stow",
      zip_code: "01775",
      state_id: "MA",
      state_name: "Massachusetts",
    },
    {
      city_id: "332",
      city: "Sturbridge",
      zip_code: "01566",
      state_id: "MA",
      state_name: "Massachusetts",
    },
    {
      city_id: "378",
      city: "Sudbury",
      zip_code: "01776",
      state_id: "MA",
      state_name: "Massachusetts",
    },
    {
      city_id: "271",
      city: "Sunderland",
      zip_code: "01375",
      state_id: "MA",
      state_name: "Massachusetts",
    },
    {
      city_id: "341",
      city: "Sutton",
      zip_code: "01590",
      state_id: "MA",
      state_name: "Massachusetts",
    },
    {
      city_id: "417",
      city: "Swampscott",
      zip_code: "01907",
      state_id: "MA",
      state_name: "Massachusetts",
    },
    {
      city_id: "673",
      city: "Swansea",
      zip_code: "02777",
      state_id: "MA",
      state_name: "Massachusetts",
    },
    {
      city_id: "675",
      city: "Taunton",
      zip_code: "02780",
      state_id: "MA",
      state_name: "Massachusetts",
    },
    {
      city_id: "292",
      city: "Templeton",
      zip_code: "01468",
      state_id: "MA",
      state_name: "Massachusetts",
    },
    {
      city_id: "406",
      city: "Tewksbury",
      zip_code: "01876",
      state_id: "MA",
      state_name: "Massachusetts",
    },
    {
      city_id: "185",
      city: "Thorndike",
      zip_code: "01079",
      state_id: "MA",
      state_name: "Massachusetts",
    },
    {
      city_id: "186",
      city: "Three Rivers",
      zip_code: "01080",
      state_id: "MA",
      state_name: "Massachusetts",
    },
    {
      city_id: "441",
      city: "Topsfield",
      zip_code: "01983",
      state_id: "MA",
      state_name: "Massachusetts",
    },
    {
      city_id: "293",
      city: "Townsend",
      zip_code: "01469",
      state_id: "MA",
      state_name: "Massachusetts",
    },
    {
      city_id: "634",
      city: "Truro",
      zip_code: "02666",
      state_id: "MA",
      state_name: "Massachusetts",
    },
    {
      city_id: "272",
      city: "Turners Falls",
      zip_code: "01376",
      state_id: "MA",
      state_name: "Massachusetts",
    },
    {
      city_id: "407",
      city: "Tyngsboro",
      zip_code: "01879",
      state_id: "MA",
      state_name: "Massachusetts",
    },
    {
      city_id: "242",
      city: "Tyringham",
      zip_code: "01264",
      state_id: "MA",
      state_name: "Massachusetts",
    },
    {
      city_id: "333",
      city: "Upton",
      zip_code: "01568",
      state_id: "MA",
      state_name: "Massachusetts",
    },
    {
      city_id: "334",
      city: "Uxbridge",
      zip_code: "01569",
      state_id: "MA",
      state_name: "Massachusetts",
    },
    {
      city_id: "602",
      city: "Vineyard Haven",
      zip_code: "02568",
      state_id: "MA",
      state_name: "Massachusetts",
    },
    {
      city_id: "572",
      city: "Waban",
      zip_code: "02468",
      state_id: "MA",
      state_name: "Massachusetts",
    },
    {
      city_id: "408",
      city: "Wakefield",
      zip_code: "01880",
      state_id: "MA",
      state_name: "Massachusetts",
    },
    {
      city_id: "187",
      city: "Wales",
      zip_code: "01081",
      state_id: "MA",
      state_name: "Massachusetts",
    },
    {
      city_id: "467",
      city: "Walpole",
      zip_code: "02081",
      state_id: "MA",
      state_name: "Massachusetts",
    },
    {
      city_id: "559",
      city: "Waltham",
      zip_code: "02451",
      state_id: "MA",
      state_name: "Massachusetts",
    },
    {
      city_id: "188",
      city: "Ware",
      zip_code: "01082",
      state_id: "MA",
      state_name: "Massachusetts",
    },
    {
      city_id: "603",
      city: "Wareham",
      zip_code: "02571",
      state_id: "MA",
      state_name: "Massachusetts",
    },
    {
      city_id: "189",
      city: "Warren",
      zip_code: "01083",
      state_id: "MA",
      state_name: "Massachusetts",
    },
    {
      city_id: "273",
      city: "Warwick",
      zip_code: "01378",
      state_id: "MA",
      state_name: "Massachusetts",
    },
    {
      city_id: "573",
      city: "Watertown",
      zip_code: "02472",
      state_id: "MA",
      state_name: "Massachusetts",
    },
    {
      city_id: "379",
      city: "Wayland",
      zip_code: "01778",
      state_id: "MA",
      state_name: "Massachusetts",
    },
    {
      city_id: "335",
      city: "Webster",
      zip_code: "01570",
      state_id: "MA",
      state_name: "Massachusetts",
    },
    {
      city_id: "578",
      city: "Wellesley",
      zip_code: "02482",
      state_id: "MA",
      state_name: "Massachusetts",
    },
    {
      city_id: "577",
      city: "Wellesley Hills",
      zip_code: "02481",
      state_id: "MA",
      state_name: "Massachusetts",
    },
    {
      city_id: "635",
      city: "Wellfleet",
      zip_code: "02667",
      state_id: "MA",
      state_name: "Massachusetts",
    },
    {
      city_id: "274",
      city: "Wendell",
      zip_code: "01379",
      state_id: "MA",
      state_name: "Massachusetts",
    },
    {
      city_id: "275",
      city: "Wendell Depot",
      zip_code: "01380",
      state_id: "MA",
      state_name: "Massachusetts",
    },
    {
      city_id: "442",
      city: "Wenham",
      zip_code: "01984",
      state_id: "MA",
      state_name: "Massachusetts",
    },
    {
      city_id: "636",
      city: "West Barnstable",
      zip_code: "02668",
      state_id: "MA",
      state_name: "Massachusetts",
    },
    {
      city_id: "338",
      city: "West Boylston",
      zip_code: "01583",
      state_id: "MA",
      state_name: "Massachusetts",
    },
    {
      city_id: "553",
      city: "West Bridgewater",
      zip_code: "02379",
      state_id: "MA",
      state_name: "Massachusetts",
    },
    {
      city_id: "339",
      city: "West Brookfield",
      zip_code: "01585",
      state_id: "MA",
      state_name: "Massachusetts",
    },
    {
      city_id: "190",
      city: "West Chesterfield",
      zip_code: "01084",
      state_id: "MA",
      state_name: "Massachusetts",
    },
    {
      city_id: "637",
      city: "West Dennis",
      zip_code: "02670",
      state_id: "MA",
      state_name: "Massachusetts",
    },
    {
      city_id: "638",
      city: "West Harwich",
      zip_code: "02671",
      state_id: "MA",
      state_name: "Massachusetts",
    },
    {
      city_id: "192",
      city: "West Hatfield",
      zip_code: "01088",
      state_id: "MA",
      state_name: "Massachusetts",
    },
    {
      city_id: "639",
      city: "West Hyannisport",
      zip_code: "02672",
      state_id: "MA",
      state_name: "Massachusetts",
    },
    {
      city_id: "443",
      city: "West Newbury",
      zip_code: "01985",
      state_id: "MA",
      state_name: "Massachusetts",
    },
    {
      city_id: "569",
      city: "West Newton",
      zip_code: "02465",
      state_id: "MA",
      state_name: "Massachusetts",
    },
    {
      city_id: "491",
      city: "West Roxbury",
      zip_code: "02132",
      state_id: "MA",
      state_name: "Massachusetts",
    },
    {
      city_id: "193",
      city: "West Springfield",
      zip_code: "01089",
      state_id: "MA",
      state_name: "Massachusetts",
    },
    {
      city_id: "243",
      city: "West Stockbridge",
      zip_code: "01266",
      state_id: "MA",
      state_name: "Massachusetts",
    },
    {
      city_id: "604",
      city: "West Tisbury",
      zip_code: "02575",
      state_id: "MA",
      state_name: "Massachusetts",
    },
    {
      city_id: "295",
      city: "West Townsend",
      zip_code: "01474",
      state_id: "MA",
      state_name: "Massachusetts",
    },
    {
      city_id: "605",
      city: "West Wareham",
      zip_code: "02576",
      state_id: "MA",
      state_name: "Massachusetts",
    },
    {
      city_id: "194",
      city: "West Warren",
      zip_code: "01092",
      state_id: "MA",
      state_name: "Massachusetts",
    },
    {
      city_id: "640",
      city: "West Yarmouth",
      zip_code: "02673",
      state_id: "MA",
      state_name: "Massachusetts",
    },
    {
      city_id: "337",
      city: "Westborough",
      zip_code: "01581",
      state_id: "MA",
      state_name: "Massachusetts",
    },
    {
      city_id: "191",
      city: "Westfield",
      zip_code: "01085",
      state_id: "MA",
      state_name: "Massachusetts",
    },
    {
      city_id: "409",
      city: "Westford",
      zip_code: "01886",
      state_id: "MA",
      state_name: "Massachusetts",
    },
    {
      city_id: "294",
      city: "Westminster",
      zip_code: "01473",
      state_id: "MA",
      state_name: "Massachusetts",
    },
    {
      city_id: "580",
      city: "Weston",
      zip_code: "02493",
      state_id: "MA",
      state_name: "Massachusetts",
    },
    {
      city_id: "676",
      city: "Westport",
      zip_code: "02790",
      state_id: "MA",
      state_name: "Massachusetts",
    },
    {
      city_id: "677",
      city: "Westport Point",
      zip_code: "02791",
      state_id: "MA",
      state_name: "Massachusetts",
    },
    {
      city_id: "468",
      city: "Westwood",
      zip_code: "02090",
      state_id: "MA",
      state_name: "Massachusetts",
    },
    {
      city_id: "518",
      city: "Weymouth",
      zip_code: "02188",
      state_id: "MA",
      state_name: "Massachusetts",
    },
    {
      city_id: "195",
      city: "Whately",
      zip_code: "01093",
      state_id: "MA",
      state_name: "Massachusetts",
    },
    {
      city_id: "196",
      city: "Wheelwright",
      zip_code: "01094",
      state_id: "MA",
      state_name: "Massachusetts",
    },
    {
      city_id: "340",
      city: "Whitinsville",
      zip_code: "01588",
      state_id: "MA",
      state_name: "Massachusetts",
    },
    {
      city_id: "554",
      city: "Whitman",
      zip_code: "02382",
      state_id: "MA",
      state_name: "Massachusetts",
    },
    {
      city_id: "197",
      city: "Wilbraham",
      zip_code: "01095",
      state_id: "MA",
      state_name: "Massachusetts",
    },
    {
      city_id: "198",
      city: "Williamsburg",
      zip_code: "01096",
      state_id: "MA",
      state_name: "Massachusetts",
    },
    {
      city_id: "244",
      city: "Williamstown",
      zip_code: "01267",
      state_id: "MA",
      state_name: "Massachusetts",
    },
    {
      city_id: "410",
      city: "Wilmington",
      zip_code: "01887",
      state_id: "MA",
      state_name: "Massachusetts",
    },
    {
      city_id: "296",
      city: "Winchendon",
      zip_code: "01475",
      state_id: "MA",
      state_name: "Massachusetts",
    },
    {
      city_id: "411",
      city: "Winchester",
      zip_code: "01890",
      state_id: "MA",
      state_name: "Massachusetts",
    },
    {
      city_id: "245",
      city: "Windsor",
      zip_code: "01270",
      state_id: "MA",
      state_name: "Massachusetts",
    },
    {
      city_id: "508",
      city: "Winthrop",
      zip_code: "02152",
      state_id: "MA",
      state_name: "Massachusetts",
    },
    {
      city_id: "380",
      city: "Woburn",
      zip_code: "01801",
      state_id: "MA",
      state_name: "Massachusetts",
    },
    {
      city_id: "591",
      city: "Woods Hole",
      zip_code: "02543",
      state_id: "MA",
      state_name: "Massachusetts",
    },
    {
      city_id: "342",
      city: "Worcester",
      zip_code: "01602",
      state_id: "MA",
      state_name: "Massachusetts",
    },
    {
      city_id: "199",
      city: "Woronoco",
      zip_code: "01097",
      state_id: "MA",
      state_name: "Massachusetts",
    },
    {
      city_id: "200",
      city: "Worthington",
      zip_code: "01098",
      state_id: "MA",
      state_name: "Massachusetts",
    },
    {
      city_id: "469",
      city: "Wrentham",
      zip_code: "02093",
      state_id: "MA",
      state_name: "Massachusetts",
    },
    {
      city_id: "641",
      city: "Yarmouth Port",
      zip_code: "02675",
      state_id: "MA",
      state_name: "Massachusetts",
    },
    {
      city_id: "6413",
      city: "Abell",
      zip_code: "20606",
      state_id: "MD",
      state_name: "Maryland",
    },
    {
      city_id: "6572",
      city: "Aberdeen",
      zip_code: "21001",
      state_id: "MD",
      state_name: "Maryland",
    },
    {
      city_id: "6573",
      city: "Aberdeen Proving Ground",
      zip_code: "21005",
      state_id: "MD",
      state_name: "Maryland",
    },
    {
      city_id: "6574",
      city: "Abingdon",
      zip_code: "21009",
      state_id: "MD",
      state_name: "Maryland",
    },
    {
      city_id: "6699",
      city: "Accident",
      zip_code: "21520",
      state_id: "MD",
      state_name: "Maryland",
    },
    {
      city_id: "6414",
      city: "Accokeek",
      zip_code: "20607",
      state_id: "MD",
      state_name: "Maryland",
    },
    {
      city_id: "6784",
      city: "Adamstown",
      zip_code: "21710",
      state_id: "MD",
      state_name: "Maryland",
    },
    {
      city_id: "6837",
      city: "Allen",
      zip_code: "21810",
      state_id: "MD",
      state_name: "Maryland",
    },
    {
      city_id: "6505",
      city: "Andrews Air Force Base",
      zip_code: "20762",
      state_id: "MD",
      state_name: "Maryland",
    },
    {
      city_id: "6693",
      city: "Annapolis",
      zip_code: "21401",
      state_id: "MD",
      state_name: "Maryland",
    },
    {
      city_id: "6471",
      city: "Annapolis Junction",
      zip_code: "20701",
      state_id: "MD",
      state_name: "Maryland",
    },
    {
      city_id: "6415",
      city: "Aquasco",
      zip_code: "20608",
      state_id: "MD",
      state_name: "Maryland",
    },
    {
      city_id: "6576",
      city: "Arnold",
      zip_code: "21012",
      state_id: "MD",
      state_name: "Maryland",
    },
    {
      city_id: "6544",
      city: "Ashton",
      zip_code: "20861",
      state_id: "MD",
      state_name: "Maryland",
    },
    {
      city_id: "6416",
      city: "Avenue",
      zip_code: "20609",
      state_id: "MD",
      state_name: "Maryland",
    },
    {
      city_id: "6577",
      city: "Baldwin",
      zip_code: "21013",
      state_id: "MD",
      state_name: "Maryland",
    },
    {
      city_id: "6650",
      city: "Baltimore",
      zip_code: "21201",
      state_id: "MD",
      state_name: "Maryland",
    },
    {
      city_id: "6722",
      city: "Barclay",
      zip_code: "21607",
      state_id: "MD",
      state_name: "Maryland",
    },
    {
      city_id: "6533",
      city: "Barnesville",
      zip_code: "20838",
      state_id: "MD",
      state_name: "Maryland",
    },
    {
      city_id: "6700",
      city: "Barton",
      zip_code: "21521",
      state_id: "MD",
      state_name: "Maryland",
    },
    {
      city_id: "6534",
      city: "Beallsville",
      zip_code: "20839",
      state_id: "MD",
      state_name: "Maryland",
    },
    {
      city_id: "6578",
      city: "Bel Air",
      zip_code: "21014",
      state_id: "MD",
      state_name: "Maryland",
    },
    {
      city_id: "6417",
      city: "Bel Alton",
      zip_code: "20611",
      state_id: "MD",
      state_name: "Maryland",
    },
    {
      city_id: "6580",
      city: "Belcamp",
      zip_code: "21017",
      state_id: "MD",
      state_name: "Maryland",
    },
    {
      city_id: "6472",
      city: "Beltsville",
      zip_code: "20705",
      state_id: "MD",
      state_name: "Maryland",
    },
    {
      city_id: "6418",
      city: "Benedict",
      zip_code: "20612",
      state_id: "MD",
      state_name: "Maryland",
    },
    {
      city_id: "6838",
      city: "Berlin",
      zip_code: "21811",
      state_id: "MD",
      state_name: "Maryland",
    },
    {
      city_id: "6525",
      city: "Bethesda",
      zip_code: "20814",
      state_id: "MD",
      state_name: "Maryland",
    },
    {
      city_id: "6723",
      city: "Betterton",
      zip_code: "21610",
      state_id: "MD",
      state_name: "Maryland",
    },
    {
      city_id: "6785",
      city: "Big Pool",
      zip_code: "21711",
      state_id: "MD",
      state_name: "Maryland",
    },
    {
      city_id: "6839",
      city: "Bishopville",
      zip_code: "21813",
      state_id: "MD",
      state_name: "Maryland",
    },
    {
      city_id: "6840",
      city: "Bivalve",
      zip_code: "21814",
      state_id: "MD",
      state_name: "Maryland",
    },
    {
      city_id: "6476",
      city: "Bladensburg",
      zip_code: "20710",
      state_id: "MD",
      state_name: "Maryland",
    },
    {
      city_id: "6701",
      city: "Bloomington",
      zip_code: "21523",
      state_id: "MD",
      state_name: "Maryland",
    },
    {
      city_id: "6786",
      city: "Boonsboro",
      zip_code: "21713",
      state_id: "MD",
      state_name: "Maryland",
    },
    {
      city_id: "6480",
      city: "Bowie",
      zip_code: "20715",
      state_id: "MD",
      state_name: "Maryland",
    },
    {
      city_id: "6535",
      city: "Boyds",
      zip_code: "20841",
      state_id: "MD",
      state_name: "Maryland",
    },
    {
      city_id: "6724",
      city: "Bozman",
      zip_code: "21612",
      state_id: "MD",
      state_name: "Maryland",
    },
    {
      city_id: "6787",
      city: "Braddock Heights",
      zip_code: "21714",
      state_id: "MD",
      state_name: "Maryland",
    },
    {
      city_id: "6419",
      city: "Brandywine",
      zip_code: "20613",
      state_id: "MD",
      state_name: "Maryland",
    },
    {
      city_id: "6484",
      city: "Brentwood",
      zip_code: "20722",
      state_id: "MD",
      state_name: "Maryland",
    },
    {
      city_id: "6545",
      city: "Brinklow",
      zip_code: "20862",
      state_id: "MD",
      state_name: "Maryland",
    },
    {
      city_id: "6531",
      city: "Brookeville",
      zip_code: "20833",
      state_id: "MD",
      state_name: "Maryland",
    },
    {
      city_id: "6673",
      city: "Brooklyn",
      zip_code: "21225",
      state_id: "MD",
      state_name: "Maryland",
    },
    {
      city_id: "6420",
      city: "Broomes Island",
      zip_code: "20615",
      state_id: "MD",
      state_name: "Maryland",
    },
    {
      city_id: "6788",
      city: "Brownsville",
      zip_code: "21715",
      state_id: "MD",
      state_name: "Maryland",
    },
    {
      city_id: "6789",
      city: "Brunswick",
      zip_code: "21716",
      state_id: "MD",
      state_name: "Maryland",
    },
    {
      city_id: "6421",
      city: "Bryans Road",
      zip_code: "20616",
      state_id: "MD",
      state_name: "Maryland",
    },
    {
      city_id: "6422",
      city: "Bryantown",
      zip_code: "20617",
      state_id: "MD",
      state_name: "Maryland",
    },
    {
      city_id: "6790",
      city: "Buckeystown",
      zip_code: "21717",
      state_id: "MD",
      state_name: "Maryland",
    },
    {
      city_id: "6791",
      city: "Burkittsville",
      zip_code: "21718",
      state_id: "MD",
      state_name: "Maryland",
    },
    {
      city_id: "6546",
      city: "Burtonsville",
      zip_code: "20866",
      state_id: "MD",
      state_name: "Maryland",
    },
    {
      city_id: "6423",
      city: "Bushwood",
      zip_code: "20618",
      state_id: "MD",
      state_name: "Maryland",
    },
    {
      city_id: "6581",
      city: "Butler",
      zip_code: "21023",
      state_id: "MD",
      state_name: "Maryland",
    },
    {
      city_id: "6529",
      city: "Cabin John",
      zip_code: "20818",
      state_id: "MD",
      state_name: "Maryland",
    },
    {
      city_id: "6424",
      city: "California",
      zip_code: "20619",
      state_id: "MD",
      state_name: "Maryland",
    },
    {
      city_id: "6425",
      city: "Callaway",
      zip_code: "20620",
      state_id: "MD",
      state_name: "Maryland",
    },
    {
      city_id: "6725",
      city: "Cambridge",
      zip_code: "21613",
      state_id: "MD",
      state_name: "Maryland",
    },
    {
      city_id: "6494",
      city: "Capitol Heights",
      zip_code: "20743",
      state_id: "MD",
      state_name: "Maryland",
    },
    {
      city_id: "6792",
      city: "Cascade",
      zip_code: "21719",
      state_id: "MD",
      state_name: "Maryland",
    },
    {
      city_id: "6676",
      city: "Catonsville",
      zip_code: "21228",
      state_id: "MD",
      state_name: "Maryland",
    },
    {
      city_id: "6878",
      city: "Cecilton",
      zip_code: "21913",
      state_id: "MD",
      state_name: "Maryland",
    },
    {
      city_id: "6726",
      city: "Centreville",
      zip_code: "21617",
      state_id: "MD",
      state_name: "Maryland",
    },
    {
      city_id: "6426",
      city: "Chaptico",
      zip_code: "20621",
      state_id: "MD",
      state_name: "Maryland",
    },
    {
      city_id: "6879",
      city: "Charlestown",
      zip_code: "21914",
      state_id: "MD",
      state_name: "Maryland",
    },
    {
      city_id: "6427",
      city: "Charlotte Hall",
      zip_code: "20622",
      state_id: "MD",
      state_name: "Maryland",
    },
    {
      city_id: "6428",
      city: "Cheltenham",
      zip_code: "20623",
      state_id: "MD",
      state_name: "Maryland",
    },
    {
      city_id: "6487",
      city: "Chesapeake Beach",
      zip_code: "20732",
      state_id: "MD",
      state_name: "Maryland",
    },
    {
      city_id: "6880",
      city: "Chesapeake City",
      zip_code: "21915",
      state_id: "MD",
      state_name: "Maryland",
    },
    {
      city_id: "6727",
      city: "Chester",
      zip_code: "21619",
      state_id: "MD",
      state_name: "Maryland",
    },
    {
      city_id: "6728",
      city: "Chestertown",
      zip_code: "21620",
      state_id: "MD",
      state_name: "Maryland",
    },
    {
      city_id: "6526",
      city: "Chevy Chase",
      zip_code: "20815",
      state_id: "MD",
      state_name: "Maryland",
    },
    {
      city_id: "6729",
      city: "Church Creek",
      zip_code: "21622",
      state_id: "MD",
      state_name: "Maryland",
    },
    {
      city_id: "6730",
      city: "Church Hill",
      zip_code: "21623",
      state_id: "MD",
      state_name: "Maryland",
    },
    {
      city_id: "6488",
      city: "Churchton",
      zip_code: "20733",
      state_id: "MD",
      state_name: "Maryland",
    },
    {
      city_id: "6582",
      city: "Churchville",
      zip_code: "21028",
      state_id: "MD",
      state_name: "Maryland",
    },
    {
      city_id: "6731",
      city: "Claiborne",
      zip_code: "21624",
      state_id: "MD",
      state_name: "Maryland",
    },
    {
      city_id: "6548",
      city: "Clarksburg",
      zip_code: "20871",
      state_id: "MD",
      state_name: "Maryland",
    },
    {
      city_id: "6583",
      city: "Clarksville",
      zip_code: "21029",
      state_id: "MD",
      state_name: "Maryland",
    },
    {
      city_id: "6793",
      city: "Clear Spring",
      zip_code: "21722",
      state_id: "MD",
      state_name: "Maryland",
    },
    {
      city_id: "6429",
      city: "Clements",
      zip_code: "20624",
      state_id: "MD",
      state_name: "Maryland",
    },
    {
      city_id: "6489",
      city: "Clinton",
      zip_code: "20735",
      state_id: "MD",
      state_name: "Maryland",
    },
    {
      city_id: "6430",
      city: "Cobb Island",
      zip_code: "20625",
      state_id: "MD",
      state_name: "Maryland",
    },
    {
      city_id: "6584",
      city: "Cockeysville",
      zip_code: "21030",
      state_id: "MD",
      state_name: "Maryland",
    },
    {
      city_id: "6492",
      city: "College Park",
      zip_code: "20740",
      state_id: "MD",
      state_name: "Maryland",
    },
    {
      city_id: "6881",
      city: "Colora",
      zip_code: "21917",
      state_id: "MD",
      state_name: "Maryland",
    },
    {
      city_id: "6431",
      city: "Coltons Point",
      zip_code: "20626",
      state_id: "MD",
      state_name: "Maryland",
    },
    {
      city_id: "6594",
      city: "Columbia",
      zip_code: "21044",
      state_id: "MD",
      state_name: "Maryland",
    },
    {
      city_id: "6882",
      city: "Conowingo",
      zip_code: "21918",
      state_id: "MD",
      state_name: "Maryland",
    },
    {
      city_id: "6794",
      city: "Cooksville",
      zip_code: "21723",
      state_id: "MD",
      state_name: "Maryland",
    },
    {
      city_id: "6732",
      city: "Cordova",
      zip_code: "21625",
      state_id: "MD",
      state_name: "Maryland",
    },
    {
      city_id: "6702",
      city: "Corriganville",
      zip_code: "21524",
      state_id: "MD",
      state_name: "Maryland",
    },
    {
      city_id: "6733",
      city: "Crapo",
      zip_code: "21626",
      state_id: "MD",
      state_name: "Maryland",
    },
    {
      city_id: "6841",
      city: "Crisfield",
      zip_code: "21817",
      state_id: "MD",
      state_name: "Maryland",
    },
    {
      city_id: "6734",
      city: "Crocheron",
      zip_code: "21627",
      state_id: "MD",
      state_name: "Maryland",
    },
    {
      city_id: "6626",
      city: "Crofton",
      zip_code: "21114",
      state_id: "MD",
      state_name: "Maryland",
    },
    {
      city_id: "6586",
      city: "Crownsville",
      zip_code: "21032",
      state_id: "MD",
      state_name: "Maryland",
    },
    {
      city_id: "6735",
      city: "Crumpton",
      zip_code: "21628",
      state_id: "MD",
      state_name: "Maryland",
    },
    {
      city_id: "6698",
      city: "Cumberland",
      zip_code: "21502",
      state_id: "MD",
      state_name: "Maryland",
    },
    {
      city_id: "6674",
      city: "Curtis Bay",
      zip_code: "21226",
      state_id: "MD",
      state_name: "Maryland",
    },
    {
      city_id: "6549",
      city: "Damascus",
      zip_code: "20872",
      state_id: "MD",
      state_name: "Maryland",
    },
    {
      city_id: "6432",
      city: "Dameron",
      zip_code: "20628",
      state_id: "MD",
      state_name: "Maryland",
    },
    {
      city_id: "6587",
      city: "Darlington",
      zip_code: "21034",
      state_id: "MD",
      state_name: "Maryland",
    },
    {
      city_id: "6588",
      city: "Davidsonville",
      zip_code: "21035",
      state_id: "MD",
      state_name: "Maryland",
    },
    {
      city_id: "6589",
      city: "Dayton",
      zip_code: "21036",
      state_id: "MD",
      state_name: "Maryland",
    },
    {
      city_id: "6842",
      city: "Deal Island",
      zip_code: "21821",
      state_id: "MD",
      state_name: "Maryland",
    },
    {
      city_id: "6500",
      city: "Deale",
      zip_code: "20751",
      state_id: "MD",
      state_name: "Maryland",
    },
    {
      city_id: "6870",
      city: "Delmar",
      zip_code: "21875",
      state_id: "MD",
      state_name: "Maryland",
    },
    {
      city_id: "6736",
      city: "Denton",
      zip_code: "21629",
      state_id: "MD",
      state_name: "Maryland",
    },
    {
      city_id: "6542",
      city: "Derwood",
      zip_code: "20855",
      state_id: "MD",
      state_name: "Maryland",
    },
    {
      city_id: "6536",
      city: "Dickerson",
      zip_code: "20842",
      state_id: "MD",
      state_name: "Maryland",
    },
    {
      city_id: "6498",
      city: "District Heights",
      zip_code: "20747",
      state_id: "MD",
      state_name: "Maryland",
    },
    {
      city_id: "6433",
      city: "Dowell",
      zip_code: "20629",
      state_id: "MD",
      state_name: "Maryland",
    },
    {
      city_id: "6434",
      city: "Drayden",
      zip_code: "20630",
      state_id: "MD",
      state_name: "Maryland",
    },
    {
      city_id: "6670",
      city: "Dundalk",
      zip_code: "21222",
      state_id: "MD",
      state_name: "Maryland",
    },
    {
      city_id: "6501",
      city: "Dunkirk",
      zip_code: "20754",
      state_id: "MD",
      state_name: "Maryland",
    },
    {
      city_id: "6883",
      city: "Earleville",
      zip_code: "21919",
      state_id: "MD",
      state_name: "Maryland",
    },
    {
      city_id: "6737",
      city: "East New Market",
      zip_code: "21631",
      state_id: "MD",
      state_name: "Maryland",
    },
    {
      city_id: "6721",
      city: "Easton",
      zip_code: "21601",
      state_id: "MD",
      state_name: "Maryland",
    },
    {
      city_id: "6843",
      city: "Eden",
      zip_code: "21822",
      state_id: "MD",
      state_name: "Maryland",
    },
    {
      city_id: "6590",
      city: "Edgewater",
      zip_code: "21037",
      state_id: "MD",
      state_name: "Maryland",
    },
    {
      city_id: "6591",
      city: "Edgewood",
      zip_code: "21040",
      state_id: "MD",
      state_name: "Maryland",
    },
    {
      city_id: "6884",
      city: "Elk Mills",
      zip_code: "21920",
      state_id: "MD",
      state_name: "Maryland",
    },
    {
      city_id: "6610",
      city: "Elkridge",
      zip_code: "21075",
      state_id: "MD",
      state_name: "Maryland",
    },
    {
      city_id: "6885",
      city: "Elkton",
      zip_code: "21921",
      state_id: "MD",
      state_name: "Maryland",
    },
    {
      city_id: "6703",
      city: "Ellerslie",
      zip_code: "21529",
      state_id: "MD",
      state_name: "Maryland",
    },
    {
      city_id: "6592",
      city: "Ellicott City",
      zip_code: "21042",
      state_id: "MD",
      state_name: "Maryland",
    },
    {
      city_id: "6795",
      city: "Emmitsburg",
      zip_code: "21727",
      state_id: "MD",
      state_name: "Maryland",
    },
    {
      city_id: "6669",
      city: "Essex",
      zip_code: "21221",
      state_id: "MD",
      state_name: "Maryland",
    },
    {
      city_id: "6844",
      city: "Ewell",
      zip_code: "21824",
      state_id: "MD",
      state_name: "Maryland",
    },
    {
      city_id: "6796",
      city: "Fairplay",
      zip_code: "21733",
      state_id: "MD",
      state_name: "Maryland",
    },
    {
      city_id: "6597",
      city: "Fallston",
      zip_code: "21047",
      state_id: "MD",
      state_name: "Maryland",
    },
    {
      city_id: "6435",
      city: "Faulkner",
      zip_code: "20632",
      state_id: "MD",
      state_name: "Maryland",
    },
    {
      city_id: "6738",
      city: "Federalsburg",
      zip_code: "21632",
      state_id: "MD",
      state_name: "Maryland",
    },
    {
      city_id: "6598",
      city: "Finksburg",
      zip_code: "21048",
      state_id: "MD",
      state_name: "Maryland",
    },
    {
      city_id: "6739",
      city: "Fishing Creek",
      zip_code: "21634",
      state_id: "MD",
      state_name: "Maryland",
    },
    {
      city_id: "6704",
      city: "Flintstone",
      zip_code: "21530",
      state_id: "MD",
      state_name: "Maryland",
    },
    {
      city_id: "6599",
      city: "Forest Hill",
      zip_code: "21050",
      state_id: "MD",
      state_name: "Maryland",
    },
    {
      city_id: "6600",
      city: "Fork",
      zip_code: "21051",
      state_id: "MD",
      state_name: "Maryland",
    },
    {
      city_id: "6502",
      city: "Fort George G Meade",
      zip_code: "20755",
      state_id: "MD",
      state_name: "Maryland",
    },
    {
      city_id: "6601",
      city: "Fort Howard",
      zip_code: "21052",
      state_id: "MD",
      state_name: "Maryland",
    },
    {
      city_id: "6495",
      city: "Fort Washington",
      zip_code: "20744",
      state_id: "MD",
      state_name: "Maryland",
    },
    {
      city_id: "6779",
      city: "Frederick",
      zip_code: "21701",
      state_id: "MD",
      state_name: "Maryland",
    },
    {
      city_id: "6602",
      city: "Freeland",
      zip_code: "21053",
      state_id: "MD",
      state_name: "Maryland",
    },
    {
      city_id: "6503",
      city: "Friendship",
      zip_code: "20758",
      state_id: "MD",
      state_name: "Maryland",
    },
    {
      city_id: "6705",
      city: "Friendsville",
      zip_code: "21531",
      state_id: "MD",
      state_name: "Maryland",
    },
    {
      city_id: "6706",
      city: "Frostburg",
      zip_code: "21532",
      state_id: "MD",
      state_name: "Maryland",
    },
    {
      city_id: "6845",
      city: "Fruitland",
      zip_code: "21826",
      state_id: "MD",
      state_name: "Maryland",
    },
    {
      city_id: "6504",
      city: "Fulton",
      zip_code: "20759",
      state_id: "MD",
      state_name: "Maryland",
    },
    {
      city_id: "6797",
      city: "Funkstown",
      zip_code: "21734",
      state_id: "MD",
      state_name: "Maryland",
    },
    {
      city_id: "6552",
      city: "Gaithersburg",
      zip_code: "20877",
      state_id: "MD",
      state_name: "Maryland",
    },
    {
      city_id: "6740",
      city: "Galena",
      zip_code: "21635",
      state_id: "MD",
      state_name: "Maryland",
    },
    {
      city_id: "6508",
      city: "Galesville",
      zip_code: "20765",
      state_id: "MD",
      state_name: "Maryland",
    },
    {
      city_id: "6603",
      city: "Gambrills",
      zip_code: "21054",
      state_id: "MD",
      state_name: "Maryland",
    },
    {
      city_id: "6562",
      city: "Garrett Park",
      zip_code: "20896",
      state_id: "MD",
      state_name: "Maryland",
    },
    {
      city_id: "6886",
      city: "Georgetown",
      zip_code: "21930",
      state_id: "MD",
      state_name: "Maryland",
    },
    {
      city_id: "6550",
      city: "Germantown",
      zip_code: "20874",
      state_id: "MD",
      state_name: "Maryland",
    },
    {
      city_id: "6604",
      city: "Gibson Island",
      zip_code: "21056",
      state_id: "MD",
      state_name: "Maryland",
    },
    {
      city_id: "6846",
      city: "Girdletree",
      zip_code: "21829",
      state_id: "MD",
      state_name: "Maryland",
    },
    {
      city_id: "6605",
      city: "Glen Arm",
      zip_code: "21057",
      state_id: "MD",
      state_name: "Maryland",
    },
    {
      city_id: "6606",
      city: "Glen Burnie",
      zip_code: "21060",
      state_id: "MD",
      state_name: "Maryland",
    },
    {
      city_id: "6524",
      city: "Glen Echo",
      zip_code: "20812",
      state_id: "MD",
      state_name: "Maryland",
    },
    {
      city_id: "6798",
      city: "Glenelg",
      zip_code: "21737",
      state_id: "MD",
      state_name: "Maryland",
    },
    {
      city_id: "6509",
      city: "Glenn Dale",
      zip_code: "20769",
      state_id: "MD",
      state_name: "Maryland",
    },
    {
      city_id: "6799",
      city: "Glenwood",
      zip_code: "21738",
      state_id: "MD",
      state_name: "Maryland",
    },
    {
      city_id: "6608",
      city: "Glyndon",
      zip_code: "21071",
      state_id: "MD",
      state_name: "Maryland",
    },
    {
      city_id: "6741",
      city: "Goldsboro",
      zip_code: "21636",
      state_id: "MD",
      state_name: "Maryland",
    },
    {
      city_id: "6707",
      city: "Grantsville",
      zip_code: "21536",
      state_id: "MD",
      state_name: "Maryland",
    },
    {
      city_id: "6742",
      city: "Grasonville",
      zip_code: "21638",
      state_id: "MD",
      state_name: "Maryland",
    },
    {
      city_id: "6436",
      city: "Great Mills",
      zip_code: "20634",
      state_id: "MD",
      state_name: "Maryland",
    },
    {
      city_id: "6510",
      city: "Greenbelt",
      zip_code: "20770",
      state_id: "MD",
      state_name: "Maryland",
    },
    {
      city_id: "6743",
      city: "Greensboro",
      zip_code: "21639",
      state_id: "MD",
      state_name: "Maryland",
    },
    {
      city_id: "6575",
      city: "Gunpowder",
      zip_code: "21010",
      state_id: "MD",
      state_name: "Maryland",
    },
    {
      city_id: "6655",
      city: "Gwynn Oak",
      zip_code: "21207",
      state_id: "MD",
      state_name: "Maryland",
    },
    {
      city_id: "6800",
      city: "Hagerstown",
      zip_code: "21740",
      state_id: "MD",
      state_name: "Maryland",
    },
    {
      city_id: "6675",
      city: "Halethorpe",
      zip_code: "21227",
      state_id: "MD",
      state_name: "Maryland",
    },
    {
      city_id: "6609",
      city: "Hampstead",
      zip_code: "21074",
      state_id: "MD",
      state_name: "Maryland",
    },
    {
      city_id: "6803",
      city: "Hancock",
      zip_code: "21750",
      state_id: "MD",
      state_name: "Maryland",
    },
    {
      city_id: "6611",
      city: "Hanover",
      zip_code: "21076",
      state_id: "MD",
      state_name: "Maryland",
    },
    {
      city_id: "6612",
      city: "Harmans",
      zip_code: "21077",
      state_id: "MD",
      state_name: "Maryland",
    },
    {
      city_id: "6514",
      city: "Harwood",
      zip_code: "20776",
      state_id: "MD",
      state_name: "Maryland",
    },
    {
      city_id: "6613",
      city: "Havre De Grace",
      zip_code: "21078",
      state_id: "MD",
      state_name: "Maryland",
    },
    {
      city_id: "6847",
      city: "Hebron",
      zip_code: "21830",
      state_id: "MD",
      state_name: "Maryland",
    },
    {
      city_id: "6744",
      city: "Henderson",
      zip_code: "21640",
      state_id: "MD",
      state_name: "Maryland",
    },
    {
      city_id: "6515",
      city: "Highland",
      zip_code: "20777",
      state_id: "MD",
      state_name: "Maryland",
    },
    {
      city_id: "6745",
      city: "Hillsboro",
      zip_code: "21641",
      state_id: "MD",
      state_name: "Maryland",
    },
    {
      city_id: "6437",
      city: "Hollywood",
      zip_code: "20636",
      state_id: "MD",
      state_name: "Maryland",
    },
    {
      city_id: "6438",
      city: "Hughesville",
      zip_code: "20637",
      state_id: "MD",
      state_name: "Maryland",
    },
    {
      city_id: "6585",
      city: "Hunt Valley",
      zip_code: "21031",
      state_id: "MD",
      state_name: "Maryland",
    },
    {
      city_id: "6439",
      city: "Huntingtown",
      zip_code: "20639",
      state_id: "MD",
      state_name: "Maryland",
    },
    {
      city_id: "6746",
      city: "Hurlock",
      zip_code: "21643",
      state_id: "MD",
      state_name: "Maryland",
    },
    {
      city_id: "6518",
      city: "Hyattsville",
      zip_code: "20781",
      state_id: "MD",
      state_name: "Maryland",
    },
    {
      city_id: "6614",
      city: "Hydes",
      zip_code: "21082",
      state_id: "MD",
      state_name: "Maryland",
    },
    {
      city_id: "6804",
      city: "Ijamsville",
      zip_code: "21754",
      state_id: "MD",
      state_name: "Maryland",
    },
    {
      city_id: "6440",
      city: "Indian Head",
      zip_code: "20640",
      state_id: "MD",
      state_name: "Maryland",
    },
    {
      city_id: "6747",
      city: "Ingleside",
      zip_code: "21644",
      state_id: "MD",
      state_name: "Maryland",
    },
    {
      city_id: "6441",
      city: "Issue",
      zip_code: "20645",
      state_id: "MD",
      state_name: "Maryland",
    },
    {
      city_id: "6615",
      city: "Jarrettsville",
      zip_code: "21084",
      state_id: "MD",
      state_name: "Maryland",
    },
    {
      city_id: "6805",
      city: "Jefferson",
      zip_code: "21755",
      state_id: "MD",
      state_name: "Maryland",
    },
    {
      city_id: "6523",
      city: "Jessup",
      zip_code: "20794",
      state_id: "MD",
      state_name: "Maryland",
    },
    {
      city_id: "6616",
      city: "Joppa",
      zip_code: "21085",
      state_id: "MD",
      state_name: "Maryland",
    },
    {
      city_id: "6806",
      city: "Keedysville",
      zip_code: "21756",
      state_id: "MD",
      state_name: "Maryland",
    },
    {
      city_id: "6748",
      city: "Kennedyville",
      zip_code: "21645",
      state_id: "MD",
      state_name: "Maryland",
    },
    {
      city_id: "6561",
      city: "Kensington",
      zip_code: "20895",
      state_id: "MD",
      state_name: "Maryland",
    },
    {
      city_id: "6807",
      city: "Keymar",
      zip_code: "21757",
      state_id: "MD",
      state_name: "Maryland",
    },
    {
      city_id: "6617",
      city: "Kingsville",
      zip_code: "21087",
      state_id: "MD",
      state_name: "Maryland",
    },
    {
      city_id: "6708",
      city: "Kitzmiller",
      zip_code: "21538",
      state_id: "MD",
      state_name: "Maryland",
    },
    {
      city_id: "6808",
      city: "Knoxville",
      zip_code: "21758",
      state_id: "MD",
      state_name: "Maryland",
    },
    {
      city_id: "6442",
      city: "La Plata",
      zip_code: "20646",
      state_id: "MD",
      state_name: "Maryland",
    },
    {
      city_id: "6473",
      city: "Lanham",
      zip_code: "20706",
      state_id: "MD",
      state_name: "Maryland",
    },
    {
      city_id: "6474",
      city: "Laurel",
      zip_code: "20707",
      state_id: "MD",
      state_name: "Maryland",
    },
    {
      city_id: "6443",
      city: "Leonardtown",
      zip_code: "20650",
      state_id: "MD",
      state_name: "Maryland",
    },
    {
      city_id: "6444",
      city: "Lexington Park",
      zip_code: "20653",
      state_id: "MD",
      state_name: "Maryland",
    },
    {
      city_id: "6809",
      city: "Libertytown",
      zip_code: "21762",
      state_id: "MD",
      state_name: "Maryland",
    },
    {
      city_id: "6848",
      city: "Linkwood",
      zip_code: "21835",
      state_id: "MD",
      state_name: "Maryland",
    },
    {
      city_id: "6618",
      city: "Linthicum Heights",
      zip_code: "21090",
      state_id: "MD",
      state_name: "Maryland",
    },
    {
      city_id: "6810",
      city: "Little Orleans",
      zip_code: "21766",
      state_id: "MD",
      state_name: "Maryland",
    },
    {
      city_id: "6709",
      city: "Lonaconing",
      zip_code: "21539",
      state_id: "MD",
      state_name: "Maryland",
    },
    {
      city_id: "6477",
      city: "Lothian",
      zip_code: "20711",
      state_id: "MD",
      state_name: "Maryland",
    },
    {
      city_id: "6445",
      city: "Loveville",
      zip_code: "20656",
      state_id: "MD",
      state_name: "Maryland",
    },
    {
      city_id: "6710",
      city: "Luke",
      zip_code: "21540",
      state_id: "MD",
      state_name: "Maryland",
    },
    {
      city_id: "6446",
      city: "Lusby",
      zip_code: "20657",
      state_id: "MD",
      state_name: "Maryland",
    },
    {
      city_id: "6619",
      city: "Lutherville Timonium",
      zip_code: "21093",
      state_id: "MD",
      state_name: "Maryland",
    },
    {
      city_id: "6750",
      city: "Madison",
      zip_code: "21648",
      state_id: "MD",
      state_name: "Maryland",
    },
    {
      city_id: "6620",
      city: "Manchester",
      zip_code: "21102",
      state_id: "MD",
      state_name: "Maryland",
    },
    {
      city_id: "6447",
      city: "Marbury",
      zip_code: "20658",
      state_id: "MD",
      state_name: "Maryland",
    },
    {
      city_id: "6849",
      city: "Mardela Springs",
      zip_code: "21837",
      state_id: "MD",
      state_name: "Maryland",
    },
    {
      city_id: "6850",
      city: "Marion Station",
      zip_code: "21838",
      state_id: "MD",
      state_name: "Maryland",
    },
    {
      city_id: "6621",
      city: "Marriottsville",
      zip_code: "21104",
      state_id: "MD",
      state_name: "Maryland",
    },
    {
      city_id: "6751",
      city: "Marydel",
      zip_code: "21649",
      state_id: "MD",
      state_name: "Maryland",
    },
    {
      city_id: "6622",
      city: "Maryland Line",
      zip_code: "21105",
      state_id: "MD",
      state_name: "Maryland",
    },
    {
      city_id: "6752",
      city: "Massey",
      zip_code: "21650",
      state_id: "MD",
      state_name: "Maryland",
    },
    {
      city_id: "6811",
      city: "Maugansville",
      zip_code: "21767",
      state_id: "MD",
      state_name: "Maryland",
    },
    {
      city_id: "6749",
      city: "Mcdaniel",
      zip_code: "21647",
      state_id: "MD",
      state_name: "Maryland",
    },
    {
      city_id: "6711",
      city: "McHenry",
      zip_code: "21541",
      state_id: "MD",
      state_name: "Maryland",
    },
    {
      city_id: "6448",
      city: "Mechanicsville",
      zip_code: "20659",
      state_id: "MD",
      state_name: "Maryland",
    },
    {
      city_id: "6668",
      city: "Middle River",
      zip_code: "21220",
      state_id: "MD",
      state_name: "Maryland",
    },
    {
      city_id: "6812",
      city: "Middletown",
      zip_code: "21769",
      state_id: "MD",
      state_name: "Maryland",
    },
    {
      city_id: "6712",
      city: "Midland",
      zip_code: "21542",
      state_id: "MD",
      state_name: "Maryland",
    },
    {
      city_id: "6713",
      city: "Midlothian",
      zip_code: "21543",
      state_id: "MD",
      state_name: "Maryland",
    },
    {
      city_id: "6623",
      city: "Millersville",
      zip_code: "21108",
      state_id: "MD",
      state_name: "Maryland",
    },
    {
      city_id: "6753",
      city: "Millington",
      zip_code: "21651",
      state_id: "MD",
      state_name: "Maryland",
    },
    {
      city_id: "6624",
      city: "Monkton",
      zip_code: "21111",
      state_id: "MD",
      state_name: "Maryland",
    },
    {
      city_id: "6813",
      city: "Monrovia",
      zip_code: "21770",
      state_id: "MD",
      state_name: "Maryland",
    },
    {
      city_id: "6557",
      city: "Montgomery Village",
      zip_code: "20886",
      state_id: "MD",
      state_name: "Maryland",
    },
    {
      city_id: "6449",
      city: "Morganza",
      zip_code: "20660",
      state_id: "MD",
      state_name: "Maryland",
    },
    {
      city_id: "6814",
      city: "Mount Airy",
      zip_code: "21771",
      state_id: "MD",
      state_name: "Maryland",
    },
    {
      city_id: "6478",
      city: "Mount Rainier",
      zip_code: "20712",
      state_id: "MD",
      state_name: "Maryland",
    },
    {
      city_id: "6714",
      city: "Mount Savage",
      zip_code: "21545",
      state_id: "MD",
      state_name: "Maryland",
    },
    {
      city_id: "6450",
      city: "Mount Victoria",
      zip_code: "20661",
      state_id: "MD",
      state_name: "Maryland",
    },
    {
      city_id: "6815",
      city: "Myersville",
      zip_code: "21773",
      state_id: "MD",
      state_name: "Maryland",
    },
    {
      city_id: "6451",
      city: "Nanjemoy",
      zip_code: "20662",
      state_id: "MD",
      state_name: "Maryland",
    },
    {
      city_id: "6851",
      city: "Nanticoke",
      zip_code: "21840",
      state_id: "MD",
      state_name: "Maryland",
    },
    {
      city_id: "6754",
      city: "Neavitt",
      zip_code: "21652",
      state_id: "MD",
      state_name: "Maryland",
    },
    {
      city_id: "6816",
      city: "New Market",
      zip_code: "21774",
      state_id: "MD",
      state_name: "Maryland",
    },
    {
      city_id: "6817",
      city: "New Windsor",
      zip_code: "21776",
      state_id: "MD",
      state_name: "Maryland",
    },
    {
      city_id: "6852",
      city: "Newark",
      zip_code: "21841",
      state_id: "MD",
      state_name: "Maryland",
    },
    {
      city_id: "6452",
      city: "Newburg",
      zip_code: "20664",
      state_id: "MD",
      state_name: "Maryland",
    },
    {
      city_id: "6755",
      city: "Newcomb",
      zip_code: "21653",
      state_id: "MD",
      state_name: "Maryland",
    },
    {
      city_id: "6479",
      city: "North Beach",
      zip_code: "20714",
      state_id: "MD",
      state_name: "Maryland",
    },
    {
      city_id: "6872",
      city: "North East",
      zip_code: "21901",
      state_id: "MD",
      state_name: "Maryland",
    },
    {
      city_id: "6682",
      city: "Nottingham",
      zip_code: "21236",
      state_id: "MD",
      state_name: "Maryland",
    },
    {
      city_id: "6715",
      city: "Oakland",
      zip_code: "21550",
      state_id: "MD",
      state_name: "Maryland",
    },
    {
      city_id: "6853",
      city: "Ocean City",
      zip_code: "21842",
      state_id: "MD",
      state_name: "Maryland",
    },
    {
      city_id: "6625",
      city: "Odenton",
      zip_code: "21113",
      state_id: "MD",
      state_name: "Maryland",
    },
    {
      city_id: "6716",
      city: "Oldtown",
      zip_code: "21555",
      state_id: "MD",
      state_name: "Maryland",
    },
    {
      city_id: "6530",
      city: "Olney",
      zip_code: "20832",
      state_id: "MD",
      state_name: "Maryland",
    },
    {
      city_id: "6490",
      city: "Owings",
      zip_code: "20736",
      state_id: "MD",
      state_name: "Maryland",
    },
    {
      city_id: "6627",
      city: "Owings Mills",
      zip_code: "21117",
      state_id: "MD",
      state_name: "Maryland",
    },
    {
      city_id: "6756",
      city: "Oxford",
      zip_code: "21654",
      state_id: "MD",
      state_name: "Maryland",
    },
    {
      city_id: "6496",
      city: "Oxon Hill",
      zip_code: "20745",
      state_id: "MD",
      state_name: "Maryland",
    },
    {
      city_id: "6453",
      city: "Park Hall",
      zip_code: "20667",
      state_id: "MD",
      state_name: "Maryland",
    },
    {
      city_id: "6628",
      city: "Parkton",
      zip_code: "21120",
      state_id: "MD",
      state_name: "Maryland",
    },
    {
      city_id: "6681",
      city: "Parkville",
      zip_code: "21234",
      state_id: "MD",
      state_name: "Maryland",
    },
    {
      city_id: "6854",
      city: "Parsonsburg",
      zip_code: "21849",
      state_id: "MD",
      state_name: "Maryland",
    },
    {
      city_id: "6629",
      city: "Pasadena",
      zip_code: "21122",
      state_id: "MD",
      state_name: "Maryland",
    },
    {
      city_id: "6454",
      city: "Patuxent River",
      zip_code: "20670",
      state_id: "MD",
      state_name: "Maryland",
    },
    {
      city_id: "6630",
      city: "Perry Hall",
      zip_code: "21128",
      state_id: "MD",
      state_name: "Maryland",
    },
    {
      city_id: "6873",
      city: "Perry Point",
      zip_code: "21902",
      state_id: "MD",
      state_name: "Maryland",
    },
    {
      city_id: "6631",
      city: "Perryman",
      zip_code: "21130",
      state_id: "MD",
      state_name: "Maryland",
    },
    {
      city_id: "6874",
      city: "Perryville",
      zip_code: "21903",
      state_id: "MD",
      state_name: "Maryland",
    },
    {
      city_id: "6632",
      city: "Phoenix",
      zip_code: "21131",
      state_id: "MD",
      state_name: "Maryland",
    },
    {
      city_id: "6656",
      city: "Pikesville",
      zip_code: "21208",
      state_id: "MD",
      state_name: "Maryland",
    },
    {
      city_id: "6455",
      city: "Piney Point",
      zip_code: "20674",
      state_id: "MD",
      state_name: "Maryland",
    },
    {
      city_id: "6855",
      city: "Pittsville",
      zip_code: "21850",
      state_id: "MD",
      state_name: "Maryland",
    },
    {
      city_id: "6856",
      city: "Pocomoke City",
      zip_code: "21851",
      state_id: "MD",
      state_name: "Maryland",
    },
    {
      city_id: "6818",
      city: "Point Of Rocks",
      zip_code: "21777",
      state_id: "MD",
      state_name: "Maryland",
    },
    {
      city_id: "6456",
      city: "Pomfret",
      zip_code: "20675",
      state_id: "MD",
      state_name: "Maryland",
    },
    {
      city_id: "6532",
      city: "Poolesville",
      zip_code: "20837",
      state_id: "MD",
      state_name: "Maryland",
    },
    {
      city_id: "6875",
      city: "Port Deposit",
      zip_code: "21904",
      state_id: "MD",
      state_name: "Maryland",
    },
    {
      city_id: "6457",
      city: "Port Republic",
      zip_code: "20676",
      state_id: "MD",
      state_name: "Maryland",
    },
    {
      city_id: "6458",
      city: "Port Tobacco",
      zip_code: "20677",
      state_id: "MD",
      state_name: "Maryland",
    },
    {
      city_id: "6541",
      city: "Potomac",
      zip_code: "20854",
      state_id: "MD",
      state_name: "Maryland",
    },
    {
      city_id: "6757",
      city: "Preston",
      zip_code: "21655",
      state_id: "MD",
      state_name: "Maryland",
    },
    {
      city_id: "6459",
      city: "Prince Frederick",
      zip_code: "20678",
      state_id: "MD",
      state_name: "Maryland",
    },
    {
      city_id: "6857",
      city: "Princess Anne",
      zip_code: "21853",
      state_id: "MD",
      state_name: "Maryland",
    },
    {
      city_id: "6633",
      city: "Pylesville",
      zip_code: "21132",
      state_id: "MD",
      state_name: "Maryland",
    },
    {
      city_id: "6858",
      city: "Quantico",
      zip_code: "21856",
      state_id: "MD",
      state_name: "Maryland",
    },
    {
      city_id: "6758",
      city: "Queen Anne",
      zip_code: "21657",
      state_id: "MD",
      state_name: "Maryland",
    },
    {
      city_id: "6759",
      city: "Queenstown",
      zip_code: "21658",
      state_id: "MD",
      state_name: "Maryland",
    },
    {
      city_id: "6634",
      city: "Randallstown",
      zip_code: "21133",
      state_id: "MD",
      state_name: "Maryland",
    },
    {
      city_id: "6717",
      city: "Rawlings",
      zip_code: "21557",
      state_id: "MD",
      state_name: "Maryland",
    },
    {
      city_id: "6635",
      city: "Reisterstown",
      zip_code: "21136",
      state_id: "MD",
      state_name: "Maryland",
    },
    {
      city_id: "6760",
      city: "Rhodesdale",
      zip_code: "21659",
      state_id: "MD",
      state_name: "Maryland",
    },
    {
      city_id: "6460",
      city: "Ridge",
      zip_code: "20680",
      state_id: "MD",
      state_name: "Maryland",
    },
    {
      city_id: "6761",
      city: "Ridgely",
      zip_code: "21660",
      state_id: "MD",
      state_name: "Maryland",
    },
    {
      city_id: "6876",
      city: "Rising Sun",
      zip_code: "21911",
      state_id: "MD",
      state_name: "Maryland",
    },
    {
      city_id: "6636",
      city: "Riva",
      zip_code: "21140",
      state_id: "MD",
      state_name: "Maryland",
    },
    {
      city_id: "6491",
      city: "Riverdale",
      zip_code: "20737",
      state_id: "MD",
      state_name: "Maryland",
    },
    {
      city_id: "6762",
      city: "Rock Hall",
      zip_code: "21661",
      state_id: "MD",
      state_name: "Maryland",
    },
    {
      city_id: "6537",
      city: "Rockville",
      zip_code: "20850",
      state_id: "MD",
      state_name: "Maryland",
    },
    {
      city_id: "6819",
      city: "Rocky Ridge",
      zip_code: "21778",
      state_id: "MD",
      state_name: "Maryland",
    },
    {
      city_id: "6820",
      city: "Rohrersville",
      zip_code: "21779",
      state_id: "MD",
      state_name: "Maryland",
    },
    {
      city_id: "6683",
      city: "Rosedale",
      zip_code: "21237",
      state_id: "MD",
      state_name: "Maryland",
    },
    {
      city_id: "6763",
      city: "Royal Oak",
      zip_code: "21662",
      state_id: "MD",
      state_name: "Maryland",
    },
    {
      city_id: "6821",
      city: "Sabillasville",
      zip_code: "21780",
      state_id: "MD",
      state_name: "Maryland",
    },
    {
      city_id: "6461",
      city: "Saint Inigoes",
      zip_code: "20684",
      state_id: "MD",
      state_name: "Maryland",
    },
    {
      city_id: "6822",
      city: "Saint James",
      zip_code: "21781",
      state_id: "MD",
      state_name: "Maryland",
    },
    {
      city_id: "6462",
      city: "Saint Leonard",
      zip_code: "20685",
      state_id: "MD",
      state_name: "Maryland",
    },
    {
      city_id: "6463",
      city: "Saint Marys City",
      zip_code: "20686",
      state_id: "MD",
      state_name: "Maryland",
    },
    {
      city_id: "6764",
      city: "Saint Michaels",
      zip_code: "21663",
      state_id: "MD",
      state_name: "Maryland",
    },
    {
      city_id: "6835",
      city: "Salisbury",
      zip_code: "21801",
      state_id: "MD",
      state_name: "Maryland",
    },
    {
      city_id: "6543",
      city: "Sandy Spring",
      zip_code: "20860",
      state_id: "MD",
      state_name: "Maryland",
    },
    {
      city_id: "6506",
      city: "Savage",
      zip_code: "20763",
      state_id: "MD",
      state_name: "Maryland",
    },
    {
      city_id: "6464",
      city: "Scotland",
      zip_code: "20687",
      state_id: "MD",
      state_name: "Maryland",
    },
    {
      city_id: "6765",
      city: "Secretary",
      zip_code: "21664",
      state_id: "MD",
      state_name: "Maryland",
    },
    {
      city_id: "6637",
      city: "Severn",
      zip_code: "21144",
      state_id: "MD",
      state_name: "Maryland",
    },
    {
      city_id: "6638",
      city: "Severna Park",
      zip_code: "21146",
      state_id: "MD",
      state_name: "Maryland",
    },
    {
      city_id: "6507",
      city: "Shady Side",
      zip_code: "20764",
      state_id: "MD",
      state_name: "Maryland",
    },
    {
      city_id: "6823",
      city: "Sharpsburg",
      zip_code: "21782",
      state_id: "MD",
      state_name: "Maryland",
    },
    {
      city_id: "6859",
      city: "Sharptown",
      zip_code: "21861",
      state_id: "MD",
      state_name: "Maryland",
    },
    {
      city_id: "6766",
      city: "Sherwood",
      zip_code: "21665",
      state_id: "MD",
      state_name: "Maryland",
    },
    {
      city_id: "6860",
      city: "Showell",
      zip_code: "21862",
      state_id: "MD",
      state_name: "Maryland",
    },
    {
      city_id: "6564",
      city: "Silver Spring",
      zip_code: "20901",
      state_id: "MD",
      state_name: "Maryland",
    },
    {
      city_id: "6824",
      city: "Smithsburg",
      zip_code: "21783",
      state_id: "MD",
      state_name: "Maryland",
    },
    {
      city_id: "6861",
      city: "Snow Hill",
      zip_code: "21863",
      state_id: "MD",
      state_name: "Maryland",
    },
    {
      city_id: "6465",
      city: "Solomons",
      zip_code: "20688",
      state_id: "MD",
      state_name: "Maryland",
    },
    {
      city_id: "6639",
      city: "Sparks Glencoe",
      zip_code: "21152",
      state_id: "MD",
      state_name: "Maryland",
    },
    {
      city_id: "6667",
      city: "Sparrows Point",
      zip_code: "21219",
      state_id: "MD",
      state_name: "Maryland",
    },
    {
      city_id: "6547",
      city: "Spencerville",
      zip_code: "20868",
      state_id: "MD",
      state_name: "Maryland",
    },
    {
      city_id: "6718",
      city: "Spring Gap",
      zip_code: "21560",
      state_id: "MD",
      state_name: "Maryland",
    },
    {
      city_id: "6640",
      city: "Stevenson",
      zip_code: "21153",
      state_id: "MD",
      state_name: "Maryland",
    },
    {
      city_id: "6767",
      city: "Stevensville",
      zip_code: "21666",
      state_id: "MD",
      state_name: "Maryland",
    },
    {
      city_id: "6768",
      city: "Still Pond",
      zip_code: "21667",
      state_id: "MD",
      state_name: "Maryland",
    },
    {
      city_id: "6862",
      city: "Stockton",
      zip_code: "21864",
      state_id: "MD",
      state_name: "Maryland",
    },
    {
      city_id: "6641",
      city: "Street",
      zip_code: "21154",
      state_id: "MD",
      state_name: "Maryland",
    },
    {
      city_id: "6769",
      city: "Sudlersville",
      zip_code: "21668",
      state_id: "MD",
      state_name: "Maryland",
    },
    {
      city_id: "6497",
      city: "Suitland",
      zip_code: "20746",
      state_id: "MD",
      state_name: "Maryland",
    },
    {
      city_id: "6466",
      city: "Sunderland",
      zip_code: "20689",
      state_id: "MD",
      state_name: "Maryland",
    },
    {
      city_id: "6719",
      city: "Swanton",
      zip_code: "21561",
      state_id: "MD",
      state_name: "Maryland",
    },
    {
      city_id: "6825",
      city: "Sykesville",
      zip_code: "21784",
      state_id: "MD",
      state_name: "Maryland",
    },
    {
      city_id: "6571",
      city: "Takoma Park",
      zip_code: "20912",
      state_id: "MD",
      state_name: "Maryland",
    },
    {
      city_id: "6467",
      city: "Tall Timbers",
      zip_code: "20690",
      state_id: "MD",
      state_name: "Maryland",
    },
    {
      city_id: "6826",
      city: "Taneytown",
      zip_code: "21787",
      state_id: "MD",
      state_name: "Maryland",
    },
    {
      city_id: "6770",
      city: "Taylors Island",
      zip_code: "21669",
      state_id: "MD",
      state_name: "Maryland",
    },
    {
      city_id: "6499",
      city: "Temple Hills",
      zip_code: "20748",
      state_id: "MD",
      state_name: "Maryland",
    },
    {
      city_id: "6827",
      city: "Thurmont",
      zip_code: "21788",
      state_id: "MD",
      state_name: "Maryland",
    },
    {
      city_id: "6771",
      city: "Tilghman",
      zip_code: "21671",
      state_id: "MD",
      state_name: "Maryland",
    },
    {
      city_id: "6772",
      city: "Toddville",
      zip_code: "21672",
      state_id: "MD",
      state_name: "Maryland",
    },
    {
      city_id: "6652",
      city: "Towson",
      zip_code: "21204",
      state_id: "MD",
      state_name: "Maryland",
    },
    {
      city_id: "6517",
      city: "Tracys Landing",
      zip_code: "20779",
      state_id: "MD",
      state_name: "Maryland",
    },
    {
      city_id: "6773",
      city: "Trappe",
      zip_code: "21673",
      state_id: "MD",
      state_name: "Maryland",
    },
    {
      city_id: "6828",
      city: "Tuscarora",
      zip_code: "21790",
      state_id: "MD",
      state_name: "Maryland",
    },
    {
      city_id: "6863",
      city: "Tyaskin",
      zip_code: "21865",
      state_id: "MD",
      state_name: "Maryland",
    },
    {
      city_id: "6864",
      city: "Tylerton",
      zip_code: "21866",
      state_id: "MD",
      state_name: "Maryland",
    },
    {
      city_id: "6829",
      city: "Union Bridge",
      zip_code: "21791",
      state_id: "MD",
      state_name: "Maryland",
    },
    {
      city_id: "6865",
      city: "Upper Fairmount",
      zip_code: "21867",
      state_id: "MD",
      state_name: "Maryland",
    },
    {
      city_id: "6643",
      city: "Upper Falls",
      zip_code: "21156",
      state_id: "MD",
      state_name: "Maryland",
    },
    {
      city_id: "6512",
      city: "Upper Marlboro",
      zip_code: "20772",
      state_id: "MD",
      state_name: "Maryland",
    },
    {
      city_id: "6642",
      city: "Upperco",
      zip_code: "21155",
      state_id: "MD",
      state_name: "Maryland",
    },
    {
      city_id: "6468",
      city: "Valley Lee",
      zip_code: "20692",
      state_id: "MD",
      state_name: "Maryland",
    },
    {
      city_id: "6866",
      city: "Vienna",
      zip_code: "21869",
      state_id: "MD",
      state_name: "Maryland",
    },
    {
      city_id: "6410",
      city: "Waldorf",
      zip_code: "20601",
      state_id: "MD",
      state_name: "Maryland",
    },
    {
      city_id: "6830",
      city: "Walkersville",
      zip_code: "21793",
      state_id: "MD",
      state_name: "Maryland",
    },
    {
      city_id: "6877",
      city: "Warwick",
      zip_code: "21912",
      state_id: "MD",
      state_name: "Maryland",
    },
    {
      city_id: "6555",
      city: "Washington Grove",
      zip_code: "20880",
      state_id: "MD",
      state_name: "Maryland",
    },
    {
      city_id: "6469",
      city: "Welcome",
      zip_code: "20693",
      state_id: "MD",
      state_name: "Maryland",
    },
    {
      city_id: "6831",
      city: "West Friendship",
      zip_code: "21794",
      state_id: "MD",
      state_name: "Maryland",
    },
    {
      city_id: "6516",
      city: "West River",
      zip_code: "20778",
      state_id: "MD",
      state_name: "Maryland",
    },
    {
      city_id: "6720",
      city: "Westernport",
      zip_code: "21562",
      state_id: "MD",
      state_name: "Maryland",
    },
    {
      city_id: "6644",
      city: "Westminster",
      zip_code: "21157",
      state_id: "MD",
      state_name: "Maryland",
    },
    {
      city_id: "6867",
      city: "Westover",
      zip_code: "21871",
      state_id: "MD",
      state_name: "Maryland",
    },
    {
      city_id: "6868",
      city: "Whaleyville",
      zip_code: "21872",
      state_id: "MD",
      state_name: "Maryland",
    },
    {
      city_id: "6647",
      city: "White Hall",
      zip_code: "21161",
      state_id: "MD",
      state_name: "Maryland",
    },
    {
      city_id: "6648",
      city: "White Marsh",
      zip_code: "21162",
      state_id: "MD",
      state_name: "Maryland",
    },
    {
      city_id: "6470",
      city: "White Plains",
      zip_code: "20695",
      state_id: "MD",
      state_name: "Maryland",
    },
    {
      city_id: "6646",
      city: "Whiteford",
      zip_code: "21160",
      state_id: "MD",
      state_name: "Maryland",
    },
    {
      city_id: "6869",
      city: "Willards",
      zip_code: "21874",
      state_id: "MD",
      state_name: "Maryland",
    },
    {
      city_id: "6832",
      city: "Williamsport",
      zip_code: "21795",
      state_id: "MD",
      state_name: "Maryland",
    },
    {
      city_id: "6686",
      city: "Windsor Mill",
      zip_code: "21244",
      state_id: "MD",
      state_name: "Maryland",
    },
    {
      city_id: "6774",
      city: "Wingate",
      zip_code: "21675",
      state_id: "MD",
      state_name: "Maryland",
    },
    {
      city_id: "6775",
      city: "Wittman",
      zip_code: "21676",
      state_id: "MD",
      state_name: "Maryland",
    },
    {
      city_id: "6833",
      city: "Woodbine",
      zip_code: "21797",
      state_id: "MD",
      state_name: "Maryland",
    },
    {
      city_id: "6834",
      city: "Woodsboro",
      zip_code: "21798",
      state_id: "MD",
      state_name: "Maryland",
    },
    {
      city_id: "6649",
      city: "Woodstock",
      zip_code: "21163",
      state_id: "MD",
      state_name: "Maryland",
    },
    {
      city_id: "6776",
      city: "Woolford",
      zip_code: "21677",
      state_id: "MD",
      state_name: "Maryland",
    },
    {
      city_id: "6777",
      city: "Worton",
      zip_code: "21678",
      state_id: "MD",
      state_name: "Maryland",
    },
    {
      city_id: "6778",
      city: "Wye Mills",
      zip_code: "21679",
      state_id: "MD",
      state_name: "Maryland",
    },
    {
      city_id: "1158",
      city: "Abbot",
      zip_code: "04406",
      state_id: "ME",
      state_name: "Maine",
    },
    {
      city_id: "1017",
      city: "Acton",
      zip_code: "04001",
      state_id: "ME",
      state_name: "Maine",
    },
    {
      city_id: "1253",
      city: "Addison",
      zip_code: "04606",
      state_id: "ME",
      state_name: "Maine",
    },
    {
      city_id: "1369",
      city: "Albion",
      zip_code: "04910",
      state_id: "ME",
      state_name: "Maine",
    },
    {
      city_id: "1018",
      city: "Alfred",
      zip_code: "04002",
      state_id: "ME",
      state_name: "Maine",
    },
    {
      city_id: "1224",
      city: "Alna",
      zip_code: "04535",
      state_id: "ME",
      state_name: "Maine",
    },
    {
      city_id: "1089",
      city: "Andover",
      zip_code: "04216",
      state_id: "ME",
      state_name: "Maine",
    },
    {
      city_id: "1370",
      city: "Anson",
      zip_code: "04911",
      state_id: "ME",
      state_name: "Maine",
    },
    {
      city_id: "1312",
      city: "Ashland",
      zip_code: "04732",
      state_id: "ME",
      state_name: "Maine",
    },
    {
      city_id: "1371",
      city: "Athens",
      zip_code: "04912",
      state_id: "ME",
      state_name: "Maine",
    },
    {
      city_id: "1088",
      city: "Auburn",
      zip_code: "04210",
      state_id: "ME",
      state_name: "Maine",
    },
    {
      city_id: "1136",
      city: "Augusta",
      zip_code: "04330",
      state_id: "ME",
      state_name: "Maine",
    },
    {
      city_id: "1159",
      city: "Aurora",
      zip_code: "04408",
      state_id: "ME",
      state_name: "Maine",
    },
    {
      city_id: "1019",
      city: "Bailey Island",
      zip_code: "04003",
      state_id: "ME",
      state_name: "Maine",
    },
    {
      city_id: "1157",
      city: "Bangor",
      zip_code: "04401",
      state_id: "ME",
      state_name: "Maine",
    },
    {
      city_id: "1255",
      city: "Bar Harbor",
      zip_code: "04609",
      state_id: "ME",
      state_name: "Maine",
    },
    {
      city_id: "1283",
      city: "Bass Harbor",
      zip_code: "04653",
      state_id: "ME",
      state_name: "Maine",
    },
    {
      city_id: "1223",
      city: "Bath",
      zip_code: "04530",
      state_id: "ME",
      state_name: "Maine",
    },
    {
      city_id: "1256",
      city: "Beals",
      zip_code: "04611",
      state_id: "ME",
      state_name: "Maine",
    },
    {
      city_id: "1372",
      city: "Belfast",
      zip_code: "04915",
      state_id: "ME",
      state_name: "Maine",
    },
    {
      city_id: "1373",
      city: "Belgrade",
      zip_code: "04917",
      state_id: "ME",
      state_name: "Maine",
    },
    {
      city_id: "1374",
      city: "Belgrade Lakes",
      zip_code: "04918",
      state_id: "ME",
      state_name: "Maine",
    },
    {
      city_id: "1313",
      city: "Benedicta",
      zip_code: "04733",
      state_id: "ME",
      state_name: "Maine",
    },
    {
      city_id: "1257",
      city: "Bernard",
      zip_code: "04612",
      state_id: "ME",
      state_name: "Maine",
    },
    {
      city_id: "1006",
      city: "Berwick",
      zip_code: "03901",
      state_id: "ME",
      state_name: "Maine",
    },
    {
      city_id: "1090",
      city: "Bethel",
      zip_code: "04217",
      state_id: "ME",
      state_name: "Maine",
    },
    {
      city_id: "1020",
      city: "Biddeford",
      zip_code: "04005",
      state_id: "ME",
      state_name: "Maine",
    },
    {
      city_id: "1021",
      city: "Biddeford Pool",
      zip_code: "04006",
      state_id: "ME",
      state_name: "Maine",
    },
    {
      city_id: "1375",
      city: "Bingham",
      zip_code: "04920",
      state_id: "ME",
      state_name: "Maine",
    },
    {
      city_id: "1258",
      city: "Birch Harbor",
      zip_code: "04613",
      state_id: "ME",
      state_name: "Maine",
    },
    {
      city_id: "1314",
      city: "Blaine",
      zip_code: "04734",
      state_id: "ME",
      state_name: "Maine",
    },
    {
      city_id: "1259",
      city: "Blue Hill",
      zip_code: "04614",
      state_id: "ME",
      state_name: "Maine",
    },
    {
      city_id: "1225",
      city: "Boothbay",
      zip_code: "04537",
      state_id: "ME",
      state_name: "Maine",
    },
    {
      city_id: "1226",
      city: "Boothbay Harbor",
      zip_code: "04538",
      state_id: "ME",
      state_name: "Maine",
    },
    {
      city_id: "1131",
      city: "Bowdoin",
      zip_code: "04287",
      state_id: "ME",
      state_name: "Maine",
    },
    {
      city_id: "1022",
      city: "Bowdoinham",
      zip_code: "04008",
      state_id: "ME",
      state_name: "Maine",
    },
    {
      city_id: "1160",
      city: "Bradford",
      zip_code: "04410",
      state_id: "ME",
      state_name: "Maine",
    },
    {
      city_id: "1161",
      city: "Bradley",
      zip_code: "04411",
      state_id: "ME",
      state_name: "Maine",
    },
    {
      city_id: "1233",
      city: "Bremen",
      zip_code: "04551",
      state_id: "ME",
      state_name: "Maine",
    },
    {
      city_id: "1162",
      city: "Brewer",
      zip_code: "04412",
      state_id: "ME",
      state_name: "Maine",
    },
    {
      city_id: "1315",
      city: "Bridgewater",
      zip_code: "04735",
      state_id: "ME",
      state_name: "Maine",
    },
    {
      city_id: "1023",
      city: "Bridgton",
      zip_code: "04009",
      state_id: "ME",
      state_name: "Maine",
    },
    {
      city_id: "1227",
      city: "Bristol",
      zip_code: "04539",
      state_id: "ME",
      state_name: "Maine",
    },
    {
      city_id: "1260",
      city: "Brooklin",
      zip_code: "04616",
      state_id: "ME",
      state_name: "Maine",
    },
    {
      city_id: "1376",
      city: "Brooks",
      zip_code: "04921",
      state_id: "ME",
      state_name: "Maine",
    },
    {
      city_id: "1261",
      city: "Brooksville",
      zip_code: "04617",
      state_id: "ME",
      state_name: "Maine",
    },
    {
      city_id: "1024",
      city: "Brownfield",
      zip_code: "04010",
      state_id: "ME",
      state_name: "Maine",
    },
    {
      city_id: "1164",
      city: "Brownville",
      zip_code: "04414",
      state_id: "ME",
      state_name: "Maine",
    },
    {
      city_id: "1165",
      city: "Brownville Junction",
      zip_code: "04415",
      state_id: "ME",
      state_name: "Maine",
    },
    {
      city_id: "1025",
      city: "Brunswick",
      zip_code: "04011",
      state_id: "ME",
      state_name: "Maine",
    },
    {
      city_id: "1091",
      city: "Bryant Pond",
      zip_code: "04219",
      state_id: "ME",
      state_name: "Maine",
    },
    {
      city_id: "1092",
      city: "Buckfield",
      zip_code: "04220",
      state_id: "ME",
      state_name: "Maine",
    },
    {
      city_id: "1166",
      city: "Bucksport",
      zip_code: "04416",
      state_id: "ME",
      state_name: "Maine",
    },
    {
      city_id: "1167",
      city: "Burlington",
      zip_code: "04417",
      state_id: "ME",
      state_name: "Maine",
    },
    {
      city_id: "1377",
      city: "Burnham",
      zip_code: "04922",
      state_id: "ME",
      state_name: "Maine",
    },
    {
      city_id: "1075",
      city: "Buxton",
      zip_code: "04093",
      state_id: "ME",
      state_name: "Maine",
    },
    {
      city_id: "1378",
      city: "Cambridge",
      zip_code: "04923",
      state_id: "ME",
      state_name: "Maine",
    },
    {
      city_id: "1351",
      city: "Camden",
      zip_code: "04843",
      state_id: "ME",
      state_name: "Maine",
    },
    {
      city_id: "1379",
      city: "Canaan",
      zip_code: "04924",
      state_id: "ME",
      state_name: "Maine",
    },
    {
      city_id: "1093",
      city: "Canton",
      zip_code: "04221",
      state_id: "ME",
      state_name: "Maine",
    },
    {
      city_id: "1084",
      city: "Cape Elizabeth",
      zip_code: "04107",
      state_id: "ME",
      state_name: "Maine",
    },
    {
      city_id: "1007",
      city: "Cape Neddick",
      zip_code: "03902",
      state_id: "ME",
      state_name: "Maine",
    },
    {
      city_id: "1380",
      city: "Caratunk",
      zip_code: "04925",
      state_id: "ME",
      state_name: "Maine",
    },
    {
      city_id: "1316",
      city: "Caribou",
      zip_code: "04736",
      state_id: "ME",
      state_name: "Maine",
    },
    {
      city_id: "1169",
      city: "Carmel",
      zip_code: "04419",
      state_id: "ME",
      state_name: "Maine",
    },
    {
      city_id: "1026",
      city: "Casco",
      zip_code: "04015",
      state_id: "ME",
      state_name: "Maine",
    },
    {
      city_id: "1170",
      city: "Castine",
      zip_code: "04421",
      state_id: "ME",
      state_name: "Maine",
    },
    {
      city_id: "1228",
      city: "Chamberlain",
      zip_code: "04541",
      state_id: "ME",
      state_name: "Maine",
    },
    {
      city_id: "1171",
      city: "Charleston",
      zip_code: "04422",
      state_id: "ME",
      state_name: "Maine",
    },
    {
      city_id: "1027",
      city: "Chebeague Island",
      zip_code: "04017",
      state_id: "ME",
      state_name: "Maine",
    },
    {
      city_id: "1263",
      city: "Cherryfield",
      zip_code: "04622",
      state_id: "ME",
      state_name: "Maine",
    },
    {
      city_id: "1381",
      city: "China Village",
      zip_code: "04926",
      state_id: "ME",
      state_name: "Maine",
    },
    {
      city_id: "1028",
      city: "Cliff Island",
      zip_code: "04019",
      state_id: "ME",
      state_name: "Maine",
    },
    {
      city_id: "1382",
      city: "Clinton",
      zip_code: "04927",
      state_id: "ME",
      state_name: "Maine",
    },
    {
      city_id: "1264",
      city: "Columbia Falls",
      zip_code: "04623",
      state_id: "ME",
      state_name: "Maine",
    },
    {
      city_id: "1137",
      city: "Coopers Mills",
      zip_code: "04341",
      state_id: "ME",
      state_name: "Maine",
    },
    {
      city_id: "1265",
      city: "Corea",
      zip_code: "04624",
      state_id: "ME",
      state_name: "Maine",
    },
    {
      city_id: "1383",
      city: "Corinna",
      zip_code: "04928",
      state_id: "ME",
      state_name: "Maine",
    },
    {
      city_id: "1174",
      city: "Corinth",
      zip_code: "04427",
      state_id: "ME",
      state_name: "Maine",
    },
    {
      city_id: "1029",
      city: "Cornish",
      zip_code: "04020",
      state_id: "ME",
      state_name: "Maine",
    },
    {
      city_id: "1266",
      city: "Cranberry Isles",
      zip_code: "04625",
      state_id: "ME",
      state_name: "Maine",
    },
    {
      city_id: "1030",
      city: "Cumberland Center",
      zip_code: "04021",
      state_id: "ME",
      state_name: "Maine",
    },
    {
      city_id: "1087",
      city: "Cumberland Foreside",
      zip_code: "04110",
      state_id: "ME",
      state_name: "Maine",
    },
    {
      city_id: "1240",
      city: "Cushing",
      zip_code: "04563",
      state_id: "ME",
      state_name: "Maine",
    },
    {
      city_id: "1267",
      city: "Cutler",
      zip_code: "04626",
      state_id: "ME",
      state_name: "Maine",
    },
    {
      city_id: "1229",
      city: "Damariscotta",
      zip_code: "04543",
      state_id: "ME",
      state_name: "Maine",
    },
    {
      city_id: "1172",
      city: "Danforth",
      zip_code: "04424",
      state_id: "ME",
      state_name: "Maine",
    },
    {
      city_id: "1268",
      city: "Deer Isle",
      zip_code: "04627",
      state_id: "ME",
      state_name: "Maine",
    },
    {
      city_id: "1031",
      city: "Denmark",
      zip_code: "04022",
      state_id: "ME",
      state_name: "Maine",
    },
    {
      city_id: "1269",
      city: "Dennysville",
      zip_code: "04628",
      state_id: "ME",
      state_name: "Maine",
    },
    {
      city_id: "1384",
      city: "Detroit",
      zip_code: "04929",
      state_id: "ME",
      state_name: "Maine",
    },
    {
      city_id: "1385",
      city: "Dexter",
      zip_code: "04930",
      state_id: "ME",
      state_name: "Maine",
    },
    {
      city_id: "1095",
      city: "Dixfield",
      zip_code: "04224",
      state_id: "ME",
      state_name: "Maine",
    },
    {
      city_id: "1386",
      city: "Dixmont",
      zip_code: "04932",
      state_id: "ME",
      state_name: "Maine",
    },
    {
      city_id: "1173",
      city: "Dover Foxcroft",
      zip_code: "04426",
      state_id: "ME",
      state_name: "Maine",
    },
    {
      city_id: "1138",
      city: "Dresden",
      zip_code: "04342",
      state_id: "ME",
      state_name: "Maine",
    },
    {
      city_id: "1094",
      city: "Durham",
      zip_code: "04222",
      state_id: "ME",
      state_name: "Maine",
    },
    {
      city_id: "1318",
      city: "Eagle Lake",
      zip_code: "04739",
      state_id: "ME",
      state_name: "Maine",
    },
    {
      city_id: "1096",
      city: "East Andover",
      zip_code: "04226",
      state_id: "ME",
      state_name: "Maine",
    },
    {
      city_id: "1032",
      city: "East Baldwin",
      zip_code: "04024",
      state_id: "ME",
      state_name: "Maine",
    },
    {
      city_id: "1230",
      city: "East Boothbay",
      zip_code: "04544",
      state_id: "ME",
      state_name: "Maine",
    },
    {
      city_id: "1097",
      city: "East Dixfield",
      zip_code: "04227",
      state_id: "ME",
      state_name: "Maine",
    },
    {
      city_id: "1270",
      city: "East Machias",
      zip_code: "04630",
      state_id: "ME",
      state_name: "Maine",
    },
    {
      city_id: "1177",
      city: "East Millinocket",
      zip_code: "04430",
      state_id: "ME",
      state_name: "Maine",
    },
    {
      city_id: "1178",
      city: "East Orland",
      zip_code: "04431",
      state_id: "ME",
      state_name: "Maine",
    },
    {
      city_id: "1035",
      city: "East Waterboro",
      zip_code: "04030",
      state_id: "ME",
      state_name: "Maine",
    },
    {
      city_id: "1099",
      city: "East Wilton",
      zip_code: "04234",
      state_id: "ME",
      state_name: "Maine",
    },
    {
      city_id: "1319",
      city: "Easton",
      zip_code: "04740",
      state_id: "ME",
      state_name: "Maine",
    },
    {
      city_id: "1271",
      city: "Eastport",
      zip_code: "04631",
      state_id: "ME",
      state_name: "Maine",
    },
    {
      city_id: "1175",
      city: "Eddington",
      zip_code: "04428",
      state_id: "ME",
      state_name: "Maine",
    },
    {
      city_id: "1237",
      city: "Edgecomb",
      zip_code: "04556",
      state_id: "ME",
      state_name: "Maine",
    },
    {
      city_id: "1008",
      city: "Eliot",
      zip_code: "03903",
      state_id: "ME",
      state_name: "Maine",
    },
    {
      city_id: "1252",
      city: "Ellsworth",
      zip_code: "04605",
      state_id: "ME",
      state_name: "Maine",
    },
    {
      city_id: "1179",
      city: "Etna",
      zip_code: "04434",
      state_id: "ME",
      state_name: "Maine",
    },
    {
      city_id: "1387",
      city: "Eustis",
      zip_code: "04936",
      state_id: "ME",
      state_name: "Maine",
    },
    {
      city_id: "1180",
      city: "Exeter",
      zip_code: "04435",
      state_id: "ME",
      state_name: "Maine",
    },
    {
      city_id: "1388",
      city: "Fairfield",
      zip_code: "04937",
      state_id: "ME",
      state_name: "Maine",
    },
    {
      city_id: "1082",
      city: "Falmouth",
      zip_code: "04105",
      state_id: "ME",
      state_name: "Maine",
    },
    {
      city_id: "1139",
      city: "Farmingdale",
      zip_code: "04344",
      state_id: "ME",
      state_name: "Maine",
    },
    {
      city_id: "1389",
      city: "Farmington",
      zip_code: "04938",
      state_id: "ME",
      state_name: "Maine",
    },
    {
      city_id: "1391",
      city: "Farmington Falls",
      zip_code: "04940",
      state_id: "ME",
      state_name: "Maine",
    },
    {
      city_id: "1320",
      city: "Fort Fairfield",
      zip_code: "04742",
      state_id: "ME",
      state_name: "Maine",
    },
    {
      city_id: "1321",
      city: "Fort Kent",
      zip_code: "04743",
      state_id: "ME",
      state_name: "Maine",
    },
    {
      city_id: "1181",
      city: "Frankfort",
      zip_code: "04438",
      state_id: "ME",
      state_name: "Maine",
    },
    {
      city_id: "1272",
      city: "Franklin",
      zip_code: "04634",
      state_id: "ME",
      state_name: "Maine",
    },
    {
      city_id: "1392",
      city: "Freedom",
      zip_code: "04941",
      state_id: "ME",
      state_name: "Maine",
    },
    {
      city_id: "1036",
      city: "Freeport",
      zip_code: "04032",
      state_id: "ME",
      state_name: "Maine",
    },
    {
      city_id: "1273",
      city: "Frenchboro",
      zip_code: "04635",
      state_id: "ME",
      state_name: "Maine",
    },
    {
      city_id: "1322",
      city: "Frenchville",
      zip_code: "04745",
      state_id: "ME",
      state_name: "Maine",
    },
    {
      city_id: "1231",
      city: "Friendship",
      zip_code: "04547",
      state_id: "ME",
      state_name: "Maine",
    },
    {
      city_id: "1037",
      city: "Fryeburg",
      zip_code: "04037",
      state_id: "ME",
      state_name: "Maine",
    },
    {
      city_id: "1140",
      city: "Gardiner",
      zip_code: "04345",
      state_id: "ME",
      state_name: "Maine",
    },
    {
      city_id: "1390",
      city: "Garland",
      zip_code: "04939",
      state_id: "ME",
      state_name: "Maine",
    },
    {
      city_id: "1232",
      city: "Georgetown",
      zip_code: "04548",
      state_id: "ME",
      state_name: "Maine",
    },
    {
      city_id: "1038",
      city: "Gorham",
      zip_code: "04038",
      state_id: "ME",
      state_name: "Maine",
    },
    {
      city_id: "1254",
      city: "Gouldsboro",
      zip_code: "04607",
      state_id: "ME",
      state_name: "Maine",
    },
    {
      city_id: "1323",
      city: "Grand Isle",
      zip_code: "04746",
      state_id: "ME",
      state_name: "Maine",
    },
    {
      city_id: "1039",
      city: "Gray",
      zip_code: "04039",
      state_id: "ME",
      state_name: "Maine",
    },
    {
      city_id: "1168",
      city: "Greenbush",
      zip_code: "04418",
      state_id: "ME",
      state_name: "Maine",
    },
    {
      city_id: "1100",
      city: "Greene",
      zip_code: "04236",
      state_id: "ME",
      state_name: "Maine",
    },
    {
      city_id: "1182",
      city: "Greenville",
      zip_code: "04441",
      state_id: "ME",
      state_name: "Maine",
    },
    {
      city_id: "1183",
      city: "Greenville Junction",
      zip_code: "04442",
      state_id: "ME",
      state_name: "Maine",
    },
    {
      city_id: "1109",
      city: "Greenwood",
      zip_code: "04255",
      state_id: "ME",
      state_name: "Maine",
    },
    {
      city_id: "1184",
      city: "Guilford",
      zip_code: "04443",
      state_id: "ME",
      state_name: "Maine",
    },
    {
      city_id: "1142",
      city: "Hallowell",
      zip_code: "04347",
      state_id: "ME",
      state_name: "Maine",
    },
    {
      city_id: "1185",
      city: "Hampden",
      zip_code: "04444",
      state_id: "ME",
      state_name: "Maine",
    },
    {
      city_id: "1274",
      city: "Hancock",
      zip_code: "04640",
      state_id: "ME",
      state_name: "Maine",
    },
    {
      city_id: "1101",
      city: "Hanover",
      zip_code: "04237",
      state_id: "ME",
      state_name: "Maine",
    },
    {
      city_id: "1275",
      city: "Harborside",
      zip_code: "04642",
      state_id: "ME",
      state_name: "Maine",
    },
    {
      city_id: "1393",
      city: "Harmony",
      zip_code: "04942",
      state_id: "ME",
      state_name: "Maine",
    },
    {
      city_id: "1065",
      city: "Harpswell",
      zip_code: "04079",
      state_id: "ME",
      state_name: "Maine",
    },
    {
      city_id: "1276",
      city: "Harrington",
      zip_code: "04643",
      state_id: "ME",
      state_name: "Maine",
    },
    {
      city_id: "1040",
      city: "Harrison",
      zip_code: "04040",
      state_id: "ME",
      state_name: "Maine",
    },
    {
      city_id: "1394",
      city: "Hartland",
      zip_code: "04943",
      state_id: "ME",
      state_name: "Maine",
    },
    {
      city_id: "1102",
      city: "Hebron",
      zip_code: "04238",
      state_id: "ME",
      state_name: "Maine",
    },
    {
      city_id: "1395",
      city: "Hinckley",
      zip_code: "04944",
      state_id: "ME",
      state_name: "Maine",
    },
    {
      city_id: "1041",
      city: "Hiram",
      zip_code: "04041",
      state_id: "ME",
      state_name: "Maine",
    },
    {
      city_id: "1176",
      city: "Holden",
      zip_code: "04429",
      state_id: "ME",
      state_name: "Maine",
    },
    {
      city_id: "1042",
      city: "Hollis Center",
      zip_code: "04042",
      state_id: "ME",
      state_name: "Maine",
    },
    {
      city_id: "1352",
      city: "Hope",
      zip_code: "04847",
      state_id: "ME",
      state_name: "Maine",
    },
    {
      city_id: "1311",
      city: "Houlton",
      zip_code: "04730",
      state_id: "ME",
      state_name: "Maine",
    },
    {
      city_id: "1186",
      city: "Howland",
      zip_code: "04448",
      state_id: "ME",
      state_name: "Maine",
    },
    {
      city_id: "1187",
      city: "Hudson",
      zip_code: "04449",
      state_id: "ME",
      state_name: "Maine",
    },
    {
      city_id: "1324",
      city: "Island Falls",
      zip_code: "04747",
      state_id: "ME",
      state_name: "Maine",
    },
    {
      city_id: "1277",
      city: "Isle Au Haut",
      zip_code: "04645",
      state_id: "ME",
      state_name: "Maine",
    },
    {
      city_id: "1353",
      city: "Islesboro",
      zip_code: "04848",
      state_id: "ME",
      state_name: "Maine",
    },
    {
      city_id: "1278",
      city: "Islesford",
      zip_code: "04646",
      state_id: "ME",
      state_name: "Maine",
    },
    {
      city_id: "1396",
      city: "Jackman",
      zip_code: "04945",
      state_id: "ME",
      state_name: "Maine",
    },
    {
      city_id: "1103",
      city: "Jay",
      zip_code: "04239",
      state_id: "ME",
      state_name: "Maine",
    },
    {
      city_id: "1143",
      city: "Jefferson",
      zip_code: "04348",
      state_id: "ME",
      state_name: "Maine",
    },
    {
      city_id: "1279",
      city: "Jonesboro",
      zip_code: "04648",
      state_id: "ME",
      state_name: "Maine",
    },
    {
      city_id: "1280",
      city: "Jonesport",
      zip_code: "04649",
      state_id: "ME",
      state_name: "Maine",
    },
    {
      city_id: "1188",
      city: "Kenduskeag",
      zip_code: "04450",
      state_id: "ME",
      state_name: "Maine",
    },
    {
      city_id: "1043",
      city: "Kennebunk",
      zip_code: "04043",
      state_id: "ME",
      state_name: "Maine",
    },
    {
      city_id: "1044",
      city: "Kennebunkport",
      zip_code: "04046",
      state_id: "ME",
      state_name: "Maine",
    },
    {
      city_id: "1144",
      city: "Kents Hill",
      zip_code: "04349",
      state_id: "ME",
      state_name: "Maine",
    },
    {
      city_id: "1397",
      city: "Kingfield",
      zip_code: "04947",
      state_id: "ME",
      state_name: "Maine",
    },
    {
      city_id: "1009",
      city: "Kittery",
      zip_code: "03904",
      state_id: "ME",
      state_name: "Maine",
    },
    {
      city_id: "1010",
      city: "Kittery Point",
      zip_code: "03905",
      state_id: "ME",
      state_name: "Maine",
    },
    {
      city_id: "1190",
      city: "Lagrange",
      zip_code: "04453",
      state_id: "ME",
      state_name: "Maine",
    },
    {
      city_id: "1033",
      city: "Lebanon",
      zip_code: "04027",
      state_id: "ME",
      state_name: "Maine",
    },
    {
      city_id: "1192",
      city: "Lee",
      zip_code: "04455",
      state_id: "ME",
      state_name: "Maine",
    },
    {
      city_id: "1116",
      city: "Leeds",
      zip_code: "04263",
      state_id: "ME",
      state_name: "Maine",
    },
    {
      city_id: "1193",
      city: "Levant",
      zip_code: "04456",
      state_id: "ME",
      state_name: "Maine",
    },
    {
      city_id: "1104",
      city: "Lewiston",
      zip_code: "04240",
      state_id: "ME",
      state_name: "Maine",
    },
    {
      city_id: "1398",
      city: "Liberty",
      zip_code: "04949",
      state_id: "ME",
      state_name: "Maine",
    },
    {
      city_id: "1046",
      city: "Limerick",
      zip_code: "04048",
      state_id: "ME",
      state_name: "Maine",
    },
    {
      city_id: "1325",
      city: "Limestone",
      zip_code: "04750",
      state_id: "ME",
      state_name: "Maine",
    },
    {
      city_id: "1047",
      city: "Limington",
      zip_code: "04049",
      state_id: "ME",
      state_name: "Maine",
    },
    {
      city_id: "1194",
      city: "Lincoln",
      zip_code: "04457",
      state_id: "ME",
      state_name: "Maine",
    },
    {
      city_id: "1354",
      city: "Lincolnville",
      zip_code: "04849",
      state_id: "ME",
      state_name: "Maine",
    },
    {
      city_id: "1105",
      city: "Lisbon",
      zip_code: "04250",
      state_id: "ME",
      state_name: "Maine",
    },
    {
      city_id: "1106",
      city: "Lisbon Falls",
      zip_code: "04252",
      state_id: "ME",
      state_name: "Maine",
    },
    {
      city_id: "1145",
      city: "Litchfield",
      zip_code: "04350",
      state_id: "ME",
      state_name: "Maine",
    },
    {
      city_id: "1281",
      city: "Little Deer Isle",
      zip_code: "04650",
      state_id: "ME",
      state_name: "Maine",
    },
    {
      city_id: "1107",
      city: "Livermore",
      zip_code: "04253",
      state_id: "ME",
      state_name: "Maine",
    },
    {
      city_id: "1108",
      city: "Livermore Falls",
      zip_code: "04254",
      state_id: "ME",
      state_name: "Maine",
    },
    {
      city_id: "1048",
      city: "Long Island",
      zip_code: "04050",
      state_id: "ME",
      state_name: "Maine",
    },
    {
      city_id: "1049",
      city: "Lovell",
      zip_code: "04051",
      state_id: "ME",
      state_name: "Maine",
    },
    {
      city_id: "1282",
      city: "Lubec",
      zip_code: "04652",
      state_id: "ME",
      state_name: "Maine",
    },
    {
      city_id: "1284",
      city: "Machias",
      zip_code: "04654",
      state_id: "ME",
      state_name: "Maine",
    },
    {
      city_id: "1285",
      city: "Machiasport",
      zip_code: "04655",
      state_id: "ME",
      state_name: "Maine",
    },
    {
      city_id: "1326",
      city: "Madawaska",
      zip_code: "04756",
      state_id: "ME",
      state_name: "Maine",
    },
    {
      city_id: "1399",
      city: "Madison",
      zip_code: "04950",
      state_id: "ME",
      state_name: "Maine",
    },
    {
      city_id: "1146",
      city: "Manchester",
      zip_code: "04351",
      state_id: "ME",
      state_name: "Maine",
    },
    {
      city_id: "1327",
      city: "Mapleton",
      zip_code: "04757",
      state_id: "ME",
      state_name: "Maine",
    },
    {
      city_id: "1328",
      city: "Mars Hill",
      zip_code: "04758",
      state_id: "ME",
      state_name: "Maine",
    },
    {
      city_id: "1355",
      city: "Matinicus",
      zip_code: "04851",
      state_id: "ME",
      state_name: "Maine",
    },
    {
      city_id: "1195",
      city: "Mattawamkeag",
      zip_code: "04459",
      state_id: "ME",
      state_name: "Maine",
    },
    {
      city_id: "1110",
      city: "Mechanic Falls",
      zip_code: "04256",
      state_id: "ME",
      state_name: "Maine",
    },
    {
      city_id: "1196",
      city: "Medway",
      zip_code: "04460",
      state_id: "ME",
      state_name: "Maine",
    },
    {
      city_id: "1111",
      city: "Mexico",
      zip_code: "04257",
      state_id: "ME",
      state_name: "Maine",
    },
    {
      city_id: "1287",
      city: "Milbridge",
      zip_code: "04658",
      state_id: "ME",
      state_name: "Maine",
    },
    {
      city_id: "1197",
      city: "Milford",
      zip_code: "04461",
      state_id: "ME",
      state_name: "Maine",
    },
    {
      city_id: "1198",
      city: "Millinocket",
      zip_code: "04462",
      state_id: "ME",
      state_name: "Maine",
    },
    {
      city_id: "1199",
      city: "Milo",
      zip_code: "04463",
      state_id: "ME",
      state_name: "Maine",
    },
    {
      city_id: "1112",
      city: "Minot",
      zip_code: "04258",
      state_id: "ME",
      state_name: "Maine",
    },
    {
      city_id: "1356",
      city: "Monhegan",
      zip_code: "04852",
      state_id: "ME",
      state_name: "Maine",
    },
    {
      city_id: "1113",
      city: "Monmouth",
      zip_code: "04259",
      state_id: "ME",
      state_name: "Maine",
    },
    {
      city_id: "1400",
      city: "Monroe",
      zip_code: "04951",
      state_id: "ME",
      state_name: "Maine",
    },
    {
      city_id: "1200",
      city: "Monson",
      zip_code: "04464",
      state_id: "ME",
      state_name: "Maine",
    },
    {
      city_id: "1329",
      city: "Monticello",
      zip_code: "04760",
      state_id: "ME",
      state_name: "Maine",
    },
    {
      city_id: "1401",
      city: "Morrill",
      zip_code: "04952",
      state_id: "ME",
      state_name: "Maine",
    },
    {
      city_id: "1288",
      city: "Mount Desert",
      zip_code: "04660",
      state_id: "ME",
      state_name: "Maine",
    },
    {
      city_id: "1147",
      city: "Mount Vernon",
      zip_code: "04352",
      state_id: "ME",
      state_name: "Maine",
    },
    {
      city_id: "1050",
      city: "Naples",
      zip_code: "04055",
      state_id: "ME",
      state_name: "Maine",
    },
    {
      city_id: "1114",
      city: "New Gloucester",
      zip_code: "04260",
      state_id: "ME",
      state_name: "Maine",
    },
    {
      city_id: "1235",
      city: "New Harbor",
      zip_code: "04554",
      state_id: "ME",
      state_name: "Maine",
    },
    {
      city_id: "1330",
      city: "New Limerick",
      zip_code: "04761",
      state_id: "ME",
      state_name: "Maine",
    },
    {
      city_id: "1407",
      city: "New Portland",
      zip_code: "04961",
      state_id: "ME",
      state_name: "Maine",
    },
    {
      city_id: "1403",
      city: "New Sharon",
      zip_code: "04955",
      state_id: "ME",
      state_name: "Maine",
    },
    {
      city_id: "1331",
      city: "New Sweden",
      zip_code: "04762",
      state_id: "ME",
      state_name: "Maine",
    },
    {
      city_id: "1404",
      city: "New Vineyard",
      zip_code: "04956",
      state_id: "ME",
      state_name: "Maine",
    },
    {
      city_id: "1234",
      city: "Newcastle",
      zip_code: "04553",
      state_id: "ME",
      state_name: "Maine",
    },
    {
      city_id: "1051",
      city: "Newfield",
      zip_code: "04056",
      state_id: "ME",
      state_name: "Maine",
    },
    {
      city_id: "1402",
      city: "Newport",
      zip_code: "04953",
      state_id: "ME",
      state_name: "Maine",
    },
    {
      city_id: "1115",
      city: "Newry",
      zip_code: "04261",
      state_id: "ME",
      state_name: "Maine",
    },
    {
      city_id: "1236",
      city: "Nobleboro",
      zip_code: "04555",
      state_id: "ME",
      state_name: "Maine",
    },
    {
      city_id: "1405",
      city: "Norridgewock",
      zip_code: "04957",
      state_id: "ME",
      state_name: "Maine",
    },
    {
      city_id: "1406",
      city: "North Anson",
      zip_code: "04958",
      state_id: "ME",
      state_name: "Maine",
    },
    {
      city_id: "1011",
      city: "North Berwick",
      zip_code: "03906",
      state_id: "ME",
      state_name: "Maine",
    },
    {
      city_id: "1052",
      city: "North Bridgton",
      zip_code: "04057",
      state_id: "ME",
      state_name: "Maine",
    },
    {
      city_id: "1117",
      city: "North Monmouth",
      zip_code: "04265",
      state_id: "ME",
      state_name: "Maine",
    },
    {
      city_id: "1053",
      city: "North Waterboro",
      zip_code: "04061",
      state_id: "ME",
      state_name: "Maine",
    },
    {
      city_id: "1118",
      city: "North Waterford",
      zip_code: "04267",
      state_id: "ME",
      state_name: "Maine",
    },
    {
      city_id: "1078",
      city: "North Yarmouth",
      zip_code: "04097",
      state_id: "ME",
      state_name: "Maine",
    },
    {
      city_id: "1289",
      city: "Northeast Harbor",
      zip_code: "04662",
      state_id: "ME",
      state_name: "Maine",
    },
    {
      city_id: "1119",
      city: "Norway",
      zip_code: "04268",
      state_id: "ME",
      state_name: "Maine",
    },
    {
      city_id: "1332",
      city: "Oakfield",
      zip_code: "04763",
      state_id: "ME",
      state_name: "Maine",
    },
    {
      city_id: "1408",
      city: "Oakland",
      zip_code: "04963",
      state_id: "ME",
      state_name: "Maine",
    },
    {
      city_id: "1055",
      city: "Ocean Park",
      zip_code: "04063",
      state_id: "ME",
      state_name: "Maine",
    },
    {
      city_id: "1012",
      city: "Ogunquit",
      zip_code: "03907",
      state_id: "ME",
      state_name: "Maine",
    },
    {
      city_id: "1056",
      city: "Old Orchard Beach",
      zip_code: "04064",
      state_id: "ME",
      state_name: "Maine",
    },
    {
      city_id: "1201",
      city: "Old Town",
      zip_code: "04468",
      state_id: "ME",
      state_name: "Maine",
    },
    {
      city_id: "1409",
      city: "Oquossoc",
      zip_code: "04964",
      state_id: "ME",
      state_name: "Maine",
    },
    {
      city_id: "1204",
      city: "Orland",
      zip_code: "04472",
      state_id: "ME",
      state_name: "Maine",
    },
    {
      city_id: "1202",
      city: "Orono",
      zip_code: "04469",
      state_id: "ME",
      state_name: "Maine",
    },
    {
      city_id: "1206",
      city: "Orrington",
      zip_code: "04474",
      state_id: "ME",
      state_name: "Maine",
    },
    {
      city_id: "1057",
      city: "Orrs Island",
      zip_code: "04066",
      state_id: "ME",
      state_name: "Maine",
    },
    {
      city_id: "1358",
      city: "Owls Head",
      zip_code: "04854",
      state_id: "ME",
      state_name: "Maine",
    },
    {
      city_id: "1333",
      city: "Oxbow",
      zip_code: "04764",
      state_id: "ME",
      state_name: "Maine",
    },
    {
      city_id: "1120",
      city: "Oxford",
      zip_code: "04270",
      state_id: "ME",
      state_name: "Maine",
    },
    {
      city_id: "1149",
      city: "Palermo",
      zip_code: "04354",
      state_id: "ME",
      state_name: "Maine",
    },
    {
      city_id: "1410",
      city: "Palmyra",
      zip_code: "04965",
      state_id: "ME",
      state_name: "Maine",
    },
    {
      city_id: "1121",
      city: "Paris",
      zip_code: "04271",
      state_id: "ME",
      state_name: "Maine",
    },
    {
      city_id: "1045",
      city: "Parsonsfield",
      zip_code: "04047",
      state_id: "ME",
      state_name: "Maine",
    },
    {
      city_id: "1207",
      city: "Passadumkeag",
      zip_code: "04475",
      state_id: "ME",
      state_name: "Maine",
    },
    {
      city_id: "1334",
      city: "Patten",
      zip_code: "04765",
      state_id: "ME",
      state_name: "Maine",
    },
    {
      city_id: "1085",
      city: "Peaks Island",
      zip_code: "04108",
      state_id: "ME",
      state_name: "Maine",
    },
    {
      city_id: "1238",
      city: "Pemaquid",
      zip_code: "04558",
      state_id: "ME",
      state_name: "Maine",
    },
    {
      city_id: "1291",
      city: "Pembroke",
      zip_code: "04666",
      state_id: "ME",
      state_name: "Maine",
    },
    {
      city_id: "1208",
      city: "Penobscot",
      zip_code: "04476",
      state_id: "ME",
      state_name: "Maine",
    },
    {
      city_id: "1335",
      city: "Perham",
      zip_code: "04766",
      state_id: "ME",
      state_name: "Maine",
    },
    {
      city_id: "1292",
      city: "Perry",
      zip_code: "04667",
      state_id: "ME",
      state_name: "Maine",
    },
    {
      city_id: "1133",
      city: "Peru",
      zip_code: "04290",
      state_id: "ME",
      state_name: "Maine",
    },
    {
      city_id: "1411",
      city: "Phillips",
      zip_code: "04966",
      state_id: "ME",
      state_name: "Maine",
    },
    {
      city_id: "1239",
      city: "Phippsburg",
      zip_code: "04562",
      state_id: "ME",
      state_name: "Maine",
    },
    {
      city_id: "1412",
      city: "Pittsfield",
      zip_code: "04967",
      state_id: "ME",
      state_name: "Maine",
    },
    {
      city_id: "1413",
      city: "Plymouth",
      zip_code: "04969",
      state_id: "ME",
      state_name: "Maine",
    },
    {
      city_id: "1122",
      city: "Poland",
      zip_code: "04274",
      state_id: "ME",
      state_name: "Maine",
    },
    {
      city_id: "1359",
      city: "Port Clyde",
      zip_code: "04855",
      state_id: "ME",
      state_name: "Maine",
    },
    {
      city_id: "1336",
      city: "Portage",
      zip_code: "04768",
      state_id: "ME",
      state_name: "Maine",
    },
    {
      city_id: "1058",
      city: "Porter",
      zip_code: "04068",
      state_id: "ME",
      state_name: "Maine",
    },
    {
      city_id: "1079",
      city: "Portland",
      zip_code: "04101",
      state_id: "ME",
      state_name: "Maine",
    },
    {
      city_id: "1059",
      city: "Pownal",
      zip_code: "04069",
      state_id: "ME",
      state_name: "Maine",
    },
    {
      city_id: "1337",
      city: "Presque Isle",
      zip_code: "04769",
      state_id: "ME",
      state_name: "Maine",
    },
    {
      city_id: "1294",
      city: "Prospect Harbor",
      zip_code: "04669",
      state_id: "ME",
      state_name: "Maine",
    },
    {
      city_id: "1141",
      city: "Randolph",
      zip_code: "04346",
      state_id: "ME",
      state_name: "Maine",
    },
    {
      city_id: "1414",
      city: "Rangeley",
      zip_code: "04970",
      state_id: "ME",
      state_name: "Maine",
    },
    {
      city_id: "1060",
      city: "Raymond",
      zip_code: "04071",
      state_id: "ME",
      state_name: "Maine",
    },
    {
      city_id: "1150",
      city: "Readfield",
      zip_code: "04355",
      state_id: "ME",
      state_name: "Maine",
    },
    {
      city_id: "1151",
      city: "Richmond",
      zip_code: "04357",
      state_id: "ME",
      state_name: "Maine",
    },
    {
      city_id: "1350",
      city: "Rockland",
      zip_code: "04841",
      state_id: "ME",
      state_name: "Maine",
    },
    {
      city_id: "1360",
      city: "Rockport",
      zip_code: "04856",
      state_id: "ME",
      state_name: "Maine",
    },
    {
      city_id: "1209",
      city: "Rockwood",
      zip_code: "04478",
      state_id: "ME",
      state_name: "Maine",
    },
    {
      city_id: "1241",
      city: "Round Pond",
      zip_code: "04564",
      state_id: "ME",
      state_name: "Maine",
    },
    {
      city_id: "1123",
      city: "Roxbury",
      zip_code: "04275",
      state_id: "ME",
      state_name: "Maine",
    },
    {
      city_id: "1124",
      city: "Rumford",
      zip_code: "04276",
      state_id: "ME",
      state_name: "Maine",
    },
    {
      city_id: "1125",
      city: "Sabattus",
      zip_code: "04280",
      state_id: "ME",
      state_name: "Maine",
    },
    {
      city_id: "1061",
      city: "Saco",
      zip_code: "04072",
      state_id: "ME",
      state_name: "Maine",
    },
    {
      city_id: "1338",
      city: "Saint Agatha",
      zip_code: "04772",
      state_id: "ME",
      state_name: "Maine",
    },
    {
      city_id: "1415",
      city: "Saint Albans",
      zip_code: "04971",
      state_id: "ME",
      state_name: "Maine",
    },
    {
      city_id: "1339",
      city: "Saint David",
      zip_code: "04773",
      state_id: "ME",
      state_name: "Maine",
    },
    {
      city_id: "1340",
      city: "Saint Francis",
      zip_code: "04774",
      state_id: "ME",
      state_name: "Maine",
    },
    {
      city_id: "1062",
      city: "Sanford",
      zip_code: "04073",
      state_id: "ME",
      state_name: "Maine",
    },
    {
      city_id: "1210",
      city: "Sangerville",
      zip_code: "04479",
      state_id: "ME",
      state_name: "Maine",
    },
    {
      city_id: "1296",
      city: "Sargentville",
      zip_code: "04673",
      state_id: "ME",
      state_name: "Maine",
    },
    {
      city_id: "1063",
      city: "Scarborough",
      zip_code: "04074",
      state_id: "ME",
      state_name: "Maine",
    },
    {
      city_id: "1297",
      city: "Seal Cove",
      zip_code: "04674",
      state_id: "ME",
      state_name: "Maine",
    },
    {
      city_id: "1298",
      city: "Seal Harbor",
      zip_code: "04675",
      state_id: "ME",
      state_name: "Maine",
    },
    {
      city_id: "1416",
      city: "Searsmont",
      zip_code: "04973",
      state_id: "ME",
      state_name: "Maine",
    },
    {
      city_id: "1417",
      city: "Searsport",
      zip_code: "04974",
      state_id: "ME",
      state_name: "Maine",
    },
    {
      city_id: "1034",
      city: "Sebago",
      zip_code: "04029",
      state_id: "ME",
      state_name: "Maine",
    },
    {
      city_id: "1211",
      city: "Sebec",
      zip_code: "04481",
      state_id: "ME",
      state_name: "Maine",
    },
    {
      city_id: "1299",
      city: "Sedgwick",
      zip_code: "04676",
      state_id: "ME",
      state_name: "Maine",
    },
    {
      city_id: "1064",
      city: "Shapleigh",
      zip_code: "04076",
      state_id: "ME",
      state_name: "Maine",
    },
    {
      city_id: "1418",
      city: "Shawmut",
      zip_code: "04975",
      state_id: "ME",
      state_name: "Maine",
    },
    {
      city_id: "1341",
      city: "Sherman",
      zip_code: "04776",
      state_id: "ME",
      state_name: "Maine",
    },
    {
      city_id: "1212",
      city: "Shirley Mills",
      zip_code: "04485",
      state_id: "ME",
      state_name: "Maine",
    },
    {
      city_id: "1419",
      city: "Skowhegan",
      zip_code: "04976",
      state_id: "ME",
      state_name: "Maine",
    },
    {
      city_id: "1420",
      city: "Smithfield",
      zip_code: "04978",
      state_id: "ME",
      state_name: "Maine",
    },
    {
      city_id: "1344",
      city: "Smyrna Mills",
      zip_code: "04780",
      state_id: "ME",
      state_name: "Maine",
    },
    {
      city_id: "1421",
      city: "Solon",
      zip_code: "04979",
      state_id: "ME",
      state_name: "Maine",
    },
    {
      city_id: "1300",
      city: "Sorrento",
      zip_code: "04677",
      state_id: "ME",
      state_name: "Maine",
    },
    {
      city_id: "1013",
      city: "South Berwick",
      zip_code: "03908",
      state_id: "ME",
      state_name: "Maine",
    },
    {
      city_id: "1242",
      city: "South Bristol",
      zip_code: "04568",
      state_id: "ME",
      state_name: "Maine",
    },
    {
      city_id: "1152",
      city: "South China",
      zip_code: "04358",
      state_id: "ME",
      state_name: "Maine",
    },
    {
      city_id: "1153",
      city: "South Gardiner",
      zip_code: "04359",
      state_id: "ME",
      state_name: "Maine",
    },
    {
      city_id: "1126",
      city: "South Paris",
      zip_code: "04281",
      state_id: "ME",
      state_name: "Maine",
    },
    {
      city_id: "1083",
      city: "South Portland",
      zip_code: "04106",
      state_id: "ME",
      state_name: "Maine",
    },
    {
      city_id: "1361",
      city: "South Thomaston",
      zip_code: "04858",
      state_id: "ME",
      state_name: "Maine",
    },
    {
      city_id: "1249",
      city: "Southport",
      zip_code: "04576",
      state_id: "ME",
      state_name: "Maine",
    },
    {
      city_id: "1301",
      city: "Southwest Harbor",
      zip_code: "04679",
      state_id: "ME",
      state_name: "Maine",
    },
    {
      city_id: "1066",
      city: "Springvale",
      zip_code: "04083",
      state_id: "ME",
      state_name: "Maine",
    },
    {
      city_id: "1362",
      city: "Spruce Head",
      zip_code: "04859",
      state_id: "ME",
      state_name: "Maine",
    },
    {
      city_id: "1243",
      city: "Squirrel Island",
      zip_code: "04570",
      state_id: "ME",
      state_name: "Maine",
    },
    {
      city_id: "1342",
      city: "Stacyville",
      zip_code: "04777",
      state_id: "ME",
      state_name: "Maine",
    },
    {
      city_id: "1067",
      city: "Standish",
      zip_code: "04084",
      state_id: "ME",
      state_name: "Maine",
    },
    {
      city_id: "1068",
      city: "Steep Falls",
      zip_code: "04085",
      state_id: "ME",
      state_name: "Maine",
    },
    {
      city_id: "1214",
      city: "Stetson",
      zip_code: "04488",
      state_id: "ME",
      state_name: "Maine",
    },
    {
      city_id: "1302",
      city: "Steuben",
      zip_code: "04680",
      state_id: "ME",
      state_name: "Maine",
    },
    {
      city_id: "1215",
      city: "Stillwater",
      zip_code: "04489",
      state_id: "ME",
      state_name: "Maine",
    },
    {
      city_id: "1346",
      city: "Stockholm",
      zip_code: "04783",
      state_id: "ME",
      state_name: "Maine",
    },
    {
      city_id: "1422",
      city: "Stockton Springs",
      zip_code: "04981",
      state_id: "ME",
      state_name: "Maine",
    },
    {
      city_id: "1098",
      city: "Stoneham",
      zip_code: "04231",
      state_id: "ME",
      state_name: "Maine",
    },
    {
      city_id: "1303",
      city: "Stonington",
      zip_code: "04681",
      state_id: "ME",
      state_name: "Maine",
    },
    {
      city_id: "1423",
      city: "Stratton",
      zip_code: "04982",
      state_id: "ME",
      state_name: "Maine",
    },
    {
      city_id: "1424",
      city: "Strong",
      zip_code: "04983",
      state_id: "ME",
      state_name: "Maine",
    },
    {
      city_id: "1290",
      city: "Sullivan",
      zip_code: "04664",
      state_id: "ME",
      state_name: "Maine",
    },
    {
      city_id: "1134",
      city: "Sumner",
      zip_code: "04292",
      state_id: "ME",
      state_name: "Maine",
    },
    {
      city_id: "1304",
      city: "Sunset",
      zip_code: "04683",
      state_id: "ME",
      state_name: "Maine",
    },
    {
      city_id: "1305",
      city: "Surry",
      zip_code: "04684",
      state_id: "ME",
      state_name: "Maine",
    },
    {
      city_id: "1306",
      city: "Swans Island",
      zip_code: "04685",
      state_id: "ME",
      state_name: "Maine",
    },
    {
      city_id: "1425",
      city: "Temple",
      zip_code: "04984",
      state_id: "ME",
      state_name: "Maine",
    },
    {
      city_id: "1363",
      city: "Tenants Harbor",
      zip_code: "04860",
      state_id: "ME",
      state_name: "Maine",
    },
    {
      city_id: "1364",
      city: "Thomaston",
      zip_code: "04861",
      state_id: "ME",
      state_name: "Maine",
    },
    {
      city_id: "1427",
      city: "Thorndike",
      zip_code: "04986",
      state_id: "ME",
      state_name: "Maine",
    },
    {
      city_id: "1069",
      city: "Topsham",
      zip_code: "04086",
      state_id: "ME",
      state_name: "Maine",
    },
    {
      city_id: "1244",
      city: "Trevett",
      zip_code: "04571",
      state_id: "ME",
      state_name: "Maine",
    },
    {
      city_id: "1428",
      city: "Troy",
      zip_code: "04987",
      state_id: "ME",
      state_name: "Maine",
    },
    {
      city_id: "1127",
      city: "Turner",
      zip_code: "04282",
      state_id: "ME",
      state_name: "Maine",
    },
    {
      city_id: "1365",
      city: "Union",
      zip_code: "04862",
      state_id: "ME",
      state_name: "Maine",
    },
    {
      city_id: "1429",
      city: "Unity",
      zip_code: "04988",
      state_id: "ME",
      state_name: "Maine",
    },
    {
      city_id: "1347",
      city: "Van Buren",
      zip_code: "04785",
      state_id: "ME",
      state_name: "Maine",
    },
    {
      city_id: "1217",
      city: "Vanceboro",
      zip_code: "04491",
      state_id: "ME",
      state_name: "Maine",
    },
    {
      city_id: "1430",
      city: "Vassalboro",
      zip_code: "04989",
      state_id: "ME",
      state_name: "Maine",
    },
    {
      city_id: "1154",
      city: "Vienna",
      zip_code: "04360",
      state_id: "ME",
      state_name: "Maine",
    },
    {
      city_id: "1366",
      city: "Vinalhaven",
      zip_code: "04863",
      state_id: "ME",
      state_name: "Maine",
    },
    {
      city_id: "1245",
      city: "Waldoboro",
      zip_code: "04572",
      state_id: "ME",
      state_name: "Maine",
    },
    {
      city_id: "1345",
      city: "Wallagrass",
      zip_code: "04781",
      state_id: "ME",
      state_name: "Maine",
    },
    {
      city_id: "1246",
      city: "Walpole",
      zip_code: "04573",
      state_id: "ME",
      state_name: "Maine",
    },
    {
      city_id: "1367",
      city: "Warren",
      zip_code: "04864",
      state_id: "ME",
      state_name: "Maine",
    },
    {
      city_id: "1348",
      city: "Washburn",
      zip_code: "04786",
      state_id: "ME",
      state_name: "Maine",
    },
    {
      city_id: "1247",
      city: "Washington",
      zip_code: "04574",
      state_id: "ME",
      state_name: "Maine",
    },
    {
      city_id: "1070",
      city: "Waterboro",
      zip_code: "04087",
      state_id: "ME",
      state_name: "Maine",
    },
    {
      city_id: "1071",
      city: "Waterford",
      zip_code: "04088",
      state_id: "ME",
      state_name: "Maine",
    },
    {
      city_id: "1368",
      city: "Waterville",
      zip_code: "04901",
      state_id: "ME",
      state_name: "Maine",
    },
    {
      city_id: "1128",
      city: "Wayne",
      zip_code: "04284",
      state_id: "ME",
      state_name: "Maine",
    },
    {
      city_id: "1129",
      city: "Weld",
      zip_code: "04285",
      state_id: "ME",
      state_name: "Maine",
    },
    {
      city_id: "1072",
      city: "Wells",
      zip_code: "04090",
      state_id: "ME",
      state_name: "Maine",
    },
    {
      city_id: "1307",
      city: "Wesley",
      zip_code: "04686",
      state_id: "ME",
      state_name: "Maine",
    },
    {
      city_id: "1073",
      city: "West Baldwin",
      zip_code: "04091",
      state_id: "ME",
      state_name: "Maine",
    },
    {
      city_id: "1130",
      city: "West Bethel",
      zip_code: "04286",
      state_id: "ME",
      state_name: "Maine",
    },
    {
      city_id: "1248",
      city: "West Boothbay Harbor",
      zip_code: "04575",
      state_id: "ME",
      state_name: "Maine",
    },
    {
      city_id: "1219",
      city: "West Enfield",
      zip_code: "04493",
      state_id: "ME",
      state_name: "Maine",
    },
    {
      city_id: "1431",
      city: "West Farmington",
      zip_code: "04992",
      state_id: "ME",
      state_name: "Maine",
    },
    {
      city_id: "1426",
      city: "West Forks",
      zip_code: "04985",
      state_id: "ME",
      state_name: "Maine",
    },
    {
      city_id: "1076",
      city: "West Newfield",
      zip_code: "04095",
      state_id: "ME",
      state_name: "Maine",
    },
    {
      city_id: "1132",
      city: "West Paris",
      zip_code: "04289",
      state_id: "ME",
      state_name: "Maine",
    },
    {
      city_id: "1074",
      city: "Westbrook",
      zip_code: "04092",
      state_id: "ME",
      state_name: "Maine",
    },
    {
      city_id: "1349",
      city: "Westfield",
      zip_code: "04787",
      state_id: "ME",
      state_name: "Maine",
    },
    {
      city_id: "1148",
      city: "Whitefield",
      zip_code: "04353",
      state_id: "ME",
      state_name: "Maine",
    },
    {
      city_id: "1308",
      city: "Whiting",
      zip_code: "04691",
      state_id: "ME",
      state_name: "Maine",
    },
    {
      city_id: "1135",
      city: "Wilton",
      zip_code: "04294",
      state_id: "ME",
      state_name: "Maine",
    },
    {
      city_id: "1054",
      city: "Windham",
      zip_code: "04062",
      state_id: "ME",
      state_name: "Maine",
    },
    {
      city_id: "1155",
      city: "Windsor",
      zip_code: "04363",
      state_id: "ME",
      state_name: "Maine",
    },
    {
      city_id: "1220",
      city: "Winn",
      zip_code: "04495",
      state_id: "ME",
      state_name: "Maine",
    },
    {
      city_id: "1309",
      city: "Winter Harbor",
      zip_code: "04693",
      state_id: "ME",
      state_name: "Maine",
    },
    {
      city_id: "1221",
      city: "Winterport",
      zip_code: "04496",
      state_id: "ME",
      state_name: "Maine",
    },
    {
      city_id: "1156",
      city: "Winthrop",
      zip_code: "04364",
      state_id: "ME",
      state_name: "Maine",
    },
    {
      city_id: "1250",
      city: "Wiscasset",
      zip_code: "04578",
      state_id: "ME",
      state_name: "Maine",
    },
    {
      city_id: "1251",
      city: "Woolwich",
      zip_code: "04579",
      state_id: "ME",
      state_name: "Maine",
    },
    {
      city_id: "1077",
      city: "Yarmouth",
      zip_code: "04096",
      state_id: "ME",
      state_name: "Maine",
    },
    {
      city_id: "1014",
      city: "York",
      zip_code: "03909",
      state_id: "ME",
      state_name: "Maine",
    },
    {
      city_id: "1015",
      city: "York Beach",
      zip_code: "03910",
      state_id: "ME",
      state_name: "Maine",
    },
    {
      city_id: "1016",
      city: "York Harbor",
      zip_code: "03911",
      state_id: "ME",
      state_name: "Maine",
    },
    {
      city_id: "16703",
      city: "Ada",
      zip_code: "49301",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16648",
      city: "Addison",
      zip_code: "49220",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16649",
      city: "Adrian",
      zip_code: "49221",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16877",
      city: "Afton",
      zip_code: "49705",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "17007",
      city: "Ahmeek",
      zip_code: "49901",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16410",
      city: "Akron",
      zip_code: "48701",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16878",
      city: "Alanson",
      zip_code: "49706",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16810",
      city: "Alba",
      zip_code: "49611",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16650",
      city: "Albion",
      zip_code: "49224",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16811",
      city: "Alden",
      zip_code: "49612",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16365",
      city: "Alger",
      zip_code: "48610",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16061",
      city: "Algonac",
      zip_code: "48001",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16553",
      city: "Allegan",
      zip_code: "49010",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16651",
      city: "Allen",
      zip_code: "49227",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16130",
      city: "Allen Park",
      zip_code: "48101",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16745",
      city: "Allendale",
      zip_code: "49401",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16062",
      city: "Allenton",
      zip_code: "48002",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16944",
      city: "Allouez",
      zip_code: "49805",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16460",
      city: "Alma",
      zip_code: "48801",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16063",
      city: "Almont",
      zip_code: "48003",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16879",
      city: "Alpena",
      zip_code: "49707",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "17008",
      city: "Alpha",
      zip_code: "49902",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16704",
      city: "Alto",
      zip_code: "49302",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16131",
      city: "Ann Arbor",
      zip_code: "48103",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16290",
      city: "Applegate",
      zip_code: "48401",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16812",
      city: "Arcadia",
      zip_code: "49613",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16291",
      city: "Argyle",
      zip_code: "48410",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16064",
      city: "Armada",
      zip_code: "48005",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16461",
      city: "Ashley",
      zip_code: "48806",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16554",
      city: "Athens",
      zip_code: "49011",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16880",
      city: "Atlanta",
      zip_code: "49709",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "17010",
      city: "Atlantic Mine",
      zip_code: "49905",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16292",
      city: "Atlas",
      zip_code: "48411",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16293",
      city: "Attica",
      zip_code: "48412",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16411",
      city: "Au Gres",
      zip_code: "48703",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16945",
      city: "Au Train",
      zip_code: "49806",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16366",
      city: "Auburn",
      zip_code: "48611",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16256",
      city: "Auburn Hills",
      zip_code: "48326",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16555",
      city: "Augusta",
      zip_code: "49012",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16065",
      city: "Avoca",
      zip_code: "48006",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16294",
      city: "Bad Axe",
      zip_code: "48413",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16705",
      city: "Bailey",
      zip_code: "49303",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16706",
      city: "Baldwin",
      zip_code: "49304",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16295",
      city: "Bancroft",
      zip_code: "48414",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16556",
      city: "Bangor",
      zip_code: "49013",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16462",
      city: "Bannister",
      zip_code: "48807",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "17011",
      city: "Baraga",
      zip_code: "49908",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16881",
      city: "Barbeau",
      zip_code: "49710",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16946",
      city: "Bark River",
      zip_code: "49807",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16625",
      city: "Baroda",
      zip_code: "49101",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16707",
      city: "Barryton",
      zip_code: "49305",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16412",
      city: "Barton City",
      zip_code: "48705",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16463",
      city: "Bath",
      zip_code: "48808",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16557",
      city: "Battle Creek",
      zip_code: "49014",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16413",
      city: "Bay City",
      zip_code: "48706",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16416",
      city: "Bay Port",
      zip_code: "48720",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16813",
      city: "Bear Lake",
      zip_code: "49614",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16933",
      city: "Beaver Island",
      zip_code: "49782",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16367",
      city: "Beaverton",
      zip_code: "48612",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16464",
      city: "Belding",
      zip_code: "48809",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16814",
      city: "Bellaire",
      zip_code: "49615",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16136",
      city: "Belleville",
      zip_code: "48111",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16560",
      city: "Bellevue",
      zip_code: "49021",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16708",
      city: "Belmont",
      zip_code: "49306",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16368",
      city: "Bentley",
      zip_code: "48613",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16561",
      city: "Benton Harbor",
      zip_code: "49022",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16815",
      city: "Benzonia",
      zip_code: "49616",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "17012",
      city: "Bergland",
      zip_code: "49910",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16108",
      city: "Berkley",
      zip_code: "48072",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16626",
      city: "Berrien Center",
      zip_code: "49102",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16627",
      city: "Berrien Springs",
      zip_code: "49103",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "17013",
      city: "Bessemer",
      zip_code: "49911",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16816",
      city: "Beulah",
      zip_code: "49617",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16947",
      city: "Big Bay",
      zip_code: "49808",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16709",
      city: "Big Rapids",
      zip_code: "49307",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16296",
      city: "Birch Run",
      zip_code: "48415",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16066",
      city: "Birmingham",
      zip_code: "48009",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16710",
      city: "Bitely",
      zip_code: "49309",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16417",
      city: "Black River",
      zip_code: "48721",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16711",
      city: "Blanchard",
      zip_code: "49310",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16652",
      city: "Blissfield",
      zip_code: "49228",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16239",
      city: "Bloomfield Hills",
      zip_code: "48301",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16563",
      city: "Bloomingdale",
      zip_code: "49026",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16817",
      city: "Boon",
      zip_code: "49618",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16882",
      city: "Boyne City",
      zip_code: "49712",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16883",
      city: "Boyne Falls",
      zip_code: "49713",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16746",
      city: "Branch",
      zip_code: "49402",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16369",
      city: "Brant",
      zip_code: "48614",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16370",
      city: "Breckenridge",
      zip_code: "48615",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16564",
      city: "Breedsville",
      zip_code: "49027",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16818",
      city: "Brethren",
      zip_code: "49619",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16418",
      city: "Bridgeport",
      zip_code: "48722",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16629",
      city: "Bridgman",
      zip_code: "49106",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16137",
      city: "Brighton",
      zip_code: "48114",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16884",
      city: "Brimley",
      zip_code: "49715",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16653",
      city: "Britton",
      zip_code: "49229",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16712",
      city: "Brohman",
      zip_code: "49312",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16565",
      city: "Bronson",
      zip_code: "49028",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16654",
      city: "Brooklyn",
      zip_code: "49230",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16297",
      city: "Brown City",
      zip_code: "48416",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "17014",
      city: "Bruce Crossing",
      zip_code: "49912",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16885",
      city: "Brutus",
      zip_code: "49716",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16630",
      city: "Buchanan",
      zip_code: "49107",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16819",
      city: "Buckley",
      zip_code: "49620",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16566",
      city: "Burlington",
      zip_code: "49029",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16567",
      city: "Burr Oak",
      zip_code: "49030",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16298",
      city: "Burt",
      zip_code: "48417",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16886",
      city: "Burt Lake",
      zip_code: "49717",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16355",
      city: "Burton",
      zip_code: "48509",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16299",
      city: "Byron",
      zip_code: "48418",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16713",
      city: "Byron Center",
      zip_code: "49315",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16809",
      city: "Cadillac",
      zip_code: "49601",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16714",
      city: "Caledonia",
      zip_code: "49316",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "17015",
      city: "Calumet",
      zip_code: "49913",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16655",
      city: "Camden",
      zip_code: "49232",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16190",
      city: "Canton",
      zip_code: "48187",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16067",
      city: "Capac",
      zip_code: "48014",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16139",
      city: "Carleton",
      zip_code: "48117",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16948",
      city: "Carney",
      zip_code: "49812",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16419",
      city: "Caro",
      zip_code: "48723",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16887",
      city: "Carp Lake",
      zip_code: "49718",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16420",
      city: "Carrollton",
      zip_code: "48724",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16465",
      city: "Carson City",
      zip_code: "48811",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16300",
      city: "Carsonville",
      zip_code: "48419",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16101",
      city: "Casco",
      zip_code: "48064",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16421",
      city: "Caseville",
      zip_code: "48725",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16715",
      city: "Casnovia",
      zip_code: "49318",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "17016",
      city: "Caspian",
      zip_code: "49915",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16422",
      city: "Cass City",
      zip_code: "48726",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16568",
      city: "Cassopolis",
      zip_code: "49031",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16820",
      city: "Cedar",
      zip_code: "49621",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16716",
      city: "Cedar Springs",
      zip_code: "49319",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16888",
      city: "Cedarville",
      zip_code: "49719",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16656",
      city: "Cement City",
      zip_code: "49233",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16068",
      city: "Center Line",
      zip_code: "48015",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16821",
      city: "Central Lake",
      zip_code: "49622",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16569",
      city: "Centreville",
      zip_code: "49032",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16570",
      city: "Ceresco",
      zip_code: "49033",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16949",
      city: "Champion",
      zip_code: "49814",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16950",
      city: "Channing",
      zip_code: "49815",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16889",
      city: "Charlevoix",
      zip_code: "49720",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16466",
      city: "Charlotte",
      zip_code: "48813",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16822",
      city: "Chase",
      zip_code: "49623",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "17017",
      city: "Chassell",
      zip_code: "49916",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16951",
      city: "Chatham",
      zip_code: "49816",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16890",
      city: "Cheboygan",
      zip_code: "49721",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16140",
      city: "Chelsea",
      zip_code: "48118",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16371",
      city: "Chesaning",
      zip_code: "48616",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16717",
      city: "Chippewa Lake",
      zip_code: "49320",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16372",
      city: "Clare",
      zip_code: "48617",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16657",
      city: "Clarklake",
      zip_code: "49234",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16267",
      city: "Clarkston",
      zip_code: "48346",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16467",
      city: "Clarksville",
      zip_code: "48815",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16069",
      city: "Clawson",
      zip_code: "48017",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16658",
      city: "Clayton",
      zip_code: "49235",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16423",
      city: "Clifford",
      zip_code: "48727",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16571",
      city: "Climax",
      zip_code: "49034",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16659",
      city: "Clinton",
      zip_code: "49236",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16081",
      city: "Clinton Township",
      zip_code: "48035",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16301",
      city: "Clio",
      zip_code: "48420",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16468",
      city: "Cohoctah",
      zip_code: "48816",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16572",
      city: "Coldwater",
      zip_code: "49036",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16373",
      city: "Coleman",
      zip_code: "48618",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16574",
      city: "Coloma",
      zip_code: "49038",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16575",
      city: "Colon",
      zip_code: "49040",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16302",
      city: "Columbiaville",
      zip_code: "48421",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16100",
      city: "Columbus",
      zip_code: "48063",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16374",
      city: "Comins",
      zip_code: "48619",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16285",
      city: "Commerce Township",
      zip_code: "48382",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16718",
      city: "Comstock Park",
      zip_code: "49321",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16660",
      city: "Concord",
      zip_code: "49237",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16747",
      city: "Conklin",
      zip_code: "49403",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16576",
      city: "Constantine",
      zip_code: "49042",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16891",
      city: "Conway",
      zip_code: "49722",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16952",
      city: "Cooks",
      zip_code: "49817",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16748",
      city: "Coopersville",
      zip_code: "49404",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16823",
      city: "Copemish",
      zip_code: "49625",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16719",
      city: "Coral",
      zip_code: "49322",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16953",
      city: "Cornell",
      zip_code: "49818",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16469",
      city: "Corunna",
      zip_code: "48817",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16577",
      city: "Covert",
      zip_code: "49043",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "17020",
      city: "Covington",
      zip_code: "49919",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16303",
      city: "Croswell",
      zip_code: "48422",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16470",
      city: "Crystal",
      zip_code: "48818",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "17021",
      city: "Crystal Falls",
      zip_code: "49920",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16424",
      city: "Curran",
      zip_code: "48728",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16954",
      city: "Curtis",
      zip_code: "49820",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16749",
      city: "Custer",
      zip_code: "49405",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16892",
      city: "Dafter",
      zip_code: "49724",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16955",
      city: "Daggett",
      zip_code: "49821",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16471",
      city: "Dansville",
      zip_code: "48819",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16269",
      city: "Davisburg",
      zip_code: "48350",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16304",
      city: "Davison",
      zip_code: "48423",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16893",
      city: "De Tour Village",
      zip_code: "49725",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16141",
      city: "Dearborn",
      zip_code: "48120",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16144",
      city: "Dearborn Heights",
      zip_code: "48125",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16578",
      city: "Decatur",
      zip_code: "49045",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16305",
      city: "Decker",
      zip_code: "48426",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16306",
      city: "Deckerville",
      zip_code: "48427",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16661",
      city: "Deerfield",
      zip_code: "49238",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16956",
      city: "Deerton",
      zip_code: "49822",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16425",
      city: "Deford",
      zip_code: "48729",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16579",
      city: "Delton",
      zip_code: "49046",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16200",
      city: "Detroit",
      zip_code: "48201",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16472",
      city: "Dewitt",
      zip_code: "48820",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16148",
      city: "Dexter",
      zip_code: "48130",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16473",
      city: "Dimondale",
      zip_code: "48821",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "17022",
      city: "Dodgeville",
      zip_code: "49921",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "17023",
      city: "Dollar Bay",
      zip_code: "49922",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16720",
      city: "Dorr",
      zip_code: "49323",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16750",
      city: "Douglas",
      zip_code: "49406",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16580",
      city: "Dowagiac",
      zip_code: "49047",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16582",
      city: "Dowling",
      zip_code: "49050",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16894",
      city: "Drummond Island",
      zip_code: "49726",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16307",
      city: "Dryden",
      zip_code: "48428",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16149",
      city: "Dundee",
      zip_code: "48131",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16308",
      city: "Durand",
      zip_code: "48429",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16474",
      city: "Eagle",
      zip_code: "48822",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16096",
      city: "East China",
      zip_code: "48054",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16895",
      city: "East Jordan",
      zip_code: "49727",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16475",
      city: "East Lansing",
      zip_code: "48823",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16583",
      city: "East Leroy",
      zip_code: "49051",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16426",
      city: "East Tawas",
      zip_code: "48730",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16824",
      city: "Eastlake",
      zip_code: "49626",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16070",
      city: "Eastpointe",
      zip_code: "48021",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16825",
      city: "Eastport",
      zip_code: "49627",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16478",
      city: "Eaton Rapids",
      zip_code: "48827",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16631",
      city: "Eau Claire",
      zip_code: "49111",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16957",
      city: "Eben Junction",
      zip_code: "49825",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16896",
      city: "Eckerman",
      zip_code: "49728",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16227",
      city: "Ecorse",
      zip_code: "48229",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16375",
      city: "Edenville",
      zip_code: "48620",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16479",
      city: "Edmore",
      zip_code: "48829",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16632",
      city: "Edwardsburg",
      zip_code: "49112",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16826",
      city: "Elberta",
      zip_code: "49628",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16827",
      city: "Elk Rapids",
      zip_code: "49629",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16427",
      city: "Elkton",
      zip_code: "48731",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16897",
      city: "Ellsworth",
      zip_code: "49729",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16898",
      city: "Elmira",
      zip_code: "49730",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16480",
      city: "Elsie",
      zip_code: "48831",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16481",
      city: "Elwell",
      zip_code: "48832",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16071",
      city: "Emmett",
      zip_code: "48022",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16828",
      city: "Empire",
      zip_code: "49630",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16959",
      city: "Engadine",
      zip_code: "49827",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16150",
      city: "Erie",
      zip_code: "48133",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16960",
      city: "Escanaba",
      zip_code: "49829",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16428",
      city: "Essexville",
      zip_code: "48732",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16829",
      city: "Evart",
      zip_code: "49631",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "17024",
      city: "Ewen",
      zip_code: "49925",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16072",
      city: "Fair Haven",
      zip_code: "48023",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16429",
      city: "Fairgrove",
      zip_code: "48733",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16376",
      city: "Fairview",
      zip_code: "48621",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16830",
      city: "Falmouth",
      zip_code: "49632",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16260",
      city: "Farmington",
      zip_code: "48331",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16377",
      city: "Farwell",
      zip_code: "48622",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16961",
      city: "Felch",
      zip_code: "49831",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16751",
      city: "Fennville",
      zip_code: "49408",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16309",
      city: "Fenton",
      zip_code: "48430",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16482",
      city: "Fenwick",
      zip_code: "48834",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16219",
      city: "Ferndale",
      zip_code: "48220",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16752",
      city: "Ferrysburg",
      zip_code: "49409",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16831",
      city: "Fife Lake",
      zip_code: "49633",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16832",
      city: "Filer City",
      zip_code: "49634",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16310",
      city: "Filion",
      zip_code: "48432",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16151",
      city: "Flat Rock",
      zip_code: "48134",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16349",
      city: "Flint",
      zip_code: "48502",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16311",
      city: "Flushing",
      zip_code: "48433",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16312",
      city: "Forestville",
      zip_code: "48434",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16097",
      city: "Fort Gratiot",
      zip_code: "48059",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16963",
      city: "Foster City",
      zip_code: "49834",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16313",
      city: "Fostoria",
      zip_code: "48435",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16753",
      city: "Fountain",
      zip_code: "49410",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16483",
      city: "Fowler",
      zip_code: "48835",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16484",
      city: "Fowlerville",
      zip_code: "48836",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16430",
      city: "Frankenmuth",
      zip_code: "48734",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16833",
      city: "Frankfort",
      zip_code: "49635",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16073",
      city: "Franklin",
      zip_code: "48025",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16074",
      city: "Fraser",
      zip_code: "48026",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16899",
      city: "Frederic",
      zip_code: "49733",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16754",
      city: "Free Soil",
      zip_code: "49411",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16378",
      city: "Freeland",
      zip_code: "48623",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16721",
      city: "Freeport",
      zip_code: "49325",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16755",
      city: "Fremont",
      zip_code: "49412",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16756",
      city: "Fruitport",
      zip_code: "49415",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16584",
      city: "Fulton",
      zip_code: "49052",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "17025",
      city: "Gaastra",
      zip_code: "49927",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16431",
      city: "Gagetown",
      zip_code: "48735",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16314",
      city: "Gaines",
      zip_code: "48436",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16585",
      city: "Galesburg",
      zip_code: "49053",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16633",
      city: "Galien",
      zip_code: "49113",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16964",
      city: "Garden",
      zip_code: "49835",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16152",
      city: "Garden City",
      zip_code: "48135",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16900",
      city: "Gaylord",
      zip_code: "49735",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16315",
      city: "Genesee",
      zip_code: "48437",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16965",
      city: "Germfask",
      zip_code: "49836",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16966",
      city: "Gladstone",
      zip_code: "49837",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16379",
      city: "Gladwin",
      zip_code: "48624",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16834",
      city: "Glen Arbor",
      zip_code: "49636",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16432",
      city: "Glennie",
      zip_code: "48737",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16586",
      city: "Gobles",
      zip_code: "49055",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16901",
      city: "Goetzville",
      zip_code: "49736",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16075",
      city: "Goodells",
      zip_code: "48027",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16316",
      city: "Goodrich",
      zip_code: "48438",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16967",
      city: "Gould City",
      zip_code: "49838",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16722",
      city: "Gowen",
      zip_code: "49326",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16317",
      city: "Grand Blanc",
      zip_code: "48439",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16757",
      city: "Grand Haven",
      zip_code: "49417",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16587",
      city: "Grand Junction",
      zip_code: "49056",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16485",
      city: "Grand Ledge",
      zip_code: "48837",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16795",
      city: "Grand Rapids",
      zip_code: "49503",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16758",
      city: "Grandville",
      zip_code: "49418",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16723",
      city: "Grant",
      zip_code: "49327",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16662",
      city: "Grass Lake",
      zip_code: "49240",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16835",
      city: "Grawn",
      zip_code: "49637",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16902",
      city: "Grayling",
      zip_code: "49738",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16433",
      city: "Greenbush",
      zip_code: "48738",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16486",
      city: "Greenville",
      zip_code: "48838",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16153",
      city: "Gregory",
      zip_code: "48137",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16154",
      city: "Grosse Ile",
      zip_code: "48138",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16228",
      city: "Grosse Pointe",
      zip_code: "48230",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16969",
      city: "Gulliver",
      zip_code: "49840",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16970",
      city: "Gwinn",
      zip_code: "49841",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16318",
      city: "Hadley",
      zip_code: "48440",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16434",
      city: "Hale",
      zip_code: "48739",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16155",
      city: "Hamburg",
      zip_code: "48139",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16759",
      city: "Hamilton",
      zip_code: "49419",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16211",
      city: "Hamtramck",
      zip_code: "48212",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "17027",
      city: "Hancock",
      zip_code: "49930",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16663",
      city: "Hanover",
      zip_code: "49241",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16634",
      city: "Harbert",
      zip_code: "49115",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16319",
      city: "Harbor Beach",
      zip_code: "48441",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16903",
      city: "Harbor Springs",
      zip_code: "49740",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16223",
      city: "Harper Woods",
      zip_code: "48225",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16836",
      city: "Harrietta",
      zip_code: "49638",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16380",
      city: "Harrison",
      zip_code: "48625",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16090",
      city: "Harrison Township",
      zip_code: "48045",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16435",
      city: "Harrisville",
      zip_code: "48740",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16076",
      city: "Harsens Island",
      zip_code: "48028",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16760",
      city: "Hart",
      zip_code: "49420",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16588",
      city: "Hartford",
      zip_code: "49057",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16270",
      city: "Hartland",
      zip_code: "48353",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16487",
      city: "Haslett",
      zip_code: "48840",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16589",
      city: "Hastings",
      zip_code: "49058",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16904",
      city: "Hawks",
      zip_code: "49743",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16077",
      city: "Hazel Park",
      zip_code: "48030",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16381",
      city: "Hemlock",
      zip_code: "48626",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16488",
      city: "Henderson",
      zip_code: "48841",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16971",
      city: "Hermansville",
      zip_code: "49847",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16905",
      city: "Herron",
      zip_code: "49744",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16837",
      city: "Hersey",
      zip_code: "49639",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16761",
      city: "Hesperia",
      zip_code: "49421",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16906",
      city: "Hessel",
      zip_code: "49745",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16590",
      city: "Hickory Corners",
      zip_code: "49060",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16382",
      city: "Higgins Lake",
      zip_code: "48627",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16271",
      city: "Highland",
      zip_code: "48356",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16202",
      city: "Highland Park",
      zip_code: "48203",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16907",
      city: "Hillman",
      zip_code: "49746",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16664",
      city: "Hillsdale",
      zip_code: "49242",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16762",
      city: "Holland",
      zip_code: "49423",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16320",
      city: "Holly",
      zip_code: "48442",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16489",
      city: "Holt",
      zip_code: "48842",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16764",
      city: "Holton",
      zip_code: "49425",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16665",
      city: "Homer",
      zip_code: "49245",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16838",
      city: "Honor",
      zip_code: "49640",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16383",
      city: "Hope",
      zip_code: "48628",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16724",
      city: "Hopkins",
      zip_code: "49328",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16666",
      city: "Horton",
      zip_code: "49246",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "17028",
      city: "Houghton",
      zip_code: "49931",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16384",
      city: "Houghton Lake",
      zip_code: "48629",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16385",
      city: "Houghton Lake Heights",
      zip_code: "48630",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16725",
      city: "Howard City",
      zip_code: "49329",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16490",
      city: "Howell",
      zip_code: "48843",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16908",
      city: "Hubbard Lake",
      zip_code: "49747",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16491",
      city: "Hubbardston",
      zip_code: "48845",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16667",
      city: "Hudson",
      zip_code: "49247",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16765",
      city: "Hudsonville",
      zip_code: "49426",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16909",
      city: "Hulbert",
      zip_code: "49748",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16106",
      city: "Huntington Woods",
      zip_code: "48070",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16156",
      city: "Ida",
      zip_code: "48140",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16839",
      city: "Idlewild",
      zip_code: "49642",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16321",
      city: "Imlay City",
      zip_code: "48444",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16910",
      city: "Indian River",
      zip_code: "49749",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16972",
      city: "Ingalls",
      zip_code: "49848",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16157",
      city: "Inkster",
      zip_code: "48141",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16840",
      city: "Interlochen",
      zip_code: "49643",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16492",
      city: "Ionia",
      zip_code: "48846",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16942",
      city: "Iron Mountain",
      zip_code: "49801",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "17030",
      city: "Iron River",
      zip_code: "49935",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16841",
      city: "Irons",
      zip_code: "49644",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "17031",
      city: "Ironwood",
      zip_code: "49938",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16973",
      city: "Ishpeming",
      zip_code: "49849",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16493",
      city: "Ithaca",
      zip_code: "48847",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16645",
      city: "Jackson",
      zip_code: "49201",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16668",
      city: "Jasper",
      zip_code: "49248",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16078",
      city: "Jeddo",
      zip_code: "48032",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16766",
      city: "Jenison",
      zip_code: "49428",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16669",
      city: "Jerome",
      zip_code: "49249",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16911",
      city: "Johannesburg",
      zip_code: "49751",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16591",
      city: "Jones",
      zip_code: "49061",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16670",
      city: "Jonesville",
      zip_code: "49250",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16546",
      city: "Kalamazoo",
      zip_code: "49001",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16842",
      city: "Kaleva",
      zip_code: "49645",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16843",
      city: "Kalkaska",
      zip_code: "49646",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16386",
      city: "Kawkawlin",
      zip_code: "48631",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16252",
      city: "Keego Harbor",
      zip_code: "48320",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16726",
      city: "Kent City",
      zip_code: "49330",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16844",
      city: "Kewadin",
      zip_code: "49648",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16935",
      city: "Kincheloe",
      zip_code: "49784",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16322",
      city: "Kinde",
      zip_code: "48445",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16943",
      city: "Kingsford",
      zip_code: "49802",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16845",
      city: "Kingsley",
      zip_code: "49649",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16436",
      city: "Kingston",
      zip_code: "48741",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16912",
      city: "Kinross",
      zip_code: "49752",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16160",
      city: "La Salle",
      zip_code: "48145",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16913",
      city: "Lachine",
      zip_code: "49753",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16592",
      city: "Lacota",
      zip_code: "49063",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16494",
      city: "Laingsburg",
      zip_code: "48848",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16387",
      city: "Lake",
      zip_code: "48632",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16846",
      city: "Lake Ann",
      zip_code: "49650",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16847",
      city: "Lake City",
      zip_code: "49651",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16388",
      city: "Lake George",
      zip_code: "48633",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16848",
      city: "Lake Leelanau",
      zip_code: "49653",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "17033",
      city: "Lake Linden",
      zip_code: "49945",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16495",
      city: "Lake Odessa",
      zip_code: "48849",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16273",
      city: "Lake Orion",
      zip_code: "48359",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16158",
      city: "Lakeland",
      zip_code: "48143",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16635",
      city: "Lakeside",
      zip_code: "49116",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16496",
      city: "Lakeview",
      zip_code: "48850",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16159",
      city: "Lambertville",
      zip_code: "48144",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16767",
      city: "Lamont",
      zip_code: "49430",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "17034",
      city: "Lanse",
      zip_code: "49946",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16539",
      city: "Lansing",
      zip_code: "48906",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16323",
      city: "Lapeer",
      zip_code: "48446",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16593",
      city: "Lawrence",
      zip_code: "49064",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16594",
      city: "Lawton",
      zip_code: "49065",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16849",
      city: "Leland",
      zip_code: "49654",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16324",
      city: "Lennon",
      zip_code: "48449",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16277",
      city: "Leonard",
      zip_code: "48367",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16595",
      city: "Leonidas",
      zip_code: "49066",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16850",
      city: "Leroy",
      zip_code: "49655",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16671",
      city: "Leslie",
      zip_code: "49251",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16914",
      city: "Levering",
      zip_code: "49755",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16915",
      city: "Lewiston",
      zip_code: "49756",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16325",
      city: "Lexington",
      zip_code: "48450",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16437",
      city: "Lincoln",
      zip_code: "48742",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16161",
      city: "Lincoln Park",
      zip_code: "48146",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16326",
      city: "Linden",
      zip_code: "48451",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16389",
      city: "Linwood",
      zip_code: "48634",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16672",
      city: "Litchfield",
      zip_code: "49252",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16962",
      city: "Little Lake",
      zip_code: "49833",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16162",
      city: "Livonia",
      zip_code: "48150",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16438",
      city: "Long Lake",
      zip_code: "48743",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16974",
      city: "Loretto",
      zip_code: "49852",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16727",
      city: "Lowell",
      zip_code: "49331",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16768",
      city: "Ludington",
      zip_code: "49431",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16165",
      city: "Luna Pier",
      zip_code: "48157",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16390",
      city: "Lupton",
      zip_code: "48635",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16851",
      city: "Luther",
      zip_code: "49656",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16391",
      city: "Luzerne",
      zip_code: "48636",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16497",
      city: "Lyons",
      zip_code: "48851",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16769",
      city: "Macatawa",
      zip_code: "49434",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16916",
      city: "Mackinac Island",
      zip_code: "49757",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16876",
      city: "Mackinaw City",
      zip_code: "49701",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16087",
      city: "Macomb",
      zip_code: "48042",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16107",
      city: "Madison Heights",
      zip_code: "48071",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16853",
      city: "Mancelona",
      zip_code: "49659",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16166",
      city: "Manchester",
      zip_code: "48158",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16854",
      city: "Manistee",
      zip_code: "49660",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16976",
      city: "Manistique",
      zip_code: "49854",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16673",
      city: "Manitou Beach",
      zip_code: "49253",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16855",
      city: "Manton",
      zip_code: "49663",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16856",
      city: "Maple City",
      zip_code: "49664",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16499",
      city: "Maple Rapids",
      zip_code: "48853",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16596",
      city: "Marcellus",
      zip_code: "49067",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "17035",
      city: "Marenisco",
      zip_code: "49947",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16084",
      city: "Marine City",
      zip_code: "48039",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16857",
      city: "Marion",
      zip_code: "49665",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16327",
      city: "Marlette",
      zip_code: "48453",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16770",
      city: "Marne",
      zip_code: "49435",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16977",
      city: "Marquette",
      zip_code: "49855",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16597",
      city: "Marshall",
      zip_code: "49068",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16598",
      city: "Martin",
      zip_code: "49070",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16085",
      city: "Marysville",
      zip_code: "48040",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16500",
      city: "Mason",
      zip_code: "48854",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "17036",
      city: "Mass City",
      zip_code: "49948",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16599",
      city: "Mattawan",
      zip_code: "49071",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16167",
      city: "Maybee",
      zip_code: "48159",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16439",
      city: "Mayville",
      zip_code: "48744",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16852",
      city: "McBain",
      zip_code: "49657",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16498",
      city: "Mcbrides",
      zip_code: "48852",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16975",
      city: "McMillan",
      zip_code: "49853",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16771",
      city: "Mears",
      zip_code: "49436",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16728",
      city: "Mecosta",
      zip_code: "49332",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16328",
      city: "Melvin",
      zip_code: "48454",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16142",
      city: "Melvindale",
      zip_code: "48122",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16086",
      city: "Memphis",
      zip_code: "48041",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16600",
      city: "Mendon",
      zip_code: "49072",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16978",
      city: "Menominee",
      zip_code: "49858",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16392",
      city: "Merrill",
      zip_code: "48637",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16858",
      city: "Merritt",
      zip_code: "49667",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16859",
      city: "Mesick",
      zip_code: "49668",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16329",
      city: "Metamora",
      zip_code: "48455",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16979",
      city: "Michigamme",
      zip_code: "49861",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16674",
      city: "Michigan Center",
      zip_code: "49254",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16502",
      city: "Middleton",
      zip_code: "48856",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16729",
      city: "Middleville",
      zip_code: "49333",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16394",
      city: "Midland",
      zip_code: "48640",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16440",
      city: "Mikado",
      zip_code: "48745",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16168",
      city: "Milan",
      zip_code: "48160",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16283",
      city: "Milford",
      zip_code: "48380",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16917",
      city: "Millersburg",
      zip_code: "49759",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16441",
      city: "Millington",
      zip_code: "48746",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16330",
      city: "Minden City",
      zip_code: "48456",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16396",
      city: "Mio",
      zip_code: "48647",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "17037",
      city: "Mohawk",
      zip_code: "49950",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16730",
      city: "Moline",
      zip_code: "49335",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16169",
      city: "Monroe",
      zip_code: "48161",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16772",
      city: "Montague",
      zip_code: "49437",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16675",
      city: "Montgomery",
      zip_code: "49255",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16331",
      city: "Montrose",
      zip_code: "48457",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16918",
      city: "Moran",
      zip_code: "49760",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16676",
      city: "Morenci",
      zip_code: "49256",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16731",
      city: "Morley",
      zip_code: "49336",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16503",
      city: "Morrice",
      zip_code: "48857",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16677",
      city: "Mosherville",
      zip_code: "49258",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16088",
      city: "Mount Clemens",
      zip_code: "48043",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16332",
      city: "Mount Morris",
      zip_code: "48458",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16504",
      city: "Mount Pleasant",
      zip_code: "48858",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16506",
      city: "Muir",
      zip_code: "48860",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16507",
      city: "Mulliken",
      zip_code: "48861",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16442",
      city: "Munger",
      zip_code: "48747",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16980",
      city: "Munising",
      zip_code: "49862",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16678",
      city: "Munith",
      zip_code: "49259",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16773",
      city: "Muskegon",
      zip_code: "49440",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16981",
      city: "Nadeau",
      zip_code: "49863",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16982",
      city: "Nahma",
      zip_code: "49864",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16679",
      city: "Napoleon",
      zip_code: "49261",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16601",
      city: "Nashville",
      zip_code: "49073",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16443",
      city: "National City",
      zip_code: "48748",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16919",
      city: "Naubinway",
      zip_code: "49762",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16602",
      city: "Nazareth",
      zip_code: "49074",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16983",
      city: "Negaunee",
      zip_code: "49866",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16091",
      city: "New Baltimore",
      zip_code: "48047",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16171",
      city: "New Boston",
      zip_code: "48164",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16636",
      city: "New Buffalo",
      zip_code: "49117",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16779",
      city: "New Era",
      zip_code: "49446",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16092",
      city: "New Haven",
      zip_code: "48048",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16172",
      city: "New Hudson",
      zip_code: "48165",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16333",
      city: "New Lothrop",
      zip_code: "48460",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16732",
      city: "Newaygo",
      zip_code: "49337",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16984",
      city: "Newberry",
      zip_code: "49868",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16173",
      city: "Newport",
      zip_code: "48166",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16638",
      city: "Niles",
      zip_code: "49120",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "17038",
      city: "Nisula",
      zip_code: "49952",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16680",
      city: "North Adams",
      zip_code: "49262",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16334",
      city: "North Branch",
      zip_code: "48461",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16508",
      city: "North Star",
      zip_code: "48862",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16093",
      city: "North Street",
      zip_code: "48049",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16860",
      city: "Northport",
      zip_code: "49670",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16174",
      city: "Northville",
      zip_code: "48167",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16681",
      city: "Norvell",
      zip_code: "49263",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16985",
      city: "Norway",
      zip_code: "49870",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16603",
      city: "Nottawa",
      zip_code: "49075",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16280",
      city: "Novi",
      zip_code: "48374",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16780",
      city: "Nunica",
      zip_code: "49448",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16233",
      city: "Oak Park",
      zip_code: "48237",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16276",
      city: "Oakland",
      zip_code: "48363",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16397",
      city: "Oakley",
      zip_code: "48649",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16920",
      city: "Oden",
      zip_code: "49764",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16509",
      city: "Okemos",
      zip_code: "48864",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16604",
      city: "Olivet",
      zip_code: "49076",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16861",
      city: "Omena",
      zip_code: "49674",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16444",
      city: "Omer",
      zip_code: "48749",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16921",
      city: "Onaway",
      zip_code: "49765",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16862",
      city: "Onekama",
      zip_code: "49675",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16682",
      city: "Onondaga",
      zip_code: "49264",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16683",
      city: "Onsted",
      zip_code: "49265",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "17039",
      city: "Ontonagon",
      zip_code: "49953",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16510",
      city: "Orleans",
      zip_code: "48865",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16335",
      city: "Ortonville",
      zip_code: "48462",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16445",
      city: "Oscoda",
      zip_code: "48750",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16684",
      city: "Osseo",
      zip_code: "49266",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16922",
      city: "Ossineke",
      zip_code: "49766",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16336",
      city: "Otisville",
      zip_code: "48463",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16605",
      city: "Otsego",
      zip_code: "49078",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16685",
      city: "Ottawa Lake",
      zip_code: "49267",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16337",
      city: "Otter Lake",
      zip_code: "48464",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16511",
      city: "Ovid",
      zip_code: "48866",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16446",
      city: "Owendale",
      zip_code: "48754",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16512",
      city: "Owosso",
      zip_code: "48867",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16278",
      city: "Oxford",
      zip_code: "48370",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "17040",
      city: "Painesdale",
      zip_code: "49955",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16986",
      city: "Palmer",
      zip_code: "49871",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16338",
      city: "Palms",
      zip_code: "48465",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16686",
      city: "Palmyra",
      zip_code: "49268",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16513",
      city: "Palo",
      zip_code: "48870",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16923",
      city: "Paradise",
      zip_code: "49768",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16733",
      city: "Paris",
      zip_code: "49338",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16687",
      city: "Parma",
      zip_code: "49269",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16606",
      city: "Paw Paw",
      zip_code: "49079",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16339",
      city: "Peck",
      zip_code: "48466",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "17041",
      city: "Pelkie",
      zip_code: "49958",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16924",
      city: "Pellston",
      zip_code: "49769",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16781",
      city: "Pentwater",
      zip_code: "49449",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16514",
      city: "Perrinton",
      zip_code: "48871",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16988",
      city: "Perronville",
      zip_code: "49873",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16515",
      city: "Perry",
      zip_code: "48872",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16688",
      city: "Petersburg",
      zip_code: "49270",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16925",
      city: "Petoskey",
      zip_code: "49770",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16516",
      city: "Pewamo",
      zip_code: "48873",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16926",
      city: "Pickford",
      zip_code: "49774",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16734",
      city: "Pierson",
      zip_code: "49339",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16447",
      city: "Pigeon",
      zip_code: "48755",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16176",
      city: "Pinckney",
      zip_code: "48169",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16398",
      city: "Pinconning",
      zip_code: "48650",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16689",
      city: "Pittsford",
      zip_code: "49271",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16607",
      city: "Plainwell",
      zip_code: "49080",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16690",
      city: "Pleasant Lake",
      zip_code: "49272",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16105",
      city: "Pleasant Ridge",
      zip_code: "48069",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16177",
      city: "Plymouth",
      zip_code: "48170",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16927",
      city: "Pointe Aux Pins",
      zip_code: "49775",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16517",
      city: "Pompeii",
      zip_code: "48874",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16264",
      city: "Pontiac",
      zip_code: "48340",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16340",
      city: "Port Austin",
      zip_code: "48467",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16341",
      city: "Port Hope",
      zip_code: "48468",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16098",
      city: "Port Huron",
      zip_code: "48060",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16342",
      city: "Port Sanilac",
      zip_code: "48469",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16547",
      city: "Portage",
      zip_code: "49002",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16518",
      city: "Portland",
      zip_code: "48875",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16928",
      city: "Posen",
      zip_code: "49776",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16519",
      city: "Potterville",
      zip_code: "48876",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16989",
      city: "Powers",
      zip_code: "49874",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16448",
      city: "Prescott",
      zip_code: "48756",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16929",
      city: "Presque Isle",
      zip_code: "49777",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16399",
      city: "Prudenville",
      zip_code: "48651",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16782",
      city: "Pullman",
      zip_code: "49450",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16608",
      city: "Quincy",
      zip_code: "49082",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16990",
      city: "Quinnesec",
      zip_code: "49876",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "17042",
      city: "Ramsay",
      zip_code: "49959",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16863",
      city: "Rapid City",
      zip_code: "49676",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16992",
      city: "Rapid River",
      zip_code: "49878",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16783",
      city: "Ravenna",
      zip_code: "49451",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16127",
      city: "Ray",
      zip_code: "48096",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16691",
      city: "Reading",
      zip_code: "49274",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16235",
      city: "Redford",
      zip_code: "48239",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16864",
      city: "Reed City",
      zip_code: "49677",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16449",
      city: "Reese",
      zip_code: "48757",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16735",
      city: "Remus",
      zip_code: "49340",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16993",
      city: "Republic",
      zip_code: "49879",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16400",
      city: "Rhodes",
      zip_code: "48652",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16609",
      city: "Richland",
      zip_code: "49083",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16099",
      city: "Richmond",
      zip_code: "48062",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16692",
      city: "Riga",
      zip_code: "49276",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16217",
      city: "River Rouge",
      zip_code: "48218",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16520",
      city: "Riverdale",
      zip_code: "48877",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16610",
      city: "Riverside",
      zip_code: "49084",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16196",
      city: "Riverview",
      zip_code: "48193",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16693",
      city: "Rives Junction",
      zip_code: "49277",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16242",
      city: "Rochester",
      zip_code: "48306",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16994",
      city: "Rock",
      zip_code: "49880",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16736",
      city: "Rockford",
      zip_code: "49341",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16178",
      city: "Rockwood",
      zip_code: "48173",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16737",
      city: "Rodney",
      zip_code: "49342",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16930",
      city: "Rogers City",
      zip_code: "49779",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16102",
      city: "Romeo",
      zip_code: "48065",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16179",
      city: "Romulus",
      zip_code: "48174",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16401",
      city: "Roscommon",
      zip_code: "48653",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16402",
      city: "Rose City",
      zip_code: "48654",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16521",
      city: "Rosebush",
      zip_code: "48878",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16103",
      city: "Roseville",
      zip_code: "48066",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16784",
      city: "Rothbury",
      zip_code: "49452",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16104",
      city: "Royal Oak",
      zip_code: "48067",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16931",
      city: "Rudyard",
      zip_code: "49780",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16958",
      city: "Rumely",
      zip_code: "49826",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16343",
      city: "Ruth",
      zip_code: "48470",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16359",
      city: "Saginaw",
      zip_code: "48601",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16995",
      city: "Sagola",
      zip_code: "49881",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16403",
      city: "Saint Charles",
      zip_code: "48655",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16113",
      city: "Saint Clair",
      zip_code: "48079",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16114",
      city: "Saint Clair Shores",
      zip_code: "48080",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16404",
      city: "Saint Helen",
      zip_code: "48656",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16932",
      city: "Saint Ignace",
      zip_code: "49781",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16522",
      city: "Saint Johns",
      zip_code: "48879",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16611",
      city: "Saint Joseph",
      zip_code: "49085",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16523",
      city: "Saint Louis",
      zip_code: "48880",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16180",
      city: "Saline",
      zip_code: "48176",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16181",
      city: "Samaria",
      zip_code: "48177",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16694",
      city: "Sand Creek",
      zip_code: "49279",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16738",
      city: "Sand Lake",
      zip_code: "49343",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16344",
      city: "Sandusky",
      zip_code: "48471",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16405",
      city: "Sanford",
      zip_code: "48657",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16524",
      city: "Saranac",
      zip_code: "48881",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16785",
      city: "Saugatuck",
      zip_code: "49453",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16934",
      city: "Sault Sainte Marie",
      zip_code: "49783",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16639",
      city: "Sawyer",
      zip_code: "49125",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16612",
      city: "Schoolcraft",
      zip_code: "49087",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16613",
      city: "Scotts",
      zip_code: "49088",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16786",
      city: "Scottville",
      zip_code: "49454",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16865",
      city: "Sears",
      zip_code: "49679",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16450",
      city: "Sebewaing",
      zip_code: "48759",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16996",
      city: "Seney",
      zip_code: "49883",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16787",
      city: "Shelby",
      zip_code: "49455",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16739",
      city: "Shelbyville",
      zip_code: "49344",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16525",
      city: "Shepherd",
      zip_code: "48883",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16526",
      city: "Sheridan",
      zip_code: "48884",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16614",
      city: "Sherwood",
      zip_code: "49089",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16997",
      city: "Shingleton",
      zip_code: "49884",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16527",
      city: "Sidney",
      zip_code: "48885",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16451",
      city: "Silverwood",
      zip_code: "48760",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16528",
      city: "Six Lakes",
      zip_code: "48886",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16998",
      city: "Skandia",
      zip_code: "49885",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "17045",
      city: "Skanee",
      zip_code: "49962",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16110",
      city: "Smiths Creek",
      zip_code: "48074",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16345",
      city: "Snover",
      zip_code: "48472",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16640",
      city: "Sodus",
      zip_code: "49126",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16695",
      city: "Somerset Center",
      zip_code: "49282",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16866",
      city: "South Boardman",
      zip_code: "49680",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16452",
      city: "South Branch",
      zip_code: "48761",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16615",
      city: "South Haven",
      zip_code: "49090",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16182",
      city: "South Lyon",
      zip_code: "48178",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "17046",
      city: "South Range",
      zip_code: "49963",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16183",
      city: "South Rockwood",
      zip_code: "48179",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16079",
      city: "Southfield",
      zip_code: "48033",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16197",
      city: "Southgate",
      zip_code: "48195",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16999",
      city: "Spalding",
      zip_code: "49886",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16740",
      city: "Sparta",
      zip_code: "49345",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16696",
      city: "Spring Arbor",
      zip_code: "49283",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16788",
      city: "Spring Lake",
      zip_code: "49456",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16697",
      city: "Springport",
      zip_code: "49284",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16453",
      city: "Spruce",
      zip_code: "48762",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16406",
      city: "Standish",
      zip_code: "48658",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16529",
      city: "Stanton",
      zip_code: "48888",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16741",
      city: "Stanwood",
      zip_code: "49346",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "17000",
      city: "Stephenson",
      zip_code: "49887",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16407",
      city: "Sterling",
      zip_code: "48659",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16245",
      city: "Sterling Heights",
      zip_code: "48310",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16641",
      city: "Stevensville",
      zip_code: "49127",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16698",
      city: "Stockbridge",
      zip_code: "49285",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16616",
      city: "Sturgis",
      zip_code: "49091",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16530",
      city: "Sumner",
      zip_code: "48889",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16531",
      city: "Sunfield",
      zip_code: "48890",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16867",
      city: "Suttons Bay",
      zip_code: "49682",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16346",
      city: "Swartz Creek",
      zip_code: "48473",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16454",
      city: "Tawas City",
      zip_code: "48763",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16184",
      city: "Taylor",
      zip_code: "48180",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16699",
      city: "Tecumseh",
      zip_code: "49286",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16617",
      city: "Tekonsha",
      zip_code: "49092",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16185",
      city: "Temperance",
      zip_code: "48182",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16868",
      city: "Thompsonville",
      zip_code: "49683",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16642",
      city: "Three Oaks",
      zip_code: "49128",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16618",
      city: "Three Rivers",
      zip_code: "49093",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16700",
      city: "Tipton",
      zip_code: "49287",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "17047",
      city: "Toivola",
      zip_code: "49965",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16937",
      city: "Topinabee",
      zip_code: "49791",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16869",
      city: "Traverse City",
      zip_code: "49684",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "17001",
      city: "Trenary",
      zip_code: "49891",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16186",
      city: "Trenton",
      zip_code: "48183",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "17048",
      city: "Trout Creek",
      zip_code: "49967",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16938",
      city: "Trout Lake",
      zip_code: "49793",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16117",
      city: "Troy",
      zip_code: "48083",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16742",
      city: "Trufant",
      zip_code: "49347",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16455",
      city: "Turner",
      zip_code: "48765",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16872",
      city: "Tustin",
      zip_code: "49688",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16789",
      city: "Twin Lake",
      zip_code: "49457",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16456",
      city: "Twining",
      zip_code: "48766",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16347",
      city: "Ubly",
      zip_code: "48475",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16644",
      city: "Union",
      zip_code: "49130",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16619",
      city: "Union City",
      zip_code: "49094",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16643",
      city: "Union Pier",
      zip_code: "49129",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16457",
      city: "Unionville",
      zip_code: "48767",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16415",
      city: "University Center",
      zip_code: "48710",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16249",
      city: "Utica",
      zip_code: "48315",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16620",
      city: "Vandalia",
      zip_code: "49095",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16939",
      city: "Vanderbilt",
      zip_code: "49795",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16458",
      city: "Vassar",
      zip_code: "48768",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16621",
      city: "Vermontville",
      zip_code: "49096",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16348",
      city: "Vernon",
      zip_code: "48476",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16532",
      city: "Vestaburg",
      zip_code: "48891",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16622",
      city: "Vicksburg",
      zip_code: "49097",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "17002",
      city: "Vulcan",
      zip_code: "49892",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "17049",
      city: "Wakefield",
      zip_code: "49968",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16701",
      city: "Waldron",
      zip_code: "49288",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16790",
      city: "Walhalla",
      zip_code: "49458",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16791",
      city: "Walkerville",
      zip_code: "49459",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "17003",
      city: "Wallace",
      zip_code: "49893",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16288",
      city: "Walled Lake",
      zip_code: "48390",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16940",
      city: "Walloon Lake",
      zip_code: "49796",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16120",
      city: "Warren",
      zip_code: "48088",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16125",
      city: "Washington",
      zip_code: "48094",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16257",
      city: "Waterford",
      zip_code: "48327",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "17050",
      city: "Watersmeet",
      zip_code: "49969",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16623",
      city: "Watervliet",
      zip_code: "49098",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "17051",
      city: "Watton",
      zip_code: "49970",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16743",
      city: "Wayland",
      zip_code: "49348",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16187",
      city: "Wayne",
      zip_code: "48184",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16533",
      city: "Webberville",
      zip_code: "48892",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16534",
      city: "Weidman",
      zip_code: "48893",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "17004",
      city: "Wells",
      zip_code: "49894",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16873",
      city: "Wellston",
      zip_code: "49689",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16253",
      city: "West Bloomfield",
      zip_code: "48322",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16408",
      city: "West Branch",
      zip_code: "48661",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16792",
      city: "West Olive",
      zip_code: "49460",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16188",
      city: "Westland",
      zip_code: "48185",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16702",
      city: "Weston",
      zip_code: "49289",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16535",
      city: "Westphalia",
      zip_code: "48894",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "17005",
      city: "Wetmore",
      zip_code: "49895",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16409",
      city: "Wheeler",
      zip_code: "48662",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16744",
      city: "White Cloud",
      zip_code: "49349",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16286",
      city: "White Lake",
      zip_code: "48383",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16624",
      city: "White Pigeon",
      zip_code: "49099",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "17052",
      city: "White Pine",
      zip_code: "49971",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16793",
      city: "Whitehall",
      zip_code: "49461",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16192",
      city: "Whitmore Lake",
      zip_code: "48189",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16193",
      city: "Whittaker",
      zip_code: "48190",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16459",
      city: "Whittemore",
      zip_code: "48770",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16874",
      city: "Williamsburg",
      zip_code: "49690",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16536",
      city: "Williamston",
      zip_code: "48895",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16194",
      city: "Willis",
      zip_code: "48191",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "17006",
      city: "Wilson",
      zip_code: "49896",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16537",
      city: "Winn",
      zip_code: "48896",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16289",
      city: "Wixom",
      zip_code: "48393",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16941",
      city: "Wolverine",
      zip_code: "49799",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16538",
      city: "Woodland",
      zip_code: "48897",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16195",
      city: "Wyandotte",
      zip_code: "48192",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16801",
      city: "Wyoming",
      zip_code: "49509",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16128",
      city: "Yale",
      zip_code: "48097",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16198",
      city: "Ypsilanti",
      zip_code: "48197",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "16794",
      city: "Zeeland",
      zip_code: "49464",
      state_id: "MI",
      state_name: "Michigan",
    },
    {
      city_id: "907",
      city: "Acworth",
      zip_code: "03601",
      state_id: "NH",
      state_name: "New Hampshire",
    },
    {
      city_id: "908",
      city: "Alstead",
      zip_code: "03602",
      state_id: "NH",
      state_name: "New Hampshire",
    },
    {
      city_id: "940",
      city: "Alton",
      zip_code: "03809",
      state_id: "NH",
      state_name: "New Hampshire",
    },
    {
      city_id: "941",
      city: "Alton Bay",
      zip_code: "03810",
      state_id: "NH",
      state_name: "New Hampshire",
    },
    {
      city_id: "759",
      city: "Amherst",
      zip_code: "03031",
      state_id: "NH",
      state_name: "New Hampshire",
    },
    {
      city_id: "801",
      city: "Andover",
      zip_code: "03216",
      state_id: "NH",
      state_name: "New Hampshire",
    },
    {
      city_id: "863",
      city: "Antrim",
      zip_code: "03440",
      state_id: "NH",
      state_name: "New Hampshire",
    },
    {
      city_id: "802",
      city: "Ashland",
      zip_code: "03217",
      state_id: "NH",
      state_name: "New Hampshire",
    },
    {
      city_id: "864",
      city: "Ashuelot",
      zip_code: "03441",
      state_id: "NH",
      state_name: "New Hampshire",
    },
    {
      city_id: "942",
      city: "Atkinson",
      zip_code: "03811",
      state_id: "NH",
      state_name: "New Hampshire",
    },
    {
      city_id: "760",
      city: "Auburn",
      zip_code: "03032",
      state_id: "NH",
      state_name: "New Hampshire",
    },
    {
      city_id: "803",
      city: "Barnstead",
      zip_code: "03218",
      state_id: "NH",
      state_name: "New Hampshire",
    },
    {
      city_id: "953",
      city: "Barrington",
      zip_code: "03825",
      state_id: "NH",
      state_name: "New Hampshire",
    },
    {
      city_id: "943",
      city: "Bartlett",
      zip_code: "03812",
      state_id: "NH",
      state_name: "New Hampshire",
    },
    {
      city_id: "915",
      city: "Bath",
      zip_code: "03740",
      state_id: "NH",
      state_name: "New Hampshire",
    },
    {
      city_id: "799",
      city: "Bedford",
      zip_code: "03110",
      state_id: "NH",
      state_name: "New Hampshire",
    },
    {
      city_id: "804",
      city: "Belmont",
      zip_code: "03220",
      state_id: "NH",
      state_name: "New Hampshire",
    },
    {
      city_id: "865",
      city: "Bennington",
      zip_code: "03442",
      state_id: "NH",
      state_name: "New Hampshire",
    },
    {
      city_id: "888",
      city: "Berlin",
      zip_code: "03570",
      state_id: "NH",
      state_name: "New Hampshire",
    },
    {
      city_id: "889",
      city: "Bethlehem",
      zip_code: "03574",
      state_id: "NH",
      state_name: "New Hampshire",
    },
    {
      city_id: "860",
      city: "Bow",
      zip_code: "03304",
      state_id: "NH",
      state_name: "New Hampshire",
    },
    {
      city_id: "805",
      city: "Bradford",
      zip_code: "03221",
      state_id: "NH",
      state_name: "New Hampshire",
    },
    {
      city_id: "890",
      city: "Bretton Woods",
      zip_code: "03575",
      state_id: "NH",
      state_name: "New Hampshire",
    },
    {
      city_id: "806",
      city: "Bristol",
      zip_code: "03222",
      state_id: "NH",
      state_name: "New Hampshire",
    },
    {
      city_id: "761",
      city: "Brookline",
      zip_code: "03033",
      state_id: "NH",
      state_name: "New Hampshire",
    },
    {
      city_id: "807",
      city: "Campton",
      zip_code: "03223",
      state_id: "NH",
      state_name: "New Hampshire",
    },
    {
      city_id: "916",
      city: "Canaan",
      zip_code: "03741",
      state_id: "NH",
      state_name: "New Hampshire",
    },
    {
      city_id: "762",
      city: "Candia",
      zip_code: "03034",
      state_id: "NH",
      state_name: "New Hampshire",
    },
    {
      city_id: "808",
      city: "Canterbury",
      zip_code: "03224",
      state_id: "NH",
      state_name: "New Hampshire",
    },
    {
      city_id: "809",
      city: "Center Barnstead",
      zip_code: "03225",
      state_id: "NH",
      state_name: "New Hampshire",
    },
    {
      city_id: "944",
      city: "Center Conway",
      zip_code: "03813",
      state_id: "NH",
      state_name: "New Hampshire",
    },
    {
      city_id: "810",
      city: "Center Harbor",
      zip_code: "03226",
      state_id: "NH",
      state_name: "New Hampshire",
    },
    {
      city_id: "945",
      city: "Center Ossipee",
      zip_code: "03814",
      state_id: "NH",
      state_name: "New Hampshire",
    },
    {
      city_id: "811",
      city: "Center Sandwich",
      zip_code: "03227",
      state_id: "NH",
      state_name: "New Hampshire",
    },
    {
      city_id: "946",
      city: "Center Tuftonboro",
      zip_code: "03816",
      state_id: "NH",
      state_name: "New Hampshire",
    },
    {
      city_id: "909",
      city: "Charlestown",
      zip_code: "03603",
      state_id: "NH",
      state_name: "New Hampshire",
    },
    {
      city_id: "763",
      city: "Chester",
      zip_code: "03036",
      state_id: "NH",
      state_name: "New Hampshire",
    },
    {
      city_id: "866",
      city: "Chesterfield",
      zip_code: "03443",
      state_id: "NH",
      state_name: "New Hampshire",
    },
    {
      city_id: "834",
      city: "Chichester",
      zip_code: "03258",
      state_id: "NH",
      state_name: "New Hampshire",
    },
    {
      city_id: "947",
      city: "Chocorua",
      zip_code: "03817",
      state_id: "NH",
      state_name: "New Hampshire",
    },
    {
      city_id: "917",
      city: "Claremont",
      zip_code: "03743",
      state_id: "NH",
      state_name: "New Hampshire",
    },
    {
      city_id: "891",
      city: "Colebrook",
      zip_code: "03576",
      state_id: "NH",
      state_name: "New Hampshire",
    },
    {
      city_id: "858",
      city: "Concord",
      zip_code: "03301",
      state_id: "NH",
      state_name: "New Hampshire",
    },
    {
      city_id: "812",
      city: "Contoocook",
      zip_code: "03229",
      state_id: "NH",
      state_name: "New Hampshire",
    },
    {
      city_id: "948",
      city: "Conway",
      zip_code: "03818",
      state_id: "NH",
      state_name: "New Hampshire",
    },
    {
      city_id: "918",
      city: "Cornish",
      zip_code: "03745",
      state_id: "NH",
      state_name: "New Hampshire",
    },
    {
      city_id: "813",
      city: "Danbury",
      zip_code: "03230",
      state_id: "NH",
      state_name: "New Hampshire",
    },
    {
      city_id: "949",
      city: "Danville",
      zip_code: "03819",
      state_id: "NH",
      state_name: "New Hampshire",
    },
    {
      city_id: "764",
      city: "Deerfield",
      zip_code: "03037",
      state_id: "NH",
      state_name: "New Hampshire",
    },
    {
      city_id: "765",
      city: "Derry",
      zip_code: "03038",
      state_id: "NH",
      state_name: "New Hampshire",
    },
    {
      city_id: "950",
      city: "Dover",
      zip_code: "03820",
      state_id: "NH",
      state_name: "New Hampshire",
    },
    {
      city_id: "910",
      city: "Drewsville",
      zip_code: "03604",
      state_id: "NH",
      state_name: "New Hampshire",
    },
    {
      city_id: "867",
      city: "Dublin",
      zip_code: "03444",
      state_id: "NH",
      state_name: "New Hampshire",
    },
    {
      city_id: "770",
      city: "Dunbarton",
      zip_code: "03046",
      state_id: "NH",
      state_name: "New Hampshire",
    },
    {
      city_id: "952",
      city: "Durham",
      zip_code: "03824",
      state_id: "NH",
      state_name: "New Hampshire",
    },
    {
      city_id: "814",
      city: "East Andover",
      zip_code: "03231",
      state_id: "NH",
      state_name: "New Hampshire",
    },
    {
      city_id: "954",
      city: "East Hampstead",
      zip_code: "03826",
      state_id: "NH",
      state_name: "New Hampshire",
    },
    {
      city_id: "955",
      city: "East Kingston",
      zip_code: "03827",
      state_id: "NH",
      state_name: "New Hampshire",
    },
    {
      city_id: "956",
      city: "East Wakefield",
      zip_code: "03830",
      state_id: "NH",
      state_name: "New Hampshire",
    },
    {
      city_id: "957",
      city: "Eaton Center",
      zip_code: "03832",
      state_id: "NH",
      state_name: "New Hampshire",
    },
    {
      city_id: "997",
      city: "Effingham",
      zip_code: "03882",
      state_id: "NH",
      state_name: "New Hampshire",
    },
    {
      city_id: "815",
      city: "Elkins",
      zip_code: "03233",
      state_id: "NH",
      state_name: "New Hampshire",
    },
    {
      city_id: "919",
      city: "Enfield",
      zip_code: "03748",
      state_id: "NH",
      state_name: "New Hampshire",
    },
    {
      city_id: "766",
      city: "Epping",
      zip_code: "03042",
      state_id: "NH",
      state_name: "New Hampshire",
    },
    {
      city_id: "816",
      city: "Epsom",
      zip_code: "03234",
      state_id: "NH",
      state_name: "New Hampshire",
    },
    {
      city_id: "892",
      city: "Errol",
      zip_code: "03579",
      state_id: "NH",
      state_name: "New Hampshire",
    },
    {
      city_id: "920",
      city: "Etna",
      zip_code: "03750",
      state_id: "NH",
      state_name: "New Hampshire",
    },
    {
      city_id: "958",
      city: "Exeter",
      zip_code: "03833",
      state_id: "NH",
      state_name: "New Hampshire",
    },
    {
      city_id: "959",
      city: "Farmington",
      zip_code: "03835",
      state_id: "NH",
      state_name: "New Hampshire",
    },
    {
      city_id: "870",
      city: "Fitzwilliam",
      zip_code: "03447",
      state_id: "NH",
      state_name: "New Hampshire",
    },
    {
      city_id: "767",
      city: "Francestown",
      zip_code: "03043",
      state_id: "NH",
      state_name: "New Hampshire",
    },
    {
      city_id: "893",
      city: "Franconia",
      zip_code: "03580",
      state_id: "NH",
      state_name: "New Hampshire",
    },
    {
      city_id: "817",
      city: "Franklin",
      zip_code: "03235",
      state_id: "NH",
      state_name: "New Hampshire",
    },
    {
      city_id: "960",
      city: "Freedom",
      zip_code: "03836",
      state_id: "NH",
      state_name: "New Hampshire",
    },
    {
      city_id: "768",
      city: "Fremont",
      zip_code: "03044",
      state_id: "NH",
      state_name: "New Hampshire",
    },
    {
      city_id: "921",
      city: "Georges Mills",
      zip_code: "03751",
      state_id: "NH",
      state_name: "New Hampshire",
    },
    {
      city_id: "827",
      city: "Gilford",
      zip_code: "03249",
      state_id: "NH",
      state_name: "New Hampshire",
    },
    {
      city_id: "818",
      city: "Gilmanton",
      zip_code: "03237",
      state_id: "NH",
      state_name: "New Hampshire",
    },
    {
      city_id: "961",
      city: "Gilmanton Iron Works",
      zip_code: "03837",
      state_id: "NH",
      state_name: "New Hampshire",
    },
    {
      city_id: "871",
      city: "Gilsum",
      zip_code: "03448",
      state_id: "NH",
      state_name: "New Hampshire",
    },
    {
      city_id: "962",
      city: "Glen",
      zip_code: "03838",
      state_id: "NH",
      state_name: "New Hampshire",
    },
    {
      city_id: "819",
      city: "Glencliff",
      zip_code: "03238",
      state_id: "NH",
      state_name: "New Hampshire",
    },
    {
      city_id: "769",
      city: "Goffstown",
      zip_code: "03045",
      state_id: "NH",
      state_name: "New Hampshire",
    },
    {
      city_id: "894",
      city: "Gorham",
      zip_code: "03581",
      state_id: "NH",
      state_name: "New Hampshire",
    },
    {
      city_id: "922",
      city: "Goshen",
      zip_code: "03752",
      state_id: "NH",
      state_name: "New Hampshire",
    },
    {
      city_id: "820",
      city: "Grafton",
      zip_code: "03240",
      state_id: "NH",
      state_name: "New Hampshire",
    },
    {
      city_id: "923",
      city: "Grantham",
      zip_code: "03753",
      state_id: "NH",
      state_name: "New Hampshire",
    },
    {
      city_id: "771",
      city: "Greenfield",
      zip_code: "03047",
      state_id: "NH",
      state_name: "New Hampshire",
    },
    {
      city_id: "964",
      city: "Greenland",
      zip_code: "03840",
      state_id: "NH",
      state_name: "New Hampshire",
    },
    {
      city_id: "772",
      city: "Greenville",
      zip_code: "03048",
      state_id: "NH",
      state_name: "New Hampshire",
    },
    {
      city_id: "895",
      city: "Groveton",
      zip_code: "03582",
      state_id: "NH",
      state_name: "New Hampshire",
    },
    {
      city_id: "965",
      city: "Hampstead",
      zip_code: "03841",
      state_id: "NH",
      state_name: "New Hampshire",
    },
    {
      city_id: "966",
      city: "Hampton",
      zip_code: "03842",
      state_id: "NH",
      state_name: "New Hampshire",
    },
    {
      city_id: "967",
      city: "Hampton Falls",
      zip_code: "03844",
      state_id: "NH",
      state_name: "New Hampshire",
    },
    {
      city_id: "872",
      city: "Hancock",
      zip_code: "03449",
      state_id: "NH",
      state_name: "New Hampshire",
    },
    {
      city_id: "924",
      city: "Hanover",
      zip_code: "03755",
      state_id: "NH",
      state_name: "New Hampshire",
    },
    {
      city_id: "873",
      city: "Harrisville",
      zip_code: "03450",
      state_id: "NH",
      state_name: "New Hampshire",
    },
    {
      city_id: "925",
      city: "Haverhill",
      zip_code: "03765",
      state_id: "NH",
      state_name: "New Hampshire",
    },
    {
      city_id: "821",
      city: "Hebron",
      zip_code: "03241",
      state_id: "NH",
      state_name: "New Hampshire",
    },
    {
      city_id: "822",
      city: "Henniker",
      zip_code: "03242",
      state_id: "NH",
      state_name: "New Hampshire",
    },
    {
      city_id: "823",
      city: "Hill",
      zip_code: "03243",
      state_id: "NH",
      state_name: "New Hampshire",
    },
    {
      city_id: "824",
      city: "Hillsborough",
      zip_code: "03244",
      state_id: "NH",
      state_name: "New Hampshire",
    },
    {
      city_id: "874",
      city: "Hinsdale",
      zip_code: "03451",
      state_id: "NH",
      state_name: "New Hampshire",
    },
    {
      city_id: "825",
      city: "Holderness",
      zip_code: "03245",
      state_id: "NH",
      state_name: "New Hampshire",
    },
    {
      city_id: "773",
      city: "Hollis",
      zip_code: "03049",
      state_id: "NH",
      state_name: "New Hampshire",
    },
    {
      city_id: "797",
      city: "Hooksett",
      zip_code: "03106",
      state_id: "NH",
      state_name: "New Hampshire",
    },
    {
      city_id: "774",
      city: "Hudson",
      zip_code: "03051",
      state_id: "NH",
      state_name: "New Hampshire",
    },
    {
      city_id: "968",
      city: "Intervale",
      zip_code: "03845",
      state_id: "NH",
      state_name: "New Hampshire",
    },
    {
      city_id: "969",
      city: "Jackson",
      zip_code: "03846",
      state_id: "NH",
      state_name: "New Hampshire",
    },
    {
      city_id: "875",
      city: "Jaffrey",
      zip_code: "03452",
      state_id: "NH",
      state_name: "New Hampshire",
    },
    {
      city_id: "896",
      city: "Jefferson",
      zip_code: "03583",
      state_id: "NH",
      state_name: "New Hampshire",
    },
    {
      city_id: "970",
      city: "Kearsarge",
      zip_code: "03847",
      state_id: "NH",
      state_name: "New Hampshire",
    },
    {
      city_id: "862",
      city: "Keene",
      zip_code: "03431",
      state_id: "NH",
      state_name: "New Hampshire",
    },
    {
      city_id: "971",
      city: "Kingston",
      zip_code: "03848",
      state_id: "NH",
      state_name: "New Hampshire",
    },
    {
      city_id: "826",
      city: "Laconia",
      zip_code: "03246",
      state_id: "NH",
      state_name: "New Hampshire",
    },
    {
      city_id: "897",
      city: "Lancaster",
      zip_code: "03584",
      state_id: "NH",
      state_name: "New Hampshire",
    },
    {
      city_id: "926",
      city: "Lebanon",
      zip_code: "03766",
      state_id: "NH",
      state_name: "New Hampshire",
    },
    {
      city_id: "983",
      city: "Lee",
      zip_code: "03861",
      state_id: "NH",
      state_name: "New Hampshire",
    },
    {
      city_id: "911",
      city: "Lempster",
      zip_code: "03605",
      state_id: "NH",
      state_name: "New Hampshire",
    },
    {
      city_id: "828",
      city: "Lincoln",
      zip_code: "03251",
      state_id: "NH",
      state_name: "New Hampshire",
    },
    {
      city_id: "898",
      city: "Lisbon",
      zip_code: "03585",
      state_id: "NH",
      state_name: "New Hampshire",
    },
    {
      city_id: "775",
      city: "Litchfield",
      zip_code: "03052",
      state_id: "NH",
      state_name: "New Hampshire",
    },
    {
      city_id: "887",
      city: "Littleton",
      zip_code: "03561",
      state_id: "NH",
      state_name: "New Hampshire",
    },
    {
      city_id: "776",
      city: "Londonderry",
      zip_code: "03053",
      state_id: "NH",
      state_name: "New Hampshire",
    },
    {
      city_id: "861",
      city: "Loudon",
      zip_code: "03307",
      state_id: "NH",
      state_name: "New Hampshire",
    },
    {
      city_id: "927",
      city: "Lyme",
      zip_code: "03768",
      state_id: "NH",
      state_name: "New Hampshire",
    },
    {
      city_id: "789",
      city: "Lyndeborough",
      zip_code: "03082",
      state_id: "NH",
      state_name: "New Hampshire",
    },
    {
      city_id: "951",
      city: "Madbury",
      zip_code: "03823",
      state_id: "NH",
      state_name: "New Hampshire",
    },
    {
      city_id: "972",
      city: "Madison",
      zip_code: "03849",
      state_id: "NH",
      state_name: "New Hampshire",
    },
    {
      city_id: "793",
      city: "Manchester",
      zip_code: "03101",
      state_id: "NH",
      state_name: "New Hampshire",
    },
    {
      city_id: "876",
      city: "Marlborough",
      zip_code: "03455",
      state_id: "NH",
      state_name: "New Hampshire",
    },
    {
      city_id: "877",
      city: "Marlow",
      zip_code: "03456",
      state_id: "NH",
      state_name: "New Hampshire",
    },
    {
      city_id: "973",
      city: "Melvin Village",
      zip_code: "03850",
      state_id: "NH",
      state_name: "New Hampshire",
    },
    {
      city_id: "829",
      city: "Meredith",
      zip_code: "03253",
      state_id: "NH",
      state_name: "New Hampshire",
    },
    {
      city_id: "928",
      city: "Meriden",
      zip_code: "03770",
      state_id: "NH",
      state_name: "New Hampshire",
    },
    {
      city_id: "777",
      city: "Merrimack",
      zip_code: "03054",
      state_id: "NH",
      state_name: "New Hampshire",
    },
    {
      city_id: "900",
      city: "Milan",
      zip_code: "03588",
      state_id: "NH",
      state_name: "New Hampshire",
    },
    {
      city_id: "778",
      city: "Milford",
      zip_code: "03055",
      state_id: "NH",
      state_name: "New Hampshire",
    },
    {
      city_id: "974",
      city: "Milton",
      zip_code: "03851",
      state_id: "NH",
      state_name: "New Hampshire",
    },
    {
      city_id: "975",
      city: "Milton Mills",
      zip_code: "03852",
      state_id: "NH",
      state_name: "New Hampshire",
    },
    {
      city_id: "976",
      city: "Mirror Lake",
      zip_code: "03853",
      state_id: "NH",
      state_name: "New Hampshire",
    },
    {
      city_id: "929",
      city: "Monroe",
      zip_code: "03771",
      state_id: "NH",
      state_name: "New Hampshire",
    },
    {
      city_id: "779",
      city: "Mont Vernon",
      zip_code: "03057",
      state_id: "NH",
      state_name: "New Hampshire",
    },
    {
      city_id: "830",
      city: "Moultonborough",
      zip_code: "03254",
      state_id: "NH",
      state_name: "New Hampshire",
    },
    {
      city_id: "780",
      city: "Nashua",
      zip_code: "03060",
      state_id: "NH",
      state_name: "New Hampshire",
    },
    {
      city_id: "878",
      city: "Nelson",
      zip_code: "03457",
      state_id: "NH",
      state_name: "New Hampshire",
    },
    {
      city_id: "784",
      city: "New Boston",
      zip_code: "03070",
      state_id: "NH",
      state_name: "New Hampshire",
    },
    {
      city_id: "977",
      city: "New Castle",
      zip_code: "03854",
      state_id: "NH",
      state_name: "New Hampshire",
    },
    {
      city_id: "978",
      city: "New Durham",
      zip_code: "03855",
      state_id: "NH",
      state_name: "New Hampshire",
    },
    {
      city_id: "832",
      city: "New Hampton",
      zip_code: "03256",
      state_id: "NH",
      state_name: "New Hampshire",
    },
    {
      city_id: "785",
      city: "New Ipswich",
      zip_code: "03071",
      state_id: "NH",
      state_name: "New Hampshire",
    },
    {
      city_id: "833",
      city: "New London",
      zip_code: "03257",
      state_id: "NH",
      state_name: "New Hampshire",
    },
    {
      city_id: "831",
      city: "Newbury",
      zip_code: "03255",
      state_id: "NH",
      state_name: "New Hampshire",
    },
    {
      city_id: "979",
      city: "Newfields",
      zip_code: "03856",
      state_id: "NH",
      state_name: "New Hampshire",
    },
    {
      city_id: "980",
      city: "Newmarket",
      zip_code: "03857",
      state_id: "NH",
      state_name: "New Hampshire",
    },
    {
      city_id: "930",
      city: "Newport",
      zip_code: "03773",
      state_id: "NH",
      state_name: "New Hampshire",
    },
    {
      city_id: "981",
      city: "Newton",
      zip_code: "03858",
      state_id: "NH",
      state_name: "New Hampshire",
    },
    {
      city_id: "982",
      city: "North Conway",
      zip_code: "03860",
      state_id: "NH",
      state_name: "New Hampshire",
    },
    {
      city_id: "984",
      city: "North Hampton",
      zip_code: "03862",
      state_id: "NH",
      state_name: "New Hampshire",
    },
    {
      city_id: "931",
      city: "North Haverhill",
      zip_code: "03774",
      state_id: "NH",
      state_name: "New Hampshire",
    },
    {
      city_id: "835",
      city: "North Sandwich",
      zip_code: "03259",
      state_id: "NH",
      state_name: "New Hampshire",
    },
    {
      city_id: "901",
      city: "North Stratford",
      zip_code: "03590",
      state_id: "NH",
      state_name: "New Hampshire",
    },
    {
      city_id: "836",
      city: "North Sutton",
      zip_code: "03260",
      state_id: "NH",
      state_name: "New Hampshire",
    },
    {
      city_id: "914",
      city: "North Walpole",
      zip_code: "03609",
      state_id: "NH",
      state_name: "New Hampshire",
    },
    {
      city_id: "838",
      city: "North Woodstock",
      zip_code: "03262",
      state_id: "NH",
      state_name: "New Hampshire",
    },
    {
      city_id: "837",
      city: "Northwood",
      zip_code: "03261",
      state_id: "NH",
      state_name: "New Hampshire",
    },
    {
      city_id: "855",
      city: "Nottingham",
      zip_code: "03290",
      state_id: "NH",
      state_name: "New Hampshire",
    },
    {
      city_id: "932",
      city: "Orford",
      zip_code: "03777",
      state_id: "NH",
      state_name: "New Hampshire",
    },
    {
      city_id: "985",
      city: "Ossipee",
      zip_code: "03864",
      state_id: "NH",
      state_name: "New Hampshire",
    },
    {
      city_id: "786",
      city: "Pelham",
      zip_code: "03076",
      state_id: "NH",
      state_name: "New Hampshire",
    },
    {
      city_id: "879",
      city: "Peterborough",
      zip_code: "03458",
      state_id: "NH",
      state_name: "New Hampshire",
    },
    {
      city_id: "933",
      city: "Piermont",
      zip_code: "03779",
      state_id: "NH",
      state_name: "New Hampshire",
    },
    {
      city_id: "934",
      city: "Pike",
      zip_code: "03780",
      state_id: "NH",
      state_name: "New Hampshire",
    },
    {
      city_id: "902",
      city: "Pittsburg",
      zip_code: "03592",
      state_id: "NH",
      state_name: "New Hampshire",
    },
    {
      city_id: "839",
      city: "Pittsfield",
      zip_code: "03263",
      state_id: "NH",
      state_name: "New Hampshire",
    },
    {
      city_id: "935",
      city: "Plainfield",
      zip_code: "03781",
      state_id: "NH",
      state_name: "New Hampshire",
    },
    {
      city_id: "986",
      city: "Plaistow",
      zip_code: "03865",
      state_id: "NH",
      state_name: "New Hampshire",
    },
    {
      city_id: "840",
      city: "Plymouth",
      zip_code: "03264",
      state_id: "NH",
      state_name: "New Hampshire",
    },
    {
      city_id: "939",
      city: "Portsmouth",
      zip_code: "03801",
      state_id: "NH",
      state_name: "New Hampshire",
    },
    {
      city_id: "903",
      city: "Randolph",
      zip_code: "03593",
      state_id: "NH",
      state_name: "New Hampshire",
    },
    {
      city_id: "787",
      city: "Raymond",
      zip_code: "03077",
      state_id: "NH",
      state_name: "New Hampshire",
    },
    {
      city_id: "880",
      city: "Rindge",
      zip_code: "03461",
      state_id: "NH",
      state_name: "New Hampshire",
    },
    {
      city_id: "963",
      city: "Rochester",
      zip_code: "03839",
      state_id: "NH",
      state_name: "New Hampshire",
    },
    {
      city_id: "989",
      city: "Rollinsford",
      zip_code: "03869",
      state_id: "NH",
      state_name: "New Hampshire",
    },
    {
      city_id: "841",
      city: "Rumney",
      zip_code: "03266",
      state_id: "NH",
      state_name: "New Hampshire",
    },
    {
      city_id: "990",
      city: "Rye",
      zip_code: "03870",
      state_id: "NH",
      state_name: "New Hampshire",
    },
    {
      city_id: "991",
      city: "Rye Beach",
      zip_code: "03871",
      state_id: "NH",
      state_name: "New Hampshire",
    },
    {
      city_id: "788",
      city: "Salem",
      zip_code: "03079",
      state_id: "NH",
      state_name: "New Hampshire",
    },
    {
      city_id: "842",
      city: "Salisbury",
      zip_code: "03268",
      state_id: "NH",
      state_name: "New Hampshire",
    },
    {
      city_id: "843",
      city: "Sanbornton",
      zip_code: "03269",
      state_id: "NH",
      state_name: "New Hampshire",
    },
    {
      city_id: "992",
      city: "Sanbornville",
      zip_code: "03872",
      state_id: "NH",
      state_name: "New Hampshire",
    },
    {
      city_id: "993",
      city: "Sandown",
      zip_code: "03873",
      state_id: "NH",
      state_name: "New Hampshire",
    },
    {
      city_id: "994",
      city: "Seabrook",
      zip_code: "03874",
      state_id: "NH",
      state_name: "New Hampshire",
    },
    {
      city_id: "995",
      city: "Silver Lake",
      zip_code: "03875",
      state_id: "NH",
      state_name: "New Hampshire",
    },
    {
      city_id: "996",
      city: "Somersworth",
      zip_code: "03878",
      state_id: "NH",
      state_name: "New Hampshire",
    },
    {
      city_id: "912",
      city: "South Acworth",
      zip_code: "03607",
      state_id: "NH",
      state_name: "New Hampshire",
    },
    {
      city_id: "844",
      city: "South Sutton",
      zip_code: "03273",
      state_id: "NH",
      state_name: "New Hampshire",
    },
    {
      city_id: "998",
      city: "South Tamworth",
      zip_code: "03883",
      state_id: "NH",
      state_name: "New Hampshire",
    },
    {
      city_id: "881",
      city: "Spofford",
      zip_code: "03462",
      state_id: "NH",
      state_name: "New Hampshire",
    },
    {
      city_id: "852",
      city: "Springfield",
      zip_code: "03284",
      state_id: "NH",
      state_name: "New Hampshire",
    },
    {
      city_id: "882",
      city: "Stoddard",
      zip_code: "03464",
      state_id: "NH",
      state_name: "New Hampshire",
    },
    {
      city_id: "999",
      city: "Strafford",
      zip_code: "03884",
      state_id: "NH",
      state_name: "New Hampshire",
    },
    {
      city_id: "1000",
      city: "Stratham",
      zip_code: "03885",
      state_id: "NH",
      state_name: "New Hampshire",
    },
    {
      city_id: "899",
      city: "Sugar Hill",
      zip_code: "03586",
      state_id: "NH",
      state_name: "New Hampshire",
    },
    {
      city_id: "868",
      city: "Sullivan",
      zip_code: "03445",
      state_id: "NH",
      state_name: "New Hampshire",
    },
    {
      city_id: "936",
      city: "Sunapee",
      zip_code: "03782",
      state_id: "NH",
      state_name: "New Hampshire",
    },
    {
      city_id: "845",
      city: "Suncook",
      zip_code: "03275",
      state_id: "NH",
      state_name: "New Hampshire",
    },
    {
      city_id: "869",
      city: "Swanzey",
      zip_code: "03446",
      state_id: "NH",
      state_name: "New Hampshire",
    },
    {
      city_id: "1001",
      city: "Tamworth",
      zip_code: "03886",
      state_id: "NH",
      state_name: "New Hampshire",
    },
    {
      city_id: "790",
      city: "Temple",
      zip_code: "03084",
      state_id: "NH",
      state_name: "New Hampshire",
    },
    {
      city_id: "853",
      city: "Thornton",
      zip_code: "03285",
      state_id: "NH",
      state_name: "New Hampshire",
    },
    {
      city_id: "846",
      city: "Tilton",
      zip_code: "03276",
      state_id: "NH",
      state_name: "New Hampshire",
    },
    {
      city_id: "883",
      city: "Troy",
      zip_code: "03465",
      state_id: "NH",
      state_name: "New Hampshire",
    },
    {
      city_id: "904",
      city: "Twin Mountain",
      zip_code: "03595",
      state_id: "NH",
      state_name: "New Hampshire",
    },
    {
      city_id: "1002",
      city: "Union",
      zip_code: "03887",
      state_id: "NH",
      state_name: "New Hampshire",
    },
    {
      city_id: "913",
      city: "Walpole",
      zip_code: "03608",
      state_id: "NH",
      state_name: "New Hampshire",
    },
    {
      city_id: "847",
      city: "Warner",
      zip_code: "03278",
      state_id: "NH",
      state_name: "New Hampshire",
    },
    {
      city_id: "848",
      city: "Warren",
      zip_code: "03279",
      state_id: "NH",
      state_name: "New Hampshire",
    },
    {
      city_id: "849",
      city: "Washington",
      zip_code: "03280",
      state_id: "NH",
      state_name: "New Hampshire",
    },
    {
      city_id: "800",
      city: "Waterville Valley",
      zip_code: "03215",
      state_id: "NH",
      state_name: "New Hampshire",
    },
    {
      city_id: "850",
      city: "Weare",
      zip_code: "03281",
      state_id: "NH",
      state_name: "New Hampshire",
    },
    {
      city_id: "851",
      city: "Wentworth",
      zip_code: "03282",
      state_id: "NH",
      state_name: "New Hampshire",
    },
    {
      city_id: "884",
      city: "West Chesterfield",
      zip_code: "03466",
      state_id: "NH",
      state_name: "New Hampshire",
    },
    {
      city_id: "937",
      city: "West Lebanon",
      zip_code: "03784",
      state_id: "NH",
      state_name: "New Hampshire",
    },
    {
      city_id: "856",
      city: "West Nottingham",
      zip_code: "03291",
      state_id: "NH",
      state_name: "New Hampshire",
    },
    {
      city_id: "1003",
      city: "West Ossipee",
      zip_code: "03890",
      state_id: "NH",
      state_name: "New Hampshire",
    },
    {
      city_id: "905",
      city: "West Stewartstown",
      zip_code: "03597",
      state_id: "NH",
      state_name: "New Hampshire",
    },
    {
      city_id: "885",
      city: "Westmoreland",
      zip_code: "03467",
      state_id: "NH",
      state_name: "New Hampshire",
    },
    {
      city_id: "906",
      city: "Whitefield",
      zip_code: "03598",
      state_id: "NH",
      state_name: "New Hampshire",
    },
    {
      city_id: "854",
      city: "Wilmot",
      zip_code: "03287",
      state_id: "NH",
      state_name: "New Hampshire",
    },
    {
      city_id: "791",
      city: "Wilton",
      zip_code: "03086",
      state_id: "NH",
      state_name: "New Hampshire",
    },
    {
      city_id: "886",
      city: "Winchester",
      zip_code: "03470",
      state_id: "NH",
      state_name: "New Hampshire",
    },
    {
      city_id: "792",
      city: "Windham",
      zip_code: "03087",
      state_id: "NH",
      state_name: "New Hampshire",
    },
    {
      city_id: "1004",
      city: "Wolfeboro",
      zip_code: "03894",
      state_id: "NH",
      state_name: "New Hampshire",
    },
    {
      city_id: "1005",
      city: "Wonalancet",
      zip_code: "03897",
      state_id: "NH",
      state_name: "New Hampshire",
    },
    {
      city_id: "857",
      city: "Woodstock",
      zip_code: "03293",
      state_id: "NH",
      state_name: "New Hampshire",
    },
    {
      city_id: "938",
      city: "Woodsville",
      zip_code: "03785",
      state_id: "NH",
      state_name: "New Hampshire",
    },
    {
      city_id: "2390",
      city: "Absecon",
      zip_code: "08201",
      state_id: "NJ",
      state_name: "New Jersey",
    },
    {
      city_id: "2222",
      city: "Allamuchy",
      zip_code: "07820",
      state_id: "NJ",
      state_name: "New Jersey",
    },
    {
      city_id: "2091",
      city: "Allendale",
      zip_code: "07401",
      state_id: "NJ",
      state_name: "New Jersey",
    },
    {
      city_id: "2183",
      city: "Allenhurst",
      zip_code: "07711",
      state_id: "NJ",
      state_name: "New Jersey",
    },
    {
      city_id: "2456",
      city: "Allentown",
      zip_code: "08501",
      state_id: "NJ",
      state_name: "New Jersey",
    },
    {
      city_id: "2503",
      city: "Allenwood",
      zip_code: "08720",
      state_id: "NJ",
      state_name: "New Jersey",
    },
    {
      city_id: "2294",
      city: "Alloway",
      zip_code: "08001",
      state_id: "NJ",
      state_name: "New Jersey",
    },
    {
      city_id: "2147",
      city: "Alpine",
      zip_code: "07620",
      state_id: "NJ",
      state_name: "New Jersey",
    },
    {
      city_id: "2223",
      city: "Andover",
      zip_code: "07821",
      state_id: "NJ",
      state_name: "New Jersey",
    },
    {
      city_id: "2527",
      city: "Annandale",
      zip_code: "08801",
      state_id: "NJ",
      state_name: "New Jersey",
    },
    {
      city_id: "2528",
      city: "Asbury",
      zip_code: "08802",
      state_id: "NJ",
      state_name: "New Jersey",
    },
    {
      city_id: "2184",
      city: "Asbury Park",
      zip_code: "07712",
      state_id: "NJ",
      state_name: "New Jersey",
    },
    {
      city_id: "2297",
      city: "Atco",
      zip_code: "08004",
      state_id: "NJ",
      state_name: "New Jersey",
    },
    {
      city_id: "2452",
      city: "Atlantic City",
      zip_code: "08401",
      state_id: "NJ",
      state_name: "New Jersey",
    },
    {
      city_id: "2185",
      city: "Atlantic Highlands",
      zip_code: "07716",
      state_id: "NJ",
      state_name: "New Jersey",
    },
    {
      city_id: "2385",
      city: "Audubon",
      zip_code: "08106",
      state_id: "NJ",
      state_name: "New Jersey",
    },
    {
      city_id: "2224",
      city: "Augusta",
      zip_code: "07822",
      state_id: "NJ",
      state_name: "New Jersey",
    },
    {
      city_id: "2391",
      city: "Avalon",
      zip_code: "08202",
      state_id: "NJ",
      state_name: "New Jersey",
    },
    {
      city_id: "1986",
      city: "Avenel",
      zip_code: "07001",
      state_id: "NJ",
      state_name: "New Jersey",
    },
    {
      city_id: "2186",
      city: "Avon By The Sea",
      zip_code: "07717",
      state_id: "NJ",
      state_name: "New Jersey",
    },
    {
      city_id: "2298",
      city: "Barnegat",
      zip_code: "08005",
      state_id: "NJ",
      state_name: "New Jersey",
    },
    {
      city_id: "2299",
      city: "Barnegat Light",
      zip_code: "08006",
      state_id: "NJ",
      state_name: "New Jersey",
    },
    {
      city_id: "2300",
      city: "Barrington",
      zip_code: "08007",
      state_id: "NJ",
      state_name: "New Jersey",
    },
    {
      city_id: "2266",
      city: "Basking Ridge",
      zip_code: "07920",
      state_id: "NJ",
      state_name: "New Jersey",
    },
    {
      city_id: "1987",
      city: "Bayonne",
      zip_code: "07002",
      state_id: "NJ",
      state_name: "New Jersey",
    },
    {
      city_id: "2504",
      city: "Bayville",
      zip_code: "08721",
      state_id: "NJ",
      state_name: "New Jersey",
    },
    {
      city_id: "2301",
      city: "Beach Haven",
      zip_code: "08008",
      state_id: "NJ",
      state_name: "New Jersey",
    },
    {
      city_id: "2505",
      city: "Beachwood",
      zip_code: "08722",
      state_id: "NJ",
      state_name: "New Jersey",
    },
    {
      city_id: "2267",
      city: "Bedminster",
      zip_code: "07921",
      state_id: "NJ",
      state_name: "New Jersey",
    },
    {
      city_id: "2187",
      city: "Belford",
      zip_code: "07718",
      state_id: "NJ",
      state_name: "New Jersey",
    },
    {
      city_id: "2457",
      city: "Belle Mead",
      zip_code: "08502",
      state_id: "NJ",
      state_name: "New Jersey",
    },
    {
      city_id: "2072",
      city: "Belleville",
      zip_code: "07109",
      state_id: "NJ",
      state_name: "New Jersey",
    },
    {
      city_id: "2319",
      city: "Bellmawr",
      zip_code: "08031",
      state_id: "NJ",
      state_name: "New Jersey",
    },
    {
      city_id: "2188",
      city: "Belmar",
      zip_code: "07719",
      state_id: "NJ",
      state_name: "New Jersey",
    },
    {
      city_id: "2225",
      city: "Belvidere",
      zip_code: "07823",
      state_id: "NJ",
      state_name: "New Jersey",
    },
    {
      city_id: "2148",
      city: "Bergenfield",
      zip_code: "07621",
      state_id: "NJ",
      state_name: "New Jersey",
    },
    {
      city_id: "2268",
      city: "Berkeley Heights",
      zip_code: "07922",
      state_id: "NJ",
      state_name: "New Jersey",
    },
    {
      city_id: "2302",
      city: "Berlin",
      zip_code: "08009",
      state_id: "NJ",
      state_name: "New Jersey",
    },
    {
      city_id: "2269",
      city: "Bernardsville",
      zip_code: "07924",
      state_id: "NJ",
      state_name: "New Jersey",
    },
    {
      city_id: "2303",
      city: "Beverly",
      zip_code: "08010",
      state_id: "NJ",
      state_name: "New Jersey",
    },
    {
      city_id: "2304",
      city: "Birmingham",
      zip_code: "08011",
      state_id: "NJ",
      state_name: "New Jersey",
    },
    {
      city_id: "2305",
      city: "Blackwood",
      zip_code: "08012",
      state_id: "NJ",
      state_name: "New Jersey",
    },
    {
      city_id: "2226",
      city: "Blairstown",
      zip_code: "07825",
      state_id: "NJ",
      state_name: "New Jersey",
    },
    {
      city_id: "1988",
      city: "Bloomfield",
      zip_code: "07003",
      state_id: "NJ",
      state_name: "New Jersey",
    },
    {
      city_id: "2092",
      city: "Bloomingdale",
      zip_code: "07403",
      state_id: "NJ",
      state_name: "New Jersey",
    },
    {
      city_id: "2529",
      city: "Bloomsbury",
      zip_code: "08804",
      state_id: "NJ",
      state_name: "New Jersey",
    },
    {
      city_id: "2141",
      city: "Bogota",
      zip_code: "07603",
      state_id: "NJ",
      state_name: "New Jersey",
    },
    {
      city_id: "1990",
      city: "Boonton",
      zip_code: "07005",
      state_id: "NJ",
      state_name: "New Jersey",
    },
    {
      city_id: "2458",
      city: "Bordentown",
      zip_code: "08505",
      state_id: "NJ",
      state_name: "New Jersey",
    },
    {
      city_id: "2530",
      city: "Bound Brook",
      zip_code: "08805",
      state_id: "NJ",
      state_name: "New Jersey",
    },
    {
      city_id: "2189",
      city: "Bradley Beach",
      zip_code: "07720",
      state_id: "NJ",
      state_name: "New Jersey",
    },
    {
      city_id: "2227",
      city: "Branchville",
      zip_code: "07826",
      state_id: "NJ",
      state_name: "New Jersey",
    },
    {
      city_id: "2506",
      city: "Brick",
      zip_code: "08723",
      state_id: "NJ",
      state_name: "New Jersey",
    },
    {
      city_id: "2306",
      city: "Bridgeport",
      zip_code: "08014",
      state_id: "NJ",
      state_name: "New Jersey",
    },
    {
      city_id: "2419",
      city: "Bridgeton",
      zip_code: "08302",
      state_id: "NJ",
      state_name: "New Jersey",
    },
    {
      city_id: "2531",
      city: "Bridgewater",
      zip_code: "08807",
      state_id: "NJ",
      state_name: "New Jersey",
    },
    {
      city_id: "2508",
      city: "Brielle",
      zip_code: "08730",
      state_id: "NJ",
      state_name: "New Jersey",
    },
    {
      city_id: "2392",
      city: "Brigantine",
      zip_code: "08203",
      state_id: "NJ",
      state_name: "New Jersey",
    },
    {
      city_id: "2270",
      city: "Brookside",
      zip_code: "07926",
      state_id: "NJ",
      state_name: "New Jersey",
    },
    {
      city_id: "2307",
      city: "Browns Mills",
      zip_code: "08015",
      state_id: "NJ",
      state_name: "New Jersey",
    },
    {
      city_id: "2229",
      city: "Budd Lake",
      zip_code: "07828",
      state_id: "NJ",
      state_name: "New Jersey",
    },
    {
      city_id: "2420",
      city: "Buena",
      zip_code: "08310",
      state_id: "NJ",
      state_name: "New Jersey",
    },
    {
      city_id: "2308",
      city: "Burlington",
      zip_code: "08016",
      state_id: "NJ",
      state_name: "New Jersey",
    },
    {
      city_id: "2093",
      city: "Butler",
      zip_code: "07405",
      state_id: "NJ",
      state_name: "New Jersey",
    },
    {
      city_id: "1991",
      city: "Caldwell",
      zip_code: "07006",
      state_id: "NJ",
      state_name: "New Jersey",
    },
    {
      city_id: "2230",
      city: "Califon",
      zip_code: "07830",
      state_id: "NJ",
      state_name: "New Jersey",
    },
    {
      city_id: "2381",
      city: "Camden",
      zip_code: "08102",
      state_id: "NJ",
      state_name: "New Jersey",
    },
    {
      city_id: "2393",
      city: "Cape May",
      zip_code: "08204",
      state_id: "NJ",
      state_name: "New Jersey",
    },
    {
      city_id: "2395",
      city: "Cape May Court House",
      zip_code: "08210",
      state_id: "NJ",
      state_name: "New Jersey",
    },
    {
      city_id: "2396",
      city: "Cape May Point",
      zip_code: "08212",
      state_id: "NJ",
      state_name: "New Jersey",
    },
    {
      city_id: "2045",
      city: "Carlstadt",
      zip_code: "07072",
      state_id: "NJ",
      state_name: "New Jersey",
    },
    {
      city_id: "1992",
      city: "Carteret",
      zip_code: "07008",
      state_id: "NJ",
      state_name: "New Jersey",
    },
    {
      city_id: "1993",
      city: "Cedar Grove",
      zip_code: "07009",
      state_id: "NJ",
      state_name: "New Jersey",
    },
    {
      city_id: "2271",
      city: "Cedar Knolls",
      zip_code: "07927",
      state_id: "NJ",
      state_name: "New Jersey",
    },
    {
      city_id: "2421",
      city: "Cedarville",
      zip_code: "08311",
      state_id: "NJ",
      state_name: "New Jersey",
    },
    {
      city_id: "2231",
      city: "Changewater",
      zip_code: "07831",
      state_id: "NJ",
      state_name: "New Jersey",
    },
    {
      city_id: "2272",
      city: "Chatham",
      zip_code: "07928",
      state_id: "NJ",
      state_name: "New Jersey",
    },
    {
      city_id: "2309",
      city: "Chatsworth",
      zip_code: "08019",
      state_id: "NJ",
      state_name: "New Jersey",
    },
    {
      city_id: "2295",
      city: "Cherry Hill",
      zip_code: "08002",
      state_id: "NJ",
      state_name: "New Jersey",
    },
    {
      city_id: "2273",
      city: "Chester",
      zip_code: "07930",
      state_id: "NJ",
      state_name: "New Jersey",
    },
    {
      city_id: "2463",
      city: "Chesterfield",
      zip_code: "08515",
      state_id: "NJ",
      state_name: "New Jersey",
    },
    {
      city_id: "2039",
      city: "Clark",
      zip_code: "07066",
      state_id: "NJ",
      state_name: "New Jersey",
    },
    {
      city_id: "2310",
      city: "Clarksboro",
      zip_code: "08020",
      state_id: "NJ",
      state_name: "New Jersey",
    },
    {
      city_id: "2422",
      city: "Clayton",
      zip_code: "08312",
      state_id: "NJ",
      state_name: "New Jersey",
    },
    {
      city_id: "2311",
      city: "Clementon",
      zip_code: "08021",
      state_id: "NJ",
      state_name: "New Jersey",
    },
    {
      city_id: "1994",
      city: "Cliffside Park",
      zip_code: "07010",
      state_id: "NJ",
      state_name: "New Jersey",
    },
    {
      city_id: "2190",
      city: "Cliffwood",
      zip_code: "07721",
      state_id: "NJ",
      state_name: "New Jersey",
    },
    {
      city_id: "1995",
      city: "Clifton",
      zip_code: "07011",
      state_id: "NJ",
      state_name: "New Jersey",
    },
    {
      city_id: "2532",
      city: "Clinton",
      zip_code: "08809",
      state_id: "NJ",
      state_name: "New Jersey",
    },
    {
      city_id: "2149",
      city: "Closter",
      zip_code: "07624",
      state_id: "NJ",
      state_name: "New Jersey",
    },
    {
      city_id: "2387",
      city: "Collingswood",
      zip_code: "08108",
      state_id: "NJ",
      state_name: "New Jersey",
    },
    {
      city_id: "2040",
      city: "Colonia",
      zip_code: "07067",
      state_id: "NJ",
      state_name: "New Jersey",
    },
    {
      city_id: "2191",
      city: "Colts Neck",
      zip_code: "07722",
      state_id: "NJ",
      state_name: "New Jersey",
    },
    {
      city_id: "2232",
      city: "Columbia",
      zip_code: "07832",
      state_id: "NJ",
      state_name: "New Jersey",
    },
    {
      city_id: "2312",
      city: "Columbus",
      zip_code: "08022",
      state_id: "NJ",
      state_name: "New Jersey",
    },
    {
      city_id: "2286",
      city: "Convent Station",
      zip_code: "07961",
      state_id: "NJ",
      state_name: "New Jersey",
    },
    {
      city_id: "2460",
      city: "Cookstown",
      zip_code: "08511",
      state_id: "NJ",
      state_name: "New Jersey",
    },
    {
      city_id: "2461",
      city: "Cranbury",
      zip_code: "08512",
      state_id: "NJ",
      state_name: "New Jersey",
    },
    {
      city_id: "1999",
      city: "Cranford",
      zip_code: "07016",
      state_id: "NJ",
      state_name: "New Jersey",
    },
    {
      city_id: "2462",
      city: "Cream Ridge",
      zip_code: "08514",
      state_id: "NJ",
      state_name: "New Jersey",
    },
    {
      city_id: "2150",
      city: "Cresskill",
      zip_code: "07626",
      state_id: "NJ",
      state_name: "New Jersey",
    },
    {
      city_id: "2533",
      city: "Dayton",
      zip_code: "08810",
      state_id: "NJ",
      state_name: "New Jersey",
    },
    {
      city_id: "2192",
      city: "Deal",
      zip_code: "07723",
      state_id: "NJ",
      state_name: "New Jersey",
    },
    {
      city_id: "2313",
      city: "Deepwater",
      zip_code: "08023",
      state_id: "NJ",
      state_name: "New Jersey",
    },
    {
      city_id: "2233",
      city: "Delaware",
      zip_code: "07833",
      state_id: "NJ",
      state_name: "New Jersey",
    },
    {
      city_id: "2423",
      city: "Delmont",
      zip_code: "08314",
      state_id: "NJ",
      state_name: "New Jersey",
    },
    {
      city_id: "2151",
      city: "Demarest",
      zip_code: "07627",
      state_id: "NJ",
      state_name: "New Jersey",
    },
    {
      city_id: "2234",
      city: "Denville",
      zip_code: "07834",
      state_id: "NJ",
      state_name: "New Jersey",
    },
    {
      city_id: "2424",
      city: "Dorchester",
      zip_code: "08316",
      state_id: "NJ",
      state_name: "New Jersey",
    },
    {
      city_id: "2425",
      city: "Dorothy",
      zip_code: "08317",
      state_id: "NJ",
      state_name: "New Jersey",
    },
    {
      city_id: "2220",
      city: "Dover",
      zip_code: "07801",
      state_id: "NJ",
      state_name: "New Jersey",
    },
    {
      city_id: "2152",
      city: "Dumont",
      zip_code: "07628",
      state_id: "NJ",
      state_name: "New Jersey",
    },
    {
      city_id: "2534",
      city: "Dunellen",
      zip_code: "08812",
      state_id: "NJ",
      state_name: "New Jersey",
    },
    {
      city_id: "2535",
      city: "East Brunswick",
      zip_code: "08816",
      state_id: "NJ",
      state_name: "New Jersey",
    },
    {
      city_id: "2279",
      city: "East Hanover",
      zip_code: "07936",
      state_id: "NJ",
      state_name: "New Jersey",
    },
    {
      city_id: "2000",
      city: "East Orange",
      zip_code: "07017",
      state_id: "NJ",
      state_name: "New Jersey",
    },
    {
      city_id: "2046",
      city: "East Rutherford",
      zip_code: "07073",
      state_id: "NJ",
      state_name: "New Jersey",
    },
    {
      city_id: "2193",
      city: "Eatontown",
      zip_code: "07724",
      state_id: "NJ",
      state_name: "New Jersey",
    },
    {
      city_id: "2002",
      city: "Edgewater",
      zip_code: "07020",
      state_id: "NJ",
      state_name: "New Jersey",
    },
    {
      city_id: "2536",
      city: "Edison",
      zip_code: "08817",
      state_id: "NJ",
      state_name: "New Jersey",
    },
    {
      city_id: "2397",
      city: "Egg Harbor City",
      zip_code: "08215",
      state_id: "NJ",
      state_name: "New Jersey",
    },
    {
      city_id: "2406",
      city: "Egg Harbor Township",
      zip_code: "08234",
      state_id: "NJ",
      state_name: "New Jersey",
    },
    {
      city_id: "2077",
      city: "Elizabeth",
      zip_code: "07201",
      state_id: "NJ",
      state_name: "New Jersey",
    },
    {
      city_id: "2082",
      city: "Elizabethport",
      zip_code: "07206",
      state_id: "NJ",
      state_name: "New Jersey",
    },
    {
      city_id: "2426",
      city: "Elmer",
      zip_code: "08318",
      state_id: "NJ",
      state_name: "New Jersey",
    },
    {
      city_id: "2094",
      city: "Elmwood Park",
      zip_code: "07407",
      state_id: "NJ",
      state_name: "New Jersey",
    },
    {
      city_id: "2398",
      city: "Elwood",
      zip_code: "08217",
      state_id: "NJ",
      state_name: "New Jersey",
    },
    {
      city_id: "2153",
      city: "Emerson",
      zip_code: "07630",
      state_id: "NJ",
      state_name: "New Jersey",
    },
    {
      city_id: "2154",
      city: "Englewood",
      zip_code: "07631",
      state_id: "NJ",
      state_name: "New Jersey",
    },
    {
      city_id: "2155",
      city: "Englewood Cliffs",
      zip_code: "07632",
      state_id: "NJ",
      state_name: "New Jersey",
    },
    {
      city_id: "2194",
      city: "Englishtown",
      zip_code: "07726",
      state_id: "NJ",
      state_name: "New Jersey",
    },
    {
      city_id: "2003",
      city: "Essex Fells",
      zip_code: "07021",
      state_id: "NJ",
      state_name: "New Jersey",
    },
    {
      city_id: "2427",
      city: "Estell Manor",
      zip_code: "08319",
      state_id: "NJ",
      state_name: "New Jersey",
    },
    {
      city_id: "2182",
      city: "Fair Haven",
      zip_code: "07704",
      state_id: "NJ",
      state_name: "New Jersey",
    },
    {
      city_id: "2095",
      city: "Fair Lawn",
      zip_code: "07410",
      state_id: "NJ",
      state_name: "New Jersey",
    },
    {
      city_id: "1989",
      city: "Fairfield",
      zip_code: "07004",
      state_id: "NJ",
      state_name: "New Jersey",
    },
    {
      city_id: "2428",
      city: "Fairton",
      zip_code: "08320",
      state_id: "NJ",
      state_name: "New Jersey",
    },
    {
      city_id: "2004",
      city: "Fairview",
      zip_code: "07022",
      state_id: "NJ",
      state_name: "New Jersey",
    },
    {
      city_id: "2005",
      city: "Fanwood",
      zip_code: "07023",
      state_id: "NJ",
      state_name: "New Jersey",
    },
    {
      city_id: "2274",
      city: "Far Hills",
      zip_code: "07931",
      state_id: "NJ",
      state_name: "New Jersey",
    },
    {
      city_id: "2195",
      city: "Farmingdale",
      zip_code: "07727",
      state_id: "NJ",
      state_name: "New Jersey",
    },
    {
      city_id: "2538",
      city: "Flagtown",
      zip_code: "08821",
      state_id: "NJ",
      state_name: "New Jersey",
    },
    {
      city_id: "2235",
      city: "Flanders",
      zip_code: "07836",
      state_id: "NJ",
      state_name: "New Jersey",
    },
    {
      city_id: "2539",
      city: "Flemington",
      zip_code: "08822",
      state_id: "NJ",
      state_name: "New Jersey",
    },
    {
      city_id: "2464",
      city: "Florence",
      zip_code: "08518",
      state_id: "NJ",
      state_name: "New Jersey",
    },
    {
      city_id: "2275",
      city: "Florham Park",
      zip_code: "07932",
      state_id: "NJ",
      state_name: "New Jersey",
    },
    {
      city_id: "2566",
      city: "Fords",
      zip_code: "08863",
      state_id: "NJ",
      state_name: "New Jersey",
    },
    {
      city_id: "2509",
      city: "Forked River",
      zip_code: "08731",
      state_id: "NJ",
      state_name: "New Jersey",
    },
    {
      city_id: "2006",
      city: "Fort Lee",
      zip_code: "07024",
      state_id: "NJ",
      state_name: "New Jersey",
    },
    {
      city_id: "2181",
      city: "Fort Monmouth",
      zip_code: "07703",
      state_id: "NJ",
      state_name: "New Jersey",
    },
    {
      city_id: "2429",
      city: "Fortescue",
      zip_code: "08321",
      state_id: "NJ",
      state_name: "New Jersey",
    },
    {
      city_id: "2096",
      city: "Franklin",
      zip_code: "07416",
      state_id: "NJ",
      state_name: "New Jersey",
    },
    {
      city_id: "2097",
      city: "Franklin Lakes",
      zip_code: "07417",
      state_id: "NJ",
      state_name: "New Jersey",
    },
    {
      city_id: "2540",
      city: "Franklin Park",
      zip_code: "08823",
      state_id: "NJ",
      state_name: "New Jersey",
    },
    {
      city_id: "2430",
      city: "Franklinville",
      zip_code: "08322",
      state_id: "NJ",
      state_name: "New Jersey",
    },
    {
      city_id: "2196",
      city: "Freehold",
      zip_code: "07728",
      state_id: "NJ",
      state_name: "New Jersey",
    },
    {
      city_id: "2542",
      city: "Frenchtown",
      zip_code: "08825",
      state_id: "NJ",
      state_name: "New Jersey",
    },
    {
      city_id: "2007",
      city: "Garfield",
      zip_code: "07026",
      state_id: "NJ",
      state_name: "New Jersey",
    },
    {
      city_id: "2008",
      city: "Garwood",
      zip_code: "07027",
      state_id: "NJ",
      state_name: "New Jersey",
    },
    {
      city_id: "2314",
      city: "Gibbsboro",
      zip_code: "08026",
      state_id: "NJ",
      state_name: "New Jersey",
    },
    {
      city_id: "2315",
      city: "Gibbstown",
      zip_code: "08027",
      state_id: "NJ",
      state_name: "New Jersey",
    },
    {
      city_id: "2276",
      city: "Gillette",
      zip_code: "07933",
      state_id: "NJ",
      state_name: "New Jersey",
    },
    {
      city_id: "2277",
      city: "Gladstone",
      zip_code: "07934",
      state_id: "NJ",
      state_name: "New Jersey",
    },
    {
      city_id: "2316",
      city: "Glassboro",
      zip_code: "08028",
      state_id: "NJ",
      state_name: "New Jersey",
    },
    {
      city_id: "2543",
      city: "Glen Gardner",
      zip_code: "08826",
      state_id: "NJ",
      state_name: "New Jersey",
    },
    {
      city_id: "2009",
      city: "Glen Ridge",
      zip_code: "07028",
      state_id: "NJ",
      state_name: "New Jersey",
    },
    {
      city_id: "2116",
      city: "Glen Rock",
      zip_code: "07452",
      state_id: "NJ",
      state_name: "New Jersey",
    },
    {
      city_id: "2317",
      city: "Glendora",
      zip_code: "08029",
      state_id: "NJ",
      state_name: "New Jersey",
    },
    {
      city_id: "2098",
      city: "Glenwood",
      zip_code: "07418",
      state_id: "NJ",
      state_name: "New Jersey",
    },
    {
      city_id: "2318",
      city: "Gloucester City",
      zip_code: "08030",
      state_id: "NJ",
      state_name: "New Jersey",
    },
    {
      city_id: "2236",
      city: "Great Meadows",
      zip_code: "07838",
      state_id: "NJ",
      state_name: "New Jersey",
    },
    {
      city_id: "2278",
      city: "Green Village",
      zip_code: "07935",
      state_id: "NJ",
      state_name: "New Jersey",
    },
    {
      city_id: "2431",
      city: "Greenwich",
      zip_code: "08323",
      state_id: "NJ",
      state_name: "New Jersey",
    },
    {
      city_id: "2320",
      city: "Grenloch",
      zip_code: "08032",
      state_id: "NJ",
      state_name: "New Jersey",
    },
    {
      city_id: "2140",
      city: "Hackensack",
      zip_code: "07601",
      state_id: "NJ",
      state_name: "New Jersey",
    },
    {
      city_id: "2237",
      city: "Hackettstown",
      zip_code: "07840",
      state_id: "NJ",
      state_name: "New Jersey",
    },
    {
      city_id: "2323",
      city: "Haddon Heights",
      zip_code: "08035",
      state_id: "NJ",
      state_name: "New Jersey",
    },
    {
      city_id: "2321",
      city: "Haddonfield",
      zip_code: "08033",
      state_id: "NJ",
      state_name: "New Jersey",
    },
    {
      city_id: "2324",
      city: "Hainesport",
      zip_code: "08036",
      state_id: "NJ",
      state_name: "New Jersey",
    },
    {
      city_id: "2134",
      city: "Haledon",
      zip_code: "07508",
      state_id: "NJ",
      state_name: "New Jersey",
    },
    {
      city_id: "2099",
      city: "Hamburg",
      zip_code: "07419",
      state_id: "NJ",
      state_name: "New Jersey",
    },
    {
      city_id: "2325",
      city: "Hammonton",
      zip_code: "08037",
      state_id: "NJ",
      state_name: "New Jersey",
    },
    {
      city_id: "2544",
      city: "Hampton",
      zip_code: "08827",
      state_id: "NJ",
      state_name: "New Jersey",
    },
    {
      city_id: "2326",
      city: "Hancocks Bridge",
      zip_code: "08038",
      state_id: "NJ",
      state_name: "New Jersey",
    },
    {
      city_id: "2156",
      city: "Harrington Park",
      zip_code: "07640",
      state_id: "NJ",
      state_name: "New Jersey",
    },
    {
      city_id: "2010",
      city: "Harrison",
      zip_code: "07029",
      state_id: "NJ",
      state_name: "New Jersey",
    },
    {
      city_id: "2327",
      city: "Harrisonville",
      zip_code: "08039",
      state_id: "NJ",
      state_name: "New Jersey",
    },
    {
      city_id: "2142",
      city: "Hasbrouck Heights",
      zip_code: "07604",
      state_id: "NJ",
      state_name: "New Jersey",
    },
    {
      city_id: "2100",
      city: "Haskell",
      zip_code: "07420",
      state_id: "NJ",
      state_name: "New Jersey",
    },
    {
      city_id: "2157",
      city: "Haworth",
      zip_code: "07641",
      state_id: "NJ",
      state_name: "New Jersey",
    },
    {
      city_id: "2133",
      city: "Hawthorne",
      zip_code: "07506",
      state_id: "NJ",
      state_name: "New Jersey",
    },
    {
      city_id: "2197",
      city: "Hazlet",
      zip_code: "07730",
      state_id: "NJ",
      state_name: "New Jersey",
    },
    {
      city_id: "2432",
      city: "Heislerville",
      zip_code: "08324",
      state_id: "NJ",
      state_name: "New Jersey",
    },
    {
      city_id: "2545",
      city: "Helmetta",
      zip_code: "08828",
      state_id: "NJ",
      state_name: "New Jersey",
    },
    {
      city_id: "2101",
      city: "Hewitt",
      zip_code: "07421",
      state_id: "NJ",
      state_name: "New Jersey",
    },
    {
      city_id: "2238",
      city: "Hibernia",
      zip_code: "07842",
      state_id: "NJ",
      state_name: "New Jersey",
    },
    {
      city_id: "2546",
      city: "High Bridge",
      zip_code: "08829",
      state_id: "NJ",
      state_name: "New Jersey",
    },
    {
      city_id: "2102",
      city: "Highland Lakes",
      zip_code: "07422",
      state_id: "NJ",
      state_name: "New Jersey",
    },
    {
      city_id: "2583",
      city: "Highland Park",
      zip_code: "08904",
      state_id: "NJ",
      state_name: "New Jersey",
    },
    {
      city_id: "2199",
      city: "Highlands",
      zip_code: "07732",
      state_id: "NJ",
      state_name: "New Jersey",
    },
    {
      city_id: "2465",
      city: "Hightstown",
      zip_code: "08520",
      state_id: "NJ",
      state_name: "New Jersey",
    },
    {
      city_id: "2555",
      city: "Hillsborough",
      zip_code: "08844",
      state_id: "NJ",
      state_name: "New Jersey",
    },
    {
      city_id: "2158",
      city: "Hillsdale",
      zip_code: "07642",
      state_id: "NJ",
      state_name: "New Jersey",
    },
    {
      city_id: "2081",
      city: "Hillside",
      zip_code: "07205",
      state_id: "NJ",
      state_name: "New Jersey",
    },
    {
      city_id: "2103",
      city: "Ho Ho Kus",
      zip_code: "07423",
      state_id: "NJ",
      state_name: "New Jersey",
    },
    {
      city_id: "2011",
      city: "Hoboken",
      zip_code: "07030",
      state_id: "NJ",
      state_name: "New Jersey",
    },
    {
      city_id: "2200",
      city: "Holmdel",
      zip_code: "07733",
      state_id: "NJ",
      state_name: "New Jersey",
    },
    {
      city_id: "2239",
      city: "Hopatcong",
      zip_code: "07843",
      state_id: "NJ",
      state_name: "New Jersey",
    },
    {
      city_id: "2240",
      city: "Hope",
      zip_code: "07844",
      state_id: "NJ",
      state_name: "New Jersey",
    },
    {
      city_id: "2466",
      city: "Hopewell",
      zip_code: "08525",
      state_id: "NJ",
      state_name: "New Jersey",
    },
    {
      city_id: "2198",
      city: "Howell",
      zip_code: "07731",
      state_id: "NJ",
      state_name: "New Jersey",
    },
    {
      city_id: "2074",
      city: "Irvington",
      zip_code: "07111",
      state_id: "NJ",
      state_name: "New Jersey",
    },
    {
      city_id: "2547",
      city: "Iselin",
      zip_code: "08830",
      state_id: "NJ",
      state_name: "New Jersey",
    },
    {
      city_id: "2510",
      city: "Island Heights",
      zip_code: "08732",
      state_id: "NJ",
      state_name: "New Jersey",
    },
    {
      city_id: "2467",
      city: "Jackson",
      zip_code: "08527",
      state_id: "NJ",
      state_name: "New Jersey",
    },
    {
      city_id: "2084",
      city: "Jersey City",
      zip_code: "07302",
      state_id: "NJ",
      state_name: "New Jersey",
    },
    {
      city_id: "2328",
      city: "Jobstown",
      zip_code: "08041",
      state_id: "NJ",
      state_name: "New Jersey",
    },
    {
      city_id: "2241",
      city: "Johnsonburg",
      zip_code: "07846",
      state_id: "NJ",
      state_name: "New Jersey",
    },
    {
      city_id: "2497",
      city: "Joint Base Mdl",
      zip_code: "08640",
      state_id: "NJ",
      state_name: "New Jersey",
    },
    {
      city_id: "2329",
      city: "Juliustown",
      zip_code: "08042",
      state_id: "NJ",
      state_name: "New Jersey",
    },
    {
      city_id: "2201",
      city: "Keansburg",
      zip_code: "07734",
      state_id: "NJ",
      state_name: "New Jersey",
    },
    {
      city_id: "2013",
      city: "Kearny",
      zip_code: "07032",
      state_id: "NJ",
      state_name: "New Jersey",
    },
    {
      city_id: "2549",
      city: "Keasbey",
      zip_code: "08832",
      state_id: "NJ",
      state_name: "New Jersey",
    },
    {
      city_id: "2541",
      city: "Kendall Park",
      zip_code: "08824",
      state_id: "NJ",
      state_name: "New Jersey",
    },
    {
      city_id: "2014",
      city: "Kenilworth",
      zip_code: "07033",
      state_id: "NJ",
      state_name: "New Jersey",
    },
    {
      city_id: "2242",
      city: "Kenvil",
      zip_code: "07847",
      state_id: "NJ",
      state_name: "New Jersey",
    },
    {
      city_id: "2202",
      city: "Keyport",
      zip_code: "07735",
      state_id: "NJ",
      state_name: "New Jersey",
    },
    {
      city_id: "2468",
      city: "Kingston",
      zip_code: "08528",
      state_id: "NJ",
      state_name: "New Jersey",
    },
    {
      city_id: "2243",
      city: "Lafayette",
      zip_code: "07848",
      state_id: "NJ",
      state_name: "New Jersey",
    },
    {
      city_id: "2015",
      city: "Lake Hiawatha",
      zip_code: "07034",
      state_id: "NJ",
      state_name: "New Jersey",
    },
    {
      city_id: "2244",
      city: "Lake Hopatcong",
      zip_code: "07849",
      state_id: "NJ",
      state_name: "New Jersey",
    },
    {
      city_id: "2511",
      city: "Lakehurst",
      zip_code: "08733",
      state_id: "NJ",
      state_name: "New Jersey",
    },
    {
      city_id: "2502",
      city: "Lakewood",
      zip_code: "08701",
      state_id: "NJ",
      state_name: "New Jersey",
    },
    {
      city_id: "2469",
      city: "Lambertville",
      zip_code: "08530",
      state_id: "NJ",
      state_name: "New Jersey",
    },
    {
      city_id: "2245",
      city: "Landing",
      zip_code: "07850",
      state_id: "NJ",
      state_name: "New Jersey",
    },
    {
      city_id: "2433",
      city: "Landisville",
      zip_code: "08326",
      state_id: "NJ",
      state_name: "New Jersey",
    },
    {
      city_id: "2512",
      city: "Lanoka Harbor",
      zip_code: "08734",
      state_id: "NJ",
      state_name: "New Jersey",
    },
    {
      city_id: "2513",
      city: "Lavallette",
      zip_code: "08735",
      state_id: "NJ",
      state_name: "New Jersey",
    },
    {
      city_id: "2331",
      city: "Lawnside",
      zip_code: "08045",
      state_id: "NJ",
      state_name: "New Jersey",
    },
    {
      city_id: "2499",
      city: "Lawrence Township",
      zip_code: "08648",
      state_id: "NJ",
      state_name: "New Jersey",
    },
    {
      city_id: "2246",
      city: "Layton",
      zip_code: "07851",
      state_id: "NJ",
      state_name: "New Jersey",
    },
    {
      city_id: "2550",
      city: "Lebanon",
      zip_code: "08833",
      state_id: "NJ",
      state_name: "New Jersey",
    },
    {
      city_id: "2247",
      city: "Ledgewood",
      zip_code: "07852",
      state_id: "NJ",
      state_name: "New Jersey",
    },
    {
      city_id: "2434",
      city: "Leesburg",
      zip_code: "08327",
      state_id: "NJ",
      state_name: "New Jersey",
    },
    {
      city_id: "2203",
      city: "Leonardo",
      zip_code: "07737",
      state_id: "NJ",
      state_name: "New Jersey",
    },
    {
      city_id: "2143",
      city: "Leonia",
      zip_code: "07605",
      state_id: "NJ",
      state_name: "New Jersey",
    },
    {
      city_id: "2016",
      city: "Lincoln Park",
      zip_code: "07035",
      state_id: "NJ",
      state_name: "New Jersey",
    },
    {
      city_id: "2204",
      city: "Lincroft",
      zip_code: "07738",
      state_id: "NJ",
      state_name: "New Jersey",
    },
    {
      city_id: "2017",
      city: "Linden",
      zip_code: "07036",
      state_id: "NJ",
      state_name: "New Jersey",
    },
    {
      city_id: "2399",
      city: "Linwood",
      zip_code: "08221",
      state_id: "NJ",
      state_name: "New Jersey",
    },
    {
      city_id: "2104",
      city: "Little Falls",
      zip_code: "07424",
      state_id: "NJ",
      state_name: "New Jersey",
    },
    {
      city_id: "2159",
      city: "Little Ferry",
      zip_code: "07643",
      state_id: "NJ",
      state_name: "New Jersey",
    },
    {
      city_id: "2205",
      city: "Little Silver",
      zip_code: "07739",
      state_id: "NJ",
      state_name: "New Jersey",
    },
    {
      city_id: "2018",
      city: "Livingston",
      zip_code: "07039",
      state_id: "NJ",
      state_name: "New Jersey",
    },
    {
      city_id: "2160",
      city: "Lodi",
      zip_code: "07644",
      state_id: "NJ",
      state_name: "New Jersey",
    },
    {
      city_id: "2206",
      city: "Long Branch",
      zip_code: "07740",
      state_id: "NJ",
      state_name: "New Jersey",
    },
    {
      city_id: "2248",
      city: "Long Valley",
      zip_code: "07853",
      state_id: "NJ",
      state_name: "New Jersey",
    },
    {
      city_id: "2454",
      city: "Longport",
      zip_code: "08403",
      state_id: "NJ",
      state_name: "New Jersey",
    },
    {
      city_id: "2333",
      city: "Lumberton",
      zip_code: "08048",
      state_id: "NJ",
      state_name: "New Jersey",
    },
    {
      city_id: "2044",
      city: "Lyndhurst",
      zip_code: "07071",
      state_id: "NJ",
      state_name: "New Jersey",
    },
    {
      city_id: "2280",
      city: "Lyons",
      zip_code: "07939",
      state_id: "NJ",
      state_name: "New Jersey",
    },
    {
      city_id: "2281",
      city: "Madison",
      zip_code: "07940",
      state_id: "NJ",
      state_name: "New Jersey",
    },
    {
      city_id: "2334",
      city: "Magnolia",
      zip_code: "08049",
      state_id: "NJ",
      state_name: "New Jersey",
    },
    {
      city_id: "2105",
      city: "Mahwah",
      zip_code: "07430",
      state_id: "NJ",
      state_name: "New Jersey",
    },
    {
      city_id: "2435",
      city: "Malaga",
      zip_code: "08328",
      state_id: "NJ",
      state_name: "New Jersey",
    },
    {
      city_id: "2335",
      city: "Manahawkin",
      zip_code: "08050",
      state_id: "NJ",
      state_name: "New Jersey",
    },
    {
      city_id: "2514",
      city: "Manasquan",
      zip_code: "08736",
      state_id: "NJ",
      state_name: "New Jersey",
    },
    {
      city_id: "2526",
      city: "Manchester Township",
      zip_code: "08759",
      state_id: "NJ",
      state_name: "New Jersey",
    },
    {
      city_id: "2515",
      city: "Mantoloking",
      zip_code: "08738",
      state_id: "NJ",
      state_name: "New Jersey",
    },
    {
      city_id: "2336",
      city: "Mantua",
      zip_code: "08051",
      state_id: "NJ",
      state_name: "New Jersey",
    },
    {
      city_id: "2551",
      city: "Manville",
      zip_code: "08835",
      state_id: "NJ",
      state_name: "New Jersey",
    },
    {
      city_id: "2337",
      city: "Maple Shade",
      zip_code: "08052",
      state_id: "NJ",
      state_name: "New Jersey",
    },
    {
      city_id: "2019",
      city: "Maplewood",
      zip_code: "07040",
      state_id: "NJ",
      state_name: "New Jersey",
    },
    {
      city_id: "2453",
      city: "Margate City",
      zip_code: "08402",
      state_id: "NJ",
      state_name: "New Jersey",
    },
    {
      city_id: "2207",
      city: "Marlboro",
      zip_code: "07746",
      state_id: "NJ",
      state_name: "New Jersey",
    },
    {
      city_id: "2338",
      city: "Marlton",
      zip_code: "08053",
      state_id: "NJ",
      state_name: "New Jersey",
    },
    {
      city_id: "2400",
      city: "Marmora",
      zip_code: "08223",
      state_id: "NJ",
      state_name: "New Jersey",
    },
    {
      city_id: "2552",
      city: "Martinsville",
      zip_code: "08836",
      state_id: "NJ",
      state_name: "New Jersey",
    },
    {
      city_id: "2208",
      city: "Matawan",
      zip_code: "07747",
      state_id: "NJ",
      state_name: "New Jersey",
    },
    {
      city_id: "2436",
      city: "Mauricetown",
      zip_code: "08329",
      state_id: "NJ",
      state_name: "New Jersey",
    },
    {
      city_id: "2437",
      city: "Mays Landing",
      zip_code: "08330",
      state_id: "NJ",
      state_name: "New Jersey",
    },
    {
      city_id: "2145",
      city: "Maywood",
      zip_code: "07607",
      state_id: "NJ",
      state_name: "New Jersey",
    },
    {
      city_id: "2340",
      city: "Medford",
      zip_code: "08055",
      state_id: "NJ",
      state_name: "New Jersey",
    },
    {
      city_id: "2282",
      city: "Mendham",
      zip_code: "07945",
      state_id: "NJ",
      state_name: "New Jersey",
    },
    {
      city_id: "2388",
      city: "Merchantville",
      zip_code: "08109",
      state_id: "NJ",
      state_name: "New Jersey",
    },
    {
      city_id: "2554",
      city: "Metuchen",
      zip_code: "08840",
      state_id: "NJ",
      state_name: "New Jersey",
    },
    {
      city_id: "2341",
      city: "Mickleton",
      zip_code: "08056",
      state_id: "NJ",
      state_name: "New Jersey",
    },
    {
      city_id: "2556",
      city: "Middlesex",
      zip_code: "08846",
      state_id: "NJ",
      state_name: "New Jersey",
    },
    {
      city_id: "2209",
      city: "Middletown",
      zip_code: "07748",
      state_id: "NJ",
      state_name: "New Jersey",
    },
    {
      city_id: "2106",
      city: "Midland Park",
      zip_code: "07432",
      state_id: "NJ",
      state_name: "New Jersey",
    },
    {
      city_id: "2557",
      city: "Milford",
      zip_code: "08848",
      state_id: "NJ",
      state_name: "New Jersey",
    },
    {
      city_id: "2020",
      city: "Millburn",
      zip_code: "07041",
      state_id: "NJ",
      state_name: "New Jersey",
    },
    {
      city_id: "2283",
      city: "Millington",
      zip_code: "07946",
      state_id: "NJ",
      state_name: "New Jersey",
    },
    {
      city_id: "2459",
      city: "Millstone Township",
      zip_code: "08510",
      state_id: "NJ",
      state_name: "New Jersey",
    },
    {
      city_id: "2558",
      city: "Milltown",
      zip_code: "08850",
      state_id: "NJ",
      state_name: "New Jersey",
    },
    {
      city_id: "2438",
      city: "Millville",
      zip_code: "08332",
      state_id: "NJ",
      state_name: "New Jersey",
    },
    {
      city_id: "2439",
      city: "Milmay",
      zip_code: "08340",
      state_id: "NJ",
      state_name: "New Jersey",
    },
    {
      city_id: "2221",
      city: "Mine Hill",
      zip_code: "07803",
      state_id: "NJ",
      state_name: "New Jersey",
    },
    {
      city_id: "2440",
      city: "Minotola",
      zip_code: "08341",
      state_id: "NJ",
      state_name: "New Jersey",
    },
    {
      city_id: "2210",
      city: "Monmouth Beach",
      zip_code: "07750",
      state_id: "NJ",
      state_name: "New Jersey",
    },
    {
      city_id: "2559",
      city: "Monmouth Junction",
      zip_code: "08852",
      state_id: "NJ",
      state_name: "New Jersey",
    },
    {
      city_id: "2548",
      city: "Monroe Township",
      zip_code: "08831",
      state_id: "NJ",
      state_name: "New Jersey",
    },
    {
      city_id: "2441",
      city: "Monroeville",
      zip_code: "08343",
      state_id: "NJ",
      state_name: "New Jersey",
    },
    {
      city_id: "2228",
      city: "Montague",
      zip_code: "07827",
      state_id: "NJ",
      state_name: "New Jersey",
    },
    {
      city_id: "2021",
      city: "Montclair",
      zip_code: "07042",
      state_id: "NJ",
      state_name: "New Jersey",
    },
    {
      city_id: "2161",
      city: "Montvale",
      zip_code: "07645",
      state_id: "NJ",
      state_name: "New Jersey",
    },
    {
      city_id: "2024",
      city: "Montville",
      zip_code: "07045",
      state_id: "NJ",
      state_name: "New Jersey",
    },
    {
      city_id: "2047",
      city: "Moonachie",
      zip_code: "07074",
      state_id: "NJ",
      state_name: "New Jersey",
    },
    {
      city_id: "2342",
      city: "Moorestown",
      zip_code: "08057",
      state_id: "NJ",
      state_name: "New Jersey",
    },
    {
      city_id: "2211",
      city: "Morganville",
      zip_code: "07751",
      state_id: "NJ",
      state_name: "New Jersey",
    },
    {
      city_id: "2284",
      city: "Morris Plains",
      zip_code: "07950",
      state_id: "NJ",
      state_name: "New Jersey",
    },
    {
      city_id: "2285",
      city: "Morristown",
      zip_code: "07960",
      state_id: "NJ",
      state_name: "New Jersey",
    },
    {
      city_id: "2249",
      city: "Mount Arlington",
      zip_code: "07856",
      state_id: "NJ",
      state_name: "New Jersey",
    },
    {
      city_id: "2343",
      city: "Mount Ephraim",
      zip_code: "08059",
      state_id: "NJ",
      state_name: "New Jersey",
    },
    {
      city_id: "2287",
      city: "Mount Freedom",
      zip_code: "07970",
      state_id: "NJ",
      state_name: "New Jersey",
    },
    {
      city_id: "2344",
      city: "Mount Holly",
      zip_code: "08060",
      state_id: "NJ",
      state_name: "New Jersey",
    },
    {
      city_id: "2339",
      city: "Mount Laurel",
      zip_code: "08054",
      state_id: "NJ",
      state_name: "New Jersey",
    },
    {
      city_id: "2345",
      city: "Mount Royal",
      zip_code: "08061",
      state_id: "NJ",
      state_name: "New Jersey",
    },
    {
      city_id: "2260",
      city: "Mount Tabor",
      zip_code: "07878",
      state_id: "NJ",
      state_name: "New Jersey",
    },
    {
      city_id: "2025",
      city: "Mountain Lakes",
      zip_code: "07046",
      state_id: "NJ",
      state_name: "New Jersey",
    },
    {
      city_id: "2061",
      city: "Mountainside",
      zip_code: "07092",
      state_id: "NJ",
      state_name: "New Jersey",
    },
    {
      city_id: "2346",
      city: "Mullica Hill",
      zip_code: "08062",
      state_id: "NJ",
      state_name: "New Jersey",
    },
    {
      city_id: "2347",
      city: "National Park",
      zip_code: "08063",
      state_id: "NJ",
      state_name: "New Jersey",
    },
    {
      city_id: "2212",
      city: "Neptune",
      zip_code: "07753",
      state_id: "NJ",
      state_name: "New Jersey",
    },
    {
      city_id: "2560",
      city: "Neshanic Station",
      zip_code: "08853",
      state_id: "NJ",
      state_name: "New Jersey",
    },
    {
      city_id: "2250",
      city: "Netcong",
      zip_code: "07857",
      state_id: "NJ",
      state_name: "New Jersey",
    },
    {
      city_id: "2581",
      city: "New Brunswick",
      zip_code: "08901",
      state_id: "NJ",
      state_name: "New Jersey",
    },
    {
      city_id: "2470",
      city: "New Egypt",
      zip_code: "08533",
      state_id: "NJ",
      state_name: "New Jersey",
    },
    {
      city_id: "2401",
      city: "New Gretna",
      zip_code: "08224",
      state_id: "NJ",
      state_name: "New Jersey",
    },
    {
      city_id: "2348",
      city: "New Lisbon",
      zip_code: "08064",
      state_id: "NJ",
      state_name: "New Jersey",
    },
    {
      city_id: "2162",
      city: "New Milford",
      zip_code: "07646",
      state_id: "NJ",
      state_name: "New Jersey",
    },
    {
      city_id: "2288",
      city: "New Providence",
      zip_code: "07974",
      state_id: "NJ",
      state_name: "New Jersey",
    },
    {
      city_id: "2289",
      city: "New Vernon",
      zip_code: "07976",
      state_id: "NJ",
      state_name: "New Jersey",
    },
    {
      city_id: "2065",
      city: "Newark",
      zip_code: "07102",
      state_id: "NJ",
      state_name: "New Jersey",
    },
    {
      city_id: "2442",
      city: "Newfield",
      zip_code: "08344",
      state_id: "NJ",
      state_name: "New Jersey",
    },
    {
      city_id: "2107",
      city: "Newfoundland",
      zip_code: "07435",
      state_id: "NJ",
      state_name: "New Jersey",
    },
    {
      city_id: "2443",
      city: "Newport",
      zip_code: "08345",
      state_id: "NJ",
      state_name: "New Jersey",
    },
    {
      city_id: "2251",
      city: "Newton",
      zip_code: "07860",
      state_id: "NJ",
      state_name: "New Jersey",
    },
    {
      city_id: "2444",
      city: "Newtonville",
      zip_code: "08346",
      state_id: "NJ",
      state_name: "New Jersey",
    },
    {
      city_id: "2012",
      city: "North Arlington",
      zip_code: "07031",
      state_id: "NJ",
      state_name: "New Jersey",
    },
    {
      city_id: "2026",
      city: "North Bergen",
      zip_code: "07047",
      state_id: "NJ",
      state_name: "New Jersey",
    },
    {
      city_id: "2582",
      city: "North Brunswick",
      zip_code: "08902",
      state_id: "NJ",
      state_name: "New Jersey",
    },
    {
      city_id: "2402",
      city: "Northfield",
      zip_code: "08225",
      state_id: "NJ",
      state_name: "New Jersey",
    },
    {
      city_id: "2163",
      city: "Northvale",
      zip_code: "07647",
      state_id: "NJ",
      state_name: "New Jersey",
    },
    {
      city_id: "2164",
      city: "Norwood",
      zip_code: "07648",
      state_id: "NJ",
      state_name: "New Jersey",
    },
    {
      city_id: "2073",
      city: "Nutley",
      zip_code: "07110",
      state_id: "NJ",
      state_name: "New Jersey",
    },
    {
      city_id: "2109",
      city: "Oak Ridge",
      zip_code: "07438",
      state_id: "NJ",
      state_name: "New Jersey",
    },
    {
      city_id: "2213",
      city: "Oakhurst",
      zip_code: "07755",
      state_id: "NJ",
      state_name: "New Jersey",
    },
    {
      city_id: "2108",
      city: "Oakland",
      zip_code: "07436",
      state_id: "NJ",
      state_name: "New Jersey",
    },
    {
      city_id: "2386",
      city: "Oaklyn",
      zip_code: "08107",
      state_id: "NJ",
      state_name: "New Jersey",
    },
    {
      city_id: "2403",
      city: "Ocean City",
      zip_code: "08226",
      state_id: "NJ",
      state_name: "New Jersey",
    },
    {
      city_id: "2516",
      city: "Ocean Gate",
      zip_code: "08740",
      state_id: "NJ",
      state_name: "New Jersey",
    },
    {
      city_id: "2214",
      city: "Ocean Grove",
      zip_code: "07756",
      state_id: "NJ",
      state_name: "New Jersey",
    },
    {
      city_id: "2404",
      city: "Ocean View",
      zip_code: "08230",
      state_id: "NJ",
      state_name: "New Jersey",
    },
    {
      city_id: "2215",
      city: "Oceanport",
      zip_code: "07757",
      state_id: "NJ",
      state_name: "New Jersey",
    },
    {
      city_id: "2110",
      city: "Ogdensburg",
      zip_code: "07439",
      state_id: "NJ",
      state_name: "New Jersey",
    },
    {
      city_id: "2562",
      city: "Old Bridge",
      zip_code: "08857",
      state_id: "NJ",
      state_name: "New Jersey",
    },
    {
      city_id: "2563",
      city: "Oldwick",
      zip_code: "08858",
      state_id: "NJ",
      state_name: "New Jersey",
    },
    {
      city_id: "2165",
      city: "Oradell",
      zip_code: "07649",
      state_id: "NJ",
      state_name: "New Jersey",
    },
    {
      city_id: "2027",
      city: "Orange",
      zip_code: "07050",
      state_id: "NJ",
      state_name: "New Jersey",
    },
    {
      city_id: "2252",
      city: "Oxford",
      zip_code: "07863",
      state_id: "NJ",
      state_name: "New Jersey",
    },
    {
      city_id: "2166",
      city: "Palisades Park",
      zip_code: "07650",
      state_id: "NJ",
      state_name: "New Jersey",
    },
    {
      city_id: "2349",
      city: "Palmyra",
      zip_code: "08065",
      state_id: "NJ",
      state_name: "New Jersey",
    },
    {
      city_id: "2167",
      city: "Paramus",
      zip_code: "07652",
      state_id: "NJ",
      state_name: "New Jersey",
    },
    {
      city_id: "2168",
      city: "Park Ridge",
      zip_code: "07656",
      state_id: "NJ",
      state_name: "New Jersey",
    },
    {
      city_id: "2564",
      city: "Parlin",
      zip_code: "08859",
      state_id: "NJ",
      state_name: "New Jersey",
    },
    {
      city_id: "2029",
      city: "Parsippany",
      zip_code: "07054",
      state_id: "NJ",
      state_name: "New Jersey",
    },
    {
      city_id: "2030",
      city: "Passaic",
      zip_code: "07055",
      state_id: "NJ",
      state_name: "New Jersey",
    },
    {
      city_id: "2128",
      city: "Paterson",
      zip_code: "07501",
      state_id: "NJ",
      state_name: "New Jersey",
    },
    {
      city_id: "2350",
      city: "Paulsboro",
      zip_code: "08066",
      state_id: "NJ",
      state_name: "New Jersey",
    },
    {
      city_id: "2290",
      city: "Peapack",
      zip_code: "07977",
      state_id: "NJ",
      state_name: "New Jersey",
    },
    {
      city_id: "2351",
      city: "Pedricktown",
      zip_code: "08067",
      state_id: "NJ",
      state_name: "New Jersey",
    },
    {
      city_id: "2352",
      city: "Pemberton",
      zip_code: "08068",
      state_id: "NJ",
      state_name: "New Jersey",
    },
    {
      city_id: "2471",
      city: "Pennington",
      zip_code: "08534",
      state_id: "NJ",
      state_name: "New Jersey",
    },
    {
      city_id: "2353",
      city: "Penns Grove",
      zip_code: "08069",
      state_id: "NJ",
      state_name: "New Jersey",
    },
    {
      city_id: "2389",
      city: "Pennsauken",
      zip_code: "08110",
      state_id: "NJ",
      state_name: "New Jersey",
    },
    {
      city_id: "2354",
      city: "Pennsville",
      zip_code: "08070",
      state_id: "NJ",
      state_name: "New Jersey",
    },
    {
      city_id: "2111",
      city: "Pequannock",
      zip_code: "07440",
      state_id: "NJ",
      state_name: "New Jersey",
    },
    {
      city_id: "2565",
      city: "Perth Amboy",
      zip_code: "08861",
      state_id: "NJ",
      state_name: "New Jersey",
    },
    {
      city_id: "2567",
      city: "Phillipsburg",
      zip_code: "08865",
      state_id: "NJ",
      state_name: "New Jersey",
    },
    {
      city_id: "2517",
      city: "Pine Beach",
      zip_code: "08741",
      state_id: "NJ",
      state_name: "New Jersey",
    },
    {
      city_id: "2032",
      city: "Pine Brook",
      zip_code: "07058",
      state_id: "NJ",
      state_name: "New Jersey",
    },
    {
      city_id: "2561",
      city: "Piscataway",
      zip_code: "08854",
      state_id: "NJ",
      state_name: "New Jersey",
    },
    {
      city_id: "2355",
      city: "Pitman",
      zip_code: "08071",
      state_id: "NJ",
      state_name: "New Jersey",
    },
    {
      city_id: "2568",
      city: "Pittstown",
      zip_code: "08867",
      state_id: "NJ",
      state_name: "New Jersey",
    },
    {
      city_id: "2034",
      city: "Plainfield",
      zip_code: "07060",
      state_id: "NJ",
      state_name: "New Jersey",
    },
    {
      city_id: "2473",
      city: "Plainsboro",
      zip_code: "08536",
      state_id: "NJ",
      state_name: "New Jersey",
    },
    {
      city_id: "2405",
      city: "Pleasantville",
      zip_code: "08232",
      state_id: "NJ",
      state_name: "New Jersey",
    },
    {
      city_id: "2518",
      city: "Point Pleasant Beach",
      zip_code: "08742",
      state_id: "NJ",
      state_name: "New Jersey",
    },
    {
      city_id: "2407",
      city: "Pomona",
      zip_code: "08240",
      state_id: "NJ",
      state_name: "New Jersey",
    },
    {
      city_id: "2112",
      city: "Pompton Lakes",
      zip_code: "07442",
      state_id: "NJ",
      state_name: "New Jersey",
    },
    {
      city_id: "2113",
      city: "Pompton Plains",
      zip_code: "07444",
      state_id: "NJ",
      state_name: "New Jersey",
    },
    {
      city_id: "2445",
      city: "Port Elizabeth",
      zip_code: "08348",
      state_id: "NJ",
      state_name: "New Jersey",
    },
    {
      city_id: "2216",
      city: "Port Monmouth",
      zip_code: "07758",
      state_id: "NJ",
      state_name: "New Jersey",
    },
    {
      city_id: "2253",
      city: "Port Murray",
      zip_code: "07865",
      state_id: "NJ",
      state_name: "New Jersey",
    },
    {
      city_id: "2446",
      city: "Port Norris",
      zip_code: "08349",
      state_id: "NJ",
      state_name: "New Jersey",
    },
    {
      city_id: "2037",
      city: "Port Reading",
      zip_code: "07064",
      state_id: "NJ",
      state_name: "New Jersey",
    },
    {
      city_id: "2408",
      city: "Port Republic",
      zip_code: "08241",
      state_id: "NJ",
      state_name: "New Jersey",
    },
    {
      city_id: "2291",
      city: "Pottersville",
      zip_code: "07979",
      state_id: "NJ",
      state_name: "New Jersey",
    },
    {
      city_id: "2474",
      city: "Princeton",
      zip_code: "08540",
      state_id: "NJ",
      state_name: "New Jersey",
    },
    {
      city_id: "2477",
      city: "Princeton Junction",
      zip_code: "08550",
      state_id: "NJ",
      state_name: "New Jersey",
    },
    {
      city_id: "2356",
      city: "Quinton",
      zip_code: "08072",
      state_id: "NJ",
      state_name: "New Jersey",
    },
    {
      city_id: "2038",
      city: "Rahway",
      zip_code: "07065",
      state_id: "NJ",
      state_name: "New Jersey",
    },
    {
      city_id: "2114",
      city: "Ramsey",
      zip_code: "07446",
      state_id: "NJ",
      state_name: "New Jersey",
    },
    {
      city_id: "2357",
      city: "Rancocas",
      zip_code: "08073",
      state_id: "NJ",
      state_name: "New Jersey",
    },
    {
      city_id: "2255",
      city: "Randolph",
      zip_code: "07869",
      state_id: "NJ",
      state_name: "New Jersey",
    },
    {
      city_id: "2569",
      city: "Raritan",
      zip_code: "08869",
      state_id: "NJ",
      state_name: "New Jersey",
    },
    {
      city_id: "2179",
      city: "Red Bank",
      zip_code: "07701",
      state_id: "NJ",
      state_name: "New Jersey",
    },
    {
      city_id: "2447",
      city: "Richland",
      zip_code: "08350",
      state_id: "NJ",
      state_name: "New Jersey",
    },
    {
      city_id: "2358",
      city: "Richwood",
      zip_code: "08074",
      state_id: "NJ",
      state_name: "New Jersey",
    },
    {
      city_id: "2169",
      city: "Ridgefield",
      zip_code: "07657",
      state_id: "NJ",
      state_name: "New Jersey",
    },
    {
      city_id: "2170",
      city: "Ridgefield Park",
      zip_code: "07660",
      state_id: "NJ",
      state_name: "New Jersey",
    },
    {
      city_id: "2115",
      city: "Ridgewood",
      zip_code: "07450",
      state_id: "NJ",
      state_name: "New Jersey",
    },
    {
      city_id: "2478",
      city: "Ringoes",
      zip_code: "08551",
      state_id: "NJ",
      state_name: "New Jersey",
    },
    {
      city_id: "2117",
      city: "Ringwood",
      zip_code: "07456",
      state_id: "NJ",
      state_name: "New Jersey",
    },
    {
      city_id: "2409",
      city: "Rio Grande",
      zip_code: "08242",
      state_id: "NJ",
      state_name: "New Jersey",
    },
    {
      city_id: "2171",
      city: "River Edge",
      zip_code: "07661",
      state_id: "NJ",
      state_name: "New Jersey",
    },
    {
      city_id: "2118",
      city: "Riverdale",
      zip_code: "07457",
      state_id: "NJ",
      state_name: "New Jersey",
    },
    {
      city_id: "2359",
      city: "Riverside",
      zip_code: "08075",
      state_id: "NJ",
      state_name: "New Jersey",
    },
    {
      city_id: "2360",
      city: "Riverton",
      zip_code: "08077",
      state_id: "NJ",
      state_name: "New Jersey",
    },
    {
      city_id: "2501",
      city: "Robbinsville",
      zip_code: "08691",
      state_id: "NJ",
      state_name: "New Jersey",
    },
    {
      city_id: "2172",
      city: "Rochelle Park",
      zip_code: "07662",
      state_id: "NJ",
      state_name: "New Jersey",
    },
    {
      city_id: "2254",
      city: "Rockaway",
      zip_code: "07866",
      state_id: "NJ",
      state_name: "New Jersey",
    },
    {
      city_id: "2479",
      city: "Rocky Hill",
      zip_code: "08553",
      state_id: "NJ",
      state_name: "New Jersey",
    },
    {
      city_id: "2480",
      city: "Roebling",
      zip_code: "08554",
      state_id: "NJ",
      state_name: "New Jersey",
    },
    {
      city_id: "2481",
      city: "Roosevelt",
      zip_code: "08555",
      state_id: "NJ",
      state_name: "New Jersey",
    },
    {
      city_id: "2041",
      city: "Roseland",
      zip_code: "07068",
      state_id: "NJ",
      state_name: "New Jersey",
    },
    {
      city_id: "2079",
      city: "Roselle",
      zip_code: "07203",
      state_id: "NJ",
      state_name: "New Jersey",
    },
    {
      city_id: "2080",
      city: "Roselle Park",
      zip_code: "07204",
      state_id: "NJ",
      state_name: "New Jersey",
    },
    {
      city_id: "2448",
      city: "Rosenhayn",
      zip_code: "08352",
      state_id: "NJ",
      state_name: "New Jersey",
    },
    {
      city_id: "2217",
      city: "Rumson",
      zip_code: "07760",
      state_id: "NJ",
      state_name: "New Jersey",
    },
    {
      city_id: "2361",
      city: "Runnemede",
      zip_code: "08078",
      state_id: "NJ",
      state_name: "New Jersey",
    },
    {
      city_id: "2043",
      city: "Rutherford",
      zip_code: "07070",
      state_id: "NJ",
      state_name: "New Jersey",
    },
    {
      city_id: "2173",
      city: "Saddle Brook",
      zip_code: "07663",
      state_id: "NJ",
      state_name: "New Jersey",
    },
    {
      city_id: "2119",
      city: "Saddle River",
      zip_code: "07458",
      state_id: "NJ",
      state_name: "New Jersey",
    },
    {
      city_id: "2362",
      city: "Salem",
      zip_code: "08079",
      state_id: "NJ",
      state_name: "New Jersey",
    },
    {
      city_id: "2570",
      city: "Sayreville",
      zip_code: "08872",
      state_id: "NJ",
      state_name: "New Jersey",
    },
    {
      city_id: "2256",
      city: "Schooleys Mountain",
      zip_code: "07870",
      state_id: "NJ",
      state_name: "New Jersey",
    },
    {
      city_id: "2049",
      city: "Scotch Plains",
      zip_code: "07076",
      state_id: "NJ",
      state_name: "New Jersey",
    },
    {
      city_id: "2519",
      city: "Sea Girt",
      zip_code: "08750",
      state_id: "NJ",
      state_name: "New Jersey",
    },
    {
      city_id: "2410",
      city: "Sea Isle City",
      zip_code: "08243",
      state_id: "NJ",
      state_name: "New Jersey",
    },
    {
      city_id: "2520",
      city: "Seaside Heights",
      zip_code: "08751",
      state_id: "NJ",
      state_name: "New Jersey",
    },
    {
      city_id: "2521",
      city: "Seaside Park",
      zip_code: "08752",
      state_id: "NJ",
      state_name: "New Jersey",
    },
    {
      city_id: "2063",
      city: "Secaucus",
      zip_code: "07094",
      state_id: "NJ",
      state_name: "New Jersey",
    },
    {
      city_id: "2050",
      city: "Sewaren",
      zip_code: "07077",
      state_id: "NJ",
      state_name: "New Jersey",
    },
    {
      city_id: "2363",
      city: "Sewell",
      zip_code: "08080",
      state_id: "NJ",
      state_name: "New Jersey",
    },
    {
      city_id: "2449",
      city: "Shiloh",
      zip_code: "08353",
      state_id: "NJ",
      state_name: "New Jersey",
    },
    {
      city_id: "2051",
      city: "Short Hills",
      zip_code: "07078",
      state_id: "NJ",
      state_name: "New Jersey",
    },
    {
      city_id: "2180",
      city: "Shrewsbury",
      zip_code: "07702",
      state_id: "NJ",
      state_name: "New Jersey",
    },
    {
      city_id: "2364",
      city: "Sicklerville",
      zip_code: "08081",
      state_id: "NJ",
      state_name: "New Jersey",
    },
    {
      city_id: "2482",
      city: "Skillman",
      zip_code: "08558",
      state_id: "NJ",
      state_name: "New Jersey",
    },
    {
      city_id: "2365",
      city: "Somerdale",
      zip_code: "08083",
      state_id: "NJ",
      state_name: "New Jersey",
    },
    {
      city_id: "2411",
      city: "Somers Point",
      zip_code: "08244",
      state_id: "NJ",
      state_name: "New Jersey",
    },
    {
      city_id: "2571",
      city: "Somerset",
      zip_code: "08873",
      state_id: "NJ",
      state_name: "New Jersey",
    },
    {
      city_id: "2572",
      city: "Somerville",
      zip_code: "08876",
      state_id: "NJ",
      state_name: "New Jersey",
    },
    {
      city_id: "2573",
      city: "South Amboy",
      zip_code: "08879",
      state_id: "NJ",
      state_name: "New Jersey",
    },
    {
      city_id: "2574",
      city: "South Bound Brook",
      zip_code: "08880",
      state_id: "NJ",
      state_name: "New Jersey",
    },
    {
      city_id: "2412",
      city: "South Dennis",
      zip_code: "08245",
      state_id: "NJ",
      state_name: "New Jersey",
    },
    {
      city_id: "2144",
      city: "South Hackensack",
      zip_code: "07606",
      state_id: "NJ",
      state_name: "New Jersey",
    },
    {
      city_id: "2052",
      city: "South Orange",
      zip_code: "07079",
      state_id: "NJ",
      state_name: "New Jersey",
    },
    {
      city_id: "2053",
      city: "South Plainfield",
      zip_code: "07080",
      state_id: "NJ",
      state_name: "New Jersey",
    },
    {
      city_id: "2575",
      city: "South River",
      zip_code: "08882",
      state_id: "NJ",
      state_name: "New Jersey",
    },
    {
      city_id: "2413",
      city: "South Seaville",
      zip_code: "08246",
      state_id: "NJ",
      state_name: "New Jersey",
    },
    {
      city_id: "2257",
      city: "Sparta",
      zip_code: "07871",
      state_id: "NJ",
      state_name: "New Jersey",
    },
    {
      city_id: "2576",
      city: "Spotswood",
      zip_code: "08884",
      state_id: "NJ",
      state_name: "New Jersey",
    },
    {
      city_id: "2218",
      city: "Spring Lake",
      zip_code: "07762",
      state_id: "NJ",
      state_name: "New Jersey",
    },
    {
      city_id: "2054",
      city: "Springfield",
      zip_code: "07081",
      state_id: "NJ",
      state_name: "New Jersey",
    },
    {
      city_id: "2258",
      city: "Stanhope",
      zip_code: "07874",
      state_id: "NJ",
      state_name: "New Jersey",
    },
    {
      city_id: "2577",
      city: "Stewartsville",
      zip_code: "08886",
      state_id: "NJ",
      state_name: "New Jersey",
    },
    {
      city_id: "2292",
      city: "Stirling",
      zip_code: "07980",
      state_id: "NJ",
      state_name: "New Jersey",
    },
    {
      city_id: "2120",
      city: "Stockholm",
      zip_code: "07460",
      state_id: "NJ",
      state_name: "New Jersey",
    },
    {
      city_id: "2483",
      city: "Stockton",
      zip_code: "08559",
      state_id: "NJ",
      state_name: "New Jersey",
    },
    {
      city_id: "2414",
      city: "Stone Harbor",
      zip_code: "08247",
      state_id: "NJ",
      state_name: "New Jersey",
    },
    {
      city_id: "2366",
      city: "Stratford",
      zip_code: "08084",
      state_id: "NJ",
      state_name: "New Jersey",
    },
    {
      city_id: "2415",
      city: "Strathmere",
      zip_code: "08248",
      state_id: "NJ",
      state_name: "New Jersey",
    },
    {
      city_id: "2259",
      city: "Succasunna",
      zip_code: "07876",
      state_id: "NJ",
      state_name: "New Jersey",
    },
    {
      city_id: "2265",
      city: "Summit",
      zip_code: "07901",
      state_id: "NJ",
      state_name: "New Jersey",
    },
    {
      city_id: "2121",
      city: "Sussex",
      zip_code: "07461",
      state_id: "NJ",
      state_name: "New Jersey",
    },
    {
      city_id: "2367",
      city: "Swedesboro",
      zip_code: "08085",
      state_id: "NJ",
      state_name: "New Jersey",
    },
    {
      city_id: "2174",
      city: "Teaneck",
      zip_code: "07666",
      state_id: "NJ",
      state_name: "New Jersey",
    },
    {
      city_id: "2175",
      city: "Tenafly",
      zip_code: "07670",
      state_id: "NJ",
      state_name: "New Jersey",
    },
    {
      city_id: "2146",
      city: "Teterboro",
      zip_code: "07608",
      state_id: "NJ",
      state_name: "New Jersey",
    },
    {
      city_id: "2368",
      city: "Thorofare",
      zip_code: "08086",
      state_id: "NJ",
      state_name: "New Jersey",
    },
    {
      city_id: "2578",
      city: "Three Bridges",
      zip_code: "08887",
      state_id: "NJ",
      state_name: "New Jersey",
    },
    {
      city_id: "2484",
      city: "Titusville",
      zip_code: "08560",
      state_id: "NJ",
      state_name: "New Jersey",
    },
    {
      city_id: "2522",
      city: "Toms River",
      zip_code: "08753",
      state_id: "NJ",
      state_name: "New Jersey",
    },
    {
      city_id: "2135",
      city: "Totowa",
      zip_code: "07512",
      state_id: "NJ",
      state_name: "New Jersey",
    },
    {
      city_id: "2055",
      city: "Towaco",
      zip_code: "07082",
      state_id: "NJ",
      state_name: "New Jersey",
    },
    {
      city_id: "2177",
      city: "Township Of Washington",
      zip_code: "07676",
      state_id: "NJ",
      state_name: "New Jersey",
    },
    {
      city_id: "2487",
      city: "Trenton",
      zip_code: "08608",
      state_id: "NJ",
      state_name: "New Jersey",
    },
    {
      city_id: "2369",
      city: "Tuckerton",
      zip_code: "08087",
      state_id: "NJ",
      state_name: "New Jersey",
    },
    {
      city_id: "2056",
      city: "Union",
      zip_code: "07083",
      state_id: "NJ",
      state_name: "New Jersey",
    },
    {
      city_id: "2058",
      city: "Union City",
      zip_code: "07087",
      state_id: "NJ",
      state_name: "New Jersey",
    },
    {
      city_id: "2059",
      city: "Vauxhall",
      zip_code: "07088",
      state_id: "NJ",
      state_name: "New Jersey",
    },
    {
      city_id: "2455",
      city: "Ventnor City",
      zip_code: "08406",
      state_id: "NJ",
      state_name: "New Jersey",
    },
    {
      city_id: "2122",
      city: "Vernon",
      zip_code: "07462",
      state_id: "NJ",
      state_name: "New Jersey",
    },
    {
      city_id: "2023",
      city: "Verona",
      zip_code: "07044",
      state_id: "NJ",
      state_name: "New Jersey",
    },
    {
      city_id: "2261",
      city: "Vienna",
      zip_code: "07880",
      state_id: "NJ",
      state_name: "New Jersey",
    },
    {
      city_id: "2416",
      city: "Villas",
      zip_code: "08251",
      state_id: "NJ",
      state_name: "New Jersey",
    },
    {
      city_id: "2370",
      city: "Vincentown",
      zip_code: "08088",
      state_id: "NJ",
      state_name: "New Jersey",
    },
    {
      city_id: "2450",
      city: "Vineland",
      zip_code: "08360",
      state_id: "NJ",
      state_name: "New Jersey",
    },
    {
      city_id: "2330",
      city: "Voorhees",
      zip_code: "08043",
      state_id: "NJ",
      state_name: "New Jersey",
    },
    {
      city_id: "2123",
      city: "Waldwick",
      zip_code: "07463",
      state_id: "NJ",
      state_name: "New Jersey",
    },
    {
      city_id: "2031",
      city: "Wallington",
      zip_code: "07057",
      state_id: "NJ",
      state_name: "New Jersey",
    },
    {
      city_id: "2262",
      city: "Wallpack Center",
      zip_code: "07881",
      state_id: "NJ",
      state_name: "New Jersey",
    },
    {
      city_id: "2124",
      city: "Wanaque",
      zip_code: "07465",
      state_id: "NJ",
      state_name: "New Jersey",
    },
    {
      city_id: "2525",
      city: "Waretown",
      zip_code: "08758",
      state_id: "NJ",
      state_name: "New Jersey",
    },
    {
      city_id: "2033",
      city: "Warren",
      zip_code: "07059",
      state_id: "NJ",
      state_name: "New Jersey",
    },
    {
      city_id: "2263",
      city: "Washington",
      zip_code: "07882",
      state_id: "NJ",
      state_name: "New Jersey",
    },
    {
      city_id: "2042",
      city: "Watchung",
      zip_code: "07069",
      state_id: "NJ",
      state_name: "New Jersey",
    },
    {
      city_id: "2371",
      city: "Waterford Works",
      zip_code: "08089",
      state_id: "NJ",
      state_name: "New Jersey",
    },
    {
      city_id: "2125",
      city: "Wayne",
      zip_code: "07470",
      state_id: "NJ",
      state_name: "New Jersey",
    },
    {
      city_id: "2057",
      city: "Weehawken",
      zip_code: "07086",
      state_id: "NJ",
      state_name: "New Jersey",
    },
    {
      city_id: "2372",
      city: "Wenonah",
      zip_code: "08090",
      state_id: "NJ",
      state_name: "New Jersey",
    },
    {
      city_id: "2373",
      city: "West Berlin",
      zip_code: "08091",
      state_id: "NJ",
      state_name: "New Jersey",
    },
    {
      city_id: "2374",
      city: "West Creek",
      zip_code: "08092",
      state_id: "NJ",
      state_name: "New Jersey",
    },
    {
      city_id: "2219",
      city: "West Long Branch",
      zip_code: "07764",
      state_id: "NJ",
      state_name: "New Jersey",
    },
    {
      city_id: "2126",
      city: "West Milford",
      zip_code: "07480",
      state_id: "NJ",
      state_name: "New Jersey",
    },
    {
      city_id: "2062",
      city: "West New York",
      zip_code: "07093",
      state_id: "NJ",
      state_name: "New Jersey",
    },
    {
      city_id: "2028",
      city: "West Orange",
      zip_code: "07052",
      state_id: "NJ",
      state_name: "New Jersey",
    },
    {
      city_id: "2060",
      city: "Westfield",
      zip_code: "07090",
      state_id: "NJ",
      state_name: "New Jersey",
    },
    {
      city_id: "2375",
      city: "Westville",
      zip_code: "08093",
      state_id: "NJ",
      state_name: "New Jersey",
    },
    {
      city_id: "2176",
      city: "Westwood",
      zip_code: "07675",
      state_id: "NJ",
      state_name: "New Jersey",
    },
    {
      city_id: "2264",
      city: "Wharton",
      zip_code: "07885",
      state_id: "NJ",
      state_name: "New Jersey",
    },
    {
      city_id: "2293",
      city: "Whippany",
      zip_code: "07981",
      state_id: "NJ",
      state_name: "New Jersey",
    },
    {
      city_id: "2579",
      city: "Whitehouse Station",
      zip_code: "08889",
      state_id: "NJ",
      state_name: "New Jersey",
    },
    {
      city_id: "2417",
      city: "Wildwood",
      zip_code: "08260",
      state_id: "NJ",
      state_name: "New Jersey",
    },
    {
      city_id: "2376",
      city: "Williamstown",
      zip_code: "08094",
      state_id: "NJ",
      state_name: "New Jersey",
    },
    {
      city_id: "2332",
      city: "Willingboro",
      zip_code: "08046",
      state_id: "NJ",
      state_name: "New Jersey",
    },
    {
      city_id: "2485",
      city: "Windsor",
      zip_code: "08561",
      state_id: "NJ",
      state_name: "New Jersey",
    },
    {
      city_id: "2377",
      city: "Winslow",
      zip_code: "08095",
      state_id: "NJ",
      state_name: "New Jersey",
    },
    {
      city_id: "2048",
      city: "Wood Ridge",
      zip_code: "07075",
      state_id: "NJ",
      state_name: "New Jersey",
    },
    {
      city_id: "2418",
      city: "Woodbine",
      zip_code: "08270",
      state_id: "NJ",
      state_name: "New Jersey",
    },
    {
      city_id: "2064",
      city: "Woodbridge",
      zip_code: "07095",
      state_id: "NJ",
      state_name: "New Jersey",
    },
    {
      city_id: "2378",
      city: "Woodbury",
      zip_code: "08096",
      state_id: "NJ",
      state_name: "New Jersey",
    },
    {
      city_id: "2379",
      city: "Woodbury Heights",
      zip_code: "08097",
      state_id: "NJ",
      state_name: "New Jersey",
    },
    {
      city_id: "2178",
      city: "Woodcliff Lake",
      zip_code: "07677",
      state_id: "NJ",
      state_name: "New Jersey",
    },
    {
      city_id: "2380",
      city: "Woodstown",
      zip_code: "08098",
      state_id: "NJ",
      state_name: "New Jersey",
    },
    {
      city_id: "2486",
      city: "Wrightstown",
      zip_code: "08562",
      state_id: "NJ",
      state_name: "New Jersey",
    },
    {
      city_id: "2127",
      city: "Wyckoff",
      zip_code: "07481",
      state_id: "NJ",
      state_name: "New Jersey",
    },
    {
      city_id: "2580",
      city: "Zarephath",
      zip_code: "08890",
      state_id: "NJ",
      state_name: "New Jersey",
    },
    {
      city_id: "3267",
      city: "Accord",
      zip_code: "12404",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3268",
      city: "Acra",
      zip_code: "12405",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3846",
      city: "Adams",
      zip_code: "13605",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3847",
      city: "Adams Center",
      zip_code: "13606",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "4329",
      city: "Addison",
      zip_code: "14801",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3476",
      city: "Adirondack",
      zip_code: "12808",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3926",
      city: "Afton",
      zip_code: "13730",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "4011",
      city: "Akron",
      zip_code: "14001",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3246",
      city: "Albany",
      zip_code: "12202",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "2944",
      city: "Albertson",
      zip_code: "11507",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "4141",
      city: "Albion",
      zip_code: "14411",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3112",
      city: "Alcove",
      zip_code: "12007",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "4012",
      city: "Alden",
      zip_code: "14004",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3726",
      city: "Alder Creek",
      zip_code: "13301",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "4013",
      city: "Alexander",
      zip_code: "14005",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3848",
      city: "Alexandria Bay",
      zip_code: "13607",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "4330",
      city: "Alfred",
      zip_code: "14802",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "4331",
      city: "Alfred Station",
      zip_code: "14803",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "4268",
      city: "Allegany",
      zip_code: "14706",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "4269",
      city: "Allentown",
      zip_code: "14707",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "4270",
      city: "Alma",
      zip_code: "14708",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "4332",
      city: "Almond",
      zip_code: "14804",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "4333",
      city: "Alpine",
      zip_code: "14805",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3113",
      city: "Alplaus",
      zip_code: "12008",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3114",
      city: "Altamont",
      zip_code: "12009",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3727",
      city: "Altmar",
      zip_code: "13302",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "4142",
      city: "Alton",
      zip_code: "14413",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3540",
      city: "Altona",
      zip_code: "12910",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3069",
      city: "Amagansett",
      zip_code: "11930",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "2691",
      city: "Amawalk",
      zip_code: "10501",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3348",
      city: "Amenia",
      zip_code: "12501",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "2988",
      city: "Amityville",
      zip_code: "11701",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3115",
      city: "Amsterdam",
      zip_code: "12010",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3349",
      city: "Ancram",
      zip_code: "12502",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3350",
      city: "Ancramdale",
      zip_code: "12503",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3927",
      city: "Andes",
      zip_code: "13731",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "4334",
      city: "Andover",
      zip_code: "14806",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "4271",
      city: "Angelica",
      zip_code: "14709",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "4014",
      city: "Angola",
      zip_code: "14006",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3351",
      city: "Annandale On Hudson",
      zip_code: "12504",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3849",
      city: "Antwerp",
      zip_code: "13608",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3928",
      city: "Apalachin",
      zip_code: "13732",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "4015",
      city: "Appleton",
      zip_code: "14008",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3610",
      city: "Apulia Station",
      zip_code: "13020",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3070",
      city: "Aquebogue",
      zip_code: "11931",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "4016",
      city: "Arcade",
      zip_code: "14009",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "2774",
      city: "Arden",
      zip_code: "10910",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "2692",
      city: "Ardsley",
      zip_code: "10502",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "2693",
      city: "Ardsley On Hudson",
      zip_code: "10503",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3477",
      city: "Argyle",
      zip_code: "12809",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "4335",
      city: "Arkport",
      zip_code: "14807",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3269",
      city: "Arkville",
      zip_code: "12406",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "2694",
      city: "Armonk",
      zip_code: "10504",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "2984",
      city: "Arverne",
      zip_code: "11692",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3270",
      city: "Ashland",
      zip_code: "12407",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "4272",
      city: "Ashville",
      zip_code: "14710",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "2847",
      city: "Astoria",
      zip_code: "11102",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3116",
      city: "Athens",
      zip_code: "12015",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3478",
      city: "Athol",
      zip_code: "12810",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "4336",
      city: "Atlanta",
      zip_code: "14808",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "2945",
      city: "Atlantic Beach",
      zip_code: "11509",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "4017",
      city: "Attica",
      zip_code: "14011",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3542",
      city: "Au Sable Forks",
      zip_code: "12912",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "33787",
      city: "Auburn",
      zip_code: "13021",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3611",
      city: "Aurora",
      zip_code: "13026",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3117",
      city: "Austerlitz",
      zip_code: "12017",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3728",
      city: "Ava",
      zip_code: "13303",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3118",
      city: "Averill Park",
      zip_code: "12018",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "4337",
      city: "Avoca",
      zip_code: "14809",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "4143",
      city: "Avon",
      zip_code: "14414",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "2989",
      city: "Babylon",
      zip_code: "11702",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3929",
      city: "Bainbridge",
      zip_code: "13733",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3479",
      city: "Bakers Mills",
      zip_code: "12811",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "2946",
      city: "Baldwin",
      zip_code: "11510",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "2695",
      city: "Baldwin Place",
      zip_code: "10505",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3612",
      city: "Baldwinsville",
      zip_code: "13027",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3119",
      city: "Ballston Lake",
      zip_code: "12019",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3120",
      city: "Ballston Spa",
      zip_code: "12020",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "4018",
      city: "Barker",
      zip_code: "14012",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3729",
      city: "Barneveld",
      zip_code: "13304",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3352",
      city: "Barrytown",
      zip_code: "12507",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3415",
      city: "Barryville",
      zip_code: "12719",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3930",
      city: "Barton",
      zip_code: "13734",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "4019",
      city: "Basom",
      zip_code: "14013",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "4020",
      city: "Batavia",
      zip_code: "14020",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "4338",
      city: "Bath",
      zip_code: "14810",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "2993",
      city: "Bay Shore",
      zip_code: "11706",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "2992",
      city: "Bayport",
      zip_code: "11705",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "2896",
      city: "Bayside",
      zip_code: "11359",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "2994",
      city: "Bayville",
      zip_code: "11709",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3353",
      city: "Beacon",
      zip_code: "12508",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "2775",
      city: "Bear Mountain",
      zip_code: "10911",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3271",
      city: "Bearsville",
      zip_code: "12409",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "4339",
      city: "Beaver Dams",
      zip_code: "14812",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3730",
      city: "Beaver Falls",
      zip_code: "13305",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "2696",
      city: "Bedford",
      zip_code: "10506",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "2697",
      city: "Bedford Hills",
      zip_code: "10507",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "4273",
      city: "Belfast",
      zip_code: "14711",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "2931",
      city: "Bellerose",
      zip_code: "11426",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3850",
      city: "Belleville",
      zip_code: "13611",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "2995",
      city: "Bellmore",
      zip_code: "11710",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "4144",
      city: "Bellona",
      zip_code: "14415",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "2996",
      city: "Bellport",
      zip_code: "11713",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "4340",
      city: "Belmont",
      zip_code: "14813",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "4274",
      city: "Bemus Point",
      zip_code: "14712",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "4145",
      city: "Bergen",
      zip_code: "14416",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3931",
      city: "Berkshire",
      zip_code: "13736",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3121",
      city: "Berlin",
      zip_code: "12022",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3122",
      city: "Berne",
      zip_code: "12023",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3613",
      city: "Bernhards Bay",
      zip_code: "13028",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3416",
      city: "Bethel",
      zip_code: "12720",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "2997",
      city: "Bethpage",
      zip_code: "11714",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "4341",
      city: "Big Flats",
      zip_code: "14814",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3272",
      city: "Big Indian",
      zip_code: "12410",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "4006",
      city: "Binghamton",
      zip_code: "13901",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "4275",
      city: "Black Creek",
      zip_code: "14714",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3851",
      city: "Black River",
      zip_code: "13612",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "2776",
      city: "Blauvelt",
      zip_code: "10913",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "4021",
      city: "Bliss",
      zip_code: "14024",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3932",
      city: "Blodgett Mills",
      zip_code: "13738",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "4170",
      city: "Bloomfield",
      zip_code: "14469",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "2777",
      city: "Blooming Grove",
      zip_code: "10914",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3417",
      city: "Bloomingburg",
      zip_code: "12721",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3543",
      city: "Bloomingdale",
      zip_code: "12913",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3273",
      city: "Bloomington",
      zip_code: "12411",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3933",
      city: "Bloomville",
      zip_code: "13739",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3731",
      city: "Blossvale",
      zip_code: "13308",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3480",
      city: "Blue Mountain Lake",
      zip_code: "12812",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "2998",
      city: "Blue Point",
      zip_code: "11715",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "2999",
      city: "Bohemia",
      zip_code: "11716",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3274",
      city: "Boiceville",
      zip_code: "12412",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "4276",
      city: "Bolivar",
      zip_code: "14715",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3481",
      city: "Bolton Landing",
      zip_code: "12814",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3544",
      city: "Bombay",
      zip_code: "12914",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3732",
      city: "Boonville",
      zip_code: "13309",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "4022",
      city: "Boston",
      zip_code: "14025",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3733",
      city: "Bouckville",
      zip_code: "13310",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3934",
      city: "Bovina Center",
      zip_code: "13740",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "4023",
      city: "Bowmansville",
      zip_code: "14026",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "4342",
      city: "Bradford",
      zip_code: "14815",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3123",
      city: "Brainard",
      zip_code: "12024",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3545",
      city: "Brainardsville",
      zip_code: "12915",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "4146",
      city: "Branchport",
      zip_code: "14418",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "4024",
      city: "Brant",
      zip_code: "14027",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3482",
      city: "Brant Lake",
      zip_code: "12815",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3734",
      city: "Brantingham",
      zip_code: "13312",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3852",
      city: "Brasher Falls",
      zip_code: "13613",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "4343",
      city: "Breesport",
      zip_code: "14816",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "2987",
      city: "Breezy Point",
      zip_code: "11697",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3000",
      city: "Brentwood",
      zip_code: "11717",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3614",
      city: "Brewerton",
      zip_code: "13029",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "2698",
      city: "Brewster",
      zip_code: "10509",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "2699",
      city: "Briarcliff Manor",
      zip_code: "10510",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3071",
      city: "Bridgehampton",
      zip_code: "11932",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3615",
      city: "Bridgeport",
      zip_code: "13030",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3735",
      city: "Bridgewater",
      zip_code: "13313",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3853",
      city: "Brier Hill",
      zip_code: "13614",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3001",
      city: "Brightwaters",
      zip_code: "11718",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3124",
      city: "Broadalbin",
      zip_code: "12025",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "4147",
      city: "Brockport",
      zip_code: "14420",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "4277",
      city: "Brocton",
      zip_code: "14716",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "2666",
      city: "Bronx",
      zip_code: "10451",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "2766",
      city: "Bronxville",
      zip_code: "10708",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3736",
      city: "Brookfield",
      zip_code: "13314",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3002",
      city: "Brookhaven",
      zip_code: "11719",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "2853",
      city: "Brooklyn",
      zip_code: "11201",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "4344",
      city: "Brooktondale",
      zip_code: "14817",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3854",
      city: "Brownville",
      zip_code: "13615",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3546",
      city: "Brushton",
      zip_code: "12916",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "2700",
      city: "Buchanan",
      zip_code: "10511",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "4107",
      city: "Buffalo",
      zip_code: "14201",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "2778",
      city: "Bullville",
      zip_code: "10915",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "4345",
      city: "Burdett",
      zip_code: "14818",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3547",
      city: "Burke",
      zip_code: "12917",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3418",
      city: "Burlingham",
      zip_code: "12722",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3737",
      city: "Burlington Flats",
      zip_code: "13315",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3125",
      city: "Burnt Hills",
      zip_code: "12027",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "4025",
      city: "Burt",
      zip_code: "14028",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3126",
      city: "Buskirk",
      zip_code: "12028",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "4148",
      city: "Byron",
      zip_code: "14422",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3548",
      city: "Cadyville",
      zip_code: "12918",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3275",
      city: "Cairo",
      zip_code: "12413",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3855",
      city: "Calcium",
      zip_code: "13616",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "4149",
      city: "Caledonia",
      zip_code: "14423",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3419",
      city: "Callicoon",
      zip_code: "12723",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3420",
      city: "Callicoon Center",
      zip_code: "12724",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3072",
      city: "Calverton",
      zip_code: "11933",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "2917",
      city: "Cambria Heights",
      zip_code: "11411",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3483",
      city: "Cambridge",
      zip_code: "12816",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3738",
      city: "Camden",
      zip_code: "13316",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "4346",
      city: "Cameron",
      zip_code: "14819",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "4347",
      city: "Cameron Mills",
      zip_code: "14820",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3616",
      city: "Camillus",
      zip_code: "13031",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "4348",
      city: "Campbell",
      zip_code: "14821",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "2779",
      city: "Campbell Hall",
      zip_code: "10916",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3127",
      city: "Canaan",
      zip_code: "12029",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3739",
      city: "Canajoharie",
      zip_code: "13317",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "4150",
      city: "Canandaigua",
      zip_code: "14424",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "4349",
      city: "Canaseraga",
      zip_code: "14822",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3617",
      city: "Canastota",
      zip_code: "13032",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3935",
      city: "Candor",
      zip_code: "13743",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "4278",
      city: "Caneadea",
      zip_code: "14717",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "4350",
      city: "Canisteo",
      zip_code: "14823",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3856",
      city: "Canton",
      zip_code: "13617",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3857",
      city: "Cape Vincent",
      zip_code: "13618",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "2947",
      city: "Carle Place",
      zip_code: "11514",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3128",
      city: "Carlisle",
      zip_code: "12031",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "2701",
      city: "Carmel",
      zip_code: "10512",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3129",
      city: "Caroga Lake",
      zip_code: "12032",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3858",
      city: "Carthage",
      zip_code: "13619",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "4279",
      city: "Cassadaga",
      zip_code: "14718",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3740",
      city: "Cassville",
      zip_code: "13318",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "4152",
      city: "Castile",
      zip_code: "14427",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3936",
      city: "Castle Creek",
      zip_code: "13744",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3130",
      city: "Castleton On Hudson",
      zip_code: "12033",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3859",
      city: "Castorland",
      zip_code: "13620",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3618",
      city: "Cato",
      zip_code: "13033",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3276",
      city: "Catskill",
      zip_code: "12414",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "4280",
      city: "Cattaraugus",
      zip_code: "14719",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3619",
      city: "Cayuga",
      zip_code: "13034",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "4351",
      city: "Cayuta",
      zip_code: "14824",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3620",
      city: "Cazenovia",
      zip_code: "13035",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "2948",
      city: "Cedarhurst",
      zip_code: "11516",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "4281",
      city: "Celoron",
      zip_code: "14720",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3073",
      city: "Center Moriches",
      zip_code: "11934",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3003",
      city: "Centereach",
      zip_code: "11720",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3004",
      city: "Centerport",
      zip_code: "11721",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "4026",
      city: "Centerville",
      zip_code: "14029",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3131",
      city: "Central Bridge",
      zip_code: "12035",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3005",
      city: "Central Islip",
      zip_code: "11722",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3621",
      city: "Central Square",
      zip_code: "13036",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "2780",
      city: "Central Valley",
      zip_code: "10917",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "4282",
      city: "Ceres",
      zip_code: "14721",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3741",
      city: "Chadwicks",
      zip_code: "13319",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "4027",
      city: "Chaffee",
      zip_code: "14030",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3549",
      city: "Champlain",
      zip_code: "12919",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "2702",
      city: "Chappaqua",
      zip_code: "10514",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3132",
      city: "Charlotteville",
      zip_code: "12036",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3860",
      city: "Chase Mills",
      zip_code: "13621",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3550",
      city: "Chateaugay",
      zip_code: "12920",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3133",
      city: "Chatham",
      zip_code: "12037",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3861",
      city: "Chaumont",
      zip_code: "13622",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "4283",
      city: "Chautauqua",
      zip_code: "14722",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3551",
      city: "Chazy",
      zip_code: "12921",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3354",
      city: "Chelsea",
      zip_code: "12512",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "4352",
      city: "Chemung",
      zip_code: "14825",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3937",
      city: "Chenango Forks",
      zip_code: "13746",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "4284",
      city: "Cherry Creek",
      zip_code: "14723",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3134",
      city: "Cherry Plain",
      zip_code: "12040",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3742",
      city: "Cherry Valley",
      zip_code: "13320",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "2781",
      city: "Chester",
      zip_code: "10918",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3484",
      city: "Chestertown",
      zip_code: "12817",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3277",
      city: "Chichester",
      zip_code: "12416",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3552",
      city: "Childwold",
      zip_code: "12922",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3862",
      city: "Chippewa Bay",
      zip_code: "13623",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3622",
      city: "Chittenango",
      zip_code: "13037",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "4153",
      city: "Churchville",
      zip_code: "14428",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3553",
      city: "Churubusco",
      zip_code: "12923",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3623",
      city: "Cicero",
      zip_code: "13039",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3624",
      city: "Cincinnatus",
      zip_code: "13040",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "2782",
      city: "Circleville",
      zip_code: "10919",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "4028",
      city: "Clarence",
      zip_code: "14031",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "4029",
      city: "Clarence Center",
      zip_code: "14032",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3743",
      city: "Clark Mills",
      zip_code: "13321",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3135",
      city: "Clarksville",
      zip_code: "12041",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3421",
      city: "Claryville",
      zip_code: "12725",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3355",
      city: "Claverack",
      zip_code: "12513",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3625",
      city: "Clay",
      zip_code: "13041",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3863",
      city: "Clayton",
      zip_code: "13624",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3744",
      city: "Clayville",
      zip_code: "13322",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3485",
      city: "Clemons",
      zip_code: "12819",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3626",
      city: "Cleveland",
      zip_code: "13042",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3486",
      city: "Cleverdale",
      zip_code: "12820",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3154",
      city: "Clifton Park",
      zip_code: "12065",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "4154",
      city: "Clifton Springs",
      zip_code: "14432",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3136",
      city: "Climax",
      zip_code: "12042",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3745",
      city: "Clinton",
      zip_code: "13323",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3356",
      city: "Clinton Corners",
      zip_code: "12514",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3357",
      city: "Clintondale",
      zip_code: "12515",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "4155",
      city: "Clyde",
      zip_code: "14433",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "4285",
      city: "Clymer",
      zip_code: "14724",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3137",
      city: "Cobleskill",
      zip_code: "12043",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3422",
      city: "Cochecton",
      zip_code: "12726",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3138",
      city: "Coeymans",
      zip_code: "12045",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3139",
      city: "Coeymans Hollow",
      zip_code: "12046",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "4353",
      city: "Cohocton",
      zip_code: "14826",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3140",
      city: "Cohoes",
      zip_code: "12047",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3746",
      city: "Cold Brook",
      zip_code: "13324",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "2703",
      city: "Cold Spring",
      zip_code: "10516",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3006",
      city: "Cold Spring Harbor",
      zip_code: "11724",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "4030",
      city: "Colden",
      zip_code: "14033",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "2893",
      city: "College Point",
      zip_code: "11356",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3938",
      city: "Colliersville",
      zip_code: "13747",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "4031",
      city: "Collins",
      zip_code: "14034",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "4032",
      city: "Collins Center",
      zip_code: "14035",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3864",
      city: "Colton",
      zip_code: "13625",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3007",
      city: "Commack",
      zip_code: "11725",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3487",
      city: "Comstock",
      zip_code: "12821",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "4156",
      city: "Conesus",
      zip_code: "14435",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "4286",
      city: "Conewango Valley",
      zip_code: "14726",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "2783",
      city: "Congers",
      zip_code: "10920",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3939",
      city: "Conklin",
      zip_code: "13748",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3278",
      city: "Connelly",
      zip_code: "12417",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3555",
      city: "Constable",
      zip_code: "12926",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3747",
      city: "Constableville",
      zip_code: "13325",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3627",
      city: "Constantia",
      zip_code: "13044",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "4354",
      city: "Coopers Plains",
      zip_code: "14827",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3748",
      city: "Cooperstown",
      zip_code: "13326",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3358",
      city: "Copake",
      zip_code: "12516",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3359",
      city: "Copake Falls",
      zip_code: "12517",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3865",
      city: "Copenhagen",
      zip_code: "13626",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3008",
      city: "Copiague",
      zip_code: "11726",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3009",
      city: "Coram",
      zip_code: "11727",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "4033",
      city: "Corfu",
      zip_code: "14036",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3488",
      city: "Corinth",
      zip_code: "12822",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "4355",
      city: "Corning",
      zip_code: "14830",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3360",
      city: "Cornwall",
      zip_code: "12518",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3361",
      city: "Cornwall On Hudson",
      zip_code: "12520",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3279",
      city: "Cornwallville",
      zip_code: "12418",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "2905",
      city: "Corona",
      zip_code: "11368",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3628",
      city: "Cortland",
      zip_code: "13045",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "2736",
      city: "Cortlandt Manor",
      zip_code: "10567",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3489",
      city: "Cossayuna",
      zip_code: "12823",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3280",
      city: "Cottekill",
      zip_code: "12419",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "4034",
      city: "Cowlesville",
      zip_code: "14037",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3141",
      city: "Coxsackie",
      zip_code: "12051",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3281",
      city: "Cragsmoor",
      zip_code: "12420",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3556",
      city: "Cranberry Lake",
      zip_code: "12927",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3362",
      city: "Craryville",
      zip_code: "12521",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3749",
      city: "Croghan",
      zip_code: "13327",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "2704",
      city: "Crompond",
      zip_code: "10517",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3142",
      city: "Cropseyville",
      zip_code: "12052",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "2705",
      city: "Cross River",
      zip_code: "10518",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "2706",
      city: "Croton Falls",
      zip_code: "10519",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "2707",
      city: "Croton On Hudson",
      zip_code: "10520",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3557",
      city: "Crown Point",
      zip_code: "12928",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "4287",
      city: "Cuba",
      zip_code: "14727",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3423",
      city: "Cuddebackville",
      zip_code: "12729",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3074",
      city: "Cutchogue",
      zip_code: "11935",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "4035",
      city: "Dale",
      zip_code: "14039",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "4356",
      city: "Dalton",
      zip_code: "14836",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3558",
      city: "Dannemora",
      zip_code: "12929",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "4157",
      city: "Dansville",
      zip_code: "14437",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "4036",
      city: "Darien Center",
      zip_code: "14040",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3940",
      city: "Davenport",
      zip_code: "13750",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3941",
      city: "Davenport Center",
      zip_code: "13751",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "4037",
      city: "Dayton",
      zip_code: "14041",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3867",
      city: "De Kalb Junction",
      zip_code: "13630",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3869",
      city: "De Peyster",
      zip_code: "13633",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3630",
      city: "De Ruyter",
      zip_code: "13052",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3750",
      city: "Deansboro",
      zip_code: "13328",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3010",
      city: "Deer Park",
      zip_code: "11729",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3866",
      city: "Deferiet",
      zip_code: "13628",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3942",
      city: "Delancey",
      zip_code: "13752",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3143",
      city: "Delanson",
      zip_code: "12053",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "4038",
      city: "Delevan",
      zip_code: "14042",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3943",
      city: "Delhi",
      zip_code: "13753",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3144",
      city: "Delmar",
      zip_code: "12054",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3629",
      city: "Delphi Falls",
      zip_code: "13051",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3282",
      city: "Denver",
      zip_code: "12421",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3868",
      city: "Depauville",
      zip_code: "13632",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "4039",
      city: "Depew",
      zip_code: "14043",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3944",
      city: "Deposit",
      zip_code: "13754",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "4040",
      city: "Derby",
      zip_code: "14047",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "4288",
      city: "Dewittville",
      zip_code: "14728",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3870",
      city: "Dexter",
      zip_code: "13634",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3490",
      city: "Diamond Point",
      zip_code: "12824",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3559",
      city: "Dickinson Center",
      zip_code: "12930",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "2708",
      city: "Dobbs Ferry",
      zip_code: "10522",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3751",
      city: "Dolgeville",
      zip_code: "13329",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3363",
      city: "Dover Plains",
      zip_code: "12522",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3945",
      city: "Downsville",
      zip_code: "13755",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "4158",
      city: "Dresden",
      zip_code: "14441",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3631",
      city: "Dryden",
      zip_code: "13053",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3145",
      city: "Duanesburg",
      zip_code: "12056",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "4357",
      city: "Dundee",
      zip_code: "14837",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "4041",
      city: "Dunkirk",
      zip_code: "14048",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3283",
      city: "Durham",
      zip_code: "12422",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3632",
      city: "Durhamville",
      zip_code: "13054",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3752",
      city: "Eagle Bay",
      zip_code: "13331",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3146",
      city: "Eagle Bridge",
      zip_code: "12057",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3147",
      city: "Earlton",
      zip_code: "12058",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3753",
      city: "Earlville",
      zip_code: "13332",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "4042",
      city: "East Amherst",
      zip_code: "14051",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "4043",
      city: "East Aurora",
      zip_code: "14052",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3148",
      city: "East Berne",
      zip_code: "12059",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "4044",
      city: "East Bethany",
      zip_code: "14054",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3946",
      city: "East Branch",
      zip_code: "13756",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3149",
      city: "East Chatham",
      zip_code: "12060",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "4045",
      city: "East Concord",
      zip_code: "14055",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3284",
      city: "East Durham",
      zip_code: "12423",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "2906",
      city: "East Elmhurst",
      zip_code: "11369",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3150",
      city: "East Greenbush",
      zip_code: "12061",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3075",
      city: "East Hampton",
      zip_code: "11937",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3011",
      city: "East Islip",
      zip_code: "11730",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3285",
      city: "East Jewett",
      zip_code: "12424",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3076",
      city: "East Marion",
      zip_code: "11939",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "2960",
      city: "East Meadow",
      zip_code: "11554",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3947",
      city: "East Meredith",
      zip_code: "13757",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3077",
      city: "East Moriches",
      zip_code: "11940",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3151",
      city: "East Nassau",
      zip_code: "12062",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3012",
      city: "East Northport",
      zip_code: "11731",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3013",
      city: "East Norwich",
      zip_code: "11732",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "4289",
      city: "East Otto",
      zip_code: "14729",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "4046",
      city: "East Pembroke",
      zip_code: "14056",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3079",
      city: "East Quogue",
      zip_code: "11942",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "4159",
      city: "East Rochester",
      zip_code: "14445",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "2949",
      city: "East Rockaway",
      zip_code: "11518",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3152",
      city: "East Schodack",
      zip_code: "12063",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3014",
      city: "East Setauket",
      zip_code: "11733",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3754",
      city: "East Springfield",
      zip_code: "13333",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3633",
      city: "East Syracuse",
      zip_code: "13057",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3153",
      city: "East Worcester",
      zip_code: "12064",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "2767",
      city: "Eastchester",
      zip_code: "10709",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3078",
      city: "Eastport",
      zip_code: "11941",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3755",
      city: "Eaton",
      zip_code: "13334",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "4047",
      city: "Eden",
      zip_code: "14057",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3756",
      city: "Edmeston",
      zip_code: "13335",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3871",
      city: "Edwards",
      zip_code: "13635",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "4048",
      city: "Elba",
      zip_code: "14058",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3634",
      city: "Elbridge",
      zip_code: "13060",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3424",
      city: "Eldred",
      zip_code: "12732",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3560",
      city: "Elizabethtown",
      zip_code: "12932",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3364",
      city: "Elizaville",
      zip_code: "12523",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3286",
      city: "Elka Park",
      zip_code: "12427",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3561",
      city: "Ellenburg",
      zip_code: "12933",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3562",
      city: "Ellenburg Center",
      zip_code: "12934",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3563",
      city: "Ellenburg Depot",
      zip_code: "12935",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3287",
      city: "Ellenville",
      zip_code: "12428",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "4290",
      city: "Ellicottville",
      zip_code: "14731",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "4291",
      city: "Ellington",
      zip_code: "14732",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3872",
      city: "Ellisburg",
      zip_code: "13636",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "4049",
      city: "Elma",
      zip_code: "14059",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "2910",
      city: "Elmhurst",
      zip_code: "11373",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "4403",
      city: "Elmira",
      zip_code: "14901",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "2833",
      city: "Elmont",
      zip_code: "11003",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "2709",
      city: "Elmsford",
      zip_code: "10523",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3948",
      city: "Endicott",
      zip_code: "13760",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3635",
      city: "Erieville",
      zip_code: "13061",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "4358",
      city: "Erin",
      zip_code: "14838",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3288",
      city: "Esopus",
      zip_code: "12429",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3155",
      city: "Esperance",
      zip_code: "12066",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3564",
      city: "Essex",
      zip_code: "12936",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3636",
      city: "Etna",
      zip_code: "13062",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3873",
      city: "Evans Mills",
      zip_code: "13637",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3637",
      city: "Fabius",
      zip_code: "13063",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "4160",
      city: "Fairport",
      zip_code: "14450",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "4292",
      city: "Falconer",
      zip_code: "14733",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3425",
      city: "Fallsburg",
      zip_code: "12733",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "2983",
      city: "Far Rockaway",
      zip_code: "11691",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "4050",
      city: "Farmersville Station",
      zip_code: "14060",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3015",
      city: "Farmingdale",
      zip_code: "11735",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "4151",
      city: "Farmington",
      zip_code: "14425",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3016",
      city: "Farmingville",
      zip_code: "11738",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "4051",
      city: "Farnham",
      zip_code: "14061",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3638",
      city: "Fayette",
      zip_code: "13065",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3639",
      city: "Fayetteville",
      zip_code: "13066",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3874",
      city: "Felts Mills",
      zip_code: "13638",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3426",
      city: "Ferndale",
      zip_code: "12734",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3156",
      city: "Feura Bush",
      zip_code: "12067",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "4293",
      city: "Fillmore",
      zip_code: "14735",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "4294",
      city: "Findley Lake",
      zip_code: "14736",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3875",
      city: "Fine",
      zip_code: "13639",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "4161",
      city: "Fishers",
      zip_code: "14453",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "1840",
      city: "Fishers Island",
      zip_code: "06390",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3877",
      city: "Fishers Landing",
      zip_code: "13641",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3365",
      city: "Fishkill",
      zip_code: "12524",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3949",
      city: "Fishs Eddy",
      zip_code: "13774",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3289",
      city: "Fleischmanns",
      zip_code: "12430",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "2832",
      city: "Floral Park",
      zip_code: "11001",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "2784",
      city: "Florida",
      zip_code: "10921",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "2891",
      city: "Flushing",
      zip_code: "11354",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3757",
      city: "Fly Creek",
      zip_code: "13337",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3157",
      city: "Fonda",
      zip_code: "12068",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "2912",
      city: "Forest Hills",
      zip_code: "11375",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3458",
      city: "Forestburgh",
      zip_code: "12777",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3758",
      city: "Forestport",
      zip_code: "13338",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "4052",
      city: "Forestville",
      zip_code: "14062",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3491",
      city: "Fort Ann",
      zip_code: "12827",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3565",
      city: "Fort Covington",
      zip_code: "12937",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3844",
      city: "Fort Drum",
      zip_code: "13602",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3492",
      city: "Fort Edward",
      zip_code: "12828",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3158",
      city: "Fort Hunter",
      zip_code: "12069",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3159",
      city: "Fort Johnson",
      zip_code: "12070",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "2785",
      city: "Fort Montgomery",
      zip_code: "10922",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3759",
      city: "Fort Plain",
      zip_code: "13339",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3760",
      city: "Frankfort",
      zip_code: "13340",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3950",
      city: "Franklin",
      zip_code: "13775",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "2836",
      city: "Franklin Square",
      zip_code: "11010",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "4295",
      city: "Franklinville",
      zip_code: "14737",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "4053",
      city: "Fredonia",
      zip_code: "14063",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "4054",
      city: "Freedom",
      zip_code: "14065",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3290",
      city: "Freehold",
      zip_code: "12431",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "2950",
      city: "Freeport",
      zip_code: "11520",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3640",
      city: "Freeville",
      zip_code: "13068",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3427",
      city: "Fremont Center",
      zip_code: "12736",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "2902",
      city: "Fresh Meadows",
      zip_code: "11365",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "4296",
      city: "Frewsburg",
      zip_code: "14738",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "4297",
      city: "Friendship",
      zip_code: "14739",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3641",
      city: "Fulton",
      zip_code: "13069",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3160",
      city: "Fultonham",
      zip_code: "12071",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3161",
      city: "Fultonville",
      zip_code: "12072",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3566",
      city: "Gabriels",
      zip_code: "12939",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "4055",
      city: "Gainesville",
      zip_code: "14066",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3162",
      city: "Galway",
      zip_code: "12074",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3493",
      city: "Gansevoort",
      zip_code: "12831",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "2951",
      city: "Garden City",
      zip_code: "11530",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3366",
      city: "Gardiner",
      zip_code: "12525",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "2786",
      city: "Garnerville",
      zip_code: "10923",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3761",
      city: "Garrattsville",
      zip_code: "13342",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "2710",
      city: "Garrison",
      zip_code: "10524",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "4056",
      city: "Gasport",
      zip_code: "14067",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "4162",
      city: "Geneseo",
      zip_code: "14454",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "4163",
      city: "Geneva",
      zip_code: "14456",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3642",
      city: "Genoa",
      zip_code: "13071",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3643",
      city: "Georgetown",
      zip_code: "13072",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3367",
      city: "Germantown",
      zip_code: "12526",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "4298",
      city: "Gerry",
      zip_code: "14740",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "4057",
      city: "Getzville",
      zip_code: "14068",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3163",
      city: "Ghent",
      zip_code: "12075",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3951",
      city: "Gilbertsville",
      zip_code: "13776",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3164",
      city: "Gilboa",
      zip_code: "12076",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3291",
      city: "Glasco",
      zip_code: "12432",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3952",
      city: "Glen Aubrey",
      zip_code: "13777",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "2952",
      city: "Glen Cove",
      zip_code: "11542",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "2953",
      city: "Glen Head",
      zip_code: "11545",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "2834",
      city: "Glen Oaks",
      zip_code: "11004",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3428",
      city: "Glen Spey",
      zip_code: "12737",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3429",
      city: "Glen Wild",
      zip_code: "12738",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3762",
      city: "Glenfield",
      zip_code: "13343",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3292",
      city: "Glenford",
      zip_code: "12433",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3368",
      city: "Glenham",
      zip_code: "12527",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3165",
      city: "Glenmont",
      zip_code: "12077",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3473",
      city: "Glens Falls",
      zip_code: "12801",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "4058",
      city: "Glenwood",
      zip_code: "14069",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "2954",
      city: "Glenwood Landing",
      zip_code: "11547",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3166",
      city: "Gloversville",
      zip_code: "12078",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "2711",
      city: "Goldens Bridge",
      zip_code: "10526",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "2787",
      city: "Goshen",
      zip_code: "10924",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3878",
      city: "Gouverneur",
      zip_code: "13642",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "4059",
      city: "Gowanda",
      zip_code: "14070",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3167",
      city: "Grafton",
      zip_code: "12082",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3430",
      city: "Grahamsville",
      zip_code: "12740",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3293",
      city: "Grand Gorge",
      zip_code: "12434",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "4060",
      city: "Grand Island",
      zip_code: "14072",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "2712",
      city: "Granite Springs",
      zip_code: "10527",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3494",
      city: "Granville",
      zip_code: "12832",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3879",
      city: "Great Bend",
      zip_code: "13643",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "2837",
      city: "Great Neck",
      zip_code: "11020",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3017",
      city: "Great River",
      zip_code: "11739",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "4299",
      city: "Great Valley",
      zip_code: "14741",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3953",
      city: "Greene",
      zip_code: "13778",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3495",
      city: "Greenfield Center",
      zip_code: "12833",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3294",
      city: "Greenfield Park",
      zip_code: "12435",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "4300",
      city: "Greenhurst",
      zip_code: "14742",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3018",
      city: "Greenlawn",
      zip_code: "11740",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3080",
      city: "Greenport",
      zip_code: "11944",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "2955",
      city: "Greenvale",
      zip_code: "11548",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3168",
      city: "Greenville",
      zip_code: "12083",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3496",
      city: "Greenwich",
      zip_code: "12834",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "4359",
      city: "Greenwood",
      zip_code: "14839",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "2788",
      city: "Greenwood Lake",
      zip_code: "10925",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3763",
      city: "Greig",
      zip_code: "13345",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3644",
      city: "Groton",
      zip_code: "13073",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "4164",
      city: "Groveland",
      zip_code: "14462",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3169",
      city: "Guilderland",
      zip_code: "12084",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3170",
      city: "Guilderland Center",
      zip_code: "12085",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3954",
      city: "Guilford",
      zip_code: "13780",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3497",
      city: "Hadley",
      zip_code: "12835",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3171",
      city: "Hagaman",
      zip_code: "12086",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3498",
      city: "Hague",
      zip_code: "12836",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3880",
      city: "Hailesboro",
      zip_code: "13645",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3295",
      city: "Haines Falls",
      zip_code: "12436",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3296",
      city: "Halcottsville",
      zip_code: "12438",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "4165",
      city: "Hall",
      zip_code: "14463",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "4061",
      city: "Hamburg",
      zip_code: "14075",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3955",
      city: "Hamden",
      zip_code: "13782",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3764",
      city: "Hamilton",
      zip_code: "13346",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "4166",
      city: "Hamlin",
      zip_code: "14464",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3881",
      city: "Hammond",
      zip_code: "13646",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "4360",
      city: "Hammondsport",
      zip_code: "14840",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3499",
      city: "Hampton",
      zip_code: "12837",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3081",
      city: "Hampton Bays",
      zip_code: "11946",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3956",
      city: "Hancock",
      zip_code: "13783",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3431",
      city: "Hankins",
      zip_code: "12741",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3172",
      city: "Hannacroix",
      zip_code: "12087",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3882",
      city: "Hannawa Falls",
      zip_code: "13647",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3645",
      city: "Hannibal",
      zip_code: "13074",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3957",
      city: "Harford",
      zip_code: "13784",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3958",
      city: "Harpersfield",
      zip_code: "13786",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3959",
      city: "Harpursville",
      zip_code: "13787",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "2789",
      city: "Harriman",
      zip_code: "10926",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3432",
      city: "Harris",
      zip_code: "12742",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "2713",
      city: "Harrison",
      zip_code: "10528",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3883",
      city: "Harrisville",
      zip_code: "13648",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3500",
      city: "Hartford",
      zip_code: "12838",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "2714",
      city: "Hartsdale",
      zip_code: "10530",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3765",
      city: "Hartwick",
      zip_code: "13348",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3646",
      city: "Hastings",
      zip_code: "13076",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "2764",
      city: "Hastings On Hudson",
      zip_code: "10706",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3054",
      city: "Hauppauge",
      zip_code: "11788",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "2790",
      city: "Haverstraw",
      zip_code: "10927",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "2715",
      city: "Hawthorne",
      zip_code: "10532",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "4361",
      city: "Hector",
      zip_code: "14841",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3884",
      city: "Helena",
      zip_code: "13649",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "4167",
      city: "Hemlock",
      zip_code: "14466",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "2956",
      city: "Hempstead",
      zip_code: "11549",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3885",
      city: "Henderson",
      zip_code: "13650",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3886",
      city: "Henderson Harbor",
      zip_code: "13651",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "4168",
      city: "Henrietta",
      zip_code: "14467",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3297",
      city: "Hensonville",
      zip_code: "12439",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3766",
      city: "Herkimer",
      zip_code: "13350",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3887",
      city: "Hermon",
      zip_code: "13652",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3888",
      city: "Heuvelton",
      zip_code: "13654",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "2962",
      city: "Hewlett",
      zip_code: "11557",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3065",
      city: "Hicksville",
      zip_code: "11801",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3298",
      city: "High Falls",
      zip_code: "12440",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3369",
      city: "Highland",
      zip_code: "12528",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "2791",
      city: "Highland Falls",
      zip_code: "10928",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3433",
      city: "Highland Lake",
      zip_code: "12743",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "2792",
      city: "Highland Mills",
      zip_code: "10930",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3299",
      city: "Highmount",
      zip_code: "12441",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "2793",
      city: "Hillburn",
      zip_code: "10931",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3370",
      city: "Hillsdale",
      zip_code: "12529",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "4169",
      city: "Hilton",
      zip_code: "14468",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "4362",
      city: "Himrod",
      zip_code: "14842",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3767",
      city: "Hinckley",
      zip_code: "13352",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "4301",
      city: "Hinsdale",
      zip_code: "14743",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3960",
      city: "Hobart",
      zip_code: "13788",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3768",
      city: "Hoffmeister",
      zip_code: "13353",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3889",
      city: "Hogansburg",
      zip_code: "13655",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3019",
      city: "Holbrook",
      zip_code: "11741",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "4062",
      city: "Holland",
      zip_code: "14080",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3769",
      city: "Holland Patent",
      zip_code: "13354",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "4171",
      city: "Holley",
      zip_code: "14470",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "2929",
      city: "Hollis",
      zip_code: "11423",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3371",
      city: "Hollowville",
      zip_code: "12530",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3372",
      city: "Holmes",
      zip_code: "12531",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3020",
      city: "Holtsville",
      zip_code: "11742",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3647",
      city: "Homer",
      zip_code: "13077",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "4172",
      city: "Honeoye",
      zip_code: "14471",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "4173",
      city: "Honeoye Falls",
      zip_code: "14472",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3173",
      city: "Hoosick Falls",
      zip_code: "12090",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3373",
      city: "Hopewell Junction",
      zip_code: "12533",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "4363",
      city: "Hornell",
      zip_code: "14843",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "4364",
      city: "Horseheads",
      zip_code: "14845",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3434",
      city: "Hortonville",
      zip_code: "12745",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "4302",
      city: "Houghton",
      zip_code: "14744",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "2920",
      city: "Howard Beach",
      zip_code: "11414",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "2794",
      city: "Howells",
      zip_code: "10932",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3174",
      city: "Howes Cave",
      zip_code: "12092",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3770",
      city: "Hubbardsville",
      zip_code: "13355",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3374",
      city: "Hudson",
      zip_code: "12534",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3501",
      city: "Hudson Falls",
      zip_code: "12839",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3435",
      city: "Huguenot",
      zip_code: "12746",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3502",
      city: "Huletts Landing",
      zip_code: "12841",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "4365",
      city: "Hunt",
      zip_code: "14846",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3300",
      city: "Hunter",
      zip_code: "12442",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3021",
      city: "Huntington",
      zip_code: "11743",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3022",
      city: "Huntington Station",
      zip_code: "11746",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3301",
      city: "Hurley",
      zip_code: "12443",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3436",
      city: "Hurleyville",
      zip_code: "12747",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3375",
      city: "Hyde Park",
      zip_code: "12538",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3771",
      city: "Ilion",
      zip_code: "13357",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3503",
      city: "Indian Lake",
      zip_code: "12842",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3772",
      city: "Inlet",
      zip_code: "13360",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "4366",
      city: "Interlaken",
      zip_code: "14847",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "2845",
      city: "Inwood",
      zip_code: "11096",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "4174",
      city: "Ionia",
      zip_code: "14475",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "4063",
      city: "Irving",
      zip_code: "14081",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "2716",
      city: "Irvington",
      zip_code: "10533",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "2963",
      city: "Island Park",
      zip_code: "11558",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3024",
      city: "Islandia",
      zip_code: "11749",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3025",
      city: "Islip",
      zip_code: "11751",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3026",
      city: "Islip Terrace",
      zip_code: "11752",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "4367",
      city: "Ithaca",
      zip_code: "14850",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "2909",
      city: "Jackson Heights",
      zip_code: "11372",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "4369",
      city: "Jacksonville",
      zip_code: "14854",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "2930",
      city: "Jamaica",
      zip_code: "11424",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3082",
      city: "Jamesport",
      zip_code: "11947",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "4267",
      city: "Jamestown",
      zip_code: "14701",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3648",
      city: "Jamesville",
      zip_code: "13078",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "4370",
      city: "Jasper",
      zip_code: "14855",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "4064",
      city: "Java Center",
      zip_code: "14082",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "4065",
      city: "Java Village",
      zip_code: "14083",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3567",
      city: "Jay",
      zip_code: "12941",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3175",
      city: "Jefferson",
      zip_code: "12093",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "2717",
      city: "Jefferson Valley",
      zip_code: "10535",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3437",
      city: "Jeffersonville",
      zip_code: "12748",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3027",
      city: "Jericho",
      zip_code: "11753",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3302",
      city: "Jewett",
      zip_code: "12444",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3504",
      city: "Johnsburg",
      zip_code: "12843",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "2795",
      city: "Johnson",
      zip_code: "10933",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3961",
      city: "Johnson City",
      zip_code: "13790",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3176",
      city: "Johnsonville",
      zip_code: "12094",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3177",
      city: "Johnstown",
      zip_code: "12095",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3649",
      city: "Jordan",
      zip_code: "13080",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3773",
      city: "Jordanville",
      zip_code: "13361",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "4371",
      city: "Kanona",
      zip_code: "14856",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "2718",
      city: "Katonah",
      zip_code: "10536",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3505",
      city: "Kattskill Bay",
      zip_code: "12844",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3438",
      city: "Kauneonga Lake",
      zip_code: "12749",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3568",
      city: "Keene",
      zip_code: "12942",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3569",
      city: "Keene Valley",
      zip_code: "12943",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3541",
      city: "Keeseville",
      zip_code: "12911",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "4175",
      city: "Kendall",
      zip_code: "14476",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "4303",
      city: "Kennedy",
      zip_code: "14747",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3439",
      city: "Kenoza Lake",
      zip_code: "12750",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "4176",
      city: "Kent",
      zip_code: "14477",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3303",
      city: "Kerhonkson",
      zip_code: "12446",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "4177",
      city: "Keuka Park",
      zip_code: "14478",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "2921",
      city: "Kew Gardens",
      zip_code: "11415",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3440",
      city: "Kiamesha Lake",
      zip_code: "12751",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "4304",
      city: "Kill Buck",
      zip_code: "14748",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3178",
      city: "Kinderhook",
      zip_code: "12106",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3650",
      city: "King Ferry",
      zip_code: "13081",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3028",
      city: "Kings Park",
      zip_code: "11754",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3266",
      city: "Kingston",
      zip_code: "12401",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3651",
      city: "Kirkville",
      zip_code: "13082",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3962",
      city: "Kirkwood",
      zip_code: "13795",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "4178",
      city: "Knowlesville",
      zip_code: "14479",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3890",
      city: "La Fargeville",
      zip_code: "13656",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3653",
      city: "La Fayette",
      zip_code: "13084",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3652",
      city: "Lacona",
      zip_code: "13083",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3376",
      city: "Lagrangeville",
      zip_code: "12540",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3571",
      city: "Lake Clear",
      zip_code: "12945",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3506",
      city: "Lake George",
      zip_code: "12845",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3029",
      city: "Lake Grove",
      zip_code: "11755",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3304",
      city: "Lake Hill",
      zip_code: "12448",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3441",
      city: "Lake Huntington",
      zip_code: "12752",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3305",
      city: "Lake Katrine",
      zip_code: "12449",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3507",
      city: "Lake Luzerne",
      zip_code: "12846",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "2719",
      city: "Lake Peekskill",
      zip_code: "10537",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3572",
      city: "Lake Placid",
      zip_code: "12946",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3179",
      city: "Lake Pleasant",
      zip_code: "12108",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "4066",
      city: "Lake View",
      zip_code: "14085",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "4179",
      city: "Lakeville",
      zip_code: "14480",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "4305",
      city: "Lakewood",
      zip_code: "14750",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "4067",
      city: "Lancaster",
      zip_code: "14086",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3306",
      city: "Lanesville",
      zip_code: "12450",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "4390",
      city: "Lansing",
      zip_code: "14882",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "2720",
      city: "Larchmont",
      zip_code: "10538",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3180",
      city: "Latham",
      zip_code: "12110",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3083",
      city: "Laurel",
      zip_code: "11948",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3963",
      city: "Laurens",
      zip_code: "13796",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "2964",
      city: "Lawrence",
      zip_code: "11559",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "4068",
      city: "Lawtons",
      zip_code: "14091",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "4181",
      city: "Le Roy",
      zip_code: "14482",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3774",
      city: "Lee Center",
      zip_code: "13363",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3307",
      city: "Leeds",
      zip_code: "12451",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "4180",
      city: "Leicester",
      zip_code: "14481",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3775",
      city: "Leonardsville",
      zip_code: "13364",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3030",
      city: "Levittown",
      zip_code: "11756",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3573",
      city: "Lewis",
      zip_code: "12950",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "4069",
      city: "Lewiston",
      zip_code: "14092",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3308",
      city: "Lexington",
      zip_code: "12452",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3442",
      city: "Liberty",
      zip_code: "12754",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "4306",
      city: "Lily Dale",
      zip_code: "14752",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "4182",
      city: "Lima",
      zip_code: "14485",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "4307",
      city: "Limestone",
      zip_code: "14753",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "2721",
      city: "Lincolndale",
      zip_code: "10540",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3031",
      city: "Lindenhurst",
      zip_code: "11757",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "4372",
      city: "Lindley",
      zip_code: "14858",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "4183",
      city: "Linwood",
      zip_code: "14486",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3891",
      city: "Lisbon",
      zip_code: "13658",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3964",
      city: "Lisle",
      zip_code: "13797",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3776",
      city: "Little Falls",
      zip_code: "13365",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "4308",
      city: "Little Genesee",
      zip_code: "14754",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "2899",
      city: "Little Neck",
      zip_code: "11362",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "4309",
      city: "Little Valley",
      zip_code: "14755",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3654",
      city: "Little York",
      zip_code: "13087",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3655",
      city: "Liverpool",
      zip_code: "13088",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3443",
      city: "Livingston Manor",
      zip_code: "12758",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "4184",
      city: "Livonia",
      zip_code: "14487",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3444",
      city: "Loch Sheldrake",
      zip_code: "12759",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3657",
      city: "Locke",
      zip_code: "13092",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "4070",
      city: "Lockport",
      zip_code: "14094",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "4373",
      city: "Lockwood",
      zip_code: "14859",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "2965",
      city: "Locust Valley",
      zip_code: "11560",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "4374",
      city: "Lodi",
      zip_code: "14860",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "2966",
      city: "Long Beach",
      zip_code: "11561",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3445",
      city: "Long Eddy",
      zip_code: "12760",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "2846",
      city: "Long Island City",
      zip_code: "11101",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3508",
      city: "Long Lake",
      zip_code: "12847",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3892",
      city: "Lorraine",
      zip_code: "13659",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "4375",
      city: "Lowman",
      zip_code: "14861",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3777",
      city: "Lowville",
      zip_code: "13367",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "2967",
      city: "Lynbrook",
      zip_code: "11563",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "4071",
      city: "Lyndonville",
      zip_code: "14098",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3574",
      city: "Lyon Mountain",
      zip_code: "12952",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "4185",
      city: "Lyons",
      zip_code: "14489",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3778",
      city: "Lyons Falls",
      zip_code: "13368",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "4186",
      city: "Macedon",
      zip_code: "14502",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "4072",
      city: "Machias",
      zip_code: "14101",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3779",
      city: "Madison",
      zip_code: "13402",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3893",
      city: "Madrid",
      zip_code: "13660",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "2722",
      city: "Mahopac",
      zip_code: "10541",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "2723",
      city: "Mahopac Falls",
      zip_code: "10542",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3966",
      city: "Maine",
      zip_code: "13802",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3181",
      city: "Malden Bridge",
      zip_code: "12115",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3309",
      city: "Malden On Hudson",
      zip_code: "12453",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3660",
      city: "Mallory",
      zip_code: "13103",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3575",
      city: "Malone",
      zip_code: "12953",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "2968",
      city: "Malverne",
      zip_code: "11565",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "2724",
      city: "Mamaroneck",
      zip_code: "10543",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "4187",
      city: "Manchester",
      zip_code: "14504",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "2841",
      city: "Manhasset",
      zip_code: "11030",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3661",
      city: "Manlius",
      zip_code: "13104",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3894",
      city: "Mannsville",
      zip_code: "13661",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3084",
      city: "Manorville",
      zip_code: "11949",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "4310",
      city: "Maple Springs",
      zip_code: "14756",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3310",
      city: "Maplecrest",
      zip_code: "12454",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3967",
      city: "Marathon",
      zip_code: "13803",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3662",
      city: "Marcellus",
      zip_code: "13108",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3780",
      city: "Marcy",
      zip_code: "13403",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3311",
      city: "Margaretville",
      zip_code: "12455",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3663",
      city: "Marietta",
      zip_code: "13110",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "4073",
      city: "Marilla",
      zip_code: "14102",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "4188",
      city: "Marion",
      zip_code: "14505",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3377",
      city: "Marlboro",
      zip_code: "12542",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3781",
      city: "Martinsburg",
      zip_code: "13404",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3664",
      city: "Martville",
      zip_code: "13111",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "2725",
      city: "Maryknoll",
      zip_code: "10545",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3182",
      city: "Maryland",
      zip_code: "12116",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3968",
      city: "Masonville",
      zip_code: "13804",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "2914",
      city: "Maspeth",
      zip_code: "11378",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3032",
      city: "Massapequa",
      zip_code: "11758",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3033",
      city: "Massapequa Park",
      zip_code: "11762",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3895",
      city: "Massena",
      zip_code: "13662",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3085",
      city: "Mastic",
      zip_code: "11950",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3086",
      city: "Mastic Beach",
      zip_code: "11951",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3087",
      city: "Mattituck",
      zip_code: "11952",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3378",
      city: "Maybrook",
      zip_code: "12543",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3183",
      city: "Mayfield",
      zip_code: "12117",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "4311",
      city: "Mayville",
      zip_code: "14757",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3965",
      city: "McDonough",
      zip_code: "13801",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3658",
      city: "McGraw",
      zip_code: "13101",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3659",
      city: "McLean",
      zip_code: "13102",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3184",
      city: "Mechanicville",
      zip_code: "12118",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3034",
      city: "Medford",
      zip_code: "11763",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "4074",
      city: "Medina",
      zip_code: "14103",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3185",
      city: "Medusa",
      zip_code: "12120",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3379",
      city: "Mellenville",
      zip_code: "12544",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3186",
      city: "Melrose",
      zip_code: "12121",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3023",
      city: "Melville",
      zip_code: "11747",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3665",
      city: "Memphis",
      zip_code: "13112",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "4189",
      city: "Mendon",
      zip_code: "14506",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3969",
      city: "Meridale",
      zip_code: "13806",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3666",
      city: "Meridian",
      zip_code: "13113",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "2969",
      city: "Merrick",
      zip_code: "11566",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3667",
      city: "Mexico",
      zip_code: "13114",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3509",
      city: "Middle Granville",
      zip_code: "12849",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3510",
      city: "Middle Grove",
      zip_code: "12850",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3088",
      city: "Middle Island",
      zip_code: "11953",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "2915",
      city: "Middle Village",
      zip_code: "11379",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3187",
      city: "Middleburgh",
      zip_code: "12122",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "4075",
      city: "Middleport",
      zip_code: "14105",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "4190",
      city: "Middlesex",
      zip_code: "14507",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "2796",
      city: "Middletown",
      zip_code: "10940",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3782",
      city: "Middleville",
      zip_code: "13406",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3970",
      city: "Milford",
      zip_code: "13807",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3036",
      city: "Mill Neck",
      zip_code: "11765",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3380",
      city: "Millbrook",
      zip_code: "12545",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3035",
      city: "Miller Place",
      zip_code: "11764",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3381",
      city: "Millerton",
      zip_code: "12546",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "4376",
      city: "Millport",
      zip_code: "14864",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "2726",
      city: "Millwood",
      zip_code: "10546",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3382",
      city: "Milton",
      zip_code: "12547",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "2943",
      city: "Mineola",
      zip_code: "11501",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3511",
      city: "Minerva",
      zip_code: "12851",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3668",
      city: "Minetto",
      zip_code: "13115",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3577",
      city: "Mineville",
      zip_code: "12956",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3669",
      city: "Minoa",
      zip_code: "13116",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3383",
      city: "Modena",
      zip_code: "12548",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3783",
      city: "Mohawk",
      zip_code: "13407",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "2727",
      city: "Mohegan Lake",
      zip_code: "10547",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3578",
      city: "Moira",
      zip_code: "12957",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3446",
      city: "Mongaup Valley",
      zip_code: "12762",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "2798",
      city: "Monroe",
      zip_code: "10950",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "2799",
      city: "Monsey",
      zip_code: "10952",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3089",
      city: "Montauk",
      zip_code: "11954",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3670",
      city: "Montezuma",
      zip_code: "13117",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3384",
      city: "Montgomery",
      zip_code: "12549",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3414",
      city: "Monticello",
      zip_code: "12701",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "4377",
      city: "Montour Falls",
      zip_code: "14865",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "2728",
      city: "Montrose",
      zip_code: "10548",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3579",
      city: "Mooers",
      zip_code: "12958",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3580",
      city: "Mooers Forks",
      zip_code: "12959",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3671",
      city: "Moravia",
      zip_code: "13118",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3581",
      city: "Moriah",
      zip_code: "12960",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3582",
      city: "Moriah Center",
      zip_code: "12961",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3090",
      city: "Moriches",
      zip_code: "11955",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3971",
      city: "Morris",
      zip_code: "13808",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3583",
      city: "Morrisonville",
      zip_code: "12962",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3896",
      city: "Morristown",
      zip_code: "13664",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3784",
      city: "Morrisville",
      zip_code: "13408",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3672",
      city: "Mottville",
      zip_code: "13119",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "2729",
      city: "Mount Kisco",
      zip_code: "10549",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3312",
      city: "Mount Marion",
      zip_code: "12456",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "4191",
      city: "Mount Morris",
      zip_code: "14510",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3037",
      city: "Mount Sinai",
      zip_code: "11766",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3313",
      city: "Mount Tremper",
      zip_code: "12457",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3972",
      city: "Mount Upton",
      zip_code: "13809",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "2730",
      city: "Mount Vernon",
      zip_code: "10550",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3973",
      city: "Mount Vision",
      zip_code: "13810",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3447",
      city: "Mountain Dale",
      zip_code: "12763",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "2800",
      city: "Mountainville",
      zip_code: "10953",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "4192",
      city: "Mumford",
      zip_code: "14511",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3785",
      city: "Munnsville",
      zip_code: "13409",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "2801",
      city: "Nanuet",
      zip_code: "10954",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3314",
      city: "Napanoch",
      zip_code: "12458",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "4193",
      city: "Naples",
      zip_code: "14512",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3448",
      city: "Narrowsburg",
      zip_code: "12764",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3188",
      city: "Nassau",
      zip_code: "12123",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3897",
      city: "Natural Bridge",
      zip_code: "13665",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3673",
      city: "Nedrow",
      zip_code: "13120",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3786",
      city: "Nelliston",
      zip_code: "13410",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3038",
      city: "Nesconset",
      zip_code: "11767",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3449",
      city: "Neversink",
      zip_code: "12765",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3189",
      city: "New Baltimore",
      zip_code: "12124",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3787",
      city: "New Berlin",
      zip_code: "13411",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "2802",
      city: "New City",
      zip_code: "10956",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "2803",
      city: "New Hampton",
      zip_code: "10958",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3788",
      city: "New Hartford",
      zip_code: "13413",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3674",
      city: "New Haven",
      zip_code: "13121",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "2842",
      city: "New Hyde Park",
      zip_code: "11040",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3315",
      city: "New Kingston",
      zip_code: "12459",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3190",
      city: "New Lebanon",
      zip_code: "12125",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3789",
      city: "New Lisbon",
      zip_code: "13415",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3387",
      city: "New Paltz",
      zip_code: "12561",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "2769",
      city: "New Rochelle",
      zip_code: "10801",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3584",
      city: "New Russia",
      zip_code: "12964",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3091",
      city: "New Suffolk",
      zip_code: "11956",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3386",
      city: "New Windsor",
      zip_code: "12553",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3675",
      city: "New Woodstock",
      zip_code: "13122",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "2584",
      city: "New York",
      zip_code: "10001",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3791",
      city: "New York Mills",
      zip_code: "13417",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "4194",
      city: "Newark",
      zip_code: "14513",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3974",
      city: "Newark Valley",
      zip_code: "13811",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3385",
      city: "Newburgh",
      zip_code: "12550",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3512",
      city: "Newcomb",
      zip_code: "12852",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "4076",
      city: "Newfane",
      zip_code: "14108",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "4378",
      city: "Newfield",
      zip_code: "14867",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3790",
      city: "Newport",
      zip_code: "13416",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3898",
      city: "Newton Falls",
      zip_code: "13666",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "4136",
      city: "Niagara Falls",
      zip_code: "14301",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "4077",
      city: "Niagara University",
      zip_code: "14109",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3975",
      city: "Nichols",
      zip_code: "13812",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3585",
      city: "Nicholville",
      zip_code: "12965",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3976",
      city: "Nineveh",
      zip_code: "13813",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3191",
      city: "Niverville",
      zip_code: "12130",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3899",
      city: "Norfolk",
      zip_code: "13667",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "2990",
      city: "North Babylon",
      zip_code: "11703",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3586",
      city: "North Bangor",
      zip_code: "12966",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3676",
      city: "North Bay",
      zip_code: "13123",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3192",
      city: "North Blenheim",
      zip_code: "12131",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3450",
      city: "North Branch",
      zip_code: "12766",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3792",
      city: "North Brookfield",
      zip_code: "13418",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3193",
      city: "North Chatham",
      zip_code: "12132",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "4195",
      city: "North Chili",
      zip_code: "14514",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "4078",
      city: "North Collins",
      zip_code: "14111",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3513",
      city: "North Creek",
      zip_code: "12853",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "4079",
      city: "North Evans",
      zip_code: "14112",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3514",
      city: "North Granville",
      zip_code: "12854",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "4196",
      city: "North Greece",
      zip_code: "14515",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3515",
      city: "North Hudson",
      zip_code: "12855",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "4080",
      city: "North Java",
      zip_code: "14113",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3587",
      city: "North Lawrence",
      zip_code: "12967",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3977",
      city: "North Norwich",
      zip_code: "13814",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3677",
      city: "North Pitcher",
      zip_code: "13124",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3516",
      city: "North River",
      zip_code: "12856",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "4197",
      city: "North Rose",
      zip_code: "14516",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "2733",
      city: "North Salem",
      zip_code: "10560",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "4081",
      city: "North Tonawanda",
      zip_code: "14120",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3039",
      city: "Northport",
      zip_code: "11768",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3194",
      city: "Northville",
      zip_code: "12134",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3978",
      city: "Norwich",
      zip_code: "13815",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3900",
      city: "Norwood",
      zip_code: "13668",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "4198",
      city: "Nunda",
      zip_code: "14517",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "2804",
      city: "Nyack",
      zip_code: "10960",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3316",
      city: "Oak Hill",
      zip_code: "12460",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3040",
      city: "Oakdale",
      zip_code: "11769",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "4082",
      city: "Oakfield",
      zip_code: "14125",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "2901",
      city: "Oakland Gardens",
      zip_code: "11364",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3451",
      city: "Obernburg",
      zip_code: "12767",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3041",
      city: "Ocean Beach",
      zip_code: "11770",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "2973",
      city: "Oceanside",
      zip_code: "11572",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "4379",
      city: "Odessa",
      zip_code: "14869",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3901",
      city: "Ogdensburg",
      zip_code: "13669",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "4083",
      city: "Olcott",
      zip_code: "14126",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3067",
      city: "Old Bethpage",
      zip_code: "11804",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3195",
      city: "Old Chatham",
      zip_code: "12136",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3793",
      city: "Old Forge",
      zip_code: "13420",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "2970",
      city: "Old Westbury",
      zip_code: "11568",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "4312",
      city: "Olean",
      zip_code: "14760",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3317",
      city: "Olivebridge",
      zip_code: "12461",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3517",
      city: "Olmstedville",
      zip_code: "12857",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3794",
      city: "Oneida",
      zip_code: "13421",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3979",
      city: "Oneonta",
      zip_code: "13820",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "4199",
      city: "Ontario",
      zip_code: "14519",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "2805",
      city: "Orangeburg",
      zip_code: "10962",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "4084",
      city: "Orchard Park",
      zip_code: "14127",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3092",
      city: "Orient",
      zip_code: "11957",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3795",
      city: "Oriskany",
      zip_code: "13424",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3796",
      city: "Oriskany Falls",
      zip_code: "13425",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "2734",
      city: "Ossining",
      zip_code: "10562",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3902",
      city: "Oswegatchie",
      zip_code: "13670",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3678",
      city: "Oswego",
      zip_code: "13126",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3980",
      city: "Otego",
      zip_code: "13825",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "2806",
      city: "Otisville",
      zip_code: "10963",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3981",
      city: "Ouaquaga",
      zip_code: "13826",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "4200",
      city: "Ovid",
      zip_code: "14521",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3982",
      city: "Owego",
      zip_code: "13827",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3588",
      city: "Owls Head",
      zip_code: "12969",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3983",
      city: "Oxford",
      zip_code: "13830",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3042",
      city: "Oyster Bay",
      zip_code: "11771",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "2922",
      city: "Ozone Park",
      zip_code: "11416",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "4380",
      city: "Painted Post",
      zip_code: "14870",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3797",
      city: "Palatine Bridge",
      zip_code: "13428",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3318",
      city: "Palenville",
      zip_code: "12463",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "2807",
      city: "Palisades",
      zip_code: "10964",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "4201",
      city: "Palmyra",
      zip_code: "14522",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "4313",
      city: "Panama",
      zip_code: "14767",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3518",
      city: "Paradox",
      zip_code: "12858",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3679",
      city: "Parish",
      zip_code: "13131",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3903",
      city: "Parishville",
      zip_code: "13672",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3452",
      city: "Parksville",
      zip_code: "12768",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3043",
      city: "Patchogue",
      zip_code: "11772",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3388",
      city: "Patterson",
      zip_code: "12563",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3196",
      city: "Pattersonville",
      zip_code: "12137",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3589",
      city: "Paul Smiths",
      zip_code: "12970",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "4202",
      city: "Pavilion",
      zip_code: "14525",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3389",
      city: "Pawling",
      zip_code: "12564",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "2808",
      city: "Pearl River",
      zip_code: "10965",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3093",
      city: "Peconic",
      zip_code: "11958",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "2735",
      city: "Peekskill",
      zip_code: "10566",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "2770",
      city: "Pelham",
      zip_code: "10803",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "4203",
      city: "Penfield",
      zip_code: "14526",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "4204",
      city: "Penn Yan",
      zip_code: "14527",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3680",
      city: "Pennellville",
      zip_code: "13132",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "4205",
      city: "Perkinsville",
      zip_code: "14529",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "4206",
      city: "Perry",
      zip_code: "14530",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "4085",
      city: "Perrysburg",
      zip_code: "14129",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3590",
      city: "Peru",
      zip_code: "12972",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3681",
      city: "Peterboro",
      zip_code: "13134",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3197",
      city: "Petersburg",
      zip_code: "12138",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "4207",
      city: "Phelps",
      zip_code: "14532",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3904",
      city: "Philadelphia",
      zip_code: "13673",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3453",
      city: "Phillipsport",
      zip_code: "12769",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3390",
      city: "Philmont",
      zip_code: "12565",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3319",
      city: "Phoenicia",
      zip_code: "12464",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3682",
      city: "Phoenix",
      zip_code: "13135",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3591",
      city: "Piercefield",
      zip_code: "12973",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "2809",
      city: "Piermont",
      zip_code: "10968",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3905",
      city: "Pierrepont Manor",
      zip_code: "13674",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "4208",
      city: "Piffard",
      zip_code: "14533",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "4086",
      city: "Pike",
      zip_code: "14130",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3391",
      city: "Pine Bush",
      zip_code: "12566",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "4381",
      city: "Pine City",
      zip_code: "14871",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3320",
      city: "Pine Hill",
      zip_code: "12465",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "2810",
      city: "Pine Island",
      zip_code: "10969",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3392",
      city: "Pine Plains",
      zip_code: "12567",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "4382",
      city: "Pine Valley",
      zip_code: "14872",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3198",
      city: "Piseco",
      zip_code: "12139",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3683",
      city: "Pitcher",
      zip_code: "13136",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "4209",
      city: "Pittsford",
      zip_code: "14534",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3066",
      city: "Plainview",
      zip_code: "11803",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3538",
      city: "Plattsburgh",
      zip_code: "12901",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3393",
      city: "Pleasant Valley",
      zip_code: "12569",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "2737",
      city: "Pleasantville",
      zip_code: "10570",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3906",
      city: "Plessis",
      zip_code: "13675",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3984",
      city: "Plymouth",
      zip_code: "13832",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3199",
      city: "Poestenkill",
      zip_code: "12140",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "2971",
      city: "Point Lookout",
      zip_code: "11569",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3798",
      city: "Poland",
      zip_code: "13431",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "2811",
      city: "Pomona",
      zip_code: "10970",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3684",
      city: "Pompey",
      zip_code: "13138",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3454",
      city: "Pond Eddy",
      zip_code: "12770",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3685",
      city: "Port Byron",
      zip_code: "13140",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "2738",
      city: "Port Chester",
      zip_code: "10573",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3985",
      city: "Port Crane",
      zip_code: "13833",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3321",
      city: "Port Ewen",
      zip_code: "12466",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "4211",
      city: "Port Gibson",
      zip_code: "14537",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3592",
      city: "Port Henry",
      zip_code: "12974",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3045",
      city: "Port Jefferson",
      zip_code: "11777",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3044",
      city: "Port Jefferson Station",
      zip_code: "11776",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3455",
      city: "Port Jervis",
      zip_code: "12771",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3593",
      city: "Port Kent",
      zip_code: "12975",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3799",
      city: "Port Leyden",
      zip_code: "13433",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "2844",
      city: "Port Washington",
      zip_code: "11050",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "4210",
      city: "Portageville",
      zip_code: "14536",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3519",
      city: "Porter Corners",
      zip_code: "12859",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "4314",
      city: "Portland",
      zip_code: "14769",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3986",
      city: "Portlandville",
      zip_code: "13834",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "4315",
      city: "Portville",
      zip_code: "14770",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3907",
      city: "Potsdam",
      zip_code: "13676",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3520",
      city: "Pottersville",
      zip_code: "12860",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3411",
      city: "Poughkeepsie",
      zip_code: "12601",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3394",
      city: "Poughquag",
      zip_code: "12570",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "2739",
      city: "Pound Ridge",
      zip_code: "10576",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "4383",
      city: "Prattsburgh",
      zip_code: "14873",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3322",
      city: "Prattsville",
      zip_code: "12468",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3686",
      city: "Preble",
      zip_code: "13141",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3323",
      city: "Preston Hollow",
      zip_code: "12469",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3800",
      city: "Prospect",
      zip_code: "13435",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3687",
      city: "Pulaski",
      zip_code: "13142",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "4384",
      city: "Pulteney",
      zip_code: "14874",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "2740",
      city: "Purchase",
      zip_code: "10577",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "2741",
      city: "Purdys",
      zip_code: "10578",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3324",
      city: "Purling",
      zip_code: "12470",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3521",
      city: "Putnam Station",
      zip_code: "12861",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "2742",
      city: "Putnam Valley",
      zip_code: "10579",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "2932",
      city: "Queens Village",
      zip_code: "11427",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3475",
      city: "Queensbury",
      zip_code: "12804",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3094",
      city: "Quogue",
      zip_code: "11959",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3594",
      city: "Rainbow Lake",
      zip_code: "12976",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "4316",
      city: "Randolph",
      zip_code: "14772",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "4087",
      city: "Ransomville",
      zip_code: "14131",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3801",
      city: "Raquette Lake",
      zip_code: "13436",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3200",
      city: "Ravena",
      zip_code: "12143",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3595",
      city: "Ray Brook",
      zip_code: "12977",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3908",
      city: "Raymondville",
      zip_code: "13678",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3688",
      city: "Red Creek",
      zip_code: "13143",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3395",
      city: "Red Hook",
      zip_code: "12571",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3802",
      city: "Redfield",
      zip_code: "13437",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3596",
      city: "Redford",
      zip_code: "12978",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3909",
      city: "Redwood",
      zip_code: "13679",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "2911",
      city: "Rego Park",
      zip_code: "11374",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3803",
      city: "Remsen",
      zip_code: "13438",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3095",
      city: "Remsenburg",
      zip_code: "11960",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3201",
      city: "Rensselaer",
      zip_code: "12144",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3910",
      city: "Rensselaer Falls",
      zip_code: "13680",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3202",
      city: "Rensselaerville",
      zip_code: "12147",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "4212",
      city: "Retsof",
      zip_code: "14539",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3203",
      city: "Rexford",
      zip_code: "12148",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "4385",
      city: "Rexville",
      zip_code: "14877",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3396",
      city: "Rhinebeck",
      zip_code: "12572",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3397",
      city: "Rhinecliff",
      zip_code: "12574",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "4317",
      city: "Richburg",
      zip_code: "14774",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3804",
      city: "Richfield Springs",
      zip_code: "13439",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3987",
      city: "Richford",
      zip_code: "13835",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3689",
      city: "Richland",
      zip_code: "13144",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "2924",
      city: "Richmond Hill",
      zip_code: "11418",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3204",
      city: "Richmondville",
      zip_code: "12149",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3911",
      city: "Richville",
      zip_code: "13681",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3096",
      city: "Ridge",
      zip_code: "11961",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "2916",
      city: "Ridgewood",
      zip_code: "11385",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3325",
      city: "Rifton",
      zip_code: "12471",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3522",
      city: "Riparius",
      zip_code: "12862",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "4318",
      city: "Ripley",
      zip_code: "14775",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3068",
      city: "Riverhead",
      zip_code: "11901",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "4242",
      city: "Rochester",
      zip_code: "14604",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3523",
      city: "Rock City Falls",
      zip_code: "12863",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3456",
      city: "Rock Hill",
      zip_code: "12775",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "4386",
      city: "Rock Stream",
      zip_code: "14878",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3398",
      city: "Rock Tavern",
      zip_code: "12575",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "2986",
      city: "Rockaway Park",
      zip_code: "11694",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "2972",
      city: "Rockville Centre",
      zip_code: "11570",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3046",
      city: "Rocky Point",
      zip_code: "11778",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3912",
      city: "Rodman",
      zip_code: "13682",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3805",
      city: "Rome",
      zip_code: "13440",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "4213",
      city: "Romulus",
      zip_code: "14541",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3047",
      city: "Ronkonkoma",
      zip_code: "11779",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "2974",
      city: "Roosevelt",
      zip_code: "11575",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3913",
      city: "Rooseveltown",
      zip_code: "13683",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3457",
      city: "Roscoe",
      zip_code: "12776",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "4214",
      city: "Rose",
      zip_code: "14542",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3807",
      city: "Roseboom",
      zip_code: "13450",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "2928",
      city: "Rosedale",
      zip_code: "11422",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3326",
      city: "Rosendale",
      zip_code: "12472",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "2975",
      city: "Roslyn",
      zip_code: "11576",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "2976",
      city: "Roslyn Heights",
      zip_code: "11577",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3205",
      city: "Rotterdam Junction",
      zip_code: "12150",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3206",
      city: "Round Lake",
      zip_code: "12151",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3327",
      city: "Round Top",
      zip_code: "12473",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3597",
      city: "Rouses Point",
      zip_code: "12979",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3328",
      city: "Roxbury",
      zip_code: "12474",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3329",
      city: "Ruby",
      zip_code: "12475",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "4215",
      city: "Rush",
      zip_code: "14543",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "4319",
      city: "Rushford",
      zip_code: "14777",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "4216",
      city: "Rushville",
      zip_code: "14544",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3914",
      city: "Russell",
      zip_code: "13684",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "2743",
      city: "Rye",
      zip_code: "10580",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3524",
      city: "Sabael",
      zip_code: "12864",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3915",
      city: "Sackets Harbor",
      zip_code: "13685",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3098",
      city: "Sag Harbor",
      zip_code: "11963",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3097",
      city: "Sagaponack",
      zip_code: "11962",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "2918",
      city: "Saint Albans",
      zip_code: "11412",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "4320",
      city: "Saint Bonaventure",
      zip_code: "14778",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3048",
      city: "Saint James",
      zip_code: "11780",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3808",
      city: "Saint Johnsville",
      zip_code: "13452",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3598",
      city: "Saint Regis Falls",
      zip_code: "12980",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "4321",
      city: "Salamanca",
      zip_code: "14779",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3525",
      city: "Salem",
      zip_code: "12865",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3809",
      city: "Salisbury Center",
      zip_code: "13454",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3399",
      city: "Salisbury Mills",
      zip_code: "12577",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3400",
      city: "Salt Point",
      zip_code: "12578",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "4088",
      city: "Sanborn",
      zip_code: "14132",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3207",
      city: "Sand Lake",
      zip_code: "12153",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3690",
      city: "Sandy Creek",
      zip_code: "13145",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3810",
      city: "Sangerfield",
      zip_code: "13455",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3599",
      city: "Saranac",
      zip_code: "12981",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3600",
      city: "Saranac Lake",
      zip_code: "12983",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3526",
      city: "Saratoga Springs",
      zip_code: "12866",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "4089",
      city: "Sardinia",
      zip_code: "14134",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3330",
      city: "Saugerties",
      zip_code: "12477",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3811",
      city: "Sauquoit",
      zip_code: "13456",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3691",
      city: "Savannah",
      zip_code: "13146",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "4387",
      city: "Savona",
      zip_code: "14879",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3049",
      city: "Sayville",
      zip_code: "11782",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "2744",
      city: "Scarsdale",
      zip_code: "10583",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3208",
      city: "Schaghticoke",
      zip_code: "12154",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3258",
      city: "Schenectady",
      zip_code: "12302",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3209",
      city: "Schenevus",
      zip_code: "12155",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3210",
      city: "Schodack Landing",
      zip_code: "12156",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3211",
      city: "Schoharie",
      zip_code: "12157",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3527",
      city: "Schroon Lake",
      zip_code: "12870",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3601",
      city: "Schuyler Falls",
      zip_code: "12985",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3812",
      city: "Schuyler Lake",
      zip_code: "13457",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3528",
      city: "Schuylerville",
      zip_code: "12871",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "4388",
      city: "Scio",
      zip_code: "14880",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3692",
      city: "Scipio Center",
      zip_code: "13147",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "4217",
      city: "Scottsburg",
      zip_code: "14545",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "4218",
      city: "Scottsville",
      zip_code: "14546",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "2977",
      city: "Sea Cliff",
      zip_code: "11579",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3050",
      city: "Seaford",
      zip_code: "11783",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3051",
      city: "Selden",
      zip_code: "11784",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3212",
      city: "Selkirk",
      zip_code: "12158",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3693",
      city: "Seneca Falls",
      zip_code: "13148",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3529",
      city: "Severance",
      zip_code: "12872",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3331",
      city: "Shandaken",
      zip_code: "12480",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3813",
      city: "Sharon Springs",
      zip_code: "13459",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3099",
      city: "Shelter Island",
      zip_code: "11964",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3100",
      city: "Shelter Island Heights",
      zip_code: "11965",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3814",
      city: "Sherburne",
      zip_code: "13460",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "4090",
      city: "Sheridan",
      zip_code: "14135",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "4322",
      city: "Sherman",
      zip_code: "14781",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3815",
      city: "Sherrill",
      zip_code: "13461",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3101",
      city: "Shirley",
      zip_code: "11967",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3332",
      city: "Shokan",
      zip_code: "12481",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3052",
      city: "Shoreham",
      zip_code: "11786",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "4219",
      city: "Shortsville",
      zip_code: "14548",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "2745",
      city: "Shrub Oak",
      zip_code: "10588",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3530",
      city: "Shushan",
      zip_code: "12873",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3988",
      city: "Sidney",
      zip_code: "13838",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3989",
      city: "Sidney Center",
      zip_code: "13839",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3531",
      city: "Silver Bay",
      zip_code: "12874",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "4091",
      city: "Silver Creek",
      zip_code: "14136",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "4220",
      city: "Silver Lake",
      zip_code: "14549",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "4221",
      city: "Silver Springs",
      zip_code: "14550",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "4323",
      city: "Sinclairville",
      zip_code: "14782",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3694",
      city: "Skaneateles",
      zip_code: "13152",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3695",
      city: "Skaneateles Falls",
      zip_code: "13153",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "2812",
      city: "Slate Hill",
      zip_code: "10973",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "4389",
      city: "Slaterville Springs",
      zip_code: "14881",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3213",
      city: "Slingerlands",
      zip_code: "12159",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3214",
      city: "Sloansville",
      zip_code: "12160",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "2813",
      city: "Sloatsburg",
      zip_code: "10974",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3459",
      city: "Smallwood",
      zip_code: "12778",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3053",
      city: "Smithtown",
      zip_code: "11787",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3990",
      city: "Smithville Flats",
      zip_code: "13841",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3816",
      city: "Smyrna",
      zip_code: "13464",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "4222",
      city: "Sodus",
      zip_code: "14551",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "4223",
      city: "Sodus Point",
      zip_code: "14555",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "2746",
      city: "Somers",
      zip_code: "10589",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "4224",
      city: "Sonyea",
      zip_code: "14556",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3055",
      city: "Sound Beach",
      zip_code: "11789",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3215",
      city: "South Bethlehem",
      zip_code: "12161",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3696",
      city: "South Butler",
      zip_code: "13154",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "4225",
      city: "South Byron",
      zip_code: "14557",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3333",
      city: "South Cairo",
      zip_code: "12482",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3916",
      city: "South Colton",
      zip_code: "13687",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "4092",
      city: "South Dayton",
      zip_code: "14138",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3460",
      city: "South Fallsburg",
      zip_code: "12779",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3474",
      city: "South Glens Falls",
      zip_code: "12803",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3103",
      city: "South Jamesport",
      zip_code: "11970",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3991",
      city: "South Kortright",
      zip_code: "13842",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3992",
      city: "South New Berlin",
      zip_code: "13843",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3697",
      city: "South Otselic",
      zip_code: "13155",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "2926",
      city: "South Ozone Park",
      zip_code: "11420",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3993",
      city: "South Plymouth",
      zip_code: "13844",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "2925",
      city: "South Richmond Hill",
      zip_code: "11419",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "2747",
      city: "South Salem",
      zip_code: "10590",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "4093",
      city: "South Wales",
      zip_code: "14139",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3102",
      city: "Southampton",
      zip_code: "11968",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "2814",
      city: "Southfields",
      zip_code: "10975",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3104",
      city: "Southold",
      zip_code: "11971",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "2815",
      city: "Sparkill",
      zip_code: "10976",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3461",
      city: "Sparrow Bush",
      zip_code: "12780",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3216",
      city: "Speculator",
      zip_code: "12164",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "4391",
      city: "Spencer",
      zip_code: "14883",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "4226",
      city: "Spencerport",
      zip_code: "14559",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3217",
      city: "Spencertown",
      zip_code: "12165",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3105",
      city: "Speonk",
      zip_code: "11972",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3218",
      city: "Sprakers",
      zip_code: "12166",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3334",
      city: "Spring Glen",
      zip_code: "12483",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "2816",
      city: "Spring Valley",
      zip_code: "10977",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3817",
      city: "Springfield Center",
      zip_code: "13468",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "2919",
      city: "Springfield Gardens",
      zip_code: "11413",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "4094",
      city: "Springville",
      zip_code: "14141",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "4227",
      city: "Springwater",
      zip_code: "14560",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3401",
      city: "Staatsburg",
      zip_code: "12580",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "4095",
      city: "Stafford",
      zip_code: "14143",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3219",
      city: "Stamford",
      zip_code: "12167",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3402",
      city: "Stanfordville",
      zip_code: "12581",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "4228",
      city: "Stanley",
      zip_code: "14561",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3917",
      city: "Star Lake",
      zip_code: "13690",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "2653",
      city: "Staten Island",
      zip_code: "10301",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "4324",
      city: "Steamburg",
      zip_code: "14783",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3220",
      city: "Stephentown",
      zip_code: "12168",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3698",
      city: "Sterling",
      zip_code: "13156",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "2817",
      city: "Sterling Forest",
      zip_code: "10979",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3222",
      city: "Stillwater",
      zip_code: "12170",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3818",
      city: "Stittville",
      zip_code: "13469",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "4325",
      city: "Stockton",
      zip_code: "14784",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3335",
      city: "Stone Ridge",
      zip_code: "12484",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3056",
      city: "Stony Brook",
      zip_code: "11790",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3532",
      city: "Stony Creek",
      zip_code: "12878",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "2818",
      city: "Stony Point",
      zip_code: "10980",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3403",
      city: "Stormville",
      zip_code: "12582",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3223",
      city: "Stottville",
      zip_code: "12172",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "4326",
      city: "Stow",
      zip_code: "14785",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3819",
      city: "Stratford",
      zip_code: "13470",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "4096",
      city: "Strykersville",
      zip_code: "14145",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3224",
      city: "Stuyvesant",
      zip_code: "12173",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3225",
      city: "Stuyvesant Falls",
      zip_code: "12174",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "2773",
      city: "Suffern",
      zip_code: "10901",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3226",
      city: "Summit",
      zip_code: "12175",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3462",
      city: "Summitville",
      zip_code: "12781",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "2849",
      city: "Sunnyside",
      zip_code: "11104",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3227",
      city: "Surprise",
      zip_code: "12176",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "4392",
      city: "Swain",
      zip_code: "14884",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3463",
      city: "Swan Lake",
      zip_code: "12783",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3699",
      city: "Sylvan Beach",
      zip_code: "13157",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3057",
      city: "Syosset",
      zip_code: "11791",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3709",
      city: "Syracuse",
      zip_code: "13202",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3820",
      city: "Taberg",
      zip_code: "13471",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3336",
      city: "Tannersville",
      zip_code: "12485",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "2819",
      city: "Tappan",
      zip_code: "10983",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "2748",
      city: "Tarrytown",
      zip_code: "10591",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3821",
      city: "Thendara",
      zip_code: "13472",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3918",
      city: "Theresa",
      zip_code: "13691",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "2820",
      city: "Thiells",
      zip_code: "10984",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "2821",
      city: "Thompson Ridge",
      zip_code: "10985",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3464",
      city: "Thompsonville",
      zip_code: "12784",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "2749",
      city: "Thornwood",
      zip_code: "10594",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3919",
      city: "Thousand Island Park",
      zip_code: "13692",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3920",
      city: "Three Mile Bay",
      zip_code: "13693",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3533",
      city: "Ticonderoga",
      zip_code: "12883",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3337",
      city: "Tillson",
      zip_code: "12486",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3404",
      city: "Tivoli",
      zip_code: "12583",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "2822",
      city: "Tomkins Cove",
      zip_code: "10986",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "4097",
      city: "Tonawanda",
      zip_code: "14150",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3994",
      city: "Treadwell",
      zip_code: "13846",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3228",
      city: "Tribes Hill",
      zip_code: "12177",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "4393",
      city: "Troupsburg",
      zip_code: "14885",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3995",
      city: "Trout Creek",
      zip_code: "13847",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3229",
      city: "Troy",
      zip_code: "12180",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "4394",
      city: "Trumansburg",
      zip_code: "14886",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3700",
      city: "Truxton",
      zip_code: "13158",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "2765",
      city: "Tuckahoe",
      zip_code: "10707",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3701",
      city: "Tully",
      zip_code: "13159",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3602",
      city: "Tupper Lake",
      zip_code: "12986",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3822",
      city: "Turin",
      zip_code: "13473",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "2823",
      city: "Tuxedo Park",
      zip_code: "10987",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3338",
      city: "Ulster Park",
      zip_code: "12487",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3996",
      city: "Unadilla",
      zip_code: "13849",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3702",
      city: "Union Springs",
      zip_code: "13160",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "2959",
      city: "Uniondale",
      zip_code: "11553",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "2824",
      city: "Unionville",
      zip_code: "10988",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3603",
      city: "Upper Jay",
      zip_code: "12987",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3106",
      city: "Upton",
      zip_code: "11973",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3841",
      city: "Utica",
      zip_code: "13501",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3232",
      city: "Valatie",
      zip_code: "12184",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "2750",
      city: "Valhalla",
      zip_code: "10595",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "2825",
      city: "Valley Cottage",
      zip_code: "10989",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3233",
      city: "Valley Falls",
      zip_code: "12185",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "2978",
      city: "Valley Stream",
      zip_code: "11580",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "4098",
      city: "Van Buren Point",
      zip_code: "14166",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "4395",
      city: "Van Etten",
      zip_code: "14889",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3823",
      city: "Van Hornesville",
      zip_code: "13475",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "4099",
      city: "Varysburg",
      zip_code: "14167",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3405",
      city: "Verbank",
      zip_code: "12585",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3604",
      city: "Vermontville",
      zip_code: "12989",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3824",
      city: "Vernon",
      zip_code: "13476",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3825",
      city: "Vernon Center",
      zip_code: "13477",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3826",
      city: "Verona",
      zip_code: "13478",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3703",
      city: "Verona Beach",
      zip_code: "13162",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "2751",
      city: "Verplanck",
      zip_code: "10596",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "4100",
      city: "Versailles",
      zip_code: "14168",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3997",
      city: "Vestal",
      zip_code: "13850",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "4229",
      city: "Victor",
      zip_code: "14564",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3534",
      city: "Victory Mills",
      zip_code: "12884",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3234",
      city: "Voorheesville",
      zip_code: "12186",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "2752",
      city: "Waccabuc",
      zip_code: "10597",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3921",
      city: "Waddington",
      zip_code: "13694",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3058",
      city: "Wading River",
      zip_code: "11792",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3107",
      city: "Wainscott",
      zip_code: "11975",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3406",
      city: "Walden",
      zip_code: "12586",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "4101",
      city: "Wales Center",
      zip_code: "14169",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3407",
      city: "Wallkill",
      zip_code: "12589",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3998",
      city: "Walton",
      zip_code: "13856",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "4230",
      city: "Walworth",
      zip_code: "14568",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3704",
      city: "Wampsville",
      zip_code: "13163",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3922",
      city: "Wanakena",
      zip_code: "13695",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3059",
      city: "Wantagh",
      zip_code: "11793",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3408",
      city: "Wappingers Falls",
      zip_code: "12590",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3705",
      city: "Warners",
      zip_code: "13164",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3235",
      city: "Warnerville",
      zip_code: "12187",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3535",
      city: "Warrensburg",
      zip_code: "12885",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "4231",
      city: "Warsaw",
      zip_code: "14569",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "2826",
      city: "Warwick",
      zip_code: "10990",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "2827",
      city: "Washingtonville",
      zip_code: "10992",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3409",
      city: "Wassaic",
      zip_code: "12592",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3108",
      city: "Water Mill",
      zip_code: "11976",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3236",
      city: "Waterford",
      zip_code: "12188",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3706",
      city: "Waterloo",
      zip_code: "13165",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "4232",
      city: "Waterport",
      zip_code: "14571",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3843",
      city: "Watertown",
      zip_code: "13601",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3827",
      city: "Waterville",
      zip_code: "13480",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3237",
      city: "Watervliet",
      zip_code: "12189",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "4396",
      city: "Watkins Glen",
      zip_code: "14891",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "4397",
      city: "Waverly",
      zip_code: "14892",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3339",
      city: "Wawarsing",
      zip_code: "12489",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "4233",
      city: "Wayland",
      zip_code: "14572",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "4398",
      city: "Wayne",
      zip_code: "14893",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "4234",
      city: "Webster",
      zip_code: "14580",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3707",
      city: "Weedsport",
      zip_code: "13166",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3876",
      city: "Wellesley Island",
      zip_code: "13640",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3238",
      city: "Wells",
      zip_code: "12190",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3999",
      city: "Wells Bridge",
      zip_code: "13859",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "4399",
      city: "Wellsburg",
      zip_code: "14894",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "4400",
      city: "Wellsville",
      zip_code: "14895",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "2991",
      city: "West Babylon",
      zip_code: "11704",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "4235",
      city: "West Bloomfield",
      zip_code: "14585",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3828",
      city: "West Burlington",
      zip_code: "13482",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3340",
      city: "West Camp",
      zip_code: "12490",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3605",
      city: "West Chazy",
      zip_code: "12992",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3239",
      city: "West Coxsackie",
      zip_code: "12192",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "4000",
      city: "West Davenport",
      zip_code: "13860",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3830",
      city: "West Eaton",
      zip_code: "13484",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3831",
      city: "West Edmeston",
      zip_code: "13485",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "4102",
      city: "West Falls",
      zip_code: "14170",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3241",
      city: "West Fulton",
      zip_code: "12194",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "2756",
      city: "West Harrison",
      zip_code: "10604",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "2828",
      city: "West Haverstraw",
      zip_code: "10993",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "2958",
      city: "West Hempstead",
      zip_code: "11552",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "4236",
      city: "West Henrietta",
      zip_code: "14586",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3341",
      city: "West Hurley",
      zip_code: "12491",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3061",
      city: "West Islip",
      zip_code: "11795",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3342",
      city: "West Kill",
      zip_code: "12492",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3242",
      city: "West Lebanon",
      zip_code: "12195",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3834",
      city: "West Leyden",
      zip_code: "13489",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3708",
      city: "West Monroe",
      zip_code: "13167",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "2829",
      city: "West Nyack",
      zip_code: "10994",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "4001",
      city: "West Oneonta",
      zip_code: "13861",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3343",
      city: "West Park",
      zip_code: "12493",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "2830",
      city: "West Point",
      zip_code: "10996",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3243",
      city: "West Sand Lake",
      zip_code: "12196",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3062",
      city: "West Sayville",
      zip_code: "11796",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3344",
      city: "West Shokan",
      zip_code: "12494",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3923",
      city: "West Stockholm",
      zip_code: "13696",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "4103",
      city: "West Valley",
      zip_code: "14171",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3836",
      city: "West Winfield",
      zip_code: "13491",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3465",
      city: "Westbrookville",
      zip_code: "12785",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "2980",
      city: "Westbury",
      zip_code: "11590",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3829",
      city: "Westdale",
      zip_code: "13483",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3240",
      city: "Westerlo",
      zip_code: "12193",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3832",
      city: "Westernville",
      zip_code: "13486",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "4327",
      city: "Westfield",
      zip_code: "14787",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3833",
      city: "Westford",
      zip_code: "13488",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3109",
      city: "Westhampton",
      zip_code: "11977",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3110",
      city: "Westhampton Beach",
      zip_code: "11978",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3835",
      city: "Westmoreland",
      zip_code: "13490",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "4328",
      city: "Westons Mills",
      zip_code: "14788",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3606",
      city: "Westport",
      zip_code: "12993",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "2831",
      city: "Westtown",
      zip_code: "10998",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3536",
      city: "Wevertown",
      zip_code: "12886",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3466",
      city: "White Lake",
      zip_code: "12786",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "2754",
      city: "White Plains",
      zip_code: "10601",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3467",
      city: "White Sulphur Springs",
      zip_code: "12787",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3537",
      city: "Whitehall",
      zip_code: "12887",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3837",
      city: "Whitesboro",
      zip_code: "13492",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "2894",
      city: "Whitestone",
      zip_code: "11357",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "4401",
      city: "Whitesville",
      zip_code: "14897",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "4002",
      city: "Whitney Point",
      zip_code: "13862",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "4237",
      city: "Willard",
      zip_code: "14588",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "4003",
      city: "Willet",
      zip_code: "13863",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "4238",
      city: "Williamson",
      zip_code: "14589",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3838",
      city: "Williamstown",
      zip_code: "13493",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "2981",
      city: "Williston Park",
      zip_code: "11596",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3345",
      city: "Willow",
      zip_code: "12495",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3607",
      city: "Willsboro",
      zip_code: "12996",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "4004",
      city: "Willseyville",
      zip_code: "13864",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3608",
      city: "Wilmington",
      zip_code: "12997",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "4104",
      city: "Wilson",
      zip_code: "14172",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3346",
      city: "Windham",
      zip_code: "12496",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "4005",
      city: "Windsor",
      zip_code: "13865",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3410",
      city: "Wingdale",
      zip_code: "12594",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3924",
      city: "Winthrop",
      zip_code: "13697",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3609",
      city: "Witherbee",
      zip_code: "12998",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "4239",
      city: "Wolcott",
      zip_code: "14590",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3468",
      city: "Woodbourne",
      zip_code: "12788",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3063",
      city: "Woodbury",
      zip_code: "11797",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3839",
      city: "Woodgate",
      zip_code: "13494",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "2927",
      city: "Woodhaven",
      zip_code: "11421",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "4402",
      city: "Woodhull",
      zip_code: "14898",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "2982",
      city: "Woodmere",
      zip_code: "11598",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3469",
      city: "Woodridge",
      zip_code: "12789",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "2913",
      city: "Woodside",
      zip_code: "11377",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3347",
      city: "Woodstock",
      zip_code: "12498",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3244",
      city: "Worcester",
      zip_code: "12197",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3470",
      city: "Wurtsboro",
      zip_code: "12790",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3064",
      city: "Wyandanch",
      zip_code: "11798",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3245",
      city: "Wynantskill",
      zip_code: "12198",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "4240",
      city: "Wyoming",
      zip_code: "14591",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3111",
      city: "Yaphank",
      zip_code: "11980",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "2760",
      city: "Yonkers",
      zip_code: "10701",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "4241",
      city: "York",
      zip_code: "14592",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "4105",
      city: "Yorkshire",
      zip_code: "14173",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "2753",
      city: "Yorktown Heights",
      zip_code: "10598",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3840",
      city: "Yorkville",
      zip_code: "13495",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "4106",
      city: "Youngstown",
      zip_code: "14174",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3471",
      city: "Youngsville",
      zip_code: "12791",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "3472",
      city: "Yulan",
      zip_code: "12792",
      state_id: "NY",
      state_name: "New York",
    },
    {
      city_id: "14854",
      city: "Aberdeen",
      zip_code: "45101",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "15179",
      city: "Ada",
      zip_code: "45810",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14368",
      city: "Adamsville",
      zip_code: "43802",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14821",
      city: "Addyston",
      zip_code: "45001",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14083",
      city: "Adelphi",
      zip_code: "43101",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14385",
      city: "Adena",
      zip_code: "43901",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14589",
      city: "Akron",
      zip_code: "44301",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "15126",
      city: "Albany",
      zip_code: "45710",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14021",
      city: "Alexandria",
      zip_code: "43001",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "15180",
      city: "Alger",
      zip_code: "45812",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14386",
      city: "Alledonia",
      zip_code: "43902",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14672",
      city: "Alliance",
      zip_code: "44601",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14948",
      city: "Alpha",
      zip_code: "45301",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14758",
      city: "Alvada",
      zip_code: "44802",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14242",
      city: "Alvordton",
      zip_code: "43501",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14084",
      city: "Amanda",
      zip_code: "43102",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14855",
      city: "Amelia",
      zip_code: "45102",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "15127",
      city: "Amesville",
      zip_code: "45711",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14440",
      city: "Amherst",
      zip_code: "44001",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14022",
      city: "Amlin",
      zip_code: "43002",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14387",
      city: "Amsterdam",
      zip_code: "43903",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14441",
      city: "Andover",
      zip_code: "44003",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14949",
      city: "Anna",
      zip_code: "45302",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14950",
      city: "Ansonia",
      zip_code: "45303",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "15181",
      city: "Antwerp",
      zip_code: "45813",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14673",
      city: "Apple Creek",
      zip_code: "44606",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14759",
      city: "Arcadia",
      zip_code: "44804",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14951",
      city: "Arcanum",
      zip_code: "45304",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14243",
      city: "Archbold",
      zip_code: "43502",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "15182",
      city: "Arlington",
      zip_code: "45814",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14760",
      city: "Ashland",
      zip_code: "44805",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14023",
      city: "Ashley",
      zip_code: "43003",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14442",
      city: "Ashtabula",
      zip_code: "44004",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14085",
      city: "Ashville",
      zip_code: "43103",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "15125",
      city: "Athens",
      zip_code: "45701",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14761",
      city: "Attica",
      zip_code: "44807",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14545",
      city: "Atwater",
      zip_code: "44201",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14674",
      city: "Augusta",
      zip_code: "44607",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14546",
      city: "Aurora",
      zip_code: "44202",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14443",
      city: "Austinburg",
      zip_code: "44010",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14313",
      city: "Ava",
      zip_code: "43711",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14444",
      city: "Avon",
      zip_code: "44011",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14445",
      city: "Avon Lake",
      zip_code: "44012",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "15062",
      city: "Bainbridge",
      zip_code: "45612",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14369",
      city: "Baltic",
      zip_code: "43804",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14086",
      city: "Baltimore",
      zip_code: "43105",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14430",
      city: "Bannock",
      zip_code: "43972",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14547",
      city: "Barberton",
      zip_code: "44203",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14314",
      city: "Barnesville",
      zip_code: "43713",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14388",
      city: "Barton",
      zip_code: "43905",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14762",
      city: "Bascom",
      zip_code: "44809",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14856",
      city: "Batavia",
      zip_code: "45103",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14536",
      city: "Bay Village",
      zip_code: "44140",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14675",
      city: "Beach City",
      zip_code: "44608",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14518",
      city: "Beachwood",
      zip_code: "44122",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14315",
      city: "Beallsville",
      zip_code: "43716",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "15063",
      city: "Beaver",
      zip_code: "45613",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "15177",
      city: "Beaverdam",
      zip_code: "45808",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14542",
      city: "Bedford",
      zip_code: "44146",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14389",
      city: "Bellaire",
      zip_code: "43906",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14952",
      city: "Bellbrook",
      zip_code: "45305",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14160",
      city: "Belle Center",
      zip_code: "43310",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14316",
      city: "Belle Valley",
      zip_code: "43717",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14161",
      city: "Bellefontaine",
      zip_code: "43311",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14763",
      city: "Bellevue",
      zip_code: "44811",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14764",
      city: "Bellville",
      zip_code: "44813",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14317",
      city: "Belmont",
      zip_code: "43718",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "15183",
      city: "Belmore",
      zip_code: "45815",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14676",
      city: "Beloit",
      zip_code: "44609",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "15128",
      city: "Belpre",
      zip_code: "45714",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "15184",
      city: "Benton Ridge",
      zip_code: "45816",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14857",
      city: "Bentonville",
      zip_code: "45105",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14446",
      city: "Berea",
      zip_code: "44017",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14391",
      city: "Bergholz",
      zip_code: "43908",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14244",
      city: "Berkey",
      zip_code: "43504",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14677",
      city: "Berlin",
      zip_code: "44610",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14607",
      city: "Berlin Center",
      zip_code: "44401",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14765",
      city: "Berlin Heights",
      zip_code: "44814",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14858",
      city: "Bethel",
      zip_code: "45106",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14318",
      city: "Bethesda",
      zip_code: "43719",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14766",
      city: "Bettsville",
      zip_code: "44815",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "15129",
      city: "Beverly",
      zip_code: "45715",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "15064",
      city: "Bidwell",
      zip_code: "45614",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14678",
      city: "Big Prairie",
      zip_code: "44611",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14024",
      city: "Blacklick",
      zip_code: "43004",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14025",
      city: "Bladensburg",
      zip_code: "43005",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14392",
      city: "Blaine",
      zip_code: "43909",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14245",
      city: "Blakeslee",
      zip_code: "43505",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14859",
      city: "Blanchester",
      zip_code: "45107",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14370",
      city: "Blissfield",
      zip_code: "43805",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14767",
      city: "Bloomdale",
      zip_code: "44817",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14087",
      city: "Bloomingburg",
      zip_code: "43106",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14393",
      city: "Bloomingdale",
      zip_code: "43910",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14768",
      city: "Bloomville",
      zip_code: "44818",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "15065",
      city: "Blue Creek",
      zip_code: "45616",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14319",
      city: "Blue Rock",
      zip_code: "43720",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "15185",
      city: "Bluffton",
      zip_code: "45817",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14679",
      city: "Bolivar",
      zip_code: "44612",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14953",
      city: "Botkins",
      zip_code: "45306",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "15066",
      city: "Bourneville",
      zip_code: "45617",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14741",
      city: "Bowerston",
      zip_code: "44695",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14954",
      city: "Bowersville",
      zip_code: "45307",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14199",
      city: "Bowling Green",
      zip_code: "43402",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14955",
      city: "Bradford",
      zip_code: "45308",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14201",
      city: "Bradner",
      zip_code: "43406",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14537",
      city: "Brecksville",
      zip_code: "44141",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14088",
      city: "Bremen",
      zip_code: "43107",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14680",
      city: "Brewster",
      zip_code: "44613",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14089",
      city: "Brice",
      zip_code: "43109",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14394",
      city: "Bridgeport",
      zip_code: "43912",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14395",
      city: "Brilliant",
      zip_code: "43913",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14026",
      city: "Brinkhaven",
      zip_code: "43006",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14608",
      city: "Bristolville",
      zip_code: "44402",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14543",
      city: "Broadview Heights",
      zip_code: "44147",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14027",
      city: "Broadway",
      zip_code: "43007",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14609",
      city: "Brookfield",
      zip_code: "44403",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14538",
      city: "Brookpark",
      zip_code: "44142",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14956",
      city: "Brookville",
      zip_code: "45309",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14320",
      city: "Brownsville",
      zip_code: "43721",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14548",
      city: "Brunswick",
      zip_code: "44212",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14246",
      city: "Bryan",
      zip_code: "43506",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "15130",
      city: "Buchtel",
      zip_code: "45716",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14028",
      city: "Buckeye Lake",
      zip_code: "43008",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "15186",
      city: "Buckland",
      zip_code: "45819",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14769",
      city: "Bucyrus",
      zip_code: "44820",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14321",
      city: "Buffalo",
      zip_code: "43722",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14549",
      city: "Burbank",
      zip_code: "44214",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14610",
      city: "Burghill",
      zip_code: "44404",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14202",
      city: "Burgoon",
      zip_code: "43407",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14957",
      city: "Burkettsville",
      zip_code: "45310",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14447",
      city: "Burton",
      zip_code: "44021",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14770",
      city: "Butler",
      zip_code: "44822",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14322",
      city: "Byesville",
      zip_code: "43723",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14029",
      city: "Cable",
      zip_code: "43009",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14390",
      city: "Cadiz",
      zip_code: "43907",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "15187",
      city: "Cairo",
      zip_code: "45820",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14323",
      city: "Caldwell",
      zip_code: "43724",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14162",
      city: "Caledonia",
      zip_code: "43314",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14324",
      city: "Cambridge",
      zip_code: "43725",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14958",
      city: "Camden",
      zip_code: "45311",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14396",
      city: "Cameron",
      zip_code: "43914",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14860",
      city: "Camp Dennison",
      zip_code: "45111",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14611",
      city: "Campbell",
      zip_code: "44405",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14681",
      city: "Canal Fulton",
      zip_code: "44614",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14090",
      city: "Canal Winchester",
      zip_code: "43110",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14612",
      city: "Canfield",
      zip_code: "44406",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14744",
      city: "Canton",
      zip_code: "44702",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14091",
      city: "Carbon Hill",
      zip_code: "43111",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14163",
      city: "Cardington",
      zip_code: "43315",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14164",
      city: "Carey",
      zip_code: "43316",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14092",
      city: "Carroll",
      zip_code: "43112",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14682",
      city: "Carrollton",
      zip_code: "44615",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14959",
      city: "Casstown",
      zip_code: "45312",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14771",
      city: "Castalia",
      zip_code: "44824",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14030",
      city: "Catawba",
      zip_code: "43010",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "15188",
      city: "Cecil",
      zip_code: "45821",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14960",
      city: "Cedarville",
      zip_code: "45314",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "15189",
      city: "Celina",
      zip_code: "45822",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14031",
      city: "Centerburg",
      zip_code: "43011",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14448",
      city: "Chagrin Falls",
      zip_code: "44022",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14325",
      city: "Chandlersville",
      zip_code: "43727",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14450",
      city: "Chardon",
      zip_code: "44024",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14772",
      city: "Chatfield",
      zip_code: "44825",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "15131",
      city: "Chauncey",
      zip_code: "45719",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "15067",
      city: "Cherry Fork",
      zip_code: "45618",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "15068",
      city: "Chesapeake",
      zip_code: "45619",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "15069",
      city: "Cheshire",
      zip_code: "45620",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "15132",
      city: "Chester",
      zip_code: "45720",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14326",
      city: "Chesterhill",
      zip_code: "43728",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14451",
      city: "Chesterland",
      zip_code: "44026",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14165",
      city: "Chesterville",
      zip_code: "43317",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "15190",
      city: "Chickasaw",
      zip_code: "45826",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "15061",
      city: "Chillicothe",
      zip_code: "45601",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14861",
      city: "Chilo",
      zip_code: "45112",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14550",
      city: "Chippewa Lake",
      zip_code: "44215",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "15023",
      city: "Christiansburg",
      zip_code: "45389",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14902",
      city: "Cincinnati",
      zip_code: "45202",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14093",
      city: "Circleville",
      zip_code: "43113",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14397",
      city: "Clarington",
      zip_code: "43915",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14094",
      city: "Clarksburg",
      zip_code: "43115",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14862",
      city: "Clarksville",
      zip_code: "45113",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14203",
      city: "Clay Center",
      zip_code: "43408",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14961",
      city: "Clayton",
      zip_code: "45315",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14497",
      city: "Cleveland",
      zip_code: "44101",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14822",
      city: "Cleves",
      zip_code: "45002",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14962",
      city: "Clifton",
      zip_code: "45316",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14551",
      city: "Clinton",
      zip_code: "44216",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "15191",
      city: "Cloverdale",
      zip_code: "45827",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14204",
      city: "Clyde",
      zip_code: "43410",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "15133",
      city: "Coal Run",
      zip_code: "45721",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "15070",
      city: "Coalton",
      zip_code: "45621",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "15192",
      city: "Coldwater",
      zip_code: "45828",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14823",
      city: "College Corner",
      zip_code: "45003",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14773",
      city: "Collins",
      zip_code: "44826",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14247",
      city: "Colton",
      zip_code: "43510",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14452",
      city: "Columbia Station",
      zip_code: "44028",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14613",
      city: "Columbiana",
      zip_code: "44408",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14082",
      city: "Columbus",
      zip_code: "43085",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "15193",
      city: "Columbus Grove",
      zip_code: "45830",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14095",
      city: "Commercial Point",
      zip_code: "43116",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14371",
      city: "Conesville",
      zip_code: "43811",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14453",
      city: "Conneaut",
      zip_code: "44030",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14963",
      city: "Conover",
      zip_code: "45317",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "15194",
      city: "Continental",
      zip_code: "45831",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "15195",
      city: "Convoy",
      zip_code: "45832",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "15134",
      city: "Coolville",
      zip_code: "45723",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14327",
      city: "Corning",
      zip_code: "43730",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14614",
      city: "Cortland",
      zip_code: "44410",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14372",
      city: "Coshocton",
      zip_code: "43812",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14964",
      city: "Covington",
      zip_code: "45318",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "15071",
      city: "Creola",
      zip_code: "45622",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14774",
      city: "Crestline",
      zip_code: "44827",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14552",
      city: "Creston",
      zip_code: "44217",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14328",
      city: "Crooksville",
      zip_code: "43731",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14032",
      city: "Croton",
      zip_code: "43013",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "15072",
      city: "Crown City",
      zip_code: "45623",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14329",
      city: "Cumberland",
      zip_code: "43732",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14205",
      city: "Curtice",
      zip_code: "43412",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14248",
      city: "Custar",
      zip_code: "43511",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "15135",
      city: "Cutler",
      zip_code: "45724",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14553",
      city: "Cuyahoga Falls",
      zip_code: "44221",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14206",
      city: "Cygnet",
      zip_code: "43413",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "15073",
      city: "Cynthiana",
      zip_code: "45624",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14683",
      city: "Dalton",
      zip_code: "44618",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14684",
      city: "Damascus",
      zip_code: "44619",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14033",
      city: "Danville",
      zip_code: "43014",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "15025",
      city: "Dayton",
      zip_code: "45402",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14166",
      city: "De Graff",
      zip_code: "43318",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14863",
      city: "Decatur",
      zip_code: "45115",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14615",
      city: "Deerfield",
      zip_code: "44411",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14740",
      city: "Deersville",
      zip_code: "44693",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14249",
      city: "Defiance",
      zip_code: "43512",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14034",
      city: "Delaware",
      zip_code: "43015",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14685",
      city: "Dellroy",
      zip_code: "44620",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "15196",
      city: "Delphos",
      zip_code: "45833",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14250",
      city: "Delta",
      zip_code: "43515",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14686",
      city: "Dennison",
      zip_code: "44621",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14096",
      city: "Derby",
      zip_code: "43117",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14330",
      city: "Derwent",
      zip_code: "43733",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14251",
      city: "Deshler",
      zip_code: "43516",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "15136",
      city: "Dexter City",
      zip_code: "45727",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14616",
      city: "Diamond",
      zip_code: "44412",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14398",
      city: "Dillonvale",
      zip_code: "43917",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "15197",
      city: "Dola",
      zip_code: "45835",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14965",
      city: "Donnelsville",
      zip_code: "45319",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14454",
      city: "Dorset",
      zip_code: "44032",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14687",
      city: "Dover",
      zip_code: "44622",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14556",
      city: "Doylestown",
      zip_code: "44230",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14373",
      city: "Dresden",
      zip_code: "43821",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14035",
      city: "Dublin",
      zip_code: "43016",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14207",
      city: "Dunbridge",
      zip_code: "43414",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14331",
      city: "Duncan Falls",
      zip_code: "43734",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14688",
      city: "Dundee",
      zip_code: "44624",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "15198",
      city: "Dunkirk",
      zip_code: "45836",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14757",
      city: "East Canton",
      zip_code: "44730",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14332",
      city: "East Fultonham",
      zip_code: "43735",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14167",
      city: "East Liberty",
      zip_code: "43319",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14399",
      city: "East Liverpool",
      zip_code: "43920",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14617",
      city: "East Palestine",
      zip_code: "44413",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14689",
      city: "East Rochester",
      zip_code: "44625",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14690",
      city: "East Sparta",
      zip_code: "44626",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14400",
      city: "East Springfield",
      zip_code: "43925",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14495",
      city: "Eastlake",
      zip_code: "44095",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14966",
      city: "Eaton",
      zip_code: "45320",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14252",
      city: "Edgerton",
      zip_code: "43517",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14168",
      city: "Edison",
      zip_code: "43320",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14253",
      city: "Edon",
      zip_code: "43518",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14967",
      city: "Eldorado",
      zip_code: "45321",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "15199",
      city: "Elgin",
      zip_code: "45838",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14618",
      city: "Elkton",
      zip_code: "44415",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14208",
      city: "Elmore",
      zip_code: "43416",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14455",
      city: "Elyria",
      zip_code: "44035",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14401",
      city: "Empire",
      zip_code: "43926",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14968",
      city: "Englewood",
      zip_code: "45322",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14969",
      city: "Enon",
      zip_code: "45323",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14037",
      city: "Etna",
      zip_code: "43018",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14513",
      city: "Euclid",
      zip_code: "44117",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14254",
      city: "Evansport",
      zip_code: "43519",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14970",
      city: "Fairborn",
      zip_code: "45324",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14827",
      city: "Fairfield",
      zip_code: "45014",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14333",
      city: "Fairview",
      zip_code: "43736",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14619",
      city: "Farmdale",
      zip_code: "44417",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14255",
      city: "Farmer",
      zip_code: "43520",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14971",
      city: "Farmersville",
      zip_code: "45325",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14256",
      city: "Fayette",
      zip_code: "43521",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14864",
      city: "Fayetteville",
      zip_code: "45118",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14865",
      city: "Feesburg",
      zip_code: "45119",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14866",
      city: "Felicity",
      zip_code: "45120",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "15200",
      city: "Findlay",
      zip_code: "45840",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14775",
      city: "Flat Rock",
      zip_code: "44828",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "15137",
      city: "Fleming",
      zip_code: "45729",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14972",
      city: "Fletcher",
      zip_code: "45326",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14434",
      city: "Flushing",
      zip_code: "43977",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "15202",
      city: "Forest",
      zip_code: "45843",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "15203",
      city: "Fort Jennings",
      zip_code: "45844",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "15204",
      city: "Fort Loramie",
      zip_code: "45845",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "15205",
      city: "Fort Recovery",
      zip_code: "45846",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14776",
      city: "Fostoria",
      zip_code: "44830",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14620",
      city: "Fowler",
      zip_code: "44418",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "15074",
      city: "Frankfort",
      zip_code: "45628",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14824",
      city: "Franklin",
      zip_code: "45005",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "15075",
      city: "Franklin Furnace",
      zip_code: "45629",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14374",
      city: "Frazeysburg",
      zip_code: "43822",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14691",
      city: "Fredericksburg",
      zip_code: "44627",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14038",
      city: "Fredericktown",
      zip_code: "43019",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14431",
      city: "Freeport",
      zip_code: "43973",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14209",
      city: "Fremont",
      zip_code: "43420",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14375",
      city: "Fresno",
      zip_code: "43824",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "15076",
      city: "Friendship",
      zip_code: "45630",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14169",
      city: "Fulton",
      zip_code: "43321",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14334",
      city: "Fultonham",
      zip_code: "43738",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14039",
      city: "Galena",
      zip_code: "43021",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14777",
      city: "Galion",
      zip_code: "44833",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "15077",
      city: "Gallipolis",
      zip_code: "45631",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14097",
      city: "Galloway",
      zip_code: "43119",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14040",
      city: "Gambier",
      zip_code: "43022",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14557",
      city: "Garrettsville",
      zip_code: "44231",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14457",
      city: "Gates Mills",
      zip_code: "44040",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14458",
      city: "Geneva",
      zip_code: "44041",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14210",
      city: "Genoa",
      zip_code: "43430",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14867",
      city: "Georgetown",
      zip_code: "45121",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14973",
      city: "Germantown",
      zip_code: "45327",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14974",
      city: "Gettysburg",
      zip_code: "45328",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14211",
      city: "Gibsonburg",
      zip_code: "43431",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14621",
      city: "Girard",
      zip_code: "44420",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "15206",
      city: "Glandorf",
      zip_code: "45848",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14402",
      city: "Glencoe",
      zip_code: "43928",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14335",
      city: "Glenford",
      zip_code: "43739",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14692",
      city: "Glenmont",
      zip_code: "44628",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "15138",
      city: "Glouster",
      zip_code: "45732",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14693",
      city: "Gnadenhutten",
      zip_code: "44629",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "15178",
      city: "Gomer",
      zip_code: "45809",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14868",
      city: "Goshen",
      zip_code: "45122",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14459",
      city: "Grafton",
      zip_code: "44044",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14257",
      city: "Grand Rapids",
      zip_code: "43522",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14460",
      city: "Grand River",
      zip_code: "44045",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14041",
      city: "Granville",
      zip_code: "43023",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14336",
      city: "Gratiot",
      zip_code: "43740",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14975",
      city: "Gratis",
      zip_code: "45330",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "15139",
      city: "Graysville",
      zip_code: "45734",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14212",
      city: "Graytown",
      zip_code: "43432",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14558",
      city: "Green",
      zip_code: "44232",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14170",
      city: "Green Camp",
      zip_code: "43322",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14778",
      city: "Green Springs",
      zip_code: "44836",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14869",
      city: "Greenfield",
      zip_code: "45123",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14694",
      city: "Greentown",
      zip_code: "44630",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14976",
      city: "Greenville",
      zip_code: "45331",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14779",
      city: "Greenwich",
      zip_code: "44837",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14258",
      city: "Grelton",
      zip_code: "43523",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14098",
      city: "Grove City",
      zip_code: "43123",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14099",
      city: "Groveport",
      zip_code: "43125",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "15207",
      city: "Grover Hill",
      zip_code: "45849",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "15140",
      city: "Guysville",
      zip_code: "45735",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14213",
      city: "Gypsum",
      zip_code: "43433",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "15078",
      city: "Hamden",
      zip_code: "45634",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14870",
      city: "Hamersville",
      zip_code: "45130",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14825",
      city: "Hamilton",
      zip_code: "45011",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14259",
      city: "Hamler",
      zip_code: "43524",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14403",
      city: "Hammondsville",
      zip_code: "43930",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14404",
      city: "Hannibal",
      zip_code: "43931",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14622",
      city: "Hanoverton",
      zip_code: "44423",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14214",
      city: "Harbor View",
      zip_code: "43434",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14171",
      city: "Harpster",
      zip_code: "43323",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14100",
      city: "Harrisburg",
      zip_code: "43126",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14829",
      city: "Harrison",
      zip_code: "45030",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14432",
      city: "Harrisville",
      zip_code: "43974",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "15208",
      city: "Harrod",
      zip_code: "45850",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14623",
      city: "Hartford",
      zip_code: "44424",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14695",
      city: "Hartville",
      zip_code: "44632",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14830",
      city: "Harveysburg",
      zip_code: "45032",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14260",
      city: "Haskins",
      zip_code: "43525",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "15079",
      city: "Haverhill",
      zip_code: "45636",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "15209",
      city: "Haviland",
      zip_code: "45851",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14101",
      city: "Haydenville",
      zip_code: "43127",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14780",
      city: "Hayesville",
      zip_code: "44838",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14062",
      city: "Heath",
      zip_code: "43056",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14042",
      city: "Hebron",
      zip_code: "43025",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14215",
      city: "Helena",
      zip_code: "43435",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14261",
      city: "Hicksville",
      zip_code: "43526",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14871",
      city: "Higginsport",
      zip_code: "45131",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14872",
      city: "Highland",
      zip_code: "45132",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14043",
      city: "Hilliard",
      zip_code: "43026",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14873",
      city: "Hillsboro",
      zip_code: "45133",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14559",
      city: "Hinckley",
      zip_code: "44233",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14560",
      city: "Hiram",
      zip_code: "44234",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "15141",
      city: "Hockingport",
      zip_code: "45739",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14262",
      city: "Holgate",
      zip_code: "43527",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14263",
      city: "Holland",
      zip_code: "43528",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14977",
      city: "Hollansburg",
      zip_code: "45332",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14437",
      city: "Holloway",
      zip_code: "43985",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14696",
      city: "Holmesville",
      zip_code: "44633",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14044",
      city: "Homer",
      zip_code: "43027",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14561",
      city: "Homerville",
      zip_code: "44235",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14697",
      city: "Homeworth",
      zip_code: "44634",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14831",
      city: "Hooven",
      zip_code: "45033",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14433",
      city: "Hopedale",
      zip_code: "43976",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14337",
      city: "Hopewell",
      zip_code: "43746",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14978",
      city: "Houston",
      zip_code: "45333",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14045",
      city: "Howard",
      zip_code: "43028",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14264",
      city: "Hoytville",
      zip_code: "43529",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14624",
      city: "Hubbard",
      zip_code: "44425",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14562",
      city: "Hudson",
      zip_code: "44236",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14461",
      city: "Huntsburg",
      zip_code: "44046",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14172",
      city: "Huntsville",
      zip_code: "43324",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14781",
      city: "Huron",
      zip_code: "44839",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14173",
      city: "Iberia",
      zip_code: "43325",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14527",
      city: "Independence",
      zip_code: "44131",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14405",
      city: "Irondale",
      zip_code: "43932",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "15080",
      city: "Ironton",
      zip_code: "45638",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14046",
      city: "Irwin",
      zip_code: "43029",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14216",
      city: "Isle Saint George",
      zip_code: "43436",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "15081",
      city: "Jackson",
      zip_code: "45640",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14979",
      city: "Jackson Center",
      zip_code: "45334",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14047",
      city: "Jacksontown",
      zip_code: "43030",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "15142",
      city: "Jacksonville",
      zip_code: "45740",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14406",
      city: "Jacobsburg",
      zip_code: "43933",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14980",
      city: "Jamestown",
      zip_code: "45335",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "15082",
      city: "Jasper",
      zip_code: "45642",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14462",
      city: "Jefferson",
      zip_code: "44047",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14102",
      city: "Jeffersonville",
      zip_code: "43128",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "15201",
      city: "Jenera",
      zip_code: "45841",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14782",
      city: "Jeromesville",
      zip_code: "44840",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14217",
      city: "Jerry City",
      zip_code: "43437",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14338",
      city: "Jerusalem",
      zip_code: "43747",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14265",
      city: "Jewell",
      zip_code: "43530",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14438",
      city: "Jewett",
      zip_code: "43986",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14048",
      city: "Johnstown",
      zip_code: "43031",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14339",
      city: "Junction City",
      zip_code: "43748",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "15210",
      city: "Kalida",
      zip_code: "45853",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14783",
      city: "Kansas",
      zip_code: "44841",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14218",
      city: "Kelleys Island",
      zip_code: "43438",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14625",
      city: "Kensington",
      zip_code: "44427",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14563",
      city: "Kent",
      zip_code: "44240",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14174",
      city: "Kenton",
      zip_code: "43326",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14981",
      city: "Kettlersville",
      zip_code: "45336",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14049",
      city: "Kilbourne",
      zip_code: "43032",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14698",
      city: "Killbuck",
      zip_code: "44637",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14340",
      city: "Kimbolton",
      zip_code: "43749",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14832",
      city: "Kings Mills",
      zip_code: "45034",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "15083",
      city: "Kingston",
      zip_code: "45644",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14463",
      city: "Kingsville",
      zip_code: "44048",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14626",
      city: "Kinsman",
      zip_code: "44428",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14341",
      city: "Kipling",
      zip_code: "43750",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14464",
      city: "Kipton",
      zip_code: "44049",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14175",
      city: "Kirby",
      zip_code: "43330",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14050",
      city: "Kirkersville",
      zip_code: "43033",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "15084",
      city: "Kitts Hill",
      zip_code: "45645",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14266",
      city: "Kunkle",
      zip_code: "43531",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14177",
      city: "La Rue",
      zip_code: "43332",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14219",
      city: "Lacarne",
      zip_code: "43439",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "15211",
      city: "Lafayette",
      zip_code: "45854",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14420",
      city: "Lafferty",
      zip_code: "43951",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14465",
      city: "Lagrange",
      zip_code: "44050",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14627",
      city: "Lake Milton",
      zip_code: "44429",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14566",
      city: "Lakemore",
      zip_code: "44250",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14220",
      city: "Lakeside Marblehead",
      zip_code: "43440",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14176",
      city: "Lakeview",
      zip_code: "43331",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14699",
      city: "Lakeville",
      zip_code: "44638",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14503",
      city: "Lakewood",
      zip_code: "44107",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14103",
      city: "Lancaster",
      zip_code: "43130",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "15143",
      city: "Langsville",
      zip_code: "45741",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14407",
      city: "Lansing",
      zip_code: "43934",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "15085",
      city: "Latham",
      zip_code: "45646",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "15212",
      city: "Latty",
      zip_code: "45855",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14982",
      city: "Laura",
      zip_code: "45337",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14104",
      city: "Laurelville",
      zip_code: "43135",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14628",
      city: "Leavittsburg",
      zip_code: "44430",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14833",
      city: "Lebanon",
      zip_code: "45036",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14874",
      city: "Leesburg",
      zip_code: "45135",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14700",
      city: "Leesville",
      zip_code: "44639",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14629",
      city: "Leetonia",
      zip_code: "44431",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "15213",
      city: "Leipsic",
      zip_code: "45856",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14051",
      city: "Lewis Center",
      zip_code: "43035",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14983",
      city: "Lewisburg",
      zip_code: "45338",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14178",
      city: "Lewistown",
      zip_code: "43333",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14342",
      city: "Lewisville",
      zip_code: "43754",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14267",
      city: "Liberty Center",
      zip_code: "43532",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "15172",
      city: "Lima",
      zip_code: "45801",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14701",
      city: "Limaville",
      zip_code: "44640",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14221",
      city: "Lindsey",
      zip_code: "43442",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14630",
      city: "Lisbon",
      zip_code: "44432",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14568",
      city: "Litchfield",
      zip_code: "44253",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14105",
      city: "Lithopolis",
      zip_code: "43136",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "15144",
      city: "Little Hocking",
      zip_code: "45742",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14106",
      city: "Lockbourne",
      zip_code: "43137",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14569",
      city: "Lodi",
      zip_code: "44254",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14107",
      city: "Logan",
      zip_code: "43138",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14108",
      city: "London",
      zip_code: "43140",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "15086",
      city: "Londonderry",
      zip_code: "45647",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "15145",
      city: "Long Bottom",
      zip_code: "45743",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14466",
      city: "Lorain",
      zip_code: "44052",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14343",
      city: "Lore City",
      zip_code: "43755",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14784",
      city: "Loudonville",
      zip_code: "44842",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14702",
      city: "Louisville",
      zip_code: "44641",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14875",
      city: "Loveland",
      zip_code: "45140",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "15146",
      city: "Lowell",
      zip_code: "45744",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14631",
      city: "Lowellville",
      zip_code: "44436",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "15147",
      city: "Lower Salem",
      zip_code: "45745",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14785",
      city: "Lucas",
      zip_code: "44843",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "15087",
      city: "Lucasville",
      zip_code: "45648",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14222",
      city: "Luckey",
      zip_code: "43443",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14984",
      city: "Ludlow Falls",
      zip_code: "45339",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14876",
      city: "Lynchburg",
      zip_code: "45142",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "15088",
      city: "Lynx",
      zip_code: "45650",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14268",
      city: "Lyons",
      zip_code: "43533",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14470",
      city: "Macedonia",
      zip_code: "44056",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "15148",
      city: "Macksburg",
      zip_code: "45746",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14471",
      city: "Madison",
      zip_code: "44057",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14052",
      city: "Magnetic Springs",
      zip_code: "43036",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14703",
      city: "Magnolia",
      zip_code: "44643",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14834",
      city: "Maineville",
      zip_code: "45039",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14270",
      city: "Malinta",
      zip_code: "43535",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14345",
      city: "Malta",
      zip_code: "43758",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14704",
      city: "Malvern",
      zip_code: "44644",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14877",
      city: "Manchester",
      zip_code: "45144",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14814",
      city: "Mansfield",
      zip_code: "44901",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14570",
      city: "Mantua",
      zip_code: "44255",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14533",
      city: "Maple Heights",
      zip_code: "44137",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14985",
      city: "Maplewood",
      zip_code: "45340",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14179",
      city: "Marengo",
      zip_code: "43334",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "15216",
      city: "Maria Stein",
      zip_code: "45860",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "15149",
      city: "Marietta",
      zip_code: "45750",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14159",
      city: "Marion",
      zip_code: "43302",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14271",
      city: "Mark Center",
      zip_code: "43536",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14705",
      city: "Marshallville",
      zip_code: "44645",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14223",
      city: "Martin",
      zip_code: "43445",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14408",
      city: "Martins Ferry",
      zip_code: "43935",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14053",
      city: "Martinsburg",
      zip_code: "43037",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14878",
      city: "Martinsville",
      zip_code: "45146",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14054",
      city: "Marysville",
      zip_code: "43040",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14835",
      city: "Mason",
      zip_code: "45040",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14706",
      city: "Massillon",
      zip_code: "44646",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14633",
      city: "Masury",
      zip_code: "44438",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14272",
      city: "Maumee",
      zip_code: "43537",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "15089",
      city: "McArthur",
      zip_code: "45651",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14269",
      city: "McClure",
      zip_code: "43534",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "15214",
      city: "McComb",
      zip_code: "45858",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14344",
      city: "Mcconnelsville",
      zip_code: "43756",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14786",
      city: "McCutchenville",
      zip_code: "44844",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "15090",
      city: "McDermott",
      zip_code: "45652",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14632",
      city: "McDonald",
      zip_code: "44437",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "15215",
      city: "McGuffey",
      zip_code: "45859",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14055",
      city: "Mechanicsburg",
      zip_code: "43044",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14708",
      city: "Mechanicstown",
      zip_code: "44651",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14571",
      city: "Medina",
      zip_code: "44256",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14986",
      city: "Medway",
      zip_code: "45341",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "15217",
      city: "Melrose",
      zip_code: "45861",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "15218",
      city: "Mendon",
      zip_code: "45862",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14472",
      city: "Mentor",
      zip_code: "44060",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14634",
      city: "Mesopotamia",
      zip_code: "44439",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14273",
      city: "Metamora",
      zip_code: "43540",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14987",
      city: "Miamisburg",
      zip_code: "45342",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14836",
      city: "Miamitown",
      zip_code: "45041",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14879",
      city: "Miamiville",
      zip_code: "45147",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14224",
      city: "Middle Bass",
      zip_code: "43446",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "15219",
      city: "Middle Point",
      zip_code: "45863",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14709",
      city: "Middlebranch",
      zip_code: "44652",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14180",
      city: "Middleburg",
      zip_code: "43336",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14473",
      city: "Middlefield",
      zip_code: "44062",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "15150",
      city: "Middleport",
      zip_code: "45760",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14837",
      city: "Middletown",
      zip_code: "45042",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14880",
      city: "Midland",
      zip_code: "45148",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14710",
      city: "Midvale",
      zip_code: "44653",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14787",
      city: "Milan",
      zip_code: "44846",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14881",
      city: "Milford",
      zip_code: "45150",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14056",
      city: "Milford Center",
      zip_code: "43045",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14225",
      city: "Millbury",
      zip_code: "43447",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14109",
      city: "Milledgeville",
      zip_code: "43142",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "15220",
      city: "Miller City",
      zip_code: "45864",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14711",
      city: "Millersburg",
      zip_code: "44654",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14057",
      city: "Millersport",
      zip_code: "43046",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "15151",
      city: "Millfield",
      zip_code: "45761",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14274",
      city: "Milton Center",
      zip_code: "43541",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14712",
      city: "Mineral City",
      zip_code: "44656",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14635",
      city: "Mineral Ridge",
      zip_code: "44440",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14713",
      city: "Minerva",
      zip_code: "44657",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "15091",
      city: "Minford",
      zip_code: "45653",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14058",
      city: "Mingo",
      zip_code: "43047",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14409",
      city: "Mingo Junction",
      zip_code: "43938",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "15221",
      city: "Minster",
      zip_code: "45865",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14572",
      city: "Mogadore",
      zip_code: "44260",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14275",
      city: "Monclova",
      zip_code: "43542",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14839",
      city: "Monroe",
      zip_code: "45050",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14788",
      city: "Monroeville",
      zip_code: "44847",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "15222",
      city: "Montezuma",
      zip_code: "45866",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14276",
      city: "Montpelier",
      zip_code: "43543",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14474",
      city: "Montville",
      zip_code: "44064",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14181",
      city: "Morral",
      zip_code: "43337",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14346",
      city: "Morristown",
      zip_code: "43759",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14882",
      city: "Morrow",
      zip_code: "45152",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14883",
      city: "Moscow",
      zip_code: "45153",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "15223",
      city: "Mount Blanchard",
      zip_code: "45867",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "15224",
      city: "Mount Cory",
      zip_code: "45868",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14714",
      city: "Mount Eaton",
      zip_code: "44659",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14182",
      city: "Mount Gilead",
      zip_code: "43338",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14715",
      city: "Mount Hope",
      zip_code: "44660",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14884",
      city: "Mount Orab",
      zip_code: "45154",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14347",
      city: "Mount Perry",
      zip_code: "43760",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14410",
      city: "Mount Pleasant",
      zip_code: "43939",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14840",
      city: "Mount Saint Joseph",
      zip_code: "45051",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14110",
      city: "Mount Sterling",
      zip_code: "43143",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14059",
      city: "Mount Vernon",
      zip_code: "43050",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14183",
      city: "Mount Victory",
      zip_code: "43340",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14885",
      city: "Mowrystown",
      zip_code: "45155",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14348",
      city: "Moxahala",
      zip_code: "43761",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14573",
      city: "Munroe Falls",
      zip_code: "44262",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14111",
      city: "Murray City",
      zip_code: "43144",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14277",
      city: "Napoleon",
      zip_code: "43545",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14376",
      city: "Nashport",
      zip_code: "43830",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14716",
      city: "Nashville",
      zip_code: "44661",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14717",
      city: "Navarre",
      zip_code: "44662",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14278",
      city: "Neapolis",
      zip_code: "43547",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14411",
      city: "Neffs",
      zip_code: "43940",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14636",
      city: "Negley",
      zip_code: "44441",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "15152",
      city: "Nelsonville",
      zip_code: "45764",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14789",
      city: "Nevada",
      zip_code: "44849",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14886",
      city: "Neville",
      zip_code: "45156",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14060",
      city: "New Albany",
      zip_code: "43054",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14435",
      city: "New Athens",
      zip_code: "43981",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14279",
      city: "New Bavaria",
      zip_code: "43548",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14184",
      city: "New Bloomington",
      zip_code: "43341",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "15225",
      city: "New Bremen",
      zip_code: "45869",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14988",
      city: "New Carlisle",
      zip_code: "45344",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14349",
      city: "New Concord",
      zip_code: "43762",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "15226",
      city: "New Hampshire",
      zip_code: "45870",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14790",
      city: "New Haven",
      zip_code: "44850",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14112",
      city: "New Holland",
      zip_code: "43145",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "15227",
      city: "New Knoxville",
      zip_code: "45871",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14989",
      city: "New Lebanon",
      zip_code: "45345",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14350",
      city: "New Lexington",
      zip_code: "43764",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14791",
      city: "New London",
      zip_code: "44851",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14990",
      city: "New Madison",
      zip_code: "45346",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "15153",
      city: "New Marshfield",
      zip_code: "45766",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "15154",
      city: "New Matamoras",
      zip_code: "45767",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14637",
      city: "New Middletown",
      zip_code: "44442",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14991",
      city: "New Paris",
      zip_code: "45347",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14718",
      city: "New Philadelphia",
      zip_code: "44663",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "15092",
      city: "New Plymouth",
      zip_code: "45654",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14887",
      city: "New Richmond",
      zip_code: "45157",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14792",
      city: "New Riegel",
      zip_code: "44853",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14638",
      city: "New Springfield",
      zip_code: "44443",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14351",
      city: "New Straitsville",
      zip_code: "43766",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14889",
      city: "New Vienna",
      zip_code: "45159",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14793",
      city: "New Washington",
      zip_code: "44854",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14640",
      city: "New Waterford",
      zip_code: "44445",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14992",
      city: "New Weston",
      zip_code: "45348",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14061",
      city: "Newark",
      zip_code: "43055",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14475",
      city: "Newbury",
      zip_code: "44065",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14377",
      city: "Newcomerstown",
      zip_code: "43832",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "15155",
      city: "Newport",
      zip_code: "45768",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14639",
      city: "Newton Falls",
      zip_code: "44444",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14888",
      city: "Newtonsville",
      zip_code: "45158",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14280",
      city: "Ney",
      zip_code: "43549",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14641",
      city: "Niles",
      zip_code: "44446",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "15228",
      city: "North Baltimore",
      zip_code: "45872",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14841",
      city: "North Bend",
      zip_code: "45052",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14642",
      city: "North Benton",
      zip_code: "44449",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14643",
      city: "North Bloomfield",
      zip_code: "44450",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14755",
      city: "North Canton",
      zip_code: "44720",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14794",
      city: "North Fairfield",
      zip_code: "44855",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14719",
      city: "North Georgetown",
      zip_code: "44665",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14993",
      city: "North Hampton",
      zip_code: "45349",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14644",
      city: "North Jackson",
      zip_code: "44451",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14477",
      city: "North Kingsville",
      zip_code: "44068",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14720",
      city: "North Lawrence",
      zip_code: "44666",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14063",
      city: "North Lewisburg",
      zip_code: "43060",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14645",
      city: "North Lima",
      zip_code: "44452",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14478",
      city: "North Olmsted",
      zip_code: "44070",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14456",
      city: "North Ridgeville",
      zip_code: "44039",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14795",
      city: "North Robinson",
      zip_code: "44856",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14529",
      city: "North Royalton",
      zip_code: "44133",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14994",
      city: "North Star",
      zip_code: "45350",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14476",
      city: "Northfield",
      zip_code: "44067",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14309",
      city: "Northwood",
      zip_code: "43619",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14796",
      city: "Norwalk",
      zip_code: "44857",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14352",
      city: "Norwich",
      zip_code: "43767",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14797",
      city: "Nova",
      zip_code: "44859",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14479",
      city: "Novelty",
      zip_code: "44072",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14226",
      city: "Oak Harbor",
      zip_code: "43449",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "15093",
      city: "Oak Hill",
      zip_code: "45656",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "15229",
      city: "Oakwood",
      zip_code: "45873",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14480",
      city: "Oberlin",
      zip_code: "44074",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "15230",
      city: "Ohio City",
      zip_code: "45874",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14842",
      city: "Okeana",
      zip_code: "45053",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14798",
      city: "Old Fort",
      zip_code: "44861",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14353",
      city: "Old Washington",
      zip_code: "43768",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14534",
      city: "Olmsted Falls",
      zip_code: "44138",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14307",
      city: "Oregon",
      zip_code: "43616",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14843",
      city: "Oregonia",
      zip_code: "45054",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14113",
      city: "Orient",
      zip_code: "43146",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14721",
      city: "Orrville",
      zip_code: "44667",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14481",
      city: "Orwell",
      zip_code: "44076",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14995",
      city: "Osgood",
      zip_code: "45351",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14064",
      city: "Ostrander",
      zip_code: "43061",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "15231",
      city: "Ottawa",
      zip_code: "45875",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "15232",
      city: "Ottoville",
      zip_code: "45876",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "15094",
      city: "Otway",
      zip_code: "45657",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14844",
      city: "Overpeck",
      zip_code: "45055",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14890",
      city: "Owensville",
      zip_code: "45160",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14845",
      city: "Oxford",
      zip_code: "45056",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14482",
      city: "Painesville",
      zip_code: "44077",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14996",
      city: "Palestine",
      zip_code: "45352",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "15233",
      city: "Pandora",
      zip_code: "45877",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14722",
      city: "Paris",
      zip_code: "44669",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14483",
      city: "Parkman",
      zip_code: "44080",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14065",
      city: "Pataskala",
      zip_code: "43062",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "15095",
      city: "Patriot",
      zip_code: "45658",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "15234",
      city: "Paulding",
      zip_code: "45879",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "15235",
      city: "Payne",
      zip_code: "45880",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "15096",
      city: "Pedro",
      zip_code: "45659",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "15097",
      city: "Peebles",
      zip_code: "45660",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14997",
      city: "Pemberton",
      zip_code: "45353",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14227",
      city: "Pemberville",
      zip_code: "43450",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14574",
      city: "Peninsula",
      zip_code: "44264",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14484",
      city: "Perry",
      zip_code: "44081",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14281",
      city: "Perrysburg",
      zip_code: "43551",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14799",
      city: "Perrysville",
      zip_code: "44864",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14646",
      city: "Petersburg",
      zip_code: "44454",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14282",
      city: "Pettisville",
      zip_code: "43553",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14998",
      city: "Phillipsburg",
      zip_code: "45354",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14354",
      city: "Philo",
      zip_code: "43771",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14114",
      city: "Pickerington",
      zip_code: "43147",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14436",
      city: "Piedmont",
      zip_code: "43983",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14485",
      city: "Pierpont",
      zip_code: "44082",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "15098",
      city: "Piketon",
      zip_code: "45661",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14283",
      city: "Pioneer",
      zip_code: "43554",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14999",
      city: "Piqua",
      zip_code: "45356",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "15000",
      city: "Pitsburg",
      zip_code: "45358",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14066",
      city: "Plain City",
      zip_code: "43064",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14378",
      city: "Plainfield",
      zip_code: "43836",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14355",
      city: "Pleasant City",
      zip_code: "43772",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "15001",
      city: "Pleasant Hill",
      zip_code: "45359",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14891",
      city: "Pleasant Plain",
      zip_code: "45162",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14115",
      city: "Pleasantville",
      zip_code: "43148",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14800",
      city: "Plymouth",
      zip_code: "44865",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14801",
      city: "Polk",
      zip_code: "44866",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "15156",
      city: "Pomeroy",
      zip_code: "45769",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14229",
      city: "Port Clinton",
      zip_code: "43452",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "15002",
      city: "Port Jefferson",
      zip_code: "45360",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14379",
      city: "Port Washington",
      zip_code: "43837",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14892",
      city: "Port William",
      zip_code: "45164",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14228",
      city: "Portage",
      zip_code: "43451",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "15157",
      city: "Portland",
      zip_code: "45770",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "15099",
      city: "Portsmouth",
      zip_code: "45662",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "15003",
      city: "Potsdam",
      zip_code: "45361",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14067",
      city: "Powell",
      zip_code: "43065",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14412",
      city: "Powhatan Point",
      zip_code: "43942",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "15101",
      city: "Proctorville",
      zip_code: "45669",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14185",
      city: "Prospect",
      zip_code: "43342",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14230",
      city: "Put In Bay",
      zip_code: "43456",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14356",
      city: "Quaker City",
      zip_code: "43773",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14186",
      city: "Quincy",
      zip_code: "43343",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "15158",
      city: "Racine",
      zip_code: "45771",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14068",
      city: "Radnor",
      zip_code: "43066",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "15102",
      city: "Rarden",
      zip_code: "45671",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14575",
      city: "Ravenna",
      zip_code: "44266",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "15236",
      city: "Rawson",
      zip_code: "45881",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "15103",
      city: "Ray",
      zip_code: "45672",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14413",
      city: "Rayland",
      zip_code: "43943",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14069",
      city: "Raymond",
      zip_code: "43067",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "15159",
      city: "Reedsville",
      zip_code: "45772",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14893",
      city: "Reesville",
      zip_code: "45166",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "15160",
      city: "Reno",
      zip_code: "45773",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14802",
      city: "Republic",
      zip_code: "44867",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14070",
      city: "Reynoldsburg",
      zip_code: "43068",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14586",
      city: "Richfield",
      zip_code: "44286",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14414",
      city: "Richmond",
      zip_code: "43944",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "15104",
      city: "Richmond Dale",
      zip_code: "45673",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14187",
      city: "Richwood",
      zip_code: "43344",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14284",
      city: "Ridgeville Corners",
      zip_code: "43555",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14188",
      city: "Ridgeway",
      zip_code: "43345",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "15105",
      city: "Rio Grande",
      zip_code: "45674",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14894",
      city: "Ripley",
      zip_code: "45167",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14231",
      city: "Risingsun",
      zip_code: "43457",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14576",
      city: "Rittman",
      zip_code: "44270",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14723",
      city: "Robertsville",
      zip_code: "44670",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14486",
      city: "Rock Creek",
      zip_code: "44084",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14116",
      city: "Rockbridge",
      zip_code: "43149",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "15237",
      city: "Rockford",
      zip_code: "45882",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14232",
      city: "Rocky Ridge",
      zip_code: "43458",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14512",
      city: "Rocky River",
      zip_code: "44116",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14647",
      city: "Rogers",
      zip_code: "44455",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14487",
      city: "Rome",
      zip_code: "44085",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14577",
      city: "Rootstown",
      zip_code: "44272",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14357",
      city: "Roseville",
      zip_code: "43777",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14071",
      city: "Rosewood",
      zip_code: "43070",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "15004",
      city: "Rossburg",
      zip_code: "45362",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14233",
      city: "Rossford",
      zip_code: "43460",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14189",
      city: "Roundhead",
      zip_code: "43346",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14234",
      city: "Rudolph",
      zip_code: "43462",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14190",
      city: "Rushsylvania",
      zip_code: "43347",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14117",
      city: "Rushville",
      zip_code: "43150",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14191",
      city: "Russells Point",
      zip_code: "43348",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14895",
      city: "Russellville",
      zip_code: "45168",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "15005",
      city: "Russia",
      zip_code: "45363",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "15161",
      city: "Rutland",
      zip_code: "45775",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14896",
      city: "Sabina",
      zip_code: "45169",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14419",
      city: "Saint Clairsville",
      zip_code: "43950",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "15238",
      city: "Saint Henry",
      zip_code: "45883",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "15239",
      city: "Saint Johns",
      zip_code: "45884",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14072",
      city: "Saint Louisville",
      zip_code: "43071",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "15240",
      city: "Saint Marys",
      zip_code: "45885",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14073",
      city: "Saint Paris",
      zip_code: "43072",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14648",
      city: "Salem",
      zip_code: "44460",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14358",
      city: "Salesville",
      zip_code: "43778",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14415",
      city: "Salineville",
      zip_code: "43945",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14803",
      city: "Sandusky",
      zip_code: "44870",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14724",
      city: "Sandyville",
      zip_code: "44671",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14359",
      city: "Sarahsville",
      zip_code: "43779",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14897",
      city: "Sardinia",
      zip_code: "45171",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14416",
      city: "Sardis",
      zip_code: "43946",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14804",
      city: "Savannah",
      zip_code: "44874",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14439",
      city: "Scio",
      zip_code: "43988",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "15106",
      city: "Scioto Furnace",
      zip_code: "45677",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "15241",
      city: "Scott",
      zip_code: "45886",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "15107",
      city: "Scottown",
      zip_code: "45678",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "15108",
      city: "Seaman",
      zip_code: "45679",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14725",
      city: "Sebring",
      zip_code: "44672",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14118",
      city: "Sedalia",
      zip_code: "43151",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14360",
      city: "Senecaville",
      zip_code: "43780",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14846",
      city: "Seven Mile",
      zip_code: "45062",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14578",
      city: "Seville",
      zip_code: "44273",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "15162",
      city: "Shade",
      zip_code: "45776",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14417",
      city: "Shadyside",
      zip_code: "43947",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14579",
      city: "Sharon Center",
      zip_code: "44274",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14361",
      city: "Shawnee",
      zip_code: "43782",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14468",
      city: "Sheffield Lake",
      zip_code: "44054",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14805",
      city: "Shelby",
      zip_code: "44875",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14726",
      city: "Sherrodsville",
      zip_code: "44675",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14285",
      city: "Sherwood",
      zip_code: "43556",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14806",
      city: "Shiloh",
      zip_code: "44878",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14727",
      city: "Shreve",
      zip_code: "44676",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "15006",
      city: "Sidney",
      zip_code: "45365",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14898",
      city: "Sinking Spring",
      zip_code: "45172",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14418",
      city: "Smithfield",
      zip_code: "43948",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14728",
      city: "Smithville",
      zip_code: "44677",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14535",
      city: "Solon",
      zip_code: "44139",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14729",
      city: "Somerdale",
      zip_code: "44678",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14362",
      city: "Somerset",
      zip_code: "43783",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14847",
      city: "Somerville",
      zip_code: "45064",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14119",
      city: "South Bloomingville",
      zip_code: "43152",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "15007",
      city: "South Charleston",
      zip_code: "45368",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14848",
      city: "South Lebanon",
      zip_code: "45065",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "15109",
      city: "South Point",
      zip_code: "45680",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "15110",
      city: "South Salem",
      zip_code: "45681",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14120",
      city: "South Solon",
      zip_code: "43153",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "15008",
      city: "South Vienna",
      zip_code: "45369",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "15111",
      city: "South Webster",
      zip_code: "45682",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14649",
      city: "Southington",
      zip_code: "44470",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14192",
      city: "Sparta",
      zip_code: "43350",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14580",
      city: "Spencer",
      zip_code: "44275",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "15242",
      city: "Spencerville",
      zip_code: "45887",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "15009",
      city: "Spring Valley",
      zip_code: "45370",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14849",
      city: "Springboro",
      zip_code: "45066",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "15055",
      city: "Springfield",
      zip_code: "45501",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14363",
      city: "Stafford",
      zip_code: "43786",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14581",
      city: "Sterling",
      zip_code: "44276",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14421",
      city: "Steubenville",
      zip_code: "43952",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "15163",
      city: "Stewart",
      zip_code: "45778",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14364",
      city: "Stockport",
      zip_code: "43787",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14380",
      city: "Stone Creek",
      zip_code: "43840",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14235",
      city: "Stony Ridge",
      zip_code: "43463",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "15112",
      city: "Stout",
      zip_code: "45684",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14121",
      city: "Stoutsville",
      zip_code: "43154",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14555",
      city: "Stow",
      zip_code: "44224",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14730",
      city: "Strasburg",
      zip_code: "44680",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14423",
      city: "Stratton",
      zip_code: "43961",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14564",
      city: "Streetsboro",
      zip_code: "44241",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14532",
      city: "Strongsville",
      zip_code: "44136",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14650",
      city: "Struthers",
      zip_code: "44471",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14286",
      city: "Stryker",
      zip_code: "43557",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14122",
      city: "Sugar Grove",
      zip_code: "43155",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14731",
      city: "Sugarcreek",
      zip_code: "44681",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14807",
      city: "Sullivan",
      zip_code: "44880",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14808",
      city: "Sulphur Springs",
      zip_code: "44881",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14365",
      city: "Summerfield",
      zip_code: "43788",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14424",
      city: "Summitville",
      zip_code: "43962",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14074",
      city: "Sunbury",
      zip_code: "43074",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14287",
      city: "Swanton",
      zip_code: "43558",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14809",
      city: "Sycamore",
      zip_code: "44882",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14288",
      city: "Sylvania",
      zip_code: "43560",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "15164",
      city: "Syracuse",
      zip_code: "45779",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14582",
      city: "Tallmadge",
      zip_code: "44278",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14123",
      city: "Tarlton",
      zip_code: "43156",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14899",
      city: "Terrace Park",
      zip_code: "45174",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "15165",
      city: "The Plains",
      zip_code: "45780",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14488",
      city: "Thompson",
      zip_code: "44086",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14075",
      city: "Thornville",
      zip_code: "43076",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "15113",
      city: "Thurman",
      zip_code: "45685",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14124",
      city: "Thurston",
      zip_code: "43157",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14810",
      city: "Tiffin",
      zip_code: "44883",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14425",
      city: "Tiltonsville",
      zip_code: "43963",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "15010",
      city: "Tipp City",
      zip_code: "45371",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14743",
      city: "Tippecanoe",
      zip_code: "44699",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14811",
      city: "Tiro",
      zip_code: "44887",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14295",
      city: "Toledo",
      zip_code: "43604",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14289",
      city: "Tontogany",
      zip_code: "43565",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14426",
      city: "Toronto",
      zip_code: "43964",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "15011",
      city: "Tremont City",
      zip_code: "45372",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14850",
      city: "Trenton",
      zip_code: "45067",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "15166",
      city: "Trimble",
      zip_code: "45782",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14381",
      city: "Trinway",
      zip_code: "43842",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "15012",
      city: "Troy",
      zip_code: "45373",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "15167",
      city: "Tuppers Plains",
      zip_code: "45783",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14732",
      city: "Tuscarawas",
      zip_code: "44682",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14489",
      city: "Twinsburg",
      zip_code: "44087",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14733",
      city: "Uhrichsville",
      zip_code: "44683",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "15024",
      city: "Union City",
      zip_code: "45390",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14125",
      city: "Union Furnace",
      zip_code: "43158",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14734",
      city: "Uniontown",
      zip_code: "44685",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14076",
      city: "Unionville Center",
      zip_code: "43077",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "15243",
      city: "Uniopolis",
      zip_code: "45888",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14193",
      city: "Upper Sandusky",
      zip_code: "43351",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14077",
      city: "Urbana",
      zip_code: "43078",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14078",
      city: "Utica",
      zip_code: "43080",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14583",
      city: "Valley City",
      zip_code: "44280",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "15244",
      city: "Van Buren",
      zip_code: "45889",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "15246",
      city: "Van Wert",
      zip_code: "45891",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "15013",
      city: "Vandalia",
      zip_code: "45377",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "15245",
      city: "Vanlue",
      zip_code: "45890",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "15247",
      city: "Vaughnsville",
      zip_code: "45893",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "15248",
      city: "Venedocia",
      zip_code: "45894",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14490",
      city: "Vermilion",
      zip_code: "44089",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "15014",
      city: "Verona",
      zip_code: "45378",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "15015",
      city: "Versailles",
      zip_code: "45380",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14236",
      city: "Vickery",
      zip_code: "43464",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14651",
      city: "Vienna",
      zip_code: "44473",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "15168",
      city: "Vincent",
      zip_code: "45784",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "15114",
      city: "Vinton",
      zip_code: "45686",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14584",
      city: "Wadsworth",
      zip_code: "44281",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14812",
      city: "Wakeman",
      zip_code: "44889",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14237",
      city: "Walbridge",
      zip_code: "43465",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14194",
      city: "Waldo",
      zip_code: "43356",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14382",
      city: "Walhonding",
      zip_code: "43843",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14735",
      city: "Walnut Creek",
      zip_code: "44687",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "15249",
      city: "Wapakoneta",
      zip_code: "45895",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14427",
      city: "Warnock",
      zip_code: "43967",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14652",
      city: "Warren",
      zip_code: "44481",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14383",
      city: "Warsaw",
      zip_code: "43844",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14126",
      city: "Washington Court House",
      zip_code: "43160",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14656",
      city: "Washingtonville",
      zip_code: "44490",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "15169",
      city: "Waterford",
      zip_code: "45786",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "15115",
      city: "Waterloo",
      zip_code: "45688",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14290",
      city: "Waterville",
      zip_code: "43566",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14291",
      city: "Wauseon",
      zip_code: "43567",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "15116",
      city: "Waverly",
      zip_code: "45690",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14585",
      city: "Wayland",
      zip_code: "44285",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14238",
      city: "Wayne",
      zip_code: "43466",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14736",
      city: "Waynesburg",
      zip_code: "44688",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "15250",
      city: "Waynesfield",
      zip_code: "45896",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14851",
      city: "Waynesville",
      zip_code: "45068",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14491",
      city: "Wellington",
      zip_code: "44090",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "15117",
      city: "Wellston",
      zip_code: "45692",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14428",
      city: "Wellsville",
      zip_code: "43968",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "15016",
      city: "West Alexandria",
      zip_code: "45381",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14852",
      city: "West Chester",
      zip_code: "45069",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14853",
      city: "West Elkton",
      zip_code: "45070",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14657",
      city: "West Farmington",
      zip_code: "44491",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14127",
      city: "West Jefferson",
      zip_code: "43162",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14384",
      city: "West Lafayette",
      zip_code: "43845",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14195",
      city: "West Liberty",
      zip_code: "43357",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "15017",
      city: "West Manchester",
      zip_code: "45382",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14196",
      city: "West Mansfield",
      zip_code: "43358",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14239",
      city: "West Millgrove",
      zip_code: "43467",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "15018",
      city: "West Milton",
      zip_code: "45383",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "15100",
      city: "West Portsmouth",
      zip_code: "45663",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14587",
      city: "West Salem",
      zip_code: "44287",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "15118",
      city: "West Union",
      zip_code: "45693",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14293",
      city: "West Unity",
      zip_code: "43570",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14079",
      city: "Westerville",
      zip_code: "43081",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14567",
      city: "Westfield Center",
      zip_code: "44251",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14541",
      city: "Westlake",
      zip_code: "44145",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14292",
      city: "Weston",
      zip_code: "43569",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14197",
      city: "Wharton",
      zip_code: "43359",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "15119",
      city: "Wheelersburg",
      zip_code: "45694",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "15170",
      city: "Whipple",
      zip_code: "45788",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14366",
      city: "White Cottage",
      zip_code: "43791",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14294",
      city: "Whitehouse",
      zip_code: "43571",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14492",
      city: "Wickliffe",
      zip_code: "44092",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "15019",
      city: "Wilberforce",
      zip_code: "45384",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "15120",
      city: "Wilkesville",
      zip_code: "45695",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14813",
      city: "Willard",
      zip_code: "44890",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14900",
      city: "Williamsburg",
      zip_code: "45176",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14493",
      city: "Williamsfield",
      zip_code: "44093",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14128",
      city: "Williamsport",
      zip_code: "43164",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "15251",
      city: "Williamstown",
      zip_code: "45897",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14240",
      city: "Williston",
      zip_code: "43468",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14494",
      city: "Willoughby",
      zip_code: "44094",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "15121",
      city: "Willow Wood",
      zip_code: "45696",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "15252",
      city: "Willshire",
      zip_code: "45898",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14901",
      city: "Wilmington",
      zip_code: "45177",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14737",
      city: "Wilmot",
      zip_code: "44689",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "15122",
      city: "Winchester",
      zip_code: "45697",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14588",
      city: "Windham",
      zip_code: "44288",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14496",
      city: "Windsor",
      zip_code: "44099",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14738",
      city: "Winesburg",
      zip_code: "44690",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "15171",
      city: "Wingett Run",
      zip_code: "45789",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14658",
      city: "Winona",
      zip_code: "44493",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14367",
      city: "Woodsfield",
      zip_code: "43793",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14081",
      city: "Woodstock",
      zip_code: "43084",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14241",
      city: "Woodville",
      zip_code: "43469",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14739",
      city: "Wooster",
      zip_code: "44691",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "15253",
      city: "Wren",
      zip_code: "45899",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "15020",
      city: "Xenia",
      zip_code: "45385",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "15021",
      city: "Yellow Springs",
      zip_code: "45387",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "15022",
      city: "Yorkshire",
      zip_code: "45388",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14429",
      city: "Yorkville",
      zip_code: "43971",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14659",
      city: "Youngstown",
      zip_code: "44502",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "15123",
      city: "Zaleski",
      zip_code: "45698",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14198",
      city: "Zanesfield",
      zip_code: "43360",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14312",
      city: "Zanesville",
      zip_code: "43701",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "14742",
      city: "Zoar",
      zip_code: "44697",
      state_id: "OH",
      state_name: "Ohio",
    },
    {
      city_id: "25997",
      city: "Mooreland",
      zip_code: "73852",
      state_id: "OK",
      state_name: "Oklahoma",
    },
    {
      city_id: "5217",
      city: "Aaronsburg",
      zip_code: "16820",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5446",
      city: "Abbottstown",
      zip_code: "17301",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "6004",
      city: "Abington",
      zip_code: "19001",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "4712",
      city: "Acme",
      zip_code: "15610",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "4677",
      city: "Acosta",
      zip_code: "15520",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "4605",
      city: "Adah",
      zip_code: "15410",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "4713",
      city: "Adamsburg",
      zip_code: "15611",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "6188",
      city: "Adamstown",
      zip_code: "19501",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "4957",
      city: "Adamsville",
      zip_code: "16110",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "4606",
      city: "Addison",
      zip_code: "15411",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "4997",
      city: "Adrian",
      zip_code: "16210",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5447",
      city: "Airville",
      zip_code: "17302",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5501",
      city: "Akron",
      zip_code: "17501",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5270",
      city: "Alba",
      zip_code: "16910",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5078",
      city: "Albion",
      zip_code: "16401",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5755",
      city: "Albrightsville",
      zip_code: "18210",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5691",
      city: "Alburtis",
      zip_code: "18011",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "4550",
      city: "Aleppo",
      zip_code: "15310",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5127",
      city: "Alexandria",
      zip_code: "16611",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "4407",
      city: "Aliquippa",
      zip_code: "15001",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "4607",
      city: "Allenport",
      zip_code: "15412",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5301",
      city: "Allensville",
      zip_code: "17002",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5746",
      city: "Allentown",
      zip_code: "18101",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5593",
      city: "Allenwood",
      zip_code: "17810",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "4608",
      city: "Allison",
      zip_code: "15413",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "4478",
      city: "Allison Park",
      zip_code: "15101",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5218",
      city: "Allport",
      zip_code: "16821",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5125",
      city: "Altoona",
      zip_code: "16601",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "4678",
      city: "Alum Bank",
      zip_code: "15521",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "4785",
      city: "Alverda",
      zip_code: "15710",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "4714",
      city: "Alverton",
      zip_code: "15612",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5399",
      city: "Amberson",
      zip_code: "17210",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "6005",
      city: "Ambler",
      zip_code: "19002",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "4408",
      city: "Ambridge",
      zip_code: "15003",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "4551",
      city: "Amity",
      zip_code: "15311",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5756",
      city: "Andreas",
      zip_code: "18211",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "4786",
      city: "Anita",
      zip_code: "15711",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5302",
      city: "Annville",
      zip_code: "17003",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5554",
      city: "Antes Fort",
      zip_code: "17720",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "4715",
      city: "Apollo",
      zip_code: "15613",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5692",
      city: "Aquashicola",
      zip_code: "18012",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "4787",
      city: "Arcadia",
      zip_code: "15712",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5825",
      city: "Archbald",
      zip_code: "18403",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "4716",
      city: "Ardara",
      zip_code: "15615",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "6006",
      city: "Ardmore",
      zip_code: "19003",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5448",
      city: "Arendtsville",
      zip_code: "17303",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5647",
      city: "Aristes",
      zip_code: "17920",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "4882",
      city: "Armagh",
      zip_code: "15920",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "4717",
      city: "Armbrust",
      zip_code: "15616",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5271",
      city: "Arnot",
      zip_code: "16911",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "4718",
      city: "Arona",
      zip_code: "15617",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5400",
      city: "Artemas",
      zip_code: "17211",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5757",
      city: "Ashfield",
      zip_code: "18212",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5648",
      city: "Ashland",
      zip_code: "17921",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5128",
      city: "Ashville",
      zip_code: "16613",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5449",
      city: "Aspers",
      zip_code: "17304",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "6015",
      city: "Aston",
      zip_code: "19014",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "6123",
      city: "Atglen",
      zip_code: "19310",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5932",
      city: "Athens",
      zip_code: "18810",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "4958",
      city: "Atlantic",
      zip_code: "16111",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "4409",
      city: "Atlasburg",
      zip_code: "15004",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5649",
      city: "Auburn",
      zip_code: "17922",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "4788",
      city: "Aultman",
      zip_code: "15713",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5193",
      city: "Austin",
      zip_code: "16720",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "4552",
      city: "Avella",
      zip_code: "15312",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5555",
      city: "Avis",
      zip_code: "17721",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "6124",
      city: "Avondale",
      zip_code: "19311",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "4719",
      city: "Avonmore",
      zip_code: "15618",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "4410",
      city: "Baden",
      zip_code: "15005",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5502",
      city: "Bainbridge",
      zip_code: "17502",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "4411",
      city: "Bairdford",
      zip_code: "15006",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "4412",
      city: "Bakerstown",
      zip_code: "15007",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "6007",
      city: "Bala Cynwyd",
      zip_code: "19004",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "6189",
      city: "Bally",
      zip_code: "19503",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5693",
      city: "Bangor",
      zip_code: "18013",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5758",
      city: "Barnesville",
      zip_code: "18214",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "6190",
      city: "Barto",
      zip_code: "19504",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5791",
      city: "Bartonsville",
      zip_code: "18321",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5694",
      city: "Bath",
      zip_code: "18014",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5503",
      city: "Bausman",
      zip_code: "17504",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5826",
      city: "Beach Lake",
      zip_code: "18405",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "4553",
      city: "Beallsville",
      zip_code: "15313",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5887",
      city: "Bear Creek",
      zip_code: "18602",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5079",
      city: "Bear Lake",
      zip_code: "16402",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "4413",
      city: "Beaver",
      zip_code: "15009",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "4414",
      city: "Beaver Falls",
      zip_code: "15010",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5759",
      city: "Beaver Meadows",
      zip_code: "18216",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5594",
      city: "Beaver Springs",
      zip_code: "17812",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "4883",
      city: "Beaverdale",
      zip_code: "15921",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5595",
      city: "Beavertown",
      zip_code: "17813",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5129",
      city: "Beccaria",
      zip_code: "16616",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "6191",
      city: "Bechtelsville",
      zip_code: "19505",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "4679",
      city: "Bedford",
      zip_code: "15522",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5219",
      city: "Beech Creek",
      zip_code: "16822",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "4415",
      city: "Belle Vernon",
      zip_code: "15012",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5220",
      city: "Bellefonte",
      zip_code: "16823",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5303",
      city: "Belleville",
      zip_code: "17004",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5130",
      city: "Bellwood",
      zip_code: "16617",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "4884",
      city: "Belsano",
      zip_code: "15922",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5450",
      city: "Bendersville",
      zip_code: "17306",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "4847",
      city: "Benezett",
      zip_code: "15821",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "6019",
      city: "Bensalem",
      zip_code: "19020",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "4554",
      city: "Bentleyville",
      zip_code: "15314",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5596",
      city: "Benton",
      zip_code: "17814",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "4680",
      city: "Berlin",
      zip_code: "15530",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "6192",
      city: "Bernville",
      zip_code: "19506",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5304",
      city: "Berrysburg",
      zip_code: "17005",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5888",
      city: "Berwick",
      zip_code: "18603",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "6125",
      city: "Berwyn",
      zip_code: "19312",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "4959",
      city: "Bessemer",
      zip_code: "16112",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "6193",
      city: "Bethel",
      zip_code: "19507",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "4479",
      city: "Bethel Park",
      zip_code: "15102",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5695",
      city: "Bethlehem",
      zip_code: "18015",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "4998",
      city: "Beyer",
      zip_code: "16211",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5401",
      city: "Big Cove Tannery",
      zip_code: "17212",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "4790",
      city: "Big Run",
      zip_code: "15715",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5221",
      city: "Bigler",
      zip_code: "16825",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5451",
      city: "Biglerville",
      zip_code: "17307",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5504",
      city: "Bird In Hand",
      zip_code: "17505",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "6194",
      city: "Birdsboro",
      zip_code: "19508",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "4791",
      city: "Black Lick",
      zip_code: "15716",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5305",
      city: "Blain",
      zip_code: "17006",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5402",
      city: "Blairs Mills",
      zip_code: "17213",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "4792",
      city: "Blairsville",
      zip_code: "15717",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5889",
      city: "Blakeslee",
      zip_code: "18610",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5222",
      city: "Blanchard",
      zip_code: "16826",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5131",
      city: "Blandburg",
      zip_code: "16619",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "6195",
      city: "Blandon",
      zip_code: "19510",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5597",
      city: "Bloomsburg",
      zip_code: "17815",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5272",
      city: "Blossburg",
      zip_code: "16912",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5505",
      city: "Blue Ball",
      zip_code: "17506",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "6160",
      city: "Blue Bell",
      zip_code: "19422",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5403",
      city: "Blue Ridge Summit",
      zip_code: "17214",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5223",
      city: "Boalsburg",
      zip_code: "16827",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "4555",
      city: "Bobtown",
      zip_code: "15315",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5306",
      city: "Boiling Springs",
      zip_code: "17007",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "4885",
      city: "Bolivar",
      zip_code: "15923",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "4681",
      city: "Boswell",
      zip_code: "15531",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "4720",
      city: "Bovard",
      zip_code: "15619",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "6196",
      city: "Bowers",
      zip_code: "19511",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5699",
      city: "Bowmanstown",
      zip_code: "18030",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5506",
      city: "Bowmansville",
      zip_code: "17507",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "4921",
      city: "Boyers",
      zip_code: "16020",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "6197",
      city: "Boyertown",
      zip_code: "19512",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "4682",
      city: "Boynton",
      zip_code: "15532",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "4416",
      city: "Brackenridge",
      zip_code: "15014",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5933",
      city: "Brackney",
      zip_code: "18812",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "4480",
      city: "Braddock",
      zip_code: "15104",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "4721",
      city: "Bradenville",
      zip_code: "15620",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5192",
      city: "Bradford",
      zip_code: "16701",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "4417",
      city: "Bradfordwoods",
      zip_code: "15015",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5650",
      city: "Branchdale",
      zip_code: "17923",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "6126",
      city: "Brandamore",
      zip_code: "19316",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "4556",
      city: "Brave",
      zip_code: "15316",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "4683",
      city: "Breezewood",
      zip_code: "15533",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5700",
      city: "Breinigsville",
      zip_code: "18031",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "6158",
      city: "Bridgeport",
      zip_code: "19405",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "4418",
      city: "Bridgeville",
      zip_code: "15017",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5132",
      city: "Brisbin",
      zip_code: "16620",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "6009",
      city: "Bristol",
      zip_code: "19007",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5133",
      city: "Broad Top",
      zip_code: "16621",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "4848",
      city: "Brockport",
      zip_code: "15823",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5651",
      city: "Brockton",
      zip_code: "17925",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "4849",
      city: "Brockway",
      zip_code: "15824",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5792",
      city: "Brodheadsville",
      zip_code: "18322",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5452",
      city: "Brogue",
      zip_code: "17309",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "6016",
      city: "Brookhaven",
      zip_code: "19015",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "4850",
      city: "Brookville",
      zip_code: "15825",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "6010",
      city: "Broomall",
      zip_code: "19008",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "4609",
      city: "Brownfield",
      zip_code: "15416",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5507",
      city: "Brownstown",
      zip_code: "17508",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "4610",
      city: "Brownsville",
      zip_code: "15417",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "4922",
      city: "Bruin",
      zip_code: "16022",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "4793",
      city: "Brush Valley",
      zip_code: "15720",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "6011",
      city: "Bryn Athyn",
      zip_code: "19009",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "6012",
      city: "Bryn Mawr",
      zip_code: "19010",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5793",
      city: "Buck Hill Falls",
      zip_code: "18323",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5967",
      city: "Buckingham",
      zip_code: "18912",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "4419",
      city: "Buena Vista",
      zip_code: "15018",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "4684",
      city: "Buffalo Mills",
      zip_code: "15534",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "4420",
      city: "Bulger",
      zip_code: "15019",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "4421",
      city: "Bunola",
      zip_code: "15020",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "4422",
      city: "Burgettstown",
      zip_code: "15021",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5934",
      city: "Burlington",
      zip_code: "18814",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5307",
      city: "Burnham",
      zip_code: "17009",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "4794",
      city: "Burnside",
      zip_code: "15721",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5404",
      city: "Burnt Cabins",
      zip_code: "17215",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5794",
      city: "Bushkill",
      zip_code: "18324",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "4919",
      city: "Butler",
      zip_code: "16001",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "4851",
      city: "Byrnedale",
      zip_code: "15827",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "4923",
      city: "Cabot",
      zip_code: "16023",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "4999",
      city: "Cadogan",
      zip_code: "16212",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "4886",
      city: "Cairnbrook",
      zip_code: "15924",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "4611",
      city: "California",
      zip_code: "15419",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5000",
      city: "Callensburg",
      zip_code: "16213",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "4924",
      city: "Callery",
      zip_code: "16024",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5134",
      city: "Calvin",
      zip_code: "16622",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5080",
      city: "Cambridge Springs",
      zip_code: "16403",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5556",
      city: "Cammal",
      zip_code: "17723",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5309",
      city: "Camp Hill",
      zip_code: "17011",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5308",
      city: "Campbelltown",
      zip_code: "17010",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5795",
      city: "Canadensis",
      zip_code: "18325",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "4557",
      city: "Canonsburg",
      zip_code: "15317",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5557",
      city: "Canton",
      zip_code: "17724",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5827",
      city: "Carbondale",
      zip_code: "18407",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "4612",
      city: "Cardale",
      zip_code: "15420",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5310",
      city: "Carlisle",
      zip_code: "17013",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5036",
      city: "Carlton",
      zip_code: "16311",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "4558",
      city: "Carmichaels",
      zip_code: "15320",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "4481",
      city: "Carnegie",
      zip_code: "15106",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "4795",
      city: "Carrolltown",
      zip_code: "15722",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5968",
      city: "Carversville",
      zip_code: "18913",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "4887",
      city: "Cassandra",
      zip_code: "15925",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5135",
      city: "Cassville",
      zip_code: "16623",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5701",
      city: "Catasauqua",
      zip_code: "18032",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5598",
      city: "Catawissa",
      zip_code: "17820",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "4559",
      city: "Cecil",
      zip_code: "15321",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5558",
      city: "Cedar Run",
      zip_code: "17727",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5702",
      city: "Center Valley",
      zip_code: "18034",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "6198",
      city: "Centerport",
      zip_code: "19516",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5081",
      city: "Centerville",
      zip_code: "16404",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "4888",
      city: "Central City",
      zip_code: "15926",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5224",
      city: "Centre Hall",
      zip_code: "16828",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "6127",
      city: "Chadds Ford",
      zip_code: "19317",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5969",
      city: "Chalfont",
      zip_code: "18914",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "4613",
      city: "Chalk Hill",
      zip_code: "15421",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5397",
      city: "Chambersburg",
      zip_code: "17201",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "4796",
      city: "Chambersville",
      zip_code: "15723",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "4722",
      city: "Champion",
      zip_code: "15622",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5037",
      city: "Chandlers Valley",
      zip_code: "16312",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "4423",
      city: "Charleroi",
      zip_code: "15022",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "6013",
      city: "Cheltenham",
      zip_code: "19012",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "4797",
      city: "Cherry Tree",
      zip_code: "15724",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5703",
      city: "Cherryville",
      zip_code: "18035",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5136",
      city: "Chest Springs",
      zip_code: "16624",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "6014",
      city: "Chester",
      zip_code: "19013",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "6017",
      city: "Chester Heights",
      zip_code: "19017",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "6161",
      city: "Chester Springs",
      zip_code: "19425",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "4614",
      city: "Chestnut Ridge",
      zip_code: "15422",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "4424",
      city: "Cheswick",
      zip_code: "15024",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "6128",
      city: "Cheyney",
      zip_code: "19319",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "4925",
      city: "Chicora",
      zip_code: "16025",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5508",
      city: "Christiana",
      zip_code: "17509",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "4425",
      city: "Clairton",
      zip_code: "15025",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5225",
      city: "Clarence",
      zip_code: "16829",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5038",
      city: "Clarendon",
      zip_code: "16313",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "4723",
      city: "Claridge",
      zip_code: "15623",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "4852",
      city: "Clarington",
      zip_code: "15828",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5001",
      city: "Clarion",
      zip_code: "16214",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "4960",
      city: "Clark",
      zip_code: "16113",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "4961",
      city: "Clarks Mills",
      zip_code: "16114",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5828",
      city: "Clarks Summit",
      zip_code: "18411",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "4798",
      city: "Clarksburg",
      zip_code: "15725",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "4560",
      city: "Clarksville",
      zip_code: "15322",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5137",
      city: "Claysburg",
      zip_code: "16625",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "4561",
      city: "Claysville",
      zip_code: "15323",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5226",
      city: "Clearfield",
      zip_code: "16830",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "4685",
      city: "Clearville",
      zip_code: "15535",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5829",
      city: "Clifford",
      zip_code: "18413",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "6018",
      city: "Clifton Heights",
      zip_code: "19018",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "4426",
      city: "Clinton",
      zip_code: "15026",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5075",
      city: "Clintonville",
      zip_code: "16372",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "4799",
      city: "Clune",
      zip_code: "15727",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "4800",
      city: "Clymer",
      zip_code: "15728",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "4615",
      city: "Coal Center",
      zip_code: "15423",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5631",
      city: "Coal Township",
      zip_code: "17866",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5760",
      city: "Coaldale",
      zip_code: "18218",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5138",
      city: "Coalport",
      zip_code: "16627",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "6129",
      city: "Coatesville",
      zip_code: "19320",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5227",
      city: "Coburn",
      zip_code: "16832",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5039",
      city: "Cochranton",
      zip_code: "16314",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "6130",
      city: "Cochranville",
      zip_code: "19330",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5311",
      city: "Cocolamus",
      zip_code: "17014",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5453",
      city: "Codorus",
      zip_code: "17311",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5559",
      city: "Cogan Station",
      zip_code: "17728",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "4562",
      city: "Cokeburg",
      zip_code: "15324",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "6162",
      city: "Collegeville",
      zip_code: "19426",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5970",
      city: "Colmar",
      zip_code: "18915",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5509",
      city: "Columbia",
      zip_code: "17512",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5273",
      city: "Columbia Cross Roads",
      zip_code: "16914",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5082",
      city: "Columbus",
      zip_code: "16405",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "4889",
      city: "Colver",
      zip_code: "15927",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "4801",
      city: "Commodore",
      zip_code: "15729",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5405",
      city: "Concord",
      zip_code: "17217",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5510",
      city: "Conestoga",
      zip_code: "17516",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "4616",
      city: "Confluence",
      zip_code: "15424",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5040",
      city: "Conneaut Lake",
      zip_code: "16316",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5083",
      city: "Conneautville",
      zip_code: "16406",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "4617",
      city: "Connellsville",
      zip_code: "15425",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "4926",
      city: "Connoquenessing",
      zip_code: "16027",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "6163",
      city: "Conshohocken",
      zip_code: "19428",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "4427",
      city: "Conway",
      zip_code: "15027",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5761",
      city: "Conyngham",
      zip_code: "18219",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5002",
      city: "Cooksburg",
      zip_code: "16217",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "4802",
      city: "Coolspring",
      zip_code: "15730",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5704",
      city: "Coopersburg",
      zip_code: "18036",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5041",
      city: "Cooperstown",
      zip_code: "16317",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5705",
      city: "Coplay",
      zip_code: "18037",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "4803",
      city: "Coral",
      zip_code: "15731",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "4482",
      city: "Coraopolis",
      zip_code: "15108",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5313",
      city: "Cornwall",
      zip_code: "17016",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5084",
      city: "Corry",
      zip_code: "16407",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "4853",
      city: "Corsica",
      zip_code: "15829",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5274",
      city: "Coudersport",
      zip_code: "16915",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "4428",
      city: "Coulters",
      zip_code: "15028",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5275",
      city: "Covington",
      zip_code: "16917",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5003",
      city: "Cowansville",
      zip_code: "16218",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "4724",
      city: "Crabtree",
      zip_code: "15624",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5042",
      city: "Cranberry",
      zip_code: "16319",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "4953",
      city: "Cranberry Township",
      zip_code: "16066",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5085",
      city: "Cranesville",
      zip_code: "16410",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "4804",
      city: "Creekside",
      zip_code: "15732",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "4429",
      city: "Creighton",
      zip_code: "15030",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "4440",
      city: "Crescent",
      zip_code: "15046",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5796",
      city: "Cresco",
      zip_code: "18326",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5139",
      city: "Cresson",
      zip_code: "16630",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5652",
      city: "Cressona",
      zip_code: "17929",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5194",
      city: "Crosby",
      zip_code: "16724",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5560",
      city: "Cross Fork",
      zip_code: "17729",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5004",
      city: "Crown",
      zip_code: "16220",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "6020",
      city: "Croydon",
      zip_code: "19021",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "4563",
      city: "Crucible",
      zip_code: "15325",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "6021",
      city: "Crum Lynne",
      zip_code: "19022",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "4686",
      city: "Crystal Spring",
      zip_code: "15536",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "4430",
      city: "Cuddy",
      zip_code: "15031",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5653",
      city: "Cumbola",
      zip_code: "17930",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5140",
      city: "Curryville",
      zip_code: "16631",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5228",
      city: "Curwensville",
      zip_code: "16833",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5195",
      city: "Custer City",
      zip_code: "16725",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5196",
      city: "Cyclone",
      zip_code: "16726",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "4854",
      city: "Dagus Mines",
      zip_code: "15831",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "4618",
      city: "Daisytown",
      zip_code: "15427",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5890",
      city: "Dallas",
      zip_code: "18612",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5454",
      city: "Dallastown",
      zip_code: "17313",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5314",
      city: "Dalmatia",
      zip_code: "17017",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5830",
      city: "Dalton",
      zip_code: "18414",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5831",
      city: "Damascus",
      zip_code: "18415",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5706",
      city: "Danielsville",
      zip_code: "18038",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5599",
      city: "Danville",
      zip_code: "17821",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "6022",
      city: "Darby",
      zip_code: "19023",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "4962",
      city: "Darlington",
      zip_code: "16115",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "4725",
      city: "Darragh",
      zip_code: "15625",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5315",
      city: "Dauphin",
      zip_code: "17018",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "4890",
      city: "Davidsville",
      zip_code: "15928",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "4619",
      city: "Dawson",
      zip_code: "15428",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5005",
      city: "Dayton",
      zip_code: "16222",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "4805",
      city: "De Lancey",
      zip_code: "15733",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5141",
      city: "Defiance",
      zip_code: "16633",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5762",
      city: "Delano",
      zip_code: "18220",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5797",
      city: "Delaware Water Gap",
      zip_code: "18327",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "4726",
      city: "Delmont",
      zip_code: "15626",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5455",
      city: "Delta",
      zip_code: "17314",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "4620",
      city: "Denbo",
      zip_code: "15429",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5511",
      city: "Denver",
      zip_code: "17517",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5197",
      city: "Derrick City",
      zip_code: "16727",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "4727",
      city: "Derry",
      zip_code: "15627",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "6131",
      city: "Devon",
      zip_code: "19333",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5561",
      city: "Dewart",
      zip_code: "17730",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "4621",
      city: "Dickerson Run",
      zip_code: "15430",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "4564",
      city: "Dilliner",
      zip_code: "15327",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5316",
      city: "Dillsburg",
      zip_code: "17019",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "4891",
      city: "Dilltown",
      zip_code: "15929",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5935",
      city: "Dimock",
      zip_code: "18816",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5798",
      city: "Dingmans Ferry",
      zip_code: "18328",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5006",
      city: "Distant",
      zip_code: "16223",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "4806",
      city: "Dixonville",
      zip_code: "15734",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "4728",
      city: "Donegal",
      zip_code: "15628",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "4431",
      city: "Donora",
      zip_code: "15033",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5601",
      city: "Dornsife",
      zip_code: "17823",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "6199",
      city: "Douglassville",
      zip_code: "19518",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5456",
      city: "Dover",
      zip_code: "17315",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "6132",
      city: "Downingtown",
      zip_code: "19335",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5406",
      city: "Doylesburg",
      zip_code: "17219",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5965",
      city: "Doylestown",
      zip_code: "18901",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "4432",
      city: "Dravosburg",
      zip_code: "15034",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "6023",
      city: "Dresher",
      zip_code: "19025",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "6024",
      city: "Drexel Hill",
      zip_code: "19026",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5229",
      city: "Drifting",
      zip_code: "16834",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5763",
      city: "Drifton",
      zip_code: "18221",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "4855",
      city: "Driftwood",
      zip_code: "15832",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5512",
      city: "Drumore",
      zip_code: "17518",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5764",
      city: "Drums",
      zip_code: "18222",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5407",
      city: "Dry Run",
      zip_code: "17220",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "4846",
      city: "Du Bois",
      zip_code: "15801",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5971",
      city: "Dublin",
      zip_code: "18917",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5142",
      city: "Dudley",
      zip_code: "16634",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5198",
      city: "Duke Center",
      zip_code: "16729",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "4622",
      city: "Dunbar",
      zip_code: "15431",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5317",
      city: "Duncannon",
      zip_code: "17020",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5143",
      city: "Duncansville",
      zip_code: "16635",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "4623",
      city: "Dunlevy",
      zip_code: "15432",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "4892",
      city: "Dunlo",
      zip_code: "15930",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "4483",
      city: "Duquesne",
      zip_code: "15110",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5914",
      city: "Duryea",
      zip_code: "18642",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5891",
      city: "Dushore",
      zip_code: "18614",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5144",
      city: "Dysart",
      zip_code: "16636",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5562",
      city: "Eagles Mere",
      zip_code: "17731",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5457",
      city: "East Berlin",
      zip_code: "17316",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "4927",
      city: "East Brady",
      zip_code: "16028",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "4928",
      city: "East Butler",
      zip_code: "16029",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5513",
      city: "East Earl",
      zip_code: "17519",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5145",
      city: "East Freedom",
      zip_code: "16637",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5708",
      city: "East Greenville",
      zip_code: "18041",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5043",
      city: "East Hickory",
      zip_code: "16321",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "4433",
      city: "East McKeesport",
      zip_code: "15035",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "4624",
      city: "East Millsboro",
      zip_code: "15433",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5514",
      city: "East Petersburg",
      zip_code: "17520",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "4484",
      city: "East Pittsburgh",
      zip_code: "15112",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5458",
      city: "East Prospect",
      zip_code: "17317",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5199",
      city: "East Smethport",
      zip_code: "16730",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5936",
      city: "East Smithfield",
      zip_code: "18817",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5086",
      city: "East Springfield",
      zip_code: "16411",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5789",
      city: "East Stroudsburg",
      zip_code: "18301",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5711",
      city: "East Texas",
      zip_code: "18046",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "4729",
      city: "East Vandergrift",
      zip_code: "15629",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5318",
      city: "East Waterford",
      zip_code: "17021",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5707",
      city: "Easton",
      zip_code: "18040",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "4929",
      city: "Eau Claire",
      zip_code: "16030",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "4893",
      city: "Ebensburg",
      zip_code: "15931",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5765",
      city: "Ebervale",
      zip_code: "18223",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5087",
      city: "Edinboro",
      zip_code: "16412",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "4963",
      city: "Edinburg",
      zip_code: "16116",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5799",
      city: "Effort",
      zip_code: "18330",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "4566",
      city: "Eighty Four",
      zip_code: "15330",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "4625",
      city: "Elco",
      zip_code: "15434",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "4807",
      city: "Elderton",
      zip_code: "15736",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5200",
      city: "Eldred",
      zip_code: "16731",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "4434",
      city: "Elizabeth",
      zip_code: "15037",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5319",
      city: "Elizabethtown",
      zip_code: "17022",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5320",
      city: "Elizabethville",
      zip_code: "17023",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "6025",
      city: "Elkins Park",
      zip_code: "19027",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5276",
      city: "Elkland",
      zip_code: "16920",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5321",
      city: "Elliottsburg",
      zip_code: "17024",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "4567",
      city: "Ellsworth",
      zip_code: "15331",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "4964",
      city: "Ellwood City",
      zip_code: "16117",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "4808",
      city: "Elmora",
      zip_code: "15737",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "4435",
      city: "Elrama",
      zip_code: "15038",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "4894",
      city: "Elton",
      zip_code: "15934",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "6200",
      city: "Elverson",
      zip_code: "19520",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5602",
      city: "Elysburg",
      zip_code: "17824",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "4809",
      city: "Emeigh",
      zip_code: "15738",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5459",
      city: "Emigsville",
      zip_code: "17318",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5076",
      city: "Emlenton",
      zip_code: "16373",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5712",
      city: "Emmaus",
      zip_code: "18049",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "4856",
      city: "Emporium",
      zip_code: "15834",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5044",
      city: "Endeavor",
      zip_code: "16322",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5322",
      city: "Enola",
      zip_code: "17025",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "4965",
      city: "Enon Valley",
      zip_code: "16120",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5146",
      city: "Entriken",
      zip_code: "16638",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5515",
      city: "Ephrata",
      zip_code: "17522",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5832",
      city: "Equinunk",
      zip_code: "18417",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5111",
      city: "Erie",
      zip_code: "16501",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "4810",
      city: "Ernest",
      zip_code: "15739",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5972",
      city: "Erwinna",
      zip_code: "18920",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "6026",
      city: "Essington",
      zip_code: "19029",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5460",
      city: "Etters",
      zip_code: "17319",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "4930",
      city: "Evans City",
      zip_code: "16033",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "4687",
      city: "Everett",
      zip_code: "15537",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "4730",
      city: "Everson",
      zip_code: "15631",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "4731",
      city: "Export",
      zip_code: "15632",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "6133",
      city: "Exton",
      zip_code: "19341",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5833",
      city: "Factoryville",
      zip_code: "18419",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "4626",
      city: "Fairbank",
      zip_code: "15435",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "4627",
      city: "Fairchance",
      zip_code: "15436",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5461",
      city: "Fairfield",
      zip_code: "17320",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "4688",
      city: "Fairhope",
      zip_code: "15538",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "6027",
      city: "Fairless Hills",
      zip_code: "19030",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5007",
      city: "Fairmount City",
      zip_code: "16224",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5088",
      city: "Fairview",
      zip_code: "16415",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5147",
      city: "Fallentimber",
      zip_code: "16639",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5892",
      city: "Falls",
      zip_code: "18615",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "4857",
      city: "Falls Creek",
      zip_code: "15840",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5408",
      city: "Fannettsburg",
      zip_code: "17221",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "4628",
      city: "Farmington",
      zip_code: "15437",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "4966",
      city: "Farrell",
      zip_code: "16121",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5462",
      city: "Fawn Grove",
      zip_code: "17321",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "4629",
      city: "Fayette City",
      zip_code: "15438",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5409",
      city: "Fayetteville",
      zip_code: "17222",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "6043",
      city: "Feasterville Trevose",
      zip_code: "19053",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5463",
      city: "Felton",
      zip_code: "17322",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "4931",
      city: "Fenelton",
      zip_code: "16034",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5973",
      city: "Ferndale",
      zip_code: "18921",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "4568",
      city: "Finleyville",
      zip_code: "15332",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "4689",
      city: "Fishertown",
      zip_code: "15539",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5834",
      city: "Fleetville",
      zip_code: "18420",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "6201",
      city: "Fleetwood",
      zip_code: "19522",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5230",
      city: "Fleming",
      zip_code: "16835",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5148",
      city: "Flinton",
      zip_code: "16640",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "6028",
      city: "Flourtown",
      zip_code: "19031",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5713",
      city: "Fogelsville",
      zip_code: "18051",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "6029",
      city: "Folcroft",
      zip_code: "19032",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "6030",
      city: "Folsom",
      zip_code: "19033",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "4967",
      city: "Fombell",
      zip_code: "16123",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "4732",
      city: "Forbes Road",
      zip_code: "15633",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "4858",
      city: "Force",
      zip_code: "15841",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5008",
      city: "Ford City",
      zip_code: "16226",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5009",
      city: "Ford Cliff",
      zip_code: "16228",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5835",
      city: "Forest City",
      zip_code: "18421",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "4932",
      city: "Forestville",
      zip_code: "16035",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5893",
      city: "Forksville",
      zip_code: "18616",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "4690",
      city: "Fort Hill",
      zip_code: "15540",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5410",
      city: "Fort Littleton",
      zip_code: "17223",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5411",
      city: "Fort Loudon",
      zip_code: "17224",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "6031",
      city: "Fort Washington",
      zip_code: "19034",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5974",
      city: "Fountainville",
      zip_code: "18923",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "4933",
      city: "Foxburg",
      zip_code: "16036",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5654",
      city: "Frackville",
      zip_code: "17931",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5045",
      city: "Franklin",
      zip_code: "16323",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5464",
      city: "Franklintown",
      zip_code: "17323",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "6164",
      city: "Frederick",
      zip_code: "19435",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5323",
      city: "Fredericksburg",
      zip_code: "17026",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "4569",
      city: "Fredericktown",
      zip_code: "15333",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "4968",
      city: "Fredonia",
      zip_code: "16124",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5603",
      city: "Freeburg",
      zip_code: "17827",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "4436",
      city: "Freedom",
      zip_code: "15042",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5766",
      city: "Freeland",
      zip_code: "18224",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5010",
      city: "Freeport",
      zip_code: "16229",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5231",
      city: "Frenchville",
      zip_code: "16836",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "4691",
      city: "Friedens",
      zip_code: "15541",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5655",
      city: "Friedensburg",
      zip_code: "17933",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5937",
      city: "Friendsville",
      zip_code: "18818",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5046",
      city: "Fryburg",
      zip_code: "16326",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5975",
      city: "Furlong",
      zip_code: "18925",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5277",
      city: "Gaines",
      zip_code: "16921",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5278",
      city: "Galeton",
      zip_code: "16922",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5149",
      city: "Gallitzin",
      zip_code: "16641",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5516",
      city: "Gap",
      zip_code: "17527",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "4570",
      city: "Garards Fort",
      zip_code: "15334",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5465",
      city: "Gardners",
      zip_code: "17324",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5089",
      city: "Garland",
      zip_code: "16416",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "6048",
      city: "Garnet Valley",
      zip_code: "19060",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "4692",
      city: "Garrett",
      zip_code: "15542",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "6202",
      city: "Geigertown",
      zip_code: "19523",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5279",
      city: "Genesee",
      zip_code: "16923",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "4437",
      city: "Georgetown",
      zip_code: "15043",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5715",
      city: "Germansville",
      zip_code: "18053",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5466",
      city: "Gettysburg",
      zip_code: "17325",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "4630",
      city: "Gibbon Glade",
      zip_code: "15440",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5938",
      city: "Gibson",
      zip_code: "18820",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "4438",
      city: "Gibsonia",
      zip_code: "15044",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5201",
      city: "Gifford",
      zip_code: "16732",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5800",
      city: "Gilbert",
      zip_code: "18331",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5656",
      city: "Gilberton",
      zip_code: "17934",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "6203",
      city: "Gilbertsville",
      zip_code: "19525",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5280",
      city: "Gillett",
      zip_code: "16925",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "4811",
      city: "Gipsy",
      zip_code: "15741",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5090",
      city: "Girard",
      zip_code: "16417",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5657",
      city: "Girardville",
      zip_code: "17935",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "6032",
      city: "Gladwyne",
      zip_code: "19035",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "4439",
      city: "Glassport",
      zip_code: "15045",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "4812",
      city: "Glen Campbell",
      zip_code: "15742",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5150",
      city: "Glen Hope",
      zip_code: "16645",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5894",
      city: "Glen Lyon",
      zip_code: "18617",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "6134",
      city: "Glen Mills",
      zip_code: "19342",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5232",
      city: "Glen Richey",
      zip_code: "16837",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5467",
      city: "Glen Rock",
      zip_code: "17327",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "6135",
      city: "Glenmoore",
      zip_code: "19343",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "6033",
      city: "Glenolden",
      zip_code: "19036",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "4485",
      city: "Glenshaw",
      zip_code: "15116",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "6034",
      city: "Glenside",
      zip_code: "19038",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5468",
      city: "Glenville",
      zip_code: "17329",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5658",
      city: "Gordon",
      zip_code: "17936",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5517",
      city: "Gordonville",
      zip_code: "17529",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5836",
      city: "Gouldsboro",
      zip_code: "18424",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5233",
      city: "Grampian",
      zip_code: "16838",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5091",
      city: "Grand Valley",
      zip_code: "16420",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5324",
      city: "Grantham",
      zip_code: "17027",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5325",
      city: "Grantville",
      zip_code: "17028",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5326",
      city: "Granville",
      zip_code: "17029",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5281",
      city: "Granville Summit",
      zip_code: "16926",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "4733",
      city: "Grapeville",
      zip_code: "15634",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5234",
      city: "Grassflat",
      zip_code: "16839",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5327",
      city: "Gratz",
      zip_code: "17030",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "4693",
      city: "Gray",
      zip_code: "15544",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "4571",
      city: "Graysville",
      zip_code: "15337",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5939",
      city: "Great Bend",
      zip_code: "18821",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5837",
      city: "Greeley",
      zip_code: "18425",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5716",
      city: "Green Lane",
      zip_code: "18054",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5412",
      city: "Greencastle",
      zip_code: "17225",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "4441",
      city: "Greenock",
      zip_code: "15047",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "4572",
      city: "Greensboro",
      zip_code: "15338",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "4711",
      city: "Greensburg",
      zip_code: "15601",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5838",
      city: "Greentown",
      zip_code: "18426",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "4969",
      city: "Greenville",
      zip_code: "16125",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "4631",
      city: "Grindstone",
      zip_code: "15442",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "4970",
      city: "Grove City",
      zip_code: "16127",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5047",
      city: "Guys Mills",
      zip_code: "16327",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "6165",
      city: "Gwynedd",
      zip_code: "19436",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "6166",
      city: "Gwynedd Valley",
      zip_code: "19437",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "4971",
      city: "Hadley",
      zip_code: "16130",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5328",
      city: "Halifax",
      zip_code: "17032",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5940",
      city: "Hallstead",
      zip_code: "18822",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "6204",
      city: "Hamburg",
      zip_code: "19526",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "4813",
      city: "Hamilton",
      zip_code: "15744",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "4734",
      city: "Hannastown",
      zip_code: "15635",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5469",
      city: "Hanover",
      zip_code: "17331",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5092",
      city: "Harborcreek",
      zip_code: "16421",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5941",
      city: "Harford",
      zip_code: "18823",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5767",
      city: "Harleigh",
      zip_code: "18225",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "6167",
      city: "Harleysville",
      zip_code: "19438",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5093",
      city: "Harmonsburg",
      zip_code: "16422",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "4934",
      city: "Harmony",
      zip_code: "16037",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5387",
      city: "Harrisburg",
      zip_code: "17101",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "4735",
      city: "Harrison City",
      zip_code: "15636",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5282",
      city: "Harrison Valley",
      zip_code: "16927",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5413",
      city: "Harrisonville",
      zip_code: "17228",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "4935",
      city: "Harrisville",
      zip_code: "16038",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5604",
      city: "Hartleton",
      zip_code: "17829",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "4972",
      city: "Hartstown",
      zip_code: "16131",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5895",
      city: "Harveys Lake",
      zip_code: "18618",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "4442",
      city: "Harwick",
      zip_code: "15049",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5151",
      city: "Hastings",
      zip_code: "16646",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "6035",
      city: "Hatboro",
      zip_code: "19040",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "6168",
      city: "Hatfield",
      zip_code: "19440",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "6036",
      city: "Haverford",
      zip_code: "19041",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "6064",
      city: "Havertown",
      zip_code: "19083",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5235",
      city: "Hawk Run",
      zip_code: "16840",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5839",
      city: "Hawley",
      zip_code: "18428",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5011",
      city: "Hawthorn",
      zip_code: "16230",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5202",
      city: "Hazel Hurst",
      zip_code: "16733",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5753",
      city: "Hazleton",
      zip_code: "18201",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5659",
      city: "Hegins",
      zip_code: "17938",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "4814",
      city: "Heilwood",
      zip_code: "15745",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5717",
      city: "Hellertown",
      zip_code: "18055",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5801",
      city: "Henryville",
      zip_code: "18332",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5718",
      city: "Hereford",
      zip_code: "18056",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "4736",
      city: "Herminie",
      zip_code: "15637",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "4984",
      city: "Hermitage",
      zip_code: "16148",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5605",
      city: "Herndon",
      zip_code: "17830",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5840",
      city: "Herrick Center",
      zip_code: "18430",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5329",
      city: "Hershey",
      zip_code: "17033",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5152",
      city: "Hesston",
      zip_code: "16647",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "4632",
      city: "Hibbs",
      zip_code: "15443",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "4573",
      city: "Hickory",
      zip_code: "15340",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "4675",
      city: "Hidden Valley",
      zip_code: "15502",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5330",
      city: "Highspire",
      zip_code: "17034",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "4633",
      city: "Hiller",
      zip_code: "15444",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "4936",
      city: "Hilliards",
      zip_code: "16040",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "4815",
      city: "Hillsdale",
      zip_code: "15746",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5896",
      city: "Hillsgrove",
      zip_code: "18619",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "4973",
      city: "Hillsville",
      zip_code: "16132",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5976",
      city: "Hilltown",
      zip_code: "18927",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "4574",
      city: "Holbrook",
      zip_code: "15341",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5153",
      city: "Hollidaysburg",
      zip_code: "16648",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "4895",
      city: "Hollsopple",
      zip_code: "15935",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "6037",
      city: "Holmes",
      zip_code: "19043",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5518",
      city: "Holtwood",
      zip_code: "17532",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "4816",
      city: "Home",
      zip_code: "15747",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "4817",
      city: "Homer City",
      zip_code: "15748",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "4486",
      city: "Homestead",
      zip_code: "15120",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5841",
      city: "Honesdale",
      zip_code: "18431",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "6136",
      city: "Honey Brook",
      zip_code: "19344",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5331",
      city: "Honey Grove",
      zip_code: "17035",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "4443",
      city: "Hookstown",
      zip_code: "15050",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "4896",
      city: "Hooversville",
      zip_code: "15936",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5942",
      city: "Hop Bottom",
      zip_code: "18824",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5154",
      city: "Hopewell",
      zip_code: "16650",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "4634",
      city: "Hopwood",
      zip_code: "15445",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "6038",
      city: "Horsham",
      zip_code: "19044",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "4737",
      city: "Hostetter",
      zip_code: "15638",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "4575",
      city: "Houston",
      zip_code: "15342",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5155",
      city: "Houtzdale",
      zip_code: "16651",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5236",
      city: "Howard",
      zip_code: "16841",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5563",
      city: "Hughesville",
      zip_code: "17737",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5332",
      city: "Hummelstown",
      zip_code: "17036",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "4738",
      city: "Hunker",
      zip_code: "15639",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5897",
      city: "Hunlock Creek",
      zip_code: "18621",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5156",
      city: "Huntingdon",
      zip_code: "16652",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "6008",
      city: "Huntingdon Valley",
      zip_code: "19006",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5898",
      city: "Huntington Mills",
      zip_code: "18622",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5414",
      city: "Hustontown",
      zip_code: "17229",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "4739",
      city: "Hutchinson",
      zip_code: "15640",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5237",
      city: "Hyde",
      zip_code: "16843",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "4740",
      city: "Hyde Park",
      zip_code: "15641",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5048",
      city: "Hydetown",
      zip_code: "16328",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "4694",
      city: "Hyndman",
      zip_code: "15545",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5333",
      city: "Ickesburg",
      zip_code: "17037",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5158",
      city: "Imler",
      zip_code: "16655",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "6137",
      city: "Immaculata",
      zip_code: "19345",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "4489",
      city: "Imperial",
      zip_code: "15126",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "4635",
      city: "Indian Head",
      zip_code: "15446",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "4783",
      city: "Indiana",
      zip_code: "15701",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "4444",
      city: "Indianola",
      zip_code: "15051",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "4445",
      city: "Industry",
      zip_code: "15052",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5519",
      city: "Intercourse",
      zip_code: "17534",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5049",
      city: "Irvine",
      zip_code: "16329",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5159",
      city: "Irvona",
      zip_code: "16656",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "4741",
      city: "Irwin",
      zip_code: "15642",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "4636",
      city: "Isabella",
      zip_code: "15447",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5943",
      city: "Jackson",
      zip_code: "18825",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "4974",
      city: "Jackson Center",
      zip_code: "16133",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "4637",
      city: "Jacobs Creek",
      zip_code: "15448",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5203",
      city: "James City",
      zip_code: "16734",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5160",
      city: "James Creek",
      zip_code: "16657",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "4975",
      city: "Jamestown",
      zip_code: "16134",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5977",
      city: "Jamison",
      zip_code: "18929",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "4742",
      city: "Jeannette",
      zip_code: "15644",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "4576",
      city: "Jefferson",
      zip_code: "15344",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "6039",
      city: "Jenkintown",
      zip_code: "19046",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "4695",
      city: "Jenners",
      zip_code: "15546",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "4696",
      city: "Jennerstown",
      zip_code: "15547",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5842",
      city: "Jermyn",
      zip_code: "18433",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "4897",
      city: "Jerome",
      zip_code: "15937",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5564",
      city: "Jersey Mills",
      zip_code: "17739",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5565",
      city: "Jersey Shore",
      zip_code: "17740",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5843",
      city: "Jessup",
      zip_code: "18434",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5768",
      city: "Jim Thorpe",
      zip_code: "18229",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "4446",
      city: "Joffre",
      zip_code: "15053",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "4859",
      city: "Johnsonburg",
      zip_code: "15845",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "4876",
      city: "Johnstown",
      zip_code: "15901",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "4743",
      city: "Jones Mills",
      zip_code: "15646",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5334",
      city: "Jonestown",
      zip_code: "17038",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "4818",
      city: "Josephine",
      zip_code: "15750",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5238",
      city: "Julian",
      zip_code: "16844",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5769",
      city: "Junedale",
      zip_code: "18230",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5204",
      city: "Kane",
      zip_code: "16735",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "4937",
      city: "Karns City",
      zip_code: "16041",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5239",
      city: "Karthaus",
      zip_code: "16845",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "4638",
      city: "Keisterville",
      zip_code: "15449",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5770",
      city: "Kelayres",
      zip_code: "18231",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "6205",
      city: "Kempton",
      zip_code: "19529",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5077",
      city: "Kennerdell",
      zip_code: "16374",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "6138",
      city: "Kennett Square",
      zip_code: "19348",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "4819",
      city: "Kent",
      zip_code: "15752",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "4860",
      city: "Kersey",
      zip_code: "15846",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "6169",
      city: "Kimberton",
      zip_code: "19442",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "6159",
      city: "King Of Prussia",
      zip_code: "19406",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5944",
      city: "Kingsley",
      zip_code: "18826",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5925",
      city: "Kingston",
      zip_code: "18704",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5978",
      city: "Kintnersville",
      zip_code: "18930",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5520",
      city: "Kinzers",
      zip_code: "17535",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5521",
      city: "Kirkwood",
      zip_code: "17536",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "4996",
      city: "Kittanning",
      zip_code: "16201",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5335",
      city: "Kleinfeltersville",
      zip_code: "17039",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5660",
      city: "Klingerstown",
      zip_code: "17941",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5012",
      city: "Knox",
      zip_code: "16232",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "4861",
      city: "Knox Dale",
      zip_code: "15847",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5283",
      city: "Knoxville",
      zip_code: "16928",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "4976",
      city: "Koppel",
      zip_code: "16136",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5050",
      city: "Kossuth",
      zip_code: "16331",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5802",
      city: "Kresgeville",
      zip_code: "18333",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5607",
      city: "Kulpmont",
      zip_code: "17834",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5719",
      city: "Kunkletown",
      zip_code: "18058",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "6206",
      city: "Kutztown",
      zip_code: "19530",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5240",
      city: "Kylertown",
      zip_code: "16847",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "4639",
      city: "La Belle",
      zip_code: "15450",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "4820",
      city: "La Jose",
      zip_code: "15753",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5849",
      city: "La Plume",
      zip_code: "18440",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5899",
      city: "Laceyville",
      zip_code: "18623",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5844",
      city: "Lackawaxen",
      zip_code: "18435",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "6170",
      city: "Lafayette Hill",
      zip_code: "19444",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5566",
      city: "Lairdsville",
      zip_code: "17742",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5845",
      city: "Lake Ariel",
      zip_code: "18436",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5094",
      city: "Lake City",
      zip_code: "16423",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5846",
      city: "Lake Como",
      zip_code: "18437",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5900",
      city: "Lake Harmony",
      zip_code: "18624",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "4640",
      city: "Lake Lynn",
      zip_code: "15451",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5901",
      city: "Lake Winola",
      zip_code: "18625",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5847",
      city: "Lakeville",
      zip_code: "18438",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5848",
      city: "Lakewood",
      zip_code: "18439",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5241",
      city: "Lamar",
      zip_code: "16848",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5548",
      city: "Lancaster",
      zip_code: "17601",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "6139",
      city: "Landenberg",
      zip_code: "19350",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5336",
      city: "Landisburg",
      zip_code: "17040",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5522",
      city: "Landisville",
      zip_code: "17538",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "4447",
      city: "Langeloth",
      zip_code: "15054",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "6040",
      city: "Langhorne",
      zip_code: "19047",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "6171",
      city: "Lansdale",
      zip_code: "19446",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "6041",
      city: "Lansdowne",
      zip_code: "19050",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5242",
      city: "Lanse",
      zip_code: "16849",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5771",
      city: "Lansford",
      zip_code: "18232",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5902",
      city: "Laporte",
      zip_code: "18626",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "4744",
      city: "Larimer",
      zip_code: "15647",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "4745",
      city: "Latrobe",
      zip_code: "15650",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5772",
      city: "Lattimer Mines",
      zip_code: "18234",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "4746",
      city: "Laughlintown",
      zip_code: "15655",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5608",
      city: "Laurelton",
      zip_code: "17835",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5720",
      city: "Laurys Station",
      zip_code: "18059",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5661",
      city: "Lavelle",
      zip_code: "17943",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5337",
      city: "Lawn",
      zip_code: "17041",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "4448",
      city: "Lawrence",
      zip_code: "15055",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5284",
      city: "Lawrenceville",
      zip_code: "16929",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5945",
      city: "Lawton",
      zip_code: "18828",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5946",
      city: "Le Raysville",
      zip_code: "18829",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5338",
      city: "Lebanon",
      zip_code: "17042",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5609",
      city: "Leck Kill",
      zip_code: "17836",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "4641",
      city: "Leckrone",
      zip_code: "15454",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "4747",
      city: "Leechburg",
      zip_code: "15656",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5013",
      city: "Leeper",
      zip_code: "16233",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "6207",
      city: "Leesport",
      zip_code: "19533",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "4449",
      city: "Leetsdale",
      zip_code: "15056",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5773",
      city: "Lehighton",
      zip_code: "18235",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5903",
      city: "Lehman",
      zip_code: "18627",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "4642",
      city: "Leisenring",
      zip_code: "15455",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5243",
      city: "Lemont",
      zip_code: "16851",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "4643",
      city: "Lemont Furnace",
      zip_code: "15456",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5339",
      city: "Lemoyne",
      zip_code: "17043",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "6208",
      city: "Lenhartsville",
      zip_code: "19534",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "6042",
      city: "Lenni",
      zip_code: "19052",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5850",
      city: "Lenoxville",
      zip_code: "18441",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5523",
      city: "Leola",
      zip_code: "17540",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "6044",
      city: "Levittown",
      zip_code: "19054",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5205",
      city: "Lewis Run",
      zip_code: "16738",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5470",
      city: "Lewisberry",
      zip_code: "17339",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5610",
      city: "Lewisburg",
      zip_code: "17837",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5340",
      city: "Lewistown",
      zip_code: "17044",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5285",
      city: "Liberty",
      zip_code: "16930",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5051",
      city: "Lickingville",
      zip_code: "16332",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "4748",
      city: "Ligonier",
      zip_code: "15658",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "4898",
      city: "Lilly",
      zip_code: "15938",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "6209",
      city: "Limekiln",
      zip_code: "19535",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "6140",
      city: "Lincoln University",
      zip_code: "19352",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5567",
      city: "Linden",
      zip_code: "17744",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5979",
      city: "Line Lexington",
      zip_code: "18932",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5095",
      city: "Linesville",
      zip_code: "16424",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5524",
      city: "Lititz",
      zip_code: "17543",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5947",
      city: "Little Meadows",
      zip_code: "18830",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5471",
      city: "Littlestown",
      zip_code: "17340",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5341",
      city: "Liverpool",
      zip_code: "17045",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5662",
      city: "Llewellyn",
      zip_code: "17944",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5568",
      city: "Lock Haven",
      zip_code: "17745",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5611",
      city: "Locust Gap",
      zip_code: "17840",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5663",
      city: "Locustdale",
      zip_code: "17945",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5569",
      city: "Loganton",
      zip_code: "17747",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5472",
      city: "Loganville",
      zip_code: "17342",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5803",
      city: "Long Pond",
      zip_code: "18334",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5904",
      city: "Lopez",
      zip_code: "18628",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "4899",
      city: "Loretto",
      zip_code: "15940",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5664",
      city: "Lost Creek",
      zip_code: "17946",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "4749",
      city: "Lowber",
      zip_code: "15660",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "4750",
      city: "Loyalhanna",
      zip_code: "15661",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5161",
      city: "Loysburg",
      zip_code: "16659",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5343",
      city: "Loysville",
      zip_code: "17047",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "4821",
      city: "Lucernemines",
      zip_code: "15754",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5014",
      city: "Lucinda",
      zip_code: "16235",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5052",
      city: "Ludlow",
      zip_code: "16333",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5980",
      city: "Lumberville",
      zip_code: "18933",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5415",
      city: "Lurgan",
      zip_code: "17232",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "4862",
      city: "Luthersburg",
      zip_code: "15848",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "4751",
      city: "Luxor",
      zip_code: "15662",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5930",
      city: "Luzerne",
      zip_code: "18709",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5344",
      city: "Lykens",
      zip_code: "17048",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "4938",
      city: "Lyndora",
      zip_code: "16045",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "6210",
      city: "Lyon Station",
      zip_code: "19536",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5572",
      city: "Mackeyville",
      zip_code: "17750",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5721",
      city: "Macungie",
      zip_code: "18062",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5162",
      city: "Madera",
      zip_code: "16661",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "4752",
      city: "Madison",
      zip_code: "15663",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5244",
      city: "Madisonburg",
      zip_code: "16852",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "4823",
      city: "Mahaffey",
      zip_code: "15757",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5665",
      city: "Mahanoy City",
      zip_code: "17948",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5666",
      city: "Mahanoy Plane",
      zip_code: "17949",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5286",
      city: "Mainesburg",
      zip_code: "16932",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "6141",
      city: "Malvern",
      zip_code: "19355",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5475",
      city: "Manchester",
      zip_code: "17345",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5525",
      city: "Manheim",
      zip_code: "17545",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "4697",
      city: "Manns Choice",
      zip_code: "15550",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "4753",
      city: "Manor",
      zip_code: "15665",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5016",
      city: "Manorville",
      zip_code: "16238",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5287",
      city: "Mansfield",
      zip_code: "16933",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5348",
      city: "Mapleton Depot",
      zip_code: "17052",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5667",
      city: "Mar Lin",
      zip_code: "17951",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5053",
      city: "Marble",
      zip_code: "16334",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "6049",
      city: "Marcus Hook",
      zip_code: "19061",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "4577",
      city: "Marianna",
      zip_code: "15345",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5017",
      city: "Marienville",
      zip_code: "16239",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5526",
      city: "Marietta",
      zip_code: "17547",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5417",
      city: "Marion",
      zip_code: "17235",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "4824",
      city: "Marion Center",
      zip_code: "15759",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5606",
      city: "Marion Heights",
      zip_code: "17832",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "4698",
      city: "Markleton",
      zip_code: "15551",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "4645",
      city: "Markleysburg",
      zip_code: "15459",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "4939",
      city: "Mars",
      zip_code: "16046",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5804",
      city: "Marshalls Creek",
      zip_code: "18335",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "4825",
      city: "Marsteller",
      zip_code: "15760",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "4646",
      city: "Martin",
      zip_code: "15460",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5722",
      city: "Martins Creek",
      zip_code: "18063",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5163",
      city: "Martinsburg",
      zip_code: "16662",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5668",
      city: "Mary D",
      zip_code: "17952",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5349",
      city: "Marysville",
      zip_code: "17053",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "4647",
      city: "Masontown",
      zip_code: "15461",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5805",
      city: "Matamoras",
      zip_code: "18336",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "4578",
      city: "Mather",
      zip_code: "15346",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5350",
      city: "Mattawana",
      zip_code: "17054",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "6211",
      city: "Maxatawny",
      zip_code: "19538",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5018",
      city: "Mayport",
      zip_code: "16240",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5527",
      city: "Maytown",
      zip_code: "17550",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5774",
      city: "Mcadoo",
      zip_code: "18237",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5345",
      city: "McAlisterville",
      zip_code: "17049",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "4644",
      city: "McClellandtown",
      zip_code: "15458",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5612",
      city: "McClure",
      zip_code: "17841",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5416",
      city: "McConnellsburg",
      zip_code: "17233",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "4450",
      city: "McDonald",
      zip_code: "15057",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5570",
      city: "McElhattan",
      zip_code: "17748",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5571",
      city: "McEwensville",
      zip_code: "17749",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5015",
      city: "McGrann",
      zip_code: "16236",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "4822",
      city: "McIntyre",
      zip_code: "15756",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5096",
      city: "McKean",
      zip_code: "16426",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "4495",
      city: "McKees Rocks",
      zip_code: "15136",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "4491",
      city: "Mckeesport",
      zip_code: "15131",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5473",
      city: "McKnightstown",
      zip_code: "17343",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5474",
      city: "McSherrystown",
      zip_code: "17344",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5347",
      city: "McVeytown",
      zip_code: "17051",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "4579",
      city: "Meadow Lands",
      zip_code: "15347",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5054",
      city: "Meadville",
      zip_code: "16335",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5346",
      city: "Mechanicsburg",
      zip_code: "17050",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "6050",
      city: "Media",
      zip_code: "19063",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5905",
      city: "Mehoopany",
      zip_code: "18629",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "4648",
      city: "Melcroft",
      zip_code: "15462",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "4826",
      city: "Mentcle",
      zip_code: "15761",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "4977",
      city: "Mercer",
      zip_code: "16137",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5418",
      city: "Mercersburg",
      zip_code: "17236",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "6052",
      city: "Merion Station",
      zip_code: "19066",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "4649",
      city: "Merrittstown",
      zip_code: "15463",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "6212",
      city: "Mertztown",
      zip_code: "19539",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5906",
      city: "Meshoppen",
      zip_code: "18630",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5352",
      city: "Mexico",
      zip_code: "17056",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "4699",
      city: "Meyersdale",
      zip_code: "15552",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5613",
      city: "Middleburg",
      zip_code: "17842",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5288",
      city: "Middlebury Center",
      zip_code: "16935",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5669",
      city: "Middleport",
      zip_code: "17953",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5353",
      city: "Middletown",
      zip_code: "17057",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "4451",
      city: "Midland",
      zip_code: "15059",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "4452",
      city: "Midway",
      zip_code: "15060",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5354",
      city: "Mifflin",
      zip_code: "17058",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5614",
      city: "Mifflinburg",
      zip_code: "17844",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5355",
      city: "Mifflintown",
      zip_code: "17059",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5907",
      city: "Mifflinville",
      zip_code: "18631",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5948",
      city: "Milan",
      zip_code: "18831",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5851",
      city: "Milanville",
      zip_code: "18443",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5908",
      city: "Mildred",
      zip_code: "18632",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5245",
      city: "Milesburg",
      zip_code: "16853",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5806",
      city: "Milford",
      zip_code: "18337",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5981",
      city: "Milford Square",
      zip_code: "18935",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5356",
      city: "Mill Creek",
      zip_code: "17060",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5573",
      city: "Mill Hall",
      zip_code: "17751",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "4650",
      city: "Mill Run",
      zip_code: "15464",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5097",
      city: "Mill Village",
      zip_code: "16427",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5357",
      city: "Millersburg",
      zip_code: "17061",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5358",
      city: "Millerstown",
      zip_code: "17062",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5528",
      city: "Millersville",
      zip_code: "17551",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5289",
      city: "Millerton",
      zip_code: "16936",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5246",
      city: "Millheim",
      zip_code: "16854",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5615",
      city: "Millmont",
      zip_code: "17845",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5807",
      city: "Millrift",
      zip_code: "18340",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5290",
      city: "Mills",
      zip_code: "16937",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "4580",
      city: "Millsboro",
      zip_code: "15348",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5616",
      city: "Millville",
      zip_code: "17846",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5775",
      city: "Milnesville",
      zip_code: "18239",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5359",
      city: "Milroy",
      zip_code: "17063",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5617",
      city: "Milton",
      zip_code: "17847",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "4900",
      city: "Mineral Point",
      zip_code: "15942",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5247",
      city: "Mineral Springs",
      zip_code: "16855",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5670",
      city: "Minersville",
      zip_code: "17954",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5248",
      city: "Mingoville",
      zip_code: "16856",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "6142",
      city: "Modena",
      zip_code: "19358",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "6213",
      city: "Mohnton",
      zip_code: "19540",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "6214",
      city: "Mohrsville",
      zip_code: "19541",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "4453",
      city: "Monaca",
      zip_code: "15061",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "4454",
      city: "Monessen",
      zip_code: "15062",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "4455",
      city: "Monongahela",
      zip_code: "15063",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5949",
      city: "Monroeton",
      zip_code: "18832",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "4503",
      city: "Monroeville",
      zip_code: "15146",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5419",
      city: "Mont Alto",
      zip_code: "17237",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "6172",
      city: "Mont Clare",
      zip_code: "19453",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5618",
      city: "Montandon",
      zip_code: "17850",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5574",
      city: "Montgomery",
      zip_code: "17752",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5982",
      city: "Montgomeryville",
      zip_code: "18936",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5575",
      city: "Montoursville",
      zip_code: "17754",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5931",
      city: "Montrose",
      zip_code: "18801",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5879",
      city: "Moosic",
      zip_code: "18507",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "4456",
      city: "Morgan",
      zip_code: "15064",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "6215",
      city: "Morgantown",
      zip_code: "19543",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5291",
      city: "Morris",
      zip_code: "16938",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5292",
      city: "Morris Run",
      zip_code: "16939",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5249",
      city: "Morrisdale",
      zip_code: "16858",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "6053",
      city: "Morrisville",
      zip_code: "19067",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "6054",
      city: "Morton",
      zip_code: "19070",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5852",
      city: "Moscow",
      zip_code: "18444",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5250",
      city: "Moshannon",
      zip_code: "16859",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "6216",
      city: "Mount Aetna",
      zip_code: "19544",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5809",
      city: "Mount Bethel",
      zip_code: "18343",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "4651",
      city: "Mount Braddock",
      zip_code: "15465",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5619",
      city: "Mount Carmel",
      zip_code: "17851",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5360",
      city: "Mount Gretna",
      zip_code: "17064",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5361",
      city: "Mount Holly Springs",
      zip_code: "17065",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5206",
      city: "Mount Jewett",
      zip_code: "16740",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5529",
      city: "Mount Joy",
      zip_code: "17552",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "4581",
      city: "Mount Morris",
      zip_code: "15349",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "4754",
      city: "Mount Pleasant",
      zip_code: "15666",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5620",
      city: "Mount Pleasant Mills",
      zip_code: "17853",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5810",
      city: "Mount Pocono",
      zip_code: "18344",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5362",
      city: "Mount Union",
      zip_code: "17066",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5476",
      city: "Mount Wolf",
      zip_code: "17347",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5928",
      city: "Mountain Top",
      zip_code: "18707",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5808",
      city: "Mountainhome",
      zip_code: "18342",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5530",
      city: "Mountville",
      zip_code: "17554",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5671",
      city: "Muir",
      zip_code: "17957",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5576",
      city: "Muncy",
      zip_code: "17756",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5577",
      city: "Muncy Valley",
      zip_code: "17758",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5251",
      city: "Munson",
      zip_code: "16860",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "4755",
      city: "Murrysville",
      zip_code: "15668",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "4582",
      city: "Muse",
      zip_code: "15350",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5363",
      city: "Myerstown",
      zip_code: "17067",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5909",
      city: "Nanticoke",
      zip_code: "18634",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "4901",
      city: "Nanty Glo",
      zip_code: "15943",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "6055",
      city: "Narberth",
      zip_code: "19072",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5531",
      city: "Narvon",
      zip_code: "17555",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "4457",
      city: "Natrona Heights",
      zip_code: "15065",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5723",
      city: "Nazareth",
      zip_code: "18064",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5420",
      city: "Needmore",
      zip_code: "17238",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5421",
      city: "Neelyton",
      zip_code: "17239",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5724",
      city: "Neffs",
      zip_code: "18065",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5293",
      city: "Nelson",
      zip_code: "16940",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "4583",
      city: "Nemacolin",
      zip_code: "15351",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5910",
      city: "Nescopeck",
      zip_code: "18635",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5776",
      city: "Nesquehoning",
      zip_code: "18240",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5950",
      city: "New Albany",
      zip_code: "18833",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "4756",
      city: "New Alexandria",
      zip_code: "15670",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "4978",
      city: "New Bedford",
      zip_code: "16140",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5621",
      city: "New Berlin",
      zip_code: "17855",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "6217",
      city: "New Berlinville",
      zip_code: "19545",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5019",
      city: "New Bethlehem",
      zip_code: "16242",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5364",
      city: "New Bloomfield",
      zip_code: "17068",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "4458",
      city: "New Brighton",
      zip_code: "15066",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5365",
      city: "New Buffalo",
      zip_code: "17069",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "4954",
      city: "New Castle",
      zip_code: "16101",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5622",
      city: "New Columbia",
      zip_code: "17856",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5366",
      city: "New Cumberland",
      zip_code: "17070",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "4757",
      city: "New Derry",
      zip_code: "15671",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "4459",
      city: "New Eagle",
      zip_code: "15067",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5164",
      city: "New Enterprise",
      zip_code: "16664",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "4902",
      city: "New Florence",
      zip_code: "15944",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5477",
      city: "New Freedom",
      zip_code: "17349",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "4584",
      city: "New Freeport",
      zip_code: "15352",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "4979",
      city: "New Galilee",
      zip_code: "16141",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "4653",
      city: "New Geneva",
      zip_code: "15467",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5367",
      city: "New Germantown",
      zip_code: "17071",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5532",
      city: "New Holland",
      zip_code: "17557",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5983",
      city: "New Hope",
      zip_code: "18938",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "4460",
      city: "New Kensington",
      zip_code: "15068",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5951",
      city: "New Milford",
      zip_code: "18834",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5252",
      city: "New Millport",
      zip_code: "16861",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5478",
      city: "New Oxford",
      zip_code: "17350",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "4700",
      city: "New Paris",
      zip_code: "15554",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5479",
      city: "New Park",
      zip_code: "17352",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5672",
      city: "New Philadelphia",
      zip_code: "17959",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5533",
      city: "New Providence",
      zip_code: "17560",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5673",
      city: "New Ringgold",
      zip_code: "17960",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "4654",
      city: "New Salem",
      zip_code: "15468",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "4758",
      city: "New Stanton",
      zip_code: "15672",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5725",
      city: "New Tripoli",
      zip_code: "18066",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "4980",
      city: "New Wilmington",
      zip_code: "16142",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5422",
      city: "Newburg",
      zip_code: "17240",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "4652",
      city: "Newell",
      zip_code: "15466",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5853",
      city: "Newfoundland",
      zip_code: "18445",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5368",
      city: "Newmanstown",
      zip_code: "17073",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5369",
      city: "Newport",
      zip_code: "17074",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5165",
      city: "Newry",
      zip_code: "16665",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5370",
      city: "Newton Hamilton",
      zip_code: "17075",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5984",
      city: "Newtown",
      zip_code: "18940",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "6056",
      city: "Newtown Square",
      zip_code: "19073",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5423",
      city: "Newville",
      zip_code: "17241",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5854",
      city: "Nicholson",
      zip_code: "18446",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "4827",
      city: "Nicktown",
      zip_code: "15762",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "4585",
      city: "Nineveh",
      zip_code: "15353",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "4655",
      city: "Normalville",
      zip_code: "15469",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "6156",
      city: "Norristown",
      zip_code: "19401",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "4759",
      city: "North Apollo",
      zip_code: "15673",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5578",
      city: "North Bend",
      zip_code: "17760",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5098",
      city: "North East",
      zip_code: "16428",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5099",
      city: "North Springfield",
      zip_code: "16430",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "4496",
      city: "North Versailles",
      zip_code: "15137",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "6173",
      city: "North Wales",
      zip_code: "19454",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "4940",
      city: "North Washington",
      zip_code: "16048",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5726",
      city: "Northampton",
      zip_code: "18067",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "4789",
      city: "Northern Cambria",
      zip_code: "15714",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "4828",
      city: "Northpoint",
      zip_code: "15763",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5623",
      city: "Northumberland",
      zip_code: "17857",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "4760",
      city: "Norvelt",
      zip_code: "15674",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "6057",
      city: "Norwood",
      zip_code: "19074",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "6143",
      city: "Nottingham",
      zip_code: "19362",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5911",
      city: "Noxen",
      zip_code: "18636",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5020",
      city: "Nu Mine",
      zip_code: "16244",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5624",
      city: "Numidia",
      zip_code: "17858",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5777",
      city: "Nuremberg",
      zip_code: "18241",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5021",
      city: "Oak Ridge",
      zip_code: "16245",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "4461",
      city: "Oakdale",
      zip_code: "15071",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5371",
      city: "Oakland Mills",
      zip_code: "17076",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "4497",
      city: "Oakmont",
      zip_code: "15139",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "6174",
      city: "Oaks",
      zip_code: "19456",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "4656",
      city: "Ohiopyle",
      zip_code: "15470",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5035",
      city: "Oil City",
      zip_code: "16301",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5253",
      city: "Olanta",
      zip_code: "16863",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5885",
      city: "Old Forge",
      zip_code: "18518",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "6218",
      city: "Oley",
      zip_code: "19547",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "4829",
      city: "Oliveburg",
      zip_code: "15764",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "4657",
      city: "Oliver",
      zip_code: "15472",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5855",
      city: "Olyphant",
      zip_code: "18447",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5778",
      city: "Oneida",
      zip_code: "18242",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5372",
      city: "Ono",
      zip_code: "17077",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5625",
      city: "Orangeville",
      zip_code: "17859",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5424",
      city: "Orbisonia",
      zip_code: "17243",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5727",
      city: "Orefield",
      zip_code: "18069",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "6058",
      city: "Oreland",
      zip_code: "19075",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5425",
      city: "Orrstown",
      zip_code: "17244",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5480",
      city: "Orrtanna",
      zip_code: "17353",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5254",
      city: "Orviston",
      zip_code: "16864",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5674",
      city: "Orwigsburg",
      zip_code: "17961",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5295",
      city: "Osceola",
      zip_code: "16942",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5166",
      city: "Osceola Mills",
      zip_code: "16666",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5167",
      city: "Osterburg",
      zip_code: "16667",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5985",
      city: "Ottsville",
      zip_code: "18942",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "6144",
      city: "Oxford",
      zip_code: "19363",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5728",
      city: "Palm",
      zip_code: "18070",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5729",
      city: "Palmerton",
      zip_code: "18071",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5373",
      city: "Palmyra",
      zip_code: "17078",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "6122",
      city: "Paoli",
      zip_code: "19301",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5534",
      city: "Paradise",
      zip_code: "17562",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "4941",
      city: "Parker",
      zip_code: "16049",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "6175",
      city: "Parker Ford",
      zip_code: "19457",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "6145",
      city: "Parkesburg",
      zip_code: "19365",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "4903",
      city: "Parkhill",
      zip_code: "15945",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5779",
      city: "Parryville",
      zip_code: "18244",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5168",
      city: "Patton",
      zip_code: "16668",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5856",
      city: "Paupack",
      zip_code: "18451",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5626",
      city: "Paxinos",
      zip_code: "17860",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5627",
      city: "Paxtonville",
      zip_code: "17861",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5535",
      city: "Peach Bottom",
      zip_code: "17563",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5857",
      city: "Peckville",
      zip_code: "18452",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5730",
      city: "Pen Argyl",
      zip_code: "18072",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "4863",
      city: "Penfield",
      zip_code: "15849",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "4761",
      city: "Penn",
      zip_code: "15675",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "4830",
      city: "Penn Run",
      zip_code: "15765",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5628",
      city: "Penns Creek",
      zip_code: "17862",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5731",
      city: "Pennsburg",
      zip_code: "18073",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5255",
      city: "Pennsylvania Furnace",
      zip_code: "16865",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5536",
      city: "Pequea",
      zip_code: "17565",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5986",
      city: "Perkasie",
      zip_code: "18944",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5732",
      city: "Perkiomenville",
      zip_code: "18074",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "4658",
      city: "Perryopolis",
      zip_code: "15473",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5169",
      city: "Petersburg",
      zip_code: "16669",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "4942",
      city: "Petrolia",
      zip_code: "16050",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "6072",
      city: "Philadelphia",
      zip_code: "19102",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5256",
      city: "Philipsburg",
      zip_code: "16866",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "6176",
      city: "Phoenixville",
      zip_code: "19460",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5579",
      city: "Picture Rocks",
      zip_code: "17762",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5374",
      city: "Pillow",
      zip_code: "17080",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5675",
      city: "Pine Grove",
      zip_code: "17963",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5257",
      city: "Pine Grove Mills",
      zip_code: "16868",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5987",
      city: "Pineville",
      zip_code: "18946",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5988",
      city: "Pipersville",
      zip_code: "18947",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "4498",
      city: "Pitcairn",
      zip_code: "15140",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5676",
      city: "Pitman",
      zip_code: "17964",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "4506",
      city: "Pittsburgh",
      zip_code: "15201",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5055",
      city: "Pittsfield",
      zip_code: "16340",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5912",
      city: "Pittston",
      zip_code: "18640",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5375",
      city: "Plainfield",
      zip_code: "17081",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5426",
      city: "Pleasant Hall",
      zip_code: "17246",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5858",
      city: "Pleasant Mount",
      zip_code: "18453",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "4762",
      city: "Pleasant Unity",
      zip_code: "15676",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5056",
      city: "Pleasantville",
      zip_code: "16341",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5022",
      city: "Plumville",
      zip_code: "16246",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5917",
      city: "Plymouth",
      zip_code: "18651",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "6177",
      city: "Plymouth Meeting",
      zip_code: "19462",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5812",
      city: "Pocono Lake",
      zip_code: "18347",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5813",
      city: "Pocono Manor",
      zip_code: "18349",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5814",
      city: "Pocono Pines",
      zip_code: "18350",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5811",
      city: "Pocono Summit",
      zip_code: "18346",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "4659",
      city: "Point Marion",
      zip_code: "15474",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5989",
      city: "Point Pleasant",
      zip_code: "18950",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5057",
      city: "Polk",
      zip_code: "16342",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "6146",
      city: "Pomeroy",
      zip_code: "19367",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5207",
      city: "Port Allegany",
      zip_code: "16743",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5677",
      city: "Port Carbon",
      zip_code: "17965",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "6219",
      city: "Port Clinton",
      zip_code: "19549",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5258",
      city: "Port Matilda",
      zip_code: "16870",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5376",
      city: "Port Royal",
      zip_code: "17082",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5629",
      city: "Port Trevorton",
      zip_code: "17864",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "4904",
      city: "Portage",
      zip_code: "15946",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "4943",
      city: "Portersville",
      zip_code: "16051",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5815",
      city: "Portland",
      zip_code: "18351",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5259",
      city: "Pottersdale",
      zip_code: "16871",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5630",
      city: "Potts Grove",
      zip_code: "17865",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "6178",
      city: "Pottstown",
      zip_code: "19464",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5646",
      city: "Pottsville",
      zip_code: "17901",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5859",
      city: "Poyntelle",
      zip_code: "18454",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "4499",
      city: "Presto",
      zip_code: "15142",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5860",
      city: "Preston Park",
      zip_code: "18455",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "4462",
      city: "Pricedale",
      zip_code: "15072",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5861",
      city: "Prompton",
      zip_code: "18456",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "4944",
      city: "Prospect",
      zip_code: "16052",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "6059",
      city: "Prospect Park",
      zip_code: "19076",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "4565",
      city: "Prosperity",
      zip_code: "15329",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "4981",
      city: "Pulaski",
      zip_code: "16143",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "4831",
      city: "Punxsutawney",
      zip_code: "15767",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5780",
      city: "Quakake",
      zip_code: "18245",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5990",
      city: "Quakertown",
      zip_code: "18951",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5537",
      city: "Quarryville",
      zip_code: "17566",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "4701",
      city: "Quecreek",
      zip_code: "15555",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5170",
      city: "Queen",
      zip_code: "16670",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5427",
      city: "Quincy",
      zip_code: "17247",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5481",
      city: "Railroad",
      zip_code: "17355",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5580",
      city: "Ralston",
      zip_code: "17763",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5171",
      city: "Ramey",
      zip_code: "16671",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "6230",
      city: "Reading",
      zip_code: "19601",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5260",
      city: "Rebersburg",
      zip_code: "16872",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5632",
      city: "Rebuck",
      zip_code: "17867",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "4763",
      city: "Rector",
      zip_code: "15677",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5733",
      city: "Red Hill",
      zip_code: "18076",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5482",
      city: "Red Lion",
      zip_code: "17356",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5377",
      city: "Reedsville",
      zip_code: "17084",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5538",
      city: "Refton",
      zip_code: "17568",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "6220",
      city: "Rehrersburg",
      zip_code: "19550",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5539",
      city: "Reinholds",
      zip_code: "17569",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "4945",
      city: "Renfrew",
      zip_code: "16053",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5058",
      city: "Reno",
      zip_code: "16343",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5581",
      city: "Renovo",
      zip_code: "17764",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "4660",
      city: "Republic",
      zip_code: "15475",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "4905",
      city: "Revloc",
      zip_code: "15948",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5208",
      city: "Rew",
      zip_code: "16744",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "4864",
      city: "Reynoldsville",
      zip_code: "15851",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5540",
      city: "Rheems",
      zip_code: "17570",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "4586",
      city: "Rices Landing",
      zip_code: "15357",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5991",
      city: "Richboro",
      zip_code: "18954",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "4587",
      city: "Richeyville",
      zip_code: "15358",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5378",
      city: "Richfield",
      zip_code: "17086",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5379",
      city: "Richland",
      zip_code: "17087",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5992",
      city: "Richlandtown",
      zip_code: "18955",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5172",
      city: "Riddlesburg",
      zip_code: "16672",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "4865",
      city: "Ridgway",
      zip_code: "15853",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "6060",
      city: "Ridley Park",
      zip_code: "19078",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5734",
      city: "Riegelsville",
      zip_code: "18077",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "4764",
      city: "Rillton",
      zip_code: "15678",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5023",
      city: "Rimersburg",
      zip_code: "16248",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "4832",
      city: "Ringgold",
      zip_code: "15770",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5678",
      city: "Ringtown",
      zip_code: "17967",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5633",
      city: "Riverside",
      zip_code: "17868",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5209",
      city: "Rixford",
      zip_code: "16745",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5582",
      city: "Roaring Branch",
      zip_code: "17765",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5173",
      city: "Roaring Spring",
      zip_code: "16673",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5174",
      city: "Robertsdale",
      zip_code: "16674",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "6221",
      city: "Robesonia",
      zip_code: "19551",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "4906",
      city: "Robinson",
      zip_code: "15949",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "4463",
      city: "Rochester",
      zip_code: "15074",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "4833",
      city: "Rochester Mills",
      zip_code: "15771",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5781",
      city: "Rock Glen",
      zip_code: "18246",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5428",
      city: "Rockhill Furnace",
      zip_code: "17249",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "4866",
      city: "Rockton",
      zip_code: "15856",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "4702",
      city: "Rockwood",
      zip_code: "15557",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "4588",
      city: "Rogersville",
      zip_code: "15359",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5952",
      city: "Rome",
      zip_code: "18837",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "4661",
      city: "Ronco",
      zip_code: "15476",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5541",
      city: "Ronks",
      zip_code: "17572",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "4662",
      city: "Roscoe",
      zip_code: "15477",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "4834",
      city: "Rossiter",
      zip_code: "15772",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5210",
      city: "Roulette",
      zip_code: "16746",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5059",
      city: "Rouseville",
      zip_code: "16344",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5429",
      city: "Rouzerville",
      zip_code: "17250",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5862",
      city: "Rowland",
      zip_code: "18457",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5430",
      city: "Roxbury",
      zip_code: "17251",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "6180",
      city: "Royersford",
      zip_code: "19468",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "4765",
      city: "Ruffs Dale",
      zip_code: "15679",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "4464",
      city: "Rural Ridge",
      zip_code: "15075",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5024",
      city: "Rural Valley",
      zip_code: "16249",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5060",
      city: "Russell",
      zip_code: "16345",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "4465",
      city: "Russellton",
      zip_code: "15076",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5296",
      city: "Sabinsville",
      zip_code: "16943",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5679",
      city: "Sacramento",
      zip_code: "17968",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "6147",
      city: "Sadsburyville",
      zip_code: "19369",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5100",
      city: "Saegertown",
      zip_code: "16433",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5025",
      city: "Sagamore",
      zip_code: "16250",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "4835",
      city: "Saint Benedict",
      zip_code: "15773",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5175",
      city: "Saint Boniface",
      zip_code: "16675",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5680",
      city: "Saint Clair",
      zip_code: "17970",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "4867",
      city: "Saint Marys",
      zip_code: "15857",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "4907",
      city: "Saint Michael",
      zip_code: "15951",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "4946",
      city: "Saint Petersburg",
      zip_code: "16054",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5431",
      city: "Saint Thomas",
      zip_code: "17252",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "4766",
      city: "Salina",
      zip_code: "15680",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "4703",
      city: "Salisbury",
      zip_code: "15558",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "4908",
      city: "Salix",
      zip_code: "15952",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5432",
      city: "Saltillo",
      zip_code: "17253",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "4767",
      city: "Saltsburg",
      zip_code: "15681",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "4982",
      city: "Sandy Lake",
      zip_code: "16145",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5176",
      city: "Sandy Ridge",
      zip_code: "16677",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "4947",
      city: "Sarver",
      zip_code: "16055",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "6181",
      city: "Sassamansville",
      zip_code: "19472",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "4948",
      city: "Saxonburg",
      zip_code: "16056",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5177",
      city: "Saxton",
      zip_code: "16678",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5816",
      city: "Saylorsburg",
      zip_code: "18353",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5953",
      city: "Sayre",
      zip_code: "18840",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "4589",
      city: "Scenery Hill",
      zip_code: "15360",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5380",
      city: "Schaefferstown",
      zip_code: "17088",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "4704",
      city: "Schellsburg",
      zip_code: "15559",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "4768",
      city: "Schenley",
      zip_code: "15682",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5735",
      city: "Schnecksville",
      zip_code: "18078",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5681",
      city: "Schuylkill Haven",
      zip_code: "17972",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "6182",
      city: "Schwenksville",
      zip_code: "19473",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5817",
      city: "Sciota",
      zip_code: "18354",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5433",
      city: "Scotland",
      zip_code: "17254",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5818",
      city: "Scotrun",
      zip_code: "18355",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "4769",
      city: "Scottdale",
      zip_code: "15683",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5876",
      city: "Scranton",
      zip_code: "18503",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "4909",
      city: "Seanor",
      zip_code: "15953",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5634",
      city: "Selinsgrove",
      zip_code: "17870",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5993",
      city: "Sellersville",
      zip_code: "18960",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5682",
      city: "Seltzer",
      zip_code: "17974",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5026",
      city: "Seminole",
      zip_code: "16253",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5061",
      city: "Seneca",
      zip_code: "16346",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5483",
      city: "Seven Valleys",
      zip_code: "17360",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "4910",
      city: "Seward",
      zip_code: "15954",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "4500",
      city: "Sewickley",
      zip_code: "15143",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5434",
      city: "Shade Gap",
      zip_code: "17255",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5635",
      city: "Shamokin",
      zip_code: "17872",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5636",
      city: "Shamokin Dam",
      zip_code: "17876",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "4705",
      city: "Shanksville",
      zip_code: "15560",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "4983",
      city: "Sharon",
      zip_code: "16146",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "6061",
      city: "Sharon Hill",
      zip_code: "19079",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "4985",
      city: "Sharpsville",
      zip_code: "16150",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "6222",
      city: "Shartlesville",
      zip_code: "19554",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5929",
      city: "Shavertown",
      zip_code: "18708",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5819",
      city: "Shawnee On Delaware",
      zip_code: "18356",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "4986",
      city: "Sheakleyville",
      zip_code: "16151",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5062",
      city: "Sheffield",
      zip_code: "16347",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "4836",
      city: "Shelocta",
      zip_code: "15774",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5683",
      city: "Shenandoah",
      zip_code: "17976",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5782",
      city: "Sheppton",
      zip_code: "18248",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5381",
      city: "Shermans Dale",
      zip_code: "17090",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5918",
      city: "Shickshinny",
      zip_code: "18655",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5211",
      city: "Shinglehouse",
      zip_code: "16748",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5435",
      city: "Shippensburg",
      zip_code: "17257",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5027",
      city: "Shippenville",
      zip_code: "16254",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "4466",
      city: "Shippingport",
      zip_code: "15077",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5436",
      city: "Shirleysburg",
      zip_code: "17260",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "6223",
      city: "Shoemakersville",
      zip_code: "19555",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5863",
      city: "Shohola",
      zip_code: "18458",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5484",
      city: "Shrewsbury",
      zip_code: "17361",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5583",
      city: "Shunk",
      zip_code: "17768",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "4911",
      city: "Sidman",
      zip_code: "15955",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "4868",
      city: "Sigel",
      zip_code: "15860",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5994",
      city: "Silverdale",
      zip_code: "18962",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "4869",
      city: "Sinnamahoning",
      zip_code: "15861",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "4706",
      city: "Sipesville",
      zip_code: "15561",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5178",
      city: "Six Mile Run",
      zip_code: "16679",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "6183",
      city: "Skippack",
      zip_code: "19474",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5820",
      city: "Skytop",
      zip_code: "18357",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5584",
      city: "Slate Run",
      zip_code: "17769",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5736",
      city: "Slatedale",
      zip_code: "18079",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5737",
      city: "Slatington",
      zip_code: "18080",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "4770",
      city: "Slickville",
      zip_code: "15684",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5028",
      city: "Sligo",
      zip_code: "16255",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "4949",
      city: "Slippery Rock",
      zip_code: "16057",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "4467",
      city: "Slovan",
      zip_code: "15078",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5212",
      city: "Smethport",
      zip_code: "16749",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5029",
      city: "Smicksburg",
      zip_code: "16256",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "4663",
      city: "Smithfield",
      zip_code: "15478",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5179",
      city: "Smithmill",
      zip_code: "16680",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "4664",
      city: "Smithton",
      zip_code: "15479",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "4665",
      city: "Smock",
      zip_code: "15480",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5542",
      city: "Smoketown",
      zip_code: "17576",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5261",
      city: "Snow Shoe",
      zip_code: "16874",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "4674",
      city: "Somerset",
      zip_code: "15501",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5995",
      city: "Souderton",
      zip_code: "18964",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "4912",
      city: "South Fork",
      zip_code: "15956",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5954",
      city: "South Gibson",
      zip_code: "18842",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "4468",
      city: "South Heights",
      zip_code: "15081",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5955",
      city: "South Montrose",
      zip_code: "18843",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5437",
      city: "South Mountain",
      zip_code: "17261",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "4490",
      city: "South Park",
      zip_code: "15129",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5864",
      city: "South Sterling",
      zip_code: "18460",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5996",
      city: "Southampton",
      zip_code: "18966",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "4590",
      city: "Southview",
      zip_code: "15361",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "4837",
      city: "Spangler",
      zip_code: "15775",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5101",
      city: "Spartansburg",
      zip_code: "16434",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "4591",
      city: "Spraggs",
      zip_code: "15362",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "4838",
      city: "Sprankle Mills",
      zip_code: "15776",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "4771",
      city: "Spring Church",
      zip_code: "15686",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "6184",
      city: "Spring City",
      zip_code: "19475",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5103",
      city: "Spring Creek",
      zip_code: "16436",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5684",
      city: "Spring Glen",
      zip_code: "17978",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5485",
      city: "Spring Grove",
      zip_code: "17362",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "6185",
      city: "Spring House",
      zip_code: "19477",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5262",
      city: "Spring Mills",
      zip_code: "16875",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5438",
      city: "Spring Run",
      zip_code: "17262",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5102",
      city: "Springboro",
      zip_code: "16435",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "4501",
      city: "Springdale",
      zip_code: "15144",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "6051",
      city: "Springfield",
      zip_code: "19064",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "4707",
      city: "Springs",
      zip_code: "15562",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5738",
      city: "Springtown",
      zip_code: "18081",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5956",
      city: "Springville",
      zip_code: "18844",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5180",
      city: "Sproul",
      zip_code: "16682",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5181",
      city: "Spruce Creek",
      zip_code: "16683",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "4772",
      city: "Stahlstown",
      zip_code: "15687",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "4666",
      city: "Star Junction",
      zip_code: "15482",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "4839",
      city: "Starford",
      zip_code: "15777",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5865",
      city: "Starlight",
      zip_code: "18461",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5866",
      city: "Starrucca",
      zip_code: "18462",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5214",
      city: "State College",
      zip_code: "16801",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5439",
      city: "State Line",
      zip_code: "17263",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5867",
      city: "Sterling",
      zip_code: "18463",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5543",
      city: "Stevens",
      zip_code: "17578",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5957",
      city: "Stevensville",
      zip_code: "18845",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5486",
      city: "Stewartstown",
      zip_code: "17363",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5637",
      city: "Stillwater",
      zip_code: "17878",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "4667",
      city: "Stockdale",
      zip_code: "15483",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5739",
      city: "Stockertown",
      zip_code: "18083",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "4987",
      city: "Stoneboro",
      zip_code: "16153",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "4708",
      city: "Stoystown",
      zip_code: "15563",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "4592",
      city: "Strabane",
      zip_code: "15363",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5544",
      city: "Strasburg",
      zip_code: "17579",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5030",
      city: "Strattanville",
      zip_code: "16258",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "6224",
      city: "Strausstown",
      zip_code: "19559",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "4913",
      city: "Strongstown",
      zip_code: "15957",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5821",
      city: "Stroudsburg",
      zip_code: "18360",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "4870",
      city: "Stump Creek",
      zip_code: "15863",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "4469",
      city: "Sturgeon",
      zip_code: "15082",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5063",
      city: "Sugar Grove",
      zip_code: "16350",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5958",
      city: "Sugar Run",
      zip_code: "18846",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5783",
      city: "Sugarloaf",
      zip_code: "18249",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5382",
      city: "Summerdale",
      zip_code: "17093",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "4914",
      city: "Summerhill",
      zip_code: "15958",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "4871",
      city: "Summerville",
      zip_code: "15864",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5784",
      city: "Summit Hill",
      zip_code: "18250",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5685",
      city: "Summit Station",
      zip_code: "17979",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5592",
      city: "Sunbury",
      zip_code: "17801",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5959",
      city: "Susquehanna",
      zip_code: "18847",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "4470",
      city: "Sutersville",
      zip_code: "15083",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "6062",
      city: "Swarthmore",
      zip_code: "19081",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5919",
      city: "Sweet Valley",
      zip_code: "18656",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5638",
      city: "Swengel",
      zip_code: "17880",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5822",
      city: "Swiftwater",
      zip_code: "18370",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "4593",
      city: "Sycamore",
      zip_code: "15364",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "4872",
      city: "Sykesville",
      zip_code: "15865",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5868",
      city: "Tafton",
      zip_code: "18464",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5785",
      city: "Tamaqua",
      zip_code: "18252",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5823",
      city: "Tamiment",
      zip_code: "18371",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5824",
      city: "Tannersville",
      zip_code: "18372",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "4471",
      city: "Tarentum",
      zip_code: "15084",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "4773",
      city: "Tarrs",
      zip_code: "15688",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5740",
      city: "Tatamy",
      zip_code: "18085",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5884",
      city: "Taylor",
      zip_code: "18517",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "4594",
      city: "Taylorstown",
      zip_code: "15365",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5997",
      city: "Telford",
      zip_code: "18969",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "6225",
      city: "Temple",
      zip_code: "19560",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5031",
      city: "Templeton",
      zip_code: "16259",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5545",
      city: "Terre Hill",
      zip_code: "17581",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5487",
      city: "Thomasville",
      zip_code: "17364",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5869",
      city: "Thompson",
      zip_code: "18465",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5383",
      city: "Thompsontown",
      zip_code: "17094",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "6148",
      city: "Thorndale",
      zip_code: "19372",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "6149",
      city: "Thornton",
      zip_code: "19373",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5440",
      city: "Three Springs",
      zip_code: "17264",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5064",
      city: "Tidioute",
      zip_code: "16351",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "4840",
      city: "Timblin",
      zip_code: "15778",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5297",
      city: "Tioga",
      zip_code: "16946",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5065",
      city: "Tiona",
      zip_code: "16352",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5066",
      city: "Tionesta",
      zip_code: "16353",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5182",
      city: "Tipton",
      zip_code: "16684",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5067",
      city: "Titusville",
      zip_code: "16354",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5870",
      city: "Tobyhanna",
      zip_code: "18466",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5183",
      city: "Todd",
      zip_code: "16685",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "6226",
      city: "Topton",
      zip_code: "19562",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "4841",
      city: "Torrance",
      zip_code: "15779",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "6150",
      city: "Toughkenamon",
      zip_code: "19374",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5960",
      city: "Towanda",
      zip_code: "18848",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5686",
      city: "Tower City",
      zip_code: "17980",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5068",
      city: "Townville",
      zip_code: "16360",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "4472",
      city: "Trafford",
      zip_code: "15085",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "4988",
      city: "Transfer",
      zip_code: "16154",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5741",
      city: "Treichlers",
      zip_code: "18086",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5687",
      city: "Tremont",
      zip_code: "17981",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5786",
      city: "Tresckow",
      zip_code: "18254",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5639",
      city: "Trevorton",
      zip_code: "17881",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5742",
      city: "Trexlertown",
      zip_code: "18087",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5585",
      city: "Trout Run",
      zip_code: "17771",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "4873",
      city: "Troutville",
      zip_code: "15866",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5298",
      city: "Troy",
      zip_code: "16947",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5998",
      city: "Trumbauersville",
      zip_code: "18970",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5920",
      city: "Tunkhannock",
      zip_code: "18657",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5586",
      city: "Turbotville",
      zip_code: "17772",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "4502",
      city: "Turtle Creek",
      zip_code: "15145",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5213",
      city: "Turtlepoint",
      zip_code: "16750",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5688",
      city: "Tuscarora",
      zip_code: "17982",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "4915",
      city: "Twin Rocks",
      zip_code: "15960",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5871",
      city: "Tyler Hill",
      zip_code: "18469",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5069",
      city: "Tylersburg",
      zip_code: "16361",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5184",
      city: "Tyrone",
      zip_code: "16686",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "4668",
      city: "Uledi",
      zip_code: "15484",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5961",
      city: "Ulster",
      zip_code: "18850",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5299",
      city: "Ulysses",
      zip_code: "16948",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5104",
      city: "Union City",
      zip_code: "16438",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5872",
      city: "Union Dale",
      zip_code: "18470",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "4604",
      city: "Uniontown",
      zip_code: "15401",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "6151",
      city: "Unionville",
      zip_code: "19375",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "4774",
      city: "United",
      zip_code: "15689",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5587",
      city: "Unityville",
      zip_code: "17774",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5215",
      city: "University Park",
      zip_code: "16802",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5999",
      city: "Upper Black Eddy",
      zip_code: "18972",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "6063",
      city: "Upper Darby",
      zip_code: "19082",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5441",
      city: "Upperstrasburg",
      zip_code: "17265",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5070",
      city: "Utica",
      zip_code: "16362",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "4950",
      city: "Valencia",
      zip_code: "16059",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "4842",
      city: "Valier",
      zip_code: "15780",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5689",
      city: "Valley View",
      zip_code: "17983",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "4595",
      city: "Van Voorhis",
      zip_code: "15366",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "4669",
      city: "Vanderbilt",
      zip_code: "15486",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "4775",
      city: "Vandergrift",
      zip_code: "15690",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5105",
      city: "Venango",
      zip_code: "16440",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "4596",
      city: "Venetia",
      zip_code: "15367",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5071",
      city: "Venus",
      zip_code: "16364",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "4504",
      city: "Verona",
      zip_code: "15147",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "4597",
      city: "Vestaburg",
      zip_code: "15368",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "4989",
      city: "Villa Maria",
      zip_code: "16155",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "6065",
      city: "Villanova",
      zip_code: "19085",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "4916",
      city: "Vintondale",
      zip_code: "15961",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "6227",
      city: "Virginville",
      zip_code: "19564",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "4990",
      city: "Volant",
      zip_code: "16156",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5032",
      city: "Vowinckel",
      zip_code: "16260",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5263",
      city: "Wallaceton",
      zip_code: "16876",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "6066",
      city: "Wallingford",
      zip_code: "19086",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5442",
      city: "Walnut Bottom",
      zip_code: "17266",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5743",
      city: "Walnutport",
      zip_code: "18088",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "4843",
      city: "Walston",
      zip_code: "15781",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "4670",
      city: "Waltersburg",
      zip_code: "15488",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "4991",
      city: "Wampum",
      zip_code: "16157",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5921",
      city: "Wapwallopen",
      zip_code: "18660",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5443",
      city: "Warfordsburg",
      zip_code: "17267",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "6000",
      city: "Warminster",
      zip_code: "18974",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5072",
      city: "Warren",
      zip_code: "16365",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5962",
      city: "Warren Center",
      zip_code: "18851",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "4473",
      city: "Warrendale",
      zip_code: "15086",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "6001",
      city: "Warrington",
      zip_code: "18976",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5264",
      city: "Warriors Mark",
      zip_code: "16877",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "4549",
      city: "Washington",
      zip_code: "15301",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5546",
      city: "Washington Boro",
      zip_code: "17582",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "6002",
      city: "Washington Crossing",
      zip_code: "18977",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5640",
      city: "Washingtonville",
      zip_code: "17884",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5185",
      city: "Waterfall",
      zip_code: "16689",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5106",
      city: "Waterford",
      zip_code: "16441",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5588",
      city: "Waterville",
      zip_code: "17776",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5589",
      city: "Watsontown",
      zip_code: "17777",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5107",
      city: "Wattsburg",
      zip_code: "16442",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5873",
      city: "Waverly",
      zip_code: "18471",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5874",
      city: "Waymart",
      zip_code: "18472",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "6067",
      city: "Wayne",
      zip_code: "19087",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5444",
      city: "Waynesboro",
      zip_code: "17268",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "4598",
      city: "Waynesburg",
      zip_code: "15370",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5787",
      city: "Weatherly",
      zip_code: "18255",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "4474",
      city: "Webster",
      zip_code: "15087",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "4874",
      city: "Weedville",
      zip_code: "15868",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5641",
      city: "Weikert",
      zip_code: "17885",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "4709",
      city: "Wellersburg",
      zip_code: "15564",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5186",
      city: "Wells Tannery",
      zip_code: "16691",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5269",
      city: "Wellsboro",
      zip_code: "16901",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5488",
      city: "Wellsville",
      zip_code: "17365",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "4776",
      city: "Wendel",
      zip_code: "15691",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "6228",
      city: "Wernersville",
      zip_code: "19565",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "4599",
      city: "West Alexander",
      zip_code: "15376",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "6152",
      city: "West Chester",
      zip_code: "19380",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5265",
      city: "West Decatur",
      zip_code: "16878",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "4475",
      city: "West Elizabeth",
      zip_code: "15088",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "4600",
      city: "West Finley",
      zip_code: "15377",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "6155",
      city: "West Grove",
      zip_code: "19390",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5073",
      city: "West Hickory",
      zip_code: "16370",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "4844",
      city: "West Lebanon",
      zip_code: "15783",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "4671",
      city: "West Leisenring",
      zip_code: "15489",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "4992",
      city: "West Middlesex",
      zip_code: "16159",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "4602",
      city: "West Middletown",
      zip_code: "15379",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "4487",
      city: "West Mifflin",
      zip_code: "15122",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5642",
      city: "West Milton",
      zip_code: "17886",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "4476",
      city: "West Newton",
      zip_code: "15089",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "4993",
      city: "West Pittsburg",
      zip_code: "16160",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "4710",
      city: "West Salisbury",
      zip_code: "15565",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5108",
      city: "West Springfield",
      zip_code: "16443",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "4951",
      city: "West Sunbury",
      zip_code: "16061",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5300",
      city: "Westfield",
      zip_code: "16950",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "4601",
      city: "Westland",
      zip_code: "15378",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "4777",
      city: "Westmoreland City",
      zip_code: "15692",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5788",
      city: "Weston",
      zip_code: "18256",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5187",
      city: "Westover",
      zip_code: "16692",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5590",
      city: "Westport",
      zip_code: "17778",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "4477",
      city: "Wexford",
      zip_code: "15090",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "4994",
      city: "Wheatland",
      zip_code: "16161",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "4672",
      city: "White",
      zip_code: "15490",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5643",
      city: "White Deer",
      zip_code: "17887",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5922",
      city: "White Haven",
      zip_code: "18661",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5875",
      city: "White Mills",
      zip_code: "18473",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5714",
      city: "Whitehall",
      zip_code: "18052",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "4778",
      city: "Whitney",
      zip_code: "15693",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "4673",
      city: "Wickhaven",
      zip_code: "15492",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5384",
      city: "Wiconisco",
      zip_code: "17097",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5644",
      city: "Wilburton",
      zip_code: "17888",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "4875",
      city: "Wilcox",
      zip_code: "15870",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5923",
      city: "Wilkes Barre",
      zip_code: "18701",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5188",
      city: "Williamsburg",
      zip_code: "16693",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5552",
      city: "Williamsport",
      zip_code: "17701",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5385",
      city: "Williamstown",
      zip_code: "17098",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "6068",
      city: "Willow Grove",
      zip_code: "19090",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5445",
      city: "Willow Hill",
      zip_code: "17271",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5547",
      city: "Willow Street",
      zip_code: "17584",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "4505",
      city: "Wilmerding",
      zip_code: "15148",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "4917",
      city: "Wilmore",
      zip_code: "15962",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5266",
      city: "Winburne",
      zip_code: "16879",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5744",
      city: "Wind Gap",
      zip_code: "18091",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "4603",
      city: "Wind Ridge",
      zip_code: "15380",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "4918",
      city: "Windber",
      zip_code: "15963",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5489",
      city: "Windsor",
      zip_code: "17366",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5645",
      city: "Winfield",
      zip_code: "17889",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "6229",
      city: "Womelsdorf",
      zip_code: "19567",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5189",
      city: "Wood",
      zip_code: "16694",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5190",
      city: "Woodbury",
      zip_code: "16695",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5267",
      city: "Woodland",
      zip_code: "16881",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "6069",
      city: "Woodlyn",
      zip_code: "19094",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5268",
      city: "Woodward",
      zip_code: "16882",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5591",
      city: "Woolrich",
      zip_code: "17779",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "6186",
      city: "Worcester",
      zip_code: "19490",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5033",
      city: "Worthington",
      zip_code: "16262",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "4845",
      city: "Worthville",
      zip_code: "15784",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5490",
      city: "Wrightsville",
      zip_code: "17368",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5963",
      city: "Wyalusing",
      zip_code: "18853",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "4779",
      city: "Wyano",
      zip_code: "15695",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "6003",
      city: "Wycombe",
      zip_code: "18980",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "6070",
      city: "Wyncote",
      zip_code: "19095",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "6071",
      city: "Wynnewood",
      zip_code: "19096",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5916",
      city: "Wyoming",
      zip_code: "18644",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5964",
      city: "Wysox",
      zip_code: "18854",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5034",
      city: "Yatesboro",
      zip_code: "16263",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5386",
      city: "Yeagertown",
      zip_code: "17099",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5494",
      city: "York",
      zip_code: "17401",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5491",
      city: "York Haven",
      zip_code: "17370",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5492",
      city: "York New Salem",
      zip_code: "17371",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5493",
      city: "York Springs",
      zip_code: "17372",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "4780",
      city: "Youngstown",
      zip_code: "15696",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5074",
      city: "Youngsville",
      zip_code: "16371",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "4781",
      city: "Youngwood",
      zip_code: "15697",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "4782",
      city: "Yukon",
      zip_code: "15698",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "4952",
      city: "Zelienople",
      zip_code: "16063",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "6187",
      city: "Zieglerville",
      zip_code: "19492",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5690",
      city: "Zion Grove",
      zip_code: "17985",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "5745",
      city: "Zionsville",
      zip_code: "18092",
      state_id: "PA",
      state_name: "Pennsylvania",
    },
    {
      city_id: "678",
      city: "Albion",
      zip_code: "02802",
      state_id: "RI",
      state_name: "Rhode Island",
    },
    {
      city_id: "679",
      city: "Ashaway",
      zip_code: "02804",
      state_id: "RI",
      state_name: "Rhode Island",
    },
    {
      city_id: "680",
      city: "Barrington",
      zip_code: "02806",
      state_id: "RI",
      state_name: "Rhode Island",
    },
    {
      city_id: "681",
      city: "Block Island",
      zip_code: "02807",
      state_id: "RI",
      state_name: "Rhode Island",
    },
    {
      city_id: "682",
      city: "Bradford",
      zip_code: "02808",
      state_id: "RI",
      state_name: "Rhode Island",
    },
    {
      city_id: "683",
      city: "Bristol",
      zip_code: "02809",
      state_id: "RI",
      state_name: "Rhode Island",
    },
    {
      city_id: "684",
      city: "Carolina",
      zip_code: "02812",
      state_id: "RI",
      state_name: "Rhode Island",
    },
    {
      city_id: "717",
      city: "Central Falls",
      zip_code: "02863",
      state_id: "RI",
      state_name: "Rhode Island",
    },
    {
      city_id: "685",
      city: "Charlestown",
      zip_code: "02813",
      state_id: "RI",
      state_name: "Rhode Island",
    },
    {
      city_id: "686",
      city: "Chepachet",
      zip_code: "02814",
      state_id: "RI",
      state_name: "Rhode Island",
    },
    {
      city_id: "687",
      city: "Clayville",
      zip_code: "02815",
      state_id: "RI",
      state_name: "Rhode Island",
    },
    {
      city_id: "688",
      city: "Coventry",
      zip_code: "02816",
      state_id: "RI",
      state_name: "Rhode Island",
    },
    {
      city_id: "748",
      city: "Cranston",
      zip_code: "02910",
      state_id: "RI",
      state_name: "Rhode Island",
    },
    {
      city_id: "718",
      city: "Cumberland",
      zip_code: "02864",
      state_id: "RI",
      state_name: "Rhode Island",
    },
    {
      city_id: "690",
      city: "East Greenwich",
      zip_code: "02818",
      state_id: "RI",
      state_name: "Rhode Island",
    },
    {
      city_id: "751",
      city: "East Providence",
      zip_code: "02914",
      state_id: "RI",
      state_name: "Rhode Island",
    },
    {
      city_id: "691",
      city: "Exeter",
      zip_code: "02822",
      state_id: "RI",
      state_name: "Rhode Island",
    },
    {
      city_id: "692",
      city: "Fiskeville",
      zip_code: "02823",
      state_id: "RI",
      state_name: "Rhode Island",
    },
    {
      city_id: "693",
      city: "Forestdale",
      zip_code: "02824",
      state_id: "RI",
      state_name: "Rhode Island",
    },
    {
      city_id: "694",
      city: "Foster",
      zip_code: "02825",
      state_id: "RI",
      state_name: "Rhode Island",
    },
    {
      city_id: "695",
      city: "Glendale",
      zip_code: "02826",
      state_id: "RI",
      state_name: "Rhode Island",
    },
    {
      city_id: "696",
      city: "Greene",
      zip_code: "02827",
      state_id: "RI",
      state_name: "Rhode Island",
    },
    {
      city_id: "697",
      city: "Greenville",
      zip_code: "02828",
      state_id: "RI",
      state_name: "Rhode Island",
    },
    {
      city_id: "698",
      city: "Harmony",
      zip_code: "02829",
      state_id: "RI",
      state_name: "Rhode Island",
    },
    {
      city_id: "699",
      city: "Harrisville",
      zip_code: "02830",
      state_id: "RI",
      state_name: "Rhode Island",
    },
    {
      city_id: "700",
      city: "Hope",
      zip_code: "02831",
      state_id: "RI",
      state_name: "Rhode Island",
    },
    {
      city_id: "701",
      city: "Hope Valley",
      zip_code: "02832",
      state_id: "RI",
      state_name: "Rhode Island",
    },
    {
      city_id: "702",
      city: "Hopkinton",
      zip_code: "02833",
      state_id: "RI",
      state_name: "Rhode Island",
    },
    {
      city_id: "703",
      city: "Jamestown",
      zip_code: "02835",
      state_id: "RI",
      state_name: "Rhode Island",
    },
    {
      city_id: "756",
      city: "Johnston",
      zip_code: "02919",
      state_id: "RI",
      state_name: "Rhode Island",
    },
    {
      city_id: "704",
      city: "Kenyon",
      zip_code: "02836",
      state_id: "RI",
      state_name: "Rhode Island",
    },
    {
      city_id: "728",
      city: "Kingston",
      zip_code: "02881",
      state_id: "RI",
      state_name: "Rhode Island",
    },
    {
      city_id: "719",
      city: "Lincoln",
      zip_code: "02865",
      state_id: "RI",
      state_name: "Rhode Island",
    },
    {
      city_id: "705",
      city: "Little Compton",
      zip_code: "02837",
      state_id: "RI",
      state_name: "Rhode Island",
    },
    {
      city_id: "706",
      city: "Manville",
      zip_code: "02838",
      state_id: "RI",
      state_name: "Rhode Island",
    },
    {
      city_id: "707",
      city: "Mapleville",
      zip_code: "02839",
      state_id: "RI",
      state_name: "Rhode Island",
    },
    {
      city_id: "710",
      city: "Middletown",
      zip_code: "02842",
      state_id: "RI",
      state_name: "Rhode Island",
    },
    {
      city_id: "729",
      city: "Narragansett",
      zip_code: "02882",
      state_id: "RI",
      state_name: "Rhode Island",
    },
    {
      city_id: "708",
      city: "Newport",
      zip_code: "02840",
      state_id: "RI",
      state_name: "Rhode Island",
    },
    {
      city_id: "711",
      city: "North Kingstown",
      zip_code: "02852",
      state_id: "RI",
      state_name: "Rhode Island",
    },
    {
      city_id: "749",
      city: "North Providence",
      zip_code: "02911",
      state_id: "RI",
      state_name: "Rhode Island",
    },
    {
      city_id: "712",
      city: "North Scituate",
      zip_code: "02857",
      state_id: "RI",
      state_name: "Rhode Island",
    },
    {
      city_id: "739",
      city: "North Smithfield",
      zip_code: "02896",
      state_id: "RI",
      state_name: "Rhode Island",
    },
    {
      city_id: "713",
      city: "Oakland",
      zip_code: "02858",
      state_id: "RI",
      state_name: "Rhode Island",
    },
    {
      city_id: "714",
      city: "Pascoag",
      zip_code: "02859",
      state_id: "RI",
      state_name: "Rhode Island",
    },
    {
      city_id: "715",
      city: "Pawtucket",
      zip_code: "02860",
      state_id: "RI",
      state_name: "Rhode Island",
    },
    {
      city_id: "720",
      city: "Portsmouth",
      zip_code: "02871",
      state_id: "RI",
      state_name: "Rhode Island",
    },
    {
      city_id: "741",
      city: "Providence",
      zip_code: "02903",
      state_id: "RI",
      state_name: "Rhode Island",
    },
    {
      city_id: "721",
      city: "Prudence Island",
      zip_code: "02872",
      state_id: "RI",
      state_name: "Rhode Island",
    },
    {
      city_id: "752",
      city: "Riverside",
      zip_code: "02915",
      state_id: "RI",
      state_name: "Rhode Island",
    },
    {
      city_id: "722",
      city: "Rockville",
      zip_code: "02873",
      state_id: "RI",
      state_name: "Rhode Island",
    },
    {
      city_id: "753",
      city: "Rumford",
      zip_code: "02916",
      state_id: "RI",
      state_name: "Rhode Island",
    },
    {
      city_id: "723",
      city: "Saunderstown",
      zip_code: "02874",
      state_id: "RI",
      state_name: "Rhode Island",
    },
    {
      city_id: "724",
      city: "Shannock",
      zip_code: "02875",
      state_id: "RI",
      state_name: "Rhode Island",
    },
    {
      city_id: "725",
      city: "Slatersville",
      zip_code: "02876",
      state_id: "RI",
      state_name: "Rhode Island",
    },
    {
      city_id: "754",
      city: "Smithfield",
      zip_code: "02917",
      state_id: "RI",
      state_name: "Rhode Island",
    },
    {
      city_id: "726",
      city: "Tiverton",
      zip_code: "02878",
      state_id: "RI",
      state_name: "Rhode Island",
    },
    {
      city_id: "727",
      city: "Wakefield",
      zip_code: "02879",
      state_id: "RI",
      state_name: "Rhode Island",
    },
    {
      city_id: "730",
      city: "Warren",
      zip_code: "02885",
      state_id: "RI",
      state_name: "Rhode Island",
    },
    {
      city_id: "731",
      city: "Warwick",
      zip_code: "02886",
      state_id: "RI",
      state_name: "Rhode Island",
    },
    {
      city_id: "689",
      city: "West Greenwich",
      zip_code: "02817",
      state_id: "RI",
      state_name: "Rhode Island",
    },
    {
      city_id: "735",
      city: "West Kingston",
      zip_code: "02892",
      state_id: "RI",
      state_name: "Rhode Island",
    },
    {
      city_id: "736",
      city: "West Warwick",
      zip_code: "02893",
      state_id: "RI",
      state_name: "Rhode Island",
    },
    {
      city_id: "734",
      city: "Westerly",
      zip_code: "02891",
      state_id: "RI",
      state_name: "Rhode Island",
    },
    {
      city_id: "737",
      city: "Wood River Junction",
      zip_code: "02894",
      state_id: "RI",
      state_name: "Rhode Island",
    },
    {
      city_id: "738",
      city: "Woonsocket",
      zip_code: "02895",
      state_id: "RI",
      state_name: "Rhode Island",
    },
    {
      city_id: "740",
      city: "Wyoming",
      zip_code: "02898",
      state_id: "RI",
      state_name: "Rhode Island",
    },
    {
      city_id: "27040",
      city: "Abbott",
      zip_code: "76621",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "28245",
      city: "Abilene",
      zip_code: "79601",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "28257",
      city: "Ackerly",
      zip_code: "79713",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "26359",
      city: "Addison",
      zip_code: "75001",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "28106",
      city: "Afton",
      zip_code: "79220",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "27728",
      city: "Agua Dulce",
      zip_code: "78330",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "27793",
      city: "Alamo",
      zip_code: "78516",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "26528",
      city: "Alba",
      zip_code: "75410",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "26951",
      city: "Albany",
      zip_code: "76430",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "26783",
      city: "Aledo",
      zip_code: "76008",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "27729",
      city: "Alice",
      zip_code: "78332",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "26360",
      city: "Allen",
      zip_code: "75002",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "27997",
      city: "Alleyton",
      zip_code: "78935",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "28303",
      city: "Alpine",
      zip_code: "79830",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "26738",
      city: "Alto",
      zip_code: "75925",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "26784",
      city: "Alvarado",
      zip_code: "76009",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "27410",
      city: "Alvin",
      zip_code: "77511",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "26882",
      city: "Alvord",
      zip_code: "76225",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "28104",
      city: "Amarillo",
      zip_code: "79124",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "28258",
      city: "Andrews",
      zip_code: "79714",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "27412",
      city: "Angleton",
      zip_code: "77515",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "26527",
      city: "Anna",
      zip_code: "75409",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "26600",
      city: "Annona",
      zip_code: "75550",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "28198",
      city: "Anson",
      zip_code: "79501",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "27730",
      city: "Aransas Pass",
      zip_code: "78336",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "26925",
      city: "Archer City",
      zip_code: "76351",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "26883",
      city: "Argyle",
      zip_code: "76226",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "26779",
      city: "Arlington",
      zip_code: "76001",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "26677",
      city: "Arp",
      zip_code: "75750",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "27569",
      city: "Artesia Wells",
      zip_code: "78001",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "26529",
      city: "Arthur City",
      zip_code: "75411",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "27961",
      city: "Asherton",
      zip_code: "78827",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "28199",
      city: "Aspermont",
      zip_code: "79502",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "26678",
      city: "Athens",
      zip_code: "75751",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "26884",
      city: "Aubrey",
      zip_code: "76227",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "27915",
      city: "Austin",
      zip_code: "78701",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "27538",
      city: "Austwell",
      zip_code: "77950",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "27042",
      city: "Avalon",
      zip_code: "76623",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "26602",
      city: "Avery",
      zip_code: "75554",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "28200",
      city: "Avoca",
      zip_code: "79503",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "27043",
      city: "Axtell",
      zip_code: "76624",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "26794",
      city: "Azle",
      zip_code: "76020",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "27414",
      city: "Bacliff",
      zip_code: "77518",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "26530",
      city: "Bagwell",
      zip_code: "75412",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "26531",
      city: "Bailey",
      zip_code: "75413",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "28201",
      city: "Baird",
      zip_code: "79504",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "26469",
      city: "Balch Springs",
      zip_code: "75180",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "27109",
      city: "Ballinger",
      zip_code: "76821",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "28259",
      city: "Balmorhea",
      zip_code: "79718",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "27110",
      city: "Bangs",
      zip_code: "76823",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "27732",
      city: "Banquete",
      zip_code: "78339",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "26421",
      city: "Bardwell",
      zip_code: "75101",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "27962",
      city: "Barksdale",
      zip_code: "78828",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "27161",
      city: "Barnhart",
      zip_code: "76930",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "26422",
      city: "Barry",
      zip_code: "75102",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "28260",
      city: "Barstow",
      zip_code: "79719",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "26996",
      city: "Bartlett",
      zip_code: "76511",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "27336",
      city: "Bay City",
      zip_code: "77414",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "27733",
      city: "Bayside",
      zip_code: "78340",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "27416",
      city: "Baytown",
      zip_code: "77520",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "27338",
      city: "Beasley",
      zip_code: "77417",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "27493",
      city: "Beaumont",
      zip_code: "77703",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "26795",
      city: "Bedford",
      zip_code: "76021",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "27626",
      city: "Beeville",
      zip_code: "78102",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "27332",
      city: "Bellaire",
      zip_code: "77401",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "26885",
      city: "Bellevue",
      zip_code: "76228",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "26532",
      city: "Bells",
      zip_code: "75414",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "27339",
      city: "Bellville",
      zip_code: "77418",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "26997",
      city: "Belton",
      zip_code: "76513",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "27735",
      city: "Ben Bolt",
      zip_code: "78342",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "26533",
      city: "Ben Franklin",
      zip_code: "75415",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "26680",
      city: "Ben Wheeler",
      zip_code: "75754",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "27734",
      city: "Benavides",
      zip_code: "78341",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "28202",
      city: "Benjamin",
      zip_code: "79505",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "27628",
      city: "Berclair",
      zip_code: "78107",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "27162",
      city: "Big Lake",
      zip_code: "76932",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "28261",
      city: "Big Spring",
      zip_code: "79720",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "27964",
      city: "Big Wells",
      zip_code: "78830",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "27573",
      city: "Bigfoot",
      zip_code: "78005",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "27736",
      city: "Bishop",
      zip_code: "78343",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "28203",
      city: "Blackwell",
      zip_code: "79506",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "26953",
      city: "Blanket",
      zip_code: "76432",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "27340",
      city: "Blessing",
      zip_code: "77419",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "27044",
      city: "Blooming Grove",
      zip_code: "76626",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "27539",
      city: "Bloomington",
      zip_code: "77951",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "26534",
      city: "Blossom",
      zip_code: "75416",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "26541",
      city: "Blue Ridge",
      zip_code: "75424",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "26954",
      city: "Bluff Dale",
      zip_code: "76433",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "27045",
      city: "Blum",
      zip_code: "76627",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "26535",
      city: "Bogata",
      zip_code: "75417",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "27341",
      city: "Boling",
      zip_code: "77420",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "26536",
      city: "Bonham",
      zip_code: "75418",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "26797",
      city: "Boyd",
      zip_code: "76023",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "27965",
      city: "Brackettville",
      zip_code: "78832",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "27112",
      city: "Brady",
      zip_code: "76825",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "27046",
      city: "Brandon",
      zip_code: "76628",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "26537",
      city: "Brashear",
      zip_code: "75420",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "27342",
      city: "Brazoria",
      zip_code: "77422",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "26947",
      city: "Breckenridge",
      zip_code: "76424",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "27047",
      city: "Bremond",
      zip_code: "76629",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "26948",
      city: "Bridgeport",
      zip_code: "76426",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "27855",
      city: "Briggs",
      zip_code: "78608",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "27163",
      city: "Bronte",
      zip_code: "76933",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "27113",
      city: "Brookesmith",
      zip_code: "76827",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "27343",
      city: "Brookshire",
      zip_code: "77423",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "26538",
      city: "Brookston",
      zip_code: "75421",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "26682",
      city: "Brownsboro",
      zip_code: "75756",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "27794",
      city: "Brownsville",
      zip_code: "78520",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "27106",
      city: "Brownwood",
      zip_code: "76801",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "27048",
      city: "Bruceville",
      zip_code: "76630",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "27737",
      city: "Bruni",
      zip_code: "78344",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "26949",
      city: "Bryson",
      zip_code: "76427",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "26998",
      city: "Buckholts",
      zip_code: "76518",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "26709",
      city: "Buffalo",
      zip_code: "75831",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "28204",
      city: "Buffalo Gap",
      zip_code: "79508",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "26683",
      city: "Bullard",
      zip_code: "75757",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "26926",
      city: "Burkburnett",
      zip_code: "76354",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "27114",
      city: "Burkett",
      zip_code: "76828",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "26798",
      city: "Burleson",
      zip_code: "76028",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "26999",
      city: "Burlington",
      zip_code: "76519",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "26927",
      city: "Byers",
      zip_code: "76357",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "27049",
      city: "Bynum",
      zip_code: "76631",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "26950",
      city: "Caddo",
      zip_code: "76429",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "26439",
      city: "Caddo Mills",
      zip_code: "75135",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "27000",
      city: "Cameron",
      zip_code: "76520",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "27966",
      city: "Camp Wood",
      zip_code: "78833",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "26539",
      city: "Campbell",
      zip_code: "75422",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "26423",
      city: "Canton",
      zip_code: "75103",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "26955",
      city: "Carbon",
      zip_code: "76435",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "27164",
      city: "Carlsbad",
      zip_code: "76934",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "26956",
      city: "Carlton",
      zip_code: "76436",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "27967",
      city: "Carrizo Springs",
      zip_code: "78834",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "26361",
      city: "Carrollton",
      zip_code: "75006",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "27995",
      city: "Cat Spring",
      zip_code: "78933",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "27968",
      city: "Catarina",
      zip_code: "78836",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "26424",
      city: "Cedar Hill",
      zip_code: "75104",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "26540",
      city: "Celeste",
      zip_code: "75423",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "26363",
      city: "Celina",
      zip_code: "75009",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "26710",
      city: "Centerville",
      zip_code: "75833",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "26684",
      city: "Chandler",
      zip_code: "75758",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "27419",
      city: "Channelview",
      zip_code: "77530",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "27738",
      city: "Chapman Ranch",
      zip_code: "78347",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "27579",
      city: "Charlotte",
      zip_code: "78011",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "26952",
      city: "Chico",
      zip_code: "76431",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "28105",
      city: "Childress",
      zip_code: "79201",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "28108",
      city: "Chillicothe",
      zip_code: "79225",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "27050",
      city: "Chilton",
      zip_code: "76632",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "27051",
      city: "China Spring",
      zip_code: "76633",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "26749",
      city: "Chireno",
      zip_code: "75937",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "27580",
      city: "Christine",
      zip_code: "78012",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "27165",
      city: "Christoval",
      zip_code: "76935",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "26957",
      city: "Cisco",
      zip_code: "76437",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "28109",
      city: "Clarendon",
      zip_code: "79226",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "26542",
      city: "Clarksville",
      zip_code: "75426",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "26799",
      city: "Cleburne",
      zip_code: "76031",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "27052",
      city: "Clifton",
      zip_code: "76634",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "27420",
      city: "Clute",
      zip_code: "77531",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "28205",
      city: "Clyde",
      zip_code: "79510",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "28206",
      city: "Coahoma",
      zip_code: "79511",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "27512",
      city: "College Station",
      zip_code: "77845",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "26801",
      city: "Colleyville",
      zip_code: "76034",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "26887",
      city: "Collinsville",
      zip_code: "76233",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "28207",
      city: "Colorado City",
      zip_code: "79512",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "27996",
      city: "Columbus",
      zip_code: "78934",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "26959",
      city: "Comanche",
      zip_code: "76442",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "27797",
      city: "Combes",
      zip_code: "78535",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "26543",
      city: "Commerce",
      zip_code: "75428",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "26545",
      city: "Como",
      zip_code: "75431",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "27969",
      city: "Comstock",
      zip_code: "78837",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "27739",
      city: "Concepcion",
      zip_code: "78349",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "27276",
      city: "Conroe",
      zip_code: "77301",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "27053",
      city: "Coolidge",
      zip_code: "76635",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "26546",
      city: "Cooper",
      zip_code: "75432",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "26366",
      city: "Coppell",
      zip_code: "75019",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "27001",
      city: "Copperas Cove",
      zip_code: "76522",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "27772",
      city: "Corpus Christi",
      zip_code: "78401",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "26426",
      city: "Corsicana",
      zip_code: "75109",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "27582",
      city: "Cotulla",
      zip_code: "78014",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "27862",
      city: "Coupland",
      zip_code: "78615",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "27054",
      city: "Covington",
      zip_code: "76636",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "28263",
      city: "Coyanosa",
      zip_code: "79730",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "26428",
      city: "Crandall",
      zip_code: "75114",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "28264",
      city: "Crane",
      zip_code: "79731",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "27056",
      city: "Crawford",
      zip_code: "76638",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "26802",
      city: "Cresson",
      zip_code: "76035",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "26711",
      city: "Crockett",
      zip_code: "75835",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "27421",
      city: "Crosby",
      zip_code: "77532",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "26960",
      city: "Cross Plains",
      zip_code: "76443",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "28110",
      city: "Crowell",
      zip_code: "79227",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "26803",
      city: "Crowley",
      zip_code: "76036",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "27971",
      city: "Crystal City",
      zip_code: "78839",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "26547",
      city: "Cumby",
      zip_code: "75433",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "26548",
      city: "Cunningham",
      zip_code: "75434",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "26686",
      city: "Cushing",
      zip_code: "75760",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "27346",
      city: "Cypress",
      zip_code: "77429",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "26473",
      city: "Dallas",
      zip_code: "75201",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "27347",
      city: "Damon",
      zip_code: "77430",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "27423",
      city: "Danbury",
      zip_code: "77534",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "27057",
      city: "Dawson",
      zip_code: "76639",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "27424",
      city: "Dayton",
      zip_code: "77535",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "26961",
      city: "De Leon",
      zip_code: "76444",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "26888",
      city: "Decatur",
      zip_code: "76234",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "27425",
      city: "Deer Park",
      zip_code: "77536",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "27972",
      city: "Del Rio",
      zip_code: "78840",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "26367",
      city: "Denison",
      zip_code: "75020",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "26875",
      city: "Denton",
      zip_code: "76201",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "26549",
      city: "Deport",
      zip_code: "75435",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "26962",
      city: "Desdemona",
      zip_code: "76445",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "26429",
      city: "Desoto",
      zip_code: "75115",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "26550",
      city: "Detroit",
      zip_code: "75436",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "27584",
      city: "Devine",
      zip_code: "78016",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "26752",
      city: "Diboll",
      zip_code: "75941",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "28111",
      city: "Dickens",
      zip_code: "79229",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "27427",
      city: "Dickinson",
      zip_code: "77539",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "26551",
      city: "Dike",
      zip_code: "75437",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "27585",
      city: "Dilley",
      zip_code: "78017",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "26552",
      city: "Dodd City",
      zip_code: "75438",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "28112",
      city: "Dodson",
      zip_code: "79230",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "27799",
      city: "Donna",
      zip_code: "78537",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "27118",
      city: "Doole",
      zip_code: "76836",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "26754",
      city: "Douglass",
      zip_code: "75943",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "27867",
      city: "Dripping Springs",
      zip_code: "78620",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "27740",
      city: "Driscoll",
      zip_code: "78351",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "27977",
      city: "Dryden",
      zip_code: "78851",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "26963",
      city: "Dublin",
      zip_code: "76446",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "26430",
      city: "Duncanville",
      zip_code: "75116",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "28250",
      city: "Dyess Afb",
      zip_code: "79607",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "27351",
      city: "Eagle Lake",
      zip_code: "77434",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "27978",
      city: "Eagle Pass",
      zip_code: "78852",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "27107",
      city: "Early",
      zip_code: "76802",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "27352",
      city: "East Bernard",
      zip_code: "77435",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "26964",
      city: "Eastland",
      zip_code: "76448",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "26553",
      city: "Ector",
      zip_code: "75439",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "27800",
      city: "Edcouch",
      zip_code: "78538",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "27003",
      city: "Eddy",
      zip_code: "76524",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "27119",
      city: "Eden",
      zip_code: "76837",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "26431",
      city: "Edgewood",
      zip_code: "75117",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "27801",
      city: "Edinburg",
      zip_code: "78539",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "27541",
      city: "Edna",
      zip_code: "77957",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "27741",
      city: "Edroy",
      zip_code: "78352",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "27353",
      city: "Egypt",
      zip_code: "77436",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "27354",
      city: "El Campo",
      zip_code: "77437",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "27979",
      city: "El Indio",
      zip_code: "78860",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "27166",
      city: "Eldorado",
      zip_code: "76936",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "26928",
      city: "Electra",
      zip_code: "76360",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "27868",
      city: "Elgin",
      zip_code: "78621",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "26713",
      city: "Elkhart",
      zip_code: "75839",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "27058",
      city: "Elm Mott",
      zip_code: "76640",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "27355",
      city: "Elmaton",
      zip_code: "77440",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "27805",
      city: "Elsa",
      zip_code: "78543",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "26554",
      city: "Emory",
      zip_code: "75440",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "27586",
      city: "Encinal",
      zip_code: "78019",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "27742",
      city: "Encino",
      zip_code: "78353",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "26555",
      city: "Enloe",
      zip_code: "75441",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "26432",
      city: "Ennis",
      zip_code: "75119",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "27167",
      city: "Eola",
      zip_code: "76937",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "28114",
      city: "Estelline",
      zip_code: "79233",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "26804",
      city: "Euless",
      zip_code: "76039",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "26433",
      city: "Eustace",
      zip_code: "75124",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "26714",
      city: "Fairfield",
      zip_code: "75840",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "27743",
      city: "Falfurrias",
      zip_code: "78355",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "26556",
      city: "Farmersville",
      zip_code: "75442",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "26437",
      city: "Fate",
      zip_code: "75132",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "26434",
      city: "Ferris",
      zip_code: "75125",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "26687",
      city: "Flint",
      zip_code: "75762",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "28115",
      city: "Flomot",
      zip_code: "79234",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "27005",
      city: "Florence",
      zip_code: "76527",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "26369",
      city: "Flower Mound",
      zip_code: "75022",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "26435",
      city: "Forney",
      zip_code: "75126",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "26806",
      city: "Forreston",
      zip_code: "76041",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "28265",
      city: "Forsan",
      zip_code: "79733",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "28266",
      city: "Fort Davis",
      zip_code: "79734",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "27016",
      city: "Fort Hood",
      zip_code: "76544",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "28267",
      city: "Fort Stockton",
      zip_code: "79735",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "26839",
      city: "Fort Worth",
      zip_code: "76102",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "26688",
      city: "Frankston",
      zip_code: "75763",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "27871",
      city: "Fredericksburg",
      zip_code: "78624",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "27428",
      city: "Freeport",
      zip_code: "77541",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "27744",
      city: "Freer",
      zip_code: "78357",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "27429",
      city: "Fresno",
      zip_code: "77545",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "27430",
      city: "Friendswood",
      zip_code: "77546",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "26375",
      city: "Frisco",
      zip_code: "75033",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "27059",
      city: "Frost",
      zip_code: "76641",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "26436",
      city: "Fruitvale",
      zip_code: "75127",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "27356",
      city: "Fulshear",
      zip_code: "77441",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "27745",
      city: "Fulton",
      zip_code: "78358",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "28268",
      city: "Gail",
      zip_code: "79738",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "26891",
      city: "Gainesville",
      zip_code: "76240",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "27431",
      city: "Galena Park",
      zip_code: "77547",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "26689",
      city: "Gallatin",
      zip_code: "75764",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "27432",
      city: "Galveston",
      zip_code: "77550",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "27544",
      city: "Ganado",
      zip_code: "77962",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "27807",
      city: "Garciasville",
      zip_code: "78547",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "28269",
      city: "Garden City",
      zip_code: "79739",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "28281",
      city: "Gardendale",
      zip_code: "79758",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "26381",
      city: "Garland",
      zip_code: "75040",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "27357",
      city: "Garwood",
      zip_code: "77442",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "27006",
      city: "Gatesville",
      zip_code: "76528",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "27588",
      city: "George West",
      zip_code: "78022",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "27872",
      city: "Georgetown",
      zip_code: "78626",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "27635",
      city: "Gillett",
      zip_code: "78116",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "28209",
      city: "Girard",
      zip_code: "79518",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "26807",
      city: "Glen Rose",
      zip_code: "76043",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "28002",
      city: "Glidden",
      zip_code: "78943",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "26808",
      city: "Godley",
      zip_code: "76044",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "28270",
      city: "Goldsmith",
      zip_code: "79741",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "27122",
      city: "Goldthwaite",
      zip_code: "76844",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "27545",
      city: "Goliad",
      zip_code: "77963",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "27874",
      city: "Gonzales",
      zip_code: "78629",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "27159",
      city: "Goodfellow Afb",
      zip_code: "76908",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "26968",
      city: "Gordon",
      zip_code: "76453",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "26929",
      city: "Goree",
      zip_code: "76363",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "26969",
      city: "Gorman",
      zip_code: "76454",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "26965",
      city: "Graford",
      zip_code: "76449",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "26966",
      city: "Graham",
      zip_code: "76450",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "26809",
      city: "Granbury",
      zip_code: "76048",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "26387",
      city: "Grand Prairie",
      zip_code: "75050",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "26441",
      city: "Grand Saline",
      zip_code: "75140",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "28271",
      city: "Grandfalls",
      zip_code: "79742",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "26811",
      city: "Grandview",
      zip_code: "76050",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "27007",
      city: "Granger",
      zip_code: "76530",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "26715",
      city: "Grapeland",
      zip_code: "75844",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "26812",
      city: "Grapevine",
      zip_code: "76051",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "26524",
      city: "Greenville",
      zip_code: "75401",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "27746",
      city: "Gregory",
      zip_code: "78359",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "27060",
      city: "Groesbeck",
      zip_code: "76642",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "28046",
      city: "Groom",
      zip_code: "79039",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "27808",
      city: "Grulla",
      zip_code: "78548",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "26393",
      city: "Gunter",
      zip_code: "75058",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "26970",
      city: "Gustine",
      zip_code: "76455",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "27359",
      city: "Guy",
      zip_code: "77444",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "26853",
      city: "Haltom City",
      zip_code: "76117",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "27008",
      city: "Hamilton",
      zip_code: "76531",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "28211",
      city: "Hamlin",
      zip_code: "79520",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "27809",
      city: "Hargill",
      zip_code: "78549",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "27017",
      city: "Harker Heights",
      zip_code: "76548",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "27810",
      city: "Harlingen",
      zip_code: "78550",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "26930",
      city: "Harrold",
      zip_code: "76364",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "28212",
      city: "Haskell",
      zip_code: "79521",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "26813",
      city: "Haslet",
      zip_code: "76052",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "28213",
      city: "Hawley",
      zip_code: "79525",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "27748",
      city: "Hebbronville",
      zip_code: "78361",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "28118",
      city: "Hedley",
      zip_code: "79237",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "27360",
      city: "Hempstead",
      zip_code: "77445",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "26642",
      city: "Henderson",
      zip_code: "75654",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "26931",
      city: "Henrietta",
      zip_code: "76365",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "28214",
      city: "Hermleigh",
      zip_code: "79526",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "27061",
      city: "Hewitt",
      zip_code: "76643",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "27124",
      city: "Hext",
      zip_code: "76848",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "26971",
      city: "Hico",
      zip_code: "76457",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "27812",
      city: "Hidalgo",
      zip_code: "78557",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "27438",
      city: "Highlands",
      zip_code: "77562",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "27062",
      city: "Hillsboro",
      zip_code: "76645",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "27439",
      city: "Hitchcock",
      zip_code: "77563",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "27362",
      city: "Hockley",
      zip_code: "77447",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "27009",
      city: "Holland",
      zip_code: "76534",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "26932",
      city: "Holliday",
      zip_code: "76366",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "26557",
      city: "Honey Grove",
      zip_code: "75446",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "27896",
      city: "Horseshoe Bay",
      zip_code: "78657",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "27179",
      city: "Houston",
      zip_code: "77002",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "26568",
      city: "Howe",
      zip_code: "75459",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "27063",
      city: "Hubbard",
      zip_code: "76648",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "27290",
      city: "Huffman",
      zip_code: "77336",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "27291",
      city: "Humble",
      zip_code: "77338",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "27363",
      city: "Hungerford",
      zip_code: "77448",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "26758",
      city: "Huntington",
      zip_code: "75949",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "26814",
      city: "Hurst",
      zip_code: "76053",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "26442",
      city: "Hutchins",
      zip_code: "75141",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "27878",
      city: "Hutto",
      zip_code: "78634",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "28272",
      city: "Imperial",
      zip_code: "79743",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "27547",
      city: "Inez",
      zip_code: "77968",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "27749",
      city: "Ingleside",
      zip_code: "78362",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "26933",
      city: "Iowa Park",
      zip_code: "76367",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "28215",
      city: "Ira",
      zip_code: "79527",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "28273",
      city: "Iraan",
      zip_code: "79744",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "27064",
      city: "Iredell",
      zip_code: "76649",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "27065",
      city: "Irene",
      zip_code: "76650",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "26379",
      city: "Irving",
      zip_code: "75038",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "27066",
      city: "Italy",
      zip_code: "76651",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "26816",
      city: "Itasca",
      zip_code: "76055",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "26558",
      city: "Ivanhoe",
      zip_code: "75447",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "26972",
      city: "Jacksboro",
      zip_code: "76458",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "26691",
      city: "Jacksonville",
      zip_code: "75766",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "27010",
      city: "Jarrell",
      zip_code: "76537",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "28216",
      city: "Jayton",
      zip_code: "79528",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "26973",
      city: "Jermyn",
      zip_code: "76459",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "26717",
      city: "Jewett",
      zip_code: "75846",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "27011",
      city: "Jonesboro",
      zip_code: "76538",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "26462",
      city: "Josephine",
      zip_code: "75164",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "26817",
      city: "Joshua",
      zip_code: "76058",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "27592",
      city: "Jourdanton",
      zip_code: "78026",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "27125",
      city: "Junction",
      zip_code: "76849",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "26895",
      city: "Justin",
      zip_code: "76247",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "27637",
      city: "Karnes City",
      zip_code: "78118",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "27364",
      city: "Katy",
      zip_code: "77449",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "26443",
      city: "Kaufman",
      zip_code: "75142",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "26818",
      city: "Keene",
      zip_code: "76059",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "26893",
      city: "Keller",
      zip_code: "76244",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "27441",
      city: "Kemah",
      zip_code: "77565",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "26444",
      city: "Kemp",
      zip_code: "75143",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "27012",
      city: "Kempner",
      zip_code: "76539",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "27366",
      city: "Kendleton",
      zip_code: "77451",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "27638",
      city: "Kenedy",
      zip_code: "78119",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "26819",
      city: "Kennedale",
      zip_code: "76060",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "26445",
      city: "Kerens",
      zip_code: "75144",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "28274",
      city: "Kermit",
      zip_code: "79745",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "27013",
      city: "Killeen",
      zip_code: "76541",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "27881",
      city: "Kingsbury",
      zip_code: "78638",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "27750",
      city: "Kingsville",
      zip_code: "78363",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "27292",
      city: "Kingwood",
      zip_code: "77339",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "26559",
      city: "Klondike",
      zip_code: "75448",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "27168",
      city: "Knickerbocker",
      zip_code: "76939",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "27981",
      city: "Knippa",
      zip_code: "78870",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "28275",
      city: "Knott",
      zip_code: "79748",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "28217",
      city: "Knox City",
      zip_code: "79529",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "27067",
      city: "Kopperl",
      zip_code: "76652",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "26897",
      city: "Krum",
      zip_code: "76249",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "27813",
      city: "La Blanca",
      zip_code: "78558",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "27814",
      city: "La Feria",
      zip_code: "78559",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "27815",
      city: "La Joya",
      zip_code: "78560",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "27443",
      city: "La Marque",
      zip_code: "77568",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "27444",
      city: "La Porte",
      zip_code: "77571",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "27983",
      city: "La Pryor",
      zip_code: "78872",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "27817",
      city: "La Villa",
      zip_code: "78562",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "26560",
      city: "Ladonia",
      zip_code: "75449",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "26398",
      city: "Lake Dallas",
      zip_code: "75065",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "27442",
      city: "Lake Jackson",
      zip_code: "77566",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "28148",
      city: "Lamesa",
      zip_code: "79331",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "26438",
      city: "Lancaster",
      zip_code: "75134",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "27367",
      city: "Lane City",
      zip_code: "77453",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "26649",
      city: "Laneville",
      zip_code: "75667",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "27597",
      city: "Laredo",
      zip_code: "78040",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "26692",
      city: "Larue",
      zip_code: "75770",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "26720",
      city: "Latexo",
      zip_code: "75849",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "27975",
      city: "Laughlin Afb",
      zip_code: "78843",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "26464",
      city: "Lavon",
      zip_code: "75166",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "28218",
      city: "Lawn",
      zip_code: "79530",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "27445",
      city: "League City",
      zip_code: "77573",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "27984",
      city: "Leakey",
      zip_code: "78873",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "27640",
      city: "Leesville",
      zip_code: "78122",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "27603",
      city: "Leming",
      zip_code: "78050",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "28276",
      city: "Lenorah",
      zip_code: "79749",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "26721",
      city: "Leona",
      zip_code: "75850",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "26563",
      city: "Leonard",
      zip_code: "75452",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "27069",
      city: "Leroy",
      zip_code: "76654",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "26392",
      city: "Lewisville",
      zip_code: "75057",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "26693",
      city: "Lindale",
      zip_code: "75771",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "26898",
      city: "Lindsay",
      zip_code: "76250",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "26975",
      city: "Lipan",
      zip_code: "76462",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "27368",
      city: "Lissie",
      zip_code: "77454",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "26400",
      city: "Little Elm",
      zip_code: "75068",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "27020",
      city: "Little River Academy",
      zip_code: "76554",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "27447",
      city: "Liverpool",
      zip_code: "77577",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "27887",
      city: "Lockhart",
      zip_code: "78644",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "27126",
      city: "Lohn",
      zip_code: "76852",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "27550",
      city: "Lolita",
      zip_code: "77971",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "27127",
      city: "Lometa",
      zip_code: "76853",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "27128",
      city: "London",
      zip_code: "76854",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "26564",
      city: "Lone Oak",
      zip_code: "75453",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "28219",
      city: "Loraine",
      zip_code: "79532",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "27070",
      city: "Lorena",
      zip_code: "76655",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "27820",
      city: "Los Ebanos",
      zip_code: "78565",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "27821",
      city: "Los Fresnos",
      zip_code: "78566",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "27822",
      city: "Los Indios",
      zip_code: "78567",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "27071",
      city: "Lott",
      zip_code: "76656",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "27369",
      city: "Louise",
      zip_code: "77455",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "26722",
      city: "Lovelady",
      zip_code: "75851",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "26974",
      city: "Loving",
      zip_code: "76460",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "28183",
      city: "Lubbock",
      zip_code: "79401",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "28220",
      city: "Lueders",
      zip_code: "79533",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "26735",
      city: "Lufkin",
      zip_code: "75901",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "27889",
      city: "Luling",
      zip_code: "78648",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "27823",
      city: "Lyford",
      zip_code: "78569",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "27604",
      city: "Lytle",
      zip_code: "78052",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "26447",
      city: "Mabank",
      zip_code: "75147",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "27300",
      city: "Magnolia",
      zip_code: "77354",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "26448",
      city: "Malakoff",
      zip_code: "75148",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "27073",
      city: "Malone",
      zip_code: "76660",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "27892",
      city: "Manor",
      zip_code: "78653",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "26821",
      city: "Mansfield",
      zip_code: "76063",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "27448",
      city: "Manvel",
      zip_code: "77578",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "28311",
      city: "Marathon",
      zip_code: "79842",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "28312",
      city: "Marfa",
      zip_code: "79843",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "27370",
      city: "Markham",
      zip_code: "77456",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "27074",
      city: "Marlin",
      zip_code: "76661",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "27522",
      city: "Marquez",
      zip_code: "77865",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "27075",
      city: "Mart",
      zip_code: "76664",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "28222",
      city: "Maryneal",
      zip_code: "79535",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "27129",
      city: "Mason",
      zip_code: "76856",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "28123",
      city: "Matador",
      zip_code: "79244",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "27371",
      city: "Matagorda",
      zip_code: "77457",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "27751",
      city: "Mathis",
      zip_code: "78368",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "27130",
      city: "May",
      zip_code: "76857",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "26822",
      city: "Maypearl",
      zip_code: "76064",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "28122",
      city: "Mcadoo",
      zip_code: "79243",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "27790",
      city: "Mcallen",
      zip_code: "78501",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "28277",
      city: "McCamey",
      zip_code: "79752",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "28221",
      city: "McCaulley",
      zip_code: "79534",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "27890",
      city: "McDade",
      zip_code: "78650",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "27072",
      city: "McGregor",
      zip_code: "76657",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "26401",
      city: "Mckinney",
      zip_code: "75069",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "26934",
      city: "Megargel",
      zip_code: "76370",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "26565",
      city: "Melissa",
      zip_code: "75454",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "27131",
      city: "Melvin",
      zip_code: "76858",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "28124",
      city: "Memphis",
      zip_code: "79245",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "27132",
      city: "Menard",
      zip_code: "76859",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "28278",
      city: "Mentone",
      zip_code: "79754",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "27824",
      city: "Mercedes",
      zip_code: "78570",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "27169",
      city: "Mereta",
      zip_code: "76940",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "27076",
      city: "Meridian",
      zip_code: "76665",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "28223",
      city: "Merkel",
      zip_code: "79536",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "27077",
      city: "Mertens",
      zip_code: "76666",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "27170",
      city: "Mertzon",
      zip_code: "76941",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "26449",
      city: "Mesquite",
      zip_code: "75149",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "27078",
      city: "Mexia",
      zip_code: "76667",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "27372",
      city: "Midfield",
      zip_code: "77458",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "28279",
      city: "Midkiff",
      zip_code: "79755",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "28252",
      city: "Midland",
      zip_code: "79701",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "26823",
      city: "Midlothian",
      zip_code: "76065",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "27021",
      city: "Milano",
      zip_code: "76556",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "27133",
      city: "Miles",
      zip_code: "76861",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "27079",
      city: "Milford",
      zip_code: "76670",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "27134",
      city: "Millersview",
      zip_code: "76862",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "26824",
      city: "Millsap",
      zip_code: "76066",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "26694",
      city: "Mineola",
      zip_code: "75773",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "26825",
      city: "Mineral Wells",
      zip_code: "76067",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "26976",
      city: "Mingus",
      zip_code: "76463",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "27752",
      city: "Mirando City",
      zip_code: "78369",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "27825",
      city: "Mission",
      zip_code: "78572",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "27373",
      city: "Missouri City",
      zip_code: "77459",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "28280",
      city: "Monahans",
      zip_code: "79756",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "27449",
      city: "Mont Belvieu",
      zip_code: "77580",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "26899",
      city: "Montague",
      zip_code: "76251",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "26724",
      city: "Montalba",
      zip_code: "75853",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "27281",
      city: "Montgomery",
      zip_code: "77316",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "27022",
      city: "Moody",
      zip_code: "76557",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "27607",
      city: "Moore",
      zip_code: "78057",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "26977",
      city: "Moran",
      zip_code: "76464",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "27080",
      city: "Morgan",
      zip_code: "76671",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "27081",
      city: "Mount Calm",
      zip_code: "76673",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "26900",
      city: "Muenster",
      zip_code: "76252",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "27135",
      city: "Mullin",
      zip_code: "76864",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "26935",
      city: "Munday",
      zip_code: "76371",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "26695",
      city: "Murchison",
      zip_code: "75778",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "26901",
      city: "Myra",
      zip_code: "76253",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "26764",
      city: "Nacogdoches",
      zip_code: "75961",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "27374",
      city: "Nada",
      zip_code: "77460",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "27609",
      city: "Natalia",
      zip_code: "78059",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "26859",
      city: "Naval Air Station Jrb",
      zip_code: "76127",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "26696",
      city: "Neches",
      zip_code: "75779",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "27375",
      city: "Needville",
      zip_code: "77461",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "26826",
      city: "Nemo",
      zip_code: "76070",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "26468",
      city: "Nevada",
      zip_code: "75173",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "26697",
      city: "New Summerfield",
      zip_code: "75780",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "26827",
      city: "Newark",
      zip_code: "76071",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "26936",
      city: "Newcastle",
      zip_code: "76372",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "27647",
      city: "Nixon",
      zip_code: "78140",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "26902",
      city: "Nocona",
      zip_code: "76255",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "27023",
      city: "Nolanville",
      zip_code: "76559",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "27648",
      city: "Nordheim",
      zip_code: "78141",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "27526",
      city: "Normangee",
      zip_code: "77871",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "27649",
      city: "Normanna",
      zip_code: "78142",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "26873",
      city: "North Richland Hills",
      zip_code: "76180",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "27136",
      city: "Norton",
      zip_code: "76865",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "28282",
      city: "Notrees",
      zip_code: "79759",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "28226",
      city: "O'Brien",
      zip_code: "79539",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "26725",
      city: "Oakwood",
      zip_code: "75855",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "28125",
      city: "Odell",
      zip_code: "79247",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "27753",
      city: "Odem",
      zip_code: "78370",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "28283",
      city: "Odessa",
      zip_code: "79761",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "28158",
      city: "Odonnell",
      zip_code: "79351",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "27024",
      city: "Oglesby",
      zip_code: "76561",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "27754",
      city: "Oilton",
      zip_code: "78371",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "26937",
      city: "Oklaunion",
      zip_code: "76373",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "28227",
      city: "Old Glory",
      zip_code: "79540",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "27828",
      city: "Olmito",
      zip_code: "78575",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "26938",
      city: "Olney",
      zip_code: "76374",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "27755",
      city: "Orange Grove",
      zip_code: "78372",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "27376",
      city: "Orchard",
      zip_code: "77464",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "28290",
      city: "Orla",
      zip_code: "79770",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "28228",
      city: "Ovalo",
      zip_code: "79541",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "26659",
      city: "Overton",
      zip_code: "75684",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "27171",
      city: "Ozona",
      zip_code: "76943",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "28126",
      city: "Paducah",
      zip_code: "79248",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "27137",
      city: "Paint Rock",
      zip_code: "76866",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "27377",
      city: "Palacios",
      zip_code: "77465",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "26707",
      city: "Palestine",
      zip_code: "75801",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "26451",
      city: "Palmer",
      zip_code: "75152",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "26988",
      city: "Palo Pinto",
      zip_code: "76484",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "28068",
      city: "Panhandle",
      zip_code: "79068",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "26828",
      city: "Paradise",
      zip_code: "76073",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "26569",
      city: "Paris",
      zip_code: "75460",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "27403",
      city: "Pasadena",
      zip_code: "77502",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "27378",
      city: "Pattison",
      zip_code: "77466",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "26571",
      city: "Pattonville",
      zip_code: "75468",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "27652",
      city: "Pawnee",
      zip_code: "78145",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "27450",
      city: "Pearland",
      zip_code: "77581",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "27610",
      city: "Pearsall",
      zip_code: "78061",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "26572",
      city: "Pecan Gap",
      zip_code: "75469",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "28291",
      city: "Pecos",
      zip_code: "79772",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "27082",
      city: "Penelope",
      zip_code: "76676",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "27829",
      city: "Penitas",
      zip_code: "78576",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "28292",
      city: "Penwell",
      zip_code: "79776",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "26989",
      city: "Perrin",
      zip_code: "76486",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "28127",
      city: "Petersburg",
      zip_code: "79250",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "26939",
      city: "Petrolia",
      zip_code: "76377",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "27653",
      city: "Pettus",
      zip_code: "78146",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "26573",
      city: "Petty",
      zip_code: "75470",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "27899",
      city: "Pflugerville",
      zip_code: "78660",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "27830",
      city: "Pharr",
      zip_code: "78577",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "26903",
      city: "Pilot Point",
      zip_code: "76258",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "27307",
      city: "Pinehurst",
      zip_code: "77362",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "27555",
      city: "Placedo",
      zip_code: "77977",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "26370",
      city: "Plano",
      zip_code: "75023",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "27613",
      city: "Pleasanton",
      zip_code: "78064",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "27380",
      city: "Pledger",
      zip_code: "77468",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "26575",
      city: "Point",
      zip_code: "75472",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "27556",
      city: "Point Comfort",
      zip_code: "77978",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "26770",
      city: "Pollok",
      zip_code: "75969",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "26904",
      city: "Ponder",
      zip_code: "76259",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "26990",
      city: "Poolville",
      zip_code: "76487",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "27756",
      city: "Port Aransas",
      zip_code: "78373",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "27831",
      city: "Port Isabel",
      zip_code: "78578",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "27557",
      city: "Port Lavaca",
      zip_code: "77979",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "27849",
      city: "Port Mansfield",
      zip_code: "78598",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "27310",
      city: "Porter",
      zip_code: "77365",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "27757",
      city: "Portland",
      zip_code: "78374",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "27614",
      city: "Poteet",
      zip_code: "78065",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "26407",
      city: "Pottsboro",
      zip_code: "75076",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "27025",
      city: "Pottsville",
      zip_code: "76565",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "26576",
      city: "Powderly",
      zip_code: "75473",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "26452",
      city: "Powell",
      zip_code: "75153",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "26698",
      city: "Poynor",
      zip_code: "75782",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "27083",
      city: "Prairie Hill",
      zip_code: "76678",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "27361",
      city: "Prairie View",
      zip_code: "77446",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "27758",
      city: "Premont",
      zip_code: "78375",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "28313",
      city: "Presidio",
      zip_code: "79845",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "27139",
      city: "Priddy",
      zip_code: "76870",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "26526",
      city: "Princeton",
      zip_code: "75407",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "27832",
      city: "Progreso",
      zip_code: "78579",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "26409",
      city: "Prosper",
      zip_code: "75078",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "27084",
      city: "Purdon",
      zip_code: "76679",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "27026",
      city: "Purmela",
      zip_code: "76566",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "26979",
      city: "Putnam",
      zip_code: "76469",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "28293",
      city: "Pyote",
      zip_code: "79777",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "28129",
      city: "Quanah",
      zip_code: "79252",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "27985",
      city: "Quemado",
      zip_code: "78877",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "26577",
      city: "Quinlan",
      zip_code: "75474",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "28130",
      city: "Quitaque",
      zip_code: "79255",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "26699",
      city: "Quitman",
      zip_code: "75783",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "26829",
      city: "Rainbow",
      zip_code: "76077",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "26578",
      city: "Randolph",
      zip_code: "75475",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "26980",
      city: "Ranger",
      zip_code: "76470",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "28294",
      city: "Rankin",
      zip_code: "79778",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "26579",
      city: "Ravenna",
      zip_code: "75476",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "27833",
      city: "Raymondville",
      zip_code: "78580",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "27085",
      city: "Reagan",
      zip_code: "76680",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "27759",
      city: "Realitos",
      zip_code: "78376",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "26453",
      city: "Red Oak",
      zip_code: "75154",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "28314",
      city: "Redford",
      zip_code: "79846",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "27760",
      city: "Refugio",
      zip_code: "78377",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "26830",
      city: "Rhome",
      zip_code: "76078",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "26454",
      city: "Rice",
      zip_code: "75155",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "26410",
      city: "Richardson",
      zip_code: "75080",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "27086",
      city: "Richland",
      zip_code: "76681",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "27140",
      city: "Richland Springs",
      zip_code: "76871",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "27333",
      city: "Richmond",
      zip_code: "77406",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "27087",
      city: "Riesel",
      zip_code: "76682",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "26905",
      city: "Ringgold",
      zip_code: "76261",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "27834",
      city: "Rio Grande City",
      zip_code: "78582",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "27835",
      city: "Rio Hondo",
      zip_code: "78583",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "26838",
      city: "Rio Vista",
      zip_code: "76093",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "26981",
      city: "Rising Star",
      zip_code: "76471",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "27761",
      city: "Riviera",
      zip_code: "78379",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "26906",
      city: "Roanoke",
      zip_code: "76262",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "28131",
      city: "Roaring Springs",
      zip_code: "79256",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "27172",
      city: "Robert Lee",
      zip_code: "76945",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "27762",
      city: "Robstown",
      zip_code: "78380",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "28229",
      city: "Roby",
      zip_code: "79543",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "27141",
      city: "Rochelle",
      zip_code: "76872",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "28230",
      city: "Rochester",
      zip_code: "79544",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "27027",
      city: "Rockdale",
      zip_code: "76567",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "27763",
      city: "Rockport",
      zip_code: "78382",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "27987",
      city: "Rocksprings",
      zip_code: "78880",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "26374",
      city: "Rockwall",
      zip_code: "75032",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "27028",
      city: "Rogers",
      zip_code: "76569",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "27836",
      city: "Roma",
      zip_code: "78584",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "28231",
      city: "Roscoe",
      zip_code: "79545",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "27029",
      city: "Rosebud",
      zip_code: "76570",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "27383",
      city: "Rosenberg",
      zip_code: "77471",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "27452",
      city: "Rosharon",
      zip_code: "77583",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "26456",
      city: "Rosser",
      zip_code: "75157",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "28232",
      city: "Rotan",
      zip_code: "79546",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "27903",
      city: "Round Rock",
      zip_code: "78664",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "27144",
      city: "Rowena",
      zip_code: "76875",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "26414",
      city: "Rowlett",
      zip_code: "75088",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "26580",
      city: "Roxton",
      zip_code: "75477",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "26472",
      city: "Royse City",
      zip_code: "75189",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "28233",
      city: "Rule",
      zip_code: "79547",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "27657",
      city: "Runge",
      zip_code: "78151",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "26701",
      city: "Rusk",
      zip_code: "75785",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "27988",
      city: "Sabinal",
      zip_code: "78881",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "26386",
      city: "Sachse",
      zip_code: "75048",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "26908",
      city: "Sadler",
      zip_code: "76264",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "26909",
      city: "Saint Jo",
      zip_code: "76265",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "27030",
      city: "Salado",
      zip_code: "76571",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "27154",
      city: "San Angelo",
      zip_code: "76901",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "27668",
      city: "San Antonio",
      zip_code: "78202",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "27838",
      city: "San Benito",
      zip_code: "78586",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "27765",
      city: "San Diego",
      zip_code: "78384",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "27384",
      city: "San Felipe",
      zip_code: "77473",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "27840",
      city: "San Juan",
      zip_code: "78589",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "27841",
      city: "San Perlita",
      zip_code: "78590",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "27145",
      city: "San Saba",
      zip_code: "76877",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "27616",
      city: "San Ygnacio",
      zip_code: "78067",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "28316",
      city: "Sanderson",
      zip_code: "79848",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "27764",
      city: "Sandia",
      zip_code: "78383",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "27146",
      city: "Santa Anna",
      zip_code: "76878",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "27409",
      city: "Santa Fe",
      zip_code: "77510",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "27843",
      city: "Santa Maria",
      zip_code: "78592",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "27844",
      city: "Santa Rosa",
      zip_code: "78593",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "26982",
      city: "Santo",
      zip_code: "76472",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "28295",
      city: "Saragosa",
      zip_code: "79780",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "27766",
      city: "Sarita",
      zip_code: "78385",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "27088",
      city: "Satin",
      zip_code: "76685",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "26582",
      city: "Savoy",
      zip_code: "75479",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "27031",
      city: "Schwertner",
      zip_code: "76573",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "26940",
      city: "Scotland",
      zip_code: "76379",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "26457",
      city: "Scurry",
      zip_code: "75158",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "27455",
      city: "Seabrook",
      zip_code: "77586",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "27559",
      city: "Seadrift",
      zip_code: "77983",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "26458",
      city: "Seagoville",
      zip_code: "75159",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "27385",
      city: "Sealy",
      zip_code: "77474",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "27845",
      city: "Sebastian",
      zip_code: "78594",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "26941",
      city: "Seymour",
      zip_code: "76380",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "28073",
      city: "Shamrock",
      zip_code: "79079",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "28296",
      city: "Sheffield",
      zip_code: "79781",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "26924",
      city: "Sheppard Afb",
      zip_code: "76311",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "26416",
      city: "Sherman",
      zip_code: "75090",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "27530",
      city: "Shiro",
      zip_code: "77876",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "28132",
      city: "Silverton",
      zip_code: "79257",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "27387",
      city: "Simonton",
      zip_code: "77476",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "27767",
      city: "Sinton",
      zip_code: "78387",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "27768",
      city: "Skidmore",
      zip_code: "78389",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "26911",
      city: "Slidell",
      zip_code: "76267",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "27661",
      city: "Smiley",
      zip_code: "78159",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "28235",
      city: "Snyder",
      zip_code: "79549",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "27173",
      city: "Sonora",
      zip_code: "76950",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "26986",
      city: "South Bend",
      zip_code: "76481",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "27456",
      city: "South Houston",
      zip_code: "77587",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "27848",
      city: "South Padre Island",
      zip_code: "78597",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "26837",
      city: "Southlake",
      zip_code: "76092",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "26912",
      city: "Southmayd",
      zip_code: "76268",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "27316",
      city: "Spring",
      zip_code: "77373",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "27618",
      city: "Spring Branch",
      zip_code: "78070",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "26831",
      city: "Springtown",
      zip_code: "76082",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "28171",
      city: "Spur",
      zip_code: "79370",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "27388",
      city: "Stafford",
      zip_code: "77477",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "28236",
      city: "Stamford",
      zip_code: "79553",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "28297",
      city: "Stanton",
      zip_code: "79782",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "26945",
      city: "Stephenville",
      zip_code: "76401",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "27174",
      city: "Sterling City",
      zip_code: "76951",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "26984",
      city: "Strawn",
      zip_code: "76475",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "26728",
      city: "Streetman",
      zip_code: "75859",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "27389",
      city: "Sugar Land",
      zip_code: "77478",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "27846",
      city: "Sullivan City",
      zip_code: "78595",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "26584",
      city: "Sulphur Bluff",
      zip_code: "75481",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "26585",
      city: "Sulphur Springs",
      zip_code: "75482",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "26586",
      city: "Sumner",
      zip_code: "75486",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "26471",
      city: "Sunnyvale",
      zip_code: "75182",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "26913",
      city: "Sunset",
      zip_code: "76270",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "27391",
      city: "Sweeny",
      zip_code: "77480",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "28237",
      city: "Sweetwater",
      zip_code: "79556",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "28238",
      city: "Sylvester",
      zip_code: "79560",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "27769",
      city: "Taft",
      zip_code: "78390",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "26587",
      city: "Talco",
      zip_code: "75487",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "27148",
      city: "Talpa",
      zip_code: "76882",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "28298",
      city: "Tarzan",
      zip_code: "79783",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "27032",
      city: "Taylor",
      zip_code: "76574",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "26729",
      city: "Teague",
      zip_code: "75860",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "27089",
      city: "Tehuacana",
      zip_code: "76686",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "26588",
      city: "Telephone",
      zip_code: "75488",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "26993",
      city: "Temple",
      zip_code: "76501",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "26730",
      city: "Tennessee Colony",
      zip_code: "75861",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "26459",
      city: "Terrell",
      zip_code: "75160",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "27457",
      city: "Texas City",
      zip_code: "77590",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "26391",
      city: "The Colony",
      zip_code: "75056",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "27392",
      city: "Thompsons",
      zip_code: "77481",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "27033",
      city: "Thorndale",
      zip_code: "76577",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "27090",
      city: "Thornton",
      zip_code: "76687",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "27034",
      city: "Thrall",
      zip_code: "76578",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "27619",
      city: "Three Rivers",
      zip_code: "78071",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "26987",
      city: "Throckmorton",
      zip_code: "76483",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "27620",
      city: "Tilden",
      zip_code: "78072",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "26914",
      city: "Tioga",
      zip_code: "76271",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "27564",
      city: "Tivoli",
      zip_code: "77990",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "26985",
      city: "Tolar",
      zip_code: "76476",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "26589",
      city: "Tom Bean",
      zip_code: "75489",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "27318",
      city: "Tomball",
      zip_code: "77375",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "28299",
      city: "Toyah",
      zip_code: "79785",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "28239",
      city: "Trent",
      zip_code: "79561",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "26590",
      city: "Trenton",
      zip_code: "75490",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "26461",
      city: "Trinidad",
      zip_code: "75163",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "26703",
      city: "Troup",
      zip_code: "75789",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "27035",
      city: "Troy",
      zip_code: "76579",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "27664",
      city: "Tuleta",
      zip_code: "78162",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "28135",
      city: "Turkey",
      zip_code: "79261",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "28240",
      city: "Tuscola",
      zip_code: "79562",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "28241",
      city: "Tye",
      zip_code: "79563",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "26667",
      city: "Tyler",
      zip_code: "75701",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "27770",
      city: "Tynan",
      zip_code: "78391",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "27959",
      city: "Uvalde",
      zip_code: "78801",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "28321",
      city: "Valentine",
      zip_code: "79854",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "27149",
      city: "Valera",
      zip_code: "76884",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "27091",
      city: "Valley Mills",
      zip_code: "76689",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "26915",
      city: "Valley View",
      zip_code: "76272",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "26704",
      city: "Van",
      zip_code: "75790",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "26595",
      city: "Van Alstyne",
      zip_code: "75495",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "26832",
      city: "Venus",
      zip_code: "76084",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "26942",
      city: "Vernon",
      zip_code: "76384",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "27535",
      city: "Victoria",
      zip_code: "77901",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "27151",
      city: "Voca",
      zip_code: "76887",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "27096",
      city: "Waco",
      zip_code: "76701",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "27394",
      city: "Wadsworth",
      zip_code: "77483",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "27177",
      city: "Wall",
      zip_code: "76957",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "27395",
      city: "Waller",
      zip_code: "77484",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "27396",
      city: "Wallis",
      zip_code: "77485",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "27092",
      city: "Walnut Springs",
      zip_code: "76690",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "27178",
      city: "Water Valley",
      zip_code: "76958",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "26463",
      city: "Waxahachie",
      zip_code: "75165",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "26833",
      city: "Weatherford",
      zip_code: "76085",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "27460",
      city: "Webster",
      zip_code: "77598",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "27566",
      city: "Weesatche",
      zip_code: "77993",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "26943",
      city: "Weinert",
      zip_code: "76388",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "27910",
      city: "Weir",
      zip_code: "78674",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "28087",
      city: "Wellington",
      zip_code: "79095",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "26775",
      city: "Wells",
      zip_code: "75976",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "27847",
      city: "Weslaco",
      zip_code: "78596",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "27093",
      city: "West",
      zip_code: "76691",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "27397",
      city: "West Columbia",
      zip_code: "77486",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "28242",
      city: "Westbrook",
      zip_code: "79565",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "27567",
      city: "Westhoff",
      zip_code: "77994",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "27398",
      city: "Wharton",
      zip_code: "77488",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "28089",
      city: "White Deer",
      zip_code: "79097",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "26705",
      city: "Whitehouse",
      zip_code: "75791",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "26916",
      city: "Whitesboro",
      zip_code: "76273",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "26591",
      city: "Whitewright",
      zip_code: "75491",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "27094",
      city: "Whitney",
      zip_code: "76692",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "26991",
      city: "Whitt",
      zip_code: "76490",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "26917",
      city: "Wichita Falls",
      zip_code: "76301",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "28300",
      city: "Wickett",
      zip_code: "79788",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "27282",
      city: "Willis",
      zip_code: "77318",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "26466",
      city: "Wills Point",
      zip_code: "75169",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "26467",
      city: "Wilmer",
      zip_code: "75172",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "26592",
      city: "Windom",
      zip_code: "75492",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "28243",
      city: "Wingate",
      zip_code: "79566",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "28301",
      city: "Wink",
      zip_code: "79789",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "28244",
      city: "Winters",
      zip_code: "79567",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "26596",
      city: "Wolfe City",
      zip_code: "75496",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "27771",
      city: "Woodsboro",
      zip_code: "78393",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "26992",
      city: "Woodson",
      zip_code: "76491",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "27104",
      city: "Woodway",
      zip_code: "76712",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "27095",
      city: "Wortham",
      zip_code: "76693",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "26420",
      city: "Wylie",
      zip_code: "75098",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "26597",
      city: "Yantis",
      zip_code: "75497",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "27568",
      city: "Yoakum",
      zip_code: "77995",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "27666",
      city: "Yorktown",
      zip_code: "78164",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "27624",
      city: "Zapata",
      zip_code: "78076",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "26778",
      city: "Zavalla",
      zip_code: "75980",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "27153",
      city: "Zephyr",
      zip_code: "76890",
      state_id: "TX",
      state_name: "Texas",
    },
    {
      city_id: "7094",
      city: "Afton",
      zip_code: "22920",
      state_id: "VA",
      state_name: "Virginia",
    },
    {
      city_id: "7366",
      city: "Alberta",
      zip_code: "23821",
      state_id: "VA",
      state_name: "Virginia",
    },
    {
      city_id: "6335",
      city: "Aldie",
      zip_code: "20105",
      state_id: "VA",
      state_name: "Virginia",
    },
    {
      city_id: "6933",
      city: "Alexandria",
      zip_code: "22301",
      state_id: "VA",
      state_name: "Virginia",
    },
    {
      city_id: "7669",
      city: "Altavista",
      zip_code: "24517",
      state_id: "VA",
      state_name: "Virginia",
    },
    {
      city_id: "7670",
      city: "Alton",
      zip_code: "24520",
      state_id: "VA",
      state_name: "Virginia",
    },
    {
      city_id: "7128",
      city: "Amelia Court House",
      zip_code: "23002",
      state_id: "VA",
      state_name: "Virginia",
    },
    {
      city_id: "7671",
      city: "Amherst",
      zip_code: "24521",
      state_id: "VA",
      state_name: "Virginia",
    },
    {
      city_id: "6336",
      city: "Amissville",
      zip_code: "20106",
      state_id: "VA",
      state_name: "Virginia",
    },
    {
      city_id: "6887",
      city: "Annandale",
      zip_code: "22003",
      state_id: "VA",
      state_name: "Virginia",
    },
    {
      city_id: "7672",
      city: "Appomattox",
      zip_code: "24522",
      state_id: "VA",
      state_name: "Virginia",
    },
    {
      city_id: "6922",
      city: "Arlington",
      zip_code: "22201",
      state_id: "VA",
      state_name: "Virginia",
    },
    {
      city_id: "7026",
      city: "Aroda",
      zip_code: "22709",
      state_id: "VA",
      state_name: "Virginia",
    },
    {
      city_id: "7095",
      city: "Arrington",
      zip_code: "22922",
      state_id: "VA",
      state_name: "Virginia",
    },
    {
      city_id: "7129",
      city: "Arvonia",
      zip_code: "23004",
      state_id: "VA",
      state_name: "Virginia",
    },
    {
      city_id: "6356",
      city: "Ashburn",
      zip_code: "20147",
      state_id: "VA",
      state_name: "Virginia",
    },
    {
      city_id: "7466",
      city: "Axton",
      zip_code: "24054",
      state_id: "VA",
      state_name: "Virginia",
    },
    {
      city_id: "7096",
      city: "Barboursville",
      zip_code: "22923",
      state_id: "VA",
      state_name: "Virginia",
    },
    {
      city_id: "7420",
      city: "Baskerville",
      zip_code: "23915",
      state_id: "VA",
      state_name: "Virginia",
    },
    {
      city_id: "7063",
      city: "Basye",
      zip_code: "22810",
      state_id: "VA",
      state_name: "Virginia",
    },
    {
      city_id: "7028",
      city: "Bealeton",
      zip_code: "22712",
      state_id: "VA",
      state_name: "Virginia",
    },
    {
      city_id: "7133",
      city: "Beaverdam",
      zip_code: "23015",
      state_id: "VA",
      state_name: "Virginia",
    },
    {
      city_id: "7673",
      city: "Bedford",
      zip_code: "24523",
      state_id: "VA",
      state_name: "Virginia",
    },
    {
      city_id: "6999",
      city: "Bentonville",
      zip_code: "22610",
      state_id: "VA",
      state_name: "Virginia",
    },
    {
      city_id: "7064",
      city: "Bergton",
      zip_code: "22811",
      state_id: "VA",
      state_name: "Virginia",
    },
    {
      city_id: "7000",
      city: "Berryville",
      zip_code: "22611",
      state_id: "VA",
      state_name: "Virginia",
    },
    {
      city_id: "7674",
      city: "Big Island",
      zip_code: "24526",
      state_id: "VA",
      state_name: "Virginia",
    },
    {
      city_id: "7367",
      city: "Blackstone",
      zip_code: "23824",
      state_id: "VA",
      state_name: "Virginia",
    },
    {
      city_id: "7675",
      city: "Blairs",
      zip_code: "24527",
      state_id: "VA",
      state_name: "Virginia",
    },
    {
      city_id: "7586",
      city: "Bland",
      zip_code: "24315",
      state_id: "VA",
      state_name: "Virginia",
    },
    {
      city_id: "6350",
      city: "Bluemont",
      zip_code: "20135",
      state_id: "VA",
      state_name: "Virginia",
    },
    {
      city_id: "7029",
      city: "Boston",
      zip_code: "22713",
      state_id: "VA",
      state_name: "Virginia",
    },
    {
      city_id: "6952",
      city: "Bowling Green",
      zip_code: "22427",
      state_id: "VA",
      state_name: "Virginia",
    },
    {
      city_id: "7001",
      city: "Boyce",
      zip_code: "22620",
      state_id: "VA",
      state_name: "Virginia",
    },
    {
      city_id: "7421",
      city: "Boydton",
      zip_code: "23917",
      state_id: "VA",
      state_name: "Virginia",
    },
    {
      city_id: "7368",
      city: "Boykins",
      zip_code: "23827",
      state_id: "VA",
      state_name: "Virginia",
    },
    {
      city_id: "7422",
      city: "Bracey",
      zip_code: "23919",
      state_id: "VA",
      state_name: "Virginia",
    },
    {
      city_id: "7369",
      city: "Branchville",
      zip_code: "23828",
      state_id: "VA",
      state_name: "Virginia",
    },
    {
      city_id: "7030",
      city: "Brandy Station",
      zip_code: "22714",
      state_id: "VA",
      state_name: "Virginia",
    },
    {
      city_id: "7726",
      city: "Breaks",
      zip_code: "24607",
      state_id: "VA",
      state_name: "Virginia",
    },
    {
      city_id: "7135",
      city: "Bremo Bluff",
      zip_code: "23022",
      state_id: "VA",
      state_name: "Virginia",
    },
    {
      city_id: "7065",
      city: "Bridgewater",
      zip_code: "22812",
      state_id: "VA",
      state_name: "Virginia",
    },
    {
      city_id: "7031",
      city: "Brightwood",
      zip_code: "22715",
      state_id: "VA",
      state_name: "Virginia",
    },
    {
      city_id: "6351",
      city: "Bristow",
      zip_code: "20136",
      state_id: "VA",
      state_name: "Virginia",
    },
    {
      city_id: "6352",
      city: "Broad Run",
      zip_code: "20137",
      state_id: "VA",
      state_name: "Virginia",
    },
    {
      city_id: "7066",
      city: "Broadway",
      zip_code: "22815",
      state_id: "VA",
      state_name: "Virginia",
    },
    {
      city_id: "7423",
      city: "Brodnax",
      zip_code: "23920",
      state_id: "VA",
      state_name: "Virginia",
    },
    {
      city_id: "7676",
      city: "Brookneal",
      zip_code: "24528",
      state_id: "VA",
      state_name: "Virginia",
    },
    {
      city_id: "7424",
      city: "Buckingham",
      zip_code: "23921",
      state_id: "VA",
      state_name: "Virginia",
    },
    {
      city_id: "7626",
      city: "Buena Vista",
      zip_code: "24416",
      state_id: "VA",
      state_name: "Virginia",
    },
    {
      city_id: "7677",
      city: "Buffalo Junction",
      zip_code: "24529",
      state_id: "VA",
      state_name: "Virginia",
    },
    {
      city_id: "7137",
      city: "Bumpass",
      zip_code: "23024",
      state_id: "VA",
      state_name: "Virginia",
    },
    {
      city_id: "6953",
      city: "Burgess",
      zip_code: "22432",
      state_id: "VA",
      state_name: "Virginia",
    },
    {
      city_id: "6888",
      city: "Burke",
      zip_code: "22015",
      state_id: "VA",
      state_name: "Virginia",
    },
    {
      city_id: "7425",
      city: "Burkeville",
      zip_code: "23922",
      state_id: "VA",
      state_name: "Virginia",
    },
    {
      city_id: "6954",
      city: "Burr Hill",
      zip_code: "22433",
      state_id: "VA",
      state_name: "Virginia",
    },
    {
      city_id: "7678",
      city: "Callands",
      zip_code: "24530",
      state_id: "VA",
      state_name: "Virginia",
    },
    {
      city_id: "6955",
      city: "Callao",
      zip_code: "22435",
      state_id: "VA",
      state_name: "Virginia",
    },
    {
      city_id: "7370",
      city: "Capron",
      zip_code: "23829",
      state_id: "VA",
      state_name: "Virginia",
    },
    {
      city_id: "6956",
      city: "Caret",
      zip_code: "22436",
      state_id: "VA",
      state_name: "Virginia",
    },
    {
      city_id: "7252",
      city: "Carrollton",
      zip_code: "23314",
      state_id: "VA",
      state_name: "Virginia",
    },
    {
      city_id: "7253",
      city: "Carrsville",
      zip_code: "23315",
      state_id: "VA",
      state_name: "Virginia",
    },
    {
      city_id: "7371",
      city: "Carson",
      zip_code: "23830",
      state_id: "VA",
      state_name: "Virginia",
    },
    {
      city_id: "7139",
      city: "Cartersville",
      zip_code: "23027",
      state_id: "VA",
      state_name: "Virginia",
    },
    {
      city_id: "7476",
      city: "Cascade",
      zip_code: "24069",
      state_id: "VA",
      state_name: "Virginia",
    },
    {
      city_id: "7032",
      city: "Castleton",
      zip_code: "22716",
      state_id: "VA",
      state_name: "Virginia",
    },
    {
      city_id: "6354",
      city: "Catharpin",
      zip_code: "20143",
      state_id: "VA",
      state_name: "Virginia",
    },
    {
      city_id: "6343",
      city: "Catlett",
      zip_code: "20119",
      state_id: "VA",
      state_name: "Virginia",
    },
    {
      city_id: "6957",
      city: "Center Cross",
      zip_code: "22437",
      state_id: "VA",
      state_name: "Virginia",
    },
    {
      city_id: "6344",
      city: "Centreville",
      zip_code: "20120",
      state_id: "VA",
      state_name: "Virginia",
    },
    {
      city_id: "6958",
      city: "Champlain",
      zip_code: "22438",
      state_id: "VA",
      state_name: "Virginia",
    },
    {
      city_id: "6358",
      city: "Chantilly",
      zip_code: "20151",
      state_id: "VA",
      state_name: "Virginia",
    },
    {
      city_id: "7426",
      city: "Charlotte Court House",
      zip_code: "23923",
      state_id: "VA",
      state_name: "Virginia",
    },
    {
      city_id: "7088",
      city: "Charlottesville",
      zip_code: "22901",
      state_id: "VA",
      state_name: "Virginia",
    },
    {
      city_id: "7427",
      city: "Chase City",
      zip_code: "23924",
      state_id: "VA",
      state_name: "Virginia",
    },
    {
      city_id: "7679",
      city: "Chatham",
      zip_code: "24531",
      state_id: "VA",
      state_name: "Virginia",
    },
    {
      city_id: "7255",
      city: "Chesapeake",
      zip_code: "23320",
      state_id: "VA",
      state_name: "Virginia",
    },
    {
      city_id: "7372",
      city: "Chester",
      zip_code: "23831",
      state_id: "VA",
      state_name: "Virginia",
    },
    {
      city_id: "7373",
      city: "Chesterfield",
      zip_code: "23832",
      state_id: "VA",
      state_name: "Virginia",
    },
    {
      city_id: "7374",
      city: "Church Road",
      zip_code: "23833",
      state_id: "VA",
      state_name: "Virginia",
    },
    {
      city_id: "7141",
      city: "Church View",
      zip_code: "23032",
      state_id: "VA",
      state_name: "Virginia",
    },
    {
      city_id: "7627",
      city: "Churchville",
      zip_code: "24421",
      state_id: "VA",
      state_name: "Virginia",
    },
    {
      city_id: "7428",
      city: "Clarksville",
      zip_code: "23927",
      state_id: "VA",
      state_name: "Virginia",
    },
    {
      city_id: "7003",
      city: "Clear Brook",
      zip_code: "22624",
      state_id: "VA",
      state_name: "Virginia",
    },
    {
      city_id: "6346",
      city: "Clifton",
      zip_code: "20124",
      state_id: "VA",
      state_name: "Virginia",
    },
    {
      city_id: "7628",
      city: "Clifton Forge",
      zip_code: "24422",
      state_id: "VA",
      state_name: "Virginia",
    },
    {
      city_id: "7680",
      city: "Clover",
      zip_code: "24534",
      state_id: "VA",
      state_name: "Virginia",
    },
    {
      city_id: "7681",
      city: "Coleman Falls",
      zip_code: "24536",
      state_id: "VA",
      state_name: "Virginia",
    },
    {
      city_id: "6959",
      city: "Colonial Beach",
      zip_code: "22443",
      state_id: "VA",
      state_name: "Virginia",
    },
    {
      city_id: "7375",
      city: "Colonial Heights",
      zip_code: "23834",
      state_id: "VA",
      state_name: "Virginia",
    },
    {
      city_id: "7143",
      city: "Columbia",
      zip_code: "23038",
      state_id: "VA",
      state_name: "Virginia",
    },
    {
      city_id: "7682",
      city: "Concord",
      zip_code: "24538",
      state_id: "VA",
      state_name: "Virginia",
    },
    {
      city_id: "7377",
      city: "Courtland",
      zip_code: "23837",
      state_id: "VA",
      state_name: "Virginia",
    },
    {
      city_id: "7097",
      city: "Covesville",
      zip_code: "22931",
      state_id: "VA",
      state_name: "Virginia",
    },
    {
      city_id: "7629",
      city: "Covington",
      zip_code: "24426",
      state_id: "VA",
      state_name: "Virginia",
    },
    {
      city_id: "7630",
      city: "Craigsville",
      zip_code: "24430",
      state_id: "VA",
      state_name: "Virginia",
    },
    {
      city_id: "7429",
      city: "Crewe",
      zip_code: "23930",
      state_id: "VA",
      state_name: "Virginia",
    },
    {
      city_id: "7067",
      city: "Criders",
      zip_code: "22820",
      state_id: "VA",
      state_name: "Virginia",
    },
    {
      city_id: "7631",
      city: "Crimora",
      zip_code: "24431",
      state_id: "VA",
      state_name: "Virginia",
    },
    {
      city_id: "7004",
      city: "Cross Junction",
      zip_code: "22625",
      state_id: "VA",
      state_name: "Virginia",
    },
    {
      city_id: "7098",
      city: "Crozet",
      zip_code: "22932",
      state_id: "VA",
      state_name: "Virginia",
    },
    {
      city_id: "7144",
      city: "Crozier",
      zip_code: "23039",
      state_id: "VA",
      state_name: "Virginia",
    },
    {
      city_id: "7683",
      city: "Crystal Hill",
      zip_code: "24539",
      state_id: "VA",
      state_name: "Virginia",
    },
    {
      city_id: "7430",
      city: "Cullen",
      zip_code: "23934",
      state_id: "VA",
      state_name: "Virginia",
    },
    {
      city_id: "7025",
      city: "Culpeper",
      zip_code: "22701",
      state_id: "VA",
      state_name: "Virginia",
    },
    {
      city_id: "7145",
      city: "Cumberland",
      zip_code: "23040",
      state_id: "VA",
      state_name: "Virginia",
    },
    {
      city_id: "6960",
      city: "Dahlgren",
      zip_code: "22448",
      state_id: "VA",
      state_name: "Virginia",
    },
    {
      city_id: "7684",
      city: "Danville",
      zip_code: "24540",
      state_id: "VA",
      state_name: "Virginia",
    },
    {
      city_id: "7068",
      city: "Dayton",
      zip_code: "22821",
      state_id: "VA",
      state_name: "Virginia",
    },
    {
      city_id: "7632",
      city: "Deerfield",
      zip_code: "24432",
      state_id: "VA",
      state_name: "Virginia",
    },
    {
      city_id: "6355",
      city: "Delaplane",
      zip_code: "20144",
      state_id: "VA",
      state_name: "Virginia",
    },
    {
      city_id: "7146",
      city: "Deltaville",
      zip_code: "23043",
      state_id: "VA",
      state_name: "Virginia",
    },
    {
      city_id: "7379",
      city: "Dendron",
      zip_code: "23839",
      state_id: "VA",
      state_name: "Virginia",
    },
    {
      city_id: "7380",
      city: "Dewitt",
      zip_code: "23840",
      state_id: "VA",
      state_name: "Virginia",
    },
    {
      city_id: "7431",
      city: "Dillwyn",
      zip_code: "23936",
      state_id: "VA",
      state_name: "Virginia",
    },
    {
      city_id: "7381",
      city: "Dinwiddie",
      zip_code: "23841",
      state_id: "VA",
      state_name: "Virginia",
    },
    {
      city_id: "7382",
      city: "Disputanta",
      zip_code: "23842",
      state_id: "VA",
      state_name: "Virginia",
    },
    {
      city_id: "7383",
      city: "Dolphin",
      zip_code: "23843",
      state_id: "VA",
      state_name: "Virginia",
    },
    {
      city_id: "7148",
      city: "Doswell",
      zip_code: "23047",
      state_id: "VA",
      state_name: "Virginia",
    },
    {
      city_id: "7432",
      city: "Drakes Branch",
      zip_code: "23937",
      state_id: "VA",
      state_name: "Virginia",
    },
    {
      city_id: "7384",
      city: "Drewryville",
      zip_code: "23844",
      state_id: "VA",
      state_name: "Virginia",
    },
    {
      city_id: "7686",
      city: "Dry Fork",
      zip_code: "24549",
      state_id: "VA",
      state_name: "Virginia",
    },
    {
      city_id: "6889",
      city: "Dumfries",
      zip_code: "22025",
      state_id: "VA",
      state_name: "Virginia",
    },
    {
      city_id: "7433",
      city: "Dundas",
      zip_code: "23938",
      state_id: "VA",
      state_name: "Virginia",
    },
    {
      city_id: "6891",
      city: "Dunn Loring",
      zip_code: "22027",
      state_id: "VA",
      state_name: "Virginia",
    },
    {
      city_id: "6961",
      city: "Dunnsville",
      zip_code: "22454",
      state_id: "VA",
      state_name: "Virginia",
    },
    {
      city_id: "7099",
      city: "Dyke",
      zip_code: "22935",
      state_id: "VA",
      state_name: "Virginia",
    },
    {
      city_id: "7487",
      city: "Eagle Rock",
      zip_code: "24085",
      state_id: "VA",
      state_name: "Virginia",
    },
    {
      city_id: "7100",
      city: "Earlysville",
      zip_code: "22936",
      state_id: "VA",
      state_name: "Virginia",
    },
    {
      city_id: "7385",
      city: "Ebony",
      zip_code: "23845",
      state_id: "VA",
      state_name: "Virginia",
    },
    {
      city_id: "7069",
      city: "Edinburg",
      zip_code: "22824",
      state_id: "VA",
      state_name: "Virginia",
    },
    {
      city_id: "7488",
      city: "Eggleston",
      zip_code: "24086",
      state_id: "VA",
      state_name: "Virginia",
    },
    {
      city_id: "7386",
      city: "Elberon",
      zip_code: "23846",
      state_id: "VA",
      state_name: "Virginia",
    },
    {
      city_id: "7070",
      city: "Elkton",
      zip_code: "22827",
      state_id: "VA",
      state_name: "Virginia",
    },
    {
      city_id: "7033",
      city: "Elkwood",
      zip_code: "22718",
      state_id: "VA",
      state_name: "Virginia",
    },
    {
      city_id: "7387",
      city: "Emporia",
      zip_code: "23847",
      state_id: "VA",
      state_name: "Virginia",
    },
    {
      city_id: "7101",
      city: "Esmont",
      zip_code: "22937",
      state_id: "VA",
      state_name: "Virginia",
    },
    {
      city_id: "7034",
      city: "Etlan",
      zip_code: "22719",
      state_id: "VA",
      state_name: "Virginia",
    },
    {
      city_id: "7687",
      city: "Evington",
      zip_code: "24550",
      state_id: "VA",
      state_name: "Virginia",
    },
    {
      city_id: "7102",
      city: "Faber",
      zip_code: "22938",
      state_id: "VA",
      state_name: "Virginia",
    },
    {
      city_id: "6892",
      city: "Fairfax",
      zip_code: "22030",
      state_id: "VA",
      state_name: "Virginia",
    },
    {
      city_id: "6897",
      city: "Fairfax Station",
      zip_code: "22039",
      state_id: "VA",
      state_name: "Virginia",
    },
    {
      city_id: "7634",
      city: "Fairfield",
      zip_code: "24435",
      state_id: "VA",
      state_name: "Virginia",
    },
    {
      city_id: "6898",
      city: "Falls Church",
      zip_code: "22041",
      state_id: "VA",
      state_name: "Virginia",
    },
    {
      city_id: "7418",
      city: "Farmville",
      zip_code: "23901",
      state_id: "VA",
      state_name: "Virginia",
    },
    {
      city_id: "6962",
      city: "Farnham",
      zip_code: "22460",
      state_id: "VA",
      state_name: "Virginia",
    },
    {
      city_id: "7103",
      city: "Fishersville",
      zip_code: "22939",
      state_id: "VA",
      state_name: "Virginia",
    },
    {
      city_id: "7005",
      city: "Flint Hill",
      zip_code: "22627",
      state_id: "VA",
      state_name: "Virginia",
    },
    {
      city_id: "7388",
      city: "Ford",
      zip_code: "23850",
      state_id: "VA",
      state_name: "Virginia",
    },
    {
      city_id: "7688",
      city: "Forest",
      zip_code: "24551",
      state_id: "VA",
      state_name: "Virginia",
    },
    {
      city_id: "7150",
      city: "Fork Union",
      zip_code: "23055",
      state_id: "VA",
      state_name: "Virginia",
    },
    {
      city_id: "6903",
      city: "Fort Belvoir",
      zip_code: "22060",
      state_id: "VA",
      state_name: "Virginia",
    },
    {
      city_id: "7635",
      city: "Fort Defiance",
      zip_code: "24437",
      state_id: "VA",
      state_name: "Virginia",
    },
    {
      city_id: "7363",
      city: "Fort Lee",
      zip_code: "23801",
      state_id: "VA",
      state_name: "Virginia",
    },
    {
      city_id: "6930",
      city: "Fort Myer",
      zip_code: "22211",
      state_id: "VA",
      state_name: "Virginia",
    },
    {
      city_id: "7017",
      city: "Fort Valley",
      zip_code: "22652",
      state_id: "VA",
      state_name: "Virginia",
    },
    {
      city_id: "7389",
      city: "Franklin",
      zip_code: "23851",
      state_id: "VA",
      state_name: "Virginia",
    },
    {
      city_id: "6947",
      city: "Fredericksburg",
      zip_code: "22401",
      state_id: "VA",
      state_name: "Virginia",
    },
    {
      city_id: "7104",
      city: "Free Union",
      zip_code: "22940",
      state_id: "VA",
      state_name: "Virginia",
    },
    {
      city_id: "7390",
      city: "Freeman",
      zip_code: "23856",
      state_id: "VA",
      state_name: "Virginia",
    },
    {
      city_id: "7006",
      city: "Front Royal",
      zip_code: "22630",
      state_id: "VA",
      state_name: "Virginia",
    },
    {
      city_id: "7071",
      city: "Fulks Run",
      zip_code: "22830",
      state_id: "VA",
      state_name: "Virginia",
    },
    {
      city_id: "6360",
      city: "Gainesville",
      zip_code: "20155",
      state_id: "VA",
      state_name: "Virginia",
    },
    {
      city_id: "7391",
      city: "Gasburg",
      zip_code: "23857",
      state_id: "VA",
      state_name: "Virginia",
    },
    {
      city_id: "7689",
      city: "Gladstone",
      zip_code: "24553",
      state_id: "VA",
      state_name: "Virginia",
    },
    {
      city_id: "7690",
      city: "Gladys",
      zip_code: "24554",
      state_id: "VA",
      state_name: "Virginia",
    },
    {
      city_id: "7691",
      city: "Glasgow",
      zip_code: "24555",
      state_id: "VA",
      state_name: "Virginia",
    },
    {
      city_id: "7152",
      city: "Glen Allen",
      zip_code: "23059",
      state_id: "VA",
      state_name: "Virginia",
    },
    {
      city_id: "7495",
      city: "Glen Lyn",
      zip_code: "24093",
      state_id: "VA",
      state_name: "Virginia",
    },
    {
      city_id: "7035",
      city: "Goldvein",
      zip_code: "22720",
      state_id: "VA",
      state_name: "Virginia",
    },
    {
      city_id: "7156",
      city: "Goochland",
      zip_code: "23063",
      state_id: "VA",
      state_name: "Virginia",
    },
    {
      city_id: "7692",
      city: "Goode",
      zip_code: "24556",
      state_id: "VA",
      state_name: "Virginia",
    },
    {
      city_id: "7105",
      city: "Gordonsville",
      zip_code: "22942",
      state_id: "VA",
      state_name: "Virginia",
    },
    {
      city_id: "7007",
      city: "Gore",
      zip_code: "22637",
      state_id: "VA",
      state_name: "Virginia",
    },
    {
      city_id: "7636",
      city: "Goshen",
      zip_code: "24439",
      state_id: "VA",
      state_name: "Virginia",
    },
    {
      city_id: "6904",
      city: "Great Falls",
      zip_code: "22066",
      state_id: "VA",
      state_name: "Virginia",
    },
    {
      city_id: "7434",
      city: "Green Bay",
      zip_code: "23942",
      state_id: "VA",
      state_name: "Virginia",
    },
    {
      city_id: "7637",
      city: "Greenville",
      zip_code: "24440",
      state_id: "VA",
      state_name: "Virginia",
    },
    {
      city_id: "7106",
      city: "Greenwood",
      zip_code: "22943",
      state_id: "VA",
      state_name: "Virginia",
    },
    {
      city_id: "7693",
      city: "Gretna",
      zip_code: "24557",
      state_id: "VA",
      state_name: "Virginia",
    },
    {
      city_id: "7638",
      city: "Grottoes",
      zip_code: "24441",
      state_id: "VA",
      state_name: "Virginia",
    },
    {
      city_id: "7157",
      city: "Gum Spring",
      zip_code: "23065",
      state_id: "VA",
      state_name: "Virginia",
    },
    {
      city_id: "6963",
      city: "Hague",
      zip_code: "22469",
      state_id: "VA",
      state_name: "Virginia",
    },
    {
      city_id: "7694",
      city: "Halifax",
      zip_code: "24558",
      state_id: "VA",
      state_name: "Virginia",
    },
    {
      city_id: "6361",
      city: "Hamilton",
      zip_code: "20158",
      state_id: "VA",
      state_name: "Virginia",
    },
    {
      city_id: "7160",
      city: "Hanover",
      zip_code: "23069",
      state_id: "VA",
      state_name: "Virginia",
    },
    {
      city_id: "7161",
      city: "Hardyville",
      zip_code: "23070",
      state_id: "VA",
      state_name: "Virginia",
    },
    {
      city_id: "7060",
      city: "Harrisonburg",
      zip_code: "22801",
      state_id: "VA",
      state_name: "Virginia",
    },
    {
      city_id: "7162",
      city: "Hartfield",
      zip_code: "23071",
      state_id: "VA",
      state_name: "Virginia",
    },
    {
      city_id: "6365",
      city: "Haymarket",
      zip_code: "20169",
      state_id: "VA",
      state_name: "Virginia",
    },
    {
      city_id: "6964",
      city: "Haynesville",
      zip_code: "22472",
      state_id: "VA",
      state_name: "Virginia",
    },
    {
      city_id: "7036",
      city: "Haywood",
      zip_code: "22722",
      state_id: "VA",
      state_name: "Virginia",
    },
    {
      city_id: "7639",
      city: "Head Waters",
      zip_code: "24442",
      state_id: "VA",
      state_name: "Virginia",
    },
    {
      city_id: "6965",
      city: "Heathsville",
      zip_code: "22473",
      state_id: "VA",
      state_name: "Virginia",
    },
    {
      city_id: "7164",
      city: "Henrico",
      zip_code: "23075",
      state_id: "VA",
      state_name: "Virginia",
    },
    {
      city_id: "6366",
      city: "Herndon",
      zip_code: "20170",
      state_id: "VA",
      state_name: "Virginia",
    },
    {
      city_id: "7072",
      city: "Hinton",
      zip_code: "22831",
      state_id: "VA",
      state_name: "Virginia",
    },
    {
      city_id: "7037",
      city: "Hood",
      zip_code: "22723",
      state_id: "VA",
      state_name: "Virginia",
    },
    {
      city_id: "7392",
      city: "Hopewell",
      zip_code: "23860",
      state_id: "VA",
      state_name: "Virginia",
    },
    {
      city_id: "7640",
      city: "Hot Springs",
      zip_code: "24445",
      state_id: "VA",
      state_name: "Virginia",
    },
    {
      city_id: "7695",
      city: "Howardsville",
      zip_code: "24562",
      state_id: "VA",
      state_name: "Virginia",
    },
    {
      city_id: "7499",
      city: "Huddleston",
      zip_code: "24104",
      state_id: "VA",
      state_name: "Virginia",
    },
    {
      city_id: "7008",
      city: "Hume",
      zip_code: "22639",
      state_id: "VA",
      state_name: "Virginia",
    },
    {
      city_id: "7009",
      city: "Huntly",
      zip_code: "22640",
      state_id: "VA",
      state_name: "Virginia",
    },
    {
      city_id: "7731",
      city: "Hurley",
      zip_code: "24620",
      state_id: "VA",
      state_name: "Virginia",
    },
    {
      city_id: "7696",
      city: "Hurt",
      zip_code: "24563",
      state_id: "VA",
      state_name: "Virginia",
    },
    {
      city_id: "6966",
      city: "Hustle",
      zip_code: "22476",
      state_id: "VA",
      state_name: "Virginia",
    },
    {
      city_id: "7641",
      city: "Iron Gate",
      zip_code: "24448",
      state_id: "VA",
      state_name: "Virginia",
    },
    {
      city_id: "6967",
      city: "Irvington",
      zip_code: "22480",
      state_id: "VA",
      state_name: "Virginia",
    },
    {
      city_id: "7393",
      city: "Ivor",
      zip_code: "23866",
      state_id: "VA",
      state_name: "Virginia",
    },
    {
      city_id: "7166",
      city: "Jamaica",
      zip_code: "23079",
      state_id: "VA",
      state_name: "Virginia",
    },
    {
      city_id: "7394",
      city: "Jarratt",
      zip_code: "23867",
      state_id: "VA",
      state_name: "Virginia",
    },
    {
      city_id: "7697",
      city: "Java",
      zip_code: "24565",
      state_id: "VA",
      state_name: "Virginia",
    },
    {
      city_id: "7038",
      city: "Jeffersonton",
      zip_code: "22724",
      state_id: "VA",
      state_name: "Virginia",
    },
    {
      city_id: "7168",
      city: "Jetersville",
      zip_code: "23083",
      state_id: "VA",
      state_name: "Virginia",
    },
    {
      city_id: "7698",
      city: "Keeling",
      zip_code: "24566",
      state_id: "VA",
      state_name: "Virginia",
    },
    {
      city_id: "7107",
      city: "Keene",
      zip_code: "22946",
      state_id: "VA",
      state_name: "Virginia",
    },
    {
      city_id: "7073",
      city: "Keezletown",
      zip_code: "22832",
      state_id: "VA",
      state_name: "Virginia",
    },
    {
      city_id: "7436",
      city: "Kenbridge",
      zip_code: "23944",
      state_id: "VA",
      state_name: "Virginia",
    },
    {
      city_id: "7169",
      city: "Kents Store",
      zip_code: "23084",
      state_id: "VA",
      state_name: "Virginia",
    },
    {
      city_id: "7108",
      city: "Keswick",
      zip_code: "22947",
      state_id: "VA",
      state_name: "Virginia",
    },
    {
      city_id: "7437",
      city: "Keysville",
      zip_code: "23947",
      state_id: "VA",
      state_name: "Virginia",
    },
    {
      city_id: "6968",
      city: "Kilmarnock",
      zip_code: "22482",
      state_id: "VA",
      state_name: "Virginia",
    },
    {
      city_id: "6969",
      city: "King George",
      zip_code: "22485",
      state_id: "VA",
      state_name: "Virginia",
    },
    {
      city_id: "6970",
      city: "Kinsale",
      zip_code: "22488",
      state_id: "VA",
      state_name: "Virginia",
    },
    {
      city_id: "7438",
      city: "La Crosse",
      zip_code: "23950",
      state_id: "VA",
      state_name: "Virginia",
    },
    {
      city_id: "6971",
      city: "Lancaster",
      zip_code: "22503",
      state_id: "VA",
      state_name: "Virginia",
    },
    {
      city_id: "6972",
      city: "Laneview",
      zip_code: "22504",
      state_id: "VA",
      state_name: "Virginia",
    },
    {
      city_id: "7395",
      city: "Lawrenceville",
      zip_code: "23868",
      state_id: "VA",
      state_name: "Virginia",
    },
    {
      city_id: "6368",
      city: "Leesburg",
      zip_code: "20175",
      state_id: "VA",
      state_name: "Virginia",
    },
    {
      city_id: "7039",
      city: "Leon",
      zip_code: "22725",
      state_id: "VA",
      state_name: "Virginia",
    },
    {
      city_id: "7642",
      city: "Lexington",
      zip_code: "24450",
      state_id: "VA",
      state_name: "Virginia",
    },
    {
      city_id: "7040",
      city: "Lignum",
      zip_code: "22726",
      state_id: "VA",
      state_name: "Virginia",
    },
    {
      city_id: "7011",
      city: "Linden",
      zip_code: "22642",
      state_id: "VA",
      state_name: "Virginia",
    },
    {
      city_id: "7074",
      city: "Linville",
      zip_code: "22834",
      state_id: "VA",
      state_name: "Virginia",
    },
    {
      city_id: "7109",
      city: "Locust Dale",
      zip_code: "22948",
      state_id: "VA",
      state_name: "Virginia",
    },
    {
      city_id: "6973",
      city: "Locust Grove",
      zip_code: "22508",
      state_id: "VA",
      state_name: "Virginia",
    },
    {
      city_id: "7174",
      city: "Locust Hill",
      zip_code: "23092",
      state_id: "VA",
      state_name: "Virginia",
    },
    {
      city_id: "7699",
      city: "Long Island",
      zip_code: "24569",
      state_id: "VA",
      state_name: "Virginia",
    },
    {
      city_id: "6974",
      city: "Loretto",
      zip_code: "22509",
      state_id: "VA",
      state_name: "Virginia",
    },
    {
      city_id: "6905",
      city: "Lorton",
      zip_code: "22079",
      state_id: "VA",
      state_name: "Virginia",
    },
    {
      city_id: "6975",
      city: "Lottsburg",
      zip_code: "22511",
      state_id: "VA",
      state_name: "Virginia",
    },
    {
      city_id: "7175",
      city: "Louisa",
      zip_code: "23093",
      state_id: "VA",
      state_name: "Virginia",
    },
    {
      city_id: "6370",
      city: "Lovettsville",
      zip_code: "20180",
      state_id: "VA",
      state_name: "Virginia",
    },
    {
      city_id: "7110",
      city: "Lovingston",
      zip_code: "22949",
      state_id: "VA",
      state_name: "Virginia",
    },
    {
      city_id: "7700",
      city: "Lowry",
      zip_code: "24570",
      state_id: "VA",
      state_name: "Virginia",
    },
    {
      city_id: "7439",
      city: "Lunenburg",
      zip_code: "23952",
      state_id: "VA",
      state_name: "Virginia",
    },
    {
      city_id: "7075",
      city: "Luray",
      zip_code: "22835",
      state_id: "VA",
      state_name: "Virginia",
    },
    {
      city_id: "7701",
      city: "Lynch Station",
      zip_code: "24571",
      state_id: "VA",
      state_name: "Virginia",
    },
    {
      city_id: "7664",
      city: "Lynchburg",
      zip_code: "24501",
      state_id: "VA",
      state_name: "Virginia",
    },
    {
      city_id: "7111",
      city: "Lyndhurst",
      zip_code: "22952",
      state_id: "VA",
      state_name: "Virginia",
    },
    {
      city_id: "7041",
      city: "Madison",
      zip_code: "22727",
      state_id: "VA",
      state_name: "Virginia",
    },
    {
      city_id: "7702",
      city: "Madison Heights",
      zip_code: "24572",
      state_id: "VA",
      state_name: "Virginia",
    },
    {
      city_id: "7176",
      city: "Maidens",
      zip_code: "23102",
      state_id: "VA",
      state_name: "Virginia",
    },
    {
      city_id: "7177",
      city: "Manakin Sabot",
      zip_code: "23103",
      state_id: "VA",
      state_name: "Virginia",
    },
    {
      city_id: "6337",
      city: "Manassas",
      zip_code: "20109",
      state_id: "VA",
      state_name: "Virginia",
    },
    {
      city_id: "7012",
      city: "Markham",
      zip_code: "22643",
      state_id: "VA",
      state_name: "Virginia",
    },
    {
      city_id: "6341",
      city: "Marshall",
      zip_code: "20115",
      state_id: "VA",
      state_name: "Virginia",
    },
    {
      city_id: "7013",
      city: "Maurertown",
      zip_code: "22644",
      state_id: "VA",
      state_name: "Virginia",
    },
    {
      city_id: "7076",
      city: "McGaheysville",
      zip_code: "22840",
      state_id: "VA",
      state_name: "Virginia",
    },
    {
      city_id: "7397",
      city: "McKenney",
      zip_code: "23872",
      state_id: "VA",
      state_name: "Virginia",
    },
    {
      city_id: "6906",
      city: "McLean",
      zip_code: "22101",
      state_id: "VA",
      state_name: "Virginia",
    },
    {
      city_id: "7440",
      city: "Meherrin",
      zip_code: "23954",
      state_id: "VA",
      state_name: "Virginia",
    },
    {
      city_id: "7645",
      city: "Middlebrook",
      zip_code: "24459",
      state_id: "VA",
      state_name: "Virginia",
    },
    {
      city_id: "6342",
      city: "Middleburg",
      zip_code: "20117",
      state_id: "VA",
      state_name: "Virginia",
    },
    {
      city_id: "7014",
      city: "Middletown",
      zip_code: "22645",
      state_id: "VA",
      state_name: "Virginia",
    },
    {
      city_id: "7042",
      city: "Midland",
      zip_code: "22728",
      state_id: "VA",
      state_name: "Virginia",
    },
    {
      city_id: "7183",
      city: "Midlothian",
      zip_code: "23112",
      state_id: "VA",
      state_name: "Virginia",
    },
    {
      city_id: "6976",
      city: "Milford",
      zip_code: "22514",
      state_id: "VA",
      state_name: "Virginia",
    },
    {
      city_id: "7015",
      city: "Millwood",
      zip_code: "22646",
      state_id: "VA",
      state_name: "Virginia",
    },
    {
      city_id: "7187",
      city: "Mineral",
      zip_code: "23117",
      state_id: "VA",
      state_name: "Virginia",
    },
    {
      city_id: "7043",
      city: "Mitchells",
      zip_code: "22729",
      state_id: "VA",
      state_name: "Virginia",
    },
    {
      city_id: "7503",
      city: "Moneta",
      zip_code: "24121",
      state_id: "VA",
      state_name: "Virginia",
    },
    {
      city_id: "7703",
      city: "Monroe",
      zip_code: "24574",
      state_id: "VA",
      state_name: "Virginia",
    },
    {
      city_id: "7647",
      city: "Montebello",
      zip_code: "24464",
      state_id: "VA",
      state_name: "Virginia",
    },
    {
      city_id: "7220",
      city: "Montpelier",
      zip_code: "23192",
      state_id: "VA",
      state_name: "Virginia",
    },
    {
      city_id: "6977",
      city: "Montross",
      zip_code: "22520",
      state_id: "VA",
      state_name: "Virginia",
    },
    {
      city_id: "7189",
      city: "Moseley",
      zip_code: "23120",
      state_id: "VA",
      state_name: "Virginia",
    },
    {
      city_id: "7077",
      city: "Mount Crawford",
      zip_code: "22841",
      state_id: "VA",
      state_name: "Virginia",
    },
    {
      city_id: "7078",
      city: "Mount Jackson",
      zip_code: "22842",
      state_id: "VA",
      state_name: "Virginia",
    },
    {
      city_id: "7649",
      city: "Mount Sidney",
      zip_code: "24467",
      state_id: "VA",
      state_name: "Virginia",
    },
    {
      city_id: "7079",
      city: "Mount Solon",
      zip_code: "22843",
      state_id: "VA",
      state_name: "Virginia",
    },
    {
      city_id: "7505",
      city: "Narrows",
      zip_code: "24124",
      state_id: "VA",
      state_name: "Virginia",
    },
    {
      city_id: "7704",
      city: "Nathalie",
      zip_code: "24577",
      state_id: "VA",
      state_name: "Virginia",
    },
    {
      city_id: "7705",
      city: "Natural Bridge",
      zip_code: "24578",
      state_id: "VA",
      state_name: "Virginia",
    },
    {
      city_id: "7706",
      city: "Natural Bridge Station",
      zip_code: "24579",
      state_id: "VA",
      state_name: "Virginia",
    },
    {
      city_id: "7113",
      city: "Nellysford",
      zip_code: "22958",
      state_id: "VA",
      state_name: "Virginia",
    },
    {
      city_id: "7707",
      city: "Nelson",
      zip_code: "24580",
      state_id: "VA",
      state_name: "Virginia",
    },
    {
      city_id: "7190",
      city: "New Canton",
      zip_code: "23123",
      state_id: "VA",
      state_name: "Virginia",
    },
    {
      city_id: "7080",
      city: "New Market",
      zip_code: "22844",
      state_id: "VA",
      state_name: "Virginia",
    },
    {
      city_id: "7507",
      city: "Newport",
      zip_code: "24128",
      state_id: "VA",
      state_name: "Virginia",
    },
    {
      city_id: "7398",
      city: "Newsoms",
      zip_code: "23874",
      state_id: "VA",
      state_name: "Virginia",
    },
    {
      city_id: "6371",
      city: "Nokesville",
      zip_code: "20181",
      state_id: "VA",
      state_name: "Virginia",
    },
    {
      city_id: "7320",
      city: "Norfolk",
      zip_code: "23504",
      state_id: "VA",
      state_name: "Virginia",
    },
    {
      city_id: "7114",
      city: "North Garden",
      zip_code: "22959",
      state_id: "VA",
      state_name: "Virginia",
    },
    {
      city_id: "7044",
      city: "Oakpark",
      zip_code: "22730",
      state_id: "VA",
      state_name: "Virginia",
    },
    {
      city_id: "6908",
      city: "Oakton",
      zip_code: "22124",
      state_id: "VA",
      state_name: "Virginia",
    },
    {
      city_id: "6909",
      city: "Occoquan",
      zip_code: "22125",
      state_id: "VA",
      state_name: "Virginia",
    },
    {
      city_id: "7194",
      city: "Oilville",
      zip_code: "23129",
      state_id: "VA",
      state_name: "Virginia",
    },
    {
      city_id: "6978",
      city: "Oldhams",
      zip_code: "22529",
      state_id: "VA",
      state_name: "Virginia",
    },
    {
      city_id: "7115",
      city: "Orange",
      zip_code: "22960",
      state_id: "VA",
      state_name: "Virginia",
    },
    {
      city_id: "7081",
      city: "Orkney Springs",
      zip_code: "22845",
      state_id: "VA",
      state_name: "Virginia",
    },
    {
      city_id: "6347",
      city: "Paeonian Springs",
      zip_code: "20129",
      state_id: "VA",
      state_name: "Virginia",
    },
    {
      city_id: "7116",
      city: "Palmyra",
      zip_code: "22963",
      state_id: "VA",
      state_name: "Virginia",
    },
    {
      city_id: "7442",
      city: "Pamplin",
      zip_code: "23958",
      state_id: "VA",
      state_name: "Virginia",
    },
    {
      city_id: "6348",
      city: "Paris",
      zip_code: "20130",
      state_id: "VA",
      state_name: "Virginia",
    },
    {
      city_id: "6979",
      city: "Partlow",
      zip_code: "22534",
      state_id: "VA",
      state_name: "Virginia",
    },
    {
      city_id: "7512",
      city: "Pearisburg",
      zip_code: "24134",
      state_id: "VA",
      state_name: "Virginia",
    },
    {
      city_id: "7513",
      city: "Pembroke",
      zip_code: "24136",
      state_id: "VA",
      state_name: "Virginia",
    },
    {
      city_id: "7514",
      city: "Penhook",
      zip_code: "24137",
      state_id: "VA",
      state_name: "Virginia",
    },
    {
      city_id: "7082",
      city: "Penn Laird",
      zip_code: "22846",
      state_id: "VA",
      state_name: "Virginia",
    },
    {
      city_id: "7364",
      city: "Petersburg",
      zip_code: "23803",
      state_id: "VA",
      state_name: "Virginia",
    },
    {
      city_id: "7443",
      city: "Phenix",
      zip_code: "23959",
      state_id: "VA",
      state_name: "Virginia",
    },
    {
      city_id: "7117",
      city: "Piney River",
      zip_code: "22964",
      state_id: "VA",
      state_name: "Virginia",
    },
    {
      city_id: "7516",
      city: "Pittsville",
      zip_code: "24139",
      state_id: "VA",
      state_name: "Virginia",
    },
    {
      city_id: "7651",
      city: "Port Republic",
      zip_code: "24471",
      state_id: "VA",
      state_name: "Virginia",
    },
    {
      city_id: "6980",
      city: "Port Royal",
      zip_code: "22535",
      state_id: "VA",
      state_name: "Virginia",
    },
    {
      city_id: "7356",
      city: "Portsmouth",
      zip_code: "23701",
      state_id: "VA",
      state_name: "Virginia",
    },
    {
      city_id: "7573",
      city: "Pound",
      zip_code: "24279",
      state_id: "VA",
      state_name: "Virginia",
    },
    {
      city_id: "7197",
      city: "Powhatan",
      zip_code: "23139",
      state_id: "VA",
      state_name: "Virginia",
    },
    {
      city_id: "7045",
      city: "Pratts",
      zip_code: "22731",
      state_id: "VA",
      state_name: "Virginia",
    },
    {
      city_id: "7399",
      city: "Prince George",
      zip_code: "23875",
      state_id: "VA",
      state_name: "Virginia",
    },
    {
      city_id: "7444",
      city: "Prospect",
      zip_code: "23960",
      state_id: "VA",
      state_name: "Virginia",
    },
    {
      city_id: "6349",
      city: "Purcellville",
      zip_code: "20132",
      state_id: "VA",
      state_name: "Virginia",
    },
    {
      city_id: "6910",
      city: "Quantico",
      zip_code: "22134",
      state_id: "VA",
      state_name: "Virginia",
    },
    {
      city_id: "7083",
      city: "Quicksburg",
      zip_code: "22847",
      state_id: "VA",
      state_name: "Virginia",
    },
    {
      city_id: "7046",
      city: "Radiant",
      zip_code: "22732",
      state_id: "VA",
      state_name: "Virginia",
    },
    {
      city_id: "7445",
      city: "Randolph",
      zip_code: "23962",
      state_id: "VA",
      state_name: "Virginia",
    },
    {
      city_id: "7652",
      city: "Raphine",
      zip_code: "24472",
      state_id: "VA",
      state_name: "Virginia",
    },
    {
      city_id: "7047",
      city: "Rapidan",
      zip_code: "22733",
      state_id: "VA",
      state_name: "Virginia",
    },
    {
      city_id: "6981",
      city: "Rappahannock Academy",
      zip_code: "22538",
      state_id: "VA",
      state_name: "Virginia",
    },
    {
      city_id: "7400",
      city: "Rawlings",
      zip_code: "23876",
      state_id: "VA",
      state_name: "Virginia",
    },
    {
      city_id: "7446",
      city: "Red House",
      zip_code: "23963",
      state_id: "VA",
      state_name: "Virginia",
    },
    {
      city_id: "7447",
      city: "Red Oak",
      zip_code: "23964",
      state_id: "VA",
      state_name: "Virginia",
    },
    {
      city_id: "6982",
      city: "Reedville",
      zip_code: "22539",
      state_id: "VA",
      state_name: "Virginia",
    },
    {
      city_id: "7048",
      city: "Remington",
      zip_code: "22734",
      state_id: "VA",
      state_name: "Virginia",
    },
    {
      city_id: "6375",
      city: "Reston",
      zip_code: "20190",
      state_id: "VA",
      state_name: "Virginia",
    },
    {
      city_id: "7049",
      city: "Reva",
      zip_code: "22735",
      state_id: "VA",
      state_name: "Virginia",
    },
    {
      city_id: "6983",
      city: "Rhoadesville",
      zip_code: "22542",
      state_id: "VA",
      state_name: "Virginia",
    },
    {
      city_id: "7448",
      city: "Rice",
      zip_code: "23966",
      state_id: "VA",
      state_name: "Virginia",
    },
    {
      city_id: "7519",
      city: "Rich Creek",
      zip_code: "24147",
      state_id: "VA",
      state_name: "Virginia",
    },
    {
      city_id: "7050",
      city: "Richardsville",
      zip_code: "22736",
      state_id: "VA",
      state_name: "Virginia",
    },
    {
      city_id: "7212",
      city: "Richmond",
      zip_code: "23173",
      state_id: "VA",
      state_name: "Virginia",
    },
    {
      city_id: "7016",
      city: "Rileyville",
      zip_code: "22650",
      state_id: "VA",
      state_name: "Virginia",
    },
    {
      city_id: "7709",
      city: "Ringgold",
      zip_code: "24586",
      state_id: "VA",
      state_name: "Virginia",
    },
    {
      city_id: "7522",
      city: "Ripplemead",
      zip_code: "24150",
      state_id: "VA",
      state_name: "Virginia",
    },
    {
      city_id: "7051",
      city: "Rixeyville",
      zip_code: "22737",
      state_id: "VA",
      state_name: "Virginia",
    },
    {
      city_id: "7052",
      city: "Rochelle",
      zip_code: "22738",
      state_id: "VA",
      state_name: "Virginia",
    },
    {
      city_id: "7653",
      city: "Rockbridge Baths",
      zip_code: "24473",
      state_id: "VA",
      state_name: "Virginia",
    },
    {
      city_id: "7200",
      city: "Rockville",
      zip_code: "23146",
      state_id: "VA",
      state_name: "Virginia",
    },
    {
      city_id: "7118",
      city: "Roseland",
      zip_code: "22967",
      state_id: "VA",
      state_name: "Virginia",
    },
    {
      city_id: "6353",
      city: "Round Hill",
      zip_code: "20141",
      state_id: "VA",
      state_name: "Virginia",
    },
    {
      city_id: "7119",
      city: "Ruckersville",
      zip_code: "22968",
      state_id: "VA",
      state_name: "Virginia",
    },
    {
      city_id: "7710",
      city: "Rustburg",
      zip_code: "24588",
      state_id: "VA",
      state_name: "Virginia",
    },
    {
      city_id: "6984",
      city: "Ruther Glen",
      zip_code: "22546",
      state_id: "VA",
      state_name: "Virginia",
    },
    {
      city_id: "7203",
      city: "Saluda",
      zip_code: "23149",
      state_id: "VA",
      state_name: "Virginia",
    },
    {
      city_id: "7204",
      city: "Sandston",
      zip_code: "23150",
      state_id: "VA",
      state_name: "Virginia",
    },
    {
      city_id: "7205",
      city: "Sandy Hook",
      zip_code: "23153",
      state_id: "VA",
      state_name: "Virginia",
    },
    {
      city_id: "7525",
      city: "Sandy Level",
      zip_code: "24161",
      state_id: "VA",
      state_name: "Virginia",
    },
    {
      city_id: "7449",
      city: "Saxe",
      zip_code: "23967",
      state_id: "VA",
      state_name: "Virginia",
    },
    {
      city_id: "7120",
      city: "Schuyler",
      zip_code: "22969",
      state_id: "VA",
      state_name: "Virginia",
    },
    {
      city_id: "7711",
      city: "Scottsburg",
      zip_code: "24589",
      state_id: "VA",
      state_name: "Virginia",
    },
    {
      city_id: "7712",
      city: "Scottsville",
      zip_code: "24590",
      state_id: "VA",
      state_name: "Virginia",
    },
    {
      city_id: "7401",
      city: "Sedley",
      zip_code: "23878",
      state_id: "VA",
      state_name: "Virginia",
    },
    {
      city_id: "6985",
      city: "Sharps",
      zip_code: "22548",
      state_id: "VA",
      state_name: "Virginia",
    },
    {
      city_id: "7084",
      city: "Shenandoah",
      zip_code: "22849",
      state_id: "VA",
      state_name: "Virginia",
    },
    {
      city_id: "7121",
      city: "Shipman",
      zip_code: "22971",
      state_id: "VA",
      state_name: "Virginia",
    },
    {
      city_id: "7402",
      city: "Skippers",
      zip_code: "23879",
      state_id: "VA",
      state_name: "Virginia",
    },
    {
      city_id: "7450",
      city: "Skipwith",
      zip_code: "23968",
      state_id: "VA",
      state_name: "Virginia",
    },
    {
      city_id: "7291",
      city: "Smithfield",
      zip_code: "23430",
      state_id: "VA",
      state_name: "Virginia",
    },
    {
      city_id: "7122",
      city: "Somerset",
      zip_code: "22972",
      state_id: "VA",
      state_name: "Virginia",
    },
    {
      city_id: "7713",
      city: "South Boston",
      zip_code: "24592",
      state_id: "VA",
      state_name: "Virginia",
    },
    {
      city_id: "7451",
      city: "South Hill",
      zip_code: "23970",
      state_id: "VA",
      state_name: "Virginia",
    },
    {
      city_id: "7053",
      city: "Sperryville",
      zip_code: "22740",
      state_id: "VA",
      state_name: "Virginia",
    },
    {
      city_id: "6986",
      city: "Spotsylvania",
      zip_code: "22551",
      state_id: "VA",
      state_name: "Virginia",
    },
    {
      city_id: "7714",
      city: "Spout Spring",
      zip_code: "24593",
      state_id: "VA",
      state_name: "Virginia",
    },
    {
      city_id: "7403",
      city: "Spring Grove",
      zip_code: "23881",
      state_id: "VA",
      state_name: "Virginia",
    },
    {
      city_id: "6911",
      city: "Springfield",
      zip_code: "22150",
      state_id: "VA",
      state_name: "Virginia",
    },
    {
      city_id: "6988",
      city: "Stafford",
      zip_code: "22554",
      state_id: "VA",
      state_name: "Virginia",
    },
    {
      city_id: "7528",
      city: "Staffordsville",
      zip_code: "24167",
      state_id: "VA",
      state_name: "Virginia",
    },
    {
      city_id: "7123",
      city: "Stanardsville",
      zip_code: "22973",
      state_id: "VA",
      state_name: "Virginia",
    },
    {
      city_id: "7086",
      city: "Stanley",
      zip_code: "22851",
      state_id: "VA",
      state_name: "Virginia",
    },
    {
      city_id: "7018",
      city: "Star Tannery",
      zip_code: "22654",
      state_id: "VA",
      state_name: "Virginia",
    },
    {
      city_id: "7207",
      city: "State Farm",
      zip_code: "23160",
      state_id: "VA",
      state_name: "Virginia",
    },
    {
      city_id: "7621",
      city: "Staunton",
      zip_code: "24401",
      state_id: "VA",
      state_name: "Virginia",
    },
    {
      city_id: "7655",
      city: "Steeles Tavern",
      zip_code: "24476",
      state_id: "VA",
      state_name: "Virginia",
    },
    {
      city_id: "7019",
      city: "Stephens City",
      zip_code: "22655",
      state_id: "VA",
      state_name: "Virginia",
    },
    {
      city_id: "7020",
      city: "Stephenson",
      zip_code: "22656",
      state_id: "VA",
      state_name: "Virginia",
    },
    {
      city_id: "6362",
      city: "Sterling",
      zip_code: "20164",
      state_id: "VA",
      state_name: "Virginia",
    },
    {
      city_id: "7054",
      city: "Stevensburg",
      zip_code: "22741",
      state_id: "VA",
      state_name: "Virginia",
    },
    {
      city_id: "7404",
      city: "Stony Creek",
      zip_code: "23882",
      state_id: "VA",
      state_name: "Virginia",
    },
    {
      city_id: "7010",
      city: "Strasburg",
      zip_code: "22641",
      state_id: "VA",
      state_name: "Virginia",
    },
    {
      city_id: "7656",
      city: "Stuarts Draft",
      zip_code: "24477",
      state_id: "VA",
      state_name: "Virginia",
    },
    {
      city_id: "7292",
      city: "Suffolk",
      zip_code: "23432",
      state_id: "VA",
      state_name: "Virginia",
    },
    {
      city_id: "7055",
      city: "Sumerduck",
      zip_code: "22742",
      state_id: "VA",
      state_name: "Virginia",
    },
    {
      city_id: "7405",
      city: "Surry",
      zip_code: "23883",
      state_id: "VA",
      state_name: "Virginia",
    },
    {
      city_id: "7407",
      city: "Sutherland",
      zip_code: "23885",
      state_id: "VA",
      state_name: "Virginia",
    },
    {
      city_id: "7715",
      city: "Sutherlin",
      zip_code: "24594",
      state_id: "VA",
      state_name: "Virginia",
    },
    {
      city_id: "7657",
      city: "Swoope",
      zip_code: "24479",
      state_id: "VA",
      state_name: "Virginia",
    },
    {
      city_id: "7056",
      city: "Syria",
      zip_code: "22743",
      state_id: "VA",
      state_name: "Virginia",
    },
    {
      city_id: "6990",
      city: "Tappahannock",
      zip_code: "22560",
      state_id: "VA",
      state_name: "Virginia",
    },
    {
      city_id: "6379",
      city: "The Plains",
      zip_code: "20198",
      state_id: "VA",
      state_name: "Virginia",
    },
    {
      city_id: "7087",
      city: "Timberville",
      zip_code: "22853",
      state_id: "VA",
      state_name: "Virginia",
    },
    {
      city_id: "7022",
      city: "Toms Brook",
      zip_code: "22660",
      state_id: "VA",
      state_name: "Virginia",
    },
    {
      city_id: "7211",
      city: "Topping",
      zip_code: "23169",
      state_id: "VA",
      state_name: "Virginia",
    },
    {
      city_id: "6915",
      city: "Triangle",
      zip_code: "22172",
      state_id: "VA",
      state_name: "Virginia",
    },
    {
      city_id: "7124",
      city: "Troy",
      zip_code: "22974",
      state_id: "VA",
      state_name: "Virginia",
    },
    {
      city_id: "7125",
      city: "Tyro",
      zip_code: "22976",
      state_id: "VA",
      state_name: "Virginia",
    },
    {
      city_id: "6991",
      city: "Unionville",
      zip_code: "22567",
      state_id: "VA",
      state_name: "Virginia",
    },
    {
      city_id: "6372",
      city: "Upperville",
      zip_code: "20184",
      state_id: "VA",
      state_name: "Virginia",
    },
    {
      city_id: "7213",
      city: "Urbanna",
      zip_code: "23175",
      state_id: "VA",
      state_name: "Virginia",
    },
    {
      city_id: "7408",
      city: "Valentines",
      zip_code: "23887",
      state_id: "VA",
      state_name: "Virginia",
    },
    {
      city_id: "7743",
      city: "Vansant",
      zip_code: "24656",
      state_id: "VA",
      state_name: "Virginia",
    },
    {
      city_id: "7717",
      city: "Vernon Hill",
      zip_code: "24597",
      state_id: "VA",
      state_name: "Virginia",
    },
    {
      city_id: "7658",
      city: "Verona",
      zip_code: "24482",
      state_id: "VA",
      state_name: "Virginia",
    },
    {
      city_id: "7659",
      city: "Vesuvius",
      zip_code: "24483",
      state_id: "VA",
      state_name: "Virginia",
    },
    {
      city_id: "7452",
      city: "Victoria",
      zip_code: "23974",
      state_id: "VA",
      state_name: "Virginia",
    },
    {
      city_id: "6916",
      city: "Vienna",
      zip_code: "22180",
      state_id: "VA",
      state_name: "Virginia",
    },
    {
      city_id: "7718",
      city: "Virgilina",
      zip_code: "24598",
      state_id: "VA",
      state_name: "Virginia",
    },
    {
      city_id: "7214",
      city: "Wake",
      zip_code: "23176",
      state_id: "VA",
      state_name: "Virginia",
    },
    {
      city_id: "7409",
      city: "Wakefield",
      zip_code: "23888",
      state_id: "VA",
      state_name: "Virginia",
    },
    {
      city_id: "7410",
      city: "Warfield",
      zip_code: "23889",
      state_id: "VA",
      state_name: "Virginia",
    },
    {
      city_id: "6373",
      city: "Warrenton",
      zip_code: "20186",
      state_id: "VA",
      state_name: "Virginia",
    },
    {
      city_id: "6992",
      city: "Warsaw",
      zip_code: "22572",
      state_id: "VA",
      state_name: "Virginia",
    },
    {
      city_id: "7058",
      city: "Washington",
      zip_code: "22747",
      state_id: "VA",
      state_name: "Virginia",
    },
    {
      city_id: "7216",
      city: "Water View",
      zip_code: "23180",
      state_id: "VA",
      state_name: "Virginia",
    },
    {
      city_id: "6378",
      city: "Waterford",
      zip_code: "20197",
      state_id: "VA",
      state_name: "Virginia",
    },
    {
      city_id: "7411",
      city: "Waverly",
      zip_code: "23890",
      state_id: "VA",
      state_name: "Virginia",
    },
    {
      city_id: "7126",
      city: "Waynesboro",
      zip_code: "22980",
      state_id: "VA",
      state_name: "Virginia",
    },
    {
      city_id: "6993",
      city: "Weems",
      zip_code: "22576",
      state_id: "VA",
      state_name: "Virginia",
    },
    {
      city_id: "7661",
      city: "West Augusta",
      zip_code: "24485",
      state_id: "VA",
      state_name: "Virginia",
    },
    {
      city_id: "7662",
      city: "Weyers Cave",
      zip_code: "24486",
      state_id: "VA",
      state_name: "Virginia",
    },
    {
      city_id: "7413",
      city: "White Plains",
      zip_code: "23893",
      state_id: "VA",
      state_name: "Virginia",
    },
    {
      city_id: "7023",
      city: "White Post",
      zip_code: "22663",
      state_id: "VA",
      state_name: "Virginia",
    },
    {
      city_id: "6994",
      city: "White Stone",
      zip_code: "22578",
      state_id: "VA",
      state_name: "Virginia",
    },
    {
      city_id: "7414",
      city: "Wilsons",
      zip_code: "23894",
      state_id: "VA",
      state_name: "Virginia",
    },
    {
      city_id: "6996",
      city: "Winchester",
      zip_code: "22601",
      state_id: "VA",
      state_name: "Virginia",
    },
    {
      city_id: "7316",
      city: "Windsor",
      zip_code: "23487",
      state_id: "VA",
      state_name: "Virginia",
    },
    {
      city_id: "7719",
      city: "Wingina",
      zip_code: "24599",
      state_id: "VA",
      state_name: "Virginia",
    },
    {
      city_id: "6919",
      city: "Woodbridge",
      zip_code: "22191",
      state_id: "VA",
      state_name: "Virginia",
    },
    {
      city_id: "6995",
      city: "Woodford",
      zip_code: "22580",
      state_id: "VA",
      state_name: "Virginia",
    },
    {
      city_id: "7024",
      city: "Woodstock",
      zip_code: "22664",
      state_id: "VA",
      state_name: "Virginia",
    },
    {
      city_id: "7059",
      city: "Woodville",
      zip_code: "22749",
      state_id: "VA",
      state_name: "Virginia",
    },
    {
      city_id: "7453",
      city: "Wylliesburg",
      zip_code: "23976",
      state_id: "VA",
      state_name: "Virginia",
    },
    {
      city_id: "7415",
      city: "Yale",
      zip_code: "23897",
      state_id: "VA",
      state_name: "Virginia",
    },
    {
      city_id: "7416",
      city: "Zuni",
      zip_code: "23898",
      state_id: "VA",
      state_name: "Virginia",
    },
  ],
};
