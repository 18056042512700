import { Container } from 'react-bootstrap';
import { css } from '@emotion/css';
import { Link } from 'react-router-dom';
import { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const Header = () => {
    const [showNav, setShowNav] = useState(false);
    return (
        <div
            className={css`
                border-bottom: 1px solid #ddd;
            `}
        >
            <Container>
                <div
                    className={css`
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        height: 80px;
                        a {
                            text-decoration: none;
                        }
                        span {
                            margin-left: 4px;
                            font-size: 12px;
                            color: #999;
                        }
                    `}
                >
                    {/* Logo with Link */}
                    <Link to="/">
                        <img src="/images/logo.png" height="50" alt="Logo" />
                    </Link>

                    {/* Navigation links */}
                    <div className="d-none align-items-center justify-content-center d-md-flex d-lg-flex d-xl-flex">
                        {/* Service Areas */}
                        <Link to="/service_areas" className="nav">
                            Service Areas
                        </Link>
                        {/* About Us */}
                        <Link to="/about_us" className="nav">
                            About Us
                        </Link>
                        {/* Contact Us */}
                        <Link to="/contact-us" className="nav">
                            Contact Us
                        </Link>
                        {/* FAQs */}
                        <Link to="/faqs" className="nav">
                            FAQs
                        </Link>
                    </div>
                    {/* Additional nav links or language options (commented out) */}
                    {/* <div className="d-none align-items-center justify-content-center d-md-flex d-lg-flex d-xl-flex">
                        <Link
                            to={i18n.language === 'en' ? `${path}/shopping_tips/` : `${path}/shopping_tips_cn/`}
                            className="nav"
                        >
                            {t('Shopping Tips')}
                        </Link>
                    </div> */}
                    {/* Mobile menu toggle button (commented out) */}
                    <div className="d-block d-md-none">
                        <button
                            className={css`
                                border: 0 none;
                                background: transparent;
                                cursor: pointer;
                            `}
                            onClick={() => {
                                setShowNav(!showNav);
                            }}
                        >
                            <FontAwesomeIcon icon="bars" style={{ fontSize: 20, color: '#666' }} />
                        </button>
                    </div>
                </div>
            </Container>
            {/* Mobile navigation menu (commented out) */}
            {showNav && (
                <div
                    className={css`
                        position: fixed;
                        top: 0;
                        right: 0;
                        bottom: 0;
                        left: 0;
                        background: #fff;
                        z-index: 999;
                    `}
                >
                    <div
                        className={css`
                            display: flex;
                            flex-direction: column;
                            align-items: center;
                            justify-content: center;
                            height: 100%;
                        `}
                    >
                        {/* <Link to="/service_areas" className="nav">
                            Service Areas
                        </Link> */}
                        {/* About Us */}
                        <Link to="/about_us" className="nav">
                            About Us
                        </Link>
                        {/* Contact Us */}
                        <Link to="/contact-us" className="nav">
                            Contact Us
                        </Link>
                        {/* FAQs */}
                        <Link to="/faqs" className="nav">
                            FAQs
                        </Link>
                    </div>
                    <button
                        className={css`
                            display: inline-block;
                            position: absolute;
                            top: 16px;
                            right: 10px;
                            border: 0 none;
                            background: transparent;
                            cursor: pointer;
                            z-index: 100;
                        `}
                        onClick={() => setShowNav(false)}
                    >
                        <FontAwesomeIcon icon="times-circle" style={{ color: '#aaa', fontSize: 36 }} />
                    </button>
                </div>
            )}
        </div>
    );
};

export default Header;
