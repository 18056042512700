import React, { useEffect, useState } from "react";
import { Box, Grid, Typography } from "@mui/material";
import { ProviderType } from "../../types";
import { convertToKebabCase } from "../../utils/helper";
import { Link } from "react-router-dom"

const CompareOthersSection = ({
  targetProvider,
  providerList,
}: {
  targetProvider: ProviderType;
  providerList: ProviderType[];
}) => {
  const [list, setList] = useState<ProviderType[]>([]);

  useEffect(() => {
    if (!targetProvider || providerList.length === 0) return;

    const filteredProviderList = providerList.filter(
      (provider: ProviderType) =>
        provider.provider_id !== targetProvider.provider_id
    );

    const numValues = Math.min(filteredProviderList.length, 20);
    const randomIndices = getRandomIndices(filteredProviderList, numValues);
    const randomItems = getRandomValuesByIndices(
      filteredProviderList,
      randomIndices
    );
    setList(randomItems);
  }, [targetProvider, providerList]);

  function getRandomIndices(arr: ProviderType[], numValues: number): number[] {
    const indices: Set<number> = new Set();
    while (indices.size < numValues) {
      const randomIndex = Math.floor(Math.random() * arr.length);
      indices.add(randomIndex);
    }
    return Array.from(indices);
  }

  function getRandomValuesByIndices(arr: ProviderType[], indices: number[]) {
    return indices.map((index) => arr[index]);
  }

  if (!targetProvider || providerList.length === 0) return null;

  return (
    <>
      <Typography
        variant="h3"
        textAlign="center"
        sx={{ fontSize: "1.5rem", fontWeight: 500 }}
      >
        Other Top Energy Providers to Compare
      </Typography>
      <Box mt={3}>
        <Grid container columnSpacing={3} rowSpacing={1}>
          {list.map((item: ProviderType) => {
            const providerName1 = targetProvider.show_name
              ? convertToKebabCase(targetProvider.show_name)
              : convertToKebabCase(targetProvider.provider_name);
            const providerName2 = item.show_name
              ? convertToKebabCase(item.show_name)
              : convertToKebabCase(item.provider_name);
            const currentUrl = window.location.href || "";
            const url = currentUrl.replace(
              /\/[^/]*$/,
              `/${providerName1}-vs-${providerName2}`
            );

            return (
              <Grid item xs={12} sm={6} key={item.provider_id}>
                <Link
                  to={url}
                  style={{
                    fontSize: '0.825rem',
                    textDecoration: 'none',
                    opacity: 0.7,
                  }}
                >{`${targetProvider.show_name || targetProvider.provider_name
                  } vs. ${item.show_name || item.provider_name}`}</Link>
              </Grid>
            );
          })}
        </Grid>
      </Box>
    </>
  );
};

export default CompareOthersSection;
