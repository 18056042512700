export const providerDescriptions = [
  {
    business_name: "174 POWER GLOBAL RETAIL TEXAS LLC (LSE)",
    show_name: "174 POWER",
    description:
      "174 POWER is a leading provider of clean energy solutions, focusing on sustainable and innovative electricity services. With a commitment to renewable energy and tailored plans, 174 POWER is a top choice for eco-friendly electricity in Houston. Located at 2800 Post Oak Blvd Suite 4100, HOUSTON, TX, 77056.",
    provider_id: "",
  },
  {
    business_name: "3000 ENERGY CORP DBA PENSTAR POWER (LSE)",
    show_name: "3000 ENERGY",
    description:
      "3000 ENERGY specializes in electricity retail, offering high-quality, reliable energy services at competitive rates. Their variety of energy plans caters to both residential and commercial customers, enhancing satisfaction and transparency. Visit 3000 ENERGY at 3000 Altamesa Blvd. Ste. 300, FORT WORTH, TX, 76133.",
    provider_id: "",
  },
  {
    business_name: "4CHANGE ENERGY COMPANY",
    show_name: "4CHANGE ENERGY",
    description:
      "4CHANGE ENERGY is known for its affordability and customer-centric approach, providing straightforward electricity plans free of hidden fees. Their competitive pricing and innovative services make 4CHANGE ENERGY a preferred choice for many consumers. Find them at 200 W John Carpenter Fwy, Suite 1240, Irving, TX, 75039.",
    provider_id: "10",
  },
  {
    business_name: "ACACIA ENERGY",
    show_name: "ACACIA ENERGY",
    description:
      "ACACIA ENERGY delivers innovative and reliable energy solutions with a focus on sustainability and customer satisfaction. Their flexible plans cater to various energy needs while promoting environmental responsibility. Visit ACACIA ENERGY at 11011 Brooklet Dr Suite 220, Houston, TX, 77099.",
    provider_id: "",
  },
  {
    business_name: "ACCENT ENERGY TEXAS LP DBA IGS ENERGY (LSE)",
    show_name: "ACCENT ENERGY",
    description:
      "ACCENT ENERGY specializes in competitive electricity and natural gas services, offering customized energy solutions to residents and businesses. Their focus on cost efficiency and reliability enhances the customer experience. Find ACCENT ENERGY at 6100 Emerald Parkway, DUBLIN, OH, 43016.",
    provider_id: "",
  },
  {
    business_name: "ALLIANCE POWER COMPANY",
    show_name: "ALLIANCE POWER",
    description:
      "ALLIANCE POWER provides top-notch electric power solutions to both residential and commercial clients. With an emphasis on reliability and excellence, ALLIANCE POWER supports green energy projects while ensuring efficient service. Visit them at 811 6th Street, Suite 205, Wichita Falls, TX, 76301.",
    provider_id: "",
  },
  {
    business_name: "ALMIKA SOLAR",
    show_name: "ALMIKA SOLAR",
    description:
      "ALMIKA SOLAR specializes in solar energy, offering comprehensive systems tailored to customer needs. Their commitment to clean energy provides eco-friendly alternatives that reduce carbon footprints and energy costs. Find ALMIKA SOLAR at 850 Central Pkwy East Suite 160, PLANO, TX, 75074.",
    provider_id: "265",
  },
  {
    business_name: "AMBER ENERGY SERVICES LLC (LSE)",
    show_name: "AMBER ENERGY",
    description:
      "AMBER ENERGY delivers innovative energy solutions tailored to diverse market needs. They focus on sustainable practices and green technology integration to optimize efficiency. Visit AMBER ENERGY at 20 Greenway Plaza Ste 650, HOUSTON, TX, 77046.",
    provider_id: "",
  },
  {
    business_name: "AMBIT ENERGY L.P.",
    show_name: "AMBIT ENERGY",
    description:
      "As a leading retail energy provider, AMBIT ENERGY offers electricity and natural gas services with a focus on customer satisfaction, flexible plans, and competitive rates. Discover energy conservation with AMBIT ENERGY at 1801 N Lamar Ste 200, Dallas, TX, 75202.",
    provider_id: "187",
  },
  {
    business_name: "AMBIT TEXAS LLC",
    show_name: "AMBIT",
    description:
      "AMBIT Texas delivers reliable energy solutions tailored to residents’ needs, emphasizing excellent customer service, transparent billing, and innovative options. Visit AMBIT at 6555 Sierra Dr, IRVING, TX, 75039.",
    provider_id: "187",
  },
  {
    business_name: "AMERICAN LP CO",
    show_name: "AMERICAN",
    description:
      "AMERICAN LP Company offers a variety of liquefied petroleum products with a strong emphasis on safety and customer needs. They deliver tailored energy solutions for both residential and commercial customers. Find them at 846 NORTH LOOP, HOUSTON, TX, 77096.",
    provider_id: "203",
  },
  {
    business_name: "AMERICAN POWERNET MANAGEMENT LP (LSE)",
    show_name: "AMERICAN POWERNET",
    description:
      "AMERICAN POWERNET specializes in innovative energy management services, focusing on demand response and sustainability solutions for businesses. Discover efficient energy solutions with AMERICAN POWERNET at 45 Commerce Drive, WYOMISSING, PA, 19610.",
    provider_id: "",
  },
  {
    business_name: "AMERIPOWER LLC",
    show_name: "AMERIPOWER",
    description:
      "AMERIPOWER is a trusted energy supplier offering competitive electricity rates tailored to residential and business needs. Their dedication to customer satisfaction ensures reliable and efficient energy solutions. Visit AMERIPOWER at 1600 TX-6 #440, Sugar Land, TX, 77478.",
    provider_id: "",
  },
  {
    business_name: "AMIGO ENERGY",
    show_name: "AMIGO ENERGY",
    description:
      "AMIGO ENERGY is a dynamic provider of affordable electricity plans paired with exceptional customer service. They offer flexible plans and renewable energy options to help customers save. Find AMIGO ENERGY at 5251 Westheimer Rd, HOUSTON, TX, 77056.",
    provider_id: "175",
  },
  {
    business_name: "AMMPER POWER LLC (LSE)",
    show_name: "AMMPER POWER",
    description:
      "AMMPER POWER provides comprehensive energy solutions, including procurement and renewable energy integration, empowering businesses with sustainable strategies. Visit them at 401 N Franklin Street Suite 2400-115, HOUSTON, TX, 77201.",
    provider_id: "",
  },
  {
    business_name: "AMPRA ENERGY",
    show_name: "AMPRA ENERGY",
    description:
      "AMPRA ENERGY delivers innovative electricity solutions with a commitment to reliability and sustainability. Their competitive rates make them a trusted partner for residential and commercial energy needs. Find AMPRA ENERGY at 850 Central Pkwy East Suite 160, PLANO, TX, 75074.",
    provider_id: "247",
  },
  {
    business_name: "ANDELER POWER",
    show_name: "ANDELER POWER",
    description:
      "ANDELER POWER specializes in efficient and sustainable power solutions, prioritizing renewable energy and customer-centric services. They offer reliable electricity for residential and commercial needs. Visit ANDELER POWER at 7335 82nd St Ste. 6, Lubbock, TX, 79424.",
    provider_id: "",
  },
  {
    business_name: "AP GAS AND ELECTRIC (TX) LLC DBA APG AND E (LSE)",
    show_name: "AP GAS AND ELECTRIC",
    description:
      "AP GAS AND ELECTRIC offers natural gas and electricity services, known for exceptional customer service and commitment to clean energy. They provide reliable energy options for residential and commercial sectors. Find them at 6161 Savoy Drive Suite 500, HOUSTON, TX, 77036.",
    provider_id: "",
  },
  {
    business_name: "APG AND E",
    show_name: "APG AND E",
    description:
      "APG AND E is a reputable provider of high-quality natural gas and electricity services with a customer-focused approach. Explore their competitive rates and sustainable solutions at 6161 Savoy Drive Suite 500, HOUSTON, TX, 77036.",
    provider_id: "",
  },
  {
    business_name: "APN STARFIRST LP",
    show_name: "APN STARFIRST",
    description:
      "APN STARFIRST specializes in innovative electricity and natural gas solutions, emphasizing sustainability and exceptional customer service. They cater to both residential and commercial needs. Visit APN STARFIRST at 45 Commerce Drive, WYOMISSING, PA, 19610.",
    provider_id: "",
  },
  {
    business_name: "APNA HOLDINGS DBA APNA ENERGY",
    show_name: "APNA HOLDINGS DBA APNA ENERGY",
    description:
      "APNA ENERGY offers affordable, reliable energy solutions tailored for residential, commercial, and industrial needs. Their commitment to customer service and energy efficiency makes them an exceptional choice. Find APNA ENERGY at 7322 Southwest Freeway Suite 730, Houston, TX, 77074.",
    provider_id: "",
  },
  {
    business_name: "APOLLO POWER  LIGHT",
    show_name: "APOLLO POWER LIGHT",
    description:
      "APOLLO POWER LIGHT is known for innovative energy solutions, offering a range of electricity plans focused on renewable sources and customer satisfaction. Visit them at 4809 Cole Ave Suite 108, Dallas, TX, 75205.",
    provider_id: "",
  },
  {
    business_name: "ARROW ENERGY TX LLC (LSE)",
    show_name: "ARROW ENERGY",
    description:
      "ARROW ENERGY specializes in comprehensive energy solutions for residential and commercial customers, focusing on sustainability and efficient power delivery. Discover reliable energy at ARROW ENERGY, located at 144 N 7th St #417, BROOKLYN, NY, 11211.",
    provider_id: "282",
  },
  {
    business_name: "ASSURANCE ENERGY",
    show_name: "ASSURANCE ENERGY",
    description:
      "ASSURANCE ENERGY offers sustainable and reliable energy solutions tailored for both residential and commercial clients. Their innovative technologies foster energy independence and efficiency. Find ASSURANCE ENERGY at 6555 Sierra Drive, IRVING, TX, 75039.",
    provider_id: "",
  },
  {
    business_name: "ATG CLEAN ENERGY HOLDINGS INC.",
    show_name: "ATG CLEAN ENERGY",
    description:
      "ATG CLEAN ENERGY specializes in renewable energy solutions, focusing on clean electricity generation and distribution to minimize carbon footprints. Visit ATG CLEAN ENERGY at 4404 Aqua Verde Dr, Austin, TX, 78746.",
    provider_id: "227",
  },
  {
    business_name: "AXON POWER AND GAS LLC",
    show_name: "AXON POWER AND GAS",
    description:
      "AXON POWER AND GAS is an innovative provider of reliable electricity and gas services, emphasizing customer satisfaction and sustainability. Find them at 2331 W Northwest Hwy SUITE 200, Dallas, TX, 75220.",
    provider_id: "",
  },
  {
    business_name: "AXPO US LLC (LSE)",
    show_name: "AXPO US",
    description:
      "AXPO US specializes in comprehensive energy solutions with an emphasis on reliable power generation and distribution. Visit AXPO US for cost-efficient, environmentally-friendly electricity at 575 5th Ave Suite 2801, NEW YORK, NY, 10017.",
    provider_id: "",
  },
  {
    business_name: "BEYOND POWER",
    show_name: "BEYOND POWER",
    description:
      "BEYOND POWER is committed to delivering innovative and affordable electricity to residential and commercial customers, enhancing energy efficiency through advanced technologies. Find them at PO BOX 181, BEASLEY, TX, 77417.",
    provider_id: "",
  },
  {
    business_name: "BKV BPP RETAIL LLC (LSE)",
    show_name: "BKV BPP",
    description:
      "BKV BPP specializes in retail energy services, providing reliable electricity solutions with a strong commitment to sustainability and affordability. Visit BKV BPP at 1200 17th St Ste 2100, DENVER, CO, 80202.",
    provider_id: "",
  },
  {
    business_name: "BLUE STAR ENERGY LLC",
    show_name: "BLUE STAR ENERGY",
    description:
      "BLUE STAR ENERGY offers reliable and sustainable electricity services, providing innovative energy solutions for residential and commercial customers. Find BLUE STAR ENERGY at 1 Easton Oval Suite 200, COLUMBUS, OH, 43219.",
    provider_id: "",
  },
  {
    business_name: "BOUNCE ENERGY",
    show_name: "BOUNCE ENERGY",
    description:
      "BOUNCE ENERGY is a customer-focused provider of reliable energy solutions suitable for residential and commercial clients. Their commitment to sustainability drives them to deliver cost-effective solutions. Visit BOUNCE ENERGY at 2802 Albany St, Houston, TX, 77006.",
    provider_id: "",
  },
  {
    business_name: "BP ENERGY COMPANY (LSE)",
    show_name: "BP ENERGY COMPANY",
    description:
      "BP ENERGY COMPANY is renowned for comprehensive energy services, focusing on reliable electricity and gas supply that aligns with sustainability goals. Find them at 201 Helios Way, HOUSTON, TX, 77079.",
    provider_id: "",
  },
  {
    business_name: "BP ENERGY HOLDING COMPANY LLC (LSE)",
    show_name: "BP ENERGY HOLDING COMPANY",
    description:
      "BP ENERGY HOLDING COMPANY specializes in advanced energy solutions, delivering reliable and cost-effective electricity services for all customer needs. Visit them at 201 Helios Way, HOUSTON, TX, 77079.",
    provider_id: "",
  },
  {
    business_name: "BP ENERGY RETAIL COMPANY LLC",
    show_name: "BP ENERGY RETAIL COMPANY",
    description:
      "BP ENERGY RETAIL COMPANY focuses on innovative energy solutions, providing comprehensive services tailored to residential and commercial clients with a commitment to sustainability. Find them at 201 Helios Way Helios Plaza, Houston, TX, 77079.",
    provider_id: "",
  },
  {
    business_name: "BRANCH ENERGY (TEXAS) LLC (LSE)",
    show_name: "BRANCH ENERGY",
    description:
      "BRANCH ENERGY is a leading Texas-based provider of reliable and sustainable power solutions, leveraging innovative technologies for both residential and commercial energy needs. Visit BRANCH ENERGY at 2700 Post Oak Blvd, Galleria Office Tower I, Floor 21, HOUSTON, TX, 77056.",
    provider_id: "257",
  },
  {
    business_name: "BREEZE ENERGY LLC",
    show_name: "BREEZE ENERGY",
    description:
      "BREEZE ENERGY specializes in clean, renewable wind energy, providing eco-friendly solutions for Texas residents. They focus on sustainability and reducing carbon footprints, making them a preferred choice for green energy. Visit BREEZE ENERGY at PO Box 600998 Suite 1400, Dallas, TX, 75360.",
    provider_id: "",
  },
  {
    business_name: "BRILLIANT ENERGY LLC",
    show_name: "BRILLIANT ENERGY",
    description:
      "BRILLIANT ENERGY is known for customizable electricity plans that fit diverse customer needs. Their competitive pricing and excellent customer service make them a strong market competitor. Visit BRILLIANT ENERGY at 800 Wilcrest Dr, Suite 109, Houston, TX, 77042.",
    provider_id: "217",
  },
  {
    business_name: "BROAD REACH POWER ENERGY SERVICES",
    show_name: "BROAD REACH",
    description:
      "BROAD REACH is an innovative company focused on utility-scale battery storage and renewable energy projects, dedicated to a sustainable energy transition. Find BROAD REACH at Three Allen Center, 333 Clay St. Suite 2800, HOUSTON, TX, 77002.",
    provider_id: "",
  },
  {
    business_name: "BROOKLET ENERGY DISTRIBUTION LLC DBA ACACIA ENERGY (LSE)",
    show_name: "BROOKLET ENERGY",
    description:
      "BROOKLET ENERGY provides pre-paid energy plans under the Acacia Energy brand, focusing on affordable and convenient solutions with a commitment to customer satisfaction. Visit them at 11011 Brooklet Drive Suite 220, HOUSTON, TX, 77099.",
    provider_id: "",
  },
  {
    business_name: "BULB ENERGY LLC",
    show_name: "BULB ENERGY",
    description:
      "Bulb Energy is dedicated to providing affordable renewable energy solutions to Texans with competitive rates and straightforward electricity plans, ensuring transparency in billing and excellent customer support.  ",
    provider_id: "236",
  },
  {
    business_name: "CALPINE ENERGY SOLUTIONS LLC",
    show_name: "CALPINE ENERGY",
    description:
      "Calpine Energy leads the way in offering comprehensive energy management and natural gas solutions tailored for commercial and industrial clients, emphasizing sustainability and efficiency.  ",
    provider_id: "",
  },
  {
    business_name: "CALPINE POWER AMERICA LLC",
    show_name: "CALPINE POWER",
    description:
      "Calpine Power specializes in wholesale energy solutions, providing reliable electricity and natural gas services to commercial and industrial sectors with a focus on operational efficiency.  ",
    provider_id: "",
  },
  {
    business_name: "CAP ENG PA LLC DBA VALUE POWER",
    show_name: "CAP ENG PA",
    description:
      "Cap Eng PA delivers electricity under the Value Power brand, offering a variety of affordable and dependable energy plans that cater to the diverse needs of consumers.  ",
    provider_id: "",
  },
  {
    business_name: "CHAMPION ENERGY IND SRVS I",
    show_name: "CHAMPION ENERGY",
    description:
      "Champion Energy stands out as a top retail electricity provider for large industrial clients, emphasizing competitive rates and custom energy solutions for efficient management.  ",
    provider_id: "183",
  },
  {
    business_name: "CHARIOT ENERGY",
    show_name: "CHARIOT ENERGY",
    description:
      "Chariot Energy is committed to making solar energy accessible and affordable for Texans, offering understandable plans and outstanding customer support for a sustainable future.  ",
    provider_id: "186",
  },
  {
    business_name: "CIRRO ENERGY",
    show_name: "CIRRO ENERGY",
    description:
      "Cirro Energy is a trusted provider of diverse electricity plans for Texas residents, known for competitive pricing and a strong dedication to customer satisfaction.  ",
    provider_id: "165",
  },
  {
    business_name: "CLEANSKY ENERGY",
    show_name: "CLEANSKY ENERGY",
    description:
      "CleanSky Energy specializes in renewable electricity plans that support sustainability and transparency, providing customers with eco-friendly energy solutions at competitive rates.  ",
    provider_id: "230",
  },
  {
    business_name: "CLEARVIEW ELECTRIC INC",
    show_name: "CLEARVIEW ELECTRIC",
    description:
      "Clearview Electric offers straightforward electricity plans prioritizing transparency and customer service, helping consumers easily manage their energy expenses.  ",
    provider_id: "",
  },
  {
    business_name: "CLYDE ENERGY",
    show_name: "CLYDE ENERGY",
    description:
      "Clyde Energy focuses on providing eco-friendly energy solutions and personalized service, aiming to meet customer needs while promoting a sustainable lifestyle.  ",
    provider_id: "",
  },
  {
    business_name: "CON EDISON SOLUTIONS",
    show_name: "CON EDISON SOLUTIONS",
    description:
      "Con Edison Solutions is a national energy service provider delivering innovative electricity and natural gas solutions, committed to reliability and efficiency.  ",
    provider_id: "",
  },
  {
    business_name: "CONOCOPHILLIPS COMPANY",
    show_name: "CONOCOPHILLIPS COMPANY",
    description:
      "ConocoPhillips Company is a leading energy powerhouse, offering comprehensive solutions in oil, natural gas, and electricity with a strong commitment to sustainability.  ",
    provider_id: "",
  },
  {
    business_name: "CONSERVICE ENERGY",
    show_name: "CONSERVICE ENERGY",
    description:
      "Conservice Energy provides tailored energy management services that enhance efficiency and reduce costs, serving a wide range of residential, commercial, and industrial clients.  ",
    provider_id: "218",
  },
  {
    business_name: "CONSTELLATION NEWENERGY",
    show_name: "CONSTELLATION NEWENERGY",
    description:
      "Constellation NewEnergy is a prominent electricity provider committed to sustainability, offering renewable options alongside traditional plans for diverse customer needs.  ",
    provider_id: "196",
  },
  {
    business_name: "CPL RETAIL ENERGY LP",
    show_name: "CPL RETAIL ENERGY LP",
    description:
      "CPL Retail Energy LP focuses on offering reliable and affordable electricity services to Texas residents, ensuring straightforward pricing and quality service.  ",
    provider_id: "216",
  },
  {
    business_name: "DAVID ENERGY SUPPLY (TEXAS) LLC (LSE)",
    show_name: "DAVID ENERGY SUPPLY",
    description:
      "David Energy Supply offers innovative energy solutions with a strong focus on sustainability and customer service, catering to the evolving needs of Texas residents.  ",
    provider_id: "283",
  },
  {
    business_name: "DECLARATION ENERGY LLC DBA ENERGY TEXAS (LSE)",
    show_name: "DECLARATION ENERGY",
    description:
      "Declaration Energy is committed to providing affordable, reliable electricity services in Texas with flexible plans and a strong emphasis on customer satisfaction.  ",
    provider_id: "",
  },
  {
    business_name: "DEMAND CONTROL 2",
    show_name: "DEMAND CONTROL 2",
    description:
      "Demand Control 2 specializes in energy efficiency solutions, providing innovative technology and services to optimize energy consumption for both businesses and residents.  ",
    provider_id: "",
  },
  {
    business_name: "DIAMOND ENERGY TX LLC",
    show_name: "DIAMOND ENERGY",
    description:
      "Diamond Energy is a premier retail electricity provider dedicated to delivering reliable and affordable solutions, focusing on customer satisfaction and exceptional service.  ",
    provider_id: "",
  },
  {
    business_name: "DIRECT ENERGY",
    show_name: "DIRECT ENERGY",
    description:
      "Direct Energy is a well-established provider offering electricity, natural gas, and renewable options, known for competitive rates and innovative energy management tools.  ",
    provider_id: "163",
  },
  {
    business_name: "DIRECT ENERGY BUSINESS LLC 2 (LSE)",
    show_name: "DIRECT ENERGY BUSINESS LLC 2",
    description:
      "Direct Energy Business LLC 2 provides tailored energy solutions to businesses, focusing on reducing energy costs and achieving sustainability through customized services.  ",
    provider_id: "163",
  },
  {
    business_name: "DIRECT ENERGY BUSINESS SERVICES",
    show_name: "DIRECT ENERGY BUSINESS SERVICES",
    description:
      "Direct Energy Business Services offers comprehensive energy solutions to commercial clients, including energy management and sustainability consulting for optimized efficiency.  ",
    provider_id: "163",
  },
  {
    business_name: "DIRECT ENERGY LP DBA DIRECT ENERGY (LSE)",
    show_name: "DIRECT ENERGY LP DBA DIRECT ENERGY",
    description:
      "Direct Energy LP is a leading energy provider delivering innovative solutions and top-notch customer service to both residential and commercial customers.  ",
    provider_id: "163",
  },
  {
    business_name: "DIRECT ENERGY MASS MARKETS",
    show_name: "DIRECT ENERGY MASS MARKETS",
    description:
      "Direct Energy Mass Markets is focused on delivering dependable energy services to households and businesses, ensuring transparency and customer support.  ",
    provider_id: "163",
  },
  {
    business_name: "DISCOUNT POWER",
    show_name: "DISCOUNT POWER",
    description:
      "Discount Power specializes in low-cost electricity plans, committed to providing budget-friendly, reliable energy solutions backed by excellent customer service.  ",
    provider_id: "191",
  },
  {
    business_name: "DPI ENERGY LLC",
    show_name: "DPI ENERGY",
    description:
      "DPI Energy is dedicated to innovative energy management solutions, ensuring reliable power delivery while prioritizing sustainability and exceptional customer service.  ",
    provider_id: "",
  },
  {
    business_name: "EAGLE ENERGY",
    show_name: "EAGLE ENERGY",
    description:
      "Eagle Energy is a premier energy provider focused on delivering reliable electricity services and effective energy management solutions to commercial clients.  ",
    provider_id: "224",
  },
  {
    business_name: "EDF ENERGY SERVICES LLC",
    show_name: "EDF ENERGY SERVICES",
    description:
      "EDF Energy Services offers customized solutions for businesses seeking to enhance energy efficiency and sustainability, prioritizing client satisfaction in all services.  ",
    provider_id: "",
  },
  {
    business_name: "EIF CHANNELVIEW RETAIL ENERGY LLC (LSE)",
    show_name: "EIF CHANNELVIEW RETAIL ENERGY",
    description:
      "EIF Channelview Retail Energy is committed to transparency and exceptional customer care, providing reliable energy solutions that meet diverse client needs.  ",
    provider_id: "",
  },
  {
    business_name: "ELECTRANET POWER LLC",
    show_name: "ELECTRANET POWER",
    description:
      "Electranet Power delivers cutting-edge electricity services focused on reliability and customer satisfaction, catering to both residential and commercial clients.  ",
    provider_id: "",
  },
  {
    business_name: "ELECTRIC NOW",
    show_name: "ELECTRIC NOW",
    description:
      "Electric Now is a trusted provider of electricity services designed to meet the needs of residential and commercial customers with an emphasis on sustainability.  ",
    provider_id: "",
  },
  {
    business_name: "ELIGO ENERGY LLC",
    show_name: "ELIGO ENERGY",
    description:
      "Eligo Energy is a leading retail provider dedicated to delivering tailored energy solutions, prioritizing innovation and customer satisfaction in every aspect.  ",
    provider_id: "174",
  },
  {
    business_name: "ENCOA",
    show_name: "ENCOA",
    description:
      "Encoa provides customized electricity plans geared towards residential and commercial clients, focusing on customer satisfaction and eco-friendly practices.  ",
    provider_id: "",
  },
  {
    business_name: "ENEL TRADING NORTH AMERICA LLC (LSE)",
    show_name: "ENEL TRADING",
    description:
      "Enel Trading specializes in delivering flexible and reliable electricity services tailored to meet the diverse needs of both homes and businesses.  ",
    provider_id: "",
  },
  {
    business_name: "ENERGY PLUS HOLDINGS LLC",
    show_name: "ENERGY PLUS HOLDINGS",
    description:
      "Energy Plus Holdings focuses on providing reliable energy solutions to commercial clients, promoting cost efficiency and sustainability in their services.  ",
    provider_id: "",
  },
  {
    business_name: "ENERGY REWARDS",
    show_name: "ENERGY REWARDS",
    description:
      "Energy Rewards specializes in energy services for residential and commercial needs, offering dependable power solutions and exceptional customer support.  ",
    provider_id: "",
  },
  {
    business_name: "ENERGY TRANSFER RETAIL POWER",
    show_name: "ENERGY TRANSFER",
    description:
      "Energy Transfer is focused on delivering tailored retail energy services with a commitment to innovation and customer satisfaction for homes and businesses.  ",
    provider_id: "",
  },
  {
    business_name: "ENERTRADE ELECTRIC LLC",
    show_name: "ENERTRADE ELECTRIC",
    description:
      "Enertrade Electric offers customized energy solutions emphasizing customer service and efficiency to meet diverse residential and commercial needs.  ",
    provider_id: "",
  },
  {
    business_name: "ENGIE RESOURCES LLC",
    show_name: "ENGIE RESOURCES",
    description:
      "Engie Resources delivers reliable retail energy solutions and innovative management services, consistently prioritizing customer satisfaction in their offerings.  ",
    provider_id: "",
  },
  {
    business_name: "ENTRUST ENERGY",
    show_name: "ENTRUST ENERGY",
    description:
      "Entrust Energy is dedicated to providing superior energy solutions and reliable service, offering competitive rates to meet the needs of residential and commercial clients.  ",
    provider_id: "201",
  },
  {
    business_name: "EXPERT ENERGY",
    show_name: "EXPERT ENERGY",
    description:
      "Expert Energy specializes in effective and reliable electricity services, delivering innovative energy solutions and exceptional support to meet customer needs.  ",
    provider_id: "",
  },
  {
    business_name: "EXPRESS ENERGY",
    show_name: "EXPRESS ENERGY",
    description:
      "Express Energy focuses on providing efficient, tailored electricity solutions and outstanding customer service, catering to the needs of residential and commercial clients.",
    provider_id: "173",
  },
  {
    business_name: "FIRE FLY POWERED BY SPARK ENERGY",
    show_name: "FIRE FLY POWERED BY SPARK ENERGY",
    description:
      "FIRE FLY POWERED BY SPARK ENERGY is an innovative energy provider that offers tailored electricity solutions for homes and businesses. With a focus on sustainability and exceptional customer support, it enhances energy efficiency for a better tomorrow. Located at 12140 Wickchester Lane Suite 100, HOUSTON, TX, 77079.",
    provider_id: "208",
  },
  {
    business_name: "FIRST CHOICE POWER",
    show_name: "FIRST CHOICE POWER",
    description:
      "FIRST CHOICE POWER is a trusted energy provider delivering competitive electricity plans and exceptional customer service. Committed to sustainability and innovative energy solutions, they provide affordable, reliable, and eco-friendly power options suited to customer needs. Visit them at 12 Greenway Plaza Suite 250, HOUSTON, TX, 77046.",
    provider_id: "162",
  },
  {
    business_name: "FIRST CHOICE POWER LLC DBA FIRST CHOICE POWER POL (LSE)",
    show_name: "FIRST CHOICE POWER LLC DBA FIRST CHOICE POWER POL",
    description:
      "FIRST CHOICE POWER LLC DBA FIRST CHOICE POWER POL is a leading energy supplier dedicated to reliable electricity solutions for residential and commercial clients. Their innovative approach and commitment to customer satisfaction make them a preferred choice for efficient and affordable energy plans. Located at 12 Greenway Plaza Suite 250, HOUSTON, TX, 77046.",
    provider_id: "162",
  },
  {
    business_name: "FLAGSHIP POWER (LSE)",
    show_name: "FLAGSHIP POWER",
    description:
      "FLAGSHIP POWER is a premier electricity provider focused on delivering customizable and competitively priced power plans. Their customer-centric approach and dedication to eco-friendly solutions ensure clients receive reliable energy services that align with their sustainability goals. Find them at PO Box 130309, THE WOODLANDS, TX, 77393.",
    provider_id: "273",
  },
  {
    business_name: "FREEPOINT ENERGY SOLUTIONS",
    show_name: "FREEPOINT ENERGY SOLUTIONS",
    description:
      "FREEPOINT ENERGY SOLUTIONS is a dynamic energy company committed to efficient and innovative electricity services. They offer tailored plans designed for the diverse needs of residential and commercial customers, ensuring reliability and cost-effectiveness. Their location is 3050 Post Oak Blvd Ste 1330, HOUSTON, TX, 77056.",
    provider_id: "",
  },
  {
    business_name: "FRONTIER UTILITIES",
    show_name: "FRONTIER UTILITIES",
    description:
      "FRONTIER UTILITIES is a top-tier utility company known for personalized energy plans and superior customer support. They focus on delivering robust electricity solutions that cater to unique client needs, ensuring competitive rates and sustainable energy options. Visit them at 5444 Westheimer Road Suite 1100, Houston, TX, 77056.",
    provider_id: "167",
  },
  {
    business_name: "FRONTIER UTILITIES LLC DBA RODEO ENERGY (LSE)",
    show_name: "FRONTIER UTILITIES LLC DBA RODEO ENERGY",
    description:
      "FRONTIER UTILITIES LLC DBA RODEO ENERGY is dedicated to providing reliable and affordable electricity options for a variety of customer segments. Their flexible energy plans are designed to meet growing demands for sustainability, ensuring comprehensive support and satisfaction. Located at 601 Travis St. Suite 1400, HOUSTON, TX, 77002.",
    provider_id: "167",
  },
  {
    business_name: "FULCRUM RETAIL ENERGY LLC DBA AMIGO ENERGY (LSE)",
    show_name: "FULCRUM RETAIL ENERGY",
    description:
      "FULCRUM RETAIL ENERGY is known for delivering innovative, reliable, and affordable electricity. They provide a variety of energy plans customized for residential and commercial customers, prioritizing sustainability and quality service. Find them at 5251 Westheimer Rd, HOUSTON, TX, 77056.",
    provider_id: "",
  },
  {
    business_name: "GATEWAY ENERGY SERVICES",
    show_name: "GATEWAY ENERGY",
    description:
      "GATEWAY ENERGY is a premier provider of electricity and natural gas solutions, offering diverse plans for residential and commercial needs. The company is committed to sustainable energy practices and customer-focused service, delivering reliable power solutions while promoting energy efficiency. Their location is 400 Rella Boulevard, Montebello, NY, 10901.",
    provider_id: "",
  },
  {
    business_name: "GDF SUEZ RETAIL ENERGY SOLUTIONSLLC",
    show_name: "GDF SUEZ RETAIL ENERGY",
    description:
      "GDF SUEZ RETAIL ENERGY is a leading energy retailer providing electricity, natural gas, and energy management solutions. They focus on customer satisfaction with innovative energy plans, sustainability, and competitive pricing along with exceptional service. Located at 1990 Post Oak Blvd, Houston, TX, 77056.",
    provider_id: "",
  },
  {
    business_name: "GERDAU AMERISTEEL ENERGY INC (LSE)",
    show_name: "GERDAU AMERISTEEL ENERGY",
    description:
      "GERDAU AMERISTEEL ENERGY specializes in natural gas and electricity for residential and industrial clients. They emphasize efficient energy solutions and sustainable practices with competitive rates to help clients manage energy consumption effectively. Visit them at 300 Ward Rd, MIDLOTHIAN, TX, 76065.",
    provider_id: "",
  },
  {
    business_name: "GEXA ENERGY",
    show_name: "GEXA ENERGY",
    description:
      "GEXA ENERGY delivers affordable electricity solutions to residential and commercial customers with a commitment to exceptional service and sustainable practices. Known for competitive rates and innovative plans, the company focuses on renewable energy. Located at 601 Travis St. Suite 1400, HOUSTON, TX, 77002.",
    provider_id: "166",
  },
  {
    business_name: "GEXA ENERGY LP DBA GEXA ENERGY (LSE)",
    show_name: "GEXA ENERGY LP DBA GEXA ENERGY",
    description:
      "GEXA ENERGY LP DBA GEXA ENERGY is a premier energy provider offering diverse electricity solutions tailored for residential and business needs. Their green initiatives and customer-centric approach establish them as leaders in sustainable energy, providing competitively priced plans and exceptional service. They are located at 601 Travis St. Suite 1400, HOUSTON, TX, 77002.",
    provider_id: "166",
  },
  {
    business_name: "GOOD CHARLIE AND CO LLC (LSE)",
    show_name: "GOOD CHARLIE",
    description:
      "GOOD CHARLIE is a customer-focused energy provider offering innovative electricity plans committed to sustainability and community engagement. They combine affordability with environmentally friendly practices to meet customers' needs. Find them at 5373 W Alabama St Suite 510, HOUSTON, TX, 77056.",
    provider_id: "269",
  },
  {
    business_name: "GREEN ENERGY EXCHANGE",
    show_name: "GREEN ENERGY EXCHANGE",
    description:
      "GREEN ENERGY EXCHANGE is an industry leader in providing green energy options, offering competitive rates and tailored electricity plans. The company focuses on reducing carbon footprints and promoting renewable energy while educating customers on efficient practices. Located at 411 Brazos Street Suite 101 None, Austin, TX, 78701.",
    provider_id: "195",
  },
  {
    business_name: "GREEN MOUNTAIN ENERGY",
    show_name: "GREEN MOUNTAIN ENERGY",
    description:
      "GREEN MOUNTAIN ENERGY is a pioneer in clean energy, offering renewable electricity plans and promoting sustainable living. The company empowers consumers to make environmentally conscious choices while benefiting from competitive pricing and exceptional service. Visit them at 1201 Fannin St., Houston, TX, 77002.",
    provider_id: "206",
  },
  {
    business_name: "GRIDDY ENERGY LLC",
    show_name: "GRIDDY ENERGY",
    description:
      "GRIDDY ENERGY is known for providing transparent, real-time rate electricity plans. They empower consumers with data-driven insights, offering unparalleled customer service and flexible plans that adapt to modern energy consumption needs. Their location is 11811 North Freeway Suite 546, Houston, TX, 77060.",
    provider_id: "",
  },
  {
    business_name: "GRIDMATIC ROSA LLC (LSE)",
    show_name: "GRIDMATIC ROSA",
    description:
      "GRIDMATIC ROSA specializes in AI and advanced algorithms for optimizing energy trading. With machine learning technology, this leader in the energy sector delivers competitive pricing while enhancing sustainability and efficiency. Located at 20450 Stevens Creek Blvd Suite 100, CUPERTINO, TX, 95014.",
    provider_id: "",
  },
  {
    business_name: "GRIDPLUS TEXAS INC.",
    show_name: "GRIDPLUS TEXAS",
    description:
      "GRIDPLUS TEXAS is dedicated to decentralizing energy finance through blockchain technology for seamless, secure transactions. Committed to transparency and innovation, they provide users the ability to manage and optimize their energy consumption like never before. The company's address is 100 Congress Avenue Suite 2026, Austin, TX, 78701.",
    provider_id: "",
  },
  {
    business_name: "HERITAGE POWER LLC",
    show_name: "HERITAGE POWER",
    description:
      "HERITAGE POWER is a competitive retail electric provider delivering reliable energy solutions with a focus on sustainability and cost-effectiveness. With a customer-centric approach, it offers customized electricity plans tailored to the unique needs of both residential and commercial clients.",
    provider_id: "240",
  },
  {
    business_name: "HUDSON ENERGY SERVICES",
    show_name: "HUDSON ENERGY",
    description:
      "HUDSON ENERGY is a well-established energy service provider committed to offering affordable and reliable energy solutions. Known for its comprehensive range of services and excellent customer support, it serves a diverse clientele with innovative energy plans crafted to fit various budgets.",
    provider_id: "",
  },
  {
    business_name: "IGS",
    show_name: "IGS",
    description:
      "IGS is committed to making energy choices simple and accessible, offering renewable and traditional energy plans with flexible pricing. With a strong emphasis on customer satisfaction, it delivers innovative energy solutions that meet the evolving needs of households and businesses.",
    provider_id: "",
  },
  {
    business_name: "IGS ENERGY",
    show_name: "IGS ENERGY",
    description:
      "IGS ENERGY is a leading provider of energy solutions, specializing in natural gas, electricity, and solar energy. Committed to delivering innovative and sustainable options to residential and commercial customers, it focuses on excellent customer service and environmental responsibility.",
    provider_id: "",
  },
  {
    business_name: "INFINITE ENERGY INC",
    show_name: "INFINITE ENERGY",
    description:
      "INFINITE ENERGY is a trusted supplier of natural gas and electricity, catering to residential, commercial, and industrial clients. It prioritizes transparency and competitive pricing to ensure reliable and affordable energy solutions with exceptional support.",
    provider_id: "200",
  },
  {
    business_name: "INFUSE ENERGY LLC",
    show_name: "INFUSE ENERGY",
    description:
      "INFUSE ENERGY is an innovative retail energy supplier dedicated to offering reliable and affordable electricity and natural gas. It prides itself on exceptional customer service and tailored energy plans designed to meet the unique needs of every customer, from homes to businesses.",
    provider_id: "180",
  },
  {
    business_name: "INSPIRE ENERGY HOLDINGS LLC (LSE)",
    show_name: "INSPIRE ENERGY",
    description:
      "INSPIRE ENERGY is an environmentally-focused energy company that provides clean and renewable energy solutions. Its mission is to inspire positive energy choices and promote sustainability by offering green energy products and outstanding customer service.",
    provider_id: "",
  },
  {
    business_name: "IRON ENERGY LLC",
    show_name: "IRON ENERGY",
    description:
      "IRON ENERGY is a leading provider of renewable energy solutions, dedicated to delivering clean, sustainable power through innovative technology and customer-centric service, striving to reduce carbon footprints and promote environmental stewardship.",
    provider_id: "",
  },
  {
    business_name: "IRONHORSE POWER SERVICES LLC (LSE)",
    show_name: "IRONHORSE POWER",
    description:
      "IRONHORSE POWER specializes in comprehensive energy management services, including electricity generation, transmission, and distribution, and is committed to reliability, safety, and efficiency to meet the diverse needs of its customers.",
    provider_id: "263",
  },
  {
    business_name: "JP ENERGY RESOURCES LLC DBA ALMIKA SOLAR (LSE)",
    show_name: "JP ENERGY RESOURCES",
    description:
      "JP ENERGY RESOURCES focuses on harnessing solar power to provide eco-friendly energy solutions, delivering sustainable and affordable energy options that promote a green and renewable future for communities.",
    provider_id: "",
  },
  {
    business_name: "JUICE",
    show_name: "JUICE",
    description:
      "JUICE is dedicated to providing reliable and affordable electricity, emphasizing innovative solutions and customer satisfaction while advancing energy efficiency and sustainability.",
    provider_id: "281",
  },
  {
    business_name: "JUST ENERGY",
    show_name: "JUST ENERGY",
    description:
      "JUST ENERGY is an established retail energy provider offering electricity and natural gas solutions, dedicated to customer satisfaction, competitive pricing, and innovative energy efficiency programs.",
    provider_id: "177",
  },
  {
    business_name: "JUST ENERGY TEXAS LP DBA JUST ENERGY (LSE)",
    show_name: "JUST ENERGY TEXAS",
    description:
      "JUST ENERGY TEXAS provides comprehensive energy services, including power and gas supply, with a strong emphasis on customer service and innovative energy products designed to meet the needs of residential and business customers.",
    provider_id: "256",
  },
  {
    business_name: "KINETIC ENERGY",
    show_name: "KINETIC ENERGY",
    description:
      "KINETIC ENERGY offers cutting-edge energy solutions, focusing on integrating advanced technology and renewable resources to optimize energy use and sustainability.",
    provider_id: "",
  },
  {
    business_name: "LIBERTY POWER.",
    show_name: "LIBERTY POWER",
    description:
      "LIBERTY POWER provides reliable electricity services with a focus on competitive pricing and customer satisfaction, delivering sustainable and efficient energy solutions to a diverse customer base.",
    provider_id: "198",
  },
  {
    business_name: "LIFEENERGY LLC",
    show_name: "LIFEENERGY",
    description:
      "LIFEENERGY is a dedicated clean energy provider offering renewable energy solutions aimed at reducing environmental impact, focusing on solar power and innovative technology.",
    provider_id: "",
  },
  {
    business_name: "LPT LLC DBA LPT SP LLC",
    show_name: "LPT LLC DBA LPT SP",
    description:
      "LPT LLC DBA LPT SP is a leading electric company known for its outstanding power solutions and customer service, offering reliable and efficient energy services.",
    provider_id: "",
  },
  {
    business_name: "LUMINANT ET SERVICES COMPANY",
    show_name: "LUMINANT ET",
    description:
      "LUMINANT ET delivers exceptional energy services with a focus on reliability, efficiency, and competitive pricing, renowned for its customer-centric approach and advanced solutions.",
    provider_id: "",
  },
  {
    business_name: "MARKET PLACE ENERGY LLC (LSE)",
    show_name: "MARKET PLACE ENERGY",
    description:
      "MARKET PLACE ENERGY is recognized for its premium energy services and customer-focused solutions, ensuring every client receives optimal energy services tailored to their specific needs.",
    provider_id: "",
  },
  {
    business_name: "MEGA ENERGY LP",
    show_name: "MEGA ENERGY",
    description:
      "MEGA ENERGY is an industry leader in energy services, dedicated to providing reliable power solutions and excellent customer support with competitive prices and advanced technology.",
    provider_id: "",
  },
  {
    business_name: "MERRILL LYNCH COMMODITIES INC",
    show_name: "MERRILL LYNCH COMMODITIES",
    description:
      "MERRILL LYNCH COMMODITIES is a premium energy provider, renowned for delivering reliable and efficient power solutions tailored to meet diverse customer needs.",
    provider_id: "",
  },
  {
    business_name: "MI TEXAS REP 1 (LSE)",
    show_name: "MI TEXAS REP 1",
    description:
      "MI TEXAS REP 1 is dedicated to providing top-tier energy solutions, excelling in delivering reliable power services with competitive pricing and exceptional support.",
    provider_id: "",
  },
  {
    business_name: "MI TEXAS REP 2",
    show_name: "MI TEXAS REP 2",
    description:
      "MI TEXAS REP 2 is a trusted energy provider offering reliable and efficient energy solutions with a focus on exceptional customer service.",
    provider_id: "",
  },
  {
    business_name: "MI TEXAS REP 2 LLC DBA THINK ENERGY (LSE)",
    show_name: "MI TEXAS REP 2 LLC DBA THINK ENERGY",
    description:
      "MI TEXAS REP 2 LLC DBA THINK ENERGY provides high-quality energy services with a focus on reliability and customer satisfaction, employing advanced technology and competitive pricing.",
    provider_id: "188",
  },
  {
    business_name: "MIDAMERICAN ENERGY COMPANY",
    show_name: "MIDAMERICAN ENERGY",
    description:
      "MIDAMERICAN ENERGY is a well-regarded energy services company, excelling in offering reliable and efficient power solutions with a commitment to customer satisfaction.",
    provider_id: "203",
  },
  {
    business_name: "MIDAMERICAN ENERGY SERVICES LLC",
    show_name: "MIDAMERICAN ENERGY SERVICES",
    description:
      "MIDAMERICAN ENERGY SERVICES is an industry-leading energy services provider, known for its commitment to reliability, efficiency, and excellent customer service.",
    provider_id: "203",
  },
  {
    business_name: "MILAGRO ELECTRIC",
    show_name: "MILAGRO ELECTRIC",
    description:
      "MILAGRO ELECTRIC focuses on delivering reliable and efficient energy services while prioritizing customer satisfaction and competitive pricing.",
    provider_id: "",
  },
  {
    business_name:
      "MITSUI AND CO ENERGY MARKETING AND SERVICES (USA) INC (LSE)",
    show_name: "MITSUI AND CO ENERGY",
    description:
      "MITSUI AND CO ENERGY is renowned for its top-notch energy services and customer-centric approach, combining cutting-edge technology with competitive pricing.",
    provider_id: "",
  },
  {
    business_name: "MONONGAHELA COMMUNICATIONS LLC",
    show_name: "MONONGAHELA COMMUNICATIONS",
    description:
      "MONONGAHELA COMMUNICATIONS specializes in providing innovative communication solutions to the energy sector, maintaining optimal power grid performance.",
    provider_id: "",
  },
  {
    business_name: "MORGAN STANLEY CAPITAL GROUP INC 1 (LSE)",
    show_name: "MORGAN STANLEY CAPITAL GROUP INC 1",
    description:
      "MORGAN STANLEY CAPITAL GROUP INC 1 is a globally renowned financial services firm that operates in the energy sector to facilitate capital investments.",
    provider_id: "",
  },
  {
    business_name: "MOTHERSHIP",
    show_name: "MOTHERSHIP",
    description:
      "MOTHERSHIP focuses on revolutionizing energy delivery by leveraging advanced technologies and data analytics to enhance efficiency and reliability.",
    provider_id: "285",
  },
  {
    business_name: "MP2 ENERGY",
    show_name: "MP2 ENERGY",
    description:
      "MP2 ENERGY is a leading energy provider offering comprehensive power solutions, including renewable energy options, to ensure environmental sustainability.",
    provider_id: "248",
  },
  {
    business_name: "MP2 ENERGY TEXAS LLC 1 DBA SHELL ENERGY SOLUTIONS (LSE)",
    show_name: "MP2 ENERGY TEXAS LLC 1 DBA SHELL ENERGY SOLUTIONS",
    description:
      "MP2 ENERGY TEXAS LLC 1 DBA SHELL ENERGY SOLUTIONS operates under a well-known brand providing tailored energy solutions for the Texas market.",
    provider_id: "248",
  },
  {
    business_name: "MXENERGY INC",
    show_name: "MXENERGY INC",
    description:
      "MXENERGY INC offers utility services with a focus on customer satisfaction through competitive energy rates, green energy options, and flexible plans.",
    provider_id: "",
  },
  {
    business_name: "MY QUEST ENERGY",
    show_name: "MY QUEST ENERGY",
    description:
      "MY QUEST ENERGY is a cutting-edge energy service provider focusing on delivering tailored energy solutions including renewable energy plans and energy savings.",
    provider_id: "",
  },
  {
    business_name: "NA POWER LLC",
    show_name: "NA POWER LLC",
    description:
      "NA POWER LLC specializes in offering bespoke power solutions, ensuring reliable and cost-effective energy services tailored to specific customer needs.",
    provider_id: "",
  },
  {
    business_name: "NAPG LLC DBA NORTH AMERICAN POWER BUSINESS LLC (LSE)",
    show_name: "NAPG LLC DBA NORTH AMERICAN POWER BUSINESS",
    description:
      "NAPG LLC DBA NORTH AMERICAN POWER BUSINESS is committed to delivering sustainable energy solutions, offering renewable energy plans to help customers reduce their impact.",
    provider_id: "",
  },
  {
    business_name: "NATIONAL GAS AND ELECTRIC TEXAS LLC (LSE)",
    show_name: "NATIONAL GAS AND ELECTRIC",
    description:
      "NATIONAL GAS AND ELECTRIC is a leading provider of natural gas and electricity solutions, committed to quality service and customer satisfaction.",
    provider_id: "",
  },
  {
    business_name: "NATIONS POWER",
    show_name: "NATIONS POWER",
    description:
      "NATIONS POWER specializes in innovative energy solutions, providing reliable and cost-effective options for residential and commercial clients.",
    provider_id: "",
  },
  {
    business_name: "NEC CO-OP ENERGY",
    show_name: "NEC CO-OP ENERGY",
    description:
      "NEC CO-OP ENERGY focuses on delivering affordable and sustainable electricity solutions to its members with an emphasis on community involvement.",
    provider_id: "255",
  },
  {
    business_name: "NEUMIN PRODUCTION CO",
    show_name: "NEUMIN PRODUCTION CO",
    description:
      "NEUMIN PRODUCTION CO is a premier production company focusing on the development and supply of high-quality energy products.",
    provider_id: "",
  },
  {
    business_name: "NEW ENERGY",
    show_name: "NEW ENERGY",
    description:
      "NEW ENERGY is dedicated to advancing the adoption of renewable energy solutions, offering cutting-edge technology and services for sustainable transitions.",
    provider_id: "",
  },
  {
    business_name: "NEW LEAF ENERGY",
    show_name: "NEW LEAF ENERGY",
    description:
      "NEW LEAF ENERGY is committed to sustainable energy solutions, providing renewable energy products and services to help customers reduce their carbon footprint.",
    provider_id: "",
  },
  {
    business_name: "NEXTERA ENERGY MARKETING LLC (LSE)",
    show_name: "NEXTERA ENERGY",
    description:
      "NEXTERA ENERGY specializes in the marketing and trading of energy products, leveraging market insights to deliver optimal solutions.",
    provider_id: "",
  },
  {
    business_name: "NOW POWER",
    show_name: "NOW POWER",
    description:
      "NOW POWER prides itself on offering straightforward and affordable electricity plans, ensuring reliable energy solutions for residential clients.",
    provider_id: "249",
  },
  {
    business_name: "NRG CONTROLLABLE LOAD SERVICES LLC",
    show_name: "NRG CONTROLLABLE LOAD SERVICES",
    description:
      "NRG CONTROLLABLE LOAD SERVICES enhances energy efficiency through innovative solutions, working with clients to optimize energy consumption.",
    provider_id: "",
  },
  {
    business_name: "NRG ENERGY",
    show_name: "NRG ENERGY",
    description:
      "NRG ENERGY is a leader in the energy industry, offering comprehensive electricity solutions with a commitment to innovation and sustainability.",
    provider_id: "",
  },
  {
    business_name: "NUECES ELECTRIC COOPERATIVE INC DBA NEC COOP ENERGY (LSE)",
    show_name: "NUECES ELECTRIC COOPERATIVE INC DBA NEC COOP ENERGY",
    description:
      "NUECES ELECTRIC COOPERATIVE INC DBA NEC COOP ENERGY focuses on offering competitive and sustainable electricity plans.",
    provider_id: "",
  },
  {
    business_name: "OCCIDENTAL POWER MARKETING",
    show_name: "OCCIDENTAL POWER MARKETING",
    description:
      "OCCIDENTAL POWER MARKETING provides strategic energy solutions for a range of clients, navigating energy market complexities with efficiency.",
    provider_id: "",
  },
  {
    business_name: "OCTOPUS ENERGY LLC",
    show_name: "OCTOPUS ENERGY",
    description:
      "OCTOPUS ENERGY specializes in sustainable and green energy solutions, prioritizing customer savings and environmental benefits.",
    provider_id: "244",
  },
  {
    business_name: "OHMCONNECT TEXAS LLC (LSE)",
    show_name: "OHMCONNECT",
    description:
      "OHMCONNECT delivers affordable and reliable electric services, focusing on demand response and energy efficiency to empower consumers.",
    provider_id: "250",
  },
  {
    business_name: "ON POINT ENERGY TEXAS LLC (LSE)",
    show_name: "ON POINT ENERGY",
    description:
      "ON POINT ENERGY delivers comprehensive energy solutions ensuring competitive rates and a customer-centric approach.",
    provider_id: "",
  },
  {
    business_name: "ONPAC ENERGY",
    show_name: "ONPAC ENERGY",
    description:
      "ONPAC ENERGY offers customized energy plans with a commitment to high-quality service, transparency, and efficiency.",
    provider_id: "",
  },
  {
    business_name: "OUR ENERGY LLC",
    show_name: "OUR ENERGY",
    description:
      "OUR ENERGY is dedicated to optimizing power usage for maximum efficiency, striving to balance cost savings and operational efficiency.",
    provider_id: "202",
  },
  {
    business_name: "PAYLESS POWER",
    show_name: "PAYLESS POWER",
    description:
      "PAYLESS POWER specializes in prepaid electricity plans, giving customers control over their expenses with flexible options.",
    provider_id: "160",
  },
  {
    business_name: "PENSTAR POWER LLC",
    show_name: "PENSTAR POWER",
    description:
      "PENSTAR POWER provides reliable and affordable energy services, committed to customer satisfaction and innovative plan options.",
    provider_id: "",
  },
  {
    business_name: "PERIDOT POWER LLC (LSE)",
    show_name: "PERIDOT POWER",
    description:
      "PERIDOT POWER emphasizes transparency and efficiency, offering competitive energy rates tailored to various consumer needs.",
    provider_id: "",
  },
  {
    business_name: "PESO POWER",
    show_name: "PESO POWER",
    description:
      "PESO POWER sets itself apart with flexible pricing models, aiming to make energy accessible while promoting sustainability.",
    provider_id: "242",
  },
  {
    business_name: "POGO ENERGY LLC (LSE)",
    show_name: "POGO ENERGY",
    description:
      "POGO ENERGY specializes in prepaid electricity services, empowering consumers to manage their energy expenses effortlessly with user-friendly options that simplify energy management.  ",
    provider_id: "205",
  },
  {
    business_name: "POWER HOUSE ENERGY",
    show_name: "POWER HOUSE ENERGY",
    description:
      "POWER HOUSE ENERGY focuses on delivering high-efficiency energy solutions tailored to consumer needs, emphasizing reliability and affordability for a positive impact on both customers and the environment.  ",
    provider_id: "",
  },
  {
    business_name: "POWER OF TEXAS HOLDINGS INC.",
    show_name: "POWER OF TEXAS",
    description:
      "POWER OF TEXAS is a leading electricity provider committed to delivering reliable, affordable, and sustainable energy solutions, serving a wide customer base with innovative services and excellent support.  ",
    provider_id: "199",
  },
  {
    business_name: "PRIER ENERGY INC.",
    show_name: "PRIER ENERGY",
    description:
      "PRIER ENERGY is a dynamic energy company dedicated to providing dependable power services, utilizing advanced technologies to ensure energy efficiency and high customer satisfaction.  ",
    provider_id: "",
  },
  {
    business_name: "PRIORITY ENERGY TRANSITION",
    show_name: "PRIORITY ENERGY TRANSITION",
    description:
      "PRIORITY ENERGY TRANSITION is dedicated to facilitating the shift to renewable energy sources, emphasizing sustainable practices and technological innovation for a cleaner, greener future.  ",
    provider_id: "",
  },
  {
    business_name: "PRIORITY ENERGY TRANSITION 1 LLC (LSE)",
    show_name: "PRIORITY ENERGY TRANSITION 1",
    description:
      "PRIORITY ENERGY TRANSITION 1 specializes in clean energy transitions, leveraging cutting-edge technology to promote sustainability and reduce carbon footprints.  ",
    provider_id: "",
  },
  {
    business_name: "PRIORITY ENERGY TRANSITION 3 LLC (LSE)",
    show_name: "PRIORITY ENERGY TRANSITION 3",
    description:
      "PRIORITY ENERGY TRANSITION 3 focuses on promoting renewable energy and clean technologies, contributing to the global movement towards a sustainable energy landscape.  ",
    provider_id: "",
  },
  {
    business_name: "PRIORITY ENERGY TRANSITION 4 LLC (LSE)",
    show_name: "PRIORITY ENERGY TRANSITION 4",
    description:
      "PRIORITY ENERGY TRANSITION 4 leads in clean energy initiatives, offering advanced renewable energy solutions that minimize environmental impact and foster sustainable practices.  ",
    provider_id: "",
  },
  {
    business_name: "PRIORITY ENERGY TRANSITION 5 LLC (LSE)",
    show_name: "PRIORITY ENERGY TRANSITION 5",
    description:
      "PRIORITY ENERGY TRANSITION 5 is committed to driving the clean energy transformation, providing innovative and sustainable energy solutions to meet evolving environmental standards.  ",
    provider_id: "",
  },
  {
    business_name: "PRO POWER PROVIDERS LLC",
    show_name: "PRO POWER",
    description:
      "PRO POWER is a top-tier energy provider known for its reliability and customer-focused approach, delivering consistent and efficient power services to residential and commercial clients.  ",
    provider_id: "",
  },
  {
    business_name: "PRONTO POWER",
    show_name: "PRONTO POWER",
    description:
      "PRONTO POWER offers affordable and reliable electricity solutions with a straightforward, hassle-free experience, ensuring consistent power and excellent customer support.  ",
    provider_id: "311",
  },
  {
    business_name: "PROTON ENERGY",
    show_name: "PROTON ENERGY",
    description:
      "PROTON ENERGY leads in renewable energy innovation, committed to sustainable practices and reducing environmental impact through advanced energy solutions.  ",
    provider_id: "",
  },
  {
    business_name: "PULSE POWER LLC",
    show_name: "PULSE POWER LLC",
    description:
      "PULSE POWER LLC is a trusted energy provider dedicated to offering reliable and affordable electricity services, ensuring customer satisfaction through exceptional service and support.  ",
    provider_id: "210",
  },
  {
    business_name: "PULSE POWER LLC DBA ENERGY TO GO (LSE)",
    show_name: "PULSE POWER DBA ENERGY TO GO",
    description:
      "PULSE POWER DBA ENERGY TO GO provides competitive electricity rates and outstanding customer care, committed to delivering efficient and reliable energy solutions.  ",
    provider_id: "193",
  },
  {
    business_name: "PUMPJACK POWER LLC",
    show_name: "PUMPJACK POWER",
    description:
      "PUMPJACK POWER delivers affordable and efficient electricity solutions with a focus on sustainability and excellent customer service, ensuring reliable power for its clients.  ",
    provider_id: "",
  },
  {
    business_name: "QUEXT ENERGY LLC (LSE)",
    show_name: "QUEXT ENERGY",
    description:
      "QUEXT ENERGY is a leading electricity provider offering competitive rates and exceptional customer service, committed to renewable energy and sustainability for a greener future.  ",
    provider_id: "",
  },
  {
    business_name: "RANCHERO POWER (LSE)",
    show_name: "RANCHERO POWER",
    description:
      "RANCHERO POWER is a dynamic energy company focused on delivering reliable and affordable electricity to residential and commercial clients through advanced technology.  ",
    provider_id: "246",
  },
  {
    business_name: "REACH ENERGY LLC",
    show_name: "REACH ENERGY",
    description:
      "REACH ENERGY specializes in comprehensive energy solutions, delivering top-tier electricity products while focusing on customer satisfaction and sustainable energy practices.  ",
    provider_id: "",
  },
  {
    business_name: "RELIANT ENERGY RETAIL",
    show_name: "RELIANT ENERGY",
    description:
      "RELIANT ENERGY offers comprehensive energy services with a reputation for excellent customer service and competitive pricing in renewable energy and sustainability.  ",
    provider_id: "159",
  },
  {
    business_name:
      "RELIANT ENERGY RETAIL SERVICES LLC DBA RELIANT ENERGY BUSINESS",
    show_name: "RELIANT ENERGY DBA RELIANT ENERGY",
    description:
      "RELIANT ENERGY DBA RELIANT ENERGY focuses on delivering exceptional customer experiences and reliable power solutions through advanced technology and sustainability efforts.  ",
    provider_id: "159",
  },
  {
    business_name: "REVOLUTION ENERGY LLC (LSE)",
    show_name: "REVOLUTION ENERGY",
    description:
      "REVOLUTION ENERGY provides innovative and sustainable energy solutions tailored to meet modern consumer needs, combining technology with eco-friendly practices.  ",
    provider_id: "286",
  },
  {
    business_name: "RHYTHM OPS LLC (LSE)",
    show_name: "RHYTHM OPS",
    description:
      "RHYTHM OPS LLC specializes in innovative electricity solutions that cater to each client’s unique needs, promoting a greener future through renewable energy initiatives.  ",
    provider_id: "239",
  },
  {
    business_name: "RODEO ENERGY",
    show_name: "RODEO ENERGY",
    description:
      "RODEO ENERGY is dedicated to offering exceptional energy solutions with a focus on customer satisfaction, sustainable practices, and competitive pricing for reliable power.  ",
    provider_id: "",
  },
  {
    business_name: "SFE ENERGY TEXAS",
    show_name: "SFE ENERGY",
    description:
      "SFE ENERGY TEXAS delivers comprehensive electricity services committed to sustainability and innovation for residential and commercial customers, ensuring reliability and satisfaction.  ",
    provider_id: "",
  },
  {
    business_name: "SIMPLE POWER L.P.",
    show_name: "SIMPLE POWER",
    description:
      "SIMPLE POWER L.P. offers straightforward and affordable energy solutions for modern consumers, focusing on ease of use and sustainability in their services.  ",
    provider_id: "",
  },
  {
    business_name: "SMART PREPAID ELECTRIC",
    show_name: "SMART PREPAID ELECTRIC",
    description:
      "SMART PREPAID ELECTRIC provides convenient prepaid electricity services designed to offer flexibility and control for residential consumers, focusing on customer satisfaction.  ",
    provider_id: "",
  },
  {
    business_name: "SMARTCOM ENERGY SERVICES LLC",
    show_name: "SMARTCOM ENERGY",
    description:
      "SMARTCOM ENERGY is a pioneering provider of innovative and sustainable energy solutions for residential and commercial customers, ensuring efficiency and reliability.  ",
    provider_id: "",
  },
  {
    business_name: "SOURCE POWER AND GAS LLC",
    show_name: "SOURCE POWER AND GAS",
    description:
      "SOURCE POWER AND GAS is committed to offering competitive rates and personalized service in sustainable energy solutions for both residential and commercial clients.  ",
    provider_id: "",
  },
  {
    business_name: "SOUTHERN FEDERAL POWER",
    show_name: "SOUTHERN FEDERAL POWER",
    description:
      "SOUTHERN FEDERAL POWER focuses on delivering reliable, cost-efficient, and environmentally friendly energy solutions, ensuring customer satisfaction in seamless energy transitions.  ",
    provider_id: "214",
  },
  {
    business_name: "SOUTHWEST SMARTENERGY LLC (LSE)",
    show_name: "SOUTHWEST SMARTENERGY",
    description:
      "SOUTHWEST SMARTENERGY delivers tailored energy plans focusing on renewable sources and cost-effectiveness, optimizing energy use for residential and commercial customers.  ",
    provider_id: "",
  },
  {
    business_name: "SPARK ENERGY",
    show_name: "SPARK ENERGY",
    description:
      "SPARK ENERGY is a trusted provider of affordable electricity and natural gas plans, committed to customer satisfaction, energy innovation, and environmental sustainability.  ",
    provider_id: "208",
  },
  {
    business_name: "STARTEX POWER.",
    show_name: "STARTEX POWER",
    description:
      "STARTEX POWER prioritizes customer service and affordable rates, providing reliable energy solutions tailored to meet the needs of modern consumers.  ",
    provider_id: "",
  },
  {
    business_name: "STAT ENERGY",
    show_name: "STAT ENERGY",
    description:
      "STAT ENERGY provides personalized energy solutions focused on competitive pricing and reliability, offering electricity and natural gas services tailored to the specific needs of residential and commercial clients. Located at 740 E. Campbel RdSTE 460, Richardson, TX, 75081.  ",
    provider_id: "",
  },
  {
    business_name: "STREAM ENERGY",
    show_name: "STREAM ENERGY",
    description:
      "STREAM ENERGY delivers innovative and affordable energy solutions with high-quality customer service, offering reliable electricity and natural gas plans to meet the diverse needs of households and businesses. Located at 14675 Dallas ParkwaySuite 150, Dallas, TX, 75254.  ",
    provider_id: "",
  },
  {
    business_name: "STREAM GAS AND ELECTRIC LTD",
    show_name: "STREAM GAS AND ELECTRIC",
    description:
      "STREAM GAS AND ELECTRIC offers top-tier energy services with a commitment to affordability, excellent customer service, and sustainability, providing reliable electricity and natural gas plans for both homes and businesses. Located at 1005 Congress Ave Suite 950, AUSTIN, TX, 78701.  ",
    provider_id: "",
  },
  {
    business_name: "STREAM SPE LTD DBA STREAM ENERGY (LSE)",
    show_name: "STREAM SPE LTD DBA STREAM ENERGY",
    description:
      "STREAM SPE LTD DBA STREAM ENERGY specializes in customized energy solutions focused on sustainability, reliability, and cost-efficiency, providing comprehensive plans for residential and commercial customers. Located at 1005 Congress Ave Suite 950, AUSTIN, TX, 78701.  ",
    provider_id: "",
  },
  {
    business_name: "SUEZ ENERGY RESOURCES DBA SERNA PLR",
    show_name: "SUEZ ENERGY RESOURCES DBA SERNA PLR",
    description:
      "SUEZ ENERGY RESOURCES DBA SERNA PLR leads in sustainable energy solutions, offering competitive rates and reliable service, with a focus on environmentally friendly electricity and natural gas plans for various customers. Located at 1990 Post Oak Blvd Suite 1900, HOUSTON, TX, 77056.  ",
    provider_id: "",
  },
  {
    business_name: "SUMMER ENERGY",
    show_name: "SUMMER ENERGY",
    description:
      "SUMMER ENERGY provides innovative electricity solutions as a retail electricity provider, offering competitive rates and flexible plans while ensuring reliability and customer satisfaction with environmentally-friendly practices. Located at 5847 San Felipe Street#3700, Houston, TX, 77057.  ",
    provider_id: "226",
  },
  {
    business_name: "SUMMER ENERGY LLC DBA PRONTO POWER (LSE)",
    show_name: "SUMMER ENERGY DBA PRONTO POWER",
    description:
      "SUMMER ENERGY DBA PRONTO POWER specializes in retail electricity, focusing on customer convenience and service excellence, delivering affordable and reliable power through customizable energy plans for homes and businesses. Located at 5847 San Felipe Street Suite 3700, HOUSTON, TX, 77057.  ",
    provider_id: "311",
  },
  {
    business_name: "TALEN ENERGY MARKETING LLC",
    show_name: "TALEN ENERGY",
    description:
      "TALEN ENERGY is an integrated energy company that provides diversified power solutions, ensuring reliable and efficient energy to wholesale markets while promoting environmental stewardship through innovative energy solutions. Located at 1780 Hughes LandingSuite 800, The Woodlands, TX, 77380.  ",
    provider_id: "",
  },
  {
    business_name: "TARA ENERGY LLC",
    show_name: "TARA ENERGY",
    description:
      "TARA ENERGY specializes in retail electricity, offering competitive energy rates and exceptional customer service with flexible plan options designed to meet the needs of both residential and commercial clients while balancing affordability and reliability. Located at 5251 Westheimer Rd, HOUSTON, TX, 77056.  ",
    provider_id: "185",
  },
  {
    business_name: "TARA ENERGY RESOURCES",
    show_name: "TARA ENERGY RESOURCES",
    description:
      "TARA ENERGY RESOURCES delivers comprehensive electricity solutions focused on the wholesale energy market, ensuring dependable power and innovative services while promoting sustainable practices to meet diverse energy needs. Located at 5251 Westheimer Rd, HOUSTON, TX, 77056.  ",
    provider_id: "185",
  },
  {
    business_name: "TENASKA POWER SERVICES",
    show_name: "TENASKA POWER",
    description:
      "TENASKA POWER specializes in energy services, providing reliable and competitive power solutions to the wholesale market while integrating generation and supply methods to optimize energy efficiency and support sustainability. Located at 1701 E. Lamar Blvd Suite 100, ARLINGTON, TX, 76006.  ",
    provider_id: "",
  },
  {
    business_name: "TENASKA TOPS REP LP (LSE)",
    show_name: "TENASKA TOPS REP",
    description:
      "TENASKA TOPS REP excels in wholesale energy solutions, delivering reliable electricity and energy management services through innovative technologies and extensive market knowledge to support sustainability initiatives for various energy needs. Located at 1701 E. Lamar Blvd Suite 100, ARLINGTON, TX, 76006.  ",
    provider_id: "",
  },
  {
    business_name: "TENASKA-OXY POWER REP SERVICES LP",
    show_name: "TENASKA-OXY POWER",
    description:
      "TENASKA-OXY POWER focuses on reliable energy services, utilizing advanced technologies for efficient power distribution and promoting sustainability by delivering dependable energy that meets diverse client requirements. Located at 1701 E. Lamar Blvd Suite 100, ARLINGTON, TX, 76006.  ",
    provider_id: "",
  },
  {
    business_name: "TERRAFORM RENEWABLES ENERGY SERVICES",
    show_name: "TERRAFORM RENEWABLES ENERGY",
    description:
      "TERRAFORM RENEWABLES ENERGY is committed to clean energy, specializing in renewable energy services through solar, wind, and other sustainable sources to advance renewable energy integration and promote innovation for a greener future. Located at 200 Liberty Street 14th Floor, NEW YORK, NY, 10281.  ",
    provider_id: "",
  },
  {
    business_name: "TESLA ENERGY VENTURES LLC (LSE)",
    show_name: "TESLA ENERGY VENTURES",
    description:
      "TESLA ENERGY VENTURES offers innovative energy solutions that combine cutting-edge technology with sustainable practices, providing residential and commercial electricity options while emphasizing renewable energy sources and energy storage. Located at 3500 Deer Creek Road, PALO ALTO, CA, 94304.  ",
    provider_id: "",
  },
  {
    business_name: "TEXAS POWER LP",
    show_name: "TEXAS POWER",
    description:
      "TEXAS POWER is a competitive retail electricity provider serving residential and commercial customers with affordable, reliable energy solutions, focusing on flexible plans and excellent customer service to promote sustainability. Located at 4025 Woodland Park Blvd.Ste 410, Arlington, TX, 76013.  ",
    provider_id: "",
  },
  {
    business_name: "TEXAS POWER OUTLET",
    show_name: "TEXAS POWER OUTLET",
    description:
      "TEXAS POWER OUTLET delivers affordable electricity solutions, prioritizing customer satisfaction with reliable service and various energy plans tailored to meet residential and commercial needs for consistent and competitive energy delivery. Located at PO Box 161540, Austin, TX, 78716.  ",
    provider_id: "",
  },
  {
    business_name: "TEXAS RETAIL ENERGY LLC 2 (LSE)",
    show_name: "TEXAS RETAIL ENERGY",
    description:
      "TEXAS RETAIL ENERGY is a leading retail electricity provider that offers reliable and affordable power to homes and businesses, ensuring tailored energy plans that promote efficiency and sustainability while maintaining competitive rates. Located at 2608 SE J Street, BENTONVILLE, AR, 72716-5530.  ",
    provider_id: "",
  },
  {
    business_name: "TEXPO POWER LP",
    show_name: "TEXPO POWER",
    description:
      "TEXPO POWER specializes in retail electricity, providing adaptive energy solutions for residential and commercial customers with a focus on competitive pricing and customer service, delivering reliable and efficient power options. Located at 5773 Woodway Dr #311, Houston, TX, 77057.  ",
    provider_id: "",
  },
  {
    business_name: "TITAN GAS LLC DBA CLEANSKY ENERGY (LSE)",
    show_name: "TITAN GAS DBA CLEANSKY ENERGY",
    description:
      "TITAN GAS DBA CLEANSKY ENERGY offers competitive power solutions with an emphasis on customer service and dependable energy delivery, providing diverse energy plans that ensure affordability, reliability, and sustainability for optimal management. Located at 3355 West Alabama Suite 500, HOUSTON, TX, 77098.  ",
    provider_id: "230",
  },
  {
    business_name: "TOMORROW ENERGY CORP",
    show_name: "TOMORROW ENERGY CORP",
    description:
      "TOMORROW ENERGY CORP is dedicated to delivering sustainable and affordable power solutions with a focus on green energy initiatives, customer-centric policies, and innovative technology to transform the energy landscape. Located at 3151 Briarpark Dr Suite 100, HOUSTON, TX, 77042.  ",
    provider_id: "225",
  },
  {
    business_name: "TOTALENERGIES GAS AND POWER NORTH AMERICA INC (LSE)",
    show_name: "TOTALENERGIES GAS AND POWER NORTH AMERICA",
    description:
      "TOTALENERGIES GAS AND POWER NORTH AMERICA is a premier provider of integrated energy solutions specializing in natural gas, electricity, and renewable energy services for both residential and commercial customers. Located at 1201 Louisiana Street, HOUSTON, TX, 77002.  ",
    provider_id: "",
  },
  {
    business_name: "TRIEAGLE ENERGY LP DBA EAGLE ENERGY (LSE)",
    show_name: "TRIEAGLE ENERGY LP DBA EAGLE ENERGY",
    description:
      "TRIEAGLE ENERGY LP DBA EAGLE ENERGY offers competitive energy plans with exceptional customer service, committed to meeting diverse customer needs while enhancing reliability, efficiency, and sustainability. Located at 6555 Sierra Dr, IRVING, TX, 75039.  ",
    provider_id: "224",
  },
  {
    business_name: "TRUE COMMODITIES LLC (LSE)",
    show_name: "TRUE COMMODITIES",
    description:
      "TRUE COMMODITIES is a premier energy provider dedicated to delivering high-quality commodities and energy solutions, designed to meet evolving market demands while ensuring maximum efficiency and sustainability. Located at 2900 Wilcrest Dr Suite 450, HOUSTON, TX, 77042.  ",
    provider_id: "",
  },
  {
    business_name: "TXU ENERGY RETAIL COMPANY LLC 2 (LSE)",
    show_name: "TXU ENERGY RETAIL COMPANY",
    description:
      "TXU ENERGY RETAIL COMPANY strives to provide reliable and innovative energy solutions with a focus on customer satisfaction and cutting-edge technology, ensuring efficiency and affordability to meet the needs of a dynamic market. Located at 6555 Sierra Drive, IRVING, TX, 75039.  ",
    provider_id: "176",
  },
  {
    business_name: "US RETAILERS LLC",
    show_name: "US RETAILERS",
    description:
      "US RETAILERS provides top-tier energy services, aiming to offer competitive rates and personalized solutions while upholding a commitment to excellence to meet the energy needs of residential and commercial clients. Located at 1005 Congress Ave Suite 950, AUSTIN, TX, 78701.  ",
    provider_id: "",
  },
  {
    business_name: "V247 POWER CORPORATION",
    show_name: "V247 POWER CORPORATION",
    description:
      "V247 POWER CORPORATION offers comprehensive energy solutions specializing in reliable and cost-effective power delivery, focused on innovation and sustainability to ensure exceptional service and value. Located at 9999 Bellaire Blvd 1133, Hou.",
    provider_id: "207",
  },
  {
    business_name: "VALUE BASED BRANDS LLC",
    show_name: "VALUE BASED BRANDS",
    description:
      "VALUE BASED BRANDS focuses on delivering reliable energy services tailored to meet the unique needs of customers in Irving. With a commitment to excellence, we offer competitive energy plans and exceptional customer support for optimal satisfaction and efficiency.",
    provider_id: "233",
  },
  {
    business_name: "VARSITY ENERGY LLC (LSE)",
    show_name: "VARSITY ENERGY",
    description:
      "VARSITY ENERGY is dedicated to providing efficient and cost-effective energy solutions that meet the diverse needs of our customers in Houston. Our commitment to innovation and customer satisfaction drives our mission to deliver reliable and sustainable energy.",
    provider_id: "228",
  },
  {
    business_name: "VERDE ENERGY TEXAS",
    show_name: "VERDE ENERGY TEXAS",
    description:
      "VERDE ENERGY TEXAS specializes in green energy and innovative technologies, committed to providing efficient and sustainable energy solutions while supporting environmental conservation and meeting customer needs in Houston.",
    provider_id: "256",
  },
  {
    business_name: "VETERAN ENERGY",
    show_name: "VETERAN ENERGY",
    description:
      "VETERAN ENERGY is dedicated to offering affordable energy solutions with a community-centered approach, catering specifically to veterans and their families in Houston and ensuring reliable and sustainable power choices.",
    provider_id: "190",
  },
  {
    business_name: "VIRIDIAN ENERGY",
    show_name: "VIRIDIAN ENERGY",
    description:
      "VIRIDIAN ENERGY stands as a leader in green energy, specializing in renewable options that help reduce carbon footprints and support sustainable living while ensuring customer satisfaction and energy efficiency in Irving.",
    provider_id: "",
  },
  {
    business_name: "VISTRA CORP",
    show_name: "VISTRA CORP",
    description:
      "VISTRA CORP is an integrated retail electricity and power generation company that delivers a wide range of energy solutions, balancing traditional power sources with innovative renewable options for customers in Irving.",
    provider_id: "",
  },
  {
    business_name: "VITAL POWER",
    show_name: "VITAL POWER",
    description:
      "VITAL POWER offers competitively priced energy plans tailored to residential and commercial customers, ensuring a seamless power supply experience with a focus on exceptional customer service in Houston.",
    provider_id: "",
  },
  {
    business_name: "VITAL POWER DBA BROOKLET ENERGY",
    show_name: "VITAL POWER DBA BROOKLET ENERGY",
    description:
      "VITAL POWER DBA BROOKLET ENERGY provides energy supply expertise through customized power plans designed to fit any budget, delivering reliable electricity and exceptional customer support in Houston.",
    provider_id: "",
  },
  {
    business_name: "VOLT ELECTRICITY PROVIDER LP",
    show_name: "VOLT ELECTRICITY",
    description:
      "VOLT ELECTRICITY provides comprehensive electricity solutions with a customer-centric approach, delivering high-quality power services at competitive rates for clients in Houston.",
    provider_id: "",
  },
  {
    business_name: "WATT BRO (LSE)",
    show_name: "WATT BRO",
    description:
      "WATT BRO specializes in electricity provision, offering competitive rates and exceptional reliability, ensuring that customers receive uninterrupted power through flexible and affordable plans in Houston.",
    provider_id: "",
  },
  {
    business_name: "WINDROSE POWER AND GAS LLC",
    show_name: "WINDROSE POWER AND GAS",
    description:
      "WINDROSE POWER AND GAS offers innovative energy solutions designed for both residential and commercial clients, focusing on reliability and customer satisfaction with clear energy plans in Woodlands.",
    provider_id: "",
  },
  {
    business_name: "WORLD POWER AND GAS LP (LSE)",
    show_name: "WORLD POWER AND GAS LP",
    description:
      "WORLD POWER AND GAS LP delivers tailored energy solutions, ensuring cost-effective and reliable power for clients built on integrity and customer commitment, aiming to be a preferred energy partner in Sugar Land.",
    provider_id: "",
  },
  {
    business_name: "WTU RETAIL ENERGY",
    show_name: "WTU RETAIL ENERGY",
    description:
      "WTU RETAIL ENERGY, a premier retail energy provider, supplies residential and commercial customers with reliable and cost-effective electricity plans, emphasizing sustainability and innovative solutions in Houston.",
    provider_id: "213",
  },
  {
    business_name: "XOOM ENERGY",
    show_name: "XOOM ENERGY",
    description:
      "XOOM ENERGY provides innovative energy solutions for residential and business clientele, offering a wide range of electricity and natural gas plans in Huntersville that cater to diverse energy needs.",
    provider_id: "237",
  },
  {
    business_name: "XOOM ENERGY TEXAS LLC",
    show_name: "XOOM ENERGY TEXA",
    description:
      "XOOM ENERGY TEXAS LLC offers flexible and tailored electricity plans designed for residents and businesses across Texas, with a focus on exceptional customer experience and affordability in Charlotte.",
    provider_id: "237",
  },
  {
    business_name: "YENTA ENERGY LP DBA CLYDE ENERGY (LSE)",
    show_name: "YENTA ENERGY LP DBA CLYDE ENERGY",
    description:
      "YENTA ENERGY LP DBA CLYDE ENERGY specializes in comprehensive energy services, focusing on sustainability and efficiency to meet the diverse needs of residential and commercial customers in San Antonio.",
    provider_id: "",
  },
  {
    business_name: "YOUNG ENERGY (LSE)",
    show_name: "YOUNG ENERGY",
    description:
      "YOUNG ENERGY is a trusted provider of electricity solutions, offering customized plans that cater to the unique needs of both residential and business customers in Fort Worth, with a strong commitment to reliability and customer service.",
    provider_id: "",
  },
  {
    business_name: "YOUNG ENERGY LLC DBA COST PLUS POWER (LSE)",
    show_name: "YOUNG ENERGY LLC DBA COST PLUS POWER",
    description:
      "YOUNG ENERGY LLC DBA COST PLUS POWER operates to provide cost-effective and sustainable electricity solutions, ensuring a seamless energy experience tailored to the specific requirements of residential and commercial customers in Fort Worth.",
    provider_id: "",
  },
  {
    business_name: "ZIP ENERGY",
    show_name: "ZIP ENERGY",
    description:
      "ZIP ENERGY is committed to delivering affordable and reliable electricity plans to households and businesses in Houston, focusing on customer satisfaction, innovative solutions, and competitive pricing for unmatched service.",
    provider_id: "",
  },
];
