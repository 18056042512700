export const dcToAcDerate = 0.85;
export const panelCapacityWatts = 250; // Watt
// const panelEfficiency = 0.153; // 15.3%

export const calculateYearlyKwhEnergyConsumption = (
  monthlyAverageEnergyBill: number,
  avgCostPerKwh: number
) => (monthlyAverageEnergyBill / avgCostPerKwh) * 12;

export const countSolarPanel = (
  monthlyAverageEnergyBill: number,
  avgCostPerKwh: number,
  usableSunlight: number
) => {
  const powerOutputPerWatt = usableSunlight / 1000;
  const yearlyPowerOutput = calculateYearlyKwhEnergyConsumption(
    monthlyAverageEnergyBill,
    avgCostPerKwh
  );
  const yearlyPowerWatt = yearlyPowerOutput / dcToAcDerate / powerOutputPerWatt;

  return Math.ceil(yearlyPowerWatt / panelCapacityWatts);
};

export const findBestPanelsCount = (data: any, energyConsumption: number) => {
  let bestMatch:any = null;

  for (let i = 0; i < data.length; i += 1) {
    const record = data[i];
    const acEnergy = record.yearlyEnergyDcKwh * dcToAcDerate; // DC to AC

    if (energyConsumption < acEnergy) {
      const percentage = (acEnergy / energyConsumption) * 100;

      if (bestMatch === null || acEnergy < bestMatch.energy) {
        bestMatch = {
          panelsCount: record.panelsCount,
          yearlyEnergyDcKwh: record.yearlyEnergyDcKwh,
          yearlyEnergyAcKwh: acEnergy,
          coveredEnergy: percentage,
        };
      }
    }
  }

  return bestMatch;
};
