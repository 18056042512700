import { css } from "@emotion/css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import throttle from "lodash/throttle";
import moment from "moment";
// import Link from "next/link";
import { useParams, useNavigate,useLocation } from "react-router-dom";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { Button as BootstrapButton, Dropdown } from "react-bootstrap";
import { useCookies } from "react-cookie";
import { useTranslation } from "react-i18next";
// import Numberformat from "react-number-format";
import { toast } from "react-toastify";
import {
  fetchAddress,
  fetchDailyUsage,
  fetchEstUsage,
  fetchIntervalUsage,
  fetchMonthlyUsage,
  fetchPremiseDetail,
} from "../../../api/api";
import {
  fetchAllPlans,
  fetchAllUsnPlans,
  fetchUSNUtility,
  fetchUsnFilterOptions,
  fetchUtility,
  fetchFilterOptions,
} from "../../../api/city";
import type {
  GetUsageModalProps,
  MonthlyUsageProps,
  PremiseInfo,
  SmtDailyReads,
  SmtIntervalReads,
  SmtMonthlyReads,
  Utilities,
  _PlanProps,
} from "../../../types";
import { GetUsageComponent } from "../../get_usage";
import { LoadingRow } from "../LoadingRow";
import { Button } from "../../styles/Button.styled";
import {
  CompareClearBtn,
  CompareToggleBar,
  PlanPageAd,
} from "../../styles/Plan.styled";
import { CompareModal } from "./CompareModal";
import { mockTouUsageData } from "./mock-tou-usage-data";
import { PlanRow } from "./plan-row";
import { PrepaidFilter } from "./prepaid-filter";
import { ProviderFilter } from "./provider-filter";
import { RenewableFilter } from "./renewable-filter";
import { SidebySideModal } from "./sidebyside-modal";
import { TermFilter } from "./term-filter";
import { UtilitiesFilter } from "./utilities-filter";
import { Box, Pagination, Typography, Container } from "@mui/material";
import useIsTexas from "../../hooks/useIsTexas";
import useGetState from "../../hooks/useGetState";
import { faSlidersH, faTimes } from "@fortawesome/free-solid-svg-icons";
import { EstimatedUsageCard } from "./cardSection/ElectricityCard";
// import { enqueueSnackbar } from "notistack";

export const PlansList = ({
  detailUsages,
  handleGetUsage,
  hideRecommendPlans,
  specifiedProviderId,
  setDetailUsages,
  handleProvider
}: {
  detailUsages?: number[];
  handleGetUsage?: Function;
  hideRecommendPlans?: boolean;
  specifiedProviderId?: string;
  setDetailUsages?:Function;
  handleProvider?:Function;
}) => {
  const { t } = useTranslation("plan");

  const sortOptions: Array<any> = [
    { label: "Price: Low to high", value: "1" },
    { label: "Price: High to low", value: "2" },
    { label: "Plan Length: Low to high", value: "3" },
    { label: "Plan Length: High to low", value: "4" },
  ];

  const navigate = useNavigate();
  const location = useLocation();
  const { state, city, zipcode, street, actualusage, type } = useParams<{
    state: string;
    city: string;
    zipcode: string;
    street: string;
    actualusage: string;
    type: string;
  }>();
  const resolvedStreet = street || type;
  const [usage, setUsage] = useState<MonthlyUsageProps | undefined>(undefined);
  const [freeWeekendUsage, setFreeWeekendUsage] = useState(undefined);
  const [free7DaysUsage, setFree7DaysUsage] = useState(undefined);
  const [freeNightUsage, setFreeNightUsage] = useState(undefined);
  const [loading, setLoading] = useState(true);
  const [initializeFilter, setInitializeFilter] = useState(true);
  const [plans, setPlans] = useState<Array<_PlanProps>>([]);
  const [pagedPlans, setPagedPlans] = useState<any>(undefined);
  const [filterValues, setFilterValues] = useState<any>({
    utility_code: "",
    term: [],
    provider_id: [],
    renewable: false,
    prepaid: "all",
    type: "normal",
  });
  const [reps, setReps] = useState([]);
  const [terms, setTerms] = useState([]);
  const [utilities, setUtilities] = useState<Array<Utilities>>([]);
  const [availableTerms, setAvailableTerms] = useState<Array<string>>([]);
  const [availableReps, setAvailableReps] = useState<Array<string>>([]);
  const [showAccessSmtSection, setShowAccessSmtSection] = useState(false);
  const [consentId, setConsentId] = useState("");
  const [isEstMode, setIsEstMode] = useState(true);
  const [planType, setPlanType] = useState("normal");
  const [premiseDetail, setPremiseDetail] = useState<PremiseInfo | undefined>(
    undefined
  );
  const [selectedSortOption, setSelectedSortOption] = useState(sortOptions[0]);
  const [currentPage, setCurrentPage] = useState(1);
  const [showFilters, setShowFilters] = useState(false);
  const [comparedPlan, setComparedPlan] = useState<_PlanProps[]>([]);
  const [showCompareModal, setShowCompareModal] = useState(false);
  const [cookies, setCookie] = useCookies([
    "consent_id",
    "show_subscribe_popup",
  ]);
  // const [openWindow, setOpenWindow] = useState(false);
  const [showSidebySideModal, setShowSidebySideModal] = useState(false);
  const [, setSelectedAddress] = useState();
  const [utility, setUtility] = useState<any>();
  const [esiid] = useState();
  const getUsageModalView = useRef("");
  const primaryFilter = useRef("");
  const allTerms = useRef(undefined);
  const allReps = useRef(undefined);
  const sidebysideComparedPlan = useRef<_PlanProps | undefined>(undefined);
  const [showAllTerm, setShowAllTerm] = useState(true);
  const [allTerm, setAllTerm] = useState([]);
  const [showAllReps, setShowAllReps] = useState(true);
  const [defaultReps, setAllReps] = useState([]);
  const [showGas, setShowGas] = useState(false);
  const [gasUtility, setGasUtility] = useState<any>();
  const [seeAllPlans, setSeeAllPlans] = useState(true);
  const [personalizedPlans, setPersonalizedPlans] = useState({});

  const isTexas = useIsTexas(state);
  const selectState = useGetState(state);

  let address = "";
  if (city && resolvedStreet && zipcode && state && resolvedStreet !== "renewable")
    address = `${resolvedStreet}, ${city}, ${"TX"} ${zipcode}`;

  useEffect(() => {
    fetchAddressDelayed(address, (result: any) => {
      setSelectedAddress(result[0] || {});
      localStorage.setItem("defaultAddress", JSON.stringify(result[0]));
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [address]);

  useEffect(() => {
    if (!specifiedProviderId) return;
    const newFilterValues = { ...filterValues };
    newFilterValues.provider_id = [specifiedProviderId];
    setFilterValues(newFilterValues);
  }, [specifiedProviderId]);

  useEffect(() => {
    if (isTexas === undefined) return;

    if (isTexas) {
      getUtility();
    } else {
      getUSNUtility();
    }
  }, [isTexas]);

  useEffect(() => {
    if (!location.pathname.includes("/city")) {
      return; // 提前返回 null
    }
    // if (type === 'gas') {
    //     setShowGas(true);
    // } else {
    setShowGas(false);
    // }
    if (type === "renewable") {
      const newFilterValues = { ...filterValues };
      newFilterValues.renewable = true;
      setFilterValues(newFilterValues);
    }
    if (!type) {
      const newFilterValues = { ...filterValues };
      newFilterValues.renewable = false;
      setFilterValues(newFilterValues);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [type]);

  useEffect(() => {
    // if (!address || !esiid || !zipcode || !city || !state || !utcode) {
    //     toast.error('Param is missing')
    //     return
    // }

    if (cookies && cookies.consent_id) {
      setConsentId(cookies.consent_id);
      setIsEstMode(false);
    } else {
      setConsentId("");
      setIsEstMode(true);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // useEffect(() => {
  //     const utilityCode = utility ? utility.utility_code : '';
  //     let type = 'filter';
  //     if (utilityCode && zipcode) {
  //         fetchFilterOptions(planType, usage, zipcode, filterValues, utilityCode).then(res => {
  //             if (res && Number(res.status) === 1) {
  //                 setFilter(res.response)
  //             }
  //             else {
  //                 enqueueSnackbar(res.message, { variant: "error" });
  //                 setLoading(false);
  //             }
  //         })
  //     }
  //     // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [utility])

  useEffect(() => {
    if (premiseDetail === undefined) {
      getPremiseDetail();
      handleSwitchPlanType("normal");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [consentId, isEstMode]);

  useEffect(() => {
    if (actualusage && showAccessSmtSection) {
      setActualUsage(actualusage);
      setShowAccessSmtSection(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [actualusage]);

  const fetchAddressDelayed = useCallback(
    throttle((text, callback) => {
      if (text && text.trim()) {
        fetchAddress(text.trim()).then(callback);
      }
    }, 1000),
    []
  );

  const getUSNUtility = () => {
    fetchUSNUtility(zipcode).then((res) => {
      if (
        res &&
        res.response &&
        res.response[0] &&
        res.response[0].utility_code
      ) {
        const utilities = res.response.filter(
          (utility:any) => utility.commodity_name === "Electric"
        );

        setUtility(utilities[0]);
      } else {
        setUtility(undefined);
        setGasUtility(undefined);
      }
    });
  };

  const getUtility = () => {
    const str: any = zipcode || "";
    if (str) {
      fetchUtility(str).then((res) => {
        if (res && res[0] && res[0].utility_code) {
          setUtility(res[0]);
          localStorage.setItem("utility", JSON.stringify(res[0]));
        } else {
          setUtility(undefined);
        }
      });
    }
  };

  const getEstUsage = () => {
    setLoading(true);
    setUsage(undefined);
    if (!resolvedStreet || !city || !state || !zipcode) return;
    fetchEstUsage(resolvedStreet, city, "tx", zipcode).then((res) => {
      if (res && res.status === 1) {
        setUsage(res.usages);
        setDetailUsages && setDetailUsages(res.usages)
      }
    });
  };

  const getPremiseDetail = () => {
    if (zipcode && resolvedStreet) {
      fetchPremiseDetail(zipcode, resolvedStreet).then((res) => {
        if (res.status === 1) {
          setPremiseDetail(res.premise);
        }
      });
    }
  };

  const getMonthlyUsage = () => {
    setLoading(true);
    setUsage(undefined);
    const startDate = moment().subtract(1, "years").format("MM/DD/YYYY");
    const endDate = moment().subtract(1, "days").format("MM/DD/YYYY");
    if (!consentId) return;
    fetchMonthlyUsage(esiid, consentId, startDate, endDate).then((res) => {
      if (res && res.response && res.response.reads) {
        const monthlyUsage = mergeMonthlyReads(res.response.reads);
        setUsage(monthlyUsage);
        setIsEstMode(false);
      }
    });
  };

  const getDailyUsage = () => {
    setLoading(true);
    setFreeWeekendUsage(undefined);
    setFree7DaysUsage(undefined);
    if (consentId) {
      const startDate = moment().subtract(1, "years").format("MM/DD/YYYY");
      const endDate = moment().subtract(1, "days").format("MM/DD/YYYY");
      fetchDailyUsage(esiid, consentId, startDate, endDate).then((res) => {
        if (res && res.response && res.response.reads) {
          const free7DaysAndWeekendUsage = mergeDailyReads(res.response.reads);
          setFree7DaysUsage(free7DaysAndWeekendUsage.free7Days);
          setFreeWeekendUsage(free7DaysAndWeekendUsage.freeWeekend);
        }
      });
    } else {
      setFree7DaysUsage(mockTouUsageData as any);
      setFreeWeekendUsage(mockTouUsageData as any);
    }
  };

  const getIntervalUsage = () => {
    setLoading(true);
    setFreeNightUsage(undefined);
    if (consentId) {
      const startDate = moment().subtract(1, "years").format("MM/DD/YYYY");
      const endDate = moment().subtract(1, "days").format("MM/DD/YYYY");
      fetchIntervalUsage(esiid, consentId, startDate, endDate).then((res) => {
        if (res && res.response && res.response.reads) {
          const dayNightUsage = mergeIntervalReads(res.response.reads);
          setFreeNightUsage(dayNightUsage);
        }
      });
    } else {
      setFreeNightUsage(mockTouUsageData as any);
    }
  };

  const mergeMonthlyReads = (data: Array<SmtMonthlyReads>) => {
    const usage: any = {};
    for (const i in data) {
      if (data[i]) {
        const key = moment(data[i].end_date, "MM/DD/YYYY").format("M");
        usage[key] = usage[key]
          ? usage[key] + parseInt(data[i].actual_kwh)
          : parseInt(data[i].actual_kwh);
      }
    }
    return usage;
  };

  const mergeDailyReads = (data: Array<SmtDailyReads>) => {
    const freeWeekend: any = {};
    const free7Days: any = {};
    const dailyUsage: any = [];

    for (const i in data) {
      if (data[i]) {
        const date = moment(data[i].read_date, "MM/DD/YYYY");
        const m = date.format("M");
        const w = date.days();
        const objKey = w === 0 || w === 6 ? "free" : "charge";
        const usage = parseFloat(data[i].energy_data_kwh);

        if (!dailyUsage[m]) dailyUsage[m] = [];
        if (!freeWeekend[m]) freeWeekend[m] = {};

        dailyUsage[m].push(usage);
        freeWeekend[m][objKey] = freeWeekend[m][objKey]
          ? freeWeekend[m][objKey] + usage
          : usage;
      }
    }

    for (const m in dailyUsage) {
      if (dailyUsage[m]) {
        const oneMonth = dailyUsage[m];
        oneMonth.sort((a: string, b: string) => {
          if (parseInt(a) < parseInt(b)) return 1;
          if (parseInt(a) > parseInt(b)) return -1;
          return 0;
        });
        oneMonth.forEach((val: number, idx: number) => {
          const objKey = idx < 7 ? "free" : "charge";
          if (!free7Days[m]) free7Days[m] = {};
          free7Days[m][objKey] = free7Days[m][objKey]
            ? free7Days[m][objKey] + val
            : val;
        });
      }
    }

    return { freeWeekend, free7Days };
  };

  const mergeIntervalReads = (data: Array<SmtIntervalReads>) => {
    const freeNight: any = {};
    for (const i in data) {
      if (data[i]) {
        const datetime = moment(data[i].datetime, "MM/DD/YYYY HH:mm");
        const date = datetime.format("MM/DD/YYYY");
        const m = datetime.format("M");

        const objKey =
          datetime.isSameOrAfter(`${date} 20:00`, "minute") ||
          datetime.isBefore(`${date} 05:59`, "minute")
            ? "free"
            : "charge";
        if (!freeNight[m]) freeNight[m] = {};
        freeNight[m][objKey] = freeNight[m][objKey]
          ? freeNight[m][objKey] + parseFloat(data[i].usage)
          : parseFloat(data[i].usage);
      }
    }

    return freeNight;
  };

  useEffect(() => {
    if (planType === "normal") {
      if (!usage) return;
      getNormalPlans();
      getFilterOptions();
    }
    if (planType === "tou") {
      if (!freeWeekendUsage || !free7DaysUsage || !freeNightUsage) return;
      getTouPlans();
      getFilterOptions();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [usage, freeWeekendUsage, free7DaysUsage, freeNightUsage]);

  useEffect(() => {
    if (!utility || (isTexas === false && showGas === undefined)) return;
    if (planType === "normal") {
      if (isTexas && !usage) return;
      getNormalPlans();
      getFilterOptions();
    }
    if (planType === "tou") {
      if (!freeWeekendUsage || !free7DaysUsage || !freeNightUsage) return;
      getTouPlans();
      getFilterOptions();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterValues, utility, showGas]);

  const sortPlan = (data: Array<_PlanProps>) => {
    const [...newData] = data;
    newData.sort((a: _PlanProps, b: _PlanProps) => {
      switch (selectedSortOption.value) {
        case "1":
          if (Number(a.rate) > Number(b.rate)) return 1;
          if (Number(a.rate) < Number(b.rate)) return -1;
          return 0;
        case "2":
          if (Number(a.rate) < Number(b.rate)) return 1;
          if (Number(a.rate) > Number(b.rate)) return -1;
          return 0;
        case "3":
          if (parseInt(a.contract_term) > parseInt(b.contract_term)) return 1;
          if (parseInt(a.contract_term) < parseInt(b.contract_term)) return -1;
          return 0;
        case "4":
          if (parseInt(a.contract_term) < parseInt(b.contract_term)) return 1;
          if (parseInt(a.contract_term) > parseInt(b.contract_term)) return -1;
          return 0;
        default:
          return 0;
      }
    });

    return paginatePlan(newData);
  };

  const paginatePlan = (data: Array<_PlanProps>) => {
    if (data.length === 0) {
      setPagedPlans(undefined);
      setCurrentPage(1);
      return;
    }
    const per = 5;
    const pagedData: any = {};

    // console.time('paginating');
    let p = 0;
    data.forEach((val, idx) => {
      if (idx % per === 0) p += 1;
      if (!pagedData[p]) pagedData[p] = [];
      pagedData[p].push(val);
    });
    // console.timeEnd('paginating');

    setPagedPlans(pagedData);
  };

  const getPersonalizedPlans = (plans: Array<_PlanProps>) => {
    let bestOverallPlan: _PlanProps | null = null;
    let bestGreenPlan: _PlanProps | null = null;
    let bestLongTermPlan: _PlanProps | null = null;

    const maxContractLength = Math.max(
      ...plans.map((plan) => Number(plan.contract_term))
    );

    for (const plan of plans) {
      if (!bestOverallPlan || plan.rate < bestOverallPlan.rate) {
        bestOverallPlan = plan;
      }

      if (
        Number(plan.renewable) === 100 &&
        (!bestGreenPlan || plan.rate < bestGreenPlan.rate)
      ) {
        bestGreenPlan = plan;
      }

      if (
        Number(plan.contract_term) === maxContractLength &&
        (!bestLongTermPlan || plan.rate < bestLongTermPlan.rate)
      ) {
        bestLongTermPlan = plan;
      }
    }

    const _personalizedPlans: any = {
      bestOverallPlan,
    };
    if (bestOverallPlan !== bestGreenPlan) {
      _personalizedPlans.bestGreenPlan = bestGreenPlan;
    }
    if (bestOverallPlan !== bestLongTermPlan) {
      _personalizedPlans.bestLongTermPlan = bestLongTermPlan;
    }

    setPersonalizedPlans(_personalizedPlans);
  };

  const getNormalPlans = () => {
    setLoading(true);
    setPlans([]);
    setPagedPlans(undefined);
    setCurrentPage(1);
    const _stateId = selectState
      ? selectState.state_id
      : utility?.state_id
        ? utility?.state_id
        : "tx";

    const { utility_code } = showGas ? gasUtility : utility || {};

    if (!utility_code) return;

    if (isTexas === true) {
      fetchAllPlans(
        planType,
        usage,
        zipcode,
        filterValues,
        utility_code,
        _stateId
      ).then((res) => {
        setLoading(false);
        if (res && res.response && res.response.plans) {
          sortPlan(res.response.plans);

          if (!hideRecommendPlans) {
            getPersonalizedPlans(res.response.plans);
          }
          setPlans(res.response.plans);
        }

        if (res.status === "0") {
          setPagedPlans(undefined);
          setCurrentPage(1);
          setPlans([]);
        }
      });
    }
    if (isTexas === false) {
      fetchAllUsnPlans(
        planType,
        new Array(12).fill(parseInt("1000")),
        zipcode,
        filterValues,
        utility_code,
        _stateId
      ).then((res) => {
        setLoading(false);
        if (res && res.response && res.response.plans) {
          sortPlan(res.response.plans);

          if (!hideRecommendPlans) {
            getPersonalizedPlans(res.response.plans);
          }
          setPlans(res.response.plans);
        }

        if (res.status === "0") {
          setPagedPlans(undefined);
          setCurrentPage(1);
          setPlans([]);
        }
      });
    }
  };

  const getTouPlans = async () => {
    setLoading(true);
    setPlans([]);
    setPagedPlans(undefined);
    setCurrentPage(1);
    let plans: Array<_PlanProps> = [];

    const _stateId = selectState
      ? selectState.state_id
      : utility?.state_id
        ? utility?.state_id
        : "tx";

    await fetchAllPlans(
      "night",
      freeNightUsage,
      zipcode,
      filterValues,
      `${utility.utility_code}`,
      _stateId
    ).then((res) => {
      if (res && res.response && res.response.plans) {
        plans = plans.concat(res.response.plans);
      }
    });
    await fetchAllPlans(
      "weekend",
      freeWeekendUsage,
      zipcode,
      filterValues,
      `${utility.utility_code}`,
      _stateId
    ).then((res) => {
      if (res && res.response && res.response.plans) {
        plans = plans.concat(res.response.plans);
      }
    });
    await fetchAllPlans(
      "7days",
      free7DaysUsage,
      zipcode,
      filterValues,
      `${utility.utility_code}`,
      _stateId
    ).then((res) => {
      if (res && res.response && res.response.plans) {
        plans = plans.concat(res.response.plans);
      }
    });
    setLoading(false);
    setPlans(plans);
    sortPlan(plans);
  };

  const getFilterOptions = () => {
    const { utility_code } = showGas ? gasUtility : utility || {};
    const state_id = selectState
      ? selectState.state_id
      : utility?.state_id
        ? utility.state_id
        : "TX";

    if (zipcode && utility_code) {
      if (isTexas === true) {
        fetchFilterOptions(
          planType,
          usage,
          zipcode,
          filterValues,
          utility_code,
          state_id
        ).then((res) => {
          if (res && res.response) {
            const { reps, terms, utilities } = res.response;
            const newAvailableTerms = [];
            const newAvailableReps = [];

            if (primaryFilter.current === "") {
              reps.sort((a: any, b: any) => {
                if (a.name < b.name) return -1;
                if (a.name > b.name) return 1;
                return 0;
              });
              allReps.current = reps;
              setReps(reps);
              terms.sort((a: string, b: string) => {
                if (parseInt(a) < parseInt(b)) return -1;
                if (parseInt(a) > parseInt(b)) return 1;
                return 0;
              });
              allTerms.current = terms;
              setTerms(terms);

              if (showGas) {
                const list = utilities.filter(
                  (item:any) => item.commodity_name === "Gas"
                );
                setUtilities(list);
              } else {
                setUtilities(utilities);
              }
              setInitializeFilter(false);
            }

            if (
              primaryFilter.current === "" ||
              primaryFilter.current === "term" ||
              filterValues.term.length === 0
            ) {
              const _reps =
                filterValues.term.length === 0 ? allReps.current : reps;

              for (const i in _reps) {
                if (_reps[i]) {
                  newAvailableReps.push(_reps[i].provider_id);
                }
              }
              setAvailableReps(newAvailableReps);
            }

            if (
              primaryFilter.current === "" ||
              primaryFilter.current === "provider" ||
              filterValues.provider_id.length === 0
            ) {
              const _terms =
                filterValues.provider_id.length === 0
                  ? allTerms.current
                  : terms;
              for (const i in _terms) {
                if (_terms[i]) {
                  newAvailableTerms.push(_terms[i]);
                }
              }
              setAvailableTerms(newAvailableTerms);
            }
          } else {
            setAvailableTerms([]);
            setAvailableReps([]);
            setInitializeFilter(false);
          }
        });
      }
      if (isTexas === false) {
        fetchUsnFilterOptions(
          planType,
          usage,
          zipcode,
          filterValues,
          utility_code,
          state_id
        ).then((res) => {
          if (res && res.response) {
            const { reps, terms, utilities } = res.response;
            const newAvailableTerms = [];
            const newAvailableReps = [];

            if (primaryFilter.current === "") {
              reps.sort((a: any, b: any) => {
                if (a.name < b.name) return -1;
                if (a.name > b.name) return 1;
                return 0;
              });
              allReps.current = reps;
              setReps(reps);
              handleProvider && handleProvider(reps)
              terms.sort((a: string, b: string) => {
                if (parseInt(a) < parseInt(b)) return -1;
                if (parseInt(a) > parseInt(b)) return 1;
                return 0;
              });
              allTerms.current = terms;
              setTerms(terms);

              const _utilities = utilities.filter(
                (utility:any) => utility.commodity_name === "Electric"
              );

              // if (showGas) {
              //     let list = utilities.filter((item) => item.commodity_name === 'Gas');
              //     setUtilities(list);
              // } else {
              setUtilities(_utilities);
              // }
              setInitializeFilter(false);
            }

            if (
              primaryFilter.current === "" ||
              primaryFilter.current === "term" ||
              filterValues.term.length === 0
            ) {
              const _reps =
                filterValues.term.length === 0 ? allReps.current : reps;

              for (const i in _reps) {
                if (_reps[i]) {
                  newAvailableReps.push(_reps[i].provider_id);
                }
              }
              setAvailableReps(newAvailableReps);
            }

            if (
              primaryFilter.current === "" ||
              primaryFilter.current === "provider" ||
              filterValues.provider_id.length === 0
            ) {
              const _terms =
                filterValues.provider_id.length === 0
                  ? allTerms.current
                  : terms;
              for (const i in _terms) {
                if (_terms[i]) {
                  newAvailableTerms.push(_terms[i]);
                }
              }
              setAvailableTerms(newAvailableTerms);
            }
          } else {
            setAvailableTerms([]);
            setAvailableReps([]);
            setInitializeFilter(false);
          }
        });
      }
    }
  };

  useEffect(() => {
    getDefaultFilterOptions();
  }, [zipcode, utility, usage]);

  const getDefaultFilterOptions = () => {
    const { utility_code } = showGas ? gasUtility : utility || {};
    const _usages =
      usage || isTexas === false ? new Array(12).fill(parseInt("1000")) : false;
    const state_id = selectState
      ? selectState.state_id
      : utility?.state_id
        ? utility.state_id
        : "TX";
    if (allTerm.length > 0) return;
    if (zipcode && utility_code && _usages) {
      const _filterValues = {
        utility_id: "",
        term: [],
        provider_id: [],
        renewable: false,
        prepaid: "all",
        type: "normal",
      };
      if (isTexas === true) {
        fetchFilterOptions(
          planType,
          _usages,
          zipcode,
          _filterValues,
          utility_code,
          state_id
        ).then((res) => {
          if (res && res.response) {
            const { terms, reps } = res.response;
            terms.sort((a: string, b: string) => {
              if (parseInt(a) < parseInt(b)) return -1;
              if (parseInt(a) > parseInt(b)) return 1;
              return 0;
            });
            allTerms.current = terms;
            setAllTerm(terms);
            setAllReps(reps);
            setAvailableTerms(terms);
          }
        });
      }
      if (isTexas === false) {
        fetchUsnFilterOptions(
          planType,
          new Array(12).fill(parseInt("1000")),
          zipcode,
          _filterValues,
          utility_code,
          state_id
        ).then((res) => {
          if (res && res.response) {
            const { terms, reps } = res.response;
            terms.sort((a: string, b: string) => {
              if (parseInt(a) < parseInt(b)) return -1;
              if (parseInt(a) > parseInt(b)) return 1;
              return 0;
            });
            allTerms.current = terms;
            setAllTerm(terms);
            setAllReps(reps);
            setAvailableTerms(terms);
          }
        });
      }
    }
  };

  const setActualUsage = (usage: any) => {
    const actualUsageParams = usage.toString().split("-");
    const actualUsage: MonthlyUsageProps = {};
    for (const i in actualUsageParams) {
      if (actualUsageParams[i]) {
        actualUsage[parseInt(i) + 1] = actualUsageParams[i];
      }
    }
    setUsage(actualUsage);
    setIsEstMode(false);
  };

  const handleSwitchPlanType = (type: string) => {
    setPlanType(type);
    const newFilterValues = {
      utility_code: "",
      term: [],
      provider_id: [],
      renewable: false,
      prepaid: "all",
      type: "normal",
    };
    newFilterValues.type = type;
    setFilterValues(newFilterValues);

    primaryFilter.current = "";
    setInitializeFilter(true);

    setUsage(undefined);
    setFree7DaysUsage(undefined);
    setFreeNightUsage(undefined);
    setFreeWeekendUsage(undefined);
    if (type === "normal") {
      /* These actual usage data are getting from TDU */
      if (actualusage && actualusage.toString().split("-").length === 12) {
        setActualUsage(actualusage);
      } else {
        if (isEstMode) {
          getEstUsage();
        }
        getMonthlyUsage();
      }
    } else {
      getDailyUsage();
      getIntervalUsage();
    }
  };

  useEffect(() => {
    if (loading || !plans) return;
    sortPlan(plans);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedSortOption]);

  const goToEnroll = (plan: any) => {
    localStorage.setItem("selectProduct", JSON.stringify(plan));
    navigate("/enrollment");
  };

  return (
    <>
      <Box>
        <Container>
          {/* <div className="plans-wrapper">
            {detailUsages && handleGetUsage && isTexas && (
              <div className="plans-aside">
                <EstimatedUsageCard
                  data={detailUsages}
                  openUsages={handleGetUsage}
                />
              </div>
            )}
            <div className="plans-list">
              {loading && (
                <div className="pt-5 pb-5">
                  <LoadingRow />
                </div>
              )}
              {!loading && Object.keys(personalizedPlans).length === 0 && (
                !hideRecommendPlans && <NoDataRow />
              )}
              {!loading && Object.keys(personalizedPlans).length > 0 && (
                <div>
                  {(Object.keys(personalizedPlans) as any).map((key: any) => {
                    const val = personalizedPlans[key];
                    if (!val) return null;
                    return (
                      <PlanRow
                        key={val.plan_id}
                        label={key}
                        val={val}
                        usage={usage}
                        hidePrice={planType === "tou" && consentId === ""}
                        handleShowAccessSmtSection={() => {
                          getUsageModalView.current = "smt";
                          setShowAccessSmtSection(true);
                        }}
                        handleCompare={() => {
                          const [...newComparedPlan] = comparedPlan;
                          let idx = -1;
                          for (const i in newComparedPlan) {
                            if (newComparedPlan[i].plan_id === val.plan_id) {
                              idx = parseInt(i);
                              break;
                            }
                          }
                          if (idx >= 0) {
                            newComparedPlan.splice(idx, 1);
                          } else {
                            if (newComparedPlan.length >= 3) {
                              toast.warning(
                                `${"You can compare upto 3 plans"}`
                              );
                              return;
                            }
                            const _plan = { ...val };
                            _plan.is_tou_plan = planType === "tou";
                            _plan.hide_price =
                              planType === "tou" && consentId === "";
                            newComparedPlan.push(_plan);
                          }
                          setComparedPlan(newComparedPlan);
                        }}
                        compare={
                          comparedPlan.filter(
                            (item) => item.plan_id === val.plan_id
                          ).length > 0
                        }
                        handleClickEnroll={(plan: any) => goToEnroll(plan)}
                        handleShowSidebySideModal={(val: _PlanProps) => {
                          sidebysideComparedPlan.current = val;
                          setShowSidebySideModal(true);
                        }}
                      />
                    );
                  })}
                </div>
              )}
            </div>
          </div> */}
          {/* {!loading && !seeAllPlans && !hideRecommendPlans (
            <Box textAlign="center">
              <Button onClick={() => setSeeAllPlans(!seeAllPlans)}>
                See All Plans
              </Button>
            </Box>
          )} */}
        </Container>
      </Box>
      {(seeAllPlans || hideRecommendPlans) && (
        <Container>
          <Typography
            variant="h4"
            fontWeight="600"
            fontSize="18px"
            sx={{
              marginTop: "20px",
            }}
          >
            {t("All Plans")}
            {plans.length > 0 && (
              <span style={{ fontSize: 14, marginLeft: 3 }}>
                ({plans.length})
              </span>
            )}
          </Typography>
          <div className="plans-wrapper">
            <div className="plans-aside">
              {detailUsages && handleGetUsage && isTexas && (
                <div className="plans-aside">
                  <EstimatedUsageCard
                    data={detailUsages}
                    openUsages={handleGetUsage}
                  />
                </div>
              )}
              <div
                id="plans-filter-group"
                className={showFilters ? "show" : ""}
              >
                {!location.pathname.includes("/city") && (
                  <div className="plans-filter">
                    <div className="plans-filter-title">
                      <span>{t("Renewable")}</span>
                    </div>
                    <div className="plans-filter-body">
                      <RenewableFilter
                        initializeFilter={initializeFilter}
                        loading={loading}
                        value={filterValues.renewable}
                        handleClick={() => {
                          const newFilterValues = { ...filterValues };
                          newFilterValues.renewable =
                            !newFilterValues.renewable;
                          setFilterValues(newFilterValues);
                        }}
                      />
                    </div>
                  </div>
                )}
                {!location.pathname.includes("/city") && (
                  <div className="plans-filter">
                    <div className="plans-filter-title">{t("Prepaid")}</div>
                    <div className="plans-filter-body">
                      <PrepaidFilter
                        initializeFilter={initializeFilter}
                        loading={loading}
                        value={filterValues.prepaid}
                        handleClick={(val: any) => {
                          const newFilterValues = { ...filterValues };
                          newFilterValues.prepaid = val;
                          setFilterValues(newFilterValues);
                        }}
                      />
                    </div>
                  </div>
                )}
                {!specifiedProviderId && (
                  <div className="plans-filter">
                    <div className="plans-filter-title">{t("Provider")}</div>
                    <div className="plans-filter-body">
                      <ProviderFilter
                        initializeFilter={initializeFilter}
                        value={filterValues.provider_id}
                        loading={loading}
                        handleClick={(val: any) => {
                          const newFilterValues = { ...filterValues };

                          if (val !== "-1") {
                            // const idx = newFilterValues.provider_id.indexOf(val)
                            // if (idx >= 0) {
                            //     newFilterValues.provider_id.splice(idx, 1)
                            // } else {
                            //     newFilterValues.provider_id.push(val)
                            // }
                            newFilterValues.provider_id = [val];
                          } else {
                            newFilterValues.provider_id = [];
                          }
                          if (newFilterValues.provider_id.length > 0) {
                            if (primaryFilter.current === "") {
                              primaryFilter.current = "provider";
                            }
                          } else if (primaryFilter.current === "provider") {
                            if (newFilterValues.term.length > 0) {
                              primaryFilter.current = "term";
                            } else {
                              primaryFilter.current = "";
                            }
                          }

                          setFilterValues(newFilterValues);
                          setShowAllReps(false);
                        }}
                        availableReps={availableReps}
                        reps={reps}
                        defalutReps={defaultReps}
                        showAllReps={showAllReps}
                      />
                    </div>
                  </div>
                )}
                {utilities.length > 1 && (
                  <div className="plans-filter">
                    <div className="plans-filter-title">{t("Utilities")}</div>
                    <div className="plans-filter-body">
                      <UtilitiesFilter
                        initializeFilter={initializeFilter}
                        loading={loading}
                        utilities={utilities}
                        value={filterValues.utility_code}
                        handleClick={(val: string) => {
                          const newFilterValues = { ...filterValues };
                          newFilterValues.utility_code = val;
                          setFilterValues(newFilterValues);
                        }}
                      />
                    </div>
                  </div>
                )}
                <div
                  className="position-absolute"
                  style={{ top: 20, right: 20 }}
                >
                  <BootstrapButton
                    className="close-btn"
                    variant="link"
                    onClick={() => {
                      setShowFilters(false);
                    }}
                    style={{ fontSize: 32, color: "#333" }}
                  >
                    <FontAwesomeIcon icon={faTimes} />
                  </BootstrapButton>
                </div>
              </div>
            </div>
            <div className="plans-list">
              <div
                className={css`
                  max-width: 980px;
                  margin: 0 auto;
                `}
              >
                <div
                  id="plans-list-header"
                  className="d-flex align-items-center justify-content-between"
                >
                  <div
                    className={`d-flex align-items-center justify-content-center ${css`
                      @media (max-width: 750px) {
                        width: 100%;
                        flex-direction: column;
                      }
                    `}`}
                  >
                    <div id="term-filter-wrapper">
                      <TermFilter
                        initializeFilter={initializeFilter}
                        availableTerms={availableTerms}
                        loading={loading}
                        handleClick={(val: any) => {
                          const { ...newFilterValues } = filterValues;

                          if (val !== "0") {
                            newFilterValues.term = val.split(",");
                          } else {
                            newFilterValues.term = [];
                          }
                          if (newFilterValues.term.length > 0) {
                            if (primaryFilter.current === "") {
                              primaryFilter.current = "term";
                            }
                          }
                          if (
                            newFilterValues.term.length <= 0 &&
                            primaryFilter.current === "term"
                          ) {
                            if (newFilterValues.provider_id.length > 0) {
                              primaryFilter.current = "provider";
                            } else {
                              primaryFilter.current = "";
                            }
                          }

                          setFilterValues(newFilterValues);
                          setShowAllTerm(false);
                        }}
                        terms={terms}
                        allTerm={allTerm}
                        showAllTerms={showAllTerm}
                      />
                    </div>
                    <div
                      className={`d-flex align-items-center ${css`
                        @media (max-width: 750px) {
                          width: 100%;
                        }
                      `}`}
                    >
                      <BootstrapButton
                        variant="light"
                        id="plans-filter-toggle"
                        onClick={() => {
                          setShowFilters(!showFilters);
                        }}
                        className="me-3"
                      >
                        <FontAwesomeIcon
                          icon={faSlidersH}
                          className="me-2"
                          width={16}
                          height={16}
                        />
                        {t("Plan Filters")}
                      </BootstrapButton>
                      <Dropdown
                        id="plans-sort-toggle"
                        className="plans-filter-dd"
                      >
                        <Dropdown.Toggle
                          variant="light"
                          disabled={loading || plans.length === 0}
                        >
                          {((val) => {
                            const res = sortOptions.filter(
                              (item) => item.value === val
                            );
                            return res[0].label;
                          })(selectedSortOption.value)}
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                          {sortOptions.map((val, idx) => (
                            <Dropdown.Item
                              eventKey={val}
                              key={idx}
                              onClick={() => {
                                setSelectedSortOption(val);
                              }}
                              active={selectedSortOption.value === val.value}
                            >
                              {val.label}
                            </Dropdown.Item>
                          ))}
                        </Dropdown.Menu>
                      </Dropdown>
                    </div>
                  </div>
                </div>
                {loading && (
                  <div className="pt-5 pb-5">
                    <LoadingRow />
                  </div>
                )}
                {!loading && !pagedPlans && <NoDataRow />}
                {!loading && pagedPlans && (
                  <div>
                    {planType === "tou" && consentId === "" && (
                      <NoRateTipsRow />
                    )}
                    {(pagedPlans as any)[currentPage].map(
                      (val: _PlanProps, index: any) => (
                        <div key={index}>
                          <PlanRow
                            key={val.plan_id}
                            val={val}
                            usage={usage}
                            hidePrice={planType === "tou" && consentId === ""}
                            handleShowAccessSmtSection={() => {
                              getUsageModalView.current = "smt";
                              setShowAccessSmtSection(true);
                            }}
                            handleCompare={() => {
                              const [...newComparedPlan] = comparedPlan;
                              let idx = -1;
                              for (const i in newComparedPlan) {
                                if (
                                  newComparedPlan[i].plan_id === val.plan_id
                                ) {
                                  idx = parseInt(i);
                                  break;
                                }
                              }
                              if (idx >= 0) {
                                newComparedPlan.splice(idx, 1);
                              } else {
                                if (newComparedPlan.length >= 3) {
                                  toast.warning(
                                    `${"You can compare upto 3 plans"}`
                                  );
                                  return;
                                }
                                const _plan = { ...val };
                                _plan.is_tou_plan = planType === "tou";
                                _plan.hide_price =
                                  planType === "tou" && consentId === "";
                                newComparedPlan.push(_plan);
                              }
                              setComparedPlan(newComparedPlan);
                            }}
                            compare={
                              comparedPlan.filter(
                                (item) => item.plan_id === val.plan_id
                              ).length > 0
                            }
                            handleClickEnroll={(plan: any) => goToEnroll(plan)}
                            handleShowSidebySideModal={(val: _PlanProps) => {
                              sidebysideComparedPlan.current = val;
                              setShowSidebySideModal(true);
                            }}
                          />
                        </div>
                      )
                    )}
                    {Object.keys(pagedPlans).length > 1 && (
                      <div className="mt-5 d-flex justify-content-center">
                        {/* <Pagination>
                                                    {Object.keys(pagedPlans).map((key: string) => (
                                                        <Pagination.Item
                                                            key={key}
                                                            active={parseInt(key) === currentPage}
                                                            onClick={(evt) => {
                                                                setCurrentPage(
                                                                    parseInt(
                                                                        (evt.target as any).innerText
                                                                    )
                                                                )
                                                                window.scrollTo(0, 0)
                                                            }}>
                                                            {key}
                                                        </Pagination.Item>
                                                    ))}
                                                </Pagination> */}

                        <Pagination
                          count={Math.ceil(plans.length / 5)}
                          showFirstButton
                          showLastButton
                          onChange={(evt, num) => {
                            setCurrentPage(num);
                            window.scrollTo({
                              top: 0,
                              behavior: 'smooth'
                            });
                          }}
                        />
                      </div>
                    )}
                  </div>
                )}
              </div>
            </div>
          </div>
          <GetUsageModal
            show={showAccessSmtSection}
            handleClose={() => {
              setShowAccessSmtSection(false);
              getUsageModalView.current = "";
            }}
            handleSetConsentId={(id: string) => {
              setConsentId(id);
              setCookie("consent_id", id, { path: "/", maxAge: 3600 });
            }}
            handleFetchActualUsage={() => getMonthlyUsage()}
            defaultView={getUsageModalView.current}
            zipcode={zipcode ?? ""}
          />
          <CompareToggle
            number={comparedPlan.length}
            handleCompare={() => {
              setShowCompareModal(true);
            }}
            handleClear={() => setComparedPlan([])}
          />
          <CompareModal
            show={showCompareModal}
            handleCloseModal={() => {
              setShowCompareModal(false);
            }}
            comparePlans={comparedPlan}
            handleClickEnroll={(plan: any) => goToEnroll(plan)}
          />
          <SidebySideModal
            show={showSidebySideModal}
            thatPlan={sidebysideComparedPlan.current}
            handleClose={() => {
              sidebysideComparedPlan.current = undefined;
              setShowSidebySideModal(false);
            }}
            zipcode={zipcode ?? ""}
            utilityCode={`${utility ? utility.utility_code : ""}`}
            usages={usage}
            planType={planType}
            handleClickEnroll={(plan:any) => goToEnroll(plan)}
            terms={filterValues.term}
          />
        </Container>
      )}
    </>
  );
};

const CompareToggle = ({
  number,
  handleCompare,
  handleClear,
}: {
  number: number;
  handleCompare: Function;
  handleClear: Function;
}) => {
  const { t } = useTranslation("plan");
  return (
    <CompareToggleBar show={number > 0}>
      <Button disabled={number < 2} onClick={() => handleCompare()}>
        {t("Compare")} {number}/3
      </Button>
      <CompareClearBtn onClick={() => handleClear()}>
        {t("Clear All")}
      </CompareClearBtn>
    </CompareToggleBar>
  );
};

const GetUsageModal: React.FC<GetUsageModalProps> = ({
  show,
  handleClose,
  handleSetConsentId,
  handleFetchActualUsage,
  defaultView,
  zipcode,
}) => {
  if (!show) return null;
  return (
    <div className={`${show ? "active" : ""}`}>
      <div className="side-modal-body">
        <div className="position-absolute" style={{ top: 20, right: 20 }}>
          <BootstrapButton
            className="close-btn"
            variant="link"
            onClick={() => handleClose()}
            style={{ fontSize: 32, color: "#333" }}
          >
            <FontAwesomeIcon icon={faTimes} />
          </BootstrapButton>
        </div>
        <GetUsageComponent
          fetchActualUsage={() => {
            handleFetchActualUsage();
            handleClose();
          }}
          handleSetConsentId={handleSetConsentId}
          defaultView={defaultView}
          inModal
          zipcode={zipcode}
          forcastUsage={() => {
            handleClose();
          }}
        />
      </div>
      <div className="mask" onClick={() => handleClose()} />
    </div>
  );
};

const NoRateTipsRow = () => {
  const { t } = useTranslation("plan");
  return (
    <div
      className={css`
        display: flex;
        background-color: #fef6f0;
        font-size: 14px;
        color: #666;
        line-height: 1.5;
        margin: 14px 0;
        border-radius: 12px;
        padding: 20px 24px;
        align-items: center;
        @media (max-width: 640px) {
          flex-direction: column;
          margin-left: -4px;
          margin-right: -4px;
          align-items: flex-start;
        }
      `}
    >
      <span
        className={css`
          flex-shrink: 0;
          color: #e8955b;
          font-size: 16px;
          display: inline-block;
          font-weight: 500;
          margin: 0 16px 0 0;
        `}
      >
        {t("No rate showing?")}
      </span>
      <span>
        {t(
          "To calculate your personalized rate, we need to access your smart meter (SMT) data to determine your time of use. Click [SMT Consent] to enable calculation and find the best rate for you."
        )}
      </span>
    </div>
  );
};

const NoDataRow = () => {
  const { t } = useTranslation("plan");

  return (
    <div
      className={css`
        padding-top: 44px;
        padding-bottom: 44px;
        text-align: center;
        color: #666;
      `}
    >
      {t("No Data Found")}
    </div>
  );
};
