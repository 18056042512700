import {
  Autocomplete,
  Box,
  Button,
  ButtonGroup,
  Container,
  Grid,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
  Typography,
} from "@mui/material";
import throttle from "lodash/throttle";
import { useParams, useNavigate } from "react-router-dom";
import React, { useCallback, useContext, useEffect, useState } from "react";
import {
  fetchAllPlans,
  fetchAllUsnPlans,
  fetchComparisonData,
  fetchFilterOptions,
  fetchMarketComparisonData,
  fetchProviderData,
  fetchUsnFilterOptions,
} from "../../api/city";

import {
  AddressProps,
  ChartDataTypes,
  PlanProps,
  PlanTypes,
  ProviderType,
  Utilities,
} from "../../types";

import { CityGuideSection } from "./CityGuideSection";
import { capitalizeString } from "../CommonFun";
import { CompareChart } from "./CompareChart";
import { ProviderIntroSection } from "./ProviderIntroSection";
import { fetchAddress } from "../../api/city";
import { RollingNumber } from "./RollingNumber";
import useGetState from "../hooks/useGetState";
import useIsTexas from "../hooks/useIsTexas";
import { PlansList } from "./plans/PlansList";
import SelectCity from "../SelectCity";
import { LoadingRow } from "./LoadingRow";
import useUtilities from "../hooks/useUtilities";
import CompareOthersSection from "./CompareOthersSection";
import { providerDescriptions } from "./provider_descriptions";
import { findProviderByName, mergeProviderData } from "../../utils/helper";
import { CityPageContext } from "../../pages/city-page";

type ProviderListType = {
  business_name: string;
  show_name: string;
  description: string;
  provider_id: string;
  provider_name: string;
};

const ProviderSection = () => {

  // const { slug } = useParams();  // Use useParams from React Router
  const navigate = useNavigate();  // Use useNavigate for routing

  const { state = "", city = "", zipcode = "", type = "" } = useParams<{
    state?: string;
    city?: string;
    zipcode?: string;
    type?: string;
  }>();
  const [address, setAddress] = useState(""); // input value
  const [selectedAddress, setSelectedAddress] = useState<AddressProps | undefined>(undefined);
  const [addressOptions, setAddressOptions] = useState([]);
  const [addressLoading, setAddressLoading] = useState(false);
  const [selectedTerm, setSelectedTerm] = useState("12");
  const [chartData, setChartData] = useState<ChartDataTypes[]>([]);
  const [comparedPlans, setComparedPlans] = useState<{ [key: string]: PlanTypes[] } | undefined>(undefined);
  const [loading, setLoading] = useState(false);
  const [utility, setUtility] = useState<Utilities | undefined>(undefined);
  const [gasUtility, setGasUtility] = useState<Utilities | undefined>(undefined);
  const [avgMonthlyUse, setAvgMonthlyUse] = useState("1000");
  const [termOptions, setTermOptions] = useState([]);
  const [products, setProducts] = useState<PlanProps[]>([]);
  const [chartLoading, setChartLoading] = useState(false);
  const [providerList, setProviderList] = useState<ProviderListType[] | undefined>(undefined);
  const [providers, setProviders] = useState<ProviderType[]>([]);

  const { data: utilityData } = useUtilities(selectedAddress?.zipcode || zipcode);
  const { stateArray, cityArray } = useContext<any>(CityPageContext);

  useEffect(() => {
    if (type) {
      console.log(type)
      window.scrollTo(0, 0);
    }
  }, [type]);

  useEffect(() => {
    console.log(providers, 'providers')
  }, [providers])

  useEffect(() => {
    fetchProviderData("").then((res) => {
      if (res && res.status === "1") {
        const txProviders = res?.response?.TX || [];

        const formattedList = mergeProviderData(
          txProviders,
          providerDescriptions
        );
        setProviderList(formattedList);
      }
    });
  }, []);

  const isTexas = useIsTexas(state);
  const selectState = useGetState(state);

  useEffect(() => {
    setUtility(utilityData?.electricity?.[0]);
    setGasUtility(utilityData?.gas?.[0]);
  }, [utilityData]);

  useEffect(() => {
    setLoading(true);
    setChartData([]);
    setProducts([]);
    setComparedPlans(undefined);
  }, [type]);

  useEffect(() => {
    if (!type || !providerList) return;

    let providerNames = [];
    if (type.includes("-vs-")) {
      providerNames = type.split("-vs-");
    } else {
      providerNames.push(type);
    }

    const providerArr: ProviderType[] = [];

    const provider1 =
      providerNames[0] && findProviderByName(providerList, providerNames[0]);
    const provider2 =
      providerNames[1] && findProviderByName(providerList, providerNames[1]);

    if (provider1) providerArr.push(provider1);
    if (provider2) providerArr.push(provider2);

    setProviders(providerArr);
  }, [providerList, type]);

  useEffect(() => {
    if (!utility || providers.length === 0) return;

    getAvgPrice();
  }, [utility, selectedTerm, avgMonthlyUse, providers]);

  useEffect(() => {
    generateChartData();
  }, [products, comparedPlans]);

  const fetchAddressDelayed = useCallback(
    throttle((text, callback) => {
      if (text && text.trim()) {
        setAddressLoading(true);
        fetchAddress(text.trim()).then(callback);
      }
    }, 1000),
    []
  );

  useEffect(() => {
    fetchAddressDelayed(address, (result: any) => {
      setAddressLoading(false);
      setAddressOptions(result || []);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [address]);

  useEffect(() => {
    if (!utility || selectedAddress) return;
    getTermOptions();
    getProducts();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [utility]);

  const getTermOptions = async () => {
    const utilityCode = utility?.utility_code || "";
    const state_id = utility?.state_id || "TX";
    const _usages = new Array(12).fill(parseInt("1000"));

    const res = isTexas
      ? await fetchFilterOptions(
        "normal",
        _usages,
        zipcode,
        [],
        utilityCode,
        state_id
      )
      : fetchUsnFilterOptions(
        "normal",
        _usages,
        zipcode,
        [],
        utilityCode,
        state_id
      );

    if (res?.response) {
      const { terms } = res.response;
      terms.sort((a: string, b: string) => {
        if (parseInt(a) < parseInt(b)) return -1;
        if (parseInt(a) > parseInt(b)) return 1;
        return 0;
      });
      setTermOptions(terms);
    } else {
      setLoading(false);
    }
  };

  const getAvgPrice = async () => {
    const _usages = new Array(12).fill(parseInt(avgMonthlyUse));
    const utilityCode = utility?.utility_code;
    const term = selectedTerm || "12";

    if (!utilityCode) return;

    let res = null;

    if (providers.length > 1) {
      res = await fetchComparisonData(
        providers[0].provider_id,
        providers[1].provider_id,
        term,
        utilityCode,
        _usages
      );
    } else {
      res = await fetchMarketComparisonData(
        providers[0].provider_id,
        term,
        utilityCode,
        _usages
      );
    }
    setLoading(false);
    if (res?.code === "200" || res?.code === 1) {
      setComparedPlans(res.response);
    } else {
      setChartLoading(false);
    }
  };

  const findLowestRate = (plans: PlanTypes[]) => {
    let lowest = 0;
    plans.forEach((plan: PlanTypes) => {
      if (lowest === 0 || lowest > plan.total_rate) lowest = plan.total_rate;
    });
    return lowest;
  };

  const findAvgRate = (plans: PlanTypes[]) => {
    const totalRate = plans.reduce(
      (acc: number, cur: PlanTypes) => acc + cur.total_rate,
      0
    );
    return totalRate / plans.length;
  };

  const generateChartData = () => {
    if (!comparedPlans) return;

    let _chartData = [];
    const _otherReps = [];

    const targetId = providers?.[0]?.provider_id || "";
    if (comparedPlans?.[targetId]) {
      _chartData.push({
        id: targetId,
        value: Math.ceil(findLowestRate(comparedPlans[targetId]) * 100) / 100,
        label: comparedPlans[targetId][0].provider_name,
        isTx: isTexas,
      });
    }

    for (const i in comparedPlans) {
      if (i !== providers?.[0]?.provider_id) {
        _otherReps.push({
          id: i,
          value: Math.ceil(findAvgRate(comparedPlans[i]) * 100) / 100,
          label: comparedPlans[i][0].provider_name,
          isTx: isTexas,
        });
      }
    }
    _otherReps.sort((a, b) => {
      if (a.value < b.value) return -1;
      if (a.value > b.value) return 1;
      return 0;
    });

    _chartData = _chartData.concat(_otherReps.slice(-3));

    setChartData(_chartData);
    setChartLoading(false);
  };

  const handleChangeAddress = (evt: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = evt.target;
    setAddress(value);
  };

  const onSubmit = async () => {
    if (!selectedAddress) return;

    localStorage.setItem("defaultAddress", JSON.stringify(selectedAddress));
    localStorage.setItem("utility", JSON.stringify(utility));
    const utilityCode = utility ? utility.utility_code : '';
    const city = selectedAddress.city.toLowerCase();
    const street = selectedAddress.street.toLowerCase();
    navigate(`/plans?address=${encodeURIComponent(street)}&esiid=${selectedAddress.esiid}&zipcode=${selectedAddress.zipcode}&city=${city}&state=${selectedAddress.state}&utcode=${utilityCode}`);
  };

  const getProducts = async () => {
    const utilityCode = utility?.utility_code;
    const state_id = utility?.state_id || "TX";
    const usage = new Array(12).fill(parseInt(avgMonthlyUse));

    const res = isTexas
      ? await fetchAllPlans("normal", usage, zipcode, [], utilityCode, state_id)
      : await fetchAllUsnPlans(
        "normal",
        usage,
        zipcode,
        [],
        utilityCode,
        state_id
      );

    if (res && Number(res.status) === 1) {
      const { plans } = res.response;
      if (plans) {
        setProducts(plans);
      }
    } else {
      setProducts([]);
      setLoading(false);
    }
  };

  let energySavings = 0;

  if (chartData && chartData.length > 0) {
    const term = selectedTerm || "12";
    const rateDiff = chartData[chartData.length - 1].value - chartData[0].value;
    energySavings =
      (rateDiff * parseFloat(avgMonthlyUse) * parseInt(term)) / 100;
  }

  return (
    <>
      <Box py={5} sx={{ backgroundColor: "#F2F5FB" }}>
        <Container>
          <Grid
            container
            position="relative"
            alignItems="center"
            justifyContent={{ xs: "center", sm: "space-between" }}
            gap="20px"
          >
            <Grid
              item
              xs={12}
              sm={7}
              position="relative"
              zIndex="1"
              textAlign="left"
            >
              {providers?.[0] && (
                <Box>
                  <Box>
                    <img
                      src={`https://dorzjv601fuva.cloudfront.net/tx/${providers?.[0].provider_id}.png`}
                      width="160"
                      alt=""
                    />
                  </Box>
                  <Typography
                    variant="h2"
                    fontWeight="500"
                    mt={2}
                    mb={1}
                    sx={{ fontSize: "1.5rem !important" }}
                  >
                    {providers.length > 1
                      ? `${capitalizeString(
                        providers?.[0].show_name
                      )} vs. ${capitalizeString(
                        providers?.[1].show_name
                      )} in ${capitalizeString(city)},${" "}
                  ${capitalizeString(state)} ${zipcode}`
                      : `${capitalizeString(
                        providers?.[0].show_name
                      )} in ${capitalizeString(city)},${" "}
                    ${capitalizeString(state)} ${zipcode}`}
                  </Typography>
                  <Typography fontSize="0.85rem">
                    {providers?.[0].description}
                  </Typography>
                </Box>
              )}
            </Grid>
            <Grid item xs={12} sm={4}>
              <Box
                px={3}
                py={4}
                textAlign="center"
                sx={{
                  backgroundColor: "#fff",
                  borderRadius: "2rem",
                  boxShadow: "0 4px 8px #00000008",
                }}
              >
                <Typography variant="h4" sx={{ fontSize: "1.25rem" }} mt={3}>
                  {isTexas
                    ? "Enter your address to see prices"
                    : `${capitalizeString(city)} ${selectState
                      ? selectState?.state_id
                      : capitalizeString(state)
                    } ${zipcode}`}
                </Typography>
                {isTexas && (
                  <Autocomplete
                    fullWidth
                    freeSolo
                    onChange={(_, val: any) => {
                      setAddress(val ? val.label : "");
                      setSelectedAddress(val);
                    }}
                    options={addressOptions.map((option: any) => option)}
                    renderOption={(props, option) => (
                      <li {...props} key={option.id}>
                        {option.label}
                      </li>
                    )}
                    loading={addressLoading}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Please Enter Your Address"
                        value={address}
                        onChange={handleChangeAddress}
                        disabled={loading}
                      />
                    )}
                    sx={{ mt: 2 }}
                    className="card-autocomplete"
                  />
                )}
                {isTexas && (
                  <Button
                    variant="contained"
                    onClick={onSubmit}
                    disabled={loading}
                    size="large"
                    sx={{ mt: 4 }}
                  >
                    Get Started
                  </Button>
                )}
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Box>

      {isTexas && (
        <Box py={3}>
          <Container>
            <Box textAlign="center">
              {chartLoading && <LoadingRow />}
              {chartData.length > 0 && (
                <Grid
                  container
                  flexDirection={{ xs: "column-reverse", sm: "row" }}
                  justifyContent={{ xs: "center", sm: "space-between" }}
                >
                  <Grid item xs={12} sm={7} ml={{ xs: 0, sm: 2 }}>
                    {city && (
                      <Typography
                        variant="h1"
                        letterSpacing="normal"
                        fontSize="1.25rem"
                        fontWeight="500"
                        lineHeight="1.5"
                        mb={3}
                      >
                        {providers.length > 1
                          ? `Compare ${capitalizeString(
                            providers[0].show_name
                          )} & ${capitalizeString(
                            providers[1].show_name
                          )}'s Cheapest Energy Plans in ${capitalizeString(
                            state
                          )} ${capitalizeString(city)} ${zipcode}`
                          : `Compare ${capitalizeString(
                            providers[0].show_name
                          )}'s Cheapest Energy Plans in ${capitalizeString(
                            state
                          )} ${capitalizeString(city)} ${zipcode}`}
                      </Typography>
                    )}

                    <Box
                      display="flex"
                      justifyContent="space-between"
                      alignItems="center"
                      flexDirection={{ xs: "column", sm: "inherit" }}
                      gap={1}
                    >
                      <Box>
                        <Typography textAlign="left" mb={1}>
                          Avg. Monthly Use:
                        </Typography>
                        <ButtonGroup size="large">
                          {["500", "1000", "2000"].map((val, idx) => (
                            <Button
                              sx={{
                                backgroundColor: avgMonthlyUse === val ? "#38c3a8" : "transparent",
                                color: avgMonthlyUse === val ? "#fff" : "#38c3a8",
                                borderColor: avgMonthlyUse === val ? "#38c3a8" : "#38c3a8",
                                "&:hover": {
                                  backgroundColor: avgMonthlyUse === val ? "#2AA794" : "#e0f7f3",
                                  borderColor: avgMonthlyUse === val ? "#2AA794" : "#38c3a8",
                                },
                              }}
                              variant={
                                avgMonthlyUse === val ? "contained" : "outlined"
                              }
                              key={idx}
                              onClick={() => {
                                setAvgMonthlyUse(val);
                              }}
                            >{`${val} kWh`}</Button>
                          ))}
                        </ButtonGroup>
                      </Box>
                      <Box>
                        <Typography textAlign="left" mb={1}>
                          Term:
                        </Typography>
                        <Select
                          labelId="term-select-label"
                          id="term-select"
                          value={selectedTerm || "12"}
                          onChange={(evt: SelectChangeEvent) => {
                            setSelectedTerm(evt.target.value as string);
                          }}
                          disabled={loading}
                        >
                          {termOptions.map((val, idx) => (
                            <MenuItem
                              value={val}
                              key={idx}
                            >{`${val} Months`}</MenuItem>
                          ))}
                        </Select>
                      </Box>
                    </Box>

                    <CompareChart chartData={chartData} />
                  </Grid>

                  <Grid
                    container
                    item
                    xs={12}
                    sm={4}
                    alignItems="center"
                    justifyContent={{ xs: "center", sm: "flex-end" }}
                    sx={{ marginTop: { xs: 3, sm: 0 } }}
                    display="flex"
                  >
                    <Box
                      sx={{
                        border: "1px solid #eee",
                        borderRadius: "2rem",
                        padding: "20px 32px",
                        boxShadow: "0 4px 8px rgba(0, 0, 0, 0.03)",
                        textAlign: "center",
                      }}
                    >
                      <Typography variant="h6" lineHeight="1.3" mb={2}>
                        Compare our electricity plans and start saving money
                        today!
                      </Typography>
                      <Typography variant="h4">
                        <Typography>Estimated Total Savings </Typography>
                        <RollingNumber
                          data={energySavings}
                          styles={{
                            color: "#30d886",
                            fontWeight: 700,
                            fontSize: 55,
                            marginTop: "1.5rem",
                            display: "block",
                          }}
                        />
                        <Typography sx={{ fontSize: "0.875rem" }}>
                          based on {avgMonthlyUse} Kwh
                        </Typography>
                      </Typography>
                      <Typography
                        mt={2}
                        sx={{ fontSize: "0.875rem", lineHeight: 1.3 }}
                      >
                        *Actual total savings will be based on your personal
                        usage and electricity plan selected.
                      </Typography>
                    </Box>
                  </Grid>
                </Grid>
              )}
            </Box>
          </Container>
        </Box>
      )}

      <Box>
        {providers?.[0] && (
          <>
            <Typography
              variant="h3"
              textAlign="center"
              mb={1}
              mt={3}
              sx={{ fontSize: "1.5rem", fontWeight: 500 }}
            >
              5 Top Electricity Rates
            </Typography>
            <Typography
              variant="h3"
              textAlign="center"
              sx={{ fontSize: "1.2rem", fontWeight: 500 }}
            >{`${capitalizeString(
              providers[0].show_name
            )}'s Cheapest Energy Plans in ${capitalizeString(
              state
            )} ${capitalizeString(city)} ${zipcode}`}</Typography>
          </>
        )}
        <PlansList
          specifiedProviderId={providers?.[0]?.provider_id}
          hideRecommendPlans
        />
      </Box>

      <Box sx={{ backgroundColor: "#F2F5FB" }}>
        <Container>
          <CityGuideSection
            city={city}
            zipcode={zipcode}
            utility={utility!}
            gasUtility={gasUtility!}
            type="all"
          />
        </Container>
      </Box>

      <Box>
        <Container>
          <ProviderIntroSection
            city={city}
            zipcode={zipcode}
            type={type}
            state={selectState?.state_name?.toLowerCase() || ""}
            providerList={providerList}
          />
        </Container>
      </Box>

      <Box py={5}>
        <Container maxWidth="md">
          <CompareOthersSection
            targetProvider={providers?.[0]}
            providerList={providerList || []}
          />
        </Container>
      </Box>

      <Box py={5}>
        <Container maxWidth="md">
          {stateArray.length > 0 && cityArray.length > 0 && <SelectCity
            selectedCity={city}
            stateArray={stateArray}
            cityArray={cityArray}
          />}
        </Container>
      </Box>
    </>
  );
};

export default ProviderSection;
