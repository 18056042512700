import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from "react-i18next";
import { Form } from 'react-bootstrap';
import { Button } from '../../styles/Button.styled';
import { useLocation } from 'react-router-dom';
import Select from 'react-select';
import { CheckboxItem } from '../../checkbox-item';
import {
    fetchRepList,
    newAgreement,
    getMyAccountInfo,
    getBillInfo
} from '../../../api/api'
import qs from 'qs';
import type { Rep, SmtGetInfoProps } from '../../../types';
import { FormError } from '../../enrollment/FormError';
import { toast } from 'react-toastify';
import { RollingTextLoading } from '../../rolling-text-loading';

// 4change, bulb, green mountain, pepco, veteran
const unsupportedRepList = ['GME', 'VTR'];

export const MyAccountInfo: React.FC<SmtGetInfoProps> = ({ handleShowAgreement, handleBack, hideBack }) => {
    const { t } = useTranslation('usage');
    const location = useLocation();
    const [ providers, setProviders ] = useState<Rep[]>([]);
    const [ loadingProviders, setLoadingProviders ] = useState(false);
    const [ validated, setValidated ] = useState(false);
    const [ selectedProvider, setSelectedProvider ] = useState<Rep|undefined>(undefined);
    const [ showProviderError, setShowProviderError ] = useState(false);
    const [ showConsentError, setShowConsentError ] = useState(false);
    const [ submitting, setSubmitting ] = useState(false);
    const [ isAgree, setIsAgree ] = useState(false);
    const [ username, setUsername ] = useState('');
    const [ password, setPassword ] = useState('');
    const [ isUnsupportedRep, setIsUnsupportedRep ] = useState(false);
    const startWithOne = useRef(false);
    
    useEffect(() => {
        setLoadingProviders(true)
        fetchRepList().then(res => {
            if (res) setProviders(res)
            setLoadingProviders(false)
        })
    }, [])

    const handleSubmit = (evt: React.FormEvent) => {
        evt.preventDefault();
        evt.stopPropagation();

        const esiid = qs.parse(location.search, { ignoreQueryPrefix: true }).esiid;
        const form = evt.currentTarget as HTMLInputElement;
        const validProvider = handleValidProvider()
        setValidated(true);
        if (!isAgree) {
            setShowConsentError(true);
            return;
        }
        if (!form.checkValidity() || !validProvider || !selectedProvider || !esiid || submitting || isUnsupportedRep) return;

        setSubmitting(true);
        
        getBillInfo(
            selectedProvider.value,
            username,
            password
        ).then(res => {
            if (res?.status === 1) {
                const { meter_ids } = res.response || {};
                if (meter_ids.length > 0) {
                    const meterNumber = `${meter_ids[0]}`;
                    if (meterNumber.substr(0, 1) === '1') startWithOne.current = true;
                    let email = '';
                    
                    // eslint-disable-next-line
                    let pattern = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g;
                    if (pattern.test(username)) {
                        email = username;
                    }
                    else {
                        email = `rep_${username}@eiqshopping.com`;
                    }
                    getAgreement(`${esiid}`, meterNumber, selectedProvider.certificate_number, email);
                }
                else {
                    setSubmitting(false);
                    toast.error(`${t('Meter number not found')}`);
                }
            }
            else {
                setSubmitting(false);
                toast.error(`${t('Login failed')}`);
            }
        })
    }

    const getAgreement = (esiid: string, meterNumber: string, certificateNumber: string, email: string) => {
        newAgreement(
            esiid,
            meterNumber,
            certificateNumber,
            email,
            '0'
        ).then(res => {
            if (res && res.response && res.response.consent_id) {
                handleShowAgreement(res.response.consent_id, email);
            }
            else {
                if (startWithOne.current === true) {
                    startWithOne.current = false;
                    getAgreement(esiid, meterNumber.substr(1), certificateNumber, email);
                }
                else {
                    if (res.error_code && res.error_code === '500') {
                        toast.error(`${t('Incorrect ESIID-METER-REP Combination')}`);
                    }
                    setSubmitting(false);
                    console.error('error: new agreement');
                }
            }
        })
    }

    const handleValidProvider = () => {
        let isValid = true
        setShowProviderError(false)
        if (!selectedProvider){
            isValid = false
            setShowProviderError(true)
        }
        return isValid
    }

    const selectStyles = {
        control: (style: object) => ({
            ...style,
            borderColor: showProviderError ? '#dc3545 !important' : '#ced4da',
            borderRadius: '.3rem'
        }),
        singleValue: (style: object) => ({
            ...style,
            color: '#495057',
            fontSize: '1.25rem',
            lineHeight: 1.5
        }),
        valueContainer: (style: object) => ({
            ...style,
            padding: '7px 1rem'
        })
    }
    
    return (
        <div className="position-relative">
            <h2>{t('We are almost there!')}</h2>
            <p>{t('We will need to access your Retail Energy Provider (REP) Account and have your permission to pull usage from Smart Meter Texas (SMT)')}</p>
            <Form noValidate validated={validated} onSubmit={handleSubmit}>
                <Form.Group>
                    <Form.Label>{t('Provider')}</Form.Label>
                    <Select
                        instanceId="repsSelect"
                        value={selectedProvider}
                        onChange={(obj: any) => {
                            setSelectedProvider(obj)
                            setIsUnsupportedRep(unsupportedRepList.indexOf(obj.value) >= 0);
                            setShowProviderError(false)
                        }}
                        options={providers}
                        getOptionLabel={option => `${option.name}`}
                        getOptionValue={option => `${option.name}`}
                        isSearchable={true}
                        maxMenuHeight={200}
                        styles={selectStyles}
                        isLoading={loadingProviders}
                        isDisabled={submitting}
                    />
                    {
                        isUnsupportedRep &&
                        <div className="mt-3">
                            <FormError message={t("Your selected energy provider does not support data sharing via MyAccount. Please choose another method to get your historical usage data")} />
                        </div>
                    }
                </Form.Group>
                <Form.Group>
                    <Form.Label>{t('Username')}</Form.Label>
                    <Form.Control
                        required
                        size="lg"
                        type="text"
                        onChange={evt => setUsername(evt.target.value)}
                        value={username}
                        disabled={submitting}
                    />
                </Form.Group>
                <Form.Group>
                    <Form.Label>{t('Password')}</Form.Label>
                    <Form.Control
                        required
                        size="lg"
                        type="password"
                        onChange={evt => setPassword(evt.target.value)}
                        value={password}
                        disabled={submitting}
                    />
                </Form.Group>
                <div className="checkbox-row">
                    <CheckboxItem
                        label={t("By clicking on the box, I agree to grant EIQshopping permission to access my account on Smart Meter Texas (SMT). In addition, EIQshopping shall provide all SMT email communications received by EIQshopping on your behalf to your email address provided.")}
                        value="1"
                        key="0"
                        checked={isAgree}
                        handleClick={() => {
                            setShowConsentError(false);
                            setIsAgree(!isAgree)
                        }}
                        disabled={submitting}
                        error={showConsentError}
                    />
                </div>
                <Form.Group className="mb-0 d-flex justify-content-end mt-5">
                    {
                        !hideBack &&
                        <Button type="button" variant="secondary" disabled={submitting} className="me-4" onClick={() => { handleBack() }}>
                            {t('Back')}
                        </Button>
                    }
                    <Button type="submit" disabled={submitting} data-test-id="cta">{t('Continue')}</Button>
                </Form.Group>
            </Form>
            <RollingTextLoading
                text={[
                    t('This process may take upto 2 minutes', {ns:'common'}),
                    t('We are connecting with your Retail Energy Provider(REP)...', {ns:'common'})
                ]}
                show={submitting}
            />
        </div>
    )
}