import styled from 'styled-components';

interface CompareToggleBarProps {
    show: boolean;
}

export const CompareClearBtn = styled.button`
    background-color: transparent;
    border: 0 none;
    margin-left: 8px;
    color: #999;
    font-size: 14px;
    cursor: pointer;
`

export const CompareToggleBar = styled.div<CompareToggleBarProps>`
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    width: 100%;
    bottom: ${({ show }) => show ? '0' : '-200px'};
    right: 0;
    opacity: ${({ show }) => show ? 1 : 0};
    background-color: #fff;
    padding: 16px 20px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, .3);
    border-top: 1px solid #ddd;
    transition: all .3s;
    z-index: 1;
`

export const CompareTableWrapper = styled.div`
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;

    table td {
        border-bottom: 1px solid #eee;
        padding: 12px 8px;
        text-align: center;
        font-size: 14px;
    }

    table tr:last-child td {
        border-bottom: 0 none;
    }

    table td.th {
        text-align: left;
        background-color: #fafafa;
        width: 160px;
        font-size: 13px;
        line-height: 1.2;
    }

    td i {
        color: #999;
        font-size: 14px;
        font-style: normal;
    }

    @media (max-width: 768px) {
        table td {
            padding: 8px 6px;
        }

        button {
            font-size: 12px;
            padding: 8px 6px;
            line-height: 1.2;
        }
    }
`

export const ChartWrapper = styled('div')`
    height: 250px;
    width: 100%;
`

export const ChartTooltips = styled('div')`
    background: #fff;
    border: 1px solid #CEF5F1;
    border-radius: 8px;
    padding: 12px 16px;
    text-align: left;

    h6 {
        font-size: 12px;
        font-weight: 700;
        color: #333;
        margin: 0 0 8px;
        padding-bottom: 8px;
        border-bottom: 1px solid #f2f2f2;
    }

    p {
        margin: 0;
    }
`

export const PlanPageAd = styled.img`
  width: 100%;
  display: block;
  margin: 0 auto;
  cursor: pointer;

  @media (max-width: 768px) {
    width: 100%;
  }
`;