export function convertToKebabCase(input: string) {
  if (!input) return "";
  return input.toLowerCase().replace(/\s+/g, "-");
}

export function cleanAndNormalizeString(input: string) {
  if (!input) return "";
  const decodedString = decodeURIComponent(input);
  return decodedString.toLowerCase().replace(/[-\s]/g, "");
}

type Json1 = { provider_id: string; provider_name: string };
type Json2 = {
  business_name: string;
  show_name: string;
  description: string;
  provider_id?: string;
};
type MergedJson = {
  business_name: string;
  show_name: string;
  description: string;
  provider_id: string;
  provider_name: string;
};

export function mergeProviderData(json1: Json1[], json2: Json2[]) {
  const json1Map: Map<string, string> = new Map(
    json1.map((item) => [item.provider_id, item.provider_name])
  );
  const json2Set: Set<string> = new Set();
  const mergedArr = json2
    .filter((item) => item.provider_id && json1Map.has(item.provider_id))
    .filter((item) => {
      if (item.provider_id && json2Set.has(item.provider_id)) {
        return false;
      }
      if (item.provider_id) {
        json2Set.add(item.provider_id);
      }
      return true;
    })
    .map((item) => ({
      provider_id: item.provider_id!,
      provider_name: json1Map.get(item.provider_id!)!,
      business_name: item.business_name,
      show_name: item.show_name,
      description: item.description,
    }));

  return mergedArr;
}

export function findProviderByName(arr: MergedJson[], providerName: string) {
  const formattedProviderName = cleanAndNormalizeString(providerName);

  return arr.find((item) => {
    const formattedShowName = cleanAndNormalizeString(item.show_name);
    return formattedShowName === formattedProviderName;
  });
}
