import { useEffect, useState } from "react";
import Chart from "react-google-charts";
import { Grid } from "@mui/material";
import { GeoChartLegend } from "./styles/MyComponents.styled";

const stateToUtility: Record<string, { value: number; utilities: string[] }> = {
  // California: {
  //   value: 3,
  //   utilities: [
  //     'Southern California Gas',
  //     'San Diego Gas & Electric',
  //     'Pacific Gas & Electric',
  //   ],
  // },
  // Connecticut: {
  //   value: 1,
  //   utilities: ['Connecticut Light & Power', 'United Illuminating'],
  // },
  // 'District of Columbia': {
  //   value: 3,
  //   utilities: ['Pepco', 'Washington Gas Light'],
  // },
  // Delaware: {
  //   value: 1,
  //   utilities: ['Delmarva Power & Light'],
  // },
  // Georgia: {
  //   value: 2,
  //   utilities: ['Atlanta Gas Light'],
  // },
  // Illinois: {
  //   value: 3,
  //   utilities: [
  //     'ComEd',
  //     'Ameren',
  //     'Nicor Gas',
  //     'North Shore Gas',
  //     'Peoples Gas',
  //   ],
  // },
  // Indiana: {
  //   value: 2,
  //   utilities: ['Northern Indiana Public Service Company'],
  // },
  // Kentucky: {
  //   value: 2,
  //   utilities: ['Columbia Gas'],
  // },
  // Massachusetts: {
  //   value: 3,
  //   utilities: [
  //     'Boston Edison',
  //     'Cambridge Electric Light Company',
  //     'Commonwealth Electric',
  //     'Western Massachusetts Electric',
  //     'Massachusetts Electric Company',
  //     'NSTAR',
  //     'Unitil Energy',
  //     'Nantucket Electric',
  //     'Boston Gas',
  //     'Colonial Gas',
  //     'Eastern Massachusetts',
  //   ],
  // },
  Maryland: {
    value: 2,
    utilities: [
      "Pepco",
      "Baltimore Gas & Electric",
      "Potomac Edison",
      "Delmarva Power & Light",
      "Washington Gas Light",
      "Southern Maryland Electric Cooperative",
    ],
  },
  // Maine: {
  //   value: 1,
  //   utilities: ['Central Maine Power', 'Bangor Hydro', 'Emera Maine'],
  // },
  // Michigan: {
  //   value: 2,
  //   utilities: [
  //     'MichCon Energy',
  //     'Consumers Energy',
  //     'DTE Energy',
  //     'Michigan Gas Utilities',
  //     'SEMCO Energy',
  //   ],
  // },
  // 'New Hampshire': {
  //   value: 1,
  //   utilities: [
  //     'Eversource Energy',
  //     'Unitil Energy',
  //     'New Hampshire Electric Co-op',
  //     'Liberty Utilities',
  //   ],
  // },
  // 'New Jersey': {
  //   value: 3,
  //   utilities: [
  //     'Public Service Electric & Gas',
  //     'Jersey Central Power & Light',
  //     'New Jersey Natural Gas',
  //     'Rockland Electric',
  //     'Atlantic City Electric',
  //     'South Jersey Gas',
  //     'Orange & Rockland',
  //     'Elizabethtown Gas',
  //   ],
  // },
  // 'New York': {
  //   value: 3,
  //   utilities: [
  //     'Niagara Mohawk',
  //     'New York State Electric & Gas',
  //     'Central Hudson Gas & Electric',
  //     'Rochester Gas & Electric',
  //     'Consolidated Edison',
  //     'Orange & Rockland',
  //     'National Grid',
  //     'KeySpan',
  //     'National Fuel',
  //   ],
  // },
  Ohio: {
    value: 2,
    utilities: [
      "Columbus Southern Power",
      "Ohio Edison",
      "AES Ohio",
      "Toledo Edison",
      "The Illuminating Company",
      "Columbia Gas",
      "Dominion Energy",
      "Duke Energy",
      "WOG",
      "AEP Ohio Power",
      "Vectren Energy",
    ],
  },
  Pennsylvania: {
    value: 2,
    utilities: [
      "Columbia Gas",
      "Duquesne Light",
      "PECO Energy",
      "Peoples Natural Gas",
      "West Penn Power",
      "PPL Electric Utilities",
      "UGI",
      "Penn Power",
      "Penelec",
      "Met-Ed",
      "National Fuel",
      "Philadelphia Gas Works",
      "Allegheny Power",
    ],
  },
  // 'Rhode Island': {
  //   value: 1,
  //   utilities: ['National Grid', 'Rhode Island Energy'],
  // },
  // Virginia: {
  //   value: 2,
  //   utilities: ['Washington Gas Light', 'Columbia Gas'],
  // },
  Texas: {
    value: 1,
    utilities: [
      "AEP Central",
      "AEP North",
      "CenterPoint Energy",
      "Oncor Electric Delievery",
      "Texas New Mexico Power",
    ],
  },
};

const states = [
  "Alabama",
  "Alaska",
  "Arizona",
  "Arkansas",
  "California",
  "Colorado",
  "Connecticut",
  "Delaware",
  "Florida",
  "Georgia",
  "Hawaii",
  "Idaho",
  "Illinois",
  "Indiana",
  "Iowa",
  "Kansas",
  "Kentucky",
  "Louisiana",
  "Maine",
  "Maryland",
  "Massachusetts",
  "Michigan",
  "Minnesota",
  "Mississippi",
  "Missouri",
  "Montana",
  "Nebraska",
  "Nevada",
  "New Hampshire",
  "New Jersey",
  "New Mexico",
  "New York",
  "North Carolina",
  "North Dakota",
  "Ohio",
  "Oklahoma",
  "Oregon",
  "Pennsylvania",
  "Rhode Island",
  "South Carolina",
  "South Dakota",
  "Tennessee",
  "Texas",
  "Utah",
  "Vermont",
  "Virginia",
  "Washington",
  "West Virginia",
  "Wisconsin",
  "Wyoming",
];

const USGeoMap = () => {
  const [data, setData] = useState<Array<any>>([
    ["State", "Value", { role: "tooltip", type: "string", p: { html: true } }],
  ]);

  useEffect(() => {
    const newData = data.map((item) => [...item]);

    for (const state of states) {
      if (stateToUtility[state]) {
        const { value, utilities } = stateToUtility[state];
        const tooltipHtml = `<div className="geo-chart-tooltip">${utilities.join(
          "<br />"
        )}</div>`;
        newData.push([state, value, tooltipHtml]);
      }
    }

    setData(newData);
  }, []);

  const options = {
    region: "US",
    displayMode: "regions",
    resolution: "provinces",
    tooltip: { isHtml: true },
    legend: "none",
    colorAxis: { values: [1, 2], colors: ["#8DC64F", "#E0F3CC"] },
  };

  const handleRegionClick = (e:any) => {
    let state = "";
    if (e.region) {
      state = e.region.substring(3);
    }
    console.log(`Clicked on state: ${state}`);
  };

  if (data.length < 2) return null;
  return (
    <Grid container>
      <Grid item xs={12} sm={2}>
        <GeoChartLegend>
          <span>
            Servicing
            <i style={{ backgroundColor: "#8DC64F" }} />
          </span>
          <span>
            Coming Soon
            <i style={{ backgroundColor: "#E0F3CC" }} />
          </span>
        </GeoChartLegend>
      </Grid>
      <Grid item xs={12} sm={10}>
        <Chart
          chartType="GeoChart"
          width="100%"
          height="400px"
          data={data}
          options={options}
          chartEvents={[
            {
              eventName: "select",
              callback: ({ chartWrapper }) => {
                if (!chartWrapper) return;

                const chart = chartWrapper.getChart();
                const selection = chart.getSelection();
                if (selection.length === 1) {
                  const [selectedItem] = selection;
                  const dataTable = chartWrapper.getDataTable();
                  if (!dataTable) return; // 如果 dataTable 为 null，直接返回

                  const { row } = selectedItem;
                  handleRegionClick({ region: dataTable.getValue(row, 0) });
                }
              },
            },
          ]}
        />

      </Grid>
    </Grid>
  );
};

export default USGeoMap;
