import { PureComponent } from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  ReferenceLine,
} from "recharts";
import { ChartTooltips, ChartWrapper } from "../styles/common.styled";
import { ChartDataTypes } from "../../types";

const tooltipStyles = {
  cursor: {
    fill: "transparent",
  },
};

// const hostRep = REPs.filter(val => val.host === true)[0];
// const ww = window.innerWidth;

export const CompareChart = ({
  chartData, // handleShowPlan,
}: {
  chartData: ChartDataTypes[];
  // handleShowPlan: Function;
}) => {
  if (!chartData) return null;

  return (
    <>
      <ChartWrapper style={{ height: 260 }}>
        <ResponsiveContainer>
          <BarChart
            data={chartData}
            margin={{ top: 30, bottom: 30, left: -36 }}
          >
            <XAxis dataKey="id" stroke="#bbb" tick={<CustomizedTick />} />
            <YAxis
              stroke="#fff"
              tick={{ fontSize: 10, fill: "#bbb" }}
              tickFormatter={(tickItem) => tickItem}
            />
            <Tooltip
              {...tooltipStyles}
              position={{ y: 150 }}
              wrapperStyle={{ zIndex: 1 }}
              content={<CustomTooltip />}
            />
            <CartesianGrid vertical={false} strokeDasharray="2" />
            <Bar
              dataKey="value"
              isAnimationActive
              maxBarSize={80}
              shape={BarWithTopBorder()}
              label={<CustomizedLabel />}
            />
            {chartData[0] && (
              <ReferenceLine
                y={chartData[0].value}
                stroke="#38c3a8"
                strokeDasharray="5 3"
                strokeWidth={2}
              />
            )}
          </BarChart>
        </ResponsiveContainer>
      </ChartWrapper>
      {/* <ViewMoreBtnRow>
                {
                    chartData.map((val, idx) => (
                        <div key={idx} >
                            <Button size="small" variant="outlined"
                                onClick={() => {
                                    handleShowPlan(val.id)
                                }}>View Plans</Button>
                        </div>
                    ))
                }
            </ViewMoreBtnRow> */}
      {/* <ChartFooter>Last scan date: {moment().isBetween(moment('00:00am', 'hh:mma'), moment('01:00am', 'hh:mma')) ? moment().subtract(1, 'days').format('MM/DD/YYYY') : moment().format('MM/DD/YYYY')}</ChartFooter> */}
    </>
  );
};

const BarWithTopBorder = () =>
  function topBorder(props: any) {
    const { x, y, width, height, index } = props;

    return (
      <g>
        <defs>
          <linearGradient id="chart-gradient1" x1="0" x2="0" y1="0" y2="1">
            <stop offset="0%" stopColor="#E7E7EB" />
            <stop offset="100%" stopColor="#9B9B9B" />
          </linearGradient>
          <linearGradient id="chart-gradient2" x1="0" x2="0" y1="0" y2="1">
            <stop offset="0%" stopColor="#E5E5E5" />
            <stop offset="100%" stopColor="#B4B4B4" />
          </linearGradient>
        </defs>
        <rect
          x={x}
          y={y}
          width={width}
          height={height}
          stroke="none"
          className="bar"
          rx="2"
          ry="2"
          fill={index === 0 ? "url(#chart-gradient1)" : "url(#chart-gradient2)"}
        />
      </g>
    );
  };

class CustomTooltip extends PureComponent {
  render() {
    const { active, payload }: Readonly<any> = this.props;

    if (!active || !payload || !payload[0]) return null;
    const { label, value } = payload[0].payload;

    return (
      <ChartTooltips>
        <h6>{label}</h6>
        <p>{value}¢</p>
      </ChartTooltips>
    );
  }
}

class CustomizedTick extends PureComponent {
  render() {
    const { x, y, payload, width, visibleTicksCount }: Readonly<any> =
      this.props;
    const isTx = sessionStorage.getItem("isTx");
    const maxWidth = 125;
    const _width =
      width / visibleTicksCount > maxWidth
        ? maxWidth
        : width / visibleTicksCount;

    const getImg = () => {
      if (isTx === "true") {
        return `https://dorzjv601fuva.cloudfront.net/tx/${payload.value}.png`;
      }
      return `https://dorzjv601fuva.cloudfront.net/usn/${payload.value}.png`;
    };

    return (
      <g transform={`translate(${x},${y})`}>
        <image
          x={(_width / 2) * -1}
          y={0}
          xlinkHref={getImg()}
          width={_width}
        />
      </g>
    );
  }
}

class CustomizedLabel extends PureComponent {
  render() {
    const { x, y, value, width }: Readonly<any> = this.props;

    return (
      <text
        x={x + width / 2}
        y={y}
        dy={-8}
        fill="#3a3a3a"
        fontSize={width / 4}
        fontWeight="700"
        textAnchor="middle"
      >
        {value}¢
      </text>
    );
  }
}
