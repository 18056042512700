export function capitalizeString(str: any) {
  if (!str) return null;
  return str
    .replace(/_/g, " ")
    .split(" ")
    .map(
      (word: any) => String(word[0]).toUpperCase() + word.slice(1).toLowerCase()
    )
    .join(" ");
}

export function BooleanString(str1: any, str2: any) {
  if (!str1 || !str2) {
    return null;
  }

  const a = str1.toLowerCase().replace(/_/g, " ");
  const b = str2.toLowerCase().replace(/_/g, " ");

  return a === b;
}

export const BooloeanShowText = (index: any, isTexas:any) => {
  if (
    (index === 1 && !isTexas) ||
    (index === 2 && !isTexas) ||
    (index === 3 && !isTexas) ||
    (index === 4 && !isTexas)
  ) {
    return false;
  }
  return true;
};
