export const IS_DEV = false

export const API_URL = IS_DEV
    ? 'https://dev.powerlego.com/ApiGateway/'
    : 'https://www.powerlego.com/ApiGateway/'
export const ACC_KEY = IS_DEV
    ? 'c5fb349da68ab8094c07a799e99e09ce'
    : 'c5fb349da68ab8094c07a799e99e09ce'
export const SIDEBYSIDE_API_URL = 'https://bb0xg7o51l.execute-api.us-east-2.amazonaws.com/beta';
export const GOOGLE_API_KEY='AIzaSyAZM3syuwFDqeu4orgmu-9_srYHvHK0Jgk'
export const ENABLE_SOLAR_MAP = true;
export const PROVIDER_ID = "239";

export const POWERLEGO_API_URL = IS_DEV
  ? "https://dev.powerlego.com/ApiGateway"
  : "https://www.powerlego.com/ApiGateway";
  
export const API_KEY = IS_DEV
? "eea1bbda1f8835b27dd821a65c658ddb"
: "c618bf5dbeaa926e1daf7eaa8ad9126f";

export const REPs = [
    {
      providerId: "239",
      providerName: "Rhythm",
    },
    {
      providerId: "269",
      providerName: "Good Charlie",
      host: true,
    },
    {
      providerId: "163",
      providerName: "Direct Energy",
    },
    {
      providerId: "176",
      providerName: "TXU Energy",
    },
    {
      providerId: "159",
      providerName: "Reliant",
    },
    {
      providerId: "206",
      providerName: "Green Mountain",
    },
    {
      providerId: "183",
      providerName: "Champion Energy",
    },
  ];