import { css } from "@emotion/css";
import {
  Autocomplete,
  Button,
  ButtonGroup,
  CircularProgress,
  Container,
  Grid,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
  Typography,
} from "@mui/material";
import throttle from "lodash/throttle";
import { useParams, useNavigate } from "react-router-dom";
// import { enqueueSnackbar } from "notistack";
import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { Image } from "react-bootstrap";
import { CityPageContext } from "../../pages/city-page";
import styles from "../styles/HomePage.module.css";
import { EnergyOptions } from "../../common/data";
import { useUpdateEffect } from "../hooks/myHooks";
import {
  fetchAddress,
  fetchCityList,
  fetchPlan,
  fetchUtility,
  fetchProviderData,
  fetchUSNUtility,
  fetchUsnFilterOptions,
  fetchAllPlans,
  fetchAllUsnPlans,
  fetchFilterOptions
} from "../../api/city";
import {
  AddressProps,
  ChartDataTypes,
  PlanProps,
  PlanTypes,
  Utilities,
  UtilityTypes,
} from "../../types";
import { CityGuideSection } from "./CityGuideSection";
import { capitalizeString } from "../CommonFun";
import { CompareChart } from "./CompareChart";
import { ProviderIntroSection } from "./ProviderIntroSection";
import { RollingNumber } from "./RollingNumber";
import SelectCity from "../SelectCity";
import useGetState from "../hooks/useGetState";
import useIsTexas from "../hooks/useIsTexas";
import { PlansList } from "./plans/PlansList";
import {
  CityTitleH1,
  EnergyMenu,
  EnergyMenuHeader,
  EnergyMenuItem,
  SeePriceCard,
} from "../styles/CityPage.styled";
import { ChartSection, CurtainWrapper } from "../styles/PlanList.styled";
import {
  BlueTitle,
  BluebgSection,
  CardBottombg,
  SectionWrapper,
  WavebgSection,
} from "../styles/saving-section.styled";
import { GoogleMap } from "../solar-section/solar_map_city";
import { CityMapSection, CitySolarIntro } from "../solar-section/solar_cards";
import { CitySolar } from "../solar-section/Solar.styled";
import { mergeProviderData } from "../../utils/helper";
import { providerDescriptions } from "./provider_descriptions";
// import { enqueueSnackbar } from "notistack";

type ProviderListType = {
  business_name: string;
  show_name: string;
  description: string;
  provider_id: string;
  provider_name: string;
};

const CitySection = () => {
  const navigate = useNavigate();
  const [address, setAddress] = useState("");
  const [utilities, setUtilities] = useState<UtilityTypes[]>([]);
  const [selectedAddress, setSelectedAddress] = useState<
    AddressProps | undefined
  >(undefined);
  const [inputZipcode, setInputZipcode] = useState<any>("");
  const [addressOptions, setAddressOptions] = useState([]);
  const [addressLoading, setAddressLoading] = useState(false);
  const [selectedUtility, setSelectedUtility] = useState("");
  const [selectedTerm, setSelectedTerm] = useState("12");
  const [chartData, setChartData] = useState<ChartDataTypes[]>([]);
  const [plans, setPlans] = useState<
    { [key: string]: PlanTypes[] } | undefined
  >(undefined);
  const [loading, setLoading] = useState(false);
  const [utility, setUtility] = useState<Utilities | any>(undefined);
  const [gasUtility, setGasUtility] = useState<Utilities | any>(
    undefined
  );
  const [avgMonthlyUse, setAvgMonthlyUse] = useState("1000");
  const [termOptions, setTermOptions] = useState([]);
  const [products, setProducts] = useState<PlanProps[]>([]);
  const [chartLoading, setChartLoading] = useState(false);
  const mainRef = useRef<null | HTMLDivElement>(null);
  const [energyType, setEnergyType] = useState<string>("all"); // 1. all energy 2.green energy 3.gas, 5.solar
  const [usnCity, setUsnCity] = useState<any>();
  const [providerList, setProviderList] = useState<
    ProviderListType[] | undefined
  >(undefined);
  const { stateArray, cityArray } = useContext<any>(CityPageContext);
  const { state = "", city = "", zipcode = "", type = "" } = useParams<{
    state?: string;
    city?: string;
    zipcode?: string;
    type?: string;
  }>();

  const isTexas = useIsTexas(state);
  const selectState = useGetState(state);
  const StateName = selectState
    ? selectState.state_id
    : capitalizeString(state);

  const getEnergyContent = (key: any) => {
    switch (key) {
      case "all":
        return {
          id: "All Energy",
          title: `Find the Right Electricity Rates for ${capitalizeString(
            city
          )} ${selectState ? selectState?.state_id : capitalizeString(state)
            } ${zipcode}`,
          content: `Looking for affordable electricity rates in ${capitalizeString(
            city
          )}, ${selectState?.state_name}? Our platform simplifies the process of comparing and finding the right electricity rates, that meets your needs. 
          ${isTexas ? "Enter your address" : "Enter your zip code"}
          , and compare electricity rates for energy providers the ${capitalizeString(
            city
          )} area. Get started now and find a great plan for your home!.`,
          icon: "/images/icon_location_blue.png",
          icon_address: "/images/icon_address_blue.svg",
          img_bg: "/images/card_bottom_bg.png",
          total_saving:
            "Here is according to your city's electricity policy, we have matched you with save plans,maybe to help you saving money!",
        };
      case "renewable":
        return {
          id: "Green energy",
          title: `Green & Renewable Energy Options in ${capitalizeString(
            city
          )} ${selectState ? selectState?.state_id : capitalizeString(state)
            } ${zipcode}`,
          content: `Benefit from the Best Renewable Energy Plans. Make a positive change by switching to cleaner, renewable sources right here in ${capitalizeString(
            city
          )}, ${selectState?.state_name}. Our renewable energy plans are tailored to meet your specific energy needs while contributing to a more eco-friendly future.`,
          icon: "/images/icon_location_blue.png",
          icon_address: "/images/icon_address_green.png",
          img_bg: "/images/card_bottom_bg_green.png",
          total_saving:
            "Here is according to your city's Green electricity policy, we have matched you with save plans, More use of green energy will help protect the environment.",
        };
      case "gas":
        return {
          id: "Gas",
          title: `Find the Right Electricity Rates for ${capitalizeString(
            city
          )} ${state.toUpperCase()}${zipcode}`,
          content: `Looking for the most affordable  electricity rates in ${capitalizeString(
            city
          )}, ${selectState?.state_name}? Our platform simplifies the process of comparing and finding the lowest electricity rates, ensuring you secure the best electric plan that meets your needs. 
            ${isTexas ? "Enter your address" : "Enter your zip code"}
            , and compare electricity rates for energy providers the ${capitalizeString(
            city
          )} area. Get started now and find the best electricity rates available for your home in ${capitalizeString(
            city
          )}.`,
          icon: "/images/icon_location_blue.png",
          icon_address: "/images/icon_address_blue.svg",
          img_bg: "/images/card_bottom_bg.png",
          total_saving:
            "Here is according to your city's electricity policy, we have matched you with save plans,maybe to help you saving money!",
        };
      // case "solar-energy":
      //   return {
      //     id: "Gas",
      //     title: `Find the Cheapest Electricity Rates for ${capitalizeString(
      //       city
      //     )} ${state.toUpperCase()}${zipcode}`,
      //     content: `Looking for the most affordable  electricity rates in ${capitalizeString(
      //       city
      //     )}, ${selectState?.state_name}? Our platform simplifies the process of comparing and finding the lowest electricity rates, ensuring you secure the best electric plan that meets your needs.
      //         ${isTexas ? "Enter your address" : "Enter your zip code"}
      //         , and compare electricity rates for energy providers the ${capitalizeString(
      //           city
      //         )} area. Get started now and find the best electricity rates available for your home in ${capitalizeString(
      //           city
      //         )}.`,
      //     icon: "/images/icon_location_blue.png",
      //     icon_address: "/images/icon_address_blue.png",
      //     img_bg: "/images/card_bottom_bg.png",
      //     total_saving:
      //       "Here is according to your city's electricity policy, we have matched you with save plans,maybe to help you saving money!",
      //   };
      default:
        return null;
    }
  };

  const PageContent = getEnergyContent(energyType);

  useEffect(() => {
    if (type === "solar" || energyType === "solar-energy") {
      return;
    }
    setPlans(undefined);
    setProducts([]);
    setChartData([]);

    if (isTexas) {
      getUtility("city");
    }
    if (isTexas === false && zipcode) {
      getUSNUtility();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [zipcode, isTexas]);

  useEffect(() => {
    if (!type || type === "undefined") {
      setEnergyType("all");
    } else if (type === "renewable") {
      setEnergyType("renewable");
    } else if (type === "gas") {
      setEnergyType("gas");
    } else if (type === "solar-energy") {
      setEnergyType("solar-energy");
    } else {
      setEnergyType("provider");
    }
  }, [type]);

  useEffect(() => {
    if (isTexas === undefined) return

    if (isTexas) {
      fetchProviderData("").then((res) => {
        if (res && res.status === "1") {
          const txProviders = res?.response?.TX || [];

          const formattedList = mergeProviderData(
            txProviders,
            providerDescriptions
          );
          setProviderList(formattedList);
        }
      });
    }
  }, [isTexas]);

  useEffect(() => {
    if (energyType && type !== "solar-energy") {
      getPlan([]);
      getProducts([]);
    }
  }, [energyType]);

  useEffect(() => {
    getAverageRate();
  }, [products, plans]);

  useEffect(() => {
    if (selectedTerm) {
      getPlan([]);
      getProducts([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedTerm]);

  const fetchAddressDelayed = useCallback(
    throttle((text, callback) => {
      if (text && text.trim()) {
        setAddressLoading(true);
        fetchAddress(text.trim()).then(callback);
      }
    }, 1000),
    []
  );

  useEffect(() => {
    fetchAddressDelayed(address, (result: any) => {
      setAddressLoading(false);
      setAddressOptions(result || []);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [address]);

  useEffect(() => {
    if (utility && !inputZipcode && !selectedAddress) {
      getFilterMonth();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [utility]);

  useUpdateEffect(() => {
    getPlan(new Array(12).fill(parseInt(avgMonthlyUse)));
    getProducts(new Array(12).fill(parseInt(avgMonthlyUse)));
  }, [avgMonthlyUse]);

  useEffect(() => {
    if (inputZipcode && inputZipcode.length === 5 && !isTexas) {
      getUSNUtility("input");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputZipcode]);

  const getFilterMonth = () => {
    const utilityCode: any = utility?.utility_code;
    const state_id = utility.state_id ? utility.state_id : "TX";
    const _usages = new Array(12).fill(parseInt("1000"));
    if (utilityCode && zipcode && state_id) {
      setSelectedTerm("");
      const str: any = zipcode;
      if (isTexas === true) {
        fetchFilterOptions(
          "normal",
          _usages,
          str,
          [],
          utilityCode,
          state_id,
          true
        ).then((res) => {
          if (res && res.response) {
            const { terms } = res.response;
            terms.sort((a: string, b: string) => {
              if (parseInt(a) < parseInt(b)) return -1;
              if (parseInt(a) > parseInt(b)) return 1;
              return 0;
            });
            setTermOptions(terms);
          } else {
            // enqueueSnackbar(res.msg, { variant: "error" });
            setLoading(false);
          }
        });
      }
      if (isTexas === false) {
        fetchUsnFilterOptions(
          "normal",
          _usages,
          str,
          [],
          utilityCode,
          state_id
        ).then((res) => {
          if (res && res.response) {
            const { terms } = res.response;
            terms.sort((a: string, b: string) => {
              if (parseInt(a) < parseInt(b)) return -1;
              if (parseInt(a) > parseInt(b)) return 1;
              return 0;
            });
            setTermOptions(terms);
          } else {
            // enqueueSnackbar(res.msg, { variant: "error" });
            setLoading(false);
          }
        });
      }
    }
  };

  const getPlan = (myUsage?: Number[], myUtility?: any) => {
    const _usages =
      myUsage && myUsage.length > 0
        ? myUsage
        : new Array(12).fill(parseInt(avgMonthlyUse));
    const utilityCode = myUtility
      ? myUtility.utility_code
      : energyType === "gas"
        ? gasUtility?.utility_code
        : utility?.utility_code;
    const term = selectedTerm || "12";
    const state_id = myUtility
      ? myUtility.state_id
      : utility
        ? utility.state_id
        : "TX";

    if (!utilityCode) return;

    fetchPlan(parseInt(term), utilityCode, _usages, state_id).then((res) => {
      setLoading(false);
      if (res && Number(res.code) === 1) {
        const allPlans = res.response;
        setPlans(allPlans);
      }
      if (res && res.error_code) {
        setChartLoading(false);
      } else {
        // enqueueSnackbar(res.msg, { variant: "error" });
      }
    });
  };

  const getAverageRate = () => {
    if (products.length > 0 && plans) {
      const allPlans: any = plans;
      const data = [];
      for (const i in allPlans) {
        if (allPlans[i]) {
          const _plans = allPlans[i];

          let totalRate = 0;
          _plans.forEach((val: PlanTypes) => {
            const { total_rate } = val;
            totalRate += total_rate;
          });
          data.push({
            id: i,
            value: Math.ceil((totalRate / _plans.length) * 100) / 100,
            label: _plans[0].provider_name,
            isTx: isTexas,
          });
        }
      }
      // handleSetChartData(data);

      // let total_price = 0;
      // products.forEach((val: any) => {
      //   total_price += Number(val.rate);
      // });

      data.sort((a, b) => {
        if (a.value < b.value) return -1;
        if (a.value > b.value) return 1;
        return 0;
      });

      let _chartData: any[] = [];
      // TODO lowerst provider_id和name
      // _chartData.push({
      //   id: products[0].provider_id,
      //   value: products[0].rate,
      //   label: products[0].provider_name,
      //   isTx: isTexas,
      // });

      _chartData = _chartData.concat(data.slice(-3));
      setChartData(_chartData);
      setChartLoading(false);

      // setTimeout(() => {
      //     handleScroll();
      // }, 200);
    }
  };

  const handleChangeAddress = (evt: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = evt.target;
    setAddress(value);
  };

  const onSubmit = async () => {
    if (!selectedAddress) return;
    localStorage.setItem("defaultAddress", JSON.stringify(selectedAddress));
    localStorage.setItem("utility", JSON.stringify(utility));
    const utilityCode = utility ? utility.utility_code : '';
    const city = selectedAddress.city.toLowerCase();
    const street = selectedAddress.street.toLowerCase();
    navigate(`/plans?address=${encodeURIComponent(street)}&esiid=${selectedAddress.esiid}&zipcode=${selectedAddress.zipcode}&city=${city}&state=${selectedAddress.state}&utcode=${utilityCode}`);
  };

  const onSubmitUsn = () => {
    localStorage.setItem("utility", JSON.stringify(utility));
    const _state = utility.state_id;
    navigate(
      `/city/${_state.toLowerCase()}/${city.toLowerCase()}/${inputZipcode}`
    );
  };

  const getUtility = (type: any) => {
    let str: any;
    if (type === "city") {
      str = zipcode;
      setChartLoading(true);
    }
    if (type === "address" && selectedAddress) {
      str = selectedAddress.zipcode;
    }

    if (str) {
      fetchUtility(str).then((res) => {
        if (res && res[0] && res[0].utility_code) {
          setUtility(res[0]);
          if (type === "address") {
            setUtilities(res);
          }
          if (type === "city") {
            getPlan([], res[0]);
            getProducts([], res[0]);
          }

          if (res[0] && res[0].utility_code) {
            setSelectedUtility(res[0].utility_name);
          }
        } else {
          // enqueueSnackbar(res.msg, { variant: "error" });
          setLoading(false);
        }
        if (res.errorCode === "500") {
          getPlan([], res[0]);
          getProducts([], res[0]);
        }
      });
    }
  };

  const getUSNUtility = (type?: string) => {
    if (!type) {
      setChartLoading(true);
    }
    fetchUSNUtility(zipcode).then((res) => {
      if (
        res &&
        res.response &&
        res.response[0] &&
        res.response[0].utility_code
      ) {
        const { response } = res;
        const electricUtilities = response.filter(
          (item: any) => item.commodity_name === "Electric"
        );
        const gasUtilities = response.filter(
          (item: any) => item.commodity_name === "Gas"
        );
        if (response && response[0] && response[0].utility_code) {
          if (type === "input") {
            setUtilities(response);
          }
          if (electricUtilities.length > 0) {
            setUtility(electricUtilities[0]);
          }

          if (gasUtilities.length > 0) {
            setGasUtility(gasUtilities[0]);
          } else {
            setGasUtility(undefined);
          }

          if (response[0] && response[0].utility_code) {
            setSelectedUtility(response[0].utility_name);
          }
        }
        if (!inputZipcode) {
          if (energyType === "gas") {
            getPlan([], gasUtilities[0]);
            getProducts([], gasUtilities[0]);
          } else {
            getPlan([], electricUtilities[0]);
            getProducts([], electricUtilities[0]);
          }
        }
        setChartLoading(false);
      } else {
        setChartLoading(false);
      }
    });
  };

  //   const handleScroll = () => {
  //     if (mainRef && mainRef.current) {
  //       mainRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
  //     }
  //   };

  const getProducts = (myUsage?: Number[], myUtility?: any) => {
    const utilityCode = myUtility
      ? myUtility.utility_code
      : energyType === "gas"
        ? gasUtility?.utility_code
        : utility?.utility_code;
    const term = selectedTerm || "12";
    // const provider_id = PROVIDER_ID;
    const state_id =
      myUtility && myUtility.state_id
        ? myUtility.state_id
        : isTexas
          ? "TX"
          : utility?.state_id;
    const usage =
      (myUsage && myUsage.length > 0)
        ? myUsage
        : new Array(12).fill(parseInt(avgMonthlyUse));

    if (utilityCode && term && state_id) {
      if (isTexas === true) {
        fetchAllPlans(
          "normal",
          usage,
          zipcode,
          [],
          utilityCode,
          state_id,
          true
        ).then((res) => {
          setLoading(false);
          if (res && Number(res.status) === 1) {
            const { plans } = res.response;
            if (plans) {
              setProducts(plans);
            }
          } else {
            setProducts([]);
            setLoading(false);
          }
        });
      }
      if (isTexas === false) {
        fetchAllUsnPlans(
          "normal",
          usage,
          zipcode,
          [],
          utilityCode,
          state_id
        ).then((res) => {
          setLoading(false);
          if (res && Number(res.status) === 1) {
            const { plans } = res.response;
            if (plans) {
              setProducts(plans);
            }
          } else {
            setProducts([]);
            setLoading(false);
          }
        });
      }
    }
  };

  // const handleChangeType = (type: any) => {
  //   if (type === "solar-energy") {
  //     return setEnergyType(type);
  //   }
  //   setLoading(true);
  //   setChartData([]);
  //   setInputZipcode(undefined);
  //   setSelectedAddress(undefined);
  //   setProducts([]);
  //   setPlans(undefined);
  //   setEnergyType(type);
  // };

  const handleChangeMenuType = (type: any) => {
    setEnergyType(type);
    setLoading(true);
    setChartData([]);
    setInputZipcode(undefined);
    setSelectedAddress(undefined);
    setProducts([]);
    setPlans(undefined);
    if (type === "renewable") {
      return navigate(`/city/${state}/${city}/${zipcode}/renewable`);
    }

    if (type === "gas") {
      return navigate(`/city/${state}/${city}/${zipcode}/gas`);
    }

    if (type === "solar-energy") {
      return navigate(`/city/${state}/${city}/${zipcode}/solar-energy`);
    }
    navigate(`/city/${state}/${city}/${zipcode}`);
  };

  // const handleZipcodeChange = (val: any) => {
  //   const onlyNums = val.target.value.replace(/[^0-9]/g, "");
  //   setInputZipcode(onlyNums);
  // };

  useEffect(() => {
    if (
      utility &&
      utility.state_id &&
      !isTexas &&
      inputZipcode &&
      energyType !== "solar-energy"
    ) {
      getUSNCity();
    }
  }, [utility]);

  useEffect(() => {
    if (usnCity && utility && utilities && utilities.length === 1) {
      const _state = usnCity.state_id.toLowerCase();
      const _city = usnCity.city.toLowerCase();
      navigate(`/city/${_state}/${_city}/${zipcode}`);
    }

    if (
      usnCity &&
      utility &&
      utilities &&
      utilities.length > 1 &&
      !selectedUtility
    ) {
      const _state = usnCity.state_id.toLowerCase();
      const _city = usnCity.city.toLowerCase();
      navigate(`/city/${_state}/${_city}/${zipcode}`);
    }
  }, [usnCity]);

  const getUSNCity = () => {
    const stateId = utility.state_id;
    fetchCityList(stateId).then((res) => {
      if (res.code === 200) {
        const _city = res.data.find((item: any) => item.state_id === stateId);
        if (_city) {
          setUsnCity(_city);
          setLoading(false);
        } else {
          //   enqueueSnackbar(
          //     res.message || "An error occurred, please try again later.",
          //     { variant: "error" }
          //   );
        }
      }
    });
  };

  let energySavings = 0;
  if (chartData && chartData.length > 0) {
    const term = selectedTerm || "12";
    const rateDiff = chartData[chartData.length - 1].value - chartData[0].value;
    energySavings =
      (rateDiff * parseFloat(avgMonthlyUse) * parseInt(term)) / 100;
  }

  const customFilterOptions = (options: any, { inputValue }: { inputValue: string }) => {
    const trimmedInput = inputValue.trim().toLowerCase();
    const filterArr = trimmedInput.split(" ");

    return options.filter((option: any) =>
      filterArr.every((char: any) => option.label.toLowerCase().includes(char))
    );
  };

  return (
    <SectionWrapper>
      {energyType !== "provider" && (
        <EnergyMenuHeader>
          <Container>
            <EnergyMenu>
              {EnergyOptions.map((item) => {
                if (item.key === 3 && !gasUtility) return null;
                return (
                  <EnergyMenuItem
                    key={item.key}
                    onClick={() => {
                      handleChangeMenuType(item.value);
                    }}
                    active={item.value === energyType}
                  >
                    {item.label}
                  </EnergyMenuItem>
                );
              })}
            </EnergyMenu>
          </Container>
        </EnergyMenuHeader>
      )}
      <CurtainWrapper>
        <Container>
          {energyType !== "solar-energy" && (
            <Grid
              container
              position="relative"
              alignItems="stretch"
              sx={{ pt: 3, pb: 3 }}
              justifyContent={{ xs: "center", sm: "space-between" }}
            >
              <Grid
                item
                xs={12}
                sm={7}
                position="relative"
                zIndex="1"
                textAlign="left"
              >
                <div className="d-flex flex-column gap-4">
                  <div className="d-flex gap-3 align-items-center">
                    <Image
                      src={PageContent?.icon}
                      alt=""
                      style={{ width: 40 }}
                    />
                    <CityTitleH1
                      green={energyType === "renewable"}
                      textAlign="left"
                    >
                      <Typography>{PageContent?.title}</Typography>
                    </CityTitleH1>
                  </div>
                  {selectState && (
                    <h2 style={{ fontSize: 18 }}>
                      <Typography>{PageContent?.content}</Typography>
                    </h2>
                  )}
                </div>
              </Grid>
              <Grid item xs={12} sm={4}>
                <SeePriceCard green={energyType === "renewable"}>
                  <Image src={PageContent?.icon_address} alt="" />
                  <h3 style={{ textAlign: "center" }}>
                    {isTexas
                      ? "Enter your address to see prices"
                      : `${capitalizeString(city)} ${selectState
                        ? selectState?.state_id
                        : capitalizeString(state)
                      } ${zipcode}`}
                  </h3>
                  {isTexas && (
                    <Autocomplete
                      freeSolo
                      onChange={(evt, val: any) => {
                        setAddress(val ? val.label : "");
                        // setUtilities([]);
                        setSelectedAddress(val);
                        setInputZipcode(val ? val.zipcode : "");
                        getUtility("address");
                      }}
                      options={addressOptions.map((option: any) => option)}
                      filterOptions={customFilterOptions}
                      renderOption={(props, option) => (
                        <li {...props} key={option.id}>
                          {option.label}
                        </li>
                      )}
                      loading={addressLoading}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Please Enter Your Address"
                          value={address}
                          onChange={handleChangeAddress}
                          disabled={loading}
                        />
                      )}
                      className={css`
                        width: 90%;
                        label {
                          padding-left: 10px;
                          font-size: 14px;
                          margin-top: 3px;
                          color: #9e9c9c;
                        }
                      `}
                    />
                  )}
                  {/* {
                  !isTexas && <>
                      <TextField
                          label="Your Zip Code"
                          value={inputZipcode}
                          onChange={handleZipcodeChange}
                          inputProps={{ maxLength: 5 }}
                          InputProps={{
                              endAdornment: loading ? <InputAdornment position="end"><ReactLoading type="bubbles" color="#bbb" width={36} height={36} /></InputAdornment> : null
                          }}
                          fullWidth
                          disabled={loading}
                          style={{ maxWidth: '90%' }}
                      />
                      {
                          (utilities && utilities.length > 1) && <>
                              <Select
                                  labelId="utility-label"
                                  id="utility"
                                  value={selectedUtility}
                                  onChange={(evt: SelectChangeEvent) => {
                                      setSelectedUtility(evt.target.value as string);
                                  }}
                                  disabled={loading}
                                  fullWidth
                                  sx={{ textAlign: 'left', borderRadius: '30px' }}
                                  style={{ maxWidth: '90%', marginTop: 15 }}
                              >
                                  {
                                      utilities.map((val: any, idx) => <MenuItem value={val.utility_name} key={idx}>{val.utility_name}</MenuItem>)
                                  }
                              </Select>
                          </>
                      }
                  </>
              } */}

                  {isTexas && (
                    <Button
                      variant="contained"
                      onClick={isTexas ? onSubmit : onSubmitUsn}
                      disabled={loading || !inputZipcode || !selectedUtility}
                      style={{
                        width: "fit-content",
                        borderRadius: 22.5,
                        padding: "10px 40px",
                        marginTop: 10,
                      }}
                      sx={{
                        backgroundColor: "#2AA794",
                        "&:hover": {
                          backgroundColor: "#2AA794", 
                        },
                        "&.Mui-disabled": {
                          backgroundColor: "#B7E7D1", 
                          color: "#FFFFFF", 
                        }
                      }}
                    >
                      Get Started
                    </Button>
                  )}
                </SeePriceCard>
              </Grid>
            </Grid>
          )}
        </Container>
        {energyType === "solar-energy" && (
          <CitySolar>
            <GoogleMap
              address={`${capitalizeString(city)}, ${selectState ? selectState?.state_id : capitalizeString(state)
                } ${zipcode}`}
            />
            <Container className="map-card">
              <CityMapSection
                city={city}
                state={
                  selectState ? selectState?.state_id : capitalizeString(state)
                }
                zipcode={zipcode}
                isTexas={isTexas}
              />
              {/* <CitySolarIntro /> */}
            </Container>
          </CitySolar>
        )}
      </CurtainWrapper>

      <Container>
        {isTexas && energyType !== "solar-energy" && (
          <ChartSection
            style={{ paddingTop: 0, textAlign: "center" }}
            green={energyType === "renewable"}
          >
            {chartData.length > 0 && !loading && (
              <Grid
                container
                flexDirection={{ xs: "column-reverse", sm: "row" }}
                style={{ marginTop: 30 }}
                justifyContent={{ xs: "center", sm: "space-between" }}
                ref={mainRef}
              >
                <Grid
                  container
                  item
                  xs={12}
                  sm={7}
                  display="flex"
                  ml={{ xs: 0, sm: 2 }}
                >
                  <div style={{ width: "100%", paddingBottom: 30 }}>
                    {city && (
                      <CityTitleH1
                        style={{ fontSize: "1.5rem" }}
                        textAlign="center"
                        green={energyType === "renewable"}
                      >
                        Compare all electricity plans in &nbsp;
                        {capitalizeString(city)}, {StateName}, for {zipcode}
                      </CityTitleH1>
                    )}
                  </div>

                  <Grid
                    item
                    xs={12}
                    sm={12}
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                    flexDirection={{ xs: "column", sm: "inherit" }}
                    gap={1}
                  >
                    <span style={{ marginRight: 12 }}>Avg. Monthly Use:</span>
                    <ButtonGroup sx={{ marginTop: { xs: 0, sm: 0 } }}>
                      {["500", "1000", "2000"].map((val, idx) => (
                        <Button
                          sx={{
                            backgroundColor: avgMonthlyUse === val ? "#38c3a8" : "transparent",
                            color: avgMonthlyUse === val ? "#fff" : "#38c3a8",
                            borderColor: avgMonthlyUse === val ? "#38c3a8" : "#38c3a8",
                            "&:hover": {
                              backgroundColor: avgMonthlyUse === val ? "#2AA794" : "#e0f7f3",
                              borderColor: avgMonthlyUse === val ? "#2AA794" : "#38c3a8",
                            },
                          }}
                          variant={
                            avgMonthlyUse === val ? "contained" : "outlined"
                          }
                          key={idx}
                          onClick={() => {
                            setAvgMonthlyUse(val);
                          }}
                        >{`${val} kWh`}</Button>
                      ))}
                    </ButtonGroup>
                    <div>
                      <span style={{ marginRight: 12 }}>Term:</span>
                      <Select
                        labelId="term-select-label"
                        id="term-select"
                        className={styles.homepage_term_select}
                        value={selectedTerm || "12"}
                        onChange={(evt: SelectChangeEvent) => {
                          setSelectedTerm(evt.target.value as string);
                        }}
                        disabled={loading}
                      >
                        {termOptions.map((val, idx) => (
                          <MenuItem
                            value={val}
                            key={idx}
                          >{`${val} Months`}</MenuItem>
                        ))}
                      </Select>
                    </div>
                  </Grid>

                  <CompareChart
                    chartData={chartData}
                  // handleShowPlan={(id: string) => {
                  // }}
                  />
                </Grid>

                <Grid
                  container
                  item
                  xs={12}
                  sm={4}
                  alignItems="center"
                  justifyContent={{ xs: "center", sm: "flex-end" }}
                  sx={{ marginTop: { xs: 3, sm: 0 } }}
                  display="flex"
                >
                  <div
                    className={styles.total_price}
                    style={{ textAlign: "center" }}
                  >
                    {/* <h4 style={{ fontSize: 24, fontWeight: 700 }}>
                      Estimated Total Savings
                    </h4>
                    <RollingNumber
                      data={energySavings}
                      styles={{
                        color: energyType === 2 ? "#54C581" : "#6288e9",
                        fontWeight: 700,
                        fontSize: 60,
                      }}
                    />
                    <div style={{ width: "100%", marginTop: "10px" }}>
                      <div style={{ fontSize: 14, marginBottom: ".8rem" }}>
                        <p>Hi~</p>
                        <p>{PageContent.total_saving}</p>
                      </div>
                      {isTexas && (
                        <>
                          <Autocomplete
                            freeSolo
                            onChange={(evt, val: any) => {
                              setAddress(val ? val.label : "");
                              // setUtilities([]);
                              setSelectedAddress(val);
                              setInputZipcode(val ? val.zipcode : "");
                              getUtility("address");
                            }}
                            options={addressOptions.map(
                              (option: any) => option
                            )}
                            renderOption={(props, option) => (
                              <li {...props} key={option.id}>
                                {option.label}
                              </li>
                            )}
                            loading={addressLoading}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label="Please Enter Your Address"
                                value={address}
                                onChange={handleChangeAddress}
                                disabled={loading}
                              />
                            )}
                            className={css`
                              label {
                                padding-left: 10px;
                                font-size: 14px;
                                margin-top: 3px;
                                color: #9e9c9c;
                              }
                            `}
                          />
                          <p style={{ fontSize: 12, marginTop: ".8rem" }}>
                            Enter address for more accurate recommendations
                          </p>
                        </>
                      )}
                    </div>
                    {utilities && utilities.length > 1 && isTexas && (
                      <div style={{ width: "80%", marginTop: "10px" }}>
                        <Select
                          labelId="utility-label"
                          id="utility"
                          className={styles.unitily_select}
                          value={selectedUtility}
                          onChange={(evt: SelectChangeEvent) => {
                            setSelectedUtility(evt.target.value as string);
                          }}
                          disabled={loading}
                          sx={{ textAlign: "left" }}
                          style={{ marginTop: "10" }}
                        >
                          {utilities.map((val: any, idx) => (
                            <MenuItem value={val.utility_name} key={idx}>
                              {val.utility_name}
                            </MenuItem>
                          ))}
                        </Select>
                      </div>
                    )}
                    <div>
                      {isTexas && (
                        <Button
                          variant="contained"
                          onClick={onSubmit}
                          disabled={
                            loading || !inputZipcode || !selectedUtility
                          }
                          style={{
                            width: "fit-content",
                            borderRadius: 22.5,
                            padding: "10px 40px",
                          }}
                        >
                          Try Now
                        </Button>
                      )}
                    </div>
                    <CardBottombg
                      src={PageContent.img_bg}
                      alt=""
                    /> */}
                    <h6>
                      Compare our electricity plans and you could be saving
                      money
                    </h6>
                    <h4>
                      <p style={{ fontWeight: 500, fontSize: "1.45rem" }}>
                        Estimated Total Savings{" "}
                      </p>
                      <RollingNumber
                        data={energySavings}
                        styles={{
                          color:
                            energyType === "renewable" ? "#54C581" : "#38c3a8",
                          fontWeight: 700,
                          fontSize: 55,
                          margin: "1.5rem 0",
                          display: "block",
                        }}
                      />
                      <p style={{ fontWeight: 300, fontSize: "1.2rem" }}>
                        based on {avgMonthlyUse} Kwh
                      </p>
                    </h4>
                    <p style={{ fontSize: "14px", color: "#333333" }}>
                      *Actual total savings will be based on your personal usage
                      and electricity plan selected.
                    </p>
                    <CardBottombg
                      src={PageContent?.img_bg}
                      alt=""
                      style={{ zIndex: 1 }}
                    />
                  </div>
                </Grid>
              </Grid>
            )}
            {chartLoading && <CircularProgress sx={{ color: "#38c3a8" }} />}
          </ChartSection>
        )}
      </Container>

      {isTexas === false &&
        energyType !== "provider" &&
        energyType !== "solar-energy" && (
          <PlansList detailUsages={[]} handleGetUsage={() => {}} handleProvider={(reps:any)=>{console.log(reps,'reps');setProviderList(reps)}}/>
        )}
      <Container>
        <CityGuideSection
          city={city}
          zipcode={zipcode}
          utility={utility}
          gasUtility={gasUtility}
          type={energyType}
        />
      </Container>

      <Container>
        {energyType !== "solar-energy" && (
          <ProviderIntroSection
            city={city}
            zipcode={zipcode}
            type={energyType}
            state={selectState?.state_name?.toLowerCase() || ""}
            providerList={providerList}
          />
        )}
        {/* {energyType === "solar-energy" && <SolarIntro city={city} />} */}
      </Container>

        <Container>
          <div style={{ textAlign: "center", transform: "translateY(-20px)" }}>
            <BlueTitle>
              Our services are available across the United States.
            </BlueTitle>
            <p className="mb-2">View saving plans now!</p>
          </div>
          {stateArray.length > 0 && cityArray.length > 0 &&<SelectCity
            selectedCity={city}
            stateArray={stateArray}
            cityArray={cityArray}
          />}
        </Container>
    </SectionWrapper>
  );
};

export default CitySection;
