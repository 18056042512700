import { styled } from "@mui/system";

export const Logo = styled("a")`
  text-decoration: none;
  color: #666;
  display: flex;
  align-items: center;

  svg {
    width: 7em;
    fill: #1c0042;
  }

  span {
    font-size: 12px;
  }

  i {
    display: inline-block;
    font-style: normal;
    background-color: #38c3a8;
    color: #fff;
    font-size: 11px;
    padding: 1px 4px;
    border-radius: 4px;
    position: relative;
    top: -10px;
  }

  &:hover {
    svg {
      fill: #6288e9;
    }
  }

  @media (max-width: 991px) {
  }
`;

export const PageWrapper = styled("div")`
  display: flex;
  flex-direction: column;
  height: 100%;

  h2 {
    font-size: 3rem;
    line-height: 1.2;
  }

  @media (max-width: 991px) {
    h2 {
      font-size: 2rem;
      text-align: center;
    }
  }
`;

export const PageMain = styled("div")`
  flex: 1;
`;

export const ChartWrapper = styled("div")`
  height: 400px;
  width: 100%;
  margin: 24px 0;

  .recharts-active-bar {
    fill: url(#chart-gradient1) !important;
  }

  @media (max-width: 991px) {
    height: 320px;
  }

  @media (max-width: 768px) {
    height: 260px;
  }
`;

export const StyledFooterInner = styled("div")`
  max-width: 1400px;
  /* margin-left: auto;
  margin-right: auto; */
  display: flex;
  justify-content: center;
  align-items: center;
  @media (max-width: 768px) {
    flex-direction: column;

    .icon {
      justify-content: flex-start !important;
      gap: 20px;
    }
    /* padding: 0 !important; */
  }
  @media (max-width: 1400px) {
    max-width: 1200px;
    /* padding: 0 4rem; */
  }

  .icon {
    display: flex;
    justify-content: flex-start;
    gap: 20px;
  }

  .icon i {
    display: flex;
    height: 100%;
    align-items: center;
    justify-content: center;
    width: 35px; 
    height: 35px; 
    border: 2px solid #666;
    border-radius: 50%;
    transition: all 0.3s ease;
    &:hover {
      border-color: #99B2F1;
      color #99B2F1;

      svg {
        color: #99B2F1;
      }
    }
  }
  .icon svg {
    font-size: 16px;
    color: #666;
    transition: color 0.5s ease;
  }
`;

export const StyledFooter = styled("div")`
  background-color: #f9f9f9;
  color: #fff;
  font-size: 14px;
  padding: 1.5rem 0;

  p {
    color: #787171;
  }

  .foot-right {
    flex: 0.8;
    display: flex;
    max-width: 800px;
    justify-content: space-between;
    ul {
      &:last-child {
        img {
          height: 30px;
        }
      }
      li {
        margin-bottom: 14px;
        color: #000000;
        cursor: pointer;
        &:first-child {
          font-size: 16px;
          font-weight: bold;
          line-height: 24px;
          margin-bottom: 25px;
          cursor: default;
        }
      }
      a {
        color: #666;
        text-decoration: none;
      }
    }
  }

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: flex-start;
    padding: 1.5rem 2rem;

    .foot-right {
      flex-direction: column;
      width: 100%;
      gap: 10px;
      ul {
        margin-bottom: 0;
      }
      li {
        /* margin-bottom: 0 !important; */
      }
    }
  }
  @media (min-width: 768px) {
    .MuiDivider-root {
      display: none;
    }
    .foot-left {
      flex: 0 0 30%;
    }
    p {
      margin-top: 20px;
    }
  }

  ol,
  ul,
  li {
    padding-left: 0;
    list-style: none;
  }
`;

export const FooterLogo = styled("div")`
  svg {
    fill: #c085ff;
    width: 8em;
  }
`;

export const FooterSocialLinks = styled("div")`
  display: flex;
  align-items: center;

  a {
    padding: 0.75em;
    border: 2px solid #38c3a8;
    border-radius: 1000px;
    color: #fff;
    transition: background-color 0.25s ease-in-out 0s;
    display: inline-flex;
    width: 52px;
    height: 52px;
    align-items: center;
    justify-content: center;
    margin-left: 0.75rem;
    box-sizing: border-box;
  }

  a:first-child {
    margin-left: 0;
  }

  a:hover,
  a:focus {
    background-color: #38c3a8;
  }

  svg {
    height: 1.5em;
    width: 1.5em;
    margin: auto;
  }

  @media (max-width: 768px) {
    margin-bottom: 32px;
  }
`;

export const ChartTooltips = styled("div")`
  background: #fff;
  border: 1px solid #6288e980;
  border-radius: 8px;
  padding: 12px 16px;
  text-align: left;

  h6 {
    font-size: 12px;
    font-weight: 700;
    color: #3a3a3a;
    margin: 0 0 8px;
    padding-bottom: 8px;
    border-bottom: 1px solid #f2f2f2;
  }

  p {
    margin: 0;
  }
`;

export const ViewMoreBtnRow = styled("div")`
  display: flex;
  padding-left: 2%;

  & > div {
    flex: 1;
    text-align: center;
  }

  @media (max-width: 768px) {
    padding-left: 4%;

    button {
      font-size: 11px;
      padding: 2px 6px;
    }
  }
`;

export const HeaderMenu = styled("div")`
  display: flex;
  align-items: center;
  gap: 20px;

  & > a {
    display: inline-block;
    text-decoration: none;
    font-size: 1.1em;
    color: #1c0042;
    position: relative;
    transition: all 0.25s ease 0s;
    cursor: pointer;
    position: relative;
    padding: 0 10px;
  }

  @media (max-width: 991px) {
    display: ${({ active }: { active: string }) =>
      active && active === "true" ? "flex" : "none"};
    position: fixed;
    top: 52px;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: #fff;
    z-index: 999;
    flex-direction: column;
    align-items: flex-start;
    padding: 1.375rem 0px 0.625rem 3.5rem;

    button {
      margin-bottom: 1.375rem;
      font-size: 1.25rem;
      padding: 0.75rem 2.125rem;
    }

    & > a {
      font-size: 1.25rem;
    }
  }
`;

export const HeaderMenuItem = styled("a")`
  display: inline-block;
  text-decoration: none;
  font-size: 0.95em;
  color: #1c0042;
  position: relative;
  transition: all 0.25s ease 0s;
  margin-right: 48px;
  cursor: pointer;

  @media (max-width: 991px) {
    font-size: 1.25rem;

    &:last-of-type {
      margin-bottom: 2.75rem;
    }
  }

  img {
  }
`;

export const StyledHeaderInner = styled("div")`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 5em;

  @media (max-width: 991px) {
    justify-content: space-between;
  }
`;

export const EntrySection = styled("div")`
  /* @media (max-width: 768px) {
    margin-bottom: -20px;
  } */
`;

export const MenuToggle = styled("button")`
  cursor: pointer;
  border: 0 none;
  mask-image: ${({ active }: { active: string }) =>
    active && active === "true"
      ? 'url("/images/X.svg")'
      : 'url("/images/Menu.svg")'};
  mask-position: 0 0;
  mask-repeat: no-repeat;
  background-color: #888;
  width: 24px;
  height: 24px;
  mask-size: cover;
  display: none;
  margin-right: 0 !important;

  @media (max-width: 991px) {
    display: inline-block;
  }
`;

export const ModalBodyStyle = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: {
    md: 650,
    xs: "85%",
  },
  bgcolor: "#fff",
  boxShadow: 24,
  borderRadius: 4,
  p: 2,
};

export const ChartFooter = styled("div")`
  margin-top: 24px;
  color: #999;
  font-size: 13px;
  text-align: center;
`;

export const ModalCloseBtn = styled("button")`
  display: inline-block;
  width: 32px;
  height: 32px;
  mask: url("/images/X.svg") 0 0 no-repeat;
  background-color: #38c3a8;
  border: 0 none;
  cursor: pointer;
`;

export const SavingRow = styled("div")`
  margin: 4px 0;

  span {
    color: #38c3a8;
    font-size: 32px;
    font-weight: 700;
    margin-left: 12px;
  }

  @media (max-width: 768px) {
    text-align: left;

    span {
      font-size: 24px;
      margin-left: 0;
    }
  }
`;
