import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import { Button as BootstrapButton } from "react-bootstrap";
import NumberFormat from "react-number-format";
import ReactTooltip from "react-tooltip";
import { PUCT_number } from "../../../common/data";
import { _PlanRowProps } from "../../../types";
import { MonthlyBreakdownTable } from "./monthly-breakdown-table";
import { useParams } from "react-router-dom";
import {
  faCaretDown,
  faCaretUp,
  faExchangeAlt,
  faFilePdf,
} from "@fortawesome/free-solid-svg-icons";
import { Button } from "../../styles/Button.styled";
import { useTranslation } from "react-i18next";
import { Box } from "@mui/material";

export const PlanRow: React.FC<_PlanRowProps> = ({
  hideEnrollBtn,
  partnerList,
  val,
  label,
  usage,
  hidePrice,
  handleShowAccessSmtSection,
  handleCompare,
  compare,
  // handleClickEnroll,
  // handleShowSidebySideModal,
  hideComparisonBtn = true,
}) => {
  const { t } = useTranslation();
  const {
    plan_name,
    contract_term,
    base_rate,
    total,
    total_usage,
    rate,
    renewable,
    cancel_fee,
    cancel_fee_type,
    plan_desc,
    terms_url,
    efl_url,
    provider_id,
    month,
    is_prepaid,
    yrac,
    state_id,
    documents
    // plan_id,
    // type,
  } = val;

  const [showDetail, setShowDetail] = useState(false);

  const isPartner = partnerList
    ? partnerList.find((partner:any) => partner.id === provider_id)
    : undefined;

  const is100Green = parseInt(renewable) === 100;

  // const IsNotUsn = router.pathname.includes("/city");

  const { state } = useParams<{ state: string }>();
  const isTx = state?.toLowerCase() === "texas"; 

  const getLabel = () => {
    if (label === "bestOverallPlan") {
      return <div className="plan-label">Best Overall Plan</div>;
    }
    if (label === "bestGreenPlan") {
      return <div className="plan-label">Best Green Plan</div>;
    }
    if (label === "bestLongTermPlan") {
      return <div className="plan-label">Best Long Term Plan</div>;
    }
    return null;
  };

  return (
    <div className="plan-row-wrapper">
      {getLabel()}
      <div className="plan-row">
        <div className="plan-content d-flex flex-column justify-content-between">
          <div className="d-flex flex-column flex-grow-1">
            <div className="plan-overview mb-4">
              <div className="rep-logo">
                <img
                  src={
                    state_id === "TX" || state_id === undefined
                      ? `https://www.powerlego.com/ApiGateway/v2/logo/provider/${provider_id}`
                      : `https://dorzjv601fuva.cloudfront.net/usn/${provider_id}.png`
                  }
                  alt=""
                />
                {PUCT_number[provider_id] && (
                  <i>PUCT# {PUCT_number[provider_id]}</i>
                )}
              </div>
              <div className="plan-info-group">
                <div className="plan-info plan-info-terms">
                  <div>
                    {contract_term} <span>{t("Months")}</span>
                  </div>
                  <label>{t("Plan Length")}</label>
                </div>
                {!isTx && (
                  <div className="plan-info">
                    <div>{(parseFloat(base_rate) * 100).toFixed(2)}¢</div>
                    <label>{t("Base Rate")}</label>
                  </div>
                )}
                {isTx && (
                  <div className="plan-info">
                    <div>
                      {hidePrice ? (
                        "N/A"
                      ) : (
                        <NumberFormat
                          value={(total / parseInt(contract_term)).toFixed(2)}
                          displayType="text"
                          thousandSeparator
                          prefix="$"
                        />
                      )}
                    </div>
                    <label>{t("Est. Avg. Monthly Cost")}</label>
                  </div>
                )}
              </div>
            </div>
            <div className="plan-intro">
              <div className="plan-name">{plan_name}</div>
              <div className="plan-tags d-flex align-items-center flex-wrap mt-2 mb-3">
                {is100Green && <span>{t("100% Green")}</span>}
                {is_prepaid === "1" && <span>{t("Prepaid")}</span>}
              </div>
            </div>
          </div>
          <div className="mt-3" style={{ textAlign: "left" }}>
            <BootstrapButton
              variant="light"
              size="sm"
              onClick={() => {
                setShowDetail(!showDetail);
              }}
              style={{ border: "1px solid #ddd", backgroundColor: "#fff" }}
            >
              <span className="me-2">{`${
                showDetail ? "Hide" : "Show"
              } Detail`}</span>
              <FontAwesomeIcon
                icon={showDetail ? faCaretUp : faCaretDown}
                width={8}
              />
            </BootstrapButton>
            {!hideComparisonBtn && (
              <BootstrapButton
                variant="light"
                size="sm"
                className={`ms-2 plan-compare-btn ${compare ? "active" : ""}`}
                onClick={() => handleCompare()}
              >
                <FontAwesomeIcon
                  icon={faExchangeAlt}
                  className="me-2"
                  width={14}
                />
                {t("Compare")}
              </BootstrapButton>
            )}
          </div>
        </div>
        <div className="plan-options d-flex flex-column justify-content-between">
          <div className="d-flex flex-column">
            <div className="plan-info">
              <div className="plan-est-rate d-flex align-items-center justify-content-center">
                {hidePrice ? "N/A" : `${rate}¢`}
                {!hidePrice && (
                  <div className="ms-2" style={{ fontSize: 0 }}>
                    <i
                      data-tip={t(
                        "The Total Est. Rate has factored in your usage pattern, tiered energy rates, TDU delivery fee, basic service charge, bill credit/rebate and other recurring charges if applicable"
                      )}
                      className="tooltip-mark"
                    >
                      ?
                    </i>
                    <ReactTooltip className="tooltip" effect="solid" />
                  </div>
                )}
              </div>
              <label>{isTx ? "Total Est. Rate/kWh" : "Base Rate"}</label>
            </div>
            {isPartner && isPartner.reward > 0 && (
              <Box
                sx={{
                  display: "inline-block",
                  textAlign: "center",
                  background: "#48539B",
                  border: "0 none",
                  color: "#fff",
                  fontWeight: 700,
                  lineHeight: { xs: 1.2, sm: 1.6 },
                  fontSize: { xs: "11px", sm: "13px" },
                  padding: "1px 4px",
                  my: 2,
                }}
              >
                Plus{" "}
                <img
                  src="/images/capitaloneshopping_inverted_logo.svg"
                  alt="Capital One"
                  width="150"
                  style={{ position: "relative", top: "-4px" }}
                />{" "}
                ${isPartner.reward} Reward
              </Box>
            )}
            <div
              className="text-center mt-2"
              style={{ fontSize: 13, color: "#999" }}
            >
              {t("Cancellation Fee")}: {cancel_fee}
              {cancel_fee_type === "PerMonth"
                ? ` (${"Per Remaining Month"})`
                : ""}
            </div>
          </div>
          {!hideEnrollBtn && (
            <div className="mt-5 d-flex justify-content-center">
              {!hidePrice &&
                (isPartner ? (
                  <Button
                    className="ps-2 pe-2"
                    onClick={() => {
                      window.location.href = isPartner.targetUrl;
                    }}
                  >
                    Get Reward
                  </Button>
                ) : (
                  <Button
                    className="ps-2 pe-2"
                    onClick={() => {
                      window.open(val.enroll_url, "_blank");
                    }}
                  >
                    {t("Check Availability")}
                  </Button>
                ))}
              {hidePrice && (
                <Button onClick={() => handleShowAccessSmtSection()}>
                  {t("Get Rate")}
                </Button>
              )}
            </div>
          )}
        </div>
      </div>
      <div className={`plan-detail ${showDetail ? "active" : ""}`}>
        <div className="pt-4 pb-4">
          {isTx && (
            <div className="plan-detail-heading">
              {t("Estimated Annualized Cost")}
            </div>
          )}
          {!hidePrice && isTx && (
            <div className="mb-2">
              {t("Total Est. Cost")}:{" "}
              <NumberFormat
                value={total}
                displayType="text"
                thousandSeparator
                prefix="$"
              />{" "}
              ({t("Based on")}{" "}
              <NumberFormat
                value={total_usage}
                displayType="text"
                suffix="kWh"
                thousandSeparator
              />
              )
            </div>
          )}
          {isTx && (
            <MonthlyBreakdownTable
              usage={usage}
              monthlyPrice={month}
              hidePrice={hidePrice}
            />
          )}
          <div className="plan-detail-heading">{t("Plan Details")}</div>
          <div>{plan_desc}</div>
          <div className="mt-4">
            <div>
              {t("Renewable Percentage")}: {renewable}%
            </div>
          </div>
          {isTx ? <div className="plan-links mt-3">
            {terms_url && (
              <a href={terms_url} target="_blank" rel="noreferrer">
                <FontAwesomeIcon icon={faFilePdf} />
                <span className="ms-2">{t("Terms of Service")}</span>
              </a>
            )}
            {efl_url && (
              <a href={efl_url} target="_blank" rel="noreferrer">
                <FontAwesomeIcon icon={faFilePdf} />
                <span className="ms-2">{t("Facts Label")}</span>
              </a>
            )}
            {yrac && (
              <a href={yrac} target="_blank" rel="noreferrer">
                <FontAwesomeIcon icon={faFilePdf} />
                <span className="ms-2">YRAC</span>
              </a>
            )}
          </div>:<div className="plan-links mt-3 flex flex-wrap gap-2">
            {(documents ?? []).map((item) => (
              <a
                key={item.location} 
                href={item.location}
                target="_blank"
                rel="noreferrer"
                className="inline-flex items-center mb-2"
              >
                <FontAwesomeIcon icon={faFilePdf} />
                <span className="ms-2">{item.description}</span>
              </a>
            ))}
          </div>}
        </div>
      </div>
    </div>
  );
};
