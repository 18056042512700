import React, { PureComponent, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Modal } from "react-bootstrap";
import type {
  SidebySideModalProps,
  ChartDataTypes,
  _PlanProps,
} from "../../../types";
import {
  ChartWrapper,
  ChartTooltips,
  CompareTableWrapper,
} from "../../styles/Plan.styled";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  ReferenceLine,
} from "recharts";
import moment from "moment";
import { toast } from "react-toastify";
import { fetchSidebySidePlans } from "../../../api/api";
import { Button } from "../../styles/Button.styled";
import { css } from "@emotion/css";
import NumberFormat from "react-number-format";
import { LoadingRow } from "../LoadingRow";

const tooltipStyles = {
  cursor: {
    fill: "transparent",
  },
};

export const SidebySideModal: React.FC<SidebySideModalProps> = ({
  partnerList,
  show,
  handleClose,
  thatPlan,
  zipcode,
  utilityCode,
  usages,
  planType,
  handleClickEnroll,
  terms,
}) => {
  const { t } = useTranslation("plan");
  const [loading, setLoading] = useState(false);
  const [comparePlans, setComparePlans] = useState<_PlanProps[]>([]);
  const [chartData, setChartData] = useState<ChartDataTypes[]>([]);

  const fields = [
    { key: "plan_name", label: "Plan Name" },
    { key: "contract_term", label: "Plan Length" },
    { key: "saving", label: "Saving" },
    { key: "enroll_btn", label: "" },
  ];

  useEffect(() => {
    if (!show || !thatPlan) return;

    setLoading(true);
    fetchSidebySidePlans(
      thatPlan.plan_id,
      thatPlan.rate,
      zipcode,
      utilityCode,
      usages,
      planType,
      terms
    ).then((res) => {
      setLoading(false);
      if (res && res.status === 1) {
        const { compare_prices_plan } = res.response || {};

        let [...sortedPlans] = compare_prices_plan || [];

        sortedPlans.sort((a: _PlanProps, b: _PlanProps) => {
          if (Number(a.rate) < Number(b.rate)) return -1;
          if (Number(a.rate) > Number(b.rate)) return 1;
          return 0;
        });

        sortedPlans = sortedPlans.slice(0, 3);

        sortedPlans.push(thatPlan);

        const data: ChartDataTypes[] = [];
        sortedPlans.forEach((val: _PlanProps) => {
          data.push({
            id: `${val.provider_id}|${val.plan_id}`,
            value: Number(val.rate),
            label: val.plan_name,
          });
        });
        setComparePlans(sortedPlans);
        setChartData(data);
      } else {
        toast.error(res.message);
      }
    });

    return () => {
      setComparePlans([]);
      setChartData([]);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [thatPlan, show]);

  const getBodyContent = () => {
    if (loading) {
      return <LoadingRow />;
    }
    if (!loading && chartData.length === 0) {
      return <h5 style={{ textAlign: "center" }}>No data found</h5>;
    }

    return (
      <>
        <div className="d-flex justify-content-between align-items-center">
          <div />
          <div
            className={css`
              color: #999;
              font-size: 12px;
            `}
          >
            {t("Last scan date")}:{" "}
            {moment().isBetween(
              moment("00:00am", "hh:mma"),
              moment("01:00am", "hh:mma")
            )
              ? moment().subtract(1, "days").format("MM/DD/YYYY")
              : moment().format("MM/DD/YYYY")}
          </div>
        </div>
        <ChartWrapper>
          <ResponsiveContainer>
            <BarChart
              data={chartData}
              margin={{ top: 24, bottom: 36, left: -12 }}
            >
              <XAxis
                dataKey="id"
                stroke="#bbb"
                tick={<CustomizedTick />}
                interval={0}
              />
              <YAxis
                stroke="#fff"
                tick={{ fontSize: 10, fill: "#bbb" }}
                tickFormatter={(tickItem) => tickItem}
                domain={["dataMin - 0.5", "dataMax + 0.5"]}
              />
              <Tooltip
                {...tooltipStyles}
                position={{ y: 50 }}
                wrapperStyle={{ zIndex: 1 }}
                content={<CustomTooltip />}
              />
              <CartesianGrid vertical={false} strokeDasharray="2" />
              <Bar
                dataKey="value"
                isAnimationActive
                maxBarSize={80}
                shape={BarWithTopBorder(thatPlan)}
                label={<CustomizedLabel />}
                animationDuration={1000}
              />
              {chartData[0] && (
                <ReferenceLine
                  y={chartData[0].value}
                  stroke="#ff7171"
                  strokeDasharray="5 3"
                  strokeWidth={2}
                />
              )}
            </BarChart>
          </ResponsiveContainer>
        </ChartWrapper>
        <CompareTableWrapper className="mt-4">
          <table width="100%">
            <tbody>
              <tr>
                {fields.map((field, fieldIdx) => (
                  <td
                    className="th"
                    key={fieldIdx}
                    style={{ textAlign: fieldIdx === 0 ? "left" : "center" }}
                  >
                    {field.label}
                  </td>
                ))}
              </tr>
              {comparePlans.map((plan: any, planIdx) => {
                const isThatPlan =
                  thatPlan && thatPlan.plan_id === plan.plan_id;

                const isPartner = partnerList
                  ? partnerList.find(
                      (partner:any) => partner.id === plan.provider_id
                    )
                  : undefined;

                return (
                  <tr key={planIdx}>
                    {fields.map((field, fieldIdx) => {
                      const { key } = field;

                      let _html = <span>-</span>;
                      if (key === "provider_id") {
                        _html = (
                          <img
                            src={`https://www.powerlego.com/ApiGateway/v2/logo/provider/${plan[key]}`}
                            width="100"
                            alt=""
                          />
                        );
                      } else if (key === "base_rate") {
                        _html = (
                          <span>
                            {(parseFloat(plan[key]) * 100).toFixed(2)}¢
                          </span>
                        );
                      } else if (key === "total") {
                        _html = (
                          <div>
                            <div>
                              {plan.hide_price ? "N/A" : `${plan[key]}`}
                            </div>
                            <div>
                              <i>
                                {"Based on"}{" "}
                                {plan.hide_price ? (
                                  "N/A "
                                ) : (
                                  <NumberFormat
                                    value={plan.total_usage}
                                    displayType="text"
                                    thousandSeparator
                                  />
                                )}
                              </i>
                            </div>
                          </div>
                        );
                      } else if (key === "avg_monthly_cost") {
                        _html = plan.hide_price ? (
                          <span>N/A</span>
                        ) : (
                          <NumberFormat
                            value={(
                              plan.total / parseInt(plan.contract_term)
                            ).toFixed(2)}
                            prefix="$"
                            displayType="text"
                            thousandSeparator
                          />
                        );
                      } else if (key === "renewable") {
                        _html = <span>{plan[key]}%</span>;
                      } else if (key === "is_prepaid") {
                        _html = <span>{plan[key] === "1" ? "Yes" : "No"}</span>;
                      } else if (key === "is_tou_plan") {
                        _html = <span>{plan[key] ? "Yes" : "No"}</span>;
                      } else if (key === "enroll_btn") {
                        _html = isPartner ? (
                          <Button
                            onClick={() => {
                              window.location.href = isPartner.targetUrl;
                            }}
                          >
                            Get Reward
                          </Button>
                        ) : (
                          <Button
                            onClick={() => {
                              if (!thatPlan) return;
                              handleClose();
                              if (isThatPlan) {
                                window.open(thatPlan.enroll_url, "_blank");
                              } else {
                                localStorage.setItem(
                                  "ts_plan",
                                  JSON.stringify(plan)
                                );
                                handleClickEnroll(plan);
                              }
                            }}
                          >
                            {t("Check Availability")}
                          </Button>
                        );
                      } else if (key === "cancel_fee") {
                        _html = (
                          <span>
                            {plan[key]}
                            {plan.cancel_fee_type === "PerMonth"
                              ? ` (${"Per Remaining Month"})`
                              : ""}
                          </span>
                        );
                      } else if (key === "saving") {
                        if (!thatPlan) return "";
                        if (isThatPlan) {
                          _html = <span>/</span>;
                        } else {
                          const saving =
                            Math.round(
                              ((Number(plan.rate) - Number(thatPlan.rate)) /
                                Number(thatPlan.rate)) *
                                10000
                            ) / 100;
                          _html = (
                            <span
                              className={css`
                                font-size: 15px;
                                font-weight: 500;
                              `}
                            >
                              ~ {Math.abs(saving)}%
                            </span>
                          );
                        }
                      } else {
                        _html = <span>{plan[key]}</span>;
                      }
                      return (
                        <td
                          key={`${planIdx}_${fieldIdx}`}
                          style={{
                            textAlign: fieldIdx === 0 ? "left" : "center",
                          }}
                        >
                          {_html}
                        </td>
                      );
                    })}
                  </tr>
                );
              })}
            </tbody>
          </table>
        </CompareTableWrapper>
      </>
    );
  };

  return (
    <Modal size="lg" show={show} onHide={() => handleClose()}>
      <Modal.Header closeButton style={{ flexDirection: "column" }}>
        <Modal.Title>{t("Not considering other plans?")}</Modal.Title>
        <p className="mb-0">
          {t(
            "We found there are some plans which can bring more savings based on your consumption"
          )}
        </p>
      </Modal.Header>
      <Modal.Body>{getBodyContent()}</Modal.Body>
    </Modal>
  );
};

const BarWithTopBorder = (plan: _PlanProps | undefined) => (props: any) => {
  const { x, y, width, height, id } = props;

  let isHost = false;
  if (plan && plan.plan_id === id.split("|")[1]) isHost = true;

  return (
    <g>
      <defs>
        <linearGradient id="chart-gradient1" x1="0" x2="0" y1="0" y2="1">
          <stop offset="0%" stopColor="#17C2C4" />
          <stop offset="100%" stopColor="#38c3a8" />
        </linearGradient>
        <linearGradient id="chart-gradient2" x1="0" x2="0" y1="0" y2="1">
          <stop offset="0%" stopColor="#E5E5E5" />
          <stop offset="100%" stopColor="#B4B4B4" />
        </linearGradient>
      </defs>
      <rect
        x={x}
        y={y}
        width={width}
        height={height}
        stroke="none"
        className="bar"
        rx="2"
        ry="2"
        fill={isHost ? "url(#chart-gradient2)" : "url(#chart-gradient1)"}
      />
    </g>
  );
};

class CustomTooltip extends PureComponent {
  render() {
    const { active, payload }: Readonly<any> = this.props;

    if (!active || !payload || !payload[0]) return null;
    const { label, value } = payload[0].payload;

    return (
      <ChartTooltips>
        <h6>{label}</h6>
        <p>{value}¢</p>
      </ChartTooltips>
    );
  }
}

class CustomizedTick extends PureComponent {
  render() {
    const { x, y, payload, width, visibleTicksCount }: Readonly<any> =
      this.props;
    const providerId = payload.value.split("|")[0];
    const maxWidth = 100;
    const _width =
      width / visibleTicksCount > maxWidth
        ? maxWidth
        : width / visibleTicksCount;

    return (
      <g transform={`translate(${x},${y})`}>
        <image
          x={(_width / 2) * -1}
          y={0}
          xlinkHref={`https://www.powerlego.com/ApiGateway/v2/logo/provider/${providerId}`}
          width={_width}
        />
      </g>
    );
  }
}

class CustomizedLabel extends PureComponent {
  render() {
    const { x, y, value, width }: Readonly<any> = this.props;
    return (
      <text
        x={x + width / 2}
        y={y}
        dy={-8}
        fill="#14305E"
        fontSize={width / 5}
        fontWeight="500"
        textAnchor="middle"
      >
        {value}¢
      </text>
    );
  }
}
