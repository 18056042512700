/*
* APIs are powered by PowerLego
* Website: https://www.powerlego.com/
*/

import { POWERLEGO_API_URL, ACC_KEY, API_KEY,REPs,PROVIDER_ID } from "../config";
const CityUrl = "https://bb0xg7o51l.execute-api.us-east-2.amazonaws.com/beta"

/*
 * Address Validator
 * Use cases:        https://www.powerlego.com/cases/address_validator
 * Postman examples: https://documenter.getpostman.com/view/4831254/SzKYPH3c#ca1bdf1f-2318-4fed-aaeb-88708d7c34eb
 */

export const fetchUsnFilterOptions = async (
  type,
  usage,
  zipcode,
  filter,
  utilityCode,
  stateId
) => {
  try {
    const myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${ACC_KEY}`);

    const formdata = getPlanFormData(type, usage, zipcode, filter, utilityCode);
    formdata.append("state_id", stateId);

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: formdata,
      redirect: "follow",
    };

    // const url = `${POWERLEGO_API_URL}/v2/commission/filter`;
    const url = `${POWERLEGO_API_URL}/v2/plan/get_filter`;

    const response = await fetch(url, requestOptions);
    return response.json();
  } catch (err) {
    console.log(err);
  }
};


export const fetchProviderData = async (providerName, stateId) => {
  const myHeaders = new Headers();
  myHeaders.append("Authorization", `Bearer ${ACC_KEY}`);

  const formdata = new FormData();
  formdata.append("provider_name", providerName);
  formdata.append("state_id", stateId || "TX");

  const requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: formdata,
    redirect: "follow",
  };

  const url = `${POWERLEGO_API_URL}/v2/provider/get`;

  const response = await fetch(url, requestOptions);
  return response.json();
};


export const fetchMarketComparisonData = async (
  providerId,
  term,
  utilityCode,
  usages,
  providerNum = 3
) => {
  const myHeaders = new Headers();
  myHeaders.append("Authorization", `Bearer ${ACC_KEY}`);
  myHeaders.append("content-type", "application/x-www-form-urlencoded");

  const requestData = `provider_id=${providerId}&term=${term}&utility_code=${utilityCode}&provider_number=${providerNum}`;

  const usageData = [];
  for (const i in usages) {
    if (usages[i]) {
      usageData.push(`usages[${i}]=${usages[i]}`);
    }
  }

  const requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: `${requestData}&${usageData.join("&")}`,
    redirect: "follow",
  };

  const url = `${CityUrl}/ProviderPriceComparison`;

  const response = await fetch(url, requestOptions);
  return response.json();
};


export const fetchFilterOptions = async (
  type,
  usage,
  zipcode,
  filter,
  utilityCode,
  stateId,
  isCap
) => {
  try {
    const myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${ACC_KEY}`);

    const formdata = getPlanFormData(type, usage, zipcode, filter, utilityCode);
    formdata.append("state_id", stateId);

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: formdata,
      redirect: "follow",
    };

    // const url = `${POWERLEGO_API_URL}/v2/commission/filter`;
    const url = isCap
      ? `${POWERLEGO_API_URL}/v2/commission/get_filter`
      : `${POWERLEGO_API_URL}/v2/plan/filter`;

    const response = await fetch(url, requestOptions);
    return response.json();
  } catch (err) {
    console.log(err);
  }
};


export const fetchAllPlans = async (
  type,
  usage,
  zipcode,
  filter,
  utilityCode,
  stateId,
  isCap
) => {
  try {
    const myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${ACC_KEY}`);
    const formdata = getPlanFormData(type, usage, zipcode, filter, utilityCode);
    if (stateId && stateId !== "TX") formdata.append("state_id", stateId);
    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: formdata,
      redirect: "follow",
    };

    const url = isCap
      ? `${POWERLEGO_API_URL}/v2/commission/get_plan`
      : `${POWERLEGO_API_URL}/v2/plan/get`;
    // const url = `${POWERLEGO_API_URL}/v2/commission/get_plan`;

    const response = await fetch(url, requestOptions);
    return response.json();
  } catch (err) {
    console.log(err);
  }
};


export const fetchAllUsnPlans = async (
  type,
  usage,
  zipcode,
  filter,
  utilityCode,
  stateId
) => {
  try {
    const myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${ACC_KEY}`);

    const formdata = getPlanFormData(type, usage, zipcode, filter, utilityCode);
    formdata.append("state_id", stateId || "TX");
    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: formdata,
      redirect: "follow",
    };

    // const url = `${POWERLEGO_API_URL}/v2/commission/plan`;
    const url = `${POWERLEGO_API_URL}/v2/plan/get_plan`;

    const response = await fetch(url, requestOptions);
    return response.json();
  } catch (err) {
    console.log(err);
  }
};


const getPlanFormData = (type, usage, zipcode, filter, utilityCode) => {
  const formdata = new FormData();
  formdata.append("zipcode", zipcode);
  if (!filter.utility_code) {
    formdata.append("utility_code", utilityCode);
  } else {
    formdata.append("utility_code", filter.utility_code);
  }

  if (usage) {
    if (type === "normal") {
      for (const u in usage) {
        if (usage[u]) {
          formdata.append(`usage[monthly][${u}]`, usage[u]);
        }
      }
      formdata.append("filter[type]", "normal");
    } else {
      for (const u in usage) {
        if (usage[u]) {
          const data = usage[u];
          for (const i in data) {
            if (i !== "free") {
              formdata.append(`usage[monthly][${u}]`, usage[u][i]);
            }
          }
        }
      }
      formdata.append("filter[type]", `free_${type}`);
    }
  } else if (type === "normal") {
    formdata.append("filter[type]", "normal");
  } else {
    formdata.append("filter[type]", "tou");
  }

  for (const f in filter) {
    if (f === "provider_id") {
      filter.provider_id.forEach((val, idx) => {
        formdata.append(`filter[provider_id][${idx}]`, val);
      });
    } else if (f === "term") {
      filter.term.forEach((val, idx) => {
        if (!val || val === 0) return true;
        formdata.append(`filter[term][${idx}]`, val);
      });
    } else if (f === "prepaid") {
      if (filter.prepaid !== "all") {
        formdata.append(`filter[prepaid]`, filter.prepaid === "true");
      }
    } else if (f !== "type") {
      if (filter[f]) formdata.append(`filter[${f}]`, filter[f]);
    }
  }
  return formdata;
};

export const fetchComparisonData = async (
  providerId1,
  providerId2,
  term,
  utilityCode,
  usages
) => {
  const myHeaders = new Headers();
  myHeaders.append("Authorization", `Bearer ${ACC_KEY}`);
  myHeaders.append("content-type", "application/x-www-form-urlencoded");

  const requestData = `term=${term}&utility_code=${utilityCode}&provider_ids[0]=${providerId1}&provider_ids[1]=${providerId2}`;

  const usageData = [];
  for (const i in usages) {
    if (usages[i]) {
      usageData.push(`usages[${i}]=${usages[i]}`);
    }
  }

  const requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: `${requestData}&${usageData.join("&")}`,
    redirect: "follow",
  };

  const url =
    "https://0gk5f0wlpd.execute-api.us-east-2.amazonaws.com/v1/SideBySide/get_average_price";

  const response = await fetch(url, requestOptions);
  return response.json();
};


export const fetchAddress = async (val) => {
  const string = val.toString().replace(/#/, "");

  const myHeaders = new Headers();
  myHeaders.append("Authorization", `Bearer ${API_KEY}`);

  const formdata = new FormData();
  formdata.append("term", string);

  const url = `${POWERLEGO_API_URL}/v1/address_validator`;

  const response = await fetch(url, {
    method: "POST",
    headers: myHeaders,
    body: formdata,
    redirect: "follow",
  });
  return response.json();
};


export const fetchEstUsage = async (address, city, state, zipcode) => {
  const myHeaders = new Headers();
  myHeaders.append("Authorization", `Bearer ${ACC_KEY}`);

  const formdata = new FormData();
  formdata.append("address1", address);
  formdata.append("city", city);
  formdata.append("state", state);
  formdata.append("zipcode", zipcode);

  const requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: formdata,
    redirect: "follow",
  };

  const url = `${POWERLEGO_API_URL}/v1/usage_estimator`;

  const response = await fetch(url, requestOptions);
  return response.json();
};

export const fetchUSNPlans = async (
  zipcode,
  utility_code,
  term,
  usage,
  state_id,
  isGreen
) => {
  const myHeaders = new Headers();
  myHeaders.append("Authorization", `Bearer ${ACC_KEY}`);

  const url = `${POWERLEGO_API_URL}/v2/commission/get_plan`;

  const formdata = new FormData();
  formdata.append("zipcode", zipcode);
  if (utility_code) {
    formdata.append("utility_code", utility_code);
  }
  formdata.append("filter[type]", "normal");
  formdata.append("filter[term][0]", term);
  formdata.append("state_id", state_id);
  if (isGreen) {
    formdata.append("filter[renewable]", isGreen);
  }

  for (let index = 0; index < 12; index += 1) {
    formdata.append(
      `usage[monthly][${index + 1}]`,
      usage[index] ? usage[index] : 1000
    );
  }
  const response = await fetch(url, {
    method: "POST",
    headers: myHeaders,
    body: formdata,
  });

  return response.json();
};

export const fetchUSNUtility = async (zipcode) => {
  const myHeaders = new Headers();
  myHeaders.append("Authorization", `Bearer ${ACC_KEY}`);

  const formdata = new FormData();
  formdata.append("zipcode", zipcode);

  const requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: formdata,
    redirect: "follow",
  };

  const url = `${POWERLEGO_API_URL}/v2/commission/get_utility`;

  const response = await fetch(url, requestOptions);
  return response.json();
};

export const fetchCityList = async (state) => {
  // const string = val.toString().replace(/#/, '')

  const myHeaders = new Headers();
  myHeaders.append("Authorization", `Bearer ${API_KEY}`);

  const formdata = new FormData();
  formdata.append("state_id", state);

  const url = `${CityUrl}/City/city`;

  const response = await fetch(url, {
    method: "POST",
    headers: myHeaders,
    body: formdata,
    redirect: "follow",
  });
  return response.json();
};

export const converArrToSearchParams = (
  name,
  arr
) => arr.map((val, key) => `${name}[${key}]=${val}`).join("&");

export const getRepIds = () => REPs.map((val) => val.providerId);

export const fetchTerms = async (utilityCode) => {
  const hostId = REPs.filter((val) => val.host)[0].providerId;

  try {
    const res = await fetch(`${CityUrl}/get_contract_terms`, {
      method: "POST",
      headers: {
        "content-type": "application/x-www-form-urlencoded",
        Authorization: `Bearer ${API_KEY}`,
      },
      body: `provider_id=${hostId}&utility_code=${utilityCode}`,
    });
    return res.json();
  } catch (err) {
    console.error(err);
  }
};


export const fetchPlan = async (
  term,
  utilityCode,
  usages,
  state_id,
) => {
  try {
    const body =
      state_id && state_id !== "TX"
        ? `term=${term}&utility_code=${utilityCode}&${converArrToSearchParams(
            "usages",
            usages
          )}&provider_num=3&state_id=${state_id}`
        : `provider_id=${PROVIDER_ID}&term=${term}&utility_code=${utilityCode}&${converArrToSearchParams(
            "usages",
            usages
          )}&provider_num=3`;
    const res = await fetch(`${CityUrl}/average_price`, {
      method: "POST",
      headers: {
        "content-type": "application/x-www-form-urlencoded",
        Authorization: `Bearer ${API_KEY}`,
      },
      body,
    });
    return res.json();
  } catch (err) {
    console.error(err);
  }
};

export const fetchUtility = async (zipcode) => {
  const myHeaders = new Headers();
  myHeaders.append("Authorization", `Bearer ${API_KEY}`);

  const formdata = new FormData();
  formdata.append("zipcode", zipcode);

  const url = `${POWERLEGO_API_URL}/v1/get_utility`;

  const response = await fetch(url, {
    method: "POST",
    headers: myHeaders,
    body: formdata,
    redirect: "follow",
  });
  return response.json();
};