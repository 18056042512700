import { Box, Container, Typography } from "@mui/material";
import SelectCity from "../components/SelectCity";
import { fetchAllCityList,fetchState } from "../api/api";
// import React, { lazy } from 'react';
import USGeoMap from '../components/USGeoMapGoogle'
import Header from '../components/header'
import { useState, useEffect } from "react";
// const USGeoMap = lazy(() => import('../src/components/USGeoMapGoogle'));

const ServiceAreas = () => {
  const [data, setData] = useState<{ stateArray: any[]; cityArray: any[] } | null>(null);
  useEffect(() => {
    const fetchData = async () => {
      const result = await getStateList();
      // console.log(result, 'result')
      setData(result);
    };

    fetchData();
  }, []);
  return (
  <div style={{minHeight:'calc(100vh - 390px)'}}>
    <Header />
    <Container maxWidth="lg">
      <Box pt={5} pb={5}>
        <Typography variant="h3" sx={{ fontSize: "2.4rem", fontWeight: 700 }}>
          Where We Serve
        </Typography>
        <USGeoMap />
      </Box>
    </Container>
    {/* <BluebgSection notmove> */}
    <Box mt={2} mb={4}>
      <Container maxWidth="md">
        {/* <Box textAlign="center" pt={3}>
              <BlueTitle>
                Our services are available across the United States.
              </BlueTitle>
              <p className="mb-2">View saving plans now!</p>
            </Box> */}
        {data && <SelectCity stateArray={data.stateArray} cityArray={data.cityArray} />}
      </Container>
    </Box>
    {/* </BluebgSection> */}
  </div>)
};

export default ServiceAreas;

export const getStateList = async () => {
  let stateArray = [];
  let cityArray = [];
  const res1: any = await fetchState();
  const res2: any = await fetchAllCityList();

  if (res1 && res1.code === 200) stateArray = res1.data;

  if (res2 && res2.code === 200) cityArray = res2.data;

  return {
    stateArray,
    cityArray,
  };
};
